import React, { useCallback, useRef } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import { phoneRegExp } from "../../../helpers/Formatters";

const primaryButtonCN = cntl`
  h-10
  px-2
  rounded-md
  bg-brandGreen
  hover:bg-opacity-90
`;

const innerCN = (className) => cntl`
  ${className}
`;

const ContactInformation = ({
  contactInfo,
  onChangeContactInfo,
  userCountError,
  isContactComplete,
  handleNextBtn,
}) => {
  const inputRefs = useRef({});
  const btnRef = useRef(null);

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        const inputs = Object.keys(inputRefs.current);
        const currentInput = event.target.name;
        if (currentInput === "phone") {
          // nested conditional so that if currentInput is phone, Enter does nothing when !isComplete
          if (isContactComplete) btnRef.current.focus();
        } else {
          const nextInput = inputs[inputs.indexOf(currentInput) + 1];
          inputRefs.current[`${nextInput}`].focus();
        }
      }
    },
    [isContactComplete]
  );

  return (
    <form name="contact-information">
      <div className={innerCN("mb-4")}>
        <p className="flex text-gray-400 text-sm font-normal">
          All fields are required unless indicated as optional.
        </p>
      </div>
      <div className={innerCN("grid grid-cols-2 gap-4")}>
        <Input
          placeholder="First Name"
          label="First Name"
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          className="px-4 pb-4"
          value={contactInfo?.firstName}
          onChange={(val) => onChangeContactInfo(val, "firstName")}
          validation={yup.string().required()}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.firstName = el)}
          handleEnter={(event) => handleEnter(event)}
          name="firstName"
        />
        <Input
          placeholder="Last Name"
          label="Last Name"
          value={contactInfo?.lastName}
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          className="px-4 pb-4"
          onChange={(val) => onChangeContactInfo(val, "lastName")}
          validation={yup.string().required()}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.lastName = el)}
          handleEnter={handleEnter}
          name="lastName"
        />
      </div>
      <div className={innerCN("grid grid-cols-2 gap-4")}>
        <Input
          placeholder="Title"
          label="Title"
          value={contactInfo?.title}
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          className="px-4 pb-4"
          onChange={(val) => onChangeContactInfo(val, "title")}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.title = el)}
          handleEnter={(event) => handleEnter(event)}
          name="title"
        />
        <Input
          placeholder="Company Name"
          label="Company"
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          className="px-4 pb-4"
          value={contactInfo?.company}
          onChange={(val) => onChangeContactInfo(val, "company")}
          validation={yup.string().required()}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.company = el)}
          handleEnter={(event) => handleEnter(event)}
          name="company"
        />
      </div>
      <div className={innerCN("grid grid-cols-2 gap-4 items-start")}>
        <Input
          placeholder="Email"
          label="Email"
          value={contactInfo?.email}
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          className="px-4 pb-4"
          errorMessageClassName="whitespace-nowrap"
          onChange={(val) => onChangeContactInfo(val, "email")}
          validation={yup.string().email().required()}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.email = el)}
          handleEnter={(event) => handleEnter(event)}
          name="email"
          showValidationErrorAtBottom
        />
        <Input
          placeholder="Phone Number"
          label="Phone"
          mainClassName="col-span-12 xl:col-span-5 text-xs sm:text-base"
          value={contactInfo?.phone}
          className="px-4 pb-4"
          errorMessageClassName="whitespace-nowrap"
          onChange={(val) => onChangeContactInfo(val, "phone")}
          validation={yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required()}
          // eslint-disable-next-line no-return-assign
          forwardedRef={(el) => (inputRefs.current.phone = el)}
          handleEnter={(event) => handleEnter(event)}
          name="phone"
          showValidationErrorAtBottom
          isPhoneNumber
        />
      </div>
      <div className={innerCN("my-4 mr-6 justify-end")}>
        {!userCountError && (
          <div className="flex flex-col">
            <div className="flex justify-end py-2">
              <BaseButton
                style={{ minWidth: "200px" }}
                title="Next"
                className={primaryButtonCN}
                ref={btnRef}
                onClick={handleNextBtn}
                disabled={!isContactComplete}
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) => (btnRef.current = el)}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

ContactInformation.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
  }),
  onChangeContactInfo: PropTypes.func,
  userCountError: PropTypes.string,
  isContactComplete: PropTypes.bool,
  handleNextBtn: PropTypes.func,
};

ContactInformation.defaultProps = {
  contactInfo: undefined,
  onChangeContactInfo: undefined,
  userCountError: undefined,
  isContactComplete: false,
  handleNextBtn: undefined,
};

export default ContactInformation;
