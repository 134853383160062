import { capitalize } from "lodash";
import moment from "moment-timezone";
import {
  MOMENT_UTC_ES_FORMAT,
  SUBMITTAL_COMPLETED_OPEN,
  SUBMITTAL_COMPLETED_RESOLVED,
  SUBMITTAL_DRAFT,
  SUBMITTAL_FORMATED_STATUS,
  SUBMITTAL_IN_PROGRESS,
  SUBMITTAL_VOID,
} from "../constants";
import checkIfUTC from "./Date/checkIfUTC";
import formatIsoToUtc from "./Date/formatIsoToUtc";
import { resolveFileRefByQuery, resolvePrimaryImage } from "./File";
import { getRecurrenceStringWithTimezone } from "./rRule";
import { getFullRrule } from "./Time";
import { wfAllStepsCompleted } from "./worflowStepHelpers";

export const numbersOnly = (value) => /^\d+$/.test(value);

export const getSpaceConfiguration = (managementConfiguration) => ({
  spaces: managementConfiguration?.management?.propertySpace?.types
    ?.filter((type) => type?.selected)
    ?.reduce((dict, item) => {
      // eslint-disable-next-line no-param-reassign
      dict[item?.id] = item;
      return dict;
    }, {}),
  levels: managementConfiguration?.management?.propertyLevel?.types
    ?.filter((type) => type?.selected)
    ?.reduce((dict, item) => {
      // eslint-disable-next-line no-param-reassign
      dict[item?.id] = item;
      return dict;
    }, {}),
});

export const formatErrorResponse = (err) => {
  return err?.response?.data?.issues?.[0]?.detail?.display;
};

export const formatCurrency = (number) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(!number || Number.isNaN(number) ? 0 : number);
};

export const formatPercentage = (number) => {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(!number || Number.isNaN(number) ? 0 : number / 100);
};

export const formatNumber = (value) => {
  let val = parseFloat(value);
  if (Number.isNaN(val)) {
    val = 0;
  }
  return val;
};

export const transformIntoPositiveFloat = (val) => {
  return val ? parseFloat(val < 0 ? val * -1 : val) : undefined;
};

export const transformCurrencyIntoPositiveFloat = (newVal, oldVal) => {
  const tempAmount = String(newVal).replaceAll(",", "");
  const regex = new RegExp(/^\d*\.?\d*$/);
  if (newVal) {
    if (regex.test(tempAmount)) {
      return tempAmount < 0 ? tempAmount * -1 : tempAmount;
    }
    return oldVal;
  }
  return undefined;
};

export const transformIntoPositiveInteger = (val) => {
  return val ? parseInt(val < 0 ? val * -1 : val, 10) : undefined;
};

export const formatValueAsOptionalFloat = (val) => {
  return val && val !== "" ? val.toLocaleString() : "";
};

export const formatWithCommasWithoutDecimal = (number) => {
  return Intl.NumberFormat().format(
    !number || Number.isNaN(number) ? 0 : Math.floor(number)
  );
};

export const formatValueAsOptionalInteger = (val) => {
  return val && val !== "" ? formatWithCommasWithoutDecimal(val) : "";
};

export const getDaysInCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const ordinalSuffix = (num) => {
  const s = ["th", "st", "nd", "rd"];
  const v = num % 100;
  return num + (s[(v - 20) % 10] || s[v] || s[0]);
};

const weekObject = {
  SU: "Sunday",
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday",
};

export const rruleToWords = (rrule) => {
  if (!rrule) {
    return "Run only once";
  }

  const rule = rrule.replace("RRULE:", "");

  const ruleObject = {};

  // convert the rrule string to array
  const ruleArray = rule.split(";");

  // convert the rrule string to object
  ruleArray.forEach((r) => {
    const keyAndValue = r.split("=");
    const [key, value] = keyAndValue;
    ruleObject[key] = value;
  });

  const ruleScheduleType = ruleObject?.FREQ;

  if (ruleScheduleType === "DAILY") {
    const { BYHOUR, BYMINUTE } = ruleObject;
    const ruleDailyValue = `${BYHOUR}:${BYMINUTE}`;

    return `Run ${ruleScheduleType.toLowerCase()} at ${ruleDailyValue}`;
  }

  if (ruleScheduleType === "WEEKLY") {
    // eslint-disable-next-line prefer-destructuring
    const ruleWeeklyValue = ruleObject?.BYDAY;
    const words = `Run ${ruleScheduleType.toLowerCase()} on ${
      weekObject[ruleWeeklyValue]
    }`;

    return words;
  }

  if (ruleScheduleType === "MONTHLY") {
    const ruleMonthlyValue = parseInt(ruleObject?.BYMONTHDAY, 10);
    const words = `Run ${ruleScheduleType.toLowerCase()} on ${ordinalSuffix(
      ruleMonthlyValue
    )}`;

    return words;
  }

  return "";
};

export const parseIntegerWithRegex = (value) => {
  return parseInt(value.replace(/[^0-9]+/g, ""), 10);
};

export const parseFloatWithRegex = (value) => {
  return parseFloat(value.replace(/[^0-9.-]+/g, ""));
};

export const parseOptionalInteger = (val) => {
  return val && val.length > 0 ? parseIntegerWithRegex(val) : val;
};

export const parseOptionalFloat = (val) => {
  return val && val.length > 0 ? parseFloatWithRegex(val) : val;
};

export const transformAsOptionalInteger = (o, v) => {
  return v === "" ? 0 : parseIntegerWithRegex(v);
};

export const transformAsOptionalFloat = (o, v) => {
  return v === "" ? 0.0 : parseFloatWithRegex(v);
};

export const formatCurrencyToFloat = (value) => {
  let val = parseFloatWithRegex(value);
  if (Number.isNaN(val)) {
    val = 0;
  }
  return val;
};

export const formatDate = (date) => moment(date).format("MMMM D, YYYY");

export const formatAddress = ({
  street,
  street2,
  city,
  state,
  zipCode,
  country,
} = {}) => {
  let addressString = "";
  if (street) {
    addressString += street;
  }
  if (street2) {
    addressString += ` ${street2}`;
  }
  if (city) {
    addressString += ` ${city}`;
  }
  if (state) {
    addressString += ` ${state}`;
  }
  if (zipCode) {
    addressString += `, ${zipCode}`;
  }
  if (country) {
    addressString += `, ${country}`;
  }
  return addressString;
};

export const blockLevelAddress = ({
  street,
  street2,
  city,
  state,
  zipCode,
  country,
} = {}) => {
  const elements = [];

  const line1 = `${street ?? ""} ${street2 ?? ""}`;
  if (line1?.trim()?.length) {
    elements.push(line1);
  }

  const line2 = [city, state, zipCode].reduce((acc, val, idx) => {
    // city
    if (idx === 0 && val) {
      return `${val},`;
    }
    // when state, zipCode has value
    if (val) {
      return `${acc} ${val}`;
    }
    // when city, state, zipCode has no value
    return acc;
  }, "");

  if (line2?.trim()?.length) {
    elements.push(line2);
  }

  const line3 = country;
  if (line3?.trim()?.length) {
    elements.push(line3);
  }
  return elements;
};

export const formatLocationTableData = (property) =>
  Promise.all(
    property?.buildings?.map((building) =>
      Promise.all(
        building.locations?.map(async (loc) => ({
          id: loc.id,
          image: (await resolvePrimaryImage(loc.images))?.thumbnailUrl,
          name: loc.name,
          property: property.title,
          building: building.name,
          level: building.levels?.find((lvl) => lvl.id === loc.level)?.name,
          buildingId: building.id,
          levelId: building.levels?.find((lvl) => lvl.id === loc.level)?.id,
          squareFootage: loc?.area?.value,
        })) ?? []
      )
    ) ?? []
  ).then((locs) => locs.flatMap((loc) => loc));

export const formatMediaData = async (media = []) => {
  const imageRefs = media?.map(({ file }) => {
    return file;
  });

  const { filesDict } = await resolveFileRefByQuery(imageRefs);
  const results = media?.map(({ file }) => {
    try {
      const fileResource = filesDict[`${file}`];
      return {
        id: file,
        src: fileResource?.contentsUrl,
        name: fileResource?.name,
        type: fileResource?.contentType,
      };
    } catch (err) {
      return undefined;
    }
  });
  return results.filter((r) => !!r);
};

export const getDefaultSubcategory = (catCode) => ({
  code: "UNCAT",
  definition: "Uncategorized",
  display: "Uncategorized",
  system: `https://cts.es-feature.io/api/Codesystem/${catCode}-subcategory`,
  version: "1.0",
});

export const formatServerErrorMessage = (err) =>
  err?.response?.data?.issues?.map(({ location }) => `${location}`).join("\n");

// tim-montague's answer on
// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export const formatCurrencyShorthand = (n) => {
  // if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `$${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `$${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `$${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `$${+(n / 1e12).toFixed(1)}T`;
  return `$${n}`;
};

/**
 * removes commas then formats currency string,
 * returns reformatted string without dollar sign
 */
export const reformatCurrencyString = (amount) => {
  if (!amount) return amount;
  const tempAmount = String(amount).replaceAll(",", "");
  if (!tempAmount || Object.is(NaN, Number(tempAmount))) {
    return amount;
  }
  return formatCurrency(tempAmount).replace("$", "");
};
/**
 * adds commas while typing
 */
export const activeCurrencyFormatter = (amount) => {
  if (!amount) return amount;
  const stringAmount = String(amount);
  if (stringAmount.slice(-1) === ".") return amount;
  const tempAmount = stringAmount.replaceAll(",", "");
  if (!tempAmount || Object.is(NaN, Number(tempAmount))) {
    return amount;
  }

  const decimal = tempAmount.split(".")[1];
  const result = formatCurrency(tempAmount).replace("$", "");
  return `${result.split(".")[0]}${decimal ? `.${decimal}` : ""}`;
};

export const subsetSort = (arr, startIndex, endIndex) => {
  const array = [...arr];

  if (!startIndex && !endIndex) {
    return array.sort((a, b) => {
      if (a?.label && b?.label) {
        return a?.label?.toString()?.localeCompare(b?.label?.toString());
      }
      return 0;
    });
  }

  // Variables to store start and end of the index range
  const l = Math.min(startIndex, endIndex);
  const r = Math.max(startIndex, endIndex);

  // Temporary array
  const temp = new Array(r - l + 1);
  temp.fill(0);
  let j = 0;
  for (let i = l; i <= r; i += 1) {
    temp[j] = array[i];
    j += 1;
  }

  // Sort the temporary array
  temp.sort((a, b) => {
    if (a?.label && b?.label) {
      return a?.label?.toString()?.localeCompare(b?.label?.toString());
    }
    return 0;
  });

  // Modifying original array with temporary array elements
  j = 0;
  for (let i = l; i <= r; i += 1) {
    array[i] = temp[j];
    j += 1;
  }

  return array.filter((item) => !!item);
};
export const getFullName = (name) => {
  if (typeof name === "string") return name;
  const newFirstName = name?.firstName
    ? name?.firstName?.[0]?.toUpperCase() + name?.firstName?.substr(1)
    : "";
  const newLastName = name?.lastName
    ? name?.lastName?.[0]?.toUpperCase() + name?.lastName?.substr(1)
    : "";
  return `${newFirstName} ${newLastName || "\u00A0"}`;
};

export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const yupEmailRegExp =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

// url must start with https:// or http://
export const urlExp =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

// can accept google.com as valid url
export const softUrlRegex = /^[a-zA-Z0-9]+(\.[a-zA-Z]{2,63})+$/;

export const isUrlValid = (url, strict = false) => {
  const result = strict ? urlExp.test(url) : softUrlRegex.test(url);
  return result;
};

export const formatPhoneNumber = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (
    !previousValue ||
    value.length > previousValue.length ||
    value.length < previousValue.length
  ) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    if (cvLength >= 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
  }
  return currentValue;
};

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatSelectUser = ({
  name,
  reference,
  avatarImage,
  avatar,
  role,
  kind,
  companyName,
  company,
  id,
} = {}) => {
  const firstName = name?.firstName;
  const lastName = name?.lastName;
  const companyValue = companyName ?? company?.value;
  return {
    label: kind === "company" ? companyValue : `${firstName} ${lastName}`,
    value: reference,
    firstName,
    lastName,
    name,
    avatar: avatarImage ? { thumbnailUrl: avatarImage } : avatar,
    id,
    role,
    kind,
    company: company?.value,
  };
};

export const capEachWord = (phrase) => {
  const phraseArr = phrase.split(" ");
  return phraseArr.length === 1
    ? capitalize(phrase)
    : phraseArr
        .map((w) => {
          if (/[^A-Za-z0-9_]/.test(w)) {
            return w;
          }
          return capitalize(w);
        })
        .join(" ");
};

export const formatNameFullFirstCap = (
  Last = "Smith",
  First = "John",
  fullLastName = true
) => {
  // format name John Smith as Smith, J. or S, John
  return fullLastName
    ? ` ${Last.toLowerCase(Last).charAt(0).toUpperCase() + Last.slice(1)}, ${
        First.toUpperCase()[0]
      }`
    : `${Last.toUpperCase()[0]}, ${
        First.toLowerCase(First).charAt(0).toUpperCase() + First.slice(1)
      }`;
};

// generate days of current month
export const DAYS_OF_CURRENT_MONTH = () => {
  const daysArray = [];
  for (let i = 1; i <= getDaysInCurrentMonth(); i += 1) {
    daysArray.push({ label: ordinalSuffix(i), value: i });
  }
  return daysArray;
};

export const rruleKeyToWeek = {
  SU: {
    label: "Sunday",
    value: "sunday",
    endOfWeekDay: "saturday",
  },
  MO: {
    label: "Monday",
    value: "monday",
    endOfWeekDay: "sunday",
  },
  TU: {
    label: "Tuesday",
    value: "tuesday",
    endOfWeekDay: "monday",
  },
  WE: {
    label: "Wednesday",
    value: "wednesday",
    endOfWeekDay: "tuesday",
  },
  TH: {
    label: "Thursday",
    value: "thursday",
    endOfWeekDay: "wednesday",
  },
  FR: {
    label: "Friday",
    value: "friday",
    endOfWeekDay: "thursday",
  },
  SA: {
    label: "Saturday",
    value: "saturday",
    endOfWeekDay: "friday",
  },
};

export const formatRecurrence = (iso, value) => {
  return `DTSTART:${iso
    .split(".")[0]
    .replace(/-/g, "")
    .replace(/:/g, "")}${value}`;
};

export const handleFormatEventPostObj = (eventForm, user, associatedRef) => {
  const invitees = eventForm.invitees
    .map((invitee) => invitee?.value ?? invitee)
    .filter((inv) => !!inv);

  const formattedObj = {
    ...eventForm,
    address: { use: "Other" },
    duration: { value: 0, typeOfDuration: "calendarDay" },
    name: eventForm.name,
    description: eventForm.description,
    startDate: `${moment(eventForm.startDate).format("YYYY-MM-DDTHH:mm:ss")}${
      eventForm.timezone.value
    }`,
    endDate: `${moment(eventForm.endDate).format("YYYY-MM-DDTHH:mm:ss")}${
      eventForm.timezone.value
    }`,
    status: "scheduled",
    association: associatedRef || eventForm.association,
    invitees,
    tags:
      eventForm?.currentTags?.map((tag) => tag?.value) || eventForm?.tags || [],
  };

  // Format Recurrence For Event Post/Patch

  if (
    formattedObj.recurrence &&
    !formattedObj?.recurrence?.value?.includes("DTSTART") &&
    typeof formattedObj?.recurrence !== "string"
  ) {
    if (formattedObj?.recurrence?.value?.includes("not repeat")) {
      delete formattedObj.recurrence;
    } else {
      formattedObj.recurrence = formatRecurrence(
        moment(formattedObj.startDate).toISOString(),
        getFullRrule(formattedObj.recurrence.value ?? formattedObj.recurrence)
          .value
      );
    }
  }

  if (formattedObj?.recurrence?.value?.includes("DTSTART")) {
    formattedObj.recurrence = formattedObj.recurrence.value;
  }

  delete formattedObj.previousEvent;
  /**
   * Event Reminders 3.6+
   */
  // const reminders = [];
  // if (eventForm?.reminders) {
  //   reminders.push({
  //     sent: moment(eventForm?.startDate)
  //       .subtract(eventForm?.reminders?.value, "minutes")
  //       .format(),
  //     sentBy: user,
  //     sentTo: invitees,
  //   });
  // }
  if (eventForm.metadata) {
    return { ...formattedObj, metadata: eventForm.metadata };
  }

  return { ...formattedObj };
};

/**
 *
 * @param {Object} task
 * @returns - Task Formatted for HTTP Operation
 */
export const formatTaskObj = (task) => {
  const {
    recurrence: recValue,
    startDate,
    endDate,
    previousTask,
    originalResource,
    currentTags,
    steps,
    ...rest
  } = task;

  const timezone = task?.timezone?.label || task?.timezone;
  // Check if the start and end dates are in UTC format
  const isStarDateUTC = checkIfUTC(startDate);
  const isEndDateUTC = checkIfUTC(endDate);

  let newStartDate = moment.tz(startDate, timezone);
  let newEndDate = moment.tz(endDate, timezone);

  // If the start or end date is not in UTC format, convert and format it to UTC
  if (!isStarDateUTC) {
    newStartDate = formatIsoToUtc(newStartDate);
  }
  if (!isEndDateUTC) {
    newEndDate = formatIsoToUtc(newEndDate);
  }

  const isAllDayEvent = task?.allDay;
  // if the event is an all day event, then set the start and end dates to the start and end of the days
  if (isAllDayEvent) {
    newStartDate = moment(newStartDate)
      .startOf("day")
      .utc()
      .format(MOMENT_UTC_ES_FORMAT);

    newEndDate = moment(newEndDate)
      .endOf("day")
      .utc()
      .format(MOMENT_UTC_ES_FORMAT);
  }

  // ensure recurrence has TZID and correct DTSTART in local time of the specified timezone
  const recurrence = getRecurrenceStringWithTimezone({
    rRule: recValue,
    startDateinIso: moment(newStartDate).toISOString(),
    timezone,
  });

  return {
    ...rest,
    invitees: task?.invitees.map((n) => n?.value ?? n),
    recurrence,
    startDate: newStartDate,
    endDate: newEndDate,
    tags: currentTags?.map((tag) => tag?.value) || task?.tags,
    // Not Allowing empty Steps -> Checking for description and sop
    steps: steps?.length
      ? steps?.filter((item) => item?.description || item?.sop)
      : [],
    timezone,
  };
};

export const removeUndefinedOrEmptyFields = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(
        ([, value]) =>
          value !== undefined && value !== "" && value !== 0 && value !== false
      )
      .map(([key, value]) => [
        key,
        value === Object(value) ? removeUndefinedOrEmptyFields(value) : value,
      ])
  );
};

export const formatRequestStatus = (submittalData) => {
  const allStepsComplete = wfAllStepsCompleted(
    submittalData?.requestWorkflow?.[0]?.steps,
    submittalData?.status
  );
  if (allStepsComplete) return "pending completion";

  switch (submittalData?.status) {
    case SUBMITTAL_DRAFT:
      return SUBMITTAL_FORMATED_STATUS?.DRAFT;
    case SUBMITTAL_IN_PROGRESS:
      return SUBMITTAL_FORMATED_STATUS?.IN_PROGRESS;
    case SUBMITTAL_COMPLETED_OPEN:
      return SUBMITTAL_FORMATED_STATUS?.COMPLETE_OPEN;
    case SUBMITTAL_COMPLETED_RESOLVED:
      return SUBMITTAL_FORMATED_STATUS?.COMPLETE_RESOLVED;
    case SUBMITTAL_VOID:
      return SUBMITTAL_FORMATED_STATUS?.VOID;
    default:
      return "";
  }
};

export const getNumericOnly = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode === 8 || charCode === 9 || charCode === 13) return true;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const extractNumbers = (inputString) => {
  if (typeof inputString !== "string") {
    return 0;
  }

  const numbersOnlyString = inputString.replace(/\D/g, "");
  const parsedString = parseInt(numbersOnlyString, 10);

  if (Number.isNaN(parsedString)) {
    return 0;
  }

  return parsedString;
};

export const findMobileValue = (contactPoints) => {
  /* This formatter is being used to filter a list, but also handle singular string values, added conditional
   * If it's an array, it will return the mobile value
   * If it's a single value it will just return that value
   */
  if (Array.isArray(contactPoints)) {
    const mobileContactPoint = contactPoints?.find(
      (cp) => cp?.system === "Phone" && cp?.use === "Mobile"
    );
    return mobileContactPoint?.value || "0";
  }
  return contactPoints;
};

export const returnFullName = (name) => {
  return `${name?.firstName} ${name?.lastName}`.toLowerCase();
};

export const capitalized = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

// Formatter for title/name check when creating Project/Property
export const cleanUpTitle = (str) =>
  str?.toLowerCase()?.replace(/\s+/g, " ")?.trim();
