import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import AssociationDropdownWithControls from "../AssociationDropdownWithControls";
import Input from "../Input/Input";
import Widget from "../Widget/Widget";
import TagsSelector from "../TagsSelector";
import GridDetailRow from "../GridDetailRow";
import TemplatesDropdown from "./TemplatesDropdown";
import DistroListSelector from "../DistroListSelector";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import IncludeInformation from "./IncludeInformation";
import RecurrenceDropdown from "../RecurrenceDropdown";
import DateRangeDropdown from "../DateRangeDropdown";
import DropdownWithCheckbox from "../DropdownWithCheckbox";
import Checkbox from "../Checkbox/Checkbox";
import formatDateRange from "../../../helpers/Date/formatDateRange";
import AlertSeverity from "../AlertSeverity";
import { PRIMARY_DARK_GREEN, REPORT_EXPORT_TYPES } from "../../../constants";

const ReportForm = ({
  reportForm,
  members,
  disableAssociationDropdown,
  associationType,
  isDistroSelectOpen,
  humanReadableRecurrence,
  assetsCategoriesData,
  includeInformationData,
  templateCheckbox,
  setTemplateCheckbox,
  setHumanReadableRecurrence,
  setDistroSelectOpen,
  setIncludeInformationData,
  setAssetsCategoriesData,
  isMultiAssociation,
  handleChangeForm,
  handleTags,
  handleDistroMembers,
  handleDateRange,
  handleApplyTemplate,
}) => {
  return (
    <>
      <Widget draggable={false} title={null} overflow className="min-w-4xl">
        <div className="grid grid-cols-2 gap-4 -mt-4">
          <GridDetailRow
            label="Report Name"
            className="items-center gap-x-2"
            isEditing
            editableComponent={
              <div className="w-72">
                <Input
                  placeholder="Name (Required)"
                  onChange={(value) => handleChangeForm("title", value)}
                  value={reportForm.title}
                  inputClassName="w-full bg-green-50 pl-4 pr-8 text-sm"
                  inputContainerClassName="bg-green-50"
                  validation={yup.string().required()}
                />
              </div>
            }
          />

          <GridDetailRow
            label="Template"
            className="items-center gap-x-2"
            isEditing
            editableComponent={
              <div className="w-72">
                <TemplatesDropdown
                  associationType={associationType}
                  lockAssociationType
                  handleApplyTemplate={handleApplyTemplate}
                  isDisabled={!reportForm?.sources?.length}
                />
              </div>
            }
          />

          <div className="border-b border-gray-450 border-opacity-50">
            <GridDetailRow
              label="Association(s)"
              className={`gap-x-2 ${
                reportForm?.sources?.length > 1 ? "" : "items-center"
              }`}
              isEditing
              hideBorder
              editableComponent={
                <div className="w-72">
                  <AssociationDropdownWithControls
                    associations={reportForm?.sources}
                    setAssociations={(value) =>
                      handleChangeForm("sources", value)
                    }
                    menuPlacement="bottom"
                    isDisabled={disableAssociationDropdown}
                    associationType={associationType}
                    isMulti={isMultiAssociation}
                  />
                </div>
              }
            />
            <span />
          </div>

          <div className="border-b border-gray-450 border-opacity-50">
            <GridDetailRow
              label="Repeat"
              className="items-center gap-x-2"
              isEditing
              hideBorder
              editableComponent={
                <div className="w-72">
                  <RecurrenceDropdown
                    recurrence={reportForm?.repeat}
                    setHumanReadableCustomRecurrence={
                      setHumanReadableRecurrence
                    }
                    setRecurrence={({ value }) =>
                      handleChangeForm("repeat", value)
                    }
                  />
                </div>
              }
            />

            {humanReadableRecurrence && (
              <div className="pb-4">
                <div className="ml-4 p-1 inline-block bg-tagGreen rounded">
                  <span className="text-sm text-primaryGreen font-light text-rigth">
                    {humanReadableRecurrence}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={reportForm?.distribution.length > 0 ? "mt-4" : ""}>
            <GridDetailRow
              label="Date Range"
              className="items-center gap-x-2"
              isEditing
              hideBorder
              editableComponent={
                <div className="w-72">
                  <DateRangeDropdown
                    dateRange={reportForm?.dateRange}
                    setDateRange={handleDateRange}
                    menuPlacement="bottom"
                  />
                </div>
              }
            />

            {reportForm?.dateRange?.option === "custom" &&
              reportForm.dateRange?.custom && (
                <div className="pb-4">
                  <div className="ml-4 p-1 inline-block bg-tagGreen rounded">
                    <span className="text-sm text-primaryGreen font-light text-rigth">
                      {formatDateRange(reportForm.dateRange)}
                    </span>
                  </div>
                </div>
              )}
          </div>

          <div className="items-center">
            <GridDetailRow
              label="Members"
              className="items-center gap-x-2"
              isEditing
              hideBorder
              editableComponent={
                <div
                  className={`-ml-11 ${
                    reportForm.distribution.length > 0 ? "" : "mt-1"
                  }`}
                >
                  <DistroListSelector
                    isDistroSelectOpen={isDistroSelectOpen}
                    members={members}
                    selectedMembersRef={reportForm.distribution}
                    setDistroSelectOpen={setDistroSelectOpen}
                    handleDistroMembers={handleDistroMembers}
                  />

                  <PlusCircleButton
                    noHover
                    title="Add"
                    className="mb-1"
                    onClick={() => setDistroSelectOpen((current) => !current)}
                    style={{ color: PRIMARY_DARK_GREEN }}
                  />
                </div>
              }
            />
            <div className="ml-4">
              <AlertSeverity
                severity="error"
                message="Information
                sharing is your responsibility, not all members may have access
                to the information within this report."
              />
            </div>
          </div>
        </div>

        <div className="border-b border-gray-450 border-opacity-50 mt-4" />

        <div className="grid grid-cols-2 gap-4 mt-2 items-center">
          <GridDetailRow
            label="Type"
            className="items-center gap-x-2"
            isEditing
            editableComponent={
              <div className="w-72">
                <DropdownWithCheckbox
                  placeholder="Select"
                  options={REPORT_EXPORT_TYPES}
                  menuPlacement="auto"
                  value={REPORT_EXPORT_TYPES.find(
                    ({ value }) => value === reportForm.outputType
                  )}
                  setValue={({ value }) =>
                    handleChangeForm("outputType", value)
                  }
                />
              </div>
            }
            hideBorder
          />

          <div className="ml-4 flex">
            <Checkbox
              label="Save as Template"
              checked={templateCheckbox}
              onChange={setTemplateCheckbox}
              labelClassName="text-base font-semibold text-primaryGreen"
            />
          </div>
        </div>

        <div className="border-b border-gray-450 border-opacity-50 mt-2 mb-4" />

        {reportForm?.sources?.length ? (
          <IncludeInformation
            associationType={associationType}
            includeInformationData={includeInformationData}
            assetsCategoriesData={assetsCategoriesData}
            setIncludeInformationData={setIncludeInformationData}
            setAssetsCategoriesData={setAssetsCategoriesData}
          />
        ) : null}

        <div className="mt-8">
          <TagsSelector
            tags={reportForm.tags}
            isEditing
            isInline
            onTagAdded={(tag) => handleTags("add", tag)}
            onTagRemoved={(tag) => handleTags("remove", tag)}
          />
        </div>
      </Widget>
    </>
  );
};

ReportForm.defaultProps = {
  reportForm: undefined,
  members: [],
  assetsCategoriesData: [],
  disableAssociationDropdown: false,
  isDistroSelectOpen: false,
  isMultiAssociation: true,
  setDistroSelectOpen: undefined,
  associationType: undefined,
  includeInformationData: undefined,
  templateCheckbox: false,
  humanReadableRecurrence: undefined,
  handleChangeForm: undefined,
  handleTags: undefined,
  handleDistroMembers: undefined,
  handleApplyTemplate: () => {},
  handleDateRange: () => {},
  setHumanReadableRecurrence: () => {},
  setIncludeInformationData: () => {},
  setAssetsCategoriesData: () => {},
  setTemplateCheckbox: () => {},
};

ReportForm.propTypes = {
  reportForm: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    outputType: PropTypes.string,
    repeat: PropTypes.string,
    dateRange: PropTypes.shape({
      option: PropTypes.string,
      custom: PropTypes.shape({}),
    }),
    distribution: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  members: PropTypes.arrayOf(PropTypes.shape({})),
  assetsCategoriesData: PropTypes.arrayOf(PropTypes.shape({})),
  disableAssociationDropdown: PropTypes.bool,
  isDistroSelectOpen: PropTypes.bool,
  isMultiAssociation: PropTypes.bool,
  templateCheckbox: PropTypes.bool,
  humanReadableRecurrence: PropTypes.string,
  setDistroSelectOpen: PropTypes.func,
  associationType: PropTypes.string,
  includeInformationData: PropTypes.shape({}),
  handleChangeForm: PropTypes.func,
  handleTags: PropTypes.func,
  handleDistroMembers: PropTypes.func,
  handleDateRange: PropTypes.func,
  handleApplyTemplate: PropTypes.func,
  setTemplateCheckbox: PropTypes.func,
  setHumanReadableRecurrence: PropTypes.func,
  setIncludeInformationData: PropTypes.func,
  setAssetsCategoriesData: PropTypes.func,
};

export default ReportForm;
