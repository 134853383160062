import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { set as _set } from "lodash";
import moment from "moment";
import { FILTER_TYPES, TABLE_COLUMN_WIDTH } from "../../../constants";
import Input from "../Input/Input";
import Table from "../Table/Table";
import ImageCell from "../Table/ImageCell";
import PropertyTableInLineForm from "./PropertyTableInLineForm";
import aggregateTableRow from "../../../helpers/Table";
import FavoriteButton from "../Buttons/FavoriteButton";
import { usePropertySwitchView } from "../../../hooks/useSwitchView";
import TagList from "../Tags/TagList";
import CardGrid from "../CardGrid/CardGrid";

const containerCN = (className) => cntl`
  bg-white
  ${className}
`;

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Type",
      value: "propertyType",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const PurePropertyTable = ({
  allowSelection,
  className,
  data,
  name,
  onAddPropertyClick,
  setProperties,
  onRowClick,
  onSaveRowClick,
  onEditRowCancelClick,
  onDeleteRowClick,
  onStartRowEditing,
  onEndRowEditing,
  propertyTypes,
  onEditSaveCallback,
  onAddSaveCallback,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  handleFavoriteClick,
  showColumnSettingsLeft,
  fileActionsIcons,
  handleRowSelect,
  hideSiteHeaderTitle,
}) => {
  const [isShowingPropertiesTable] = usePropertySwitchView();
  const tableColumns = React.useMemo(() => {
    const onValueChange = (newValue, indexToEdit, accessor) => {
      setProperties((prev) =>
        prev.map((property, index) => {
          if (index === indexToEdit) {
            return { ..._set(property, accessor, newValue) };
          }
          return property;
        })
      );
    };

    const getEditInput = (
      { value, row: { index }, column: { id }, onSaveEdit },
      altOnChange
    ) => {
      const onKeyPress = (event) => {
        if (event.key === "Enter") {
          onSaveEdit();
        }
      };

      return (
        <Input
          placeholder="Value"
          key={`${id}-${index}`}
          value={value}
          onChange={
            altOnChange
              ? (newValue) => altOnChange(newValue, index)
              : (newValue) => onValueChange(newValue, index, id)
          }
          onKeyPress={onKeyPress}
        />
      );
    };

    return [
      {
        id: "favorited",
        Header: "",
        editColumnModalHeader: "Favorite",
        isPrimary: true,
        accessor: "isFavorited",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
        width: 20,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        Cell: ({ row }) => {
          return (
            <FavoriteButton
              isSelected={row?.original?.isFavorited}
              onClick={() => {
                handleFavoriteClick(row?.original?.id);
              }}
            />
          );
        },
        Aggregated: () => null,
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => <ImageCell src={value} circled />,
        Edit: ({ value }) => <ImageCell src={value} circled />,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        isPrimary: true,
        disableSortBy: true,
        disableResizing: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Name",
        accessor: "title",
        Edit: (props) => getEditInput(props),
        isPrimary: true,
      },
      {
        Header: "Address",
        accessor: "address",
        Edit: ({ value }) => value ?? "",
        Cell: ({ value }) => <p>{value?.[0]?.street}</p>,
      },
      {
        Header: "Type",
        accessor: "propertyType",
        Cell: ({ value }) => <p>{value}</p>,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Acreage",
        accessor: "lotSize.value",
        Edit: (props) =>
          getEditInput({
            ...props,
            // eslint-disable-next-line react/prop-types
            column: { ...props.column, id: "lotSize.value" },
          }),
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "lotSize.value").toFixed(2)}</p>;
        },
      },
      {
        Header: "SF",
        accessor: "sf",
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "sf").toFixed(2)}</p>;
        },
      },
      {
        Header: "# of Bedrooms",
        accessor: "bedrooms",
        width: 200,
        aggregate: "sum",
      },
      {
        Header: "# of Bathrooms",
        accessor: "bathrooms",
        width: 200,
        aggregate: "sum",
      },
      {
        Header: "Investment Prop",
        accessor: "isInvestment",
        Cell: ({ value }) => <p>{value ? "Yes" : "No"}</p>,
        width: 200,
        Aggregated: () => null,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 200,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Date Modified",
        accessor: "lastUpdated",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MMMM Do YYYY, h:mm a")}</p>
          </div>
        ),
        width: 350,
        Aggregated: () => null,
      },
      {
        Header: "Purchase Date",
        accessor: "purchase.date",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{value ? moment(value).format("MMMM Do YYYY") : ""}</p>
          </div>
        ),
        width: 350,
        Aggregated: () => null,
        show: false,
      },
      {
        Header: "Purchase Price",
        accessor: "purchase.price",
        width: 200,
        show: false,
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "purchase.price").toFixed(2)}</p>;
        },
      },
      {
        Header: "Interest Rate",
        accessor: "interestRate",
        show: false,
      },
      {
        Header: "Mortgage",
        accessor: "mortgage",
        show: false,
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "mortgage").toFixed(2)}</p>;
        },
      },
    ];
  }, [handleFavoriteClick, setProperties]);

  return (
    <div className={containerCN(className)}>
      <Table
        cardGrid={!isShowingPropertiesTable}
        cardGridComponent={(rows) => {
          return (
            <CardGrid
              resourceName="Property"
              rows={rows}
              onClick={onRowClick}
            />
          );
        }}
        allowSelection={allowSelection}
        resourceName={name}
        className={className}
        columns={tableColumns}
        data={data}
        onRowClick={({ index }) => onRowClick(data[index]?.id)}
        onSaveRowClick={({ index }) => onSaveRowClick(index)}
        onEditRowCancelClick={onEditRowCancelClick}
        onDeleteRowClick={({ index }) => onDeleteRowClick(data[index]?.id)}
        onStartRowEditing={onStartRowEditing}
        onEndRowEditing={onEndRowEditing}
        siteHeaderCreateNewClick={onAddPropertyClick}
        inLineForm={(row, isEdit, togglePopOver) => (
          <PropertyTableInLineForm
            row={row}
            togglePopOver={togglePopOver}
            isEdit={isEdit}
            propertyTypes={propertyTypes}
            onEditSaveCallback={(property) => onEditSaveCallback(row, property)}
            onAddSaveCallback={(property) => onAddSaveCallback(row, property)}
            prefillFieldsOnAddAnother={["propertyType"]}
          />
        )}
        groupOptions={groupOptions}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showConstantRowIndex
        showColumnSettingsLeft={showColumnSettingsLeft}
        fileActionsIcons={fileActionsIcons}
        onSelectedRowChange={handleRowSelect}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        hideSiteHeaderActionButtons
        showReportButton
      />
    </div>
  );
};

PurePropertyTable.propTypes = {
  /**
   * classNames to pass to the Table
   */
  className: PropTypes.string,
  /**
   * data to pass to the table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      category: PropTypes.string,
      subcategory: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
      valuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /**
   * unique name for the table
   */
  name: PropTypes.string,
  onAddPropertyClick: PropTypes.func,
  setProperties: PropTypes.func,
  onRowClick: PropTypes.func,
  onSaveRowClick: PropTypes.func,
  onEditRowCancelClick: PropTypes.func,
  onDeleteRowClick: PropTypes.func,
  onStartRowEditing: PropTypes.func,
  onEndRowEditing: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  onAddSaveCallback: PropTypes.func,
  propertyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  updateUserTemplateSettings: PropTypes.func,
  deleteUserTemplateSettings: PropTypes.func,
  handleFavoriteClick: PropTypes.func,
  handleRowSelect: PropTypes.func,
  hideSiteHeaderTitle: PropTypes.bool,
  allowSelection: PropTypes.bool,
  showColumnSettingsLeft: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
};

PurePropertyTable.defaultProps = {
  className: undefined,
  data: undefined,
  name: undefined,
  onAddPropertyClick: undefined,
  setProperties: undefined,
  onRowClick: undefined,
  onSaveRowClick: undefined,
  onEditRowCancelClick: undefined,
  onDeleteRowClick: undefined,
  onStartRowEditing: undefined,
  onEndRowEditing: undefined,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  propertyTypes: [],
  templateSettings: [],
  updateUserTemplateSettings: undefined,
  deleteUserTemplateSettings: undefined,
  handleFavoriteClick: undefined,
  handleRowSelect: () => {},
  hideSiteHeaderTitle: false,
  allowSelection: false,
  showColumnSettingsLeft: false,
  fileActionsIcons: undefined,
};

export default PurePropertyTable;
