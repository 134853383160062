import { ConfigurationAPI } from "@griffingroupglobal/eslib-api";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import moment from "moment";
import { useAppState } from "../state/appState";
import {
  COA_CODE_TYPES,
  SET_FINANCIALS_CONFIGURATION,
  SET_PAYROLL_PERIOD,
} from "../constants";
import { getLatestTime } from "../helpers/Time";
import { configurationKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

const { TimeSheets } = TimesheetUtil;

const fetchTimesheetConfiguration = async () => {
  const { data } = await ConfigurationAPI.getById("financials");

  // Create kep-value pairs for fast search
  const csiCodeMappingObject = {};

  const revenueAccountingCodes = data?.financials?.chartOfAccounts?.filter(
    (code) => code.codeType === COA_CODE_TYPES[1].value
  );

  const expenseAccountingCodes = data?.financials?.chartOfAccounts?.filter(
    (code) => code.codeType === COA_CODE_TYPES[0].value
  );

  data?.financials?.csiCodeMapping.forEach((div) => {
    div?.csiCodes.forEach((csi) => {
      csi?.subCodes.forEach((sub) => {
        const finalKey = `${div.division} ${csi.code} ${sub.code}`;
        let finalDescription = sub.description;
        if (sub.revenueCode) {
          finalDescription = revenueAccountingCodes?.find(
            (revenueCode) => revenueCode.code === sub?.revenueCode
          )?.description;
        } else if (sub.expenseCode) {
          finalDescription = expenseAccountingCodes?.find(
            (expenseCode) => expenseCode.code === sub?.expenseCode
          )?.description;
        }
        csiCodeMappingObject[finalKey] = finalDescription;
      });
    });
  });

  const divisionsObject = {};
  data?.financials?.csiCodeMapping?.forEach((div) => {
    divisionsObject[div.division] = `${div.division} - ${div.description}`;
  });

  return {
    ...data,
    financials: {
      ...data?.financials,
      csiCodeMappingObject,
      divisionsObject,
    },
  };
};

export default () => {
  const [{ configurationLastUpdated }, dispatch] = useAppState();

  return useAuthenticatedQuery({
    queryKey: configurationKeys("financials"),
    queryFn: fetchTimesheetConfiguration,
    placeholderData: {},
    onError: (error) => console.error(":x: useFinancialsConfiguration", error),
    onSuccess: (data) => {
      dispatch({
        type: SET_FINANCIALS_CONFIGURATION,
        financialsConfiguration: {
          financials: {
            ...data?.financials,
          },
        },
        configurationLastUpdated: getLatestTime(
          configurationLastUpdated,
          data.metadata.lastUpdated
        ),
      });

      // do not set payroll timesheet and payroll periods if they havent been
      // set by user yet. Default is an empty string. We cant use "locked" because
      // thats what is set after the first timesheet was saved
      if (
        data?.financials?.payroll?.period &&
        data?.financials?.period?.start
      ) {
        // this function cannot handle any falsey value for period and start
        // ex: empty string, null, undefined, false etc.
        const payrollPeriodFormatted = TimeSheets.getPeriodFrame(
          data?.financials?.payroll?.period,
          data?.financials?.period?.start,
          moment().format("MM/DD/YYYY")
        );

        dispatch({
          type: SET_PAYROLL_PERIOD,
          payrollPeriod: payrollPeriodFormatted,
        });
      }
    },
  });
};
