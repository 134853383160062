import moment from "moment-timezone";
import { RRule } from "rrule";

// rrule utc to local conversions (to fix daylight and byweekday)
const addMinutes = (dt, offset) =>
  new Date(dt.setMinutes(dt.getMinutes() + offset));

const startDateInRRuleInput = (date, timezone) => {
  // if no timezone, just return the date as-is
  if (!timezone) {
    return date;
  }

  const offset = moment.tz(date, "YYYY-MM-DDTHH:mm:ssZ", timezone).utcOffset();

  return addMinutes(new Date(date), offset);
};

/**
 *
 * @param {Object} rRule - string or object with value as a property
 * @param {String} startDateinIso - date in ISO format
 * @param {String} timezone - timezone in string
 * @returns {String} rrule string
 */
const getRecurrenceStringWithTimezone = ({
  rRule,
  startDateinIso,
  timezone,
}) => {
  const rRuleString = typeof rRule === "string" ? rRule : rRule?.value;

  if (!rRuleString || rRuleString.toLowerCase().includes("does not repeat")) {
    return undefined;
  }
  const options = RRule.parseString(rRuleString);
  options.tzid = timezone;

  // sets start date in rrule corresponding to local time of specified timezone
  options.dtstart = startDateInRRuleInput(startDateinIso, timezone);

  return RRule.optionsToString(options);
};

// get the startDate based on the recurrence DTSTART and BYDAY
const isValidRecurrence = ({ rRuleString }) => {
  // calculate what the very first instance is
  // note - this is necessary b/c dtstart could fall on a diff day due to BYDAY repeating
  const rule = RRule.fromString(rRuleString);

  // date coming back from rule.after() needs to be converted to UTC
  const firstInstance = rule.after(new Date(rule.options.dtstart), true);

  return !!firstInstance;
};

export { getRecurrenceStringWithTimezone, isValidRecurrence };
