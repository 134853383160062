import { useReducer } from "react";
import { cloneDeep } from "lodash";

const defaultState = {};

const reducer = (subscription, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.subscription) ?? defaultState;
    case "contactFirstName":
      return {
        ...subscription,
        contactFirstName: action.value,
      };
    case "contactLastName":
      return {
        ...subscription,
        contactLastName: action.value,
      };
    case "contactTitle":
      return {
        ...subscription,
        contactTitle: action.value,
      };
    case "contactCompany":
      return {
        ...subscription,
        contactCompany: action.value,
      };
    case "contactEmail":
      return {
        ...subscription,
        contactEmail: action.value,
      };
    case "contactPhone":
      return {
        ...subscription,
        contactPhone: action.value,
      };
    case "nameOnCard":
      return {
        ...subscription,
        nameOnCard: action.value,
      };
    case "cardType":
      return {
        ...subscription,
        cardType: action.value,
      };
    case "billingCountry":
      return {
        ...subscription,
        billingCountry: action.value,
      };
    case "billingAddress1":
      return {
        ...subscription,
        billingStreet: action.value,
      };
    case "billingAddress2":
      return {
        ...subscription,
        billingStreet2: action.value,
      };
    case "billingCity":
      return {
        ...subscription,
        billingCity: action.value,
      };
    case "billingState":
      return {
        ...subscription,
        billingState: action.value,
      };
    case "billingZip":
      return {
        ...subscription,
        billingZipCode: action.value,
      };
    case "planName":
      return {
        ...subscription,
        planName: action.value,
      };
    case "userCount":
      return {
        ...subscription,
        userCount: action.value,
      };
    case "userText":
      return {
        ...subscription,
        userText: action.value,
      };
    case "billingCycle":
      return {
        ...subscription,
        billingCycle: action.value,
      };
    case "nextPayment":
      return {
        ...subscription,
        nextPayment: action.value,
      };
    case "nextPaymentDate":
      return {
        ...subscription,
        nextPaymentDate: action.value,
      };
    case "cardNumber":
      return {
        ...subscription,
        cardNumber: action.value,
      };
    case "expiration":
      return {
        ...subscription,
        expiration: action.value,
      };
    case "cvc":
      return {
        ...subscription,
        cvc: action.value,
      };
    case "subscriptionItem":
      return {
        ...subscription,
        subscriptionItem: action.value,
      };
    case "subscriptionStatus":
      return {
        ...subscription,
        subscriptionStatus: action.value,
      };
    default:
      return subscription;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};
