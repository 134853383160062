import { EventAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment-timezone";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { filePaginatedKeys } from "../../../../config/reactQuery/queryKeyFactory";
import { uploadFileWithData } from "../../../../helpers/File";
import { guessTimeZone } from "../../../../helpers/Time";
import useEsEvents from "../../../../hooks/useEsEvents";
import { toastError, toastMessage } from "../../Toast/Toast";
import determineTimeSpan from "../../../../helpers/Date/determineTimeSpan";
import checkIfUTC from "../../../../helpers/Date/checkIfUTC";
import formatIsoToUtc from "../../../../helpers/Date/formatIsoToUtc";
import { EVENT_EMPTY, MOMENT_UTC_ES_FORMAT } from "../../../../constants";
import {
  getRecurrenceStringWithTimezone,
  isValidRecurrence,
} from "../../../../helpers/rRule";

const useEventEditCreateModalData = ({ modalData }) => {
  const queryClient = useQueryClient();
  const { addEventToDictionaries } = useEsEvents();
  // get the current time and round up to the next half hour
  let initStart =
    moment().minutes() > 30
      ? moment().startOf("hour").add(30, "minutes").format()
      : moment().startOf("hour").format();

  // if an ISO date is passed in, use that as the start date
  if (modalData.dateISO) {
    const dateFromDateISO = modalData.dateISO.split("T")[0];
    const timeFromInitStart = initStart.split("T")[1];
    initStart = `${dateFromDateISO}T${timeFromInitStart}`;
  }
  // get the end date based on the start date
  const getEndDate = (start) => moment(start).add(30, "minutes").format();

  // set the default state for formState
  const defaultState = {
    name: "",
    description: "",
    startDate: initStart,
    endDate: getEndDate(initStart),
    status: "scheduled",
    invitees: [],
    association: "",
    projects: [],
    properties: [],
    files: [],
    recurrence: "",
    tags: [],
    isAllDay: false,
    spaces: [],
    links: [],
    // default to current TZ
    timezone: guessTimeZone()?.label,
  };

  const initEvent = useMemo(
    () => ({
      ...EVENT_EMPTY,
      startDate: initStart,
      endDate: getEndDate(initStart),
    }),
    [initStart]
  );

  const [formState, setFormState] = useState(
    modalData?.formData || defaultState
  );

  const [showConfirm, setShowConfirm] = useState(false);

  const updateFormState = (value) => {
    setFormState({ ...formState, ...value });
  };

  const toggleAllDay = () => {
    updateFormState({
      isAllDay: !formState.isAllDay,
      allDay: !formState.isAllDay,
    });
  };

  useEffect(() => {
    const isSame = isEqual(initEvent, formState);
    setShowConfirm(!isSame);
  }, [initEvent, formState]);

  const allDayData = { isAllDay: formState.isAllDay, toggleAllDay };

  const onAddAttachment = async (photo, data = {}, progressCallback) => {
    try {
      const fileResource = await uploadFileWithData(
        photo,
        data,
        progressCallback,
        undefined,
        true,
        undefined,
        true
      );
      return fileResource;
    } catch (error) {
      return error;
    }
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    try {
      const result = await Promise.all(
        files.map(async ({ name, docType, isFavorited, original }) => {
          const data = {
            name,
            docType,
            isFavorited,
            contentType: original.type,
            size: original.size,
          };
          const resource = await onAddAttachment(
            original,
            data,
            (loaded, total) => handleProgressCallback(loaded, total, name)
          );

          return { ...data, ...resource };
        })
      );

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = formState?.files?.reduce(
      (list, file) => {
        if (!file?.isEditing && file?.type) {
          list.latest.push(file);
          return list;
        }
        list.previous.push(file);
        return list;
      },
      { latest: [], previous: [] }
    );

    try {
      const res = await onUpload(filteredFiles.latest, () => {});

      // update files in overview
      queryClient.invalidateQueries(filePaginatedKeys.allFiles);

      return [...filteredFiles.previous, ...res];
    } catch (error) {
      return [];
    }
  }, [formState?.files, onUpload, queryClient]);

  const handleEventSubmit = async () => {
    try {
      const newEventObject = JSON.parse(JSON.stringify(formState));
      // Check if the start and end dates are in UTC format
      const isStarDateUTC = checkIfUTC(formState.startDate);
      const isEndDateUTC = checkIfUTC(formState.endDate);

      let newStartDate = moment.tz(formState.startDate, formState.timezone);
      let newEndDate = moment.tz(formState.endDate, formState.timezone);

      // If the start or end date is not in UTC format, convert and format it to UTC
      if (!isStarDateUTC) {
        newStartDate = formatIsoToUtc(newStartDate);
      }
      if (!isEndDateUTC) {
        newEndDate = formatIsoToUtc(newEndDate);
      }

      const isAllDayEvent = newEventObject?.isAllDay || newEventObject.allDay;
      // if the event is an all day event, then set the start and end dates to the start and end of the days
      if (isAllDayEvent) {
        newEventObject.allDay = true;
        newStartDate = moment(newStartDate)
          .startOf("day")
          .utc()
          .format(MOMENT_UTC_ES_FORMAT);

        newEndDate = moment(newEndDate)
          .endOf("day")
          .utc()
          .format(MOMENT_UTC_ES_FORMAT);
      } else {
        newEventObject.allDay = false;
      }

      newEventObject.startDate = newStartDate;
      newEventObject.endDate = newEndDate;
      // remove the isAllDay property from the object before sending to the API
      delete newEventObject.isAllDay;

      const formattedTags = newEventObject.tags.map((tag) => tag.value);
      newEventObject.tags = formattedTags;

      // ensure recurrence has TZID and correct DTSTART in local time of the specified timezone
      newEventObject.recurrence = getRecurrenceStringWithTimezone({
        rRule: formState.recurrence,
        startDateinIso: moment(newEventObject.startDate).toISOString(),
        timezone: newEventObject.timezone,
      });

      if (newEventObject.recurrence) {
        // check if first recurring instance start date < until
        const isValid = isValidRecurrence({
          rRuleString: newEventObject.recurrence,
        });

        if (!isValid) throw Error("Invalid custom repeat detected.");
      }

      if (newEventObject.files.length > 0) {
        const uploads = await handleFilesUploaded();

        newEventObject.files = uploads.map((file) => ({
          ref: file.reference || file.ref,
          category: file.category,
        }));
      }
      const newEventResponse = await EventAPI.post(newEventObject);
      const newEvent = { ...newEventResponse.data };

      if (isAllDayEvent || newEvent?.allDay) {
        const { startDate, endDate } = newEvent;
        const timeSpan = determineTimeSpan(startDate, endDate);
        newEvent.daySpan = timeSpan;
      }

      addEventToDictionaries(newEvent);
      toastMessage("Event created successfully");
    } catch (error) {
      toastError(
        `There was an error creating the event. ${
          error.message && error.message
        }`
      );
      console.error("Error creating Event, useCreateEventModalData.js", error);
    }
  };

  const formIsValid = useMemo(() => {
    const { association, name } = formState;
    return !!association && association !== "" && name !== "";
  }, [formState]);

  return {
    showConfirm,
    formState,
    formIsValid,
    allDayData,
    handleEventSubmit,
    updateFormState,
  };
};

export default useEventEditCreateModalData;
