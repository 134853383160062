// Import necessary constants and utilities
import { useEffect, useRef, useState } from "react";
import { TOGGLE_POSITIONED_POPUP } from "../../../constants";

import useURLChange from "../../../hooks/useURLChange";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useAppState } from "../../../state/appState";

// Custom hook to calculate and provide mouse alert positioned data
const usePopupData = (containerRef) => {
  // Retrieve relevant state and dispatch function using the app state hook
  const [{ popupData }, dispatch] = useAppState();
  const currentURL = useURLChange();
  const { height: innerHeight, width: innerWidth } = useWindowDimensions();
  const [popupHeight, setPopupHeight] = useState(0);
  const [popupWrapperStyles, setPopupWrapperStyles] = useState();
  // keeps track of whether the component is being rendered for the first time
  const initialRender = useRef(true);

  // need timeout for containerRef to be available
  if (!popupHeight) {
    setTimeout(() => {
      const height = containerRef?.current?.clientHeight;
      setPopupHeight(height);
    }, 1000);
  }

  // TODO: ResizeObserver was not working but not removing until as the current solution
  // is not the best one as it uses 1 second timeout to show popup
  // useEffect(() => {
  //   // when the container ref is available, get the height of the containers
  //   const container = containerRef.current;
  //   if (!container) return;

  //   // Create a flag to track whether the height has been updated
  //   let hasHeightUpdated = false;

  //   // use an observer to monitor size changes
  //   const observer = new ResizeObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.target === container && !hasHeightUpdated) {
  //         setPopupHeight(entry.contentRect.height);
  //         hasHeightUpdated = true; // Set the flag to true after the first change
  //         observer.disconnect(); // Disconnect the observer
  //       }
  //     });
  //   });

  //   // set observer to monitor the container
  //   observer.observe(container);

  //   // eslint-disable-next-line consistent-return
  //   return () => {
  //     observer.unobserve(container);
  //   };
  // }, [containerRef, popupData]);

  useEffect(() => {
    if (initialRender.current) {
      // Skip the effect logic for the first render
      initialRender.current = false;
      return;
    }
    // Close the popup when the URL changes
    dispatch({
      type: TOGGLE_POSITIONED_POPUP,
      popupType: null,
    });
  }, [dispatch, currentURL]);

  useEffect(() => {
    // Extract position from the state, if available
    const positionX = popupData?.position.x;
    const positionY = popupData?.position.y;

    // Define dimensions of the popup
    const popupWidth =
      popupData?.popupData?.popupWidth || containerRef?.current?.clientWidth;

    // Calculate adjusted left position of the popup
    let adjustedLeft;

    if (positionX + popupWidth > innerWidth) {
      // If popup exceeds right boundary of the window, adjust to the left
      adjustedLeft = positionX - popupWidth;
    } else if (positionX < 0) {
      // If popup exceeds left boundary of the window, keep it at the leftmost
      adjustedLeft = 0;
    } else {
      // Otherwise, position it at the mouse's X coordinate
      adjustedLeft = positionX;
    }

    // Calculate adjusted top position of the popup
    let adjustedTop;

    if (positionY + popupHeight > innerHeight) {
      // If popup exceeds bottom boundary of the window, position it near the top
      adjustedTop = innerHeight - popupHeight - innerHeight * 0.1;
    } else if (positionY < 0) {
      // If popup exceeds top boundary of the window, keep it at the topmost
      adjustedTop = 0;
    } else {
      // Otherwise, position it at the mouse's Y coordinate
      adjustedTop = positionY;
    }

    // If the popup is to be centered, calculate the center position
    if (!positionX && !positionY && popupData?.position.centered) {
      adjustedLeft = (innerWidth - popupWidth) / 2;
      adjustedTop = (innerHeight - popupHeight) / 2;
    }

    // Define styles for the positioned popup
    const style = {
      container: {
        position: "fixed",
        left: `${adjustedLeft}px`,
        top: `${adjustedTop}px`,
        zIndex: 10000, // Ensure the popup is on top of other content
        // (e.g. custom recurrence popup needs to be over edit event modal)
        visibility: popupHeight > 0 ? "visible" : "hidden", // hide popover until the best height is determined
      },
      body: {
        backgroundColor: "white",
        overflow: "auto",
      },
    };

    setPopupWrapperStyles(style);
  }, [
    containerRef,
    innerHeight,
    innerWidth,
    popupData?.popupData?.popupWidth,
    popupData?.position.centered,
    popupData?.position.x,
    popupData?.position.y,
    popupHeight,
  ]);

  // Function to close the popup
  const closePopup = () => {
    dispatch({
      type: TOGGLE_POSITIONED_POPUP,
      popupType: null,
    });
  };

  // Return relevant data and functions to the caller
  return { popupWrapperStyles, popupData, closePopup };
};

// Export the custom hook for external use
export default usePopupData;
