import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Modal from "../../stories/Components/Modal/Modal";
import { PROJECT_TABLE_PATH } from "../../constants";

export default function ProjectDeleteModal({
  project,
  showDeleteModal,
  setShowDeleteModal,
  removeProject,
}) {
  const history = useHistory();

  const onDeleteClicked = () => {
    setShowDeleteModal(false);
    removeProject(project?.id);
    history.push(PROJECT_TABLE_PATH);
  };

  return (
    <Modal
      title="Delete Project?"
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {project?.name}? Once deleted, this
          property and its data cannot be recovered.
        </p>
      </>
    </Modal>
  );
}

ProjectDeleteModal.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
  removeProject: PropTypes.func,
};

ProjectDeleteModal.defaultProps = {
  project: null,
  showDeleteModal: false,
  setShowDeleteModal: () => {},
  removeProject: () => {},
};
