import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import useSopHistory from "../../../../hooks/useSopHistory";
import { useUsers } from "../../../../hooks/useUsers.new";
import { useSopById } from "../../../../hooks/useSopById";

const useSopHistoryData = (currentSop) => {
  const history = useHistory();

  const sopId = currentSop?.id;
  const currentVersion = currentSop?.version;

  const { data: sopHistory = [], isLoading } = useSopHistory(sopId);
  const { data: usersData } = useUsers();

  const userDict = usersData?.userDict;

  // Initializes state to previuos sop version
  const [version, setVersion] = useState(currentVersion - 1);

  // Download specific sop version
  const { data: sopVersioned } = useSopById(sopId, version);

  const [sopExpandedRowsMap, setSopExpandedRowsMap] = useState({
    [version]: { isExpanded: true },
  });

  // sop versions
  const versions = useMemo(() => {
    const versionsList = sopHistory?.[0]?.versions;

    if (!versionsList) return [];

    // Don't return the current version
    return versionsList.slice(1);
  }, [sopHistory]);

  useEffect(() => {
    if (!sopVersioned) return;

    setSopExpandedRowsMap((prev) => {
      const index = sopVersioned.version;

      return {
        ...prev,
        [index]: {
          ...prev[index],
          data: sopVersioned,
        },
      };
    });
  }, [sopVersioned]);

  /**
   * Toggles the selected version based on the row and enabled the version hook to download specific sop version.
   * @param {number} index - index of row clicked
   */
  const handleRowSelected = (index) => {
    // Set selected version if the row is expanded
    if (!sopExpandedRowsMap[index]?.isExpanded) {
      setVersion(index);
    } else {
      setVersion(null);
    }

    setSopExpandedRowsMap((prev) => ({
      ...prev,
      [index]: { isExpanded: !prev[index]?.isExpanded },
    }));
  };

  /**
   * Return formatted given date
   */
  const getDateFormatted = useCallback((stringDate) => {
    return moment(stringDate).format("MMMM DD, YYYY, hh:mm A");
  }, []);

  /**
   * Return formatted user name
   */
  const getUpdatedBy = useCallback(
    (userRef) => {
      if (userDict?.[userRef]) {
        const { firstName, lastName } = userDict[userRef].name;
        return `${firstName} ${lastName}`;
      }

      return "";
    },
    [userDict]
  );

  /**
   * Return `media` and `nonMedia` files for given sop version
   */
  const getFiles = useCallback((sop) => {
    const files = sop && sop?.files ? sop.files : [];

    const { media = [], nonMedia = [] } = files.reduce(
      (acc, next) => {
        if (next.category === "Documents") {
          acc.nonMedia.push(next);
        } else {
          acc.media.push(next);
        }
        return acc;
      },

      { media: [], nonMedia: [] }
    );

    return { media, nonMedia };
  }, []);

  /**
   * Navigates to given sop version page
   */
  const handleNavigateToSop = useCallback(
    (sop) => {
      history.push(`${sopId}?version=${sop.version}`);
    },
    [history, sopId]
  );

  return {
    versions,
    sopExpandedRowsMap,
    isLoading,
    handleRowSelected,
    handleNavigateToSop,
    getDateFormatted,
    getUpdatedBy,
    getFiles,
  };
};

export default useSopHistoryData;
