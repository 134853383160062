import React, { useCallback, useEffect, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Spinner from "../Spinner/Spinner";
import BaseButton from "../Buttons/BaseButton";
import Checkbox from "../Checkbox/Checkbox";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import Modal from "../Modal/Modal";
import { SIGNUP_CONFIRMATION_PATH } from "../../../constants";
import { formatCountriesDropdown } from "../../../helpers/Address";

const countryList = require("country-list");

const primaryButtonCN = cntl`
  h-10
  px-2
  rounded-md
  bg-brandGreen
  hover:bg-opacity-90
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const modifyCN = cntl`{
  mb-1
  text-brandGreen
  font-semibold
  text-sm
`;

const ReviewOrder = ({
  selectedPlan,
  userCount,
  frequency,
  total,
  contactInfo,
  billingInfo,
  setOrderDetails,
  setToggleReviewSelection,
  setTogglePlanSelection,
  setToggleContactSelection,
  setToggleBillingSelection,
  isPlanComplete,
  isBillingComplete,
  isContactComplete,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [TNC, setTNC] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    const countries = countryList.getNameList();

    const list = formatCountriesDropdown(countries);

    setDropdownCountries(list);
  }, []);

  const submitOrder = useCallback(async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const tenant = {
      espaceName: contactInfo.firstName,
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
      phone: contactInfo.phone,
      email: contactInfo.email,
      company: contactInfo.company,
      stripe: {},
      numUsers: userCount,
      title: contactInfo?.title,
      address: {
        street: billingInfo?.street,
        street2: billingInfo?.street2,
        city: billingInfo?.city,
        state: billingInfo?.state,
        country: billingInfo.country.label,
        zipCode: billingInfo.zipCode,
      },
    };

    const stripeAddress = {
      line1: billingInfo?.street,
      line2: billingInfo?.street2,
      city: billingInfo?.city,
      state: billingInfo?.state,
      country:
        dropdownCountries.find(
          (country) => country.label === billingInfo.country.label
        )?.countryCode || billingInfo.country.countryCode,
      postal_code: billingInfo.zipCode,
    };

    const handleClientSecretCreation = async () => {
      // creates customerID needed for subscription
      const customerCreationResponse = await SignUpAPI.handleCustomerCreation({
        name: `${contactInfo.firstName} ${contactInfo.lastName}`,
        address: stripeAddress,
        email: contactInfo.email,
        phone: contactInfo.phone,
        metadata: {
          company: contactInfo.company,
          title: contactInfo?.title,
        },
      });

      tenant.stripe.customerId = customerCreationResponse?.id;

      // creates clientSecret for Stripe validation
      const clientSecretCreation = await SignUpAPI.handleClientSecretCreation({
        customerCreationResponse,
        priceId:
          frequency === "monthly"
            ? selectedPlan.priceIdMonthly
            : selectedPlan.priceIdAnnual,
        userCount,
      });
      tenant.stripe.subscriptionId = clientSecretCreation?.subscriptionId;
      return clientSecretCreation;
    };

    const createTenant = async () => {
      await SignUpAPI.createTenant(tenant);

      setToggleReviewSelection((prev) => !prev);

      setOrderDetails({
        selectedPlan,
        userCount,
        frequency,
        total,
        contactInfo,
        billingInfo,
      });
      setErrorMessage(null);
      history.push(SIGNUP_CONFIRMATION_PATH);
    };

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      // create card
      const payment = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: billingInfo.name,
          address: stripeAddress,
          email: contactInfo.email,
          phone: contactInfo.phone,
        },
      });

      if (payment?.error) {
        throw new Error(payment?.error?.message);
      }

      const clientSecretCreation = await handleClientSecretCreation();

      if (clientSecretCreation?.clientSecret) {
        // if no free trial, process payment now
        // add card to customer
        await SignUpAPI.updatePaymentCard(
          tenant.stripe.customerId,
          payment?.paymentMethod?.id
        );

        // processes payment via Stripe
        const stripeResponse = await stripe.confirmCardPayment(
          clientSecretCreation.clientSecret,
          {
            payment_method: {
              card: cardNumberElement,
              // can add receipt_email field
              billing_details: {
                name: billingInfo.name,
                address: stripeAddress,
                email: contactInfo.email,
                phone: contactInfo.phone,
              },
            },
          }
        );
        tenant.stripe.paymentIntentId = stripeResponse?.paymentIntent?.id;

        if (stripeResponse?.paymentIntent?.status === "succeeded") {
          await createTenant(tenant);
        } else {
          setErrorMessage(stripeResponse?.error?.message);
          setIsErrorModalOpen(true);
        }
      } else {
        // for free trials, add card for future payments
        // add card to customer
        await SignUpAPI.updatePaymentCard(
          tenant.stripe.customerId,
          payment?.paymentMethod?.id
        );

        await createTenant(tenant);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(
        "Error processing the request. Please try again with valid data."
      );
      setIsErrorModalOpen(true);
    }
    setIsLoading(false);
  }, [
    billingInfo,
    contactInfo,
    dropdownCountries,
    elements,
    frequency,
    history,
    selectedPlan,
    setOrderDetails,
    setToggleReviewSelection,
    stripe,
    total,
    userCount,
  ]);

  return (
    <>
      <div className={innerCN("mb-4")}>
        <p className="text-gray-400 text-sm font-normal">
          Please review your information before finalizing your order.
        </p>
      </div>
      <div className={innerCN("flex-col xl:flex-row")}>
        <div className="flex flex-col pt-4 px-4 w-full xl:w-1/2 border-b xl:border-b-0 xl:border-r">
          <div className="mb-4">
            <p className="text-gray-400 font-semibold text-base">
              Plan Selection
            </p>
          </div>
          <div className="mb-">
            <p className="font-semibold text-base">
              {selectedPlan.planName.toUpperCase()}
            </p>
          </div>
          <div className="mb-4">
            <p className="text-gray-400 text-base inline-block">
              {userCount} users |
            </p>
            <p className="text-gray-400 text-base capitalize inline-block px-1">
              {frequency}
            </p>
            <p className="text-gray-400 text-base inline-block">
              ($
              {frequency === "monthly"
                ? selectedPlan.monthlyCost
                : selectedPlan.annualCost}{" "}
              per user/month)
            </p>
          </div>
          <div className="mb-4">
            <p className="text-base font-semibold">Total Due: ${total}</p>
          </div>
          <div
            onClick={() => setTogglePlanSelection((prev) => !prev)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setTogglePlanSelection((prev) => !prev)}
            className="mb-4"
          >
            <HashLink
              className={modifyCN}
              to="#planSelection"
              smooth
              replace
              duration={100}
            >
              Modify
            </HashLink>
          </div>
        </div>
        <div className="flex flex-col pt-4 w-full xl:w-1/2 pl-4 xl:pl-8">
          <div className={innerCN("mb-4")}>
            <p className="flex text-gray-400 font-semibold text-base">
              Contact Information
            </p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">
              {contactInfo.firstName} {contactInfo.lastName}
            </p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">{contactInfo.title}</p>
          </div>
          <div className="mb-4">
            <p className="text-gray-400 text-base">{contactInfo.company}</p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">{contactInfo.email}</p>
          </div>
          <div className="mb-4">
            <p className="text-gray-400 text-base">{contactInfo.phone}</p>
          </div>
          <div
            onClick={() => setToggleContactSelection((prev) => !prev)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setToggleContactSelection((prev) => !prev)}
            className="mb-4"
          >
            <HashLink
              className={modifyCN}
              to="#planSelection"
              smooth
              replace
              duration={100}
            >
              Modify
            </HashLink>
          </div>
        </div>
      </div>
      <div className={innerCN("flex-col xl:flex-row")}>
        <div className="flex flex-col p-4 w-full xl:w-1/2 border-b xl:border-b-0 xl:border-r">
          <div className="mb-4">
            <p className="text-gray-400 font-semibold text-base">
              Credit Card Information
            </p>
          </div>
          <div className="mb-2">
            <p className="text-gray-400 text-base">
              {billingInfo.cardType?.toUpperCase()}
            </p>
          </div>
          <div
            onClick={() => setToggleBillingSelection((prev) => !prev)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setToggleBillingSelection((prev) => !prev)}
          >
            <HashLink
              className={modifyCN}
              to="#planSelection"
              smooth
              replace
              duration={100}
            >
              Modify
            </HashLink>
          </div>
        </div>
        <div className="flex flex-col pt-4 w-full xl:w-1/2 pl-4 xl:pl-8">
          <div className={innerCN("mb-4")}>
            <p className="flex text-gray-400 font-semibold text-base">
              Billing Address
            </p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">{billingInfo.name}</p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">
              {billingInfo.street} {billingInfo.street && ", "}{" "}
              {billingInfo.street2}
            </p>
          </div>
          <div className="">
            <p className="text-gray-400 text-base">
              {billingInfo.city} {billingInfo.city && ", "}
              {billingInfo.state} {billingInfo.zipCode}
            </p>
          </div>
          <div className="mb-4">
            <p className="text-gray-400 text-base">
              {billingInfo.country?.value}
            </p>
          </div>
          <div
            onClick={() => setToggleBillingSelection((prev) => !prev)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setToggleBillingSelection((prev) => !prev)}
          >
            <HashLink
              className={modifyCN}
              to="#planSelection"
              smooth
              replace
              duration={100}
            >
              Modify
            </HashLink>
          </div>
        </div>
      </div>
      <div className={innerCN("my-4 mr-6 justify-end")}>
        <div className="flex flex-col">
          <div className="flex justify-end mt-4">
            <Checkbox className="col-span-8" onChange={setTNC} checked={TNC} />
            <p className="text-gray-400 pl-2 text-sm sm:text-base">
              I agree to EstateSpace’s&nbsp;
            </p>
          </div>
          <div className="flex">
            <a
              href="https://estatespace.com/saas/"
              target="_blank"
              rel="noreferrer"
              className="text-brandGreen text-sm sm:text-base"
            >
              SaaS Terms and Conditions.
            </a>
          </div>
          <div className="flex justify-end py-2">
            <BaseButton
              style={{ minWidth: "200px" }}
              title="Submit Order"
              className={primaryButtonCN}
              onClick={submitOrder}
              disabled={
                isLoading ||
                !TNC ||
                !isPlanComplete ||
                !isBillingComplete ||
                !isContactComplete
              }
            />
          </div>
        </div>
      </div>
      {isErrorModalOpen && (
        <Modal
          inset="35% 30%"
          isOpen
          title="Error"
          onRequestModalClose={() => setIsErrorModalOpen(false)}
          hideFooter
        >
          <div>
            <p>{errorMessage}</p>
          </div>
        </Modal>
      )}
      {isLoading && <Spinner />}
    </>
  );
};

ReviewOrder.propTypes = {
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  userCount: PropTypes.number,
  frequency: PropTypes.string,
  total: PropTypes.number,
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
  }),
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    state: {
      label: PropTypes.string,
      value: PropTypes.string,
    },
    cardType: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    country: {
      label: PropTypes.string,
      value: PropTypes.string,
      countryCode: PropTypes.string,
    },
    zipCode: PropTypes.string,
  }),
  setOrderDetails: PropTypes.func,
  setToggleReviewSelection: PropTypes.func,
  setTogglePlanSelection: PropTypes.func,
  setToggleContactSelection: PropTypes.func,
  setToggleBillingSelection: PropTypes.func,
  isPlanComplete: PropTypes.bool,
  isBillingComplete: PropTypes.bool,
  isContactComplete: PropTypes.bool,
};

ReviewOrder.defaultProps = {
  selectedPlan: undefined,
  userCount: 0,
  frequency: undefined,
  total: 0,
  contactInfo: undefined,
  billingInfo: undefined,
  setOrderDetails: undefined,
  setToggleReviewSelection: undefined,
  setTogglePlanSelection: undefined,
  setToggleContactSelection: undefined,
  setToggleBillingSelection: undefined,
  isPlanComplete: false,
  isBillingComplete: false,
  isContactComplete: false,
};

export default ReviewOrder;
