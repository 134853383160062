// Framework Tools
import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

// Components
import RequestWorkflowEditMode from "../RequestWorkflow/RequestWorkflowEditMode";
import WorkflowDetailViewCard from "./WorkflowDetailViewCard";
import WorkflowStepTasks from "./WorkflowStepTasks";
import {
  WF_ACTIVE_STEP_TASK_INFO,
  WF_STATUS_TYPES,
  WF_STEP_ACTION_STATUS,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import ToggleButton from "../Buttons/ToggleButton";
import useCurrentUser from "../../../hooks/useCurrentUser";
import infoIconCircledGreen from "../../assets/images/infoIconCircledGreen.svg";
import FreeFlowTooltip from "./FreeFlowTooltip";

const WorkflowSteps = ({
  loading,
  setLoading,
  setAttachmentMap,
  attachmentMap,
  handleSendReminder,
  workflowActionSubmit,
  workflowActionReview,
  patchWorkflow,
  editedWorkflow,
  commentMap,
  editing,
  workflowSteps,
  checkStepComplete,
  workflowForm,
  workflowFormDispatch,
  projectMembers,
  userDict,
  workflowHistory,
  updateAssociatedFiles,
  stepTasksInfo,
  setStepTasksInfo,
  setTasksToBeDeleted,
  setDisableEditWF,
  disableEditWF,
  setShowStepDetails,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [{ wfActiveStepTaskInfo }, dispatch] = useAppState();
  const isFreeFlow = workflowForm?.requestWorkflow?.[0]?.isFreeFlow;
  const [stepTasksViewData, setStepTasksViewData] = useState({
    id: undefined,
    show: false,
    tasks: [],
    index: 1,
    status: undefined,
    role: undefined,
  });

  const { data: currentUser } = useCurrentUser();

  const isInProgress = useMemo(() => {
    return editedWorkflow?.status === WF_STATUS_TYPES?.IN_PROGRESS;
  }, [editedWorkflow?.status]);

  const userCanCompleteStep = useCallback(
    (usrArray) => {
      return usrArray?.some(
        (item) => item?.reference?.split("/")[1] === currentUser?.id
      );
    },
    [currentUser?.id]
  );

  useEffect(() => {
    if (wfActiveStepTaskInfo?.backFromTasksView) {
      setStepTasksViewData((prev) => ({
        ...prev,
        id: wfActiveStepTaskInfo?.id,
        status: wfActiveStepTaskInfo?.status,
        index: wfActiveStepTaskInfo?.index,
        show: wfActiveStepTaskInfo?.show,
        role: wfActiveStepTaskInfo?.role,
        tasks: wfActiveStepTaskInfo?.tasks,
        users: wfActiveStepTaskInfo?.users,
        attachments: wfActiveStepTaskInfo?.attachments,
        dueDate: wfActiveStepTaskInfo.dueDate,
      }));

      dispatch({
        type: WF_ACTIVE_STEP_TASK_INFO,
        payload: { ...wfActiveStepTaskInfo, backFromTasksView: false },
      });
    }
  }, [dispatch, wfActiveStepTaskInfo]);

  useEffect(() => {
    if (stepTasksViewData?.showStepTasks) {
      setStepTasksViewData((prev) => ({
        ...prev,
        status: stepTasksInfo?.status,
        index: stepTasksInfo?.stepIndex,
        show: stepTasksInfo?.showStepTasks,
        tasks: workflowSteps[stepTasksInfo?.stepIndex - 1]?.tasks,
      }));
      setStepTasksInfo({});
    }
  }, [
    setStepTasksInfo,
    stepTasksInfo?.showStepTasks,
    stepTasksInfo?.status,
    stepTasksInfo?.stepIndex,
    stepTasksViewData?.showStepTasks,
    workflowSteps,
  ]);

  const getIsActiveStep = useCallback(
    (step, index) => {
      let isActiveStep = false;
      if (isFreeFlow) {
        isActiveStep = step.users.every((usr) => usr.status === "waiting");
      } else {
        const activeStepIndex = workflowSteps.findIndex((item) =>
          item.users.every((usr) => usr.status === "waiting")
        );
        isActiveStep = index === activeStepIndex;
      }
      return isActiveStep;
    },
    [isFreeFlow, workflowSteps]
  );

  // Get the status of the step
  const getStepStatus = useCallback((step, isActiveStep) => {
    if (
      step?.users?.every(
        (user) => user.status === WF_STEP_ACTION_STATUS.WAITING
      ) &&
      isActiveStep
    ) {
      return WF_STEP_ACTION_STATUS.PENDING;
    }
    if (
      step?.users?.every(
        (user) => user.status === WF_STEP_ACTION_STATUS.WAITING
      )
    ) {
      return WF_STEP_ACTION_STATUS.UPCOMING;
    }
    if (
      step?.users?.some((user) => user.status === WF_STEP_ACTION_STATUS.VOID)
    ) {
      return WF_STEP_ACTION_STATUS.VOID;
    }
    return WF_STEP_ACTION_STATUS.COMPLETED;
  }, []);

  const handleMouseOver = () => {
    setShowTooltip((prev) => !prev);
  };

  const getStepLatestAction = useCallback(
    (stepNumber) => {
      let latestAction = "";
      let latestComment = "";
      Object.values(commentMap)?.forEach((comment) => {
        if (
          comment?.context?.workflow?.step?.position === 0 ||
          comment?.context?.workflow?.step?.position === stepNumber
        ) {
          latestAction = comment?.context?.workflow?.step?.action;
          latestComment = comment?.content;
        }
      });

      return { latestAction, latestComment };
    },
    [commentMap]
  );

  return (
    <div>
      {!editing && !stepTasksViewData.show && (
        <>
          <div className="absolute -top-7 flex items-center pb-5">
            <div className="reflex flex-col">
              <FreeFlowTooltip showTooltip={showTooltip} />
              <div className="flex">
                <ToggleButton isChecked={isFreeFlow} />
                <div className="flex flex-col">
                  <p className="flex items-center px-2 text-sm font-medium">
                    <span className="mr-2">Free flow</span>
                    <img
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOver}
                      onFocus={handleMouseOver}
                      onBlur={handleMouseOver}
                      className="w-3.5 h-3.5"
                      src={infoIconCircledGreen}
                      alt="info"
                    />
                  </p>
                  <p className="px-2 text-xs text-gray-300">
                    Complete steps in any order
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto flex flex-col gap-6 mt-24">
            {workflowSteps?.map((step, index) => {
              const isActiveStep = getIsActiveStep(step, index);
              const complete = step.users.some(checkStepComplete);
              const canComplete = userCanCompleteStep(step?.users);

              return (
                <WorkflowDetailViewCard
                  key={step.id}
                  loading={loading}
                  setLoading={setLoading}
                  onPatch={patchWorkflow}
                  requestData={editedWorkflow}
                  allSteps={workflowSteps}
                  commentMap={commentMap}
                  complete={complete}
                  canComplete={canComplete}
                  isInProgress={isInProgress}
                  activeStep={isActiveStep}
                  step={step}
                  stepStatus={getStepStatus(step, isActiveStep)}
                  index={index}
                  onSubmit={workflowActionSubmit}
                  onStepAction={workflowActionReview}
                  handleSendReminder={handleSendReminder}
                  attachmentMap={attachmentMap}
                  setAttachmentMap={setAttachmentMap}
                  history={workflowHistory}
                  updateAssociatedFiles={updateAssociatedFiles}
                  setStepTasksViewData={setStepTasksViewData}
                  isEditingWF={editing}
                  setShowStepDetails={setShowStepDetails}
                  userAction={getStepLatestAction(index + 1)}
                />
              );
            })}
          </div>
        </>
      )}
      {editing && !stepTasksViewData.show && (
        <RequestWorkflowEditMode
          setTasksToBeDeleted={setTasksToBeDeleted}
          workflow={workflowForm?.requestWorkflow?.[0]}
          requestForm={workflowForm}
          requestFormDispatch={workflowFormDispatch}
          association={workflowForm?.association}
          projectMembers={projectMembers}
          userDict={userDict}
          onPatch={patchWorkflow}
          workflowSteps={workflowSteps}
          getStepStatus={getStepStatus}
          getIsActiveStep={getIsActiveStep}
          isWorkflow
        />
      )}

      {stepTasksViewData.show && (
        <WorkflowStepTasks
          stepTasksViewData={stepTasksViewData}
          setStepTasksViewData={setStepTasksViewData}
          association={workflowForm?.association}
          projectMembers={projectMembers}
          onPatch={patchWorkflow}
          workflow={workflowForm?.requestWorkflow?.[0]}
          requestFormDispatch={workflowFormDispatch}
          editedWorkflow={editedWorkflow}
          workflowForm={workflowForm}
          setDisableEditWF={setDisableEditWF}
          disableEditWF={disableEditWF}
          onStepAction={workflowActionReview}
          setShowStepDetails={setShowStepDetails}
          getStepStatus={getStepStatus}
          editing={editing}
          actionTaken={
            getStepLatestAction(stepTasksViewData?.index)?.latestAction
          }
        />
      )}
    </div>
  );
};

WorkflowSteps.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setAttachmentMap: PropTypes.func,
  attachmentMap: PropTypes.shape({}),
  handleSendReminder: PropTypes.func,
  workflowActionReview: PropTypes.func,
  patchWorkflow: PropTypes.func,
  editedWorkflow: PropTypes.shape({ status: PropTypes.string }),
  commentMap: PropTypes.shape({}),
  editing: PropTypes.bool,
  workflowSteps: PropTypes.arrayOf(
    PropTypes.shape({ tasks: PropTypes.arrayOf(PropTypes.string) })
  ),
  checkStepComplete: PropTypes.string,
  workflowForm: PropTypes.shape({
    association: PropTypes.string,
    requestWorkflow: PropTypes.arrayOf(
      PropTypes.shape({
        isFreeFlow: PropTypes.bool,
      })
    ),
  }),
  workflowFormDispatch: PropTypes.func,
  projectMembers: PropTypes.arrayOf(PropTypes.shape({})),
  userDict: PropTypes.shape({}),
  workflowHistory: PropTypes.shape({}),
  updateAssociatedFiles: PropTypes.func,
  workflowActionSubmit: PropTypes.func,
  stepTasksInfo: PropTypes.shape({
    status: PropTypes.string,
    showStepTasks: PropTypes.bool,
    stepIndex: PropTypes.number,
  }),
  setStepTasksInfo: PropTypes.func,
  setTasksToBeDeleted: PropTypes.func,
  setDisableEditWF: PropTypes.func,
  disableEditWF: PropTypes.bool,
  setShowStepDetails: PropTypes.func,
};

WorkflowSteps.defaultProps = {
  loading: false,
  setLoading: undefined,
  setAttachmentMap: undefined,
  attachmentMap: undefined,
  handleSendReminder: undefined,
  workflowActionReview: undefined,
  patchWorkflow: undefined,
  editedWorkflow: undefined,
  commentMap: undefined,
  editing: false,
  workflowSteps: [],
  checkStepComplete: undefined,
  workflowForm: undefined,
  workflowFormDispatch: undefined,
  projectMembers: [],
  userDict: undefined,
  workflowHistory: {},
  updateAssociatedFiles: {},
  workflowActionSubmit: undefined,
  stepTasksInfo: {},
  setStepTasksInfo: undefined,
  setTasksToBeDeleted: undefined,
  setDisableEditWF: undefined,
  disableEditWF: true,
  setShowStepDetails: undefined,
};

export default WorkflowSteps;
