import React from "react";
import SingleTaskView from "../../../stories/Components/TaskTable/SingleTaskView";
import useTaskSingleData from "./useTaskSingleData";

const TaskSingle = () => {
  const { currentTask, setCurrentTask, clearCurrentTask, usedSopDict } =
    useTaskSingleData();
  return (
    <div>
      {currentTask && (
        <SingleTaskView
          page
          currentTask={currentTask}
          setCurrentTask={setCurrentTask}
          usedSopDict={usedSopDict}
          clearCurrentTask={clearCurrentTask}
        />
      )}
    </div>
  );
};

export default TaskSingle;
