import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";

import WorkflowsOverview from "../Overviews/Workflows/WorkflowsOverview";
import useProjects from "../../hooks/useProjects";
import DetailViewBanner from "../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../helpers/File";

const ProjectWorkflows = ({ currentUser, isDraftView }) => {
  const { projectId } = useParams();
  const { currentProject } = useProjects(projectId);
  const [projectImage, setProjectImage] = useState();

  useEffect(() => {
    if (currentProject?.images?.length) {
      resolvePrimaryImage(currentProject?.images)
        .then((imageFile) => setProjectImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve project primary image:${err}`)
        );
    }
  }, [currentProject?.images]);

  return (
    <WorkflowsOverview
      isDraftView={isDraftView}
      associatedResource={`Project/${projectId}`}
      siteHeaderProps={{
        title: currentProject?.name || "Loading",
        dropdownRoutes: getProjectOptions(projectId, currentUser),
      }}
      bannerComponent={
        <DetailViewBanner
          resource={currentProject}
          resourceImage={projectImage}
          disableUpload
        />
      }
    />
  );
};

ProjectWorkflows.propTypes = {
  currentUser: PropTypes.shape({ hasPermission: PropTypes.func }),
  isDraftView: PropTypes.bool,
};

ProjectWorkflows.defaultProps = {
  currentUser: undefined,
  isDraftView: false,
};

export default ProjectWorkflows;
