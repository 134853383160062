import React, { useEffect, useRef } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import BaseAddressInput from "../AddressInput/BaseAddressInput";

const cardInputCN = cntl`
  p-2
  ESInputContainer
`;

const cardElementStyles = {
  style: {
    base: {
      fontSize: "16px",
      color: "#1A1A1A",
      fontWeight: 100,
      fontFamily: "Arial, Helvetica, sans-serif",
      "::placeholder": {
        color: "#A6A6A6",
        fontStyle: "italic",
        fontWeight: "normal",
      },
    },
    invalid: {
      color: "black",
    },
  },
};

const primaryButtonCN = cntl`
  h-10
  px-2
  rounded-md
  bg-brandGreen
  hover:bg-opacity-90
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const BillingInformation = ({
  billingInfo,
  onChangeBillingInfo,
  onChangeBillingAddress,
  userCountError,
  setToggleBillingSelection,
  setToggleReviewSelection,
  isBillingComplete,
}) => {
  const elements = useElements();
  const countryRef = useRef(null);

  useEffect(() => {
    const cardnumber = elements.getElement(CardNumberElement);
    if (cardnumber)
      cardnumber.on("change", (f) =>
        onChangeBillingInfo(f?.complete, "cardNumber")
      );
  }, [elements, onChangeBillingInfo]);

  useEffect(() => {
    const cardexpiry = elements.getElement(CardExpiryElement);
    if (cardexpiry)
      cardexpiry.on("change", (f) =>
        onChangeBillingInfo(f?.complete, "expiration")
      );
  }, [elements, onChangeBillingInfo]);

  useEffect(() => {
    const cardexpiry = elements.getElement(CardCvcElement);
    if (cardexpiry)
      cardexpiry.on("change", (f) => onChangeBillingInfo(f?.complete, "cvc"));
  }, [elements, onChangeBillingInfo]);

  const handleCountryRef = () => {
    if (countryRef) countryRef.current.focus();
  };

  return (
    <>
      <div className={innerCN("mb-4")}>
        <p className="text-gray-400 text-sm font-normal">
          All fields are required unless indicated as optional. Your credit card
          will not be charged for 30 days.
        </p>
      </div>
      <div className={innerCN("flex-col xl:flex-row")}>
        <form
          className=" flex-col pt-4 px-4 w-full xl:w-1/2 xl:border-r justify-start"
          name="ccform"
        >
          <div className="mb-4">
            <p className="text-gray-400 font-semibold text-base">
              Credit Card Information
            </p>
          </div>
          <Input
            placeholder="Name on card"
            label="Name on Card"
            className="pb-4"
            value={billingInfo.name}
            onChange={(val) => onChangeBillingInfo(val, "name")}
            validation={yup.string().required()}
            autofocus
          />
          <div>
            <p className="text-gray-200 mb-1">Card Number</p>
          </div>
          <CardNumberElement
            className={cardInputCN}
            options={cardElementStyles}
            onChange={(val) => onChangeBillingInfo(val?.brand, "cardType")}
            autofocus
          />
          <div className="flex flex-col sm:flex-row w-full py-4">
            <div className="w-full mr-4">
              <p className="text-gray-200 mb-1">Expiration Date</p>
              <CardExpiryElement
                className="ESInputContainer p-2"
                options={cardElementStyles}
                placeholder="MM/YY"
              />
            </div>
            <div className="w-full mt-4 sm:mt-0">
              <p className="text-gray-200 mb-1">CVC</p>
              <CardCvcElement
                className="ESInputContainer p-2"
                options={cardElementStyles}
                onBlur={() => {
                  handleCountryRef();
                }}
                placeholder="Enter CVC "
                value="hello"
              />
            </div>
          </div>
        </form>
        <div className="flex flex-col pt-4 w-ful xl:w-1/2 pl-4">
          <div className={innerCN("mb-4")}>
            <p className="flex text-gray-400 font-semibold text-base">
              Billing Address
            </p>
          </div>

          <BaseAddressInput
            address={billingInfo}
            onChange={onChangeBillingAddress}
          />
        </div>
      </div>
      <div className={innerCN("my-4 mr-6 justify-end")}>
        {!userCountError && (
          <div className="flex flex-col">
            <div className="flex justify-end py-2">
              <BaseButton
                style={{ minWidth: "200px" }}
                title="Next"
                className={primaryButtonCN}
                onClick={() => {
                  setToggleBillingSelection((prev) => !prev);
                  setToggleReviewSelection((prev) => {
                    if (prev) {
                      return prev;
                    }
                    return !prev;
                  });
                }}
                disabled={!isBillingComplete}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

BillingInformation.propTypes = {
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    cardType: PropTypes.string,
    address1: {
      label: PropTypes.string,
      value: PropTypes.string,
    },
    address2: PropTypes.string,
    country: {
      label: PropTypes.string,
      value: PropTypes.string,
      countryCode: PropTypes.string,
    },
    zipCode: PropTypes.string,
  }),
  selected: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  userCountError: PropTypes.string,
  onChangeBillingInfo: PropTypes.func,
  onChangeBillingAddress: PropTypes.func,
  setToggleBillingSelection: PropTypes.func,
  setToggleReviewSelection: PropTypes.func,
  isBillingComplete: PropTypes.bool,
};

BillingInformation.defaultProps = {
  billingInfo: undefined,
  selected: undefined,
  userCountError: undefined,
  onChangeBillingInfo: false,
  onChangeBillingAddress: undefined,
  setToggleBillingSelection: false,
  setToggleReviewSelection: undefined,
  isBillingComplete: false,
};

export default BillingInformation;
