import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { isSafari } from "react-device-detect";

import ShareIcon from "../../../assets/images/shareIcon.svg";
import deleteGreenIcon from "../../../assets/images/deleteGreenIcon.svg";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import Input from "../../Input/Input";
import { validUrlSchema } from "../../../../helpers/FormValidations";
import IconButton from "../../Buttons/IconButton";
import { handleLinkClick } from "../../../../helpers/Utilities";
import useLinksRow from "./useLinksRow";

const inputCN = (browser) => cntl`
  bg-white
  flex
  flex-row
  place-self-start
  items-center
  ${browser ? "flex-wrap" : "items-center justify-between"}
`;
const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };
const linkCN = () => cntl`
  flex 
  justify-between
  items-center 
  flex-row
  w-fit
  h-8
  pr-2
  gap-4
  mr-2
  rounded-md
  hover:shadow-light-lift
`;

const LinksRow = ({
  isEditing,
  form,
  currentTask,
  handleAddLink,
  handleRemoveLink,
  noTitle,
}) => {
  const {
    addLinksIsOpen,
    setAddLinksIsOpen,
    confirmEdit,
    handleChange,
    handleDeleteClick,
    inputRef,
    setLinkUrl,
    linkName,
    linkUrl,
    setLinkName,
  } = useLinksRow({ isEditing, form, handleAddLink, handleRemoveLink });

  return (
    <>
      <div className="flex flex-row w-full flex-wrap items-center">
        {!noTitle && (
          <p style={labelWidth} className="font-semibold text-gray-450 text-md">
            Links
          </p>
        )}
        {addLinksIsOpen && isEditing && (
          <div className={inputCN(isSafari, !!form?.links?.length)}>
            <div className="flex flex-row items-center">
              <Input
                placeholder="Title"
                inlineLabel
                inputClassName="ESInput w-2/5"
                className="pr-4"
                label="Title"
                name="title"
                forwardedRef={(el) => {
                  inputRef.current.title = el;
                }}
                autoFocus
                value={linkName}
                onChange={(val) => setLinkName(val)}
                disableClear
              />
              <Input
                placeholder="Url"
                inlineLabel
                inputClassName="ESInput w-2/5"
                className=""
                label="Url"
                value={linkUrl}
                onChange={(val) => setLinkUrl(val)}
                validation={validUrlSchema}
                onKeyDown={(e) => handleChange(e)}
                disableClear
                showValidationErrorAtBottom
              />
            </div>
            <PlusCircleButton
              onClick={confirmEdit}
              disabled={!isEditing}
              className="flex mr-5"
              style={{ fontSize: 16 }}
            />
          </div>
        )}
        {(isEditing || !currentTask) &&
          !!form?.links?.length &&
          form?.links?.reduce((linkList, link) => {
            if (link?.url) {
              linkList.push(
                <div key={link?.id} className={linkCN()}>
                  <button
                    type="button"
                    className="flex w-full items-center cursor-pointer select-none"
                    onClick={() => handleLinkClick(link)}
                  >
                    <div className="flex py-4 pl-2 items-center w-full">
                      <img
                        src={ShareIcon}
                        alt="link open icon"
                        height={15}
                        width={15}
                      />
                      <p className="text-gray-300 underline font-regular ml-2">
                        {link?.name || link?.url}
                      </p>
                    </div>
                  </button>
                  <IconButton
                    wrapperClassName={isEditing ? "flex" : "hidden pr-2"}
                    onClick={() => handleDeleteClick(link)}
                    icon={deleteGreenIcon}
                    disabled={!isEditing}
                  />
                </div>
              );
            }
            return linkList;
          }, [])}
        {!isEditing &&
          !!currentTask?.links?.length &&
          currentTask?.links?.reduce((linkList, link) => {
            if (link?.url) {
              linkList.push(
                <div key={link?.id} className={linkCN()}>
                  <button
                    type="button"
                    className="flex w-full items-center cursor-pointer select-none"
                    onClick={() => handleLinkClick(link)}
                  >
                    <div className="flex py-4 pl-2 items-center w-full">
                      <img
                        src={ShareIcon}
                        alt="link open icon"
                        height={15}
                        width={15}
                      />
                      <p className="text-gray-300 underline font-regular ml-2">
                        {link?.name || link?.url}
                      </p>
                    </div>
                  </button>
                  <IconButton
                    wrapperClassName={isEditing ? "flex" : "hidden pr-2"}
                    onClick={() => handleDeleteClick(link)}
                    icon={deleteGreenIcon}
                    disabled={!isEditing}
                  />
                </div>
              );
            }
            return linkList;
          }, [])}
      </div>

      {isEditing && !addLinksIsOpen ? (
        <div className="flex flex-row w-full justify-start">
          <div style={{ minWidth: "10vw" }} />
          <PlusCircleButton
            title="Add Link"
            onClick={() => setAddLinksIsOpen(!addLinksIsOpen)}
            disabled={!isEditing}
            className="flex items-center h-16 text-darkenedGreen ml-2"
            style={{ fontSize: 16 }}
          />
        </div>
      ) : null}
    </>
  );
};

LinksRow.propTypes = {
  isEditing: PropTypes.bool,
  form: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentTask: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  handleAddLink: PropTypes.func,
  handleRemoveLink: PropTypes.func,
  noTitle: PropTypes.bool,
};

LinksRow.defaultProps = {
  isEditing: false,
  form: undefined,
  currentTask: undefined,
  handleAddLink: () => {},
  handleRemoveLink: () => {},
  noTitle: false,
};

export default LinksRow;
