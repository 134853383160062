import Api from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { taskKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";
import useAuthenticatedInfiniteQuery from "./useAuthenticatedInfiniteQuery";

const fetchActivity = async (pageParam, association) => {
  const { data } = await Api.get(`/api/${association}/history`, {
    paging: false,
    params: {
      limit: 10,
      page: pageParam,
    },
  });

  return {
    data: data?.entries?.map(({ resource }) => resource) || [],
    page: data.page,
    pages: data.pages,
  };
};

const useTaskActivity = (association) => {
  const [{ currentUser }] = useAppState();

  const queryKey = taskKeys.taskActivity(association);

  return useAuthenticatedInfiniteQuery({
    queryKey,
    enabled: !!currentUser && !!association,
    queryFn: ({ pageParam = 1 }) => fetchActivity(pageParam, association),
    getNextPageParam: (lastPageData) => {
      const nextPage =
        lastPageData.page < lastPageData.pages
          ? lastPageData.page + 1
          : undefined;

      return nextPage;
    },
    onError: (error) => {
      toastError("Something failed while getting Task activity");
      console.error("useTaskActivity", error);
    },
  });
};

export default useTaskActivity;
