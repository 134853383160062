import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { MEMBERS_INVITE_PATH } from "../../../constants";

export default function useAccountAndAdminViewData({ currentIndex }) {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [activeIndex, setActiveIndex] = useState(currentIndex || 0);
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [isEditingBillingCycle, setIsEditingBillingCycle] = useState(false);
  const [isEditingUserSeats, setIsEditingUserSeats] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [isExportingMemberList, setIsExportingMemberList] = useState(false);
  const [isInvitingMember, setIsInvitingMember] = useState(false);

  const onSave = useCallback(() => {
    setIsSaving(true);
  }, [setIsSaving]);

  const onCancel = () => {
    setIsCancel(true);
  };

  const dropDownItems = (index) => {
    switch (index) {
      case 0:
        return [];
      case 1:
        return [
          {
            title: "Change Plan",
            onClick: () => setIsEditingPlan(true),
          },
          {
            title: "Change Billing Cycle",
            onClick: () => setIsEditingBillingCycle(true),
          },
          {
            title: "Change User Seats",
            onClick: () => setIsEditingUserSeats(true),
          },
          {
            title: "Cancel Subscription",
            onClick: () => setIsCancellingSubscription(true),
          },
        ];
      case 2:
        return [
          {
            title: "Invite Member",
            onClick: () => {
              history.push(MEMBERS_INVITE_PATH);
            },
          },
          {
            title: "Export Member List",
            onClick: () => {
              setIsExportingMemberList(true);
            },
          },
        ];
      case 3:
        return [];

      default:
        return [];
    }
  };

  return {
    isSaving,
    setIsSaving,
    isCancel,
    setIsCancel,
    activeIndex,
    setActiveIndex,
    onSave,
    onCancel,
    dropDownItems,
    isEditingPlan,
    setIsEditingPlan,
    isEditingBillingCycle,
    setIsEditingBillingCycle,
    isEditingUserSeats,
    setIsEditingUserSeats,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
    isInvitingMember,
    setIsInvitingMember,
  };
}
