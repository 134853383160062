import { cloneDeep } from "lodash";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const getDefaultState = () => ({
  name: "",
  id: uuidv4(),
  images: [],
  files: [],
  videos: [],
  additionalInfo: [],
  type: undefined,
  isNewType: undefined,
  level: undefined,
  area: { value: 0 },
  originalResource: {},
});

const reducer = (space, action) => {
  switch (action.type) {
    case "reset": {
      const cloned = cloneDeep(action.space);
      return cloned
        ? { ...cloned, originalResource: cloned }
        : getDefaultState();
    }
    case "setCurrentTags": {
      return { ...space, currentTags: action.currentTags };
    }
    case "addLinkCompleteLink":
      return {
        ...space,
        links: [...(space.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...space,
        links: [
          ...(space.links ?? []),
          { id: action.id, name: action.name, url: action.url },
        ],
      };
    case "deleteLink":
      return {
        ...space,
        links: space.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "setLinks":
      return {
        ...space,
        links: action?.links,
      };
    case "addImage": {
      const { images = [] } = space;
      return {
        ...space,
        images: [
          ...images,
          {
            ...action.image,
            isPrimary: images.length < 1,
          },
        ],
      };
    }

    case "deleteImage": {
      const { images = [] } = space;
      return {
        ...space,
        images: images.filter((image) => image.file !== action.imageRef),
      };
    }
    case "deleteImages": {
      const { images = [] } = space;
      const imageRefs = action.images?.map((ref) => ref?.file);
      return {
        ...space,
        images: images.filter((image) => imageRefs.indexOf(image?.file) < 0),
      };
    }
    case "changeIsPrimary": {
      const { images = [] } = space;
      return {
        ...space,
        images: images.map((image) => {
          if (image.file === action.id) {
            return {
              ...image,
              isPrimary: action.isPrimary,
            };
          }
          return {
            ...image,
            isPrimary: false,
          };
        }),
      };
    }
    case "updatePrimaryImage": {
      return {
        ...space,
        files: [
          ...(space.files || []),
          { ref: action.fileRef, category: action.category },
        ],
        primaryImage: action.fileRef,
        image: action.image,
      };
    }
    case "name":
      return {
        ...space,
        name: action.name,
      };
    case "area": {
      const { area = {} } = space;
      return { ...space, area: { ...area, value: action.value } };
    }
    case "level":
      return { ...space, level: action.value };
    case "type": {
      return { ...space, type: action.value, isNewType: action?.isNewType };
    }
    case "overview":
      return {
        ...space,
        level: action.level,
        name: action.name,
        type: action.spaceType,
      };
    case "measurement": {
      if (action?.operation === "remove") {
        return {
          ...space,
          additionalInfo: space.additionalInfo?.filter(
            (item) => item.detail !== action.detail
          ),
        };
      }
      const elementIndex = space.additionalInfo.findIndex(
        (item) => item.detail === action.detail
      );
      if (elementIndex > -1) {
        return {
          ...space,
          additionalInfo: space.additionalInfo?.map((item, idx) => {
            if (idx === elementIndex) {
              return {
                id: action.id,
                detail: action.detail,
                value: action.value,
                unit: action.unit,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...space,
        additionalInfo: [
          ...space.additionalInfo,
          {
            id: action.id,
            detail: action.detail,
            value: action.value,
            unit: action.unit,
          },
        ],
      };
    }
    default:
      return space;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? getDefaultState());
};
