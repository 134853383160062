/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TimesheetAPI, TimeoffAPI } from "@griffingroupglobal/eslib-api";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import _ from "lodash";
import {
  checkEmployeePtoCache,
  checkPayrollCache,
  formatHistory,
  unzipEmployeePto,
  unzipPayroll,
} from "../helpers/TimeSheet";
import useFinancialsConfiguration from "./useFinancialsConfiguration";
import usePagePersistance from "./usePagePersistence";
import handleHistoryReqSplit from "../helpers/Utilities/handleHistoryReqSplit";

const { TimeSheets } = TimesheetUtil;

export default ({ permission, users }) => {
  /**
   * @summary Financials Configuration
   */
  const payrollConfig = useRef(null);

  /**
   * @summary Financials Config State
   */
  const [financials, setFinancials] = useState();

  /**
   * @summary TimeSheet Util
   */
  const util = useRef(null);

  /**
   * @summary Employee Timesheets
   */
  const [timesheets, setTimesheets] = useState();
  /**
   * @summary Number of submitted timesheets
   */
  const [submittedTimesheets, setSubmittedTimesheets] = useState(0);

  /**
   * Approved PTO
   */

  const [approvedPto, setApprovedPto] = useState();

  /**
   * @summary Loading State
   */
  const [loading, setLoading] = useState(true);

  /**
   * @summary list of usersInfo
   */

  const { pageState } = usePagePersistance();
  const usersList = useMemo(() => {
    return users;
  }, [users]);

  /**
   * @summary Cache
   */

  const cache = useRef(null);

  /**
   * Pto Cache
   */
  const localPtoCache = useRef(null);

  /**
   * @summary Current Payroll Period
   */
  // const [periodFrame] = useState();

  /**
   * @Summary Timesheet history
   */
  const [payrollHistory, setPayrollHistory] = useState();

  const { data: finSettings } = useFinancialsConfiguration();

  const countSubmittedTimsheets = useCallback((sheets) => {
    return sheets?.reduce((list, item) => {
      list[item?.userRef] = true;
      return list;
    }, {});
  }, []);

  const reload = useCallback(
    async (start, end) => {
      try {
        setTimesheets();
        setApprovedPto();
        setLoading(true);
        if (!payrollConfig.current) {
          payrollConfig.current = finSettings?.financials;
          util.current = new TimeSheets(
            payrollConfig.current?.payroll?.period,
            payrollConfig.current?.period?.start,
            3,
            end
          );

          setFinancials({
            period: payrollConfig.current?.payroll?.period,
            start: payrollConfig.current?.period?.start,
          });
          // (PARKER) Leaving code here in-case unknown side-effects appear
          // Commenting this fixed resetting the period when approving payroll
          // should fix the reseting of the period when reload
          // setPeriodFrame(util.current.currentPeriod);
        }

        if (!localPtoCache.current) {
          /**
           * @TODO - Request API update for range & implement cache
           */
          const { data: ptoRes } = await TimeoffAPI.get({
            params: { status: "approved" },
          });
          localPtoCache.current = unzipEmployeePto(
            ptoRes?.entries?.reduce((arr, { resource }) => {
              if (resource?.requests) {
                resource?.requests?.forEach((item) => {
                  // eslint-disable-next-line no-param-reassign
                  arr = arr.concat({ ...item, userRef: resource?.user });
                });
              }
              return arr;
            }, []),
            usersList
          );
        }

        if (start) {
          const cachedSheets = checkPayrollCache(
            cache.current,
            start,
            end,
            true
          );
          const cachedPto = checkEmployeePtoCache(
            localPtoCache.current,
            start,
            end
          );
          if (cachedPto?.length > 0) {
            setApprovedPto(cachedPto);
          }
          if (cachedSheets?.length > 0) {
            setTimesheets(cachedSheets);
            setSubmittedTimesheets(countSubmittedTimsheets(cachedSheets));
            setLoading(false);
            return;
          }
        }

        /**
         * Initial Cache Fetch
         */
        const query = {
          periodStart: start ?? util.current.cacheLimit,
          periodEnd: end ?? util.current?.currentPeriod?.periodEnd,
          status: "approved,approved-with-changes,locked,submitted,rejected",
        };

        const {
          data: { entries },
        } = await TimesheetAPI.get({ params: query });
        if (entries?.length > 0) {
          const { queryString, unzipped } = unzipPayroll(entries, usersList);
          cache.current = cache.current?.length
            ? _.uniqBy([...cache.current].concat(unzipped), (item) => {
                return item?.resource?.id;
              })
            : unzipped;

          const { data } = await handleHistoryReqSplit(queryString);
          setPayrollHistory((prev) => {
            return prev
              ? {
                  ...prev,
                  ...formatHistory(data?.entries, usersList),
                  queryString,
                }
              : { ...formatHistory(data?.entries, usersList), queryString };
          });
        }
        if (start) {
          const cachedSheets = checkPayrollCache(
            cache.current,
            start,
            end,
            true
          );
          const cachedPto = checkEmployeePtoCache(
            localPtoCache.current,
            start,
            end
          );
          if (cachedPto?.length > 0) {
            setApprovedPto(cachedPto);
          }
          if (cachedSheets?.length > 0) {
            setTimesheets(cachedSheets);
            setSubmittedTimesheets(countSubmittedTimsheets(cachedSheets));
          }
          setLoading(false);
          return;
        }

        if (!start) {
          const cachedSheets = checkPayrollCache(
            cache.current,
            util.current?.currentPeriod?.periodStart,
            util.current?.currentPeriod?.periodEnd,
            true
          );
          const cachedPto = checkEmployeePtoCache(
            localPtoCache.current,
            util.current?.currentPeriod?.periodStart,
            util.current?.currentPeriod?.periodEnd
          );
          if (cachedPto?.length > 0) {
            setApprovedPto(cachedPto);
          }
          if (cachedSheets?.length > 0) {
            setTimesheets(cachedSheets);

            setSubmittedTimesheets(countSubmittedTimsheets(cachedSheets));
          }
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [finSettings?.financials, countSubmittedTimsheets, usersList]
  );
  const updateCache = (sheets, action) => {
    if (Array.isArray(sheets)) {
      sheets.forEach((item) => {
        cache.current[item.index] = {
          resource: { ...item, payrollStatus: action },
        };
      });
      return;
    }
    cache.current[sheets.index] = {
      resource: { ...sheets, payrollStatus: action },
    };
  };

  useEffect(() => {
    if (usersList && permission && finSettings?.financials) {
      reload(
        pageState?.timesheet?.periodStart,
        pageState?.timesheet?.periodEnd
      );
    }
  }, [finSettings?.financials, pageState, permission, reload, usersList]);

  const reloadHistory = useCallback(async () => {
    try {
      if (payrollHistory?.queryString) {
        const { data } = await handleHistoryReqSplit(
          payrollHistory?.queryString
        );

        setPayrollHistory((prev) => ({
          ...prev,
          ...formatHistory(data?.entries, usersList),
        }));
      }
    } catch {
      console.error("Error Loading History");
    }
  }, [payrollHistory?.queryString, usersList]);

  return {
    timesheets,
    pto: approvedPto,
    submittedTimesheets,
    loading,
    financials,
    // periodFrame,
    reload,
    payrollHistory,
    updateCache,

    reloadHistory,
  };
};
