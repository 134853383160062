/* eslint no-underscore-dangle: 0 */

import { useCallback, useEffect, useState } from "react";
import { AssetAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import { getAssetOrPropertyMembersRef } from "../helpers/Asset";
import { getFullName } from "../helpers/Formatters";

export default function useAssetById(assetId) {
  const [{ userDict }] = useAppState();
  const [asset, setAsset] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [assetMembers, setAssetMembers] = useState([]);

  useEffect(() => {
    const fetchMemberResources = async () => {
      const membersRef = await getAssetOrPropertyMembersRef(asset);

      const membersArray = [];
      membersRef?.forEach((m) => {
        const user = userDict[m];
        if (user?.kind === "member") {
          membersArray.push({
            label: getFullName(user.name),
            value: user.reference,
          });
        }
      });
      setAssetMembers(membersArray);
    };
    fetchMemberResources();
  }, [asset, userDict]);

  const reload = useCallback(async () => {
    if (assetId) {
      setLoading(true);

      try {
        const response = await AssetAPI.getById(assetId);

        setAsset(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [assetId]);

  const patchAsset = async (finishedAsset, throwError) => {
    let rv;
    try {
      const { data } = await AssetAPI.patch(asset.id, finishedAsset, asset);
      setAsset(data);
      rv = data;
    } catch (err) {
      if (throwError) {
        throw err;
      }
    }
    return rv;
  };

  useEffect(() => {
    if (userDict) reload();
  }, [reload, userDict]);

  return {
    asset,
    isLoading,
    reload,
    setAsset,
    assetMembers,
    patchAsset,
  };
}
