/* eslint-disable consistent-return */
const getUpdatedAllTaskSpacesAssets = ({
  currentEntities,
  newCompletionStatus,
  entity,
}) => {
  const updatedEntityList = currentEntities;
  const spaces = [];
  const assets = [];
  updatedEntityList.forEach((item) => {
    if (entity?.ref?.length && entity?.ref === item?.ref) {
      return assets.push({ ...item, isCompleted: newCompletionStatus });
    }
    if (entity?.id?.length && entity?.id === item?.id) {
      return spaces.push({ ...item, isCompleted: newCompletionStatus });
    }
    // return non complete spaces
    if (item?.id) {
      return spaces.push({ ...item });
    }

    // return non complete assets
    if (item?.ref) {
      return assets.push({ ...item });
    }
  });
  return { spaces, assets };
};

export default getUpdatedAllTaskSpacesAssets;
