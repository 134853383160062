import React from "react";
import PropTypes from "prop-types";

const ContactInformationHeader = ({ contactInfo }) => {
  return (
    <div className="flex flex-col pr-2" style={{ width: "28rem" }}>
      <div className="flex flex-row">
        <p className="text-black font-normal">
          {contactInfo?.firstName} {contactInfo?.lastName} ({contactInfo?.email}
          )
        </p>
      </div>
      <div className="flex flex-row">
        <p className="text-black font-semibold">{contactInfo?.company}</p>
      </div>
    </div>
  );
};

ContactInformationHeader.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
  }),
};

ContactInformationHeader.defaultProps = {
  contactInfo: undefined,
};

export default ContactInformationHeader;
