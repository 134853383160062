import { useCallback, useMemo } from "react";

import moment from "moment";
import { CommentAPI } from "@griffingroupglobal/eslib-api";
import { useHistory } from "react-router";
import { DateIsBefore } from "../../../helpers/Dates";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, VIEW_TODO_MODAL } from "../../../constants";
import useTodos from "../../../hooks/useTodos";
import usePagePersistance from "../../../hooks/usePagePersistence";
import useCurrentUser from "../../../hooks/useCurrentUser";

const getCommentByRef = async (ref) => {
  try {
    const { data } = await CommentAPI.getByRef(ref);
    return data;
  } catch (error) {
    console.error("getCommentByRef", error);
    return {};
  }
};

const useTrayNotificationData = ({
  reference,
  selectedNotifs,
  title,
  text,
  target,
  close,
}) => {
  const history = useHistory();

  const [, modalDispatch] = useModalState();
  const { setPersistentPageItem, pageState } = usePagePersistance();
  const { data: currentUser } = useCurrentUser();

  const { data: todos } = useTodos();

  const createdAt = (d) => {
    const today = moment().startOf("day").format();
    const date = moment(d);

    return DateIsBefore(d, today)
      ? date.format("MM/DD/YYYY h:mm a")
      : date.format("h:mm a");
  };

  const selected = useMemo(() => {
    return selectedNotifs?.find((item) => item?.reference === reference);
  }, [reference, selectedNotifs]);

  /**
   * Open Todo modal if Target has a Todo Reference
   */
  const handleTodoSelected = useCallback(
    (todoReference) => {
      const todoId = todoReference.split("/")[1];

      const todoFound = todos.find((todo) => todo.id === todoId);

      if (todoFound) {
        modalDispatch({
          type: ADD_OPEN_MODAL,
          ref: { id: todoId },
          modalData: { item: todoFound },
          modalType: VIEW_TODO_MODAL,
        });

        close();
      }
    },
    [todos, close, modalDispatch]
  );

  /**
   * Handles the onClick event for a notification item.
   * 1. If the title or text includes "removed", it doesn't perform any action.
   * 2. Custom actions for Todo and Comment targets
   *
   * If the target and path are valid and it doesn't include "removed", it closes the notification and deeplink to the path.
   */
  const onClick = useCallback(async () => {
    const includesRemove =
      title?.toLowerCase()?.includes("removed") ||
      text?.toLowerCase()?.includes("removed");

    let path;
    let isCommentNotification = false;

    if (target.startsWith("Todo")) {
      handleTodoSelected(target);
    } else if (target.startsWith("Comment")) {
      const data = await getCommentByRef(target);
      // find parent resource of the comment
      path = getSingleResourcePath(data?.association);
      isCommentNotification = true;
    } else if (target.startsWith("Timeoff")) {
      path = getSingleResourcePath(
        target,
        currentUser,
        pageState,
        setPersistentPageItem
      );
    } else if (target.startsWith("Timesheet")) {
      const data = await getCommentByRef(target);
      path = getSingleResourcePath(
        target,
        currentUser,
        pageState,
        setPersistentPageItem,
        data
      );
    } else {
      path = getSingleResourcePath(target);
    }

    if (!includesRemove && target && path) {
      close();
      history.push(path, {
        isCommentNotification,
      });
    }
  }, [
    close,
    history,
    target,
    text,
    title,
    currentUser,
    pageState,
    setPersistentPageItem,
    handleTodoSelected,
  ]);

  const ellipsedTitle = useMemo(() => {
    if (title.length > 50) {
      return `${title.substring(0, 50).trim()}...`;
    }
    return title;
  }, [title]);

  return { selected, createdAt, onClick, ellipsedTitle };
};

export default useTrayNotificationData;
