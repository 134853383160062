import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { components } from "react-select";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import errorIcon from "../../assets/images/redExclamationIcon.svg";

const primaryButtonCN = cntl`
  h-10
  px-2
  rounded-md
  bg-brandGreen
  hover:bg-opacity-90
`;

const buttonCN = (showGreenBorder) => cntl`
  flex
  flex-col
  w-full
  lg:w-1/2
  h-28
  mr-2
  px-4
  py-3
  mb-2
  rounded
  ${showGreenBorder ? "border-4 border-brandGreen" : "border-4"}
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const formatOptionLabel = ({ label, subLabel }) => (
  <div className="flex flex-col">
    <div className="font-semibold">{label}</div>
    <div className="font-normal text-sm text-gray-400">{subLabel}</div>
  </div>
);

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props} className="h-16">
      {children}
    </components.ValueContainer>
  );
};

const PlanSelection = ({
  changeFrequency,
  discount,
  frequency,
  onPlanChange,
  onPlanUpdate,
  onUserChange,
  selectedPlan,
  subscriptionPlans,
  supportedPlan,
  total,
  userCount,
  userCountError,
  isPlanComplete,
  handleNextBtn,
}) => {
  return (
    <>
      <div className={innerCN("mb-4")}>
        <p className="flex text-gray-400 text-sm font-normal">
          Confirm your subscription plan details.
        </p>
      </div>
      <div className={innerCN("flex-col xl:flex-row")}>
        <div className="border-b mb-4 xl:mb-0 xl:border-r xl:border-b-0 pr-4 w-full xl:w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            Which plan level?
          </p>
          <div className="mt-4 w-60 mb-6">
            <Dropdown
              formatOptionLabel={formatOptionLabel}
              options={subscriptionPlans}
              onChange={(val) => onPlanChange(val)}
              value={selectedPlan}
              ValueContainer={ValueContainer}
              disableClear
              disableSearch
              customStyle={{ control: { borderWidth: "0px" } }}
              hideIndicator
            />
          </div>
        </div>
        <div className="border-b mb-4 xl:mb-0 xl:border-r xl:border-b-0 px-4 w-full xl:w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            How many users?
          </p>
          <p className="flex pb-2 text-gray-400 text-sm font-normal">
            You can always add more users.
          </p>
          <div className={innerCN("flex-row")}>
            <div className="mr-2 truncate border-2 rounded-md">
              <Input
                placeholder="Count"
                className="flex w-24 max-w-min"
                value={userCount}
                disableClear
                onChange={(val) => onUserChange(val)}
              />
            </div>
            {userCountError && (
              <img width="20" src={errorIcon} alt="errorIcon" />
            )}
          </div>
          <p className="flex py-2 text-red-600 text-xs font-normal">
            {userCountError}
          </p>
          {userCountError && supportedPlan && (
            <BaseButton
              className="min-w-max"
              title={`Switch to ${supportedPlan.planName.toLowerCase()} plan`}
              colorCN="text-brandGreen"
              onClick={() => onPlanUpdate()}
            />
          )}
        </div>
        <div className="px-4 w-full xl:w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            What billing frequency?
          </p>
          <div className="flex flex-col sm:flex-row xl:justify-center pt-4">
            <button
              key="MONTHLY"
              type="button"
              className={buttonCN(frequency === "monthly")}
              onClick={() => changeFrequency("monthly")}
            >
              <div>
                <div className={innerCN("justify-center")}>
                  <h2 className="text-gray-900 text-sm font-semibold">
                    MONTHLY
                  </h2>
                </div>
                <div className={innerCN("justify-center")}>
                  <h2 className="font-bold text-lg">
                    $ {selectedPlan.monthlyCost}
                  </h2>
                </div>
                <div className={innerCN("justify-center")}>
                  <p className="flex pb-2 text-gray-400 text-xs font-normal">
                    per user
                  </p>
                </div>
              </div>
            </button>
            <button
              key="ANNUAL"
              type="button"
              className={buttonCN(frequency === "annual")}
              onClick={() => changeFrequency("annual")}
            >
              <div>
                <div className={innerCN("justify-center")}>
                  <h2 className="text-gray-900 text-sm font-semibold">
                    ANNUAL
                  </h2>
                </div>
                <div className={innerCN("justify-center")}>
                  <h2 className="font-bold text-lg">
                    $ {selectedPlan.annualCost}
                  </h2>
                </div>
                <div className={innerCN("justify-center")}>
                  <p className="flex pb-2 text-gray-400 text-xs font-normal">
                    per user
                  </p>
                </div>
                <div className={innerCN("justify-center")}>
                  <p className="flex text-black text-xs font-semibold min-w-max">
                    {selectedPlan.discount}% discount
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={innerCN("border-t my-4 mr-6 justify-end")}>
        {!userCountError && (
          <div className="flex flex-col">
            <div className="flex justify-end mt-3">
              <p className="text-sm font-bold pt-1">TOTAL:&nbsp;</p>
              <p className="text-lg font-bold"> ${total}</p>
            </div>
            <div className="flex justify-end">
              <p className="text-gray-400 text-xs font-normal">
                You save ${discount}
              </p>
            </div>
            <div className="flex justify-end py-2">
              <BaseButton
                style={{ minWidth: "200px" }}
                title="Next"
                className={primaryButtonCN}
                onClick={handleNextBtn}
                disabled={!isPlanComplete}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

PlanSelection.propTypes = {
  changeFrequency: PropTypes.func,
  discount: PropTypes.string,
  frequency: PropTypes.string,
  onPlanChange: PropTypes.func,
  onPlanUpdate: PropTypes.func,
  onUserChange: PropTypes.func,
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  subscriptionPlans: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      minUsers: PropTypes.number,
      maxUsers: PropTypes.number,
      monthlyCost: PropTypes.string,
      annualCost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      priceIdMonthly: PropTypes.string,
      priceIdAnnual: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      subLabel: PropTypes.string,
    })
  ),
  supportedPlan: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  total: PropTypes.number,
  userCount: PropTypes.number,
  userCountError: PropTypes.string,
  isPlanComplete: PropTypes.bool,
  handleNextBtn: PropTypes.func,
};

PlanSelection.defaultProps = {
  selectedPlan: undefined,
  changeFrequency: undefined,
  discount: undefined,
  frequency: undefined,
  onPlanChange: undefined,
  onPlanUpdate: undefined,
  onUserChange: undefined,
  subscriptionPlans: [],
  supportedPlan: undefined,
  total: 0,
  userCount: 0,
  userCountError: undefined,
  isPlanComplete: false,
  handleNextBtn: undefined,
};

export default PlanSelection;
