import { PROPERTY_TYPES } from "../constants";

/**
 * PropertyData is a helper object to format data from a Property payload returned by the API into a more organized
 * format for display.
 */
class PropertyData {
  id = "";

  image = "";

  title = "";

  address = {};

  lotSize = {};

  sf = 0;

  bedrooms = 0;

  bathrooms = 0;

  isInvestment = false;

  propertyType = "";

  lastUpdated = new Date();

  purchase = {};

  interestRate = 0;

  mortgage = 0;

  description = "";

  addAnother = false;

  propertyTypeId = "";

  tags = [];

  currentTags = [];

  static getDropDownOption = (value, type) => {
    switch (type) {
      case "propertyType": {
        if (value) {
          return value === "commercial" ? PROPERTY_TYPES[0] : PROPERTY_TYPES[1];
        }
        return undefined;
      }
      default:
        return undefined;
    }
  };

  /**
   * Create a new {@link PropertyData} from a Property payload. The Property payload may come from useProperties.
   */
  static formatTableData(property, propertyTypes = null, tagsDict = {}) {
    let typeLabel;

    if (propertyTypes) {
      typeLabel = propertyTypes.filter(
        (val) => property?.propertyType === val?.value
      );
    }

    return new PropertyData({
      id: property.id,
      image: property.primaryImage,
      title: property.title,
      address: property.address,
      lotSize: property?.lotSize,
      description: property?.description,
      sf: property.area?.value || 0,
      bedrooms: property.bedrooms || 0,
      bathrooms: property.bathrooms || 0,
      isInvestment: property.isInvestment,
      notes: property.notes,
      propertyType: typeLabel[0]?.label,
      lastUpdated: property?.metadata?.lastUpdated,
      purchase: property?.purchase,
      interestRate: property?.interestRate,
      mortgage: property?.mortgageAmount?.price || 0,
      addAnother: property?.addAnother,
      propertyTypeId: property.propertyType,
      tags: property?.tags,
      currentTags: property?.tags?.map((tagRef) => ({
        label: tagsDict[tagRef]?.label,
        value: tagsDict[tagRef]?.reference,
      })),
    });
  }

  /**
   * Constructor. Please use {@link formatTableData} instead.
   */
  constructor({
    id,
    image,
    title,
    address,
    lotSize,
    sf,
    bedrooms,
    bathrooms,
    isInvestment,
    notes,
    propertyType,
    lastUpdated,
    interestRate,
    mortgage,
    description,
    purchase,
    addAnother,
    typeLabel,
    tags,
    currentTags,
  }) {
    this.id = id;
    this.image = image;
    this.title = title;
    this.address = address;
    this.lotSize = lotSize;
    this.sf = sf;
    this.bedrooms = bedrooms;
    this.bathrooms = bathrooms;
    this.isInvestment = isInvestment;
    this.notes = notes;
    this.propertyType = propertyType;
    this.lastUpdated = lastUpdated;
    this.interestRate = interestRate;
    this.mortgage = mortgage;
    this.description = description;
    this.purchase = purchase;
    this.addAnother = addAnother;
    this.typeLabel = typeLabel;
    this.tags = tags;
    this.currentTags = currentTags;
  }
}

export default PropertyData;
