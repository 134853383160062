import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import * as yup from "yup";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import Modal from "../Modal/Modal";
import useIsSignedIn from "../../../hooks/useIsSignedIn";

const CancelSubscription = ({ isOpen, onClose, selectedPlan, dispatch }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [feedback, setFeedback] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSignedIn, setIsSignedIn] = useIsSignedIn();

  useEffect(() => {
    if (feedback?.length > 400) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [feedback]);

  const onLogout = useCallback(async () => {
    setIsSignedIn(false);
  }, [setIsSignedIn]);

  const onSave = useCallback(async () => {
    const res = await SignUpAPI.cancelSubscription(
      selectedPlan?.subscriptionId,
      feedback
    );

    dispatch({
      type: "subscriptionStatus",
      value: res?.status,
    });
    onClose();
    onLogout();
  }, [dispatch, feedback, onClose, onLogout, selectedPlan?.subscriptionId]);

  if (!isSignedIn) {
    return (
      <Redirect
        to={{
          pathname: "/landing",
        }}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestModalClose={onClose}
      title="CANCEL SUBSCRIPTION"
      hideFooter
      primaryButtonTitle="Yes, Cancel Subscription"
      primaryButtonOnClick={onSave}
      tertiaryButtonTitle="No, Never Mind"
      disabled={!isChecked || isInvalid}
      crossIconGreen
    >
      <div className="flex flex-col">
        <div>
          <p>
            Once you’ve cancelled, you’ll no longer have access to your
            EstateSpace data.
          </p>
        </div>
        <div className="py-4">
          <p className="font-semibold">
            Are you sure you want to cancel your subscription?
          </p>
        </div>
        <div className="flex flex-row pb-6">
          <Checkbox checked={isChecked} onChange={setIsChecked} />
          <p className="text-gray-200 p-2">
            I want to cancel my EstateSpace subscription.
          </p>
        </div>
        {isChecked && (
          <div className="py-4">
            <Input
              label="Can you please tell us why you’re leaving EstateSpace?"
              value={feedback}
              onChange={setFeedback}
              isTextarea
              placeholder="Feedback"
              validation={yup
                .string()
                .max(400, "Limit feedback to 400 characters")}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

CancelSubscription.propTypes = {
  onClose: PropTypes.func,
  selectedPlan: PropTypes.shape({
    subscriptionId: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
};

CancelSubscription.defaultProps = {
  onClose: undefined,
  selectedPlan: undefined,
  dispatch: undefined,
  isOpen: false,
};

export default CancelSubscription;
