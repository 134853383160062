import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";

import { useAppState } from "../../../state/appState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useDocuments from "../../../hooks/useDocuments";
import useProjects from "../../../hooks/useProjects";
import usePropertyById from "../../../hooks/usePropertyById";
import { getBudgetLineItems } from "../../../hooks/useBudgetLineItems";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useDocumentFormReducer from "../../../hooks/useDocumentFormReducer";
import useFavorites from "../../../hooks/useFavorites";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";

import DocumentActions from "../DocumentActions/DocumentActions";
// eslint-disable-next-line import/no-cycle
import DocumentViewForm from "./DocumentViewForm";
import DocumentHistory from "../DocumentHistory/DocumentHistory";
// eslint-disable-next-line import/no-cycle
import AdditionalDocuments from "../AdditionalDocuments/AdditionalDocuments";

import SiteHeader from "../SiteHeader/SiteHeader";
import Modal from "../Modal/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";

import chevronLeft from "../../assets/images/chevronLeft.svg";
import starIcon from "../../assets/images/starIcon.svg";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import starIconGreen from "../../assets/images/starGreen.svg";
import { getCSICodeDescription } from "../../../helpers/Budget";
import { downloadMedia } from "../../../helpers/File";
import { toastError } from "../../../helpers/Toast";
import {
  RESET,
  COA_CODE_TYPES,
  PURCHASE_AUTHORIZATION,
  CONTINGENCY_AUTHORIZATION,
  CHANGE_ORDER,
  PURCHASE_ORDER,
  GET_DOCUMENT_EDIT_PATH,
  GET_DOCUMENT_FOLDER_FILE_EDIT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH,
  DOCUMENT_DISPLAY_STATUS,
  VENDOR_INVOICE,
  SET_WORKFLOW_MODAL_STATUS,
  INVOICE,
} from "../../../constants";
import DocumentHeader from "./DocumentHeader";
import DocumentSummary from "./DocumentSummary";
import DocumentAttachments from "./DocumentAttachments";
import DocumentVendorInvoices from "../FinancialDocuments/Invoice/DocumentVendorInvoices";
import DocumentExpenses from "../FinancialDocuments/Invoice/DocumentExpenses";
import DocumentFiles from "./DocumentFiles";
import DocumentTimesheets from "../FinancialDocuments/Invoice/DocumentTimesheets";
import WidgetContainer from "../Widget/WidgetContainer";
import PopoverComments from "../PopoverComments";
import { useUsers } from "../../../hooks/useUsers.new";
import DocumentDetails from "../FinancialDocuments/Invoice/DocumentDetails";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

export function DocumentViewContainer() {
  return <DocumentView hideFloatingActionButtons />;
}

const DocumentView = ({
  existingDoc,
  onBackClick,
  docRef,
  projId,
  propId,
  onCancel,
  hideSiteHeaderButtons,
  hideDocumentActions,
  hideFloatingActionButtons,
  hideSiteHeader,
  backButtonTitle,
}) => {
  const { folderName, projectId, propertyId, documentId } = useParams();

  const location = useLocation();
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const { data: userData } = useUsers();
  const users = userData?.users;
  const {
    documents,
    removeDocument,
    isLoading,
    documentsDict,
    addDocument,
    addDocuments,
    reload,
  } = useDocuments();
  const { currentProject: project } = useProjects(projId ?? projectId);
  const { currentProperty: property } = usePropertyById(
    propId ?? propertyId,
    true
  );
  const [, dispatchAppState] = useAppState();
  const { data: docConfig } = useDocumentsConfiguration();
  const [document, dispatch] = useDocumentFormReducer();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  const [budget, setBudget] = useState({});
  const [selectedBudgetRows, setSelectedBudgetRows] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [budgetId, setBudgetId] = useState();
  const [loadingLineItems, setLoadingLineItems] = useState();
  const [originalLineItems, setOriginalLineItems] = useState();
  const [csiCodes, setCsiCodes] = useState([]);
  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);

  const [originalDocumentId, setOriginalDocumentId] = useState();
  const [parentDocument, setParentDocument] = useState();
  const [additionalDocuments, setAdditionalDocuments] = useState([]);

  const [showCommentsPopover, setShowCommentsPopover] = useState(false);

  const associationReference = project?.reference || property?.reference;

  useEffect(() => {
    setTabIndex(0);
  }, [documentId]);

  useEffect(() => {
    if (documents?.length && document?.documentRelationships?.parent) {
      const parentDoc = documents.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      setParentDocument(parentDoc);
    }

    if (documents?.length && document?.additionalDocuments?.length) {
      const allChildDocs = [];
      document?.additionalDocuments?.forEach((child) => {
        const childDoc = documents.find((doc) => doc.reference === child);
        if (childDoc) {
          allChildDocs.push(childDoc);
        }
      });
      setAdditionalDocuments(allChildDocs);
    }
  }, [documents, document]);

  const prepareDocument = React.useCallback(
    (dcmt) => {
      const documentData = Object.entries(dcmt).reduce((obj, entry) => {
        const [entryKey, entryValue] = entry;
        let newVal;
        if (
          entryKey === "qualificationsAndExclusions" ||
          entryKey === "submissionReqs"
        ) {
          newVal = entryValue.map((val) => ({
            id: uuidv4(),
            text: val,
          }));
        } else if (entryKey === "attachments") {
          newVal = entryValue.map((item) =>
            documents?.find((doc) => doc.reference === item)
          );
        } else if (entryKey === "distro") {
          newVal = entryValue.map((item) => ({ id: item }));
        }
        return { ...obj, [entryKey]: newVal ?? entryValue };
      }, {});

      documentData.hasLineItems = !!documentData.lineItems?.length;
      documentData.lineItemsSet = !documentData.hasLineItems;

      dispatch({
        type: RESET,
        value: { ...document, ...documentData },
      });
    },
    [documents, document, dispatch]
  );

  useEffect(() => {
    if (documentId) {
      setOriginalDocumentId(documentId);
    } else if (docRef) {
      setOriginalDocumentId(docRef.split("/")[1]);
    }
  }, [documentId, docRef]);

  useEffect(() => {
    if (
      originalDocumentId &&
      documents?.length &&
      document?.id !== originalDocumentId
    ) {
      const doc = documents?.find((item) => item.id === originalDocumentId);
      if (doc) {
        dispatch({
          type: RESET,
          value: doc,
        });
        prepareDocument(doc);
      }
    } else if (existingDoc && existingDoc !== originalDocumentId) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [
    originalDocumentId,
    document,
    documents,
    dispatch,
    prepareDocument,
    existingDoc,
    originalLineItems,
  ]);

  useEffect(() => {
    const getLineItems = async (id) => {
      setLoadingLineItems(true);
      const { budget: currentBudget, lineItems } = await getBudgetLineItems(id);
      setBudget(currentBudget);
      setOriginalLineItems(lineItems);
      setLoadingLineItems(false);
    };

    if (!originalLineItems && document?.budget) {
      const bId = document.budget.split("/")?.[1];
      getLineItems(bId);
      setBudgetId(bId);
    } else if (
      originalLineItems &&
      document?.hasLineItems &&
      !document.lineItemsSet
    ) {
      document.workingLineItems = document.lineItems.map((item) =>
        originalLineItems.find((li) => li.reference === item)
      );
      document.lineItemsSet = true;
    }
  }, [originalLineItems, document]);

  useEffect(() => {
    if (!selectedBudgetRows && document?.state?.checkedBudgetRows) {
      setSelectedBudgetRows(document.state.checkedBudgetRows);
    }
  }, [selectedBudgetRows, document]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const divisionsFormatted = [];
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.map((div) => {
        const division = {
          label: `${div.division} - ${div.description}`,
          value: div.division,
        };
        divisionsFormatted.push(division);

        div.csiCodes.map((csi) => {
          csi.subCodes.map((sub) => {
            const csiCodeFormatted = {
              label: `${div.division} ${csi.code} ${
                sub.code
              } - ${getCSICodeDescription(
                { division: div.division, code: csi.code, subcode: sub.code },
                financialsConfiguration?.financials?.csiCodeMapping,
                revenueAccountingCodes,
                expenseAccountingCodes
              )}`,
              value: `${div.division} ${csi.code} ${sub.code}`,
            };
            csiCodesFormatted.push(csiCodeFormatted);
            return sub;
          });

          return csi;
        });
        return div;
      });
      setCsiCodes(csiCodesFormatted);
    }
  }, [expenseAccountingCodes, financialsConfiguration, revenueAccountingCodes]);

  useEffect(() => {
    const showHistory = location?.pathname.includes("history");
    if (showHistory) {
      setTabIndex(1);
    }
  }, [location]);

  useEffect(() => {
    const showAdditionalDocuments = location?.pathname.includes(
      "additional-documents"
    );
    if (showAdditionalDocuments) {
      setTabIndex(2);
    }
  }, [location]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }
    },
    [favorites, deleteFavorite, postFavorite]
  );

  const actionsList = React.useMemo(
    () => [
      {
        icon: favorites?.some((fav) => fav.item.reference.includes(document.id))
          ? starIconGreen
          : starIcon,
        text: "Favorite",
        onClick: () => handleFavoriteClick(document?.id),
      },
      // Removed for 3.0
      // {
      //   icon: starIcon,
      //   text: "Link",
      //   onClick: () => {},
      // },
      // {
      //   icon: starIcon,
      //   text: "Message",
      //   onClick: () => {},
      // },
    ],
    [document, favorites, handleFavoriteClick]
  );

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        ?.filter((doc) => doc.selected && (doc.value || doc.id))
        ?.reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const resetDocumentForm = useCallback(
    (doc) => {
      dispatch({
        type: RESET,
        value: doc,
      });
      prepareDocument(doc);
    },
    [dispatch, prepareDocument]
  );

  const patchDocument = useCallback(
    async (updatedDocument, throwError) => {
      try {
        const { data } = await DocumentAPI.patch(
          document.id,
          updatedDocument,
          document
        );
        addDocument(data);
        resetDocumentForm(data);
      } catch (err) {
        if (throwError) {
          throw err;
        }
      }
    },
    [addDocument, document, resetDocumentForm]
  );

  const tabs = useMemo(
    () => [
      ...(document.docType === VENDOR_INVOICE
        ? [
            {
              title: "Summary",
              content: (
                <DocumentSummary
                  userList={users}
                  csiCodes={csiCodes}
                  document={document}
                  documentsDict={documentsDict}
                  selectedBudgetRows={selectedBudgetRows}
                />
              ),
            },
          ]
        : []),
      ...(document.docType === INVOICE
        ? [
            {
              title: "Details",
              content: (
                <DocumentDetails
                  userList={users}
                  csiCodes={csiCodes}
                  document={document}
                  project={project}
                  documentsDict={documentsDict}
                  selectedBudgetRows={selectedBudgetRows}
                  setShowCommentsPopover={setShowCommentsPopover}
                  originalLineItems={originalLineItems}
                />
              ),
            },
            {
              title: "Vendor Invoices",
              content: (
                <DocumentVendorInvoices
                  userList={users}
                  csiCodes={csiCodes}
                  documentsDict={documentsDict}
                  project={project}
                  setShowCommentsPopover={setShowCommentsPopover}
                  document={document}
                />
              ),
            },
            {
              title: "Expenses",
              content: (
                <DocumentExpenses
                  projectRef={`Project/${projId || projectId}`}
                  setShowCommentsPopover={setShowCommentsPopover}
                />
              ),
            },
            {
              title: "Timesheets",
              content: (
                <DocumentTimesheets
                  project={project}
                  csiCodes={csiCodes}
                  setShowCommentsPopover={setShowCommentsPopover}
                  document={document}
                />
              ),
            },
            {
              title: "Files",
              content: (
                <DocumentFiles
                  document={document}
                  patchDocument={patchDocument}
                  currentUser={currentUser}
                />
              ),
            },
          ]
        : []),
      ...(document.docType !== INVOICE
        ? [
            {
              title:
                document.docType === VENDOR_INVOICE
                  ? "Detailed Summary"
                  : "Document",
              content: (
                <div className="mb-40">
                  {!hideDocumentActions &&
                    document.docType !== VENDOR_INVOICE && (
                      <DocumentActions
                        className="pb-5"
                        actionsList={actionsList}
                      />
                    )}
                  <div className="">
                    <DocumentViewForm
                      document={document}
                      dispatch={dispatch}
                      budget={budget}
                      userList={users}
                      property={property}
                      project={project}
                      budgetId={budgetId}
                      associatedResource={null}
                      selectedBudgetRows={selectedBudgetRows}
                      onBudgetRowSelect={setSelectedBudgetRows}
                      originalLineItems={originalLineItems}
                      docTypeOptionsMap={docTypeOptionsMap}
                      csiCodes={csiCodes}
                      onSelectAssociatedResource={() => {}}
                      onCancel={onCancel}
                      editMode={location?.pathname.includes("edit")}
                      viewMode={!location?.pathname.includes("edit")}
                      hideFloatingActionButtons={hideFloatingActionButtons}
                      disableAssociation
                      documentsDict={documentsDict}
                      allDocuments={documents}
                      addDocument={addDocument}
                      addDocuments={addDocuments}
                      removeDocument={removeDocument}
                      reload={reload}
                    />
                  </div>
                </div>
              ),
            },
          ]
        : []),

      ...(document.docType === VENDOR_INVOICE
        ? [
            {
              title: "Attachments",
              content: (
                <DocumentAttachments
                  document={document}
                  docTypeOptionsMap={docTypeOptionsMap}
                />
              ),
            },
          ]
        : []),
      {
        title: "History",
        content: (
          <>
            {!hideDocumentActions && document.docType !== VENDOR_INVOICE && (
              <DocumentActions className="pb-5" actionsList={actionsList} />
            )}
            <DocumentHistory
              document={document}
              setShowCommentsPopover={setShowCommentsPopover}
            />
          </>
        ),
      },
      ...(document.docType !== VENDOR_INVOICE && document.docType !== INVOICE
        ? [
            {
              title: "Additional Documents",
              content: (
                <>
                  <AdditionalDocuments
                    document={document}
                    dispatch={dispatch}
                    docTypeOptionsMap={docTypeOptionsMap}
                    condensedView
                  />
                </>
              ),
            },
          ]
        : []),
    ],
    [
      document,
      users,
      csiCodes,
      documentsDict,
      selectedBudgetRows,
      project,
      projId,
      projectId,
      patchDocument,
      currentUser,
      hideDocumentActions,
      actionsList,
      dispatch,
      budget,
      property,
      budgetId,
      originalLineItems,
      docTypeOptionsMap,
      onCancel,
      location?.pathname,
      hideFloatingActionButtons,
      documents,
      addDocument,
      addDocuments,
      removeDocument,
      reload,
    ]
  );

  const deleteButtonHandler = async () => {
    setShowDeleteModal(true);
  };

  const handleDeleteDocument = async () => {
    setShowDeleteModal(false);
    const { data } = await DocumentAPI.getWOP(`${document.id}/$canedit`);
    if (data.canedit) {
      try {
        await DocumentAPI.delete(document.id);
        removeDocument(document.id);

        if (document?.additionalDocuments?.length) {
          document?.additionalDocuments.map((doc) =>
            removeDocument(doc.split("/")[1])
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        history.goBack();
      }
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be deleted`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const canSendToWorkflow = useCallback(async () => {
    const doesParentDocHasCurrentDocAsAdditionalDoc =
      parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
    // if document has parent, then block the access and toast msg
    if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
      return {
        canSend: false,
        errorMessage: `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
      };
    }

    const { data } = await DocumentAPI.getById(document.id);
    if (data.docType === PURCHASE_ORDER) {
      if (!["open", "requestResubmission"].includes(document.status)) {
        return {
          canSend: false,
          errorMessage: `Document is currently "${
            DOCUMENT_DISPLAY_STATUS[data?.status]
          }" and cannot be sent to workflow.`,
        };
      }
      if (!Object.keys(data?.state?.sov || {}).length) {
        return {
          canSend: false,
          errorMessage: `Document must have "Schedule of Values" for every lineitem before sending to workflow.`,
        };
      }

      let allLineitemsHaveSov = true;
      if (Object.keys(data.state.sov)?.length !== data?.lineItems?.length) {
        allLineitemsHaveSov = false;
      }
      Object.keys(data.state.sov).forEach((lineItemId) => {
        const sovsCount = data.state.sov[lineItemId]?.length;
        if (!sovsCount) {
          allLineitemsHaveSov = false;
        }
      });

      if (!allLineitemsHaveSov) {
        return {
          canSend: false,
          errorMessage: `Document must have "Schedule of Values" for every lineitem before sending to workflow.`,
        };
      }
    }
    return {
      canSend: true,
    };
  }, [document, parentDocument]);

  const handleSendToWorkflow = useCallback(async () => {
    const { canSend, errorMessage } = await canSendToWorkflow();
    if (canSend) {
      const { reference, project: proj, property: prop } = document;

      dispatchAppState({
        type: SET_WORKFLOW_MODAL_STATUS,
        association: proj || prop,
        open: true,
        documents: [
          reference,
          ...additionalDocuments.map((child) => child.reference),
        ],
      });
      // @deprecated
      // id document has children then also attach the children docs to the WF
      // dispatchAppState({
      //   type: SET_WORKFLOW_DOCUMENT,
      //   workflowDocument: [id, ...additionalDocuments.map((child) => child.id)],
      // });

      // if (proj) {
      //   history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(proj.split("/")[1]));
      // } else if (prop) {
      //   history.push(GET_PROPERTY_WORKFLOW_CREATE_PATH(prop.split("/")[1]));
      // } else {
      //   history.push(WORKFLOWS_ADD_NEW_PATH);
      // }
    } else {
      toastError(errorMessage, toastErrorIcon, toastCloseIcon);
    }
  }, [canSendToWorkflow, document, dispatchAppState, additionalDocuments]);

  const handleFileDownload = async () => {
    await downloadMedia([document.contentReference]);
  };

  const handleEditDocument = async () => {
    const { data } = await DocumentAPI.getWOP(`${document.id}/$canedit`);
    if (data.canedit) {
      if (folderName) {
        history.push(
          GET_DOCUMENT_FOLDER_FILE_EDIT_PATH(folderName, document.id)
        );
      } else if (projectId) {
        history.push(
          GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH(projectId, document.id)
        );
      } else {
        history.push(GET_DOCUMENT_EDIT_PATH(document.id));
      }
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be edited`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleSubmitVendorInvoice = async () => {
    const { data } = await DocumentAPI.getById(document.id);
    let allSovsHaveADollarAmount = true;

    Object.keys(document.state.sov)?.forEach((lineItemId) => {
      const allSovItems = document.state.sov[lineItemId];
      allSovItems.forEach((sovItem) => {
        if (sovItem?.workCompletedThisPeriodDollarValue === undefined) {
          allSovsHaveADollarAmount = false;
        }
      });
    });

    if (!["open"].includes(document.status)) {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be submitted`,
        toastErrorIcon,
        toastCloseIcon
      );
    } else if (!allSovsHaveADollarAmount) {
      toastError(
        `Document must have "Work Completed This Period" specified for every "Schedule of Values" before submitting.`,
        toastErrorIcon,
        toastCloseIcon
      );
    } else {
      try {
        const response = await DocumentAPI.patch(
          document.id,
          {
            ...document,
            status: "submitted",
          },
          document
        );
        addDocument(response.data);
        resetDocumentForm(response.data);
        // history.goBack();
      } catch (e) {
        toastError(`Error updating document`, toastErrorIcon, toastCloseIcon);
      }
    }
  };

  const handleAcceptVendorInvoice = async () => {
    const { data } = await DocumentAPI.getById(document.id);
    if (
      !["pendingVendorAcceptance", "pendingClientAcceptance"].includes(
        document.status
      )
    ) {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be submitted`,
        toastErrorIcon,
        toastCloseIcon
      );
    } else {
      await DocumentAPI.postByIdWOP(document.id, "$accept", {
        lastUpdated: document.metadata.lastUpdated,
      });

      history.goBack();
    }
  };

  const documentButtonActions = [
    ...(document.contentReference || document.docType === VENDOR_INVOICE
      ? []
      : [
          {
            title: "Edit",
            onClick: handleEditDocument,
          },
        ]),
    ...(document.workflow || document.docType === VENDOR_INVOICE
      ? []
      : [
          {
            title: "Send to Workflow",
            onClick: handleSendToWorkflow,
          },
        ]),
    ...(document.contentReference
      ? [
          {
            title: "Download",
            onClick: () => handleFileDownload(),
          },
        ]
      : []),
    ...(document.docType === PURCHASE_ORDER
      ? [
          {
            title: "Issue To Vendor",
            onClick: () => {},
          },
        ]
      : []),
    ...(document.docType === VENDOR_INVOICE
      ? []
      : [
          {
            title: "Delete",
            onClick: deleteButtonHandler,
          },
        ]),
    ...(document.docType === VENDOR_INVOICE &&
    (document.status === "open" || document.status === "rejected")
      ? [
          {
            title: "Submit",
            onClick: () => handleSubmitVendorInvoice(),
          },
        ]
      : []),
    ...(document.docType === VENDOR_INVOICE &&
    (document.status === "pendingClientAcceptance" ||
      document.status === "pendingVendorAcceptance")
      ? [
          {
            title: "Accept",
            onClick: () => handleAcceptVendorInvoice(),
          },
        ]
      : []),
  ];

  const getTabs = useMemo(() => {
    return tabs.filter((item) => {
      if (
        [
          PURCHASE_AUTHORIZATION,
          CONTINGENCY_AUTHORIZATION,
          CHANGE_ORDER,
        ].includes(document.docType)
      ) {
        return true;
      }
      return item.title !== "Purchase Orders";
    });
  }, [document, tabs]);

  const getCTAButton = () => {
    switch (document.docType) {
      case PURCHASE_ORDER: {
        return (
          <PrimaryButton
            title="Send to Workflow"
            large
            onClick={handleSendToWorkflow}
          />
        );
      }
      case VENDOR_INVOICE: {
        if (document.status === "open" || document.status === "rejected") {
          return (
            <PrimaryButton
              title="Submit"
              large
              onClick={handleSubmitVendorInvoice}
            />
          );
        }

        if (
          document.status === "pendingClientAcceptance" ||
          document.status === "pendingVendorAcceptance"
        ) {
          return (
            <PrimaryButton
              title="Accept"
              large
              onClick={handleAcceptVendorInvoice}
            />
          );
        }

        return <></>;
      }
      default: {
        return (
          <PrimaryButton
            title="Actions"
            dropdownItems={documentButtonActions}
            large
          />
        );
      }
    }
  };

  return (
    <>
      {!hideSiteHeader && (
        <SiteHeader
          title="Documents"
          buttons={
            !hideSiteHeaderButtons &&
            !location?.pathname.includes("edit") &&
            currentUser?.hasPermission?.("document", "can_write") &&
            getCTAButton()
          }
        />
      )}
      <div>
        {onBackClick && (
          <TertiaryButton
            title={backButtonTitle}
            onClick={onBackClick}
            iconLeft={<img src={chevronLeft} alt="" className="pr-2" />}
          />
        )}
        {(document.docType === VENDOR_INVOICE ||
          document.docType === INVOICE) && (
          <DocumentHeader project={project} document={document} />
        )}
        <WidgetContainer
          className={`p-4 border-gray-200 shadow-lg border rounded-md ${
            (isLoading || loadingLineItems) && "loading"
          }`}
          style={{ minWidth: "903px" }}
          onFinishEditing={() => {}}
          tabs={getTabs}
          loading={isLoading}
          disableEditing
          activeIndex={tabIndex}
          onTabClick={setTabIndex}
        />
      </div>
      <Modal
        title={
          document
            ? `Delete ${
                document.filename || document.customName || document.name
              }?`
            : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setShowDeleteModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete{" "}
            {document
              ? document.filename || document.customName || document.name
              : "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
      {showCommentsPopover && (
        <PopoverComments
          isOpen={showCommentsPopover}
          resourceReference={`Document/${document.id}`}
          association={associationReference}
          onClose={() => setShowCommentsPopover(false)}
        />
      )}
    </>
  );
};

DocumentView.propTypes = {
  /**
   * existing document
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  /**
   * handle click back to documents view
   */
  onBackClick: PropTypes.func,
  docRef: PropTypes.string,
  projId: PropTypes.string,
  propId: PropTypes.string,
  onCancel: PropTypes.func,
  hideSiteHeaderButtons: PropTypes.bool,
  hideDocumentActions: PropTypes.bool,
  hideFloatingActionButtons: PropTypes.bool,
  hideSiteHeader: PropTypes.bool,
  backButtonTitle: PropTypes.string,
};

DocumentView.defaultProps = {
  existingDoc: undefined,
  onBackClick: undefined,
  docRef: undefined,
  projId: undefined,
  propId: undefined,
  onCancel: undefined,
  hideSiteHeaderButtons: false,
  hideDocumentActions: false,
  hideFloatingActionButtons: false,
  hideSiteHeader: false,
  backButtonTitle: "Go Back",
};

export default DocumentView;
