import React from "react";
import PropTypes from "prop-types";
import { CALENDAR_STROKE } from "../../../../constants";

const DayBody = ({ hours }) => {
  // console.log("hours", hours);
  return (
    <svg viewBox={`-0.5 0.125 296 ${(hours.length - 1) * 15 + 1}`} width="100%">
      <g id="body-cells">
        {Array.from({ length: hours.length - 1 }, (_, i) => (
          <g
            id={`week-${hours[i]}-column-${i}`}
            key={`week-${hours[i]}-column-${i}`}
          >
            <rect
              // x = column * cell width
              x={15}
              // y = row * cell height
              y={i * 15}
              // width = cell width
              width={280}
              // height = cell height
              height={15}
              fill="white"
              stroke={CALENDAR_STROKE}
              strokeWidth="0.2"
            />

            <text
              // x = column * cell width + (1/2 cell width)
              x={15 - 2}
              // y = row * cell height + (20% of cell)
              y={i * 15}
              fontSize="3"
              fill="#333333"
              alignmentBaseline="middle"
              textAnchor="end"
            >
              {hours[i]}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};

DayBody.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string),
};

DayBody.defaultProps = {
  hours: [],
};

export default DayBody;
