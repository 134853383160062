import { AssetAPI, DocumentAPI } from "@griffingroupglobal/eslib-api";
import { decorateDocumentsWithUserData } from "./Document";
import { formatServerErrorMessage } from "./Formatters";

// eslint-disable-next-line import/prefer-default-export
export const getAssetDocuments = async (
  asset,
  documentRefs,
  currentUser,
  userDict
) => {
  const docArray = documentRefs ?? [];
  const refString = docArray.reduce(
    (str, val) => (str ? `${str},${val}` : `${val}`),
    ""
  );

  if (!refString) {
    return { documents: [] };
  }

  const queryString = `?reference=${refString}`;
  const [documentsData] = await Promise.all([
    DocumentAPI.getWOP(queryString).then((response) => {
      const data = response.data?.entries ?? [];
      return data;
    }),
  ]);

  const foundDocs = [];
  const missingDocs = [];
  documentRefs.forEach((ref) => {
    const found = documentsData.find(
      ({ resource }) => resource.reference === ref
    );
    if (found) {
      foundDocs.push(found);
    } else {
      missingDocs.push(ref);
    }
  });

  let returnedAsset;
  if (missingDocs.length) {
    const newAsset = {
      ...asset,
      documents: foundDocs.map(({ resource }) => resource.reference),
    };
    try {
      if (currentUser?.hasPermission?.("asset", "can_write")) {
        returnedAsset = await AssetAPI.patch(asset.id, newAsset, asset);
      }
    } catch (err) {
      const serverMsg = formatServerErrorMessage(err);
      console.error(serverMsg);
    }
  }

  decorateDocumentsWithUserData(foundDocs, userDict);
  const result = {
    documents: foundDocs.map((item) => item.resource),
    returnedAsset: returnedAsset?.data,
  };

  return result;
};
