import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { debounce } from "lodash";

import editIcon from "../../assets/images/editDarkenedGreenIcon.svg";
import removeIcon from "../../assets/images/RemoveDarkenedGreen.svg";

const editRemoveContainerCN = (className) => cntl`
    flex
    flex-row
    ${className}
`;

const deleteButtonCN = (isEditing) => cntl`
  ml-2
  w-5
  h-5
  ${isEditing ? "mt-3" : "mt-1"}
`;

const editButtonCN = (isEditing) => cntl`
  ml-2
  w-6
  h-6
  mt-0
  ${isEditing ? "mt-2" : "mt-0"}
`;

const EditRemoveActions = ({
  index,
  secondIndex,
  isEditing,
  toggleEditing,
  handleRemove,
  className,
  setAddMode,
  children,
  hideRemove,
  hideEdit,
}) => {
  const [showActionIcons, setShowActionIcons] = useState(false);

  const handleMouseEnter = useMemo(
    () => debounce(() => setShowActionIcons(true), 300),
    [setShowActionIcons]
  );

  const handleMouseLeave = useMemo(
    () => debounce(() => setShowActionIcons(false), 300),
    [setShowActionIcons]
  );

  return (
    <div
      className={editRemoveContainerCN(className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showActionIcons && (
        <>
          {!hideEdit && (
            <div className="flex-0 ml-1">
              <button
                className={editButtonCN(isEditing)}
                type="button"
                onClick={() => {
                  setAddMode(false);
                  toggleEditing(index, secondIndex);
                }}
              >
                <img
                  className="w-5 cursor-pointer"
                  src={editIcon}
                  alt="edit data"
                />
              </button>
            </div>
          )}
          {!hideRemove && (
            <div className="flex-0">
              <button
                className={deleteButtonCN(isEditing)}
                type="button"
                onClick={() => handleRemove(index, secondIndex)}
              >
                <img
                  className="w-8 cursor-pointer"
                  src={removeIcon}
                  alt="remove data"
                />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

EditRemoveActions.propTypes = {
  index: PropTypes.number,
  secondIndex: PropTypes.number,
  isEditing: PropTypes.bool,
  toggleEditing: PropTypes.func,
  handleRemove: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  setAddMode: PropTypes.func,
  hideRemove: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

EditRemoveActions.defaultProps = {
  index: undefined,
  secondIndex: undefined,
  isEditing: undefined,
  toggleEditing: undefined,
  handleRemove: undefined,
  className: undefined,
  setAddMode: undefined,
  hideRemove: false,
  hideEdit: false,
};

export default EditRemoveActions;
