import { FavoriteAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect } from "react";
import { SET_FAVORITES } from "../constants";
import { useAppState } from "../state/appState";

export default (resource, disabled = false) => {
  const [{ favorites }, dispatch] = useAppState([]);

  const reload = useCallback(async () => {
    if (!disabled) {
      const {
        data: { entries },
      } = await FavoriteAPI.get({ resource });
      const newFavorites = entries.map((entry) => entry.resource);
      dispatch({
        type: SET_FAVORITES,
        favorites: newFavorites,
      });
    }
  }, [disabled, dispatch, resource]);

  const postFavorite = async (id, resourceType) => {
    const body = {
      item: {
        reference: `${resourceType}/${id}`,
      },
    };

    const { data } = await FavoriteAPI.post(body);
    dispatch({
      type: SET_FAVORITES,
      favorites: [...favorites, data],
    });
  };

  const deleteFavorite = async (currentFavorite) => {
    await FavoriteAPI.delete(currentFavorite[0]?.id);
    dispatch({
      type: SET_FAVORITES,
      favorites: favorites.filter(
        (document) => document.id !== currentFavorite[0]?.id
      ),
    });
  };

  useEffect(() => {
    reload();
  }, [reload]);

  return [favorites, { postFavorite, deleteFavorite }];
};
