/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { formatCountriesDropdown } from "../../../helpers/Address";
import Input from "../Input/Input";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import BillingBaseAddressInput from "./BillingBaseAddressInput";
import { toastError, toastMessage } from "../Toast/Toast";

const countryList = require("country-list");

const cardInputCN = cntl`
  p-2.5
  ESInputContainer
`;

const innerCN = (className) => cntl`
  flex
  mx-8
  ${className}
`;

const errorMessageCN = cntl`
  capitalize-first
  text-xs
  text-brandRed
  italic
  pl-2
`;

const cardElementStyles = {
  style: {
    base: {
      fontSize: "16px",
      color: "#1A1A1A",
      fontWeight: 100,
      fontFamily: "Arial, Helvetica, sans-serif",
      "::placeholder": {
        color: "#A6A6A6",
        fontStyle: "italic",
        fontWeight: "normal",
      },
    },
    invalid: {
      color: "black",
    },
  },
};

const PaymentInformation = ({
  isEditingPayment,
  setIsEditingPayment,
  subscriptionDetails,
  dispatch,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [dropdownCountries, setDropdownCountries] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState();
  const [isSaving, setIsSaving] = useState();

  const paymentComplete = useMemo(() => {
    return (
      !!paymentDetails?.nameOnCard &&
      !!paymentDetails?.nameOnCard?.length &&
      !!paymentDetails?.cardNumber &&
      !!paymentDetails?.expiration &&
      !!paymentDetails?.cvc &&
      !!paymentDetails?.billingCountry &&
      !!paymentDetails?.billingCountry?.value?.length &&
      !!paymentDetails?.billingZipCode &&
      !!paymentDetails?.billingZipCode?.length
    );
  }, [
    paymentDetails?.billingCountry,
    paymentDetails?.billingZipCode,
    paymentDetails?.cardNumber,
    paymentDetails?.cvc,
    paymentDetails?.expiration,
    paymentDetails?.nameOnCard,
  ]);

  const cardInfoComplete = useMemo(() => {
    return (
      !!paymentDetails?.nameOnCard &&
      !!paymentDetails?.nameOnCard?.length &&
      !!paymentDetails?.cardNumber &&
      !!paymentDetails?.expiration &&
      !!paymentDetails?.cvc
    );
  }, [
    paymentDetails?.cardNumber,
    paymentDetails?.cvc,
    paymentDetails?.expiration,
    paymentDetails?.nameOnCard,
  ]);

  const onChange = useCallback((val, field) => {
    setPaymentDetails((prev) => ({
      ...prev,
      [field]: val,
    }));
  }, []);

  const cardnumber = elements.getElement(CardNumberElement);
  if (cardnumber)
    cardnumber.on("change", (f) => {
      onChange(f?.complete, "cardNumber");
    });

  const cardexpiry = elements.getElement(CardExpiryElement);
  if (cardexpiry)
    cardexpiry.on("change", (f) => {
      onChange(f?.complete, "expiration");
    });
  const cardcvc = elements.getElement(CardCvcElement);

  if (cardcvc)
    cardcvc.on("change", (f) => {
      onChange(f?.complete, "cvc");
    });

  useEffect(() => {
    setPaymentDetails(subscriptionDetails);
  }, [subscriptionDetails]);

  useEffect(() => {
    const countries = countryList.getNameList();

    const list = formatCountriesDropdown(countries);

    setDropdownCountries(list);
  }, []);

  const onChangeAddress = useCallback(
    (val) => {
      const billingAddress = Object.entries(val)?.reduce((obj, [key, item]) => {
        obj[`billing${key.charAt(0).toUpperCase() + key.slice(1)}`] = item;
        return obj;
      }, {});

      setPaymentDetails((prev) => ({
        ...prev,
        ...billingAddress,
        billingZipCode: billingAddress?.billingZipCode || "00000",
        billingCountry: {
          label:
            billingAddress?.billingCountry?.label ??
            billingAddress.billingCountry,
          value:
            billingAddress?.billingCountry?.value ??
            billingAddress.billingCountry,
          countryCode: dropdownCountries.find((country) =>
            billingAddress?.billingCountry?.label
              ? billingAddress?.billingCountry?.label === country.label
              : billingAddress?.billingCountry?.includes(country.label)
          ),
        },
        ...val,
      }));
    },
    [dropdownCountries]
  );

  const formAddress = useMemo(() => {
    return Object.entries(paymentDetails ?? {})?.reduce((obj, [key, item]) => {
      if (key?.includes("billing")) {
        const strippedKey = key?.replace("billing", "");
        obj[`${strippedKey.charAt(0).toLowerCase() + strippedKey.slice(1)}`] =
          item;
      }
      return obj;
    }, {});
  }, [paymentDetails]);

  const onCancel = useCallback(() => {
    dispatch({
      type: "reset",
      subscription: subscriptionDetails,
    });
    setIsEditingPayment(false);
  }, [dispatch, setIsEditingPayment, subscriptionDetails]);

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);

      const address = {
        line1: paymentDetails.billingStreet,
        line2: paymentDetails.billingStreet2,
        city: paymentDetails.billingCity,
        state: paymentDetails.billingState,
        country: paymentDetails.billingCountry.label,
        postal_code: paymentDetails.billingZipCode || "00000",
      };

      await SignUpAPI.updateCustomer(paymentDetails?.customerId, { address });

      const cardNumberElement = elements.getElement(CardNumberElement);

      const newPaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: paymentDetails.nameOnCard,
          address: {
            ...address,
            country: dropdownCountries.find(
              (country) => country.label === paymentDetails.billingCountry.label
            )?.countryCode,
          },
          email: paymentDetails.contactEmail,
          phone: paymentDetails.contactPhone,
        },
      });

      const updatedPaymentDetails = { ...paymentDetails };
      if (newPaymentMethod?.paymentMethod?.id) {
        const newCard = await SignUpAPI.updatePaymentCard(
          paymentDetails?.customerId,
          newPaymentMethod?.paymentMethod?.id
        );
        updatedPaymentDetails.cardType = newCard?.card?.brand;
      }

      dispatch({
        type: "reset",
        subscription: updatedPaymentDetails,
      });

      setIsEditingPayment(false);
      toastMessage("Successfully updated payment information");
    } catch (e) {
      console.error("payment error", e);
      toastError(
        `Error updating payment info. Please enter valid card details. ${
          e?.response?.data?.issues[0]?.location || e.message || ""
        }`
      );
    } finally {
      setIsSaving(false);
    }
  }, [
    dispatch,
    dropdownCountries,
    elements,
    paymentDetails,
    setIsEditingPayment,
    stripe,
  ]);

  return (
    <div className="flex w-2/3 border rounded px-8">
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full pt-8 pb-4 pr-4">
          <div className="pt-1">
            <SecondaryHeader>Payment Information</SecondaryHeader>
          </div>
          {!isEditingPayment && (
            <div>
              <TertiaryButton
                title="Edit"
                className="text-lg"
                onClick={() => setIsEditingPayment(true)}
                disabled={
                  subscriptionDetails?.subscriptionStatus === "canceled"
                }
              />
            </div>
          )}
        </div>
        {(isEditingPayment && (
          <div className="flex flex-row w-full">
            <div className="border-r pr-8 w-1/2">
              <div className="flex flex-col">
                <p className="text-lg font-semibold">Credit Card Information</p>
                {!cardInfoComplete && (
                  <p className={errorMessageCN}>
                    Invalid/incomplete card details
                  </p>
                )}
              </div>
              <div className="py-4">
                <div className="flex flex-col">
                  <div>
                    <p className="ESInputLabel mb-2">Name on Card</p>
                  </div>
                  <Input
                    placeholder="Name on card"
                    className="mb-3"
                    value={paymentDetails.nameOnCard}
                    onChange={(val) => onChange(val, "nameOnCard")}
                    validation={yup.string().required()}
                  />
                  <p className="ESInputLabel mb-2">Card Number</p>
                  <CardNumberElement
                    className={cardInputCN}
                    options={cardElementStyles}
                    onChange={(val) => onChange(val?.brand, "cardType")}
                  />
                  <div className="flex flex-row items-end w-full py-4">
                    <div className="w-1/2 mr-4">
                      <p className="ESInputLabel mb-2">Expiration Date</p>
                      <CardExpiryElement
                        className={cardInputCN}
                        options={cardElementStyles}
                        placeholder="MM/YY"
                      />
                    </div>
                    <div className="w-1/2">
                      <p className="ESInputLabel mb-2">CVC</p>
                      <CardCvcElement
                        className={cardInputCN}
                        options={cardElementStyles}
                        placeholder="Enter CVC"
                        value="hello"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-8 w-1/2">
              <p className="text-lg font-semibold mb-4">Billing Address</p>
              <div className="py-4">
                <div className="flex flex-col">
                  <BillingBaseAddressInput
                    addressGroupCN="flex flex-row w-full items-end"
                    address={formAddress}
                    onChange={onChangeAddress}
                  />
                  <div className="flex justify-end py-4">
                    <TertiaryButton
                      title="Cancel"
                      onClick={onCancel}
                      disabled={isSaving}
                    />
                    <PrimaryButton
                      title="Save Changes"
                      onClick={onSave}
                      disabled={!paymentComplete || isSaving}
                      className="ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div className={innerCN("flex-row w-full")}>
            <div className="border-r pr-4 w-1/2">
              <p className="text-lg font-semibold">Credit Card Information</p>
              <div className="py-4">
                <div className="flex flex-row w-full">
                  <div className="flex flex-col w-1/3">
                    <p className="text-lg font-semibold py-2">Name on Card</p>
                    <p className="text-lg font-semibold py-2">Card Type</p>
                  </div>
                  <div className="flex flex-col w-2/3">
                    <p className="text-lg text-gray-200 py-2">
                      {subscriptionDetails.nameOnCard}
                    </p>
                    <p className="text-lg text-gray-200 py-2 capitalize">
                      {subscriptionDetails.cardType}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-8 w-1/2">
              <p className="text-lg font-semibold">Billing Address</p>
              <div className="py-4">
                <div className="flex flex-col">
                  <p className="text-lg text-gray-200">
                    {subscriptionDetails.billingStreet}
                  </p>
                  <p className="text-lg text-gray-200">
                    {subscriptionDetails.billingStreet2}
                  </p>
                  <p className="text-lg text-gray-200">
                    {subscriptionDetails.billingCity}
                    {subscriptionDetails.billingState && ","}{" "}
                    {subscriptionDetails.billingState}{" "}
                    {subscriptionDetails.billingZipCode}
                  </p>
                  <p className="text-lg text-gray-200">
                    {subscriptionDetails.billingCountry?.label}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentInformation.propTypes = {
  isEditingPayment: PropTypes.bool,
  setIsEditingPayment: PropTypes.func,
  subscriptionDetails: PropTypes.shape({
    contactFirstName: PropTypes.string,
    nameOnCard: PropTypes.string,
    cardType: PropTypes.string,
    billingStreet: PropTypes.shape({
      label: PropTypes.string,
    }),
    billingStreet2: PropTypes.string,
    billingCity: PropTypes.string,
    billingCountry: PropTypes.shape({
      label: PropTypes.string,
    }),
    billingState: PropTypes.string,
    billingZipCode: PropTypes.string,
    subscriptionStatus: PropTypes.string,
  }),
  dispatch: PropTypes.func,
};

PaymentInformation.defaultProps = {
  isEditingPayment: false,
  setIsEditingPayment: undefined,
  subscriptionDetails: undefined,
  dispatch: undefined,
};

export default PaymentInformation;
