import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useState } from "react";
import {
  SET_PROPERTIES,
  SET_PROPERTIES_DD,
  SET_PROPERTIES_DICT,
} from "../constants";
import { useAppState } from "../state/appState";

export default () => {
  const [{ properties, propertiesDict }, dispatch] = useAppState([]);
  const [loading, setLoading] = useState(true);

  const reload = useCallback(async () => {
    const dropDown = [];
    const {
      data: { entries },
    } = await PropertyAPI.get();
    const newProperties = entries.map((entry) => {
      dropDown.push({
        label: entry.resource?.title,
        value: entry.resource?.reference,
        id: entry.resource?.id,
      });

      return entry.resource;
    });
    dispatch({
      type: SET_PROPERTIES,
      properties: newProperties,
    });
    dispatch({
      type: SET_PROPERTIES_DICT,
      propertiesDict: newProperties.reduce(
        (acc, v) => ({ ...acc, [v.reference]: v }),
        {}
      ),
    });
    dispatch({
      type: SET_PROPERTIES_DD,
      dropDown,
    });

    dispatch({
      type: SET_PROPERTIES,
      properties: newProperties,
    });
    setLoading(false);
  }, [dispatch]);

  const removeProperty = (deletedPropertyId) => {
    PropertyAPI.delete(deletedPropertyId)
      .then(() => {
        const propertiesDD = [];
        const newProperties = properties
          ?.filter(({ id }) => deletedPropertyId !== id)
          .map((e) => {
            propertiesDD.push({
              label: e?.name,
              value: e?.reference,
              id: e?.id,
            });
            return e;
          });

        dispatch({
          type: SET_PROPERTIES,
          properties: newProperties,
        });
        dispatch({
          type: SET_PROPERTIES_DD,
          propertiesDD,
        });
        dispatch({
          type: SET_PROPERTIES_DICT,
          propertiesDict: newProperties.reduce(
            (acc, v) => ({ ...acc, [v.reference]: v }),
            {}
          ),
        });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    reload();
  }, [reload]);

  return [properties, reload, loading, propertiesDict, removeProperty];
};
