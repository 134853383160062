import React, { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import PropTypes from "prop-types";
import PurePayrollTable from "./PurePayrollTable";
import {
  getIsoDate,
  rangeToMoments,
  weeksFollowingDay,
} from "../../../helpers/TimeSheet";
import usePagePersistance from "../../../hooks/usePagePersistence";

const { TimeSheets } = TimesheetUtil;

const PayrollTable = ({
  financials,
  payrollHistory,
  reload,
  currentUser,
  loading,
  timesheets,
  selectedRows,
  setSelectedRows,
  warnings,
  handleEditRow,
  customSiteHeader,
  pto,
}) => {
  const { pageState, setPersistentPageItem } = usePagePersistance();

  const currentWeek = useMemo(() => {
    const currentFrame = TimeSheets.getPeriodFrame(
      financials?.period,
      financials?.start,
      getIsoDate()
    );
    return rangeToMoments(currentFrame?.periodStart, currentFrame?.periodEnd);
  }, [financials]);

  const [dates, setDates] = useState(currentWeek);

  useEffect(() => {
    setDates(currentWeek);
  }, [currentWeek]);

  /**
   * @function showNextWeek
   * @summary - disables next week navigation if the 1st day of
   * next week is outside the pay period
   * Dev Notes: Two types biweekly & weekly (array element 13 & 6 are the last days)
   */

  const forwardButtonDisabled = useMemo(() => {
    return moment(pageState?.timesheet?.periodEnd).isAfter(moment(), "day");
  }, [pageState?.timesheet?.periodEnd]);

  const showNextWeek = useMemo(() => {
    if (forwardButtonDisabled) {
      return null;
    }

    return () => {
      const newStartDate = moment(pageState?.timesheet?.periodStart)
        .add(7, "days")
        .format("YYYY-MM-DD");
      const newEndDate = moment(pageState?.timesheet?.periodEnd)
        .add(7, "days")
        .format("YYYY-MM-DD");

      setPersistentPageItem("timesheet", {
        ...pageState?.timesheet,
        periodStart: newStartDate,
        periodEnd: newEndDate,
      });

      const nextWeek = weeksFollowingDay(
        financials?.start,
        dates[financials?.period === "everyotherweek" ? 13 : 6]
          .add(1, "days")
          .format(),
        financials?.period === "everyotherweek" ? 2 : 1
      );
      setDates(nextWeek);
      reload(
        moment(nextWeek[0]).format("YYYY-MM-DD"),
        moment(
          nextWeek[financials?.period === "everyotherweek" ? 13 : 6]
        ).format("YYYY-MM-DD")
      );
    };
  }, [
    dates,
    financials?.period,
    financials?.start,
    forwardButtonDisabled,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);

  const showPrevWeek = useCallback(() => {
    const newStartDate = moment(pageState?.timesheet?.periodStart)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    const newEndDate = moment(pageState?.timesheet?.periodEnd)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: newStartDate,
      periodEnd: newEndDate,
    });

    const previousWeek = weeksFollowingDay(
      financials?.start,
      moment(dates[0].format())
        .subtract(financials?.period === "everyotherweek" ? 14 : 7, "days")
        .format(),
      financials?.period === "everyotherweek" ? 2 : 1
    );
    setDates(previousWeek);
    reload(
      moment(previousWeek[0]).format("YYYY-MM-DD"),
      moment(
        previousWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD")
    );
  }, [
    dates,
    financials?.period,
    financials?.start,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);

  const showThisWeek = useCallback(() => {
    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: moment(currentWeek[0]).format("YYYY-MM-DD"),
      periodEnd: moment(
        currentWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD"),
    });

    // remanence of old system, changing this wil likely break the list
    setDates(currentWeek);
    reload(
      moment(currentWeek[0]).format("YYYY-MM-DD"),
      moment(
        currentWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD")
    );
  }, [
    currentWeek,
    financials?.period,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);
  /**
   * Time Navigation Func/Var End
   */

  const data = useMemo(() => {
    return timesheets?.map((item) => {
      const newItem = { ...item };
      if (item?.payrollStatus === "approved") {
        newItem.status = "payrollApproved";
      }
      return { ...newItem };
    });
  }, [timesheets]);

  return (
    <PurePayrollTable
      currentUser={currentUser}
      data={data}
      historyData={payrollHistory}
      dates={dates}
      showNextWeek={showNextWeek}
      showPrevWeek={showPrevWeek}
      showThisWeek={showThisWeek}
      loading={loading}
      pto={pto}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      warnings={warnings}
      handleEditRow={handleEditRow}
      customSiteHeader={customSiteHeader}
    />
  );
};

PayrollTable.propTypes = {
  financials: PropTypes.shape({
    start: PropTypes.string,
    period: PropTypes.string,
  }),
  periodFrame: PropTypes.shape({
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
  }),
  payrollHistory: PropTypes.shape({}),
  reload: PropTypes.func,
  currentUser: PropTypes.shape({}),
  loading: PropTypes.bool,
  timesheets: PropTypes.arrayOf(PropTypes.shape({})),
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedRows: PropTypes.func,
  warnings: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }),
  handleEditRow: PropTypes.func,
  /**
   * Used to set Custom Site Header From Parent
   * (Outside the Table Component But maintaining Functionality)
   */
  customSiteHeader: PropTypes.element,
  pto: PropTypes.arrayOf(PropTypes.shape({})),
};

PayrollTable.defaultProps = {
  financials: undefined,
  periodFrame: undefined,
  payrollHistory: undefined,
  reload: undefined,
  currentUser: undefined,
  loading: true,
  timesheets: undefined,
  selectedRows: undefined,
  setSelectedRows: undefined,
  warnings: undefined,
  handleEditRow: undefined,
  customSiteHeader: undefined,
  pto: [],
};

export default PayrollTable;
