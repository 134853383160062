import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import useProjects from "../../../hooks/useProjects";
import useProperties from "../../../hooks/useProperties";
// import useCompany from "../../../hooks/useCompany";

import Dropdown from "../Dropdown/Dropdown";
import { PROJECT_STATUS_TYPES } from "../../../constants";

// TODO: Post 3.0 release, add projects and companies bacck to the list
const ResourceDropDown = ({
  resource,
  setResource,
  required,
  validation,
  showTextView,
  disableAssociation,
  noLabel,
  customOnChange,
  name,
  placeholder,
  forwardedRef,
  onKeyUp,
  className,
  bottomMessage,
  onlyActiveProjects,
  ...props
}) => {
  const { projects } = useProjects();
  const [properties] = useProperties();
  // const [companies] = useCompany();

  const [options, setOptions] = useState([]);
  const [optionsFull, setOptionsFull] = useState([]);

  useEffect(() => {
    const projectDD = projects?.reduce((acc, info) => {
      if (!onlyActiveProjects) {
        acc.push({
          label: info?.name,
          value: info?.reference,
        });
      } else if (info.status === PROJECT_STATUS_TYPES.active) {
        acc.push({
          label: info?.name,
          value: info?.reference,
        });
      }

      return acc;
    }, []);

    const propertyDD = properties?.map((info) => ({
      label: info?.title,
      value: info?.reference,
    }));
    // const companyDD = companies?.map((info) => ({
    //   label: info?.name,
    //   value: info?.reference,
    // }));

    const tempOptions = [
      // { label: "Entities", options: companyDD },
      { label: "Projects", options: projectDD },
      { label: "Properties", options: propertyDD },
    ];
    setOptions(tempOptions);
    setOptionsFull(
      tempOptions.reduce((arr, item) => {
        return [...arr, ...(item.options ?? [])];
      }, [])
    );
  }, [properties, projects, onlyActiveProjects]);

  const onChange = (option) => {
    if (customOnChange) {
      customOnChange();
    }
    let resourceType = option.value?.split("/")[0].toLowerCase();
    if (typeof resource === "string") {
      resourceType = resourceType ?? resource.split("/")[0].toLowerCase();
    } else {
      resourceType =
        resourceType ?? resource?.value?.split("/")[0].toLowerCase();
    }

    const resourceName = option.label;
    const val = option.value ?? "";
    setResource(val, resourceType, resourceName);
  };

  const onClear = () => {
    setResource(undefined);
  };

  const getDropdownValue = () => {
    let identifier = resource;
    if (resource && typeof resource !== "string") {
      identifier = resource.value;
    }

    if (showTextView) {
      const res = optionsFull.find((item) => item.value === identifier);
      return res?.label;
    }

    return optionsFull.find((item) => item.value === identifier);
  };

  const getLabel = () => {
    if (!noLabel) {
      return required ? "Association (required)" : "Association";
    }
    return undefined;
  };

  return (
    <>
      {showTextView && getDropdownValue()}
      {!showTextView && (
        <Dropdown
          className={className}
          options={options}
          label={getLabel()}
          placeholder={placeholder || "Search Resources..."}
          value={getDropdownValue()}
          onChange={onChange}
          validation={validation}
          bottomMessage={bottomMessage}
          isDisabled={disableAssociation}
          onRequestDropdownClear={onClear}
          name={name}
          forwardedRef={forwardedRef}
          onKeyUp={onKeyUp}
          {...props}
        />
      )}
    </>
  );
};

ResourceDropDown.propTypes = {
  /**
   * current resource selected
   */
  resource: PropTypes.oneOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string
  ),
  /**
   * function for setting the resource
   */
  setResource: PropTypes.func,
  /**
   * Change Label to include (Required)
   */
  required: PropTypes.bool,
  /**
   * Validation for required DDL value
   */
  validation: PropTypes.shape({}),
  showTextView: PropTypes.bool,
  disableAssociation: PropTypes.bool,
  noLabel: PropTypes.bool,

  /**
   * function for handling custom onChange functionality
   */
  customOnChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  forwardedRef: PropTypes.func,
  onKeyUp: PropTypes.func,
  className: PropTypes.string,

  //
  bottomMessage: PropTypes.bool,
  // retuns only active projects in the dropdown
  onlyActiveProjects: PropTypes.bool,
};

ResourceDropDown.defaultProps = {
  resource: undefined,
  setResource: () => {},
  required: false,
  validation: undefined,
  showTextView: false,
  disableAssociation: false,
  noLabel: false,
  customOnChange: undefined,
  name: undefined,
  placeholder: undefined,
  forwardedRef: undefined,
  onKeyUp: undefined,
  className: undefined,
  bottomMessage: false,
  onlyActiveProjects: false,
};

export default ResourceDropDown;
