import { useCallback, useEffect, useState } from "react";
import { useAppState } from "../../../state/appState";

const associationTypesOptions = [
  { id: "property", label: "Property", value: "property" },
  { id: "project", label: "Project", value: "project" },
  { id: "asset", label: "Asset", value: "asset" },
];

const useAssociationDropdownWithControlsData = ({
  associations,
  setAssociations,
  associationType,
  isMulti,
}) => {
  const [
    {
      propertiesDD,
      projectDD,
      assetsDD,
      propertiesDict,
      projectDict,
      assetsDict,
    },
  ] = useAppState();

  // State that holds selected association type: property | project | asset
  const [associationTypeSelected, setAssociationTypeSelected] =
    useState(associationType);
  // State that holds associations based on association type
  const [associationsOptions, setAssociationsOptions] = useState([]);
  // State that holds values for dropdown component
  const [value, setValue] = useState();

  // Initializes association type with given value or property by default
  useEffect(() => {
    setAssociationTypeSelected(associationType ?? "property");
  }, [associationType]);

  useEffect(() => {
    if (associationTypeSelected) {
      setAssociationsOptions(() => {
        if (associationTypeSelected === "property") {
          return propertiesDD;
        }

        if (associationTypeSelected === "project") {
          return projectDD;
        }

        if (associationTypeSelected === "asset") {
          return assetsDD;
        }

        return [];
      });
    }
  }, [associationTypeSelected, propertiesDD, projectDD, assetsDD]);

  const handleSetValue = useCallback(
    (valueSelected) => {
      const selectedValue = isMulti
        ? valueSelected.map((val) => val.value)
        : valueSelected.value;

      setAssociations(selectedValue);
      setValue(valueSelected);
    },
    [isMulti, setAssociations]
  );

  /**
   * Transform association reference into value form for dropdown
   */
  const getAssociationData = useCallback(
    (associationReference) => {
      const associationObj =
        propertiesDict[associationReference] ||
        projectDict[associationReference] ||
        assetsDict[associationReference];
      return {
        id: associationObj?.id,
        label: associationObj?.name ?? associationObj?.title,
        value: associationReference,
      };
    },
    [propertiesDict, projectDict, assetsDict]
  );

  /**
   * This effect set the dropdown with the current association(s).
   * This is done if associations prop is set with single (object) or multiple(array)
   */
  useEffect(() => {
    if (associations) {
      const associationsArrayObj = isMulti
        ? associations.map((val) => getAssociationData(val))
        : getAssociationData(associations[0]);

      setValue(associationsArrayObj);
    }
  }, [associations, isMulti, associationsOptions, getAssociationData]);

  return {
    associationsOptions,
    associationTypesOptions,
    associationTypeSelected,
    value,
    handleSetValue,
    setAssociationTypeSelected,
  };
};

export default useAssociationDropdownWithControlsData;
