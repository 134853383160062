import { isEqual } from "lodash";
import { TaskAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { taskKeys } from "../../../../config/reactQuery/queryKeyFactory";
import {
  EDIT_RECURRENCE_POPUP,
  SET_TASK_FOR_SERVICE_REQUEST,
  TASK_EMPTY,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { isFullDay } from "../../../../helpers/Calendar";
import { formatTaskObj } from "../../../../helpers/Formatters";
import useEsTasks from "../../../../hooks/useEsTasks";
import useTaskFormReducer from "../../../../hooks/useTaskFormReducer";
import { useAppState } from "../../../../state/appState";
import useEventModalData from "../ViewEventModal/useEventModalData";
import { toastError, toastMessage } from "../../Toast/Toast";
import determineTimeSpan from "../../../../helpers/Date/determineTimeSpan";
import useFilesPost from "../../../../hooks/useFilesPost";
import hasRestrictedTaskFieldsChanged from "../../../../helpers/Task/hasRestrictedTaskFieldsChanged";
import hasRecurrenceFieldChanged from "../../../../helpers/Calendar/hasRecurrenceFieldChanged";
import { isValidRecurrence } from "../../../../helpers/rRule";

const useCreateEditTaskModal = ({ modal }) => {
  const { item: existingTask } = modal?.modalData;

  const formattedTags =
    modal?.modalData?.formData?.currentTags?.map((tag) => tag.value) || [];

  const formForExistingTask = {
    ...existingTask,
    previousTask: existingTask,
  };
  // If creating a new task, format tags to just have reference.
  const formForNewTask = {
    ...modal?.modalData?.formData,
    tags: formattedTags,
  };

  delete formForNewTask?.originalResource?.originalResource;

  // If editing an existing task, use existing task data.
  // Otherwise, use data from modal.
  const dataForReducer = existingTask ? formForExistingTask : formForNewTask;

  const [form, dispatch] = useTaskFormReducer(dataForReducer);

  const { sopOptions } = useEventModalData(modal?.modalData);

  const [, appStateDispatch] = useAppState();
  const { addToTaskDictionary, editTaskInDictionary } = useEsTasks();
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const [association, setAssociation] = useState();

  const { mutateAsync: onUpload } = useFilesPost();

  const initialTask = useMemo(
    () => ({
      ...TASK_EMPTY,
      association: association ?? form?.association,
      startDate: form?.startDate,
      endDate: form?.endDate,
    }),
    [association, form?.association, form?.endDate, form?.startDate]
  );

  const confirmClose = useCallback(() => {
    const isSame = isEqual(initialTask, form);
    return !isSame;
  }, [form, initialTask]);

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = form?.files?.reduce(
      (list, file) => {
        if (!file?.isEditing && file?.type) {
          list.latest.push(file);
          return list;
        }
        list.previous.push(file);
        return list;
      },
      { latest: [], previous: [] }
    );
    const uploadedFiles = await onUpload(filteredFiles.latest);

    return [...filteredFiles.previous, ...uploadedFiles];
  }, [form?.files, onUpload]);

  const taskCall = (uploads) => {
    if (!existingTask) {
      const taskPostObject = {
        ...formatTaskObj(form),
        files: uploads.map((file) => ({
          ref: file.reference || file.ref,
          category: file.category,
        })),
      };

      if (taskPostObject.recurrence) {
        // check if first recurring instance start date < until
        const isValid = isValidRecurrence({
          rRuleString: taskPostObject.recurrence,
        });

        if (!isValid) throw Error("Invalid custom repeat detected.");
      }

      return TaskAPI.post(taskPostObject);
    }

    const { reference, invitees, originalKey, files, metadata, ...rest } = form;

    let newTask = {
      ...rest,
      reference,
      metadata: { ...metadata, lastUpdated: moment().format() },
      invitees: invitees?.map((invitee) => invitee.value ?? invitee),
      // Not Allowing empty Steps -> Checking for description and sop
      steps: form?.steps?.length
        ? form?.steps?.filter((item) => item?.description || item?.sop)
        : [],
    };

    newTask = formatTaskObj(newTask);

    return TaskAPI.patch(
      existingTask.id,
      {
        ...newTask,
        files: uploads.map((file) => ({
          ref: file.reference || file.ref,
          category: file.category,
        })),
        // Not Allowing empty Steps -> Checking for description and sop
        steps: newTask?.steps?.length
          ? newTask?.steps?.filter((item) => item?.description || item?.sop)
          : [],
        spaces: newTask?.spaces ?? [],
      },
      existingTask
    );
  };

  const handleSubmit = async () => {
    setSaving(true);
    dispatch({
      type: "reset",
    });

    if (existingTask?.recurrence) {
      // check if edit all should be disabled
      const hasFieldsChanged = hasRestrictedTaskFieldsChanged(
        existingTask,
        form
      );

      const hasRecurrenceChanged = hasRecurrenceFieldChanged(
        existingTask,
        form
      );

      appStateDispatch({
        type: TOGGLE_POSITIONED_POPUP,
        position: {
          centered: true,
        },
        popupData: {
          item: existingTask,
          form,
          popupWidth: 444,
          disableEditAll: hasFieldsChanged,
          disableEditThis: hasRecurrenceChanged,
        },
        popupType: EDIT_RECURRENCE_POPUP,
      });
      return;
    }

    handleFilesUploaded()
      .then((uploads) => {
        return taskCall(uploads);
      })
      .then((res) => {
        if (existingTask) {
          // update tasks activity
          queryClient.invalidateQueries(taskKeys.taskHistory);
          // Check if editing an existing task.
          // Update local state to include edited task.
          toastMessage("Task Edited");

          const editedTask = {
            ...res?.data,
          };
          // Check if task is full day.
          const { startDate, endDate, allDay } = res?.data;
          const fullDay = isFullDay(startDate, endDate, allDay);
          // If task is full day, determine and set the day span.
          if (fullDay) {
            const daySpan = determineTimeSpan(startDate, endDate);
            editedTask.daySpan = daySpan;
          }

          editTaskInDictionary(editedTask, existingTask);
        } else {
          // Dispatch this new Task using appState
          // Any active subscriber can listen to it
          if (res?.data?.id) {
            appStateDispatch({
              type: SET_TASK_FOR_SERVICE_REQUEST,
              taskForRequest: {
                id: res.data.id,
                name: res.data.name,
                startDate: res.data.startDate,
                endDate: res.data.endDate,
                status: res.data.status,
                reference: res.data.reference,
              },
            });
          }

          // Notify user that task was created.
          toastMessage("Task Created");
          // Update local state to include new task.
          const newTask = {
            ...res?.data,
          };
          const fullDay = isFullDay(
            res?.data?.startDate,
            res?.data?.endDate,
            res?.data?.allDay
          );

          if (fullDay) {
            const { startDate, endDate } = res?.data;
            const daySpan = determineTimeSpan(startDate, endDate);

            newTask.daySpan = daySpan;
          }

          addToTaskDictionary(newTask);
        }
      })
      .catch((err) => {
        // Handle errors during the process.

        if (existingTask) {
          toastError(
            `Task could not be updated. ${err.message && err.message}`
          );
        } else {
          toastError(
            `Task could not be created. ${err.message && err.message}`
          );
        }

        console.error(
          err,
          "Error Submitting Task -> useCreateEditTaskModal.js"
        );
      })
      .finally(() => {
        // Reset 'saving' state.
        setSaving(false);
      });
  };

  return {
    form,
    handleSubmit,
    valid,
    saving,
    confirmClose,
    changesMade,
    setAssociation,
    dispatch,
    setValid,
    setChangesMade,
    sopOptions,
    initialTask,
    existingTask,
  };
};

export default useCreateEditTaskModal;
