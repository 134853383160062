import React, { useState, useEffect, useCallback } from "react";
import { findIndex } from "lodash";
import {
  FileAPI,
  PropertyAPI,
  ProjectAPI,
} from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { useAppState } from "../state/appState";

import { SET_ASSOCIATED_FILES } from "../constants";
import {
  filePaginatedKeys,
  tagKeys,
} from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

export default function useAssociatedFiles(params = {}) {
  const queryClient = useQueryClient();
  const [{ associatedFiles, associatedFilesDict }, dispatch] = useAppState();
  const [isLoading, setIsLoading] = useState(true);

  const updateAppState = useCallback(
    (state) => {
      dispatch({
        type: SET_ASSOCIATED_FILES,
        associatedFiles: state.associatedFiles,
        associatedFilesDict: state.associatedFilesDict,
      });
    },
    [dispatch]
  );

  const reload = React.useCallback(async () => {
    if (params.association) {
      updateAppState({
        associatedFiles: [],
        associatedFilesDict: {},
      });

      try {
        setIsLoading(true);

        const { data } = await FileAPI.get({
          params: { association: params.association },
        });

        let parentResource;
        let filteredFiles = [...data.entries];
        const resourceType = params.association.split("/")[0];

        // get parent association resource
        switch (resourceType) {
          case "Property": {
            const id = params.association.split("/")[1];
            try {
              const res = await PropertyAPI.getById(id);
              parentResource = res.data;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log("Error getting Property for associated files", e);
            }

            break;
          }

          case "Project": {
            const id = params.association.split("/")[1];
            try {
              const res = await ProjectAPI.getById(id);
              parentResource = res.data;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log("Error getting Property for associated files", e);
            }
            break;
          }
          default: {
            // "Do not need spaces for Asset
          }
        }

        // get files for all the spaces
        const allSpaceFiles = parentResource?.spaces?.reduce((acc, space) => {
          // eslint-disable-next-line no-param-reassign
          acc = [...acc, ...space.files.map((file) => file.ref)].filter(
            (ref) => !!ref
          );
          return acc;
        }, []);

        if (params.spaceId) {
          // Get files associated to provided spaceId
          const associatedSpaceFiles = parentResource.spaces.reduce(
            (acc, space) => {
              if (space.id === params.spaceId) {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...space.files.map((file) => file.ref)].filter(
                  (ref) => !!ref
                );
              }
              return acc;
            },
            []
          );

          // filter file resources for the provided spaceId
          filteredFiles = filteredFiles.filter(({ resource }) =>
            associatedSpaceFiles.includes(resource.reference)
          );
        } else if (allSpaceFiles?.length) {
          // filter file resources to drop all space related files
          filteredFiles = filteredFiles.filter(
            ({ resource }) => !allSpaceFiles.includes(resource.reference)
          );
        }

        const state = filteredFiles.reduce(
          (acc, { resource }) => {
            return {
              associatedFiles: [...acc.associatedFiles, resource],
              associatedFilesDict: {
                ...acc.associatedFilesDict,
                [`${resource.reference}`]: resource,
              },
            };
          },
          { associatedFiles: [], associatedFilesDict: {} }
        );

        updateAppState({
          ...state,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      updateAppState({
        associatedFiles: [],
        associatedFilesDict: {},
      });
    }
  }, [params, updateAppState]);

  const addFile = React.useCallback(
    (file) => {
      const found = associatedFiles?.find(
        (item) => item.reference === file.reference
      );
      let tempFiles = [...associatedFiles] ?? [];
      if (found) {
        tempFiles = tempFiles.map((item) => {
          if (item.reference === file.reference) {
            return { ...item, ...file };
          }
          return item;
        });
      } else {
        tempFiles.push(file);
      }

      const updatedDict = { ...associatedFilesDict };
      updatedDict[file.reference] = file;

      updateAppState({
        associatedFiles: tempFiles,
        associatedFilesDict: updatedDict,
      });
    },
    [associatedFiles, associatedFilesDict, updateAppState]
  );

  const addFiles = React.useCallback(
    (files) => {
      // for new files
      const notFound = files?.filter(
        (file) =>
          findIndex(associatedFiles, (assFile) => {
            return assFile.reference === file.reference;
          }) === -1
      );

      let tempFiles = [...associatedFiles] ?? [];
      // for existing files
      if (notFound?.length !== files?.length) {
        const found = files?.filter(
          (file) =>
            findIndex(associatedFiles, (assFile) => {
              return assFile.reference === file.reference;
            }) !== -1
        );
        if (found?.length) {
          found.forEach((foundFile) => {
            const idx = findIndex(tempFiles, (tempFile) => {
              return tempFile.reference === foundFile.reference;
            });
            tempFiles[idx] = foundFile;
          });
        }
      }

      if (notFound?.length) {
        tempFiles = [...tempFiles, ...notFound];
      }

      const updatedDict = { ...associatedFilesDict };

      files.forEach((file) => {
        updatedDict[file.reference] = file;
      });

      updateAppState({
        associatedFiles: tempFiles,
        associatedFilesDict: updatedDict,
      });
    },
    [associatedFiles, associatedFilesDict, updateAppState]
  );

  const removeFile = React.useCallback(
    (fileRef) => {
      const updatedDict = { ...associatedFilesDict };
      delete updatedDict[fileRef];

      updateAppState({
        associatedFiles: associatedFiles?.filter(
          (item) => item.reference !== fileRef
        ),
        associatedFilesDict: updatedDict,
      });
    },
    [associatedFiles, associatedFilesDict, updateAppState]
  );

  const removeFilesAndUpdateApi = React.useCallback(
    (fileRefs) => {
      const updatedDict = { ...associatedFilesDict };
      fileRefs.forEach((ref) => delete updatedDict[ref]);

      updateAppState({
        associatedFiles: associatedFiles?.filter(
          (item) => !fileRefs.includes(item.reference)
        ),
        associatedFilesDict: updatedDict,
      });

      try {
        Promise.all(fileRefs?.map((ref) => FileAPI.delete(ref?.split("/")[1])));

        // update files in overview
        queryClient.invalidateQueries(filePaginatedKeys.allFiles);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error deleting file", e);
      }
    },
    [associatedFiles, associatedFilesDict, queryClient, updateAppState]
  );

  const patchFile = React.useCallback(
    async ({ id, prevFile, updatedFile }) => {
      try {
        const { data } = await FileAPI.patch(id, updatedFile, prevFile);

        toastMessage("File successfully updated");
        const updatedDict = { ...associatedFilesDict };
        updatedDict[data.reference] = {
          ...prevFile,
          ...data,
        };

        updateAppState({
          associatedFiles: associatedFiles?.map((item) => {
            if (data.reference === item.reference) {
              return { ...item, ...data };
            }
            return item;
          }),
          associatedFilesDict: updatedDict,
        });

        // update files in overview
        queryClient.invalidateQueries(filePaginatedKeys.allFiles);

        // update tags in s&c
        queryClient.invalidateQueries(tagKeys.tags);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error updating file", e);
        toastError("Error updating file");
      }
    },
    [associatedFiles, associatedFilesDict, queryClient, updateAppState]
  );

  const cloneFile = React.useCallback(
    async (fileId) => {
      const { data } = await FileAPI.postByIdWOP(fileId, "$clone");
      const updatedDict = { ...associatedFilesDict };
      updatedDict[data.reference] = data;

      updateAppState({
        associatedFiles: [...associatedFiles, data],
        associatedFilesDict: updatedDict,
      });

      return data;
    },
    [associatedFiles, associatedFilesDict, updateAppState]
  );

  /**
   * Clone associated files
   * @summary - Clone all the files associated to a resource in a batch
   * @param - fileRefs = {ref: fileReference: category: fileCategory}
   * @example - {ref: "File/b348f25d-fe6c-464b-ac80-4fee172a2124", category: "Documents"}
   */
  const cloneFiles = React.useCallback(
    (fileRefs = []) => {
      // if fileRefs is empty array or not an array return empty array
      if (fileRefs === [] || !Array.isArray(fileRefs)) return [];

      const clonedFilesArr = Promise.all(
        fileRefs.map(async (fileRef) => {
          // actually clone file
          const fileId = fileRef?.ref?.split("/")[1];
          const { data: clonedFile } = await FileAPI.postByIdWOP(
            fileId,
            "$clone"
          );
          return clonedFile;
        }, [])
      )
        .then((clonedFiles) => {
          // update associatedFiles
          const updatedDict = { ...associatedFilesDict };
          clonedFiles.forEach((data) => {
            updatedDict[data.reference] = data;
          });
          // update appState
          updateAppState({
            associatedFiles: [...associatedFiles, ...clonedFiles],
            associatedFilesDict: updatedDict,
          });

          return clonedFiles;
        })
        .catch((error) => {
          console.warn("Error cloning files", error);
        });

      return clonedFilesArr;
    },
    [associatedFiles, associatedFilesDict, updateAppState]
  );

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    associatedFiles,
    associatedFilesDict,
    isLoading,
    reload,
    addFile,
    removeFile,
    removeFilesAndUpdateApi,
    addFiles,
    cloneFile,
    cloneFiles,
    patchFile,
  };
}
