import React, { useState, useEffect, useCallback } from "react";
import { DocumentAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import {
  useFilters,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { useHistory } from "react-router";

import { useAppState } from "../../../state/appState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useDocuments from "../../../hooks/useDocuments";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useFavorites from "../../../hooks/useFavorites";
import { useDocumentSwitchView } from "../../../hooks/useSwitchView";
import { toastError } from "../../../helpers/Toast";
import DocumentUploadForm from "../DocumentUploadForm/DocumentUploadForm";
import FileCard from "../FileCard/FileCard";

import Modal from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";
import SiteHeader from "../SiteHeader/SiteHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import SiteHeaderSearch from "../SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../SiteHeader/SiteHeaderFilter";
import SiteHeaderSwitchView from "../SiteHeader/SiteHeaderSwitchView";

import folderIconGreen from "../../assets/images/folderOpenGreen.svg";
import folderIconWhite from "../../assets/images/folderIconWhite.svg";
import {
  DOCUMENTS_PATH,
  CREATE_DOCUMENTS_PATH,
  GET_DOCUMENT_FOLDER_FILE_PATH,
  FILTER_TYPES,
  TABLE_FILTER,
  fileCardStyle,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  SET_WORKFLOW_DOCUMENT,
  WORKFLOWS_ADD_NEW_PATH,
  DOCUMENT_DISPLAY_STATUS,
} from "../../../constants";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
} from "../Table/tableFilters";

import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const DocumentMgmtFolderContent = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { projectId, folderName } = useParams();
  const { data: currentUser } = useCurrentUser();
  const [, dispatchAppState] = useAppState();
  const { data: docConfig } = useDocumentsConfiguration();
  const {
    documents: allDocuments,
    addDocument,
    addDocuments,
    removeDocument,
  } = useDocuments();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const [isShowingTable, setIsShowingTable] = useDocumentSwitchView(false);

  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [folderContents, setFolderContents] = useState([]);
  const [columns, setColumns] = useState([]);
  const [addedFiles, setAddedFiles] = useState([]);
  const [docTypeId, setDocTypeId] = useState("");
  const [deleteDoc, setDeleteDoc] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const docTypeOptions = React.useMemo(
    () => Object.values(docTypeOptionsMap),
    [docTypeOptionsMap]
  );

  const documentColumns = React.useMemo(
    () => [
      {
        id: "favorited",
        Header: "",
        accessor: "isFavorited",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
      },
      {
        Header: "Name",
        accessor: "resource",
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "docType",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: (row) => moment(row.modifiedDate).format("MMM DD, YYYY"),
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const col = documentColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [documentColumns]);

  const {
    setAllFilters,
    setGlobalFilter,
    rows,
    allColumns,
    state: { filters, globalFilter },
  } = useTable(
    {
      columns,
      data: documents,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters,
    useGlobalFilter
  );

  const [value, setValue] = useState(globalFilter);
  const onSearchChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  useEffect(() => {
    setFolderContents(rows.map((item) => item.original) ?? []);
  }, [rows]);

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });
      const foundDoc = documents.find((doc) => doc.id === id);

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Document");
      }

      if (foundDoc) {
        delete foundDoc.isFavorited;
        addDocument(foundDoc);
      }
    },
    [documents, favorites, deleteFavorite, postFavorite, addDocument]
  );

  useEffect(() => {
    const stillUploading = addedFiles.some((item) => item.isUploading);

    if (!stillUploading && addedFiles.length) {
      addedFiles.forEach((item) => {
        if (item.isFavorited) {
          handleFavoriteClick(item.id);
        }
      });
      addDocuments(addedFiles);
      setAddedFiles([]);
    }
  }, [addedFiles, addDocuments, handleFavoriteClick]);

  useEffect(() => {
    let docType = state?.folderName;
    let docSet = [];
    let docId;

    switch (folderName) {
      case "drafts":
        docId = "isDraft";
        break;
      case "favorites":
        docId = "isFavorited";
        break;
      default:
        docId = docTypeOptions.find(
          (item) => item.label === folderName || item.value === folderName
        )?.value;
        break;
    }
    setDocTypeId(docId);

    if (!state?.folderName) {
      docType = docId;
    }

    if (allDocuments?.length) {
      switch (docType) {
        case "isDraft":
          docSet = allDocuments.filter((doc) => doc.status === "draft");
          break;
        case "isFavorited":
          docSet = allDocuments.filter((doc) => {
            return favorites.find(
              (fav) => fav.item.reference === doc.reference
            );
          });
          break;
        default:
          docSet = allDocuments.filter((doc) => doc.docType === docType);
          break;
      }

      if (projectId) {
        setDocuments([
          ...addedFiles,
          ...docSet.filter((doc) => doc.project?.includes(projectId)),
        ]);
      } else {
        setDocuments([...addedFiles, ...docSet]);
      }

      setLoading(false);
    } else if (allDocuments && !allDocuments.length) {
      setDocuments([]);
      setLoading(false);
    }
  }, [
    allDocuments,
    addedFiles,
    projectId,
    state,
    docTypeOptions,
    favorites,
    folderName,
  ]);

  const getDocType = (documentType) => {
    if (documentType) {
      return docTypeOptionsMap[documentType]?.label ?? documentType;
    }
    return "File";
  };

  const getFolderTitle = React.useCallback(
    (docType) => {
      const foundValue = docTypeOptions.find((item) => item.value === docType);
      const typeIsId = docType?.split("-")?.length > 1;

      if (docType) {
        if (docType === "isDraft") {
          return "Drafts";
        }
        if (docType === "isFavorited") {
          return "Favorites";
        }
        if (typeIsId) {
          return foundValue.label;
        }
      } else if (folderName) {
        if (folderName === "drafts") {
          return "Drafts";
        }
        if (folderName === "favorites") {
          return "Favorites";
        }

        return folderName;
      }

      return foundValue.label;
    },
    [docTypeOptions, folderName]
  );

  const handleUploadClick = () => {
    document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  const handleCreateDocumentClick = () => {
    history.push(CREATE_DOCUMENTS_PATH);
  };

  const handleBackClick = () => {
    history.push(DOCUMENTS_PATH);
  };

  const handleUpdateDocuments = () => {};
  const handleShowHistory = () => {};
  const handleUploadVersion = () => {};
  const handleFileEdit = () => {};

  const handleSendToWorkflow = useCallback(
    ({ id, project, property }) => {
      const document = allDocuments.find((doc) => doc.id === id);

      const parentDocument = allDocuments.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      const doesParentDocHasCurrentDocAsAdditionalDoc =
        parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
      // if document has parent, then block the access and toast msg
      if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
        toastError(
          `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
          toastErrorIcon,
          toastCloseIcon
        );
      } else {
        const allChildDocs = [];
        // if document has children then also attach the children docs to the WF
        if (allDocuments?.length && document?.additionalDocuments?.length) {
          document?.additionalDocuments?.forEach((child) => {
            const childDoc = allDocuments.find(
              (doc) => doc.reference === child
            );
            if (childDoc) {
              allChildDocs.push(childDoc);
            }
          });
        }
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          workflowDocument: [id, ...allChildDocs.map((child) => child.id)],
        });

        if (project) {
          history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(project.split("/")[1]));
        } else if (property) {
          history.push(
            GET_PROPERTY_WORKFLOW_CREATE_PATH(property.split("/")[1])
          );
        } else {
          history.push(WORKFLOWS_ADD_NEW_PATH);
        }
      }
    },
    [allDocuments, dispatchAppState, history]
  );

  const buttonActions = [
    { title: "Add Document", onClick: handleUploadClick },
    { title: "Create Financial Document", onClick: handleCreateDocumentClick },
  ];

  const handleFileClick = (doc) => {
    history.push(GET_DOCUMENT_FOLDER_FILE_PATH(folderName, doc.id));
  };

  const handleFileAdded = React.useCallback(
    (fileRef, fileBody, showSpinner) => {
      if (showSpinner) {
        setLoading(true);
      }

      const currentFile = {
        isUploading: true,
        reference: fileRef,
        isFavorited: fileBody.isFavorited,
      };
      setAddedFiles((prev) => [currentFile, ...prev]);

      if (fileBody) {
        const tempDocument = {
          customName: fileBody.name,
          docType: fileBody.docType,
          contentReference: fileRef,
          lineItems: [],
          status: "open",
        };

        DocumentAPI.post(tempDocument)
          .then(({ data: newDocument }) => {
            const resultDocument = {
              ...newDocument,
              creator: currentUser,
              isFavorited: fileBody.isFavorited,
              uploadedFileRef: fileRef,
            };

            setAddedFiles((prev) => {
              return [
                resultDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setLoading(false);
          })
          .catch((err) => {
            setAddedFiles((prev) => {
              return [
                tempDocument,
                ...prev.filter((item) => item.reference !== fileRef),
              ];
            });
            setLoading(false);
            console.error(err);
          });
      }
    },
    [currentUser]
  );

  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = async () => {
    const { data } = await DocumentAPI.getWOP(`${deleteDoc.id}/$canedit`);
    if (data.canedit) {
      DocumentAPI.delete(deleteDoc.id);
      removeDocument(deleteDoc.id);
      setDocuments((prev) => prev.filter((item) => item.id !== deleteDoc.id));
      handleHideDelete();
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be deleted`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  return (
    <>
      <SiteHeader
        title={getDocType(folderName) || "Folder"}
        buttons={
          currentUser?.hasPermission?.("document", "can_write") && (
            <PrimaryButton
              title="Actions"
              dropdownItems={buttonActions}
              onClick={handleCreateDocumentClick}
              large
            />
          )
        }
        search={
          <SiteHeaderSearch
            handleSearch={(val) => {
              onSearchChange(val);
              setValue(val);
            }}
            globalFilter={value}
          />
        }
        filter={
          <SiteHeaderFilter
            allColumns={allColumns}
            tableData={folderContents}
            filters={filters}
            setAllFilters={setAllFilters}
          />
        }
        switchViewHidden={
          <SiteHeaderSwitchView
            isShowingTable={isShowingTable}
            setIsShowingTable={setIsShowingTable}
            firstIcon={folderIconGreen}
            firstSelectedIcon={folderIconWhite}
          />
        }
      />

      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      {!loading && (
        <>
          <div className="flex items-center mb-6">
            <button type="button" onClick={handleBackClick}>
              <p className="text-md">Documents</p>
            </button>
            <p className="mx-3"> / </p>
            <div className="flex items-center">
              <img
                className="-mt-1 mr-3"
                src={folderIconGreen}
                alt="file icon"
              />
              <p className="font-semibold">
                {getFolderTitle(state?.folderName) ?? "Folder"}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap mt-3">
            {docTypeId?.split("-").length > 1 && (
              <DocumentUploadForm
                defaultType={docTypeId}
                onFinishUploading={handleUpdateDocuments}
                onAddFile={handleFileAdded}
                onAddPhoto={handleFileAdded}
                onAddVideo={handleFileAdded}
                showCorner
                small
              />
            )}
            {folderContents?.map((doc) => {
              return (
                <div key={doc.id} className="">
                  <FileCard
                    {...doc}
                    docType={getDocType(doc.docType)}
                    style={fileCardStyle}
                    createdAt={
                      doc.metadata?.createdAt
                        ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                        : "--"
                    }
                    isFavorited={
                      doc.isFavorited
                        ? doc.isFavorited
                        : favorites.some((fav) =>
                            fav.item.reference.includes(doc.id)
                          )
                    }
                    isDraft={doc.status === "draft"}
                    onFileClick={() => handleFileClick(doc)}
                    onFileEdit={() => handleFileEdit(doc)}
                    onShowHistory={handleShowHistory}
                    onSendToWorkflow={() => handleSendToWorkflow(doc)}
                    onUploadVersion={handleUploadVersion}
                    onFileDelete={() => handleShowDelete(doc)}
                    onFavoriteClick={() => handleFavoriteClick(doc.id)}
                  />
                </div>
              );
            })}
          </div>

          <Modal
            title={
              deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
            }
            isOpen={showDeleteModal}
            primaryButtonTitle="Yes, delete"
            primaryButtonOnClick={handleDeleteDocument}
            tertiaryButtonTitle="Cancel"
            onRequestModalClose={handleHideDelete}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            hideFooter
          >
            <>
              <p className="text-base mb-2">
                Are you sure you want to delete{" "}
                {deleteDoc?.name ?? "this document"}? Once deleted, it cannot be
                recovered.
              </p>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

DocumentMgmtFolderContent.propTypes = {};

DocumentMgmtFolderContent.defaultProps = {};

export default DocumentMgmtFolderContent;
