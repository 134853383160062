import React, { useCallback, useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import * as yup from "yup";
import moment from "moment";
import { EventAPI, DocumentAPI, FileAPI } from "@griffingroupglobal/eslib-api";

import { useQueryClient } from "react-query";
import { assetMaintenanceScheduleSchema } from "../../../helpers/FormValidations";
import Input from "../Input/Input";
import DatePicker from "../DatePicker/DatePicker";
import TimePicker from "../DatePicker/TimePicker";
import TertiaryButton from "../Buttons/TertiaryButton";
import Dropdown from "../Dropdown/Dropdown";

import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import deleteIcon from "../../assets/images/collapseIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import eventIcon from "../../assets/images/eventIcon.png";
import clockIcon from "../../assets/images/clockIconGray.svg";
import { getFullRrule, taskRecurrenceOptions } from "../../../helpers/Time";
import MaintenanceSopSelect from "./MaintenanceSopSelect";
import IconButton from "../Buttons/IconButton";
import { useAppState } from "../../../state/appState";
import { useSop } from "../../../hooks/useSop";
import {
  customModalStyles,
  DOCUMENT_ATTACHMENT_EXTENSIONS,
  maintenanceStatus,
  MEDIA_ATTACHMENT_EXTENSIONS,
} from "../../../constants";
import SimpleResourceDropDown from "../ExpenseForm/SimpleResourceDropDown";
import { uploadFileWithData } from "../../../helpers/File";
import { getAssetOrPropertyMembersRef } from "../../../helpers/Asset";
import Checkbox from "../Checkbox/Checkbox";
import SimpleFileUpLoad from "./SimpleFileUpLoad";
import DisplayFileNames from "../../../Pages/Overviews/Sop/DisplayFileNames";
import Modal from "../Modal/Modal";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import SelectUserInterface from "../SelectUserInterface/SelectUserInterface";
import UserList from "../CalendarAddEventForm/UserList";
import { toastError, toastMessage } from "../../../helpers/Toast";
import { handleLinkClick } from "../../../helpers/Utilities";
import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="DUe date" />
);
const clockIconElement = (
  <img className="w-4 h-4" src={clockIcon} alt="Due time" />
);

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const mediaTypes = [
  "image/jpeg",
  "video/quicktime",
  "image/png",
  "image/svg+xml",
  "video/mp4",
];

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;
const { CANCELLED, COMPLETE, SCHEDULED } = maintenanceStatus;

const MaintenanceScheduleTaskForm = ({
  association,
  assetData,
  isEditing,
  invitees: origInvitees,
  onFinishedEditing,
  onCloseTaskModal,
  assetMembersOptions,
  reloadAsset,
  isModalOpen,
}) => {
  const [{ documents, documentsConfiguration: docConfig, userDict }] =
    useAppState();
  const { data: sopData } = useSop({ selectOptions: true });
  const queryClient = useQueryClient();
  const inputRefs = useRef({});
  const { assetId, propertyId, projectId } = useParams();
  const history = useHistory();
  const [assetMembers, setAssetMembers] = useState(assetMembersOptions);
  const [editedAsset, setEditedAsset] = useState(assetData);
  const [invitees, setInvitees] = useState(origInvitees || []);
  const [isInputValid, setIsInputValid] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [dueTime, setDueTime] = useState(undefined);
  const [startDate, setStartDate] = useState(editedAsset?.startDate);
  const [recurrence, setRecurrence] = useState();
  const [disableEdit, setDisableEdit] = useState(true);
  const [steps, setSteps] = useState([]);
  const [resource, setResource] = useState();
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false);
  const [addLinksIsOpen, setAddLinksIsOpen] = useState(false);
  const [link, setLink] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [showAddMedia, setShowAddMedia] = useState(false);
  const [showAddSteps, setShowAddSteps] = useState(false);
  const [showAddDocuments, setShowAddDocuments] = useState(false);

  useEffect(() => {
    const duration = moment.duration(
      moment(editedAsset?.endDate).diff(moment(editedAsset?.startDate))
    );
    setAllDayEvent(duration?.asHours() > 23 && duration?.asHours() <= 24);
  }, [editedAsset?.endDate, editedAsset?.startDate]);

  useEffect(() => {
    if (!editedAsset?.links) setEditedAsset((prev) => ({ ...prev, links: [] }));
  }, [editedAsset?.links]);

  useEffect(() => {
    if (editedAsset?.steps) {
      const editedSteps = editedAsset?.steps.map((step) => ({
        ...step,
        label: step.description,
        value: step.description,
      }));
      setSteps(editedSteps);
    }
  }, [editedAsset?.steps]);

  useEffect(() => {
    if (isEditing) {
      setAssignedTo(origInvitees);
    }
  }, [isEditing, origInvitees]);

  useEffect(() => {
    const getExistingFiles = async () => {
      try {
        const filesRefs = [];
        const docsRefs = [];

        const imageRefs = editedAsset?.images?.map(
          (img) => img?.file?.split("/")[1]
        );
        const videoRefs = editedAsset?.videos?.map(
          (video) => video?.split("/")[1]
        );

        editedAsset?.files?.forEach((item) => {
          if (item?.reference?.startsWith("Document/"))
            docsRefs?.push(item?.reference?.split("/")[1]);
          else if (item?.reference?.startsWith("File/"))
            filesRefs?.push(item?.reference?.split("/")[1]);
        });

        let docs = [];
        if (docsRefs?.length > 0) {
          docs = await Promise.all(
            docsRefs?.map(async (doc) => {
              const { data } = await DocumentAPI.getById(doc);
              return data;
            })
          );
        }

        const allFileRefs = [...imageRefs, ...videoRefs, ...filesRefs];

        const files = await Promise.all(
          allFileRefs?.map(async (f) => {
            const { data } = await FileAPI.getById(f);
            return data;
          })
        );

        setFilesToUpload(
          [...files, ...docs]?.map((f) => ({ ...f, isEditing: true }))
        );
      } catch (error) {
        console.warn(error.message);
      }
    };
    if (isEditing) {
      getExistingFiles();
    }
  }, [
    editedAsset,
    editedAsset?.files,
    editedAsset?.images,
    editedAsset?.videos,
    isEditing,
  ]);

  useEffect(() => {
    const getRecurrence = async () => {
      const editRecurrence = await getFullRrule(editedAsset?.recurrence);
      setRecurrence(editRecurrence || taskRecurrenceOptions[0]);
    };
    getRecurrence();
  }, [editedAsset?.recurrence]);

  useEffect(() => {
    if (isEditing) {
      setDueTime(moment(editedAsset?.startDate).toDate());
      setStartDate(moment(editedAsset?.startDate).toDate());
    } else {
      setStartDate(moment().format("MM/DD/YYYY"));
      setDueTime(moment().add(1, "hours").startOf("hour").toDate());
    }
  }, [editedAsset?.startDate, invitees, isEditing]);

  useEffect(() => {
    setAssetMembers(assetMembersOptions);
  }, [assetMembersOptions]);

  const getMembersDD = useCallback(() => {
    const membersResult = [];
    const cleanInviteesValues = invitees?.map(({ value }) => value);
    assetMembers?.forEach((member) => {
      if (!cleanInviteesValues?.includes(member?.value)) {
        membersResult?.push(member);
      }
    });
    return membersResult;
  }, [assetMembers, invitees]);

  useEffect(() => {
    if (documents && docConfig) {
      const docTypeMap = {};
      documents.forEach((doc) => {
        const docOption = {
          label:
            doc.name ??
            `Unnamed ${
              doc.metadata?.createdAt
                ? `- ${moment(doc.metadata.createdAt).format(
                    "MM/DD/YYYY, H:MM A"
                  )}`
                : ""
            }`,
          value: doc.id,
          type: doc.docType,
          ref: doc.reference,
        };
        if (docTypeMap[doc.docType]) {
          docTypeMap[doc.docType].push(docOption);
        } else {
          docTypeMap[doc.docType] = [docOption];
        }
      });
    }
  }, [documents, docConfig]);

  const handleAction = async () => {
    onCloseTaskModal();
    if (
      history.location.pathname.includes(
        `/projects/${projectId}/asset/${assetId}`
      )
    ) {
      history.push(`/projects/${projectId}/asset/${assetId}/maintenance`);
    } else if (
      history.location.pathname.includes(
        `/properties/${propertyId}/asset/${assetId}`
      )
    ) {
      history.push(`/properties/${propertyId}/asset/${assetId}/maintenance`);
    }
  };

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    checkValidation(
      {
        name: editedAsset?.name,
        startDate,
        dueTime,
        recurrence: recurrence?.value,
      },
      assetMaintenanceScheduleSchema
    );
  }, [
    editedAsset?.name,
    startDate,
    checkValidation,
    dueTime,
    recurrence?.value,
  ]);

  useEffect(() => {
    const tempInvitees = uniq(assignedTo);
    setEditedAsset((prev) => ({ ...prev, invitees: tempInvitees }));
  }, [assignedTo]);

  const handleChange = useCallback(
    (key, val) => {
      if (key === "invitees") {
        setAssignedTo(val);
        setInvitees((prev) => [...prev, ...val]);
        const tempInvitees = uniq([
          ...editedAsset?.invitees,
          ...val?.map(({ value }) => value),
        ]);
        setEditedAsset((prev) => ({ ...prev, invitees: tempInvitees }));
      } else {
        setEditedAsset((prev) => ({ ...prev, [key]: val }));
      }
    },
    [editedAsset?.invitees]
  );
  const handleRecurrenceChange = useCallback((value) => {
    setRecurrence(value);
  }, []);
  const handleTimeChange = useCallback((value) => {
    setDueTime(new Date(value));
  }, []);

  const buildNewTaskData = useCallback(
    (images, videos, files) => {
      const date = moment(editedAsset.startDate).toISOString();
      const startTime = dueTime?.toISOString()?.split("T")[1];
      const tempDate = date.split("T")[0];

      const startDateTime = moment(`${tempDate}T${startTime}`).toISOString();
      let scheduledTaskData = {};
      if (!isEditing) {
        scheduledTaskData.recurrence = `DTSTART:${startDateTime
          .split(".")[0]
          .replace(/-/g, "")
          .replace(/:/g, "")}Z${recurrence?.value}`;
      } else if (recurrence?.value.includes("DTSTART:")) {
        scheduledTaskData.recurrence = recurrence?.value;
      } else {
        scheduledTaskData.recurrence = `DTSTART:${startDateTime
          .split(".")[0]
          .replace(/-/g, "")
          .replace(/:/g, "")}Z${recurrence?.value}`;
      }
      const maintenanceSteps = steps?.map((step) => {
        const tempStep = { ...step };
        delete tempStep.label;
        delete tempStep.value;
        delete tempStep.isEditing;
        return tempStep;
      });

      scheduledTaskData = {
        ...scheduledTaskData,
        isTask: true,
        isMaintenance: true,
        association: resource?.val
          ? resource?.val
          : association?.reference || `Asset/${assetId}`,
        invitees: invitees?.map(({ value }) => value),
        name: editedAsset.name,
        description: editedAsset?.description,
        startDate: allDayEvent
          ? moment(date).startOf("day").toISOString()
          : startDateTime,
        endDate: allDayEvent
          ? moment(date).endOf("day").toISOString()
          : startDateTime,
        status: SCHEDULED,
        images,
        videos,
        files,
        steps: maintenanceSteps,
        links: editedAsset?.links,
      };

      if (recurrence?.value.includes("\nDoes not repeat")) {
        delete scheduledTaskData.recurrence;
        delete scheduledTaskData.instanceStartDate;
        delete scheduledTaskData.recurringCalendarOptions;
      }
      return scheduledTaskData;
    },
    [
      editedAsset?.links,
      editedAsset?.startDate,
      editedAsset?.name,
      editedAsset?.description,
      dueTime,
      allDayEvent,
      isEditing,
      recurrence?.value,
      steps,
      resource?.val,
      association?.reference,
      assetId,
      invitees,
    ]
  );

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileRef = await uploadFileWithData(doc, data, progressCallback);
    return fileRef;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original?.type,
          size: original?.size,
        };
        const res = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return { ...data, reference: res };
      })
    );

    return result;
  }, []);

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
    const res = await onUpload(filteredFiles, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);

    setFilesToUpload([]);
    return res;
  }, [filesToUpload, onUpload, queryClient]);

  // eslint-disable-next-line consistent-return
  const handleCreateSchedule = async () => {
    onCloseTaskModal();
    try {
      setDisableEdit(true);
      const newlyAddedFiles = await handleFilesUploaded();
      const editingFiles = filesToUpload.filter((file) => file.isEditing);
      const allFilesData = [...newlyAddedFiles, ...editingFiles];

      const images = [];
      const videos = [];
      const files = [];
      allFilesData?.forEach((f) => {
        if (f?.contentType?.includes("video/")) videos.push(f?.reference);
        else if (f?.contentType?.includes("image/"))
          images.push({ file: f.reference, isPrimary: false });
        else files.push(f?.reference);
      });
      const scheduledTaskData = buildNewTaskData(images, videos, files);

      if (isEditing) {
        onFinishedEditing(scheduledTaskData);
        return null;
      }

      await EventAPI.post(scheduledTaskData);
      toastMessage("Task creation success", toastIcon, toastCloseIcon);
      await reloadAsset();
    } catch (err) {
      console.warn("Failed to create task schedule", err);
      toastError(
        `Task creation failed. Please try again: ${err.message}`,
        toastErrorIcon,
        toastCloseIcon
      );
    } finally {
      handleAction();
    }
  };

  useEffect(() => {
    setDisableEdit(!(isInputValid && !!editedAsset?.invitees?.length));
  }, [editedAsset?.invitees?.length, isInputValid]);

  const handleAddStep = () => {
    setSteps((prev) => [
      ...prev,
      {
        id: uuidv4(),
        date: new Date().toISOString(),
        description: "",
        value: "",
        isEditing: true,
      },
    ]);
  };

  const removeStep = useCallback((val) => {
    setSteps((prev) => prev.filter((step) => step.id !== val.id));
  }, []);

  const sopHasDuplicate = useCallback(
    (value, idx) => {
      if (value?.sop) {
        return steps?.some((s) => s.sop && s.sop === value.sop);
      }
      return steps?.some((x, index) => {
        return (
          !x.sop &&
          x?.description?.toLowerCase() === value?.description?.toLowerCase() &&
          index !== idx
        );
      });
    },
    [steps]
  );
  const onSopStepChange = useCallback(
    (newValue, index) => {
      if (!sopHasDuplicate(newValue)) {
        setSteps((prev) =>
          prev.map((step, idx) => {
            if (idx === index) {
              return {
                ...step,
                ...newValue,
                position: index + 1,
                isEditing: false,
                isCompleted: false,
              };
            }
            return step;
          })
        );
        handleAddStep();
      }
    },
    [sopHasDuplicate]
  );

  const toggleEditing = useCallback(
    (index) => {
      if (isEditing) {
        if (!steps[index]?.isEditing) {
          setSteps((prev) =>
            prev.map((step, idx) =>
              index === idx
                ? { ...step, isEditing: true }
                : { ...step, isEditing: false }
            )
          );
        } else {
          setSteps((prev) =>
            prev.map((step, idx) =>
              index === idx ? { ...step, isEditing: !step.isEditing } : step
            )
          );
        }
      } else {
        setSteps((prev) =>
          prev.map((item) =>
            item.position === index + 1
              ? { ...item, isEditing: !item.isEditing }
              : item
          )
        );
      }
    },
    [isEditing, steps]
  );

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    toggleEditing(idx);
    if (value.description === "" || sopHasDuplicate(value, idx)) {
      removeStep(value);
    }
  };

  useEffect(() => {
    if (resource) {
      setAssignedTo();
      const getMembers = async () => {
        const members = await getAssetOrPropertyMembersRef(resource);
        const membersOptions = [];
        members?.forEach((m) => {
          const user = userDict[m];
          if (user?.kind === "member") {
            membersOptions.push({
              label: `${user.name?.firstName} ${user.name?.lastName}`,
              value: user.reference,
            });
          }
        });
        setAssetMembers(membersOptions);
      };
      getMembers();
    }
  }, [resource, resource?.val, userDict]);

  const handleAddLink = (val) => {
    setEditedAsset((prev) => ({ ...prev, links: [...prev?.links, val] }));
  };

  const handleDeleteLink = (id) => {
    setEditedAsset((prev) => ({
      ...prev,
      links: prev?.links?.filter((l, index) => index !== id),
    }));
  };

  const handleFilesAdded = React.useCallback(
    async (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );

  const removeAttachedFile = useCallback(
    (id, fileToDelete) => {
      const remainingFiles = filesToUpload?.filter(
        (file) => file?.id !== fileToDelete?.id
      );
      setFilesToUpload(remainingFiles);
    },
    [filesToUpload]
  );

  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleRemoveInvitee = useCallback(
    (val) => {
      const remainingInvitees = invitees?.filter((inv) => inv?.value !== val);
      setInvitees(remainingInvitees);
      setEditedAsset((prev) => ({ ...prev, invitees: remainingInvitees }));
    },
    [invitees]
  );

  return (
    <Modal
      closeTimeoutMS={200}
      childContainerClassName="!pr-4 !pl-4 overflow-y-scroll"
      title={`${isEditing ? "Edit Task" : "Create Task"}`}
      isOpen={isModalOpen}
      onRequestModalClose={() => {
        handleAction();
        onCloseTaskModal();
      }}
      primaryButtonOnClick={handleCreateSchedule}
      primaryButtonTitle={`${isEditing ? "Save" : "Create"}`}
      disabled={disableEdit}
      tertiaryButtonTitle="Cancel"
      shouldCloseOnEsc
      hideFooter
      overlayStyle={overlayStyle}
      contentStyle={contentStyle}
      titleStyle={titleStyle}
      headerStyle={headerStyle}
      buttonCnClass="mb-1"
      showConfirm
      modalAction={`${isEditing ? "Task Editing" : "Task Creation"}`}
    >
      <div
        className="flex flex-col w-192 mb-4"
        role="presentation"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <SimpleResourceDropDown
          resource={resource}
          setResource={setResource}
          setInvitees={setInvitees}
          required
          isEditing={isEditing}
          disableAssociation={
            assetId !== undefined || association?.reference !== undefined
          }
          defaultValue={
            assetId === undefined && association?.reference === undefined
              ? {}
              : {
                  label: association?.name || editedAsset?.name,
                  value: association?.reference || `Asset/${assetId}`,
                }
          }
          isMaintenance
        />
        <div className="flex items-center w-full mb-3 mt-2.5">
          <Input
            mainClassName="w-full"
            tabIndex={0}
            label="Title"
            name="title"
            placeholder="Title"
            value={editedAsset?.name}
            validation={yup.string().required()}
            onChange={(val) => handleChange("name", val)}
            // eslint-disable-next-line no-return-assign
            forwardedRef={(el) => (inputRefs.current.title = el)}
            required
          />
        </div>
        <div className="flex justify-between items-center w-full mb-3">
          <div className="w-1/2 mr-2">
            <DatePicker
              tabIndex={0}
              className="flex-1"
              label="Due Date"
              name="startDate"
              onChange={(val) =>
                handleChange("startDate", moment(val).format("MM/DD/YYYY"))
              }
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current.startDate = el)}
              minDate={moment().toDate()}
              value={editedAsset?.startDate || startDate}
              validation={yup.date().required()}
              iconLeft={
                editedAsset?.recurrence && isEditing ? null : eventIconElement
              }
              required
            />
          </div>
          <div className="flex justify-between w-1/2 ml-2">
            {!allDayEvent && (
              <TimePicker
                className="w-3/5"
                tabIndex={0}
                label="Due Time"
                placeholder="12:00 PM"
                name="dueTime"
                value={dueTime}
                onChange={(val) => handleTimeChange(val)}
                // eslint-disable-next-line no-return-assign
                forwardedRef={(el) => (inputRefs.current.dueTime = el)}
                validation={yup.string().required()}
                iconLeft={clockIconElement}
                minTime={new Date().setHours(0, 0, 0)}
                timeIntervals={5}
                required
              />
            )}
            <Checkbox
              className="flex items-center ESInputLabel ml-4"
              label="All day"
              checked={allDayEvent}
              onChange={() => setAllDayEvent((prev) => !prev)}
            />
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="w-1/2  mr-2">
            <SelectUserInterface
              className="w-full"
              onAddUser={(val) => handleChange("invitees", val)}
              isMulti
              userPlaceholder="Search"
              userLabel="Assigned To"
              disableCreateUser
              userOptions={getMembersDD()}
              userList={[]}
              required
            />
          </div>
          <div className="w-1/2  ml-2">
            <Dropdown
              tabIndex={0}
              name="recurrence"
              label="Repeat"
              placeholder="Select"
              options={taskRecurrenceOptions}
              value={recurrence}
              onChange={(val) => handleRecurrenceChange(val)}
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current.recurrence = el)}
              validation={yup
                .object()
                .shape({
                  label: yup.string().required(),
                  value: yup.string().required(),
                })
                .required()}
              disableSort
            />
          </div>
        </div>
        <UserList
          userList={invitees}
          handleRemove={(val) => handleRemoveInvitee(val)}
        />
        <div className="w-full">
          <PlusCircleButton
            title="Description"
            onClick={() => {
              setDescriptionIsOpen(!descriptionIsOpen);
            }}
          />
          {descriptionIsOpen && (
            <Input
              placeholder="Description"
              className="mt-2"
              inputContainerClassName="min-h-36 border"
              label="Description"
              onChange={(val) => handleChange("description", val)}
              value={editedAsset?.description}
              isTextarea
            />
          )}
        </div>

        <div className="flex flex-col justify-start w-full">
          <PlusCircleButton
            title="Attachments"
            onClick={() => {
              setShowAddDocuments(!showAddDocuments);
            }}
          />
          {showAddDocuments && (
            <div className="flex justify-start z-0">
              <SimpleFileUpLoad
                extensions={DOCUMENT_ATTACHMENT_EXTENSIONS}
                files={filesToUpload}
                onFilesAdded={handleFilesAdded}
                onFilesUpdated={handleFilesUpdated}
                onFilesUploaded={handleFilesUploaded}
                customUploadAreaStyle={{ marginRight: "0px" }}
                simple
              />
            </div>
          )}
          {filesToUpload?.length > 0 && (
            <div className="mt-2 mx-2">
              <DisplayFileNames
                filesToUpload={filesToUpload?.filter(
                  (file) =>
                    !mediaTypes?.includes(file?.contentType) &&
                    !mediaTypes?.includes(file?.type)
                )}
                removeAttachedFile={removeAttachedFile}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className="w-96">
            <PlusCircleButton
              className="mt-5"
              title="Links"
              onClick={() => {
                setAddLinksIsOpen(!addLinksIsOpen);
              }}
            />
          </div>
          {addLinksIsOpen && (
            <div className="flex items-center w-full justify-between mt-4">
              <div className="flex">
                <Input
                  placeholder="Url"
                  className="mb-1 mr-4"
                  label="Link URL"
                  value={link?.url}
                  onChange={(val) => setLink({ ...link, url: val })}
                  validation={yup.string().required()}
                />
                <Input
                  placeholder="Name"
                  label="Link Name"
                  value={link?.name}
                  onChange={(val) => setLink({ ...link, name: val })}
                />
              </div>
              <TertiaryButton
                disabled={!link?.url?.length}
                className="mt-2"
                title="Add Link"
                onClick={() => {
                  handleAddLink(link);
                  setLink({
                    name: "",
                    url: "",
                  });
                }}
              />
            </div>
          )}
          {editedAsset?.links?.length > 0 && (
            <div className="flex flex-col ml-2 -mb-2">
              {editedAsset?.links?.map((item, index) => {
                return (
                  <div key={item?.id} className="flex items-center mt-2">
                    <button
                      className="text-brandGreen"
                      onClick={() => handleLinkClick(item)}
                      type="button"
                    >
                      {item?.name ?? item?.url}
                    </button>
                    <IconButton
                      icon={deleteIcon}
                      className="ml-4"
                      onClick={() => handleDeleteLink(index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="flex flex-col justify-start w-full">
          <PlusCircleButton
            title="Media"
            onClick={() => {
              setShowAddMedia(!showAddMedia);
            }}
          />
          {showAddMedia && (
            <div className="flex justify-start z-0">
              <SimpleFileUpLoad
                extensions={MEDIA_ATTACHMENT_EXTENSIONS}
                files={filesToUpload}
                onFilesAdded={handleFilesAdded}
                onFilesUpdated={handleFilesUpdated}
                onFilesUploaded={handleFilesUploaded}
                customUploadAreaStyle={{ marginRight: "0px" }}
                simple
              />
            </div>
          )}
          {filesToUpload?.length > 0 && (
            <div className="mt-2 mx-2">
              <DisplayFileNames
                filesToUpload={filesToUpload?.filter(
                  (file) =>
                    mediaTypes?.includes(file?.contentType) ||
                    mediaTypes?.includes(file?.type)
                )}
                removeAttachedFile={removeAttachedFile}
              />
            </div>
          )}
        </div>

        <div className="pl-0  w-3/4 mt-4 mb-5">
          <PlusCircleButton
            className="mt-3"
            title="Steps"
            onClick={() => {
              setShowAddSteps(!showAddSteps);
            }}
          />
          <div className="flex flex-col">
            {steps?.length > 0 && (
              <div className="flex flex-col w-full ml-2 mt-3">
                {steps?.map((step, index) => {
                  return (
                    <div
                      className="flex items-center !w-full mt-2"
                      key={step?.id}
                    >
                      {(step?.isEditing && (
                        <MaintenanceSopSelect
                          menuPlacement="auto"
                          sopData={sopData}
                          setSteps={setSteps}
                          key={uuidv4()}
                          index={index}
                          handleSopStepChange={onSopStepChange}
                          step={step}
                          className="w-full mr-3"
                          disableClear={!isEditing}
                          disabled={step.sop && isEditing}
                          handleBlur={handleBlur}
                        />
                      )) || (
                        <p
                          className={`text-gray-400 ${
                            step?.sop && "font-medium"
                          }`}
                        >{`${step?.description} ${
                          step?.sop ? " (sop)" : ""
                        }`}</p>
                      )}
                      {!step?.sop &&
                        editedAsset?.status !== COMPLETE &&
                        editedAsset?.status !== CANCELLED && (
                          <IconButton
                            className="ml-2"
                            imgClassName="w-5 h-5"
                            iconClassName="w-5 h-5"
                            onClick={() => toggleEditing(index)}
                            icon={editIcon}
                          />
                        )}

                      <div className="flex item-center justify-center w-5 h-5">
                        <IconButton
                          className="ml-2"
                          imgClassName="w-5 h-5"
                          iconClassName="w-5 h-5"
                          onClick={() => removeStep(step)}
                          icon={deleteIcon}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {showAddSteps && (
            <div className="mt-3">
              <TertiaryButton
                onClick={() => handleAddStep()}
                className="col-span-1 pl-0"
                title="+ Add Step"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

MaintenanceScheduleTaskForm.propTypes = {
  isEditing: PropTypes.bool,
  invitees: PropTypes.arrayOf(PropTypes.string),
  onFinishedEditing: PropTypes.func,
  assetData: PropTypes.shape({}),
  onCloseTaskModal: PropTypes.func,
  reloadAsset: PropTypes.func,
  assetMembersOptions: PropTypes.arrayOf("string"),
  association: PropTypes.shape({
    reference: PropTypes.string,
    name: PropTypes.string,
    project: PropTypes.string,
    property: PropTypes.string,
  }),
  isModalOpen: PropTypes.bool,
};
MaintenanceScheduleTaskForm.defaultProps = {
  isEditing: undefined,
  invitees: [],
  onFinishedEditing: undefined,
  assetData: {},
  onCloseTaskModal: undefined,
  reloadAsset: undefined,
  assetMembersOptions: [],
  association: {},
  isModalOpen: false,
};

export default MaintenanceScheduleTaskForm;
