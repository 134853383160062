import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { getInitials, renderStatusImage } from "../../../helpers/User";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import Avatar from "../Message/Avatar";

const memberAvatarInitialsCN = cntl`
  w-8
  h-8
  flex
  items-center
  justify-center
`;

/**
 * Renders the View All Members dropdown list.
 * @param members
 */
const MemberAvatarListWithViewAll = ({
  members,
  viewAllClassName,
  viewAllButton,
  disableOnClick,
  handleMemberClick,
  buttonLeft,
  showRsvpStatus,
  rsvpData,
}) => {
  const [showViewAllMembers, setShowViewAllMembers] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowViewAllMembers(false));

  return (
    <div ref={wrapperRef} className="flex relative">
      <button
        type="button"
        className={`${
          viewAllClassName || "text-base pl-3"
        } font-normal tracking-wider text-brandGreen`}
        onClick={(e) => {
          if (!disableOnClick) {
            e.stopPropagation();
            setShowViewAllMembers(!showViewAllMembers);
          }
        }}
      >
        {viewAllButton || "View All"}
      </button>
      <div
        className={`h-auto max-h-96 bg-white absolute overflow-auto border rounded-md flex flex-col z-20 top-8 p-1 gap-1 ${
          showViewAllMembers ? "" : "hidden"
        }
          ${buttonLeft ? "right-full" : "left-full"}
        `}
      >
        {members.map((member) => (
          <div
            key={member?.id}
            className="role-member-view-all-list-item relative flex flex-row items-center cursor-pointer rounded-md p-1 hover:bg-backgroundGreen"
            onClick={() => handleMemberClick(member)}
            onKeyDown={(e) => {
              if (e.code === "enter") handleMemberClick(member);
            }}
            tabIndex={0}
            role="button"
          >
            <div
              className={memberAvatarInitialsCN}
              style={{ paddingLeft: "11px" }}
            >
              {showRsvpStatus &&
                typeof rsvpData?.[member?.reference] === "string" && (
                  <img
                    alt="status"
                    src={renderStatusImage(rsvpData?.[member?.reference])}
                    className="absolute h-fit bottom-6 left-7"
                    style={{ zIndex: 9999 }}
                    width="15px"
                  />
                )}
              <Avatar
                className="text-xs"
                name={getInitials(member?.name)}
                isOnline={member?.isOnline}
                isExternalUser
                id={member?.id}
                avatar={member?.avatar}
                kind={member?.kind}
                disableTooltip
              />
            </div>

            <p className="ml-2 font-semibold text-black text-xs truncate text-ellipsis capitalize">
              {member?.name?.firstName ?? ""} {member?.name?.lastName ?? ""}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

MemberAvatarListWithViewAll.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  viewAllClassName: PropTypes.string,
  viewAllButton: PropTypes.element,
  disableOnClick: PropTypes.bool,
  handleMemberClick: PropTypes.func,
  buttonLeft: PropTypes.bool,
  showRsvpStatus: PropTypes.bool,
  rsvpData: PropTypes.shape({}),
};

MemberAvatarListWithViewAll.defaultProps = {
  viewAllClassName: undefined,
  viewAllButton: undefined,
  members: [],
  disableOnClick: false,
  handleMemberClick: () => {},
  buttonLeft: undefined,
  showRsvpStatus: undefined,
  rsvpData: undefined,
};

export default MemberAvatarListWithViewAll;
