import React from "react";
import ProfileDropZone from "../../ProfileImageDropZone";
import Input from "../../Input/Input";
import IconButton from "../../Buttons/IconButton";
import RemoveDarkenedGreen from "../../../assets/images/RemoveDarkenedGreen.svg";
import DatePicker from "../../DatePicker/DatePicker";
import useProfileDetailsForm from "./useProfileDetailsForm";
import Dropdown from "../../Dropdown/Dropdown";

const container = "flex flex-col items-center w-full gap-10 p-8";
const headerTextCN =
  "text-black text-es-lg font-es-semibold tracking-es-wide flex flex-row w-full";
const rowCN = "flex flex-row col-span-1 items-center justify-between";
const labelCN =
  "text-es-medium-grey font-es-bold text-es-normal tracking-es-wide";
const rowContainer = "grid grid-cols-2 gap-8";
const inputWidth = { minWidth: "355px", height: "42px" };
const inputCN = "flex flex-row items-center gap-3";

const ProfileDetailsForm = ({ editableUser, setEditableUser }) => {
  const {
    userEmail,
    userPhoneNumber,
    userDate,
    userSocial,
    changeName,
    changeEmail,
    changePhoneNumber,
    changeDate,
    changeSocial,
    changeProfileImage,
    changePronunciation,
    changeTimezone,
    firstNameValidation,
    phoneNumberValidation,
    removeField,
    isHidden,
    timezoneOptions,
  } = useProfileDetailsForm({ editableUser, setEditableUser });
  return (
    <div className={container} style={{ width: "1268px" }}>
      {/* profile picture drag n drop container */}
      <ProfileDropZone contact={editableUser} onChange={changeProfileImage} />

      <div className="flex flex-col w-full p-8 border rounded-lg gap-8">
        <p className={headerTextCN}>Personal Profile</p>
        <div className={rowContainer}>
          {/* Row */}
          <div className={rowCN}>
            <p className={labelCN}>First Name</p>
            <div style={inputWidth}>
              <Input
                placeholder="First Name"
                value={editableUser?.name?.firstName}
                onChange={(val) => changeName(val, "firstName")}
                validation={firstNameValidation} // string.trim.length >= 3; required
              />
            </div>
          </div>

          <div className={rowCN}>
            <p className={labelCN}>Last Name</p>
            <div style={inputWidth}>
              <Input
                placeholder="Last Name"
                value={editableUser?.name?.lastName}
                onChange={(val) => changeName(val, "lastName")}
              />
            </div>
          </div>

          {/* Row */}
          <div className={rowCN}>
            <p className={labelCN}>Pronunciation</p>
            <div style={inputWidth}>
              <Input
                placeholder="Pronunciation"
                onChange={(val) => changePronunciation(val)}
                value={editableUser?.pronunciation}
              />
            </div>
          </div>

          <div className={rowCN}>
            <p className={labelCN}>Email</p>
            <div style={inputWidth}>
              <Input
                placeholder="Email"
                value={userEmail}
                onChange={(val) => changeEmail(val)}
              />
            </div>
          </div>

          {/* Row */}
          <div className={rowCN}>
            <p className={labelCN}>Phone Number</p>
            <div style={inputWidth}>
              <Input
                placeholder="Mobile Number"
                value={userPhoneNumber}
                onChange={(val) => changePhoneNumber(val)}
                validation={phoneNumberValidation}
              />
            </div>
          </div>

          <div className={rowCN}>
            <p className={labelCN}>Time Zone</p>
            <div style={inputWidth}>
              <Dropdown
                options={timezoneOptions}
                value={editableUser?.timezone}
                onChange={(val) => changeTimezone(val?.label)}
                name="user-timezone"
                placeholder={editableUser?.timezone ?? "Select"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full p-8 border rounded-lg gap-8">
        <p className={headerTextCN}>About Me</p>
        <div className={rowContainer}>
          {/* Row */}
          <div className={`${rowCN} ${isHidden.one}`}>
            <p className={labelCN}>Start Date</p>
            <div className={inputCN} style={inputWidth}>
              <DatePicker
                placeholder="Select Start Date"
                onChange={(value) => changeDate(value, "Start Date")}
                className="flex-1 h-fit"
                value={userDate("Start Date")}
                popperPlacement="right"
              />
              <IconButton
                icon={RemoveDarkenedGreen}
                imgClassName="w-6"
                onClick={() => removeField("one")}
              />
            </div>
          </div>

          <div className={`${rowCN} ${isHidden.two}`}>
            <p className={labelCN}>Birthday</p>
            <div className={inputCN} style={inputWidth}>
              <DatePicker
                placeholder="Select your Birthday"
                onChange={(val) => changeDate(val, "Birthday")}
                value={userDate("Birthday")}
                className="flex-1"
                popperPlacement="right"
              />
              <IconButton
                icon={RemoveDarkenedGreen}
                imgClassName="w-6"
                onClick={() => removeField("two")}
              />
            </div>
          </div>

          {/* Row */}
          <div className={`${rowCN} ${isHidden.three}`}>
            <p className={labelCN}>LinkedIn</p>
            <div className={inputCN} style={inputWidth}>
              <Input
                placeholder="LinkedIn URL"
                mainWrapperClassName="flex-1"
                value={userSocial}
                onChange={(val) => changeSocial(val)}
              />
              <IconButton
                icon={RemoveDarkenedGreen}
                imgClassName="w-6"
                onClick={() => removeField("three")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsForm;
