const moment = require("moment");

const isOnSameDay = (timestamp1, timestamp2) => {
  const moment1 = moment(timestamp1);
  const moment2 = moment(timestamp2);

  return moment1.isSame(moment2, "day");
};

module.exports = isOnSameDay;
