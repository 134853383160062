import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import cntl from "cntl";

import useProjects from "../../../hooks/useProjects";
import useUsers from "../../../hooks/useUsers";
import useBudgetLineItems, {
  getBudget,
} from "../../../hooks/useBudgetLineItems";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";

import Spinner from "../Spinner/Spinner";
import SummaryTab from "./SummaryTab";
import DetailedSummary from "./DetailedSummary";
import ModalConfirmAll from "../Modal/ModalConfirmAll";

import TabbedContainer from "../TabbedContainer/TabbedContainer";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import { DOCUMENTS_PATH, COA_CODE_TYPES } from "../../../constants";
import { getCSICodeDescription } from "../../../helpers/Budget";
import StaffingSummary from "./StaffingSummary";
import DataImport from "./DataImport";

const PRIMARY_HEADER = "Create Invoice";
const topLeaderItemCN = cntl`
    flex-1
    flex
    flex-col
    mr-4
`;
const topLeaderItemLastCN = cntl`
    flex
    flex-col
    mr-4
`;
const topLeaderTitleCN = cntl`
    text-lg
    text-gray-300
    font-medium
`;
const topLeaderBodyCN = cntl`
    flex
    flex-col
    text-lg
    font-medium
`;
const topLeaderBodyAltCN = cntl`
    flex
    flex-col
    ml-4
    text-lg
`;
const statusPillCN = cntl`
    flex
    justify-center
    items-center
    ml-6
    px-3
    py-1
    bg-gray-300
    text-xs
    text-white
    rounded-full
`;

const handleParseLocationData = (data) => {
  if (data) {
    if (typeof data === "string") {
      return JSON.parse(data);
    }
    return data;
  }
  return null;
};

const InvoiceForm = () => {
  const { state } = useLocation();
  const [users] = useUsers();
  const { data: currentUser } = useCurrentUser();
  const locationState = handleParseLocationData(state) ?? {};
  const { currentProject: project } = useProjects(locationState.projectId);
  const [originalBudgetLineItems, , , budgetType] = useBudgetLineItems(
    locationState.budgetId
  );
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  const [invoiceState, setInvoiceState] = useState({});
  const [timeSheetData, setTimeSheetData] = useState();
  const [budgetTypeOptions, setBudgetTypeOptions] = useState();
  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);
  const [csiCodes, setCsiCodes] = useState([]);

  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [budget, setBudget] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [rateSheet, setRateSheet] = useState();

  useEffect(() => {
    const retrieveBudget = async () => {
      const result = await getBudget(locationState.budgetId);
      setBudget(result);
    };
    if (locationState.budgetId && !budget) {
      retrieveBudget();
    }
  }, [budget, locationState.budgetId]);

  useEffect(() => {
    if (financialsConfiguration?.financials?.rateSheet && !rateSheet) {
      setRateSheet(financialsConfiguration.financials.rateSheet);
    }
  }, [rateSheet, financialsConfiguration]);

  useEffect(() => {
    if (
      financialsConfiguration?.financials?.projectBudgetTypes &&
      !budgetTypeOptions
    ) {
      setBudgetTypeOptions(
        financialsConfiguration.financials.projectBudgetTypes.reduce(
          (arr, item) => {
            arr.push({
              label: item.display,
              value: item.display
                .split(" ")
                .map((str, idx) => {
                  if (idx === 0) {
                    return `${str.slice(0, 1).toLowerCase()}${str.slice(1)}`;
                  }
                  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
                })
                .join(""),
            });

            return arr;
          },
          []
        )
      );
    }
  }, [budgetTypeOptions, financialsConfiguration]);

  useEffect(() => {
    const getTimeSheets = async (query) => {
      TimesheetAPI.getWOP(query).then(({ data: { entries } }) => {
        const result = (entries ?? []).map(({ resource }) => {
          const { periodStart, periodEnd } = resource;
          const user = users.find((usr) => {
            return usr.reference === resource.metadata.createdBy;
          });

          return {
            ...resource,
            rows: resource.rows?.map((row) => {
              return {
                ...row,
                periodStart,
                periodEnd,
                employeeName: `${user.name.firstName} ${user.name.lastName}`,
              };
            }),
          };
        });

        setTimeSheetData(
          result.reduce((arr, item) => {
            return [...arr, ...(item?.rows ?? [])];
          }, [])
        );
      });
    };

    if (project?.id && users?.length && rateSheet && !timeSheetData) {
      const queryString = `?project=${project.reference}`;
      getTimeSheets(queryString);
    }
  }, [project, users, rateSheet, timeSheetData]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const divisionsFormatted = [];
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.map((div) => {
        const division = {
          label: `${div.division} - ${div.description}`,
          value: div.division,
        };
        divisionsFormatted.push(division);

        div.csiCodes.map((csi) => {
          csi.subCodes.map((sub) => {
            const csiCodeFormatted = {
              label: `${div.division} ${csi.code} ${
                sub.code
              } - ${getCSICodeDescription(
                { division: div.division, code: csi.code, subcode: sub.code },
                financialsConfiguration?.financials?.csiCodeMapping,
                revenueAccountingCodes,
                expenseAccountingCodes
              )}`,
              value: `${div.division} ${csi.code} ${sub.code}`,
            };
            csiCodesFormatted.push(csiCodeFormatted);
            return sub;
          });

          return csi;
        });
        return div;
      });
      setCsiCodes(csiCodesFormatted);
    }
  }, [expenseAccountingCodes, financialsConfiguration, revenueAccountingCodes]);

  const projectAddress = React.useMemo(
    () => project?.address?.find((item) => item.use === "Physical"),
    [project?.address]
  );

  const args = React.useMemo(
    () => ({
      invoice: invoiceState,
      dispatch: (action) =>
        setInvoiceState((prev) => {
          return { ...prev, [action.type]: action.value };
        }),
      budgetId: locationState.budgetId,
      projectId: locationState.projectId,
      project,
      lineItems: originalBudgetLineItems.map((item) => {
        return {
          ...item,
          csiCode: csiCodes.find((code) => code.label.includes(item.csiCode))
            ?.label,
        };
      }),
      budget,
      budgetType: budgetType?.value,
      budgetTypeOptions,
      timeSheetData,
    }),
    [
      invoiceState,
      setInvoiceState,
      locationState.budgetId,
      locationState.projectId,
      project,
      originalBudgetLineItems,
      budget,
      budgetType,
      budgetTypeOptions,
      csiCodes,
      timeSheetData,
    ]
  );

  const tabs = React.useMemo(
    () => [
      {
        title: "Summary",
        content: (
          <div className="">
            <SummaryTab {...args} />
          </div>
        ),
      },
      {
        title: "Detailed Summary",
        content: (
          <div className="">
            <DetailedSummary {...args} />
          </div>
        ),
      },
      {
        title: "Staffing",
        content: (
          <div className="">
            <StaffingSummary {...args} />
          </div>
        ),
      },
      {
        title: "Vendors",
        content: <div className="">Vendors</div>,
      },
      {
        title: "Expenses",
        content: <div className="">Expenses</div>,
      },
      {
        title: "Attachments",
        content: <div className="">Attachments</div>,
      },
    ],
    [args]
  );

  const steps = React.useMemo(() => {
    const dataImport = {
      name: "Data Import",
      component: () => (
        <div className="w-full">
          <DataImport {...args} />
        </div>
      ),
    };
    const reviewAndEdit = {
      name: "Review & Edit",
      component: () => (
        <div className="w-full">
          <TabbedContainer
            tabs={tabs}
            activeIndex={tabIndex}
            onTabClick={setTabIndex}
            className="pt-5"
          />
        </div>
      ),
    };
    const bundle = {
      name: "Bundle",
      component: () => <div className="w-full">Bundle</div>,
    };

    return [dataImport, reviewAndEdit, bundle];
  }, [args, tabIndex, tabs]);

  const getCurrentStepView = (params) => {
    return steps[selectedStepIndex].component(params);
  };

  const discardButtonHandler = () => {
    setShowConfirm(true);
  };

  const saveButtonHandler = () => {};

  const handleContinue = async () => {
    if (selectedStepIndex === steps.length - 1) {
      saveButtonHandler();
      setSelectedStepIndex(0);
    } else {
      setSelectedStepIndex((prev) => prev + 1);
    }
  };

  const handleDiscard = () => {
    return DOCUMENTS_PATH;
  };

  const nextButtonHandler = () => {
    handleContinue();
  };

  const stepClickHandler = (index) => {
    setSelectedStepIndex(index);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col flex-1">
          <PrimaryHeader className="pt-6">{PRIMARY_HEADER}</PrimaryHeader>
        </div>
        <div className="flex flex-1 justify-end pr-14">
          <div className={`flex flex-1 justify-end `}>
            <ProgressTracker
              steps={steps.map((s) => s.name)}
              selectedIndex={selectedStepIndex}
              onStepClick={(index) => stepClickHandler(index)}
            />
          </div>
        </div>
      </div>
      <hr />

      {!currentUser && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      {currentUser && (
        <div className="flex flex-col mb-40">
          <div className="flex items-center mt-10 mb-2">
            <div className="text-xl font-medium uppercase">Invoice No. 14</div>
            <div className={statusPillCN}>DRAFT</div>
          </div>

          {selectedStepIndex === 1 && (
            <div
              className="flex justify-between mb-16"
              style={{ minHeight: "192px" }}
            >
              <div className={topLeaderItemCN}>
                <div className={topLeaderTitleCN}>Project</div>
                {projectAddress && (
                  <div className={topLeaderBodyCN}>
                    <span>{projectAddress.street}</span>
                    <span>{projectAddress.street2}</span>
                    <span>{`${projectAddress.city}${
                      projectAddress.state ? `, ${projectAddress.state}` : ""
                    }`}</span>
                    <span>{`${projectAddress.country}${
                      projectAddress.zipCode
                        ? `, ${projectAddress.zipCode}`
                        : ""
                    }`}</span>
                  </div>
                )}
              </div>

              <div className={topLeaderItemCN}>
                <div className={topLeaderTitleCN}>Client</div>
                {project?.client && (
                  <div className={topLeaderBodyCN}>
                    <span>{project.client}</span>
                  </div>
                )}
              </div>

              <div className={topLeaderItemCN}>
                <div className={topLeaderTitleCN}>Contractor</div>
                {project?.contractor && (
                  <div className={topLeaderBodyCN}>
                    <span>{project.contractor}</span>
                  </div>
                )}
              </div>

              <div className={topLeaderItemCN}>
                <div className={topLeaderTitleCN}>Via Architect</div>
                {project?.architect && (
                  <div className={topLeaderBodyCN}>
                    <span>{project.architect}</span>
                  </div>
                )}
              </div>

              <div className={topLeaderItemLastCN}>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>Application Date</div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>Period To</div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>Contract For</div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>Contract Date</div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>Project Number</div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={topLeaderBodyCN}>
                    Project Budget Version #
                  </div>
                  <div className={topLeaderBodyAltCN}>
                    Lorem ipsum dolor sit amet.
                  </div>
                </div>
              </div>

              <div className={topLeaderItemCN} />
            </div>
          )}

          <div>{getCurrentStepView({})}</div>

          <div className="flex justify-end">
            <div
              className="fixed flex w-fit py-4 px-4 border-gray-100 rounded-lg bg-white"
              style={{
                borderWidth: "3px",
                bottom: "75px",
                right: "32px",
                zIndex: "20",
              }}
            >
              {[0, 1, 2].includes(selectedStepIndex) && (
                <>
                  <TertiaryButton
                    className="mr-2"
                    title="Cancel"
                    onClick={discardButtonHandler}
                  />
                  <SecondaryButton
                    className="mr-6"
                    title="Save Draft"
                    onClick={saveButtonHandler}
                  />
                  <PrimaryButton title="Next" onClick={nextButtonHandler} />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <ModalConfirmAll
        navigateToPath={handleDiscard()}
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={`Discard ${PRIMARY_HEADER}`}
      />
    </>
  );
};

InvoiceForm.propTypes = {};

InvoiceForm.defaultProps = {};

export default InvoiceForm;
