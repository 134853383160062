import React from "react";
import { cloneDeep } from "lodash";
import { ProjectAPI, PropertyAPI } from "@griffingroupglobal/eslib-api";
import useProjectById from "../../../hooks/useProjectById";
import usePropertyById from "../../../hooks/usePropertyById";
import { useAppState } from "../../../state/appState";
import Avatar from "../Message/Avatar";
import TertiaryButton from "../Buttons/TertiaryButton";

/**
 * This component takes in the following props
 * addMember - user to be added to the resource
 * currentUser - current user trying to add alternate users to the resource
 * association - resource association
 */

const QuickAddToResource = ({
  addMember,
  projectId,
  propertyId,
  setNonRscMembers,
  currentMembers,
}) => {
  const [{ userDict }] = useAppState();
  const member = userDict?.[addMember];
  const { project } = useProjectById(projectId);
  const { property } = usePropertyById(propertyId);

  const handleAddToProject = async () => {
    const isAlreadyInList = project?.members.find(
      (val) => val?.user === addMember
    );

    // Checks again to make sure user is not on the project
    if (!isAlreadyInList) {
      // original project data
      const original = cloneDeep(project);

      // array of original project members to push into
      const newMemberArray = project?.members;
      newMemberArray.push({ user: addMember });

      // patch project object with the added member pushed in
      const patch = { ...project, members: newMemberArray };

      // api call to update project members
      try {
        currentMembers?.push(member);
        setNonRscMembers([]);
        await ProjectAPI.patch(project.id, patch, original);
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const handleAddToProperty = async () => {
    const isAlreadyInList = property?.members.find(
      (val) => val?.user === addMember
    );

    // Checks again to make sure user is not on the project
    if (!isAlreadyInList) {
      // original project data
      const original = cloneDeep(property);

      // array of original project members to push into
      const newPropMemberArray = property?.members;
      newPropMemberArray.push({ user: addMember });

      // patch project object with the added member pushed in
      const patch = { ...property, members: newPropMemberArray };

      // api call to update project members
      try {
        // eslint-disable-next-line no-underscore-dangle
        await PropertyAPI.patch(propertyId, patch, original);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleAddToResource = () => {
    if (projectId) {
      return handleAddToProject();
    }

    if (propertyId) {
      return handleAddToProperty();
    }

    throw new Error("No project or Property association id");
  };

  return (
    <div className="flex pt-2 w-full justify-between">
      <div className="flex items-center">
        <Avatar name={`${member?.name?.firstName} ${member?.name?.lastName}`} />
        <p className="mr-4">
          {member?.name?.firstName} {member?.name?.lastName}
        </p>
      </div>
      <TertiaryButton
        title={`+ Add to ${projectId ? "Project" : "Property"}`}
        onClick={handleAddToResource}
      />
    </div>
  );
};

export default QuickAddToResource;
