import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useEsTasks from "../../../hooks/useEsTasks";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";

const useTaskSingleData = () => {
  const { tasksDict, reloadTaskList } = useEsTasks();
  const [currentTask, setCurrentTask] = useState(null);
  const history = useHistory();
  const { taskId } = useParams();
  const { usedSopDict } = useSopVersionForTask({ currentTask });
  // if tasksDict isn't loaded, force a refresh
  // Keeps from user being stuck on blank screen
  useEffect(() => {
    if (!Object?.values(tasksDict)?.length) {
      reloadTaskList();
    }
    setCurrentTask(tasksDict?.[taskId]);
  }, [reloadTaskList, taskId, tasksDict]);

  const clearCurrentTask = () => {
    history.push("/tasks");
  };

  return {
    tasksDict,
    currentTask,
    setCurrentTask,
    usedSopDict,
    clearCurrentTask,
  };
};

export default useTaskSingleData;
