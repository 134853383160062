import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { GET_SPACE_VIEW_PATH } from "../../../constants";
import useSpaces from "../../../hooks/useSpaces";
import { useAppState } from "../../../state/appState";
import AssetOverview from "../../../stories/Components/AssetOverview/AssetOverview";
import AttributeWidget from "../../../stories/Components/Attributes/AttributeWidget";
import LinksView from "../../../stories/Components/Links/LinksView";
import LocationWidget from "../../../stories/Components/LocationWidget/LocationWidget";

const AssetDetailView = ({
  editing,
  editedAsset,
  dispatch,
  onFinishEditing,
  isSaving,
  isLoading,
  disableEditing,
  setNewAttributes,
  newAttributes,
  measurements,
}) => {
  const history = useHistory();
  const [{ managementConfiguration }] = useAppState();
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [categoryOptionsList, setCategoryOptionsList] = useState([]);
  const [subcategoryOptionsMap, setSubcategoryOptionsMap] = useState({});
  const [levelTypes, setLevelTypes] = useState();
  const [spaceTypes, setSpaceTypes] = useState();

  useEffect(() => {
    setProjectTypes(
      managementConfiguration?.management?.project?.types
        .filter((type) => type.selected)
        .map((type) => ({
          label: type.display,
          value: type.id,
        }))
    );

    setPropertyTypes(
      managementConfiguration?.management?.property?.types.reduce(
        (list, type) => {
          // eslint-disable-next-line no-param-reassign
          list[type.id] = type.display;
          return list;
        },
        {}
      )
    );

    const categories = managementConfiguration?.management?.asset?.category;
    const subcatMap = {};
    setCategoryOptionsList(() => {
      const catList = categories
        ?.filter((cat) => cat.selected)
        .map((cat) => {
          subcatMap[cat.display] = cat.subcategories
            .filter((subcat) => subcat.selected)
            .map((subcat) => {
              return { label: subcat.display, value: subcat.id };
            });
          return { label: cat.display, value: cat.id };
        });
      return catList;
    });
    setSubcategoryOptionsMap(subcatMap);
    const propertyLevels =
      managementConfiguration?.management?.propertyLevel?.types
        ?.filter((type) => type?.selected)
        ?.reduce((levels, val) => {
          // eslint-disable-next-line no-param-reassign
          levels[val.id] = {
            value: val?.id,
            label: val?.display,
          };
          return levels;
        }, {});
    const propertySpaces =
      managementConfiguration?.management?.propertySpace?.types
        ?.filter((type) => type?.selected)
        ?.reduce((levels, val) => {
          // eslint-disable-next-line no-param-reassign
          levels[val.id] = {
            value: val?.id,
            label: val?.display,
          };
          return levels;
        }, {});
    setSpaceTypes(propertySpaces);
    setLevelTypes(propertyLevels);
  }, [
    managementConfiguration?.management?.asset?.category,
    managementConfiguration?.management.project.types,
    managementConfiguration?.management?.property?.types,
    managementConfiguration?.management?.propertyLevel?.types,
    managementConfiguration?.management?.propertySpace?.types,
  ]);

  /**
   * Spaces
   */

  const hookDeps = useMemo(() => {
    if (editedAsset?.property) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: editedAsset?.property?.split("/")?.[1],
        ref: editedAsset?.property,
        api: "PropertyAPI",
        spaceId: editedAsset?.spaces?.[0],
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: editedAsset?.project?.split("/")?.[1],
      ref: editedAsset?.project,
      api: "ProjectAPI",
      spaceId: editedAsset?.spaces?.[0],
    };
  }, [editedAsset?.project, editedAsset?.property, editedAsset?.spaces]);

  const {
    base: {
      data: { spaces = [], parent },
      isFetching,
    },
  } = useSpaces(hookDeps);

  const space = useMemo(() => {
    return spaces?.find((item) => item?.id === editedAsset?.spaces?.[0]);
  }, [editedAsset?.spaces, spaces]);

  /**
   * Spaces
   */

  const categoryOptions = React.useMemo(
    () =>
      subcategoryOptionsMap
        ? Object.entries(subcategoryOptionsMap).map(([key, val]) => {
            return {
              label: key,
              options: val.map((item) => {
                return { ...item, category: key };
              }),
            };
          })
        : [],
    [subcategoryOptionsMap]
  );

  const handleEdit = (name, val) => {
    dispatch({
      type: name,
      [name]: val,
    });
  };

  const handleCategoryChange = (subcategory) => {
    const { category } = subcategory;
    const value = {
      type: "categorySubcategory",
      category: categoryOptionsList?.find((item) => item.label === category)
        ?.value,
      subcategory: subcategory.value,
    };
    dispatch(value);
  };

  const handleAddSpace = async (val) => {
    dispatch({
      type: "addSpace",
      space: val,
    });
  };

  const handleRemoveSpace = async () => {
    dispatch({
      type: "removeSpace",
    });
    await onFinishEditing("spaces", []);
  };

  const getAssetCategory = (catId) => {
    const category = categoryOptionsList?.find((cat) => cat.value === catId);
    return category?.label;
  };

  const getCategoryValue = () => {
    const cat = subcategoryOptionsMap[
      categoryOptionsList?.find((item) => item.value === editedAsset.category)
        ?.label
    ]?.find((item) => item.value === editedAsset?.subcategory);
    return cat;
  };

  const handleChange = (field, val) => {
    switch (field) {
      case "name":
        handleEdit("name", val);
        break;
      case "assetType":
        handleCategoryChange(val);
        break;
      case "description":
        handleEdit("description", val);
        break;
      default:
    }
  };

  const handleEditAttribute = useCallback(
    (name, val) => {
      dispatch({
        type: name,
        [name]: val,
      });
    },
    [dispatch]
  );

  const handleEditTwo = useCallback(
    (key1, key2, value) => {
      dispatch({
        type: "edit2",
        key1,
        key2,
        value,
      });
    },
    [dispatch]
  );

  const onClickSpaceView = (parnt, id) => {
    history.push(GET_SPACE_VIEW_PATH[hookDeps.route](parnt, id));
  };

  const attributeInformation = useMemo(() => {
    return (
      <AttributeWidget
        resource={editedAsset}
        dispatch={dispatch}
        editing={editing}
        defaultAttributes={[
          {
            title: "Brand",
            fieldType: "text-entry",
            value: editedAsset?.generalInfo?.brand ?? "",
            onChange: (val) => handleEditAttribute("brand", val),
          },
          {
            title: "Quantity",
            fieldType: "number-entry",
            value: editedAsset?.generalInfo?.quantity ?? "",
            onChange: (val) => handleEditAttribute("quantity", val),
          },
          {
            title: "Purchase Date",
            fieldType: "date-picker",
            value: editedAsset?.generalInfo?.purchaseDate
              ? editedAsset?.generalInfo?.purchaseDate
              : "",
            onChange: (val) =>
              handleEditTwo("generalInfo", "purchaseDate", val),
          },
          {
            title: "Condition",
            fieldType: "ddl",
            fieldValues: ["Excellent", "Fair", "Poor"],
            value: editedAsset?.generalInfo?.condition ?? "",
            onChange: (val) => handleEditTwo("generalInfo", "condition", val),
          },
          {
            title: "Place of Origin",
            fieldType: "text-entry",
            value: editedAsset?.generalInfo?.origin ?? "",
            onChange: (val) => handleEditTwo("generalInfo", "origin", val),
          },
          {
            title: "Year Made",
            fieldType: "text-entry",
            value: editedAsset?.generalInfo?.yearMade,
            onChange: (val) => handleEditTwo("generalInfo", "yearMade", val),
          },
          {
            title: "Number of Previous Owners",
            fieldType: "number-entry",
            value: editedAsset?.generalInfo?.numberOfOwners ?? "",
            onChange: (val) =>
              handleEditTwo("generalInfo", "numberOfOwners", val),
          },
          {
            title: "Family Legacy",
            fieldType: "text-entry",
            value: editedAsset?.generalInfo?.legacy,
            onChange: (val) => handleEditTwo("generalInfo", "legacy", val),
          },
        ]}
        disableEditing={disableEditing}
        setNewAttributes={setNewAttributes}
        measurements={measurements}
        newAttributes={newAttributes}
      />
    );
  }, [
    disableEditing,
    dispatch,
    editedAsset,
    editing,
    handleEditAttribute,
    handleEditTwo,
    measurements,
    newAttributes,
    setNewAttributes,
  ]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row h-min w-full pb-6">
        <AssetOverview
          onChange={handleChange}
          asset={editedAsset}
          assetTypes={categoryOptions}
          getAssetCategoryValue={getCategoryValue}
          getAssetCategory={getAssetCategory}
          editing={editing}
          dispatch={dispatch}
        />

        <div className="flex w-1/3 ml-5 min-h-0 flex-auto">
          <LocationWidget
            spaces={spaces}
            space={space}
            editedAsset={editedAsset}
            parent={parent}
            resourceTypes={
              parent?.resource === "Property" ? propertyTypes : projectTypes
            }
            resourceDetails={{
              label: "Type",
              value:
                parent?.resource === "Property"
                  ? propertyTypes?.[parent?.propertyType]
                  : projectTypes?.find(
                      (type) => type?.value === parent?.projectType
                    )?.label,
            }}
            dispatch={dispatch}
            handleAddSpace={handleAddSpace}
            removeSpace={handleRemoveSpace}
            onFinishEditing={onFinishEditing}
            levelTypes={levelTypes}
            spaceTypes={spaceTypes}
            isFetching={isFetching}
            isSaving={isSaving}
            disableEditing={disableEditing}
            editing={editing}
            onClickSpaceView={onClickSpaceView}
          />
        </div>
      </div>
      <div
        id="about-property-row-2"
        className="grid grid-cols-2 w-full gap-4 pb-6"
      >
        {attributeInformation}
      </div>
      <div className="flex flex-col w-2/5">
        <LinksView
          editing={editing}
          resource={editedAsset}
          dispatch={dispatch}
          disableEditing={disableEditing}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

AssetDetailView.propTypes = {
  /**
   * edited asset form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  editedAsset: PropTypes.object,
  /**
   * create asset form dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * function called to save edits
   */
  onFinishEditing: PropTypes.func,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableEditing: PropTypes.bool,
  editing: PropTypes.bool,
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
};

AssetDetailView.defaultProps = {
  editedAsset: undefined,
  dispatch: undefined,
  onFinishEditing: undefined,
  isSaving: false,
  isLoading: true,
  disableEditing: false,
  editing: false,
  setNewAttributes: undefined,
  newAttributes: undefined,
  measurements: undefined,
};

export default AssetDetailView;
