/* eslint-disable no-param-reassign */
import { UserAPI } from "@griffingroupglobal/eslib-api";
import VCard from "vcard-creator";
import _ from "lodash";
import { uploadFile } from "./File";
import { isNumeric } from "./Utilities";
import { toTitleCase } from "./Formatters";
import { PTO_CATEGORY, RSVP_STATUSES, UPDATE_USERS } from "../constants";
import acceptedIcon from "../stories/assets/images/acceptedIcon.svg";
import maybeIcon from "../stories/assets/images/maybeIcon.svg";
import declineIcon from "../stories/assets/images/declineIcon.svg";

const createCompanyContact = async (companyName, moreInfo = {}) => {
  // create company contact
  const companyBody = {
    company: {
      valueType: "string",
      value: companyName,
    },
    kind: "company",
    ...moreInfo,
  };

  const { data } = await UserAPI.post(companyBody);
  return data;
};

/**
 *
 * Formats current user profile updates
 * Uploads avatar, returns reference
 * & user data formated for patch
 *
 */
const formatCurrentUserEditPayload = async (
  currentUser,
  userInfo,
  currentTags
) => {
  const phoneNumbers = userInfo?.phoneNumbers
    ?.map((phone, index) => {
      return {
        system: "Phone",
        use: phone?.type,
        value: phone?.value,
        rank: index + 1,
      };
    })
    .filter((cp) => !!cp.value);

  const emails = userInfo?.emails
    ?.map((email, index) => ({
      system: "Email",
      use: email?.type,
      value: email?.value,
      rank: index + 1,
    }))
    .filter((cp) => !!cp.value);
  const websites = userInfo?.websites
    ?.map((website, index) => ({
      system: "Internet",
      use: website?.type,
      value: website?.value,
      rank: index + 1,
    }))
    .filter((cp) => !!cp.value);
  const addresses = userInfo?.addresses?.map((address) => ({
    use: address?.type,
    street: address?.address1?.label ?? address?.street,
    street2: address?.address2 ?? address?.street2,
    city: address?.city,
    state: address?.state,
    zipCode: address?.zipCode,
    country: address?.country,
  }));
  const socials = userInfo?.socials
    ?.map((social) => ({
      platform: social?.type?.label || social?.type,
      value: social?.value,
    }))
    .filter((cp) => !!cp.value);
  const dates = userInfo?.dates
    ?.map((date) => ({
      occasion: date?.type?.label || date?.type,
      value: date?.value,
    }))
    .filter((cp) => !!cp.value);
  const contactPoint = [...phoneNumbers, ...emails, ...websites];

  let currentAvatar = currentUser?.avatar;

  if (currentUser?.avatar?.name) {
    const avatarFileReference = currentUser?.avatar?.reference;
    if (currentUser?.avatar?.name && !avatarFileReference) {
      const fileRef = await uploadFile(currentUser?.avatar);
      currentAvatar = fileRef;
    }
  }

  const payload = {
    ...currentUser,
    contactPoint,
    address: addresses,
    social: socials,
    dates,
    notes: userInfo?.notes,
    avatar: currentAvatar,
    gender: userInfo?.gender,
    disciplines: userInfo?.disciplines || [],
    territories: userInfo?.territories || [],
    tags: currentTags?.map((tag) => tag?.value) || [],
  };

  let companyReference = currentUser?.companyName?.value;
  // for new
  if (currentUser?.isNewCompany) {
    const company = await createCompanyContact(currentUser?.companyName?.value);
    companyReference = company.reference;
  }

  if (companyReference) {
    payload.company = {
      valueType: "reference",
      value: companyReference,
    };
  }

  return payload;
};

const formatUserPayload = async (
  contactDetails,
  contactInfo,
  kind,
  currentTags,
  links
) => {
  const phoneNumbers = contactInfo?.phoneNumbers
    ?.map((phone, index) => {
      return {
        system: "Phone",
        use: phone?.type,
        value: phone?.value,
        rank: index + 1,
      };
    })
    .filter((cp) => !!cp.value);

  const emails = contactInfo?.emails
    ?.map((email, index) => ({
      system: "Email",
      use: email?.type,
      value: email?.value,
      rank: index + 1,
    }))
    .filter((cp) => !!cp.value);
  const websites = contactInfo?.websites
    ?.map((website, index) => ({
      system: "Internet",
      use: website?.type,
      value: website?.value,
      rank: index + 1,
    }))
    .filter((cp) => !!cp.value);
  const addresses = contactInfo?.addresses?.reduce((initial, address) => {
    if (address?.use ?? address?.type) {
      initial.push({
        use: address?.use ?? address?.type,
        street: address?.address1?.label ?? address?.street,
        street2: address?.address2 ?? address?.street2,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
        country: address?.country,
      });
    }
    return initial;
  }, []);
  const socials = contactInfo?.socials
    ?.map((social) => ({
      platform: social?.type?.label || social?.type,
      value: social?.value,
    }))
    .filter((cp) => !!cp.value);
  const dates = contactInfo?.dates
    ?.map((date) => ({
      occasion: date?.type?.label || date?.type,
      value: date?.value,
    }))
    .filter((cp) => !!cp.value);
  const contactPoint = [...phoneNumbers, ...emails, ...websites];

  const payload = {
    kind,
    title: contactDetails?.title,
    contactPoint,
    address: addresses,
    social: socials,
    dates,
    notes: contactInfo?.notes,
    avatar: contactDetails?.avatar,
    gender: contactInfo?.gender,
    disciplines: contactInfo?.disciplines,
    territories: contactInfo?.territories,
    tags: currentTags?.map((tag) => tag?.value) || [],
    links: links || [],
  };

  if (kind === "company") {
    payload.company = {
      valueType: "string",
      value: toTitleCase(contactDetails?.firstName),
    };
  } else {
    payload.name = {
      firstName: contactDetails?.firstName,
      lastName: contactDetails?.lastName,
    };

    const companyReference = contactDetails?.company?.value;

    if (companyReference) {
      payload.company = {
        valueType: "reference",
        value: companyReference,
      };
      payload.companyName = contactDetails?.companyName;
    }
  }

  return payload;
};

/**
 *
 * check for valid gender
 * @param gender
 * @returns bool
 */
const hasValidGenderValue = (gender) => {
  const genderValues = ["male", "female", "unspecified", "nonbinary"];
  const validGender = genderValues.includes(gender.toLowerCase());

  return validGender;
};

/**
 * UserContactData is a helper object to format data from a User payload returned by the API into a more organized
 * format for display.
 */
class UserContactData {
  user = {
    firstName: "",
    lastName: "",
    title: "",
    company: "",
    gender: "",
    avatar: null,
  };

  emails = [];

  phoneNumbers = [];

  websites = [];

  addresses = [];

  socials = [];

  dates = [];

  notes = "";

  /**
   * Helper method to extract contacts matching a specific system (like "Phone" or "Email") from the
   * contacts for a user payload.
   */
  static filterPayloadContactsBySystem(payload, contactSystem) {
    return payload?.contactPoint
      ?.filter((contact) => contact.system === contactSystem)
      .map((contact) => ({ type: contact.use, value: contact.value }));
  }

  /**
   * Create a new {@link UserContactData} from a User payload. The User payload may come from useCurrentUser or another
   * way to access Users.
   */
  //  EDIT: payload.socials -> payload.social
  //  EDIT: added gender & avatar to UserContactData constructor & fromUserPayload
  static fromUserPayload(payload) {
    return new UserContactData({
      user: {
        firstName: payload?.name?.firstName ?? "",
        lastName: payload?.name?.lastName ?? "",
        title: payload?.title ?? "",
        company: payload?.company?.value ?? "",
        gender: payload?.gender ?? "",
      },
      emails: this.filterPayloadContactsBySystem(payload, "Email") ?? [],
      phoneNumbers: this.filterPayloadContactsBySystem(payload, "Phone") ?? [],
      websites: this.filterPayloadContactsBySystem(payload, "Internet") ?? [],
      addresses:
        payload?.address?.map((address) => ({
          type: address?.use || address?.type,
          street: address?.street,
          street2: address?.street2,
          city: address?.city,
          state: address?.state,
          country: address?.country,
          zipCode: address?.zipCode,
        })) ?? [],
      socials:
        payload?.social?.map((social) => ({
          type: social.platform,
          value: social.value,
        })) ?? [],
      dates:
        payload?.dates?.map((date) => ({
          type: date.occasion,
          value: date.value,
        })) ?? [],
      notes: payload?.notes ?? "",
      disciplines: payload?.disciplines ?? [],
      territories: payload?.territories ?? [],
    });
  }

  /**
   * Create a new {@link UserContactData} from a Current User payload.
   */
  //  Added avatar to userDetails
  static fromCurrentUserPayload(payload, companyContacts) {
    const userDetails = new UserContactData({
      user: {
        firstName: payload?.name?.firstName ?? "",
        lastName: payload?.name?.lastName ?? "",
        title: payload?.title ?? "",
        company: companyContacts?.find(
          (cont) => cont.value === payload?.company?.value
        ),
      },
    });

    const userInfo = new UserContactData({
      emails: this.filterPayloadContactsBySystem(payload, "Email") ?? [],
      phoneNumbers: this.filterPayloadContactsBySystem(payload, "Phone") ?? [],
      websites: this.filterPayloadContactsBySystem(payload, "Internet") ?? [],
      addresses:
        payload?.address?.map((address) => ({
          type: address.use,
          address1: address.street,
          address2: address.street2,
          city: address.city,
          state: address.state,
          zipCode: address.zipCode,
          value: `${address.street || ""} ${address.street2 || ""} ${
            address.city || ""
          } ${address.state || ""} ${address.country || ""} ${
            address.zipCode || ""
          }`,
        })) ?? [],
      socials:
        payload?.social?.map((social) => ({
          type: social.platform,
          value: social.value,
        })) ?? [],
      dates:
        payload?.dates?.map((date) => ({
          type: date.occasion,
          value: date.value,
        })) ?? [],
      notes: payload?.notes ?? "",
      disciplines: payload?.disciplines ?? [],
      territories: payload?.territories ?? [],
    });

    return { userDetails, userInfo };
  }

  /**
   * Constructor. Please use {@link fromUserPayload} instead.
   */
  constructor({
    user,
    emails,
    phoneNumbers,
    websites,
    addresses,
    socials,
    dates,
    notes,
    disciplines,
    territories,
  }) {
    this.user = user;
    this.emails = emails;
    this.phoneNumbers = phoneNumbers;
    this.websites = websites;
    this.addresses = addresses;
    this.socials = socials;
    this.dates = dates;
    this.notes = notes;
    this.disciplines = disciplines;
    this.territories = territories;
  }

  /**
   * Formats an address object as a single string.
   */
  static formatAddress(address) {
    const { city, country, state, street, street2, zipCode } = address;
    /**
     * Keep line feed format because it's used to format address in UI
     */
    return `
      ${street || ""}
      ${street2 || ""}
      ${city || ""} ${state || ""}
      ${country || ""}
      ${zipCode || ""}
    `;
  }
}

/**
 * @deprecated. Use {@link UserContactData} instead.
 * TODO: ES-2874 Refactor User/Contact helper functions
 */
const formatPayloadUser = (payload) => {
  let contactDetails = {};
  let contactInfo = {};
  if (payload?.kind === "company") {
    contactDetails = {
      id: payload?.id,
      firstName: payload?.company?.value,
      lastName: "",
      title: payload?.title,
      company: {},
      companyName: payload?.company?.value,
      avatar: payload?.avatar,
      contactPoint: payload?.contactPoint,
      kind: payload?.kind,
      links: payload?.links,
    };
  } else {
    contactDetails = {
      firstName: payload?.name?.firstName,
      lastName: payload?.name?.lastName,
      title: payload?.title,
      company: {
        label: payload?.companyName,
        value: payload?.company?.value,
      },
      companyName: payload?.companyName,
      isOnline: payload?.isOnline,
      avatar: payload?.avatar,
      contactPoint: payload?.contactPoint,
      kind: payload?.kind,
      links: payload?.links,
    };
  }

  const emails =
    payload?.contactPoint
      ?.filter((contact) => contact.system === "Email")
      .map((contact) => ({ type: contact.use, value: contact.value })) || [];
  const phoneNumbers =
    payload?.contactPoint
      ?.filter((phone) => phone.system === "Phone")
      .map((phone) => ({ type: phone.use, value: phone.value })) || [];
  const websites =
    payload?.contactPoint
      ?.filter((website) => website.system === "Internet")
      .map((website) => ({ type: website.use, value: website.value })) || [];
  const socials =
    payload?.social?.map((social) => ({
      type: social.platform,
      value: social.value,
    })) || [];
  const dates =
    payload?.dates?.map((date) => ({
      type: date.occasion,
      value: date.value,
    })) || [];
  contactInfo = {
    phoneNumbers,
    emails,
    websites,
    addresses: payload?.address,
    socials,
    dates,
    notes: payload?.notes,
    gender: payload?.gender,
    kind: payload?.kind,
    disciplines: payload?.disciplines,
    territories: payload?.territories,
    tags: payload?.tags,
    links: payload?.links,
  };

  return { contactDetails, contactInfo };
};

const formatUserPreference = (user, preference) => {
  const like = preference?.likes?.map((item) => ({
    ...item,
    display: item.label,
    link: {
      url: item?.linkType === "Website" ? item?.link : undefined,
      reference: item?.linkType === "SOP" ? item?.link : undefined,
    },
  }));
  const dislike = preference?.dislikes?.map((item) => ({
    ...item,
    display: item.label,
    link: {
      url: item?.linkType === "Website" ? item?.link : undefined,
      reference: item?.linkType === "SOP" ? item?.link : undefined,
    },
  }));
  const special = preference?.specials?.map((item) => ({
    ...item,
    display: item.label,
    link: {
      url: item?.linkType === "Website" ? item?.link : undefined,
      reference: item?.linkType === "SOP" ? item?.link : undefined,
    },
  }));

  if (user) {
    const newPreference = { user: `User/${user.id}`, like, dislike, special };
    return newPreference;
  }
  const newPreference = { like, dislike, special };
  return newPreference;
};

const formatPreferenceUser = (preferences) => {
  const likes = preferences?.like?.map((item) => ({
    ...item,
    label: item.display,
    link: item?.link?.url || item?.link?.reference,
    // eslint-disable-next-line no-nested-ternary
    linkType: item?.link?.url
      ? "Website"
      : item?.link?.reference
      ? "SOP"
      : undefined,
  }));
  const dislikes = preferences?.dislike?.map((item) => ({
    ...item,
    label: item.display,
    link: item?.link?.url || item?.link?.reference,
    // eslint-disable-next-line no-nested-ternary
    linkType: item?.link?.url
      ? "Website"
      : item?.link?.reference
      ? "SOP"
      : undefined,
  }));
  const specials = preferences?.special?.map((item) => ({
    ...item,
    label: item.display,
    link: item?.link?.url || item?.link?.reference,
    // eslint-disable-next-line no-nested-ternary
    linkType: item?.link?.url
      ? "Website"
      : item?.link?.reference
      ? "SOP"
      : undefined,
  }));
  const newPreference = { likes, dislikes, specials };
  return newPreference;
};

const getInitials = (name) => {
  const firstNameLetter = name?.firstName?.charAt(0) ?? "";
  const lastNameLetter = name?.lastName?.charAt(0) ?? "";
  return `${firstNameLetter}${lastNameLetter}`;
};

/**
 * Transforms a response from /api/User? into a list of User objects for the members list.
 * @param roleMembersResult
 */
export const transformUsersResponseToUserAvatars = (roleMembersResult) =>
  _.chain(roleMembersResult.data.entries)
    .map((entry) =>
      _.pick(entry.resource, ["id", "name", "avatar", "isOnline", "kind"])
    )
    .filter((user) => !!user.name)
    .value();

const getCompanyInitials = (name) => {
  return name?.charAt(0);
};

const getVCard = (contact) => {
  const vCard = new VCard();

  switch (contact?.kind) {
    case "company": {
      vCard.addName(contact?.company?.value);

      contact?.contactPoint
        ?.filter((cp) => cp.system === "Email")
        ?.map((email) => {
          vCard.addEmail(email?.value);
          return email;
        });

      contact?.contactPoint
        ?.filter((cp) => cp.system === "Phone")
        ?.map((phone) => {
          vCard.addPhoneNumber(phone?.value, "WORK");
          return phone;
        });

      break;
    }
    case "nonmember": {
      vCard.addName(contact?.name?.lastName, contact?.name?.firstName);

      contact?.contactPoint
        ?.filter((cp) => cp.system === "Email")
        ?.map((email) => {
          vCard.addEmail(email?.value);
          return email;
        });

      contact?.contactPoint
        ?.filter((cp) => cp.system === "Phone")
        ?.map((phone) => {
          vCard.addPhoneNumber(phone?.value, "WORK");
          return phone;
        });

      if (contact?.company?.label) {
        vCard.addCompany(contact?.company?.label);
      }

      if (contact?.title) {
        vCard.addJobtitle(contact?.title);
      }

      break;
    }
    default: {
      vCard
        .addName(contact?.name?.lastName, contact?.name?.firstName)
        .addEmail(contact?.email);

      if (contact?.company?.label) {
        vCard.addCompany(contact?.company?.label);
      }

      contact?.contactPoint
        ?.filter((cp) => cp.system === "Phone")
        ?.map((phone) => {
          vCard.addPhoneNumber(phone?.value, "WORK");
          return phone;
        });

      if (contact?.title) {
        vCard.addJobtitle(contact?.title);
      }
    }
  }

  return vCard;
};

const formatContactForExport = (contact) => {
  let Name = `${contact?.name?.firstName} ${contact?.name?.lastName}`;
  let Company;
  let Mobile;
  let Email;

  contact?.contactPoint?.map((cp) => {
    if (cp?.system === "Phone") {
      Mobile = cp?.value;
    }
    return cp;
  });

  switch (contact?.kind) {
    case "member": {
      Company = contact?.company?.label;
      Email = contact?.email;
      break;
    }
    case "company": {
      Name = contact?.company?.label;
      contact?.contactPoint?.map((cp) => {
        if (cp?.system === "Email") {
          Email = cp?.value;
        }
        return cp;
      });
      break;
    }
    case "nonmember": {
      Company = contact?.company?.label;
      contact?.contactPoint?.map((cp) => {
        if (cp?.system === "Email") {
          Email = cp?.value;
        }
        return cp;
      });
      break;
    }
    default: {
      Company = contact?.company?.label;
      Email = contact?.email;
    }
  }

  return {
    Company,
    Name,
    Mobile,
    Email,
    Role: contact?.role,
  };
};

const getFilteredContacts = (
  contacts,
  searchKeyword,
  applyFilterByTab,
  tabIndex,
  currentUserId
) => {
  return contacts
    ?.filter(
      (contact) =>
        contact?.name?.firstName
          ?.toLowerCase()
          ?.includes(searchKeyword?.toLowerCase()) ||
        contact?.name?.lastName
          ?.toLowerCase()
          ?.includes(searchKeyword?.toLowerCase()) ||
        contact?.company?.value
          ?.toLowerCase()
          ?.includes(searchKeyword?.toLowerCase())
    )
    ?.filter((contact) => {
      if (!applyFilterByTab) {
        return true;
      }
      switch (tabIndex) {
        case 1: {
          return contact.kind === "company";
        }
        case 2: {
          return contact.kind === "nonmember";
        }
        case 3: {
          return contact.kind === "member";
        }
        default: {
          return true;
        }
      }
    })
    .map((item) => {
      if (item.id === currentUserId) {
        const user = { ...item };
        user.hideSelection = true;
        return user;
      }
      return item;
    });
};

const getFilteredAndReducedContactsByFirstLetter = (
  contacts,
  searchKeyword,
  applyFilterByTab,
  tabIndex
) => {
  const sortContacts = getFilteredContacts(
    contacts,
    searchKeyword,
    applyFilterByTab,
    tabIndex
  ).reduce((result, contact) => {
    const name =
      contact?.name?.firstName?.toUpperCase() ||
      contact?.company?.value?.toUpperCase();
    let firstLetter = name && name[0];

    if (isNumeric(firstLetter)) {
      firstLetter = "#";
    }

    if (typeof result[firstLetter] === "undefined") {
      return {
        ...result,
        [firstLetter]: [contact],
      };
    }
    result[firstLetter].push(contact);

    return result;
  }, {});

  return sortContacts;
};

const validateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const validateContactsImportedCsvFile = (rows, setErrors) => {
  if (!rows.length) {
    setErrors((prev) => [...prev, "File is empty"]);
  } else if (
    rows[0].contactType === undefined ||
    !rows[0].name === undefined ||
    !rows[0].email === undefined ||
    !rows[0].phone === undefined
  ) {
    setErrors((prev) => [
      ...prev,
      "Missing one or more required columns - contactType, name, email, phone",
    ]);
  } else {
    rows.map((row, idx) => {
      if (
        row.contactType === "" ||
        row.name === "" ||
        row.email === "" ||
        row.phone === ""
      ) {
        setErrors((prev) => [
          ...prev,
          `Row ${
            idx + 1
          }- Missing one or more required values (contactType, name, email, phone)`,
        ]);
      } else if (row.contactType !== "user" && row.contactType !== "company") {
        setErrors((prev) => [
          ...prev,
          `Row ${
            idx + 1
          }- contactType is invalid. Must be either "user" or "contactType"`,
        ]);
      } else if (!/^(\d+-?)+\d+$/.test(row.phone)) {
        setErrors((prev) => [
          ...prev,
          `Row ${
            idx + 1
          }- phone is invalid. Must be of type XXX-XXX-XX or XXXX`,
        ]);
      } else if (validateEmail(!row.email)) {
        setErrors((prev) => [...prev, `Row ${idx + 1}- email is invalid.`]);
      }

      return row;
    });
  }
};

const getUserPostpayloadFromCsvImportedContact = (contact) => {
  const name = contact?.name;
  const userPayload = {
    name: {
      firstName: name?.substring(0, name.indexOf(" ")),
      lastName: name?.substring(name.indexOf(" ") + 1),
    },
    kind: "nonmember",
    company: contact?.company,
    address: {
      type: "Work",
      street: contact?.address1,
      street2: contact?.address2,
      city: contact?.city,
      state: contact?.state,
      country: contact?.country,
      zipCode: contact.zipCode,
    },
    contactPoint: [
      {
        system: "Email",
        use: "Work",
        value: contact?.email,
      },
      {
        system: "Phone",
        use: "Work",
        value: contact?.phone,
      },
    ],
  };

  return userPayload;
};

const getInviteMemberPayload = (user, other) => {
  const payload = { ...user };

  payload.isEmployee = other?.isEmployee;
  payload.role = other?.userRole?.value || other?.role?.value;
  payload.kind = "member";
  payload.email = other?.email?.value || other?.email;

  if (other?.isEmployee) {
    const hd = new Date(other?.hireDate);
    const newHd = new Date(
      Date.UTC(hd.getFullYear(), hd.getMonth(), hd.getDate())
    );

    payload.employeeInfo = {
      ...user?.employeeInfo,
      isExempt: other?.employeeType?.value === "exempt",
      annualVacation: other?.annualVacation || 0,
      hourlyRate: other?.salaryType?.value === "hourly" ? other?.salary : 0,
      annualSalary: other?.salaryType?.value === "annual" ? other?.salary : 0,
      pto: {
        category: other?.stateOfEmployment?.value
          ? PTO_CATEGORY.location
          : PTO_CATEGORY.general,
        locationId: other?.stateOfEmployment?.value,
      },
      id: other?.employeeId,
      hireDate: newHd,
    };
    payload.directReport = other?.directReport;
  }

  payload.contactPoint = [];

  if (other?.phone?.value) {
    payload.contactPoint.push({
      system: "Phone",
      use: "Mobile",
      value: other?.phone?.value,
    });
  }

  if (payload?.email) {
    payload.contactPoint.push({
      system: "Email",
      use: "Other",
      value: payload?.email,
    });
  }

  return payload;
};

/**
 * Creates a user if do not exist, or sends invitation to existing user to join EstateSpace
 * @param {Promise<Object>} postUser - Async function to create a new user
 * @param {Object} invitee - New user to create
 * @param {Object []} users -  List of existing users
 */
const inviteContact = async (postUser, invitee, users, companiesCreated) => {
  if (invitee?.isExisting) {
    const originalUser = users.find((user) => user.reference === invitee.value);
    const updatedBody = getInviteMemberPayload(originalUser, invitee);

    return UserAPI.postWOP(`${originalUser?.id}/$invitecontact`, updatedBody);
  }

  const user = { ...invitee };
  let companyReference = invitee?.company?.value;
  // for new company
  if (invitee?.isNewCompany) {
    const foundCompanyReference = companiesCreated?.find(
      (company) => company?.label === invitee?.company?.label
    )?.value;

    if (foundCompanyReference) {
      companyReference = foundCompanyReference;
      user.company = {
        valueType: "reference",
        value: companyReference,
      };
    } else {
      const data = await createCompanyContact(invitee?.company?.label);
      companyReference = data?.reference;
      user.company = {
        valueType: "reference",
        value: companyReference,
      };
      companiesCreated.push({
        label: data?.company?.value,
        value: data?.reference,
      });
    }
  }
  const updatedBody = getInviteMemberPayload(user, invitee);

  return postUser(updatedBody);
};

const formatNonMemberToContact = (user, users) => {
  return {
    label: `${user?.name?.firstName} ${user?.name?.lastName}`,
    name: {
      firstName: user?.name?.firstName,
      lastName: user?.name?.lastName,
    },
    value: user?.reference,
    company: user?.company?.value
      ? users?.find((u) => u?.reference === user?.company?.value)?.company
          ?.value
      : "",
    contactPoint: user?.contactPoint,
    isEmployee: user?.isEmployee,
    avatar: user?.avatar,
  };
};

const formatUserPreferences = (preference) => {
  return (
    preference?.map((l) => ({
      ...l,
      label: l.display,
      link: l?.link?.url || l?.link?.reference,
      value: l?.value,
      // eslint-disable-next-line no-nested-ternary
      linkType: l?.link?.url
        ? "Website"
        : l?.link?.reference
        ? "SOP"
        : undefined,
    })) || []
  );
};

const getCompanyResourceForCsvImportedContact = async (
  contact,
  allCompanyContacts
) => {
  let company;
  let isExisting = false;

  switch (contact?.contactType) {
    case "user": {
      const companyName = contact?.company;
      const companyExist = allCompanyContacts.find(
        (cc) =>
          cc?.company?.value?.toLowerCase() ===
          companyName?.trim()?.toLowerCase()
      );

      if (companyExist) {
        company = companyExist;
        isExisting = true;
      } else {
        const resource = await createCompanyContact(companyName);
        company = resource;
      }

      break;
    }
    case "company": {
      const companyName = contact?.name;
      const companyExist = allCompanyContacts.find(
        (cc) =>
          cc?.company?.value?.toLowerCase() ===
          companyName?.trim()?.toLowerCase()
      );

      if (companyExist) {
        company = companyExist;
        isExisting = true;
      } else {
        const moreInfo = {
          contactPoint: [
            {
              system: "Email",
              use: "Work",
              value: contact?.email,
            },
            {
              system: "Phone",
              use: "Work",
              value: contact?.phone,
            },
          ],
        };

        const resource = await createCompanyContact(companyName, moreInfo);
        company = resource;
      }

      break;
    }
    default: {
      // eslint-disable-next-line no-console
      console.log("Not a supporrted user type", contact?.contactType);
    }
  }

  return { company, isExisting };
};

const handleUserImport = async (contact, allCompanyContacts) => {
  const userPayload = getUserPostpayloadFromCsvImportedContact(contact);

  if (contact?.company && contact?.company !== "") {
    const { company, isExisting } =
      await getCompanyResourceForCsvImportedContact(
        contact,
        allCompanyContacts
      );

    userPayload.company = {
      valueType: "reference",
      value: company?.reference,
    };

    if (!isExisting) {
      allCompanyContacts.push(company);
    }
  }

  await UserAPI.post(userPayload);
};

const handleCompanyImport = async (contact, allCompanyContacts) => {
  const { company, isExisting } = await getCompanyResourceForCsvImportedContact(
    contact,
    allCompanyContacts
  );

  if (!isExisting) {
    allCompanyContacts.push(company);
  }
};

const createUserPatchPayload = async (data, currentUser) => {
  const contactPoint = [
    ...data.contactInfo?.phoneNumbers
      ?.map((num) => {
        return {
          system: "Phone",
          use: num?.type,
          value: num?.value,
        };
      })
      .filter((cp) => !!cp.value),
    ...data.contactInfo?.emails
      ?.map((email) => {
        return {
          system: "Email",
          use: email?.type,
          value: email?.value,
        };
      })
      .filter((cp) => !!cp.value),
    ...data.contactInfo?.websites
      ?.map((website) => {
        return {
          system: "Internet",
          use: website?.type,
          value: website?.value,
        };
      })
      .filter((cp) => !!cp.value),
  ].filter((cp) => !!cp.value);

  let address = [];
  if (data?.contactInfo?.addresses?.length) {
    address = data?.contactInfo?.addresses?.map((add) => ({
      ...add,
      use: add?.use || add?.type,
      city: add?.city,
      street: add?.address1?.label,
      street2: add?.address2,
      state: add?.state,
      country: add?.country,
    }));
  }

  const patchUser = {
    ...currentUser,
    name: {
      ...currentUser?.name,
      firstName: data.contactDetails?.firstName,
      lastName: data.contactDetails?.lastName,
    },
    title: data.contactDetails?.title,
    contactPoint,
    address,
    dates: data.contactInfo?.dates?.map((date) => {
      return {
        occasion: date?.type,
        value: new Date(date?.value),
      };
    }),
    social: data.contactInfo?.socials
      ?.map((social) => {
        return {
          platform: social?.type,
          value: social?.value,
        };
      })
      .filter((cp) => !!cp.value),
    notes: data.contactInfo?.notes,
    gender: data.contactInfo?.gender,
    registered: true,
    disciplines: data.contactInfo?.disciplines,
    territories: data.contactInfo?.territories,
  };

  let companyReference = data.contactDetails?.company?.value;
  // for new
  if (data.contactDetails?.isNewCompany) {
    const company = await createCompanyContact(
      data.contactDetails?.company?.value
    );
    companyReference = company.reference;
  }

  patchUser.company = {
    valueType: "reference",
    value: companyReference,
  };
  const avatarFileReference = data.contactDetails?.avatar?.reference;
  if (data.contactDetails?.avatar?.name && !avatarFileReference) {
    const fileRef = await uploadFile(data.contactDetails?.avatar);
    patchUser.avatar = fileRef;
  }

  return patchUser;
};

const createPatchPreferencePayload = (data, preferences) => {
  const patchPreferences = {
    ...preferences,
    like: data.contactPreferences?.likes?.map((item) => ({
      ...item,
      link: {
        url: item?.linkType === "Website" ? item?.link : undefined,
        reference: item?.linkType === "SOP" ? item?.link : undefined,
      },
      display: item?.label,
    })),
    dislike: data.contactPreferences?.dislikes?.map((item) => ({
      ...item,
      link: {
        url: item?.linkType === "Website" ? item?.link : undefined,
        reference: item?.linkType === "SOP" ? item?.link : undefined,
      },
      display: item?.label,
    })),
    special: data.contactPreferences?.specials?.map((item) => ({
      ...item,
      link: {
        url: item?.linkType === "Website" ? item?.link : undefined,
        reference: item?.linkType === "SOP" ? item?.link : undefined,
      },
      display: item?.label,
    })),
  };

  return patchPreferences;
};

const isContactInfoSpecified = (contactInfo) => {
  let hasInfo = false;
  hasInfo = contactInfo?.phoneNumbers?.some(
    (phoneNumber) => phoneNumber?.value && phoneNumber.value !== ""
  );

  hasInfo =
    hasInfo ||
    contactInfo?.emails?.some((email) => email?.value && email.value !== "");

  hasInfo =
    hasInfo ||
    contactInfo?.addresses?.some(
      (address) => address?.value && address.value !== ""
    );

  hasInfo =
    hasInfo ||
    contactInfo?.websites?.some(
      (website) => website?.value && website.value !== ""
    );

  hasInfo =
    hasInfo ||
    contactInfo?.dates?.some((date) => date?.value && date.value !== "");

  hasInfo =
    hasInfo ||
    contactInfo?.socials?.some(
      (social) => social?.value && social.value !== ""
    );

  hasInfo = hasInfo || (contactInfo?.notes && contactInfo.notes !== "");
  return hasInfo;
};
const getContactDisplayType = (kind) => {
  switch (kind) {
    case "company":
      return "company";
    case "nonmember":
      return "contact";
    case "member":
      return "member";
    default:
      return "user";
  }
};

const getUsedAndUnusedPreferences = (
  contactPreferencesOptions,
  contactPreferences,
  preferenceType
) => {
  const unusedCustomPreferences = [];
  const favoritesWithValues = contactPreferencesOptions[preferenceType]?.map(
    (opt) => {
      const found = contactPreferences[preferenceType]?.find(
        (preference) => preference.id === opt.id
      );

      if (found?.value) {
        opt.value = found?.value;
        opt.link = found?.link;
        opt.linkType = found?.linkType;
      } else if (opt.custom) {
        unusedCustomPreferences.push(opt);
      }

      return opt;
    }
  );
  // find preferences with id that do not exist in options
  const userSpecificPreferences = _.differenceBy(
    contactPreferences[preferenceType],
    contactPreferencesOptions[preferenceType],
    "id"
  );

  const usedPreferences = _.differenceBy(
    favoritesWithValues,
    unusedCustomPreferences,
    "id"
  );

  return {
    usedPreferences: [...usedPreferences, ...userSpecificPreferences],
    unusedCustomPreferences,
  };
};

const assignCompanyToContact = async (
  assineeId,
  companyReference,
  lastUpdated
) => {
  const patchBody = {
    company: {
      valueType: "reference",
      value: companyReference,
    },
  };

  // eslint-disable-next-line no-await-in-loop
  await UserAPI.patch(assineeId, patchBody, {}, { date: lastUpdated });
};

const disassociateCompanyFromContact = async (
  assineeId,
  lastUpdated,
  dispatch
) => {
  const patchBody = {
    company: {},
    companyName: "",
  };
  // eslint-disable-next-line no-await-in-loop
  const { data: res } = await UserAPI.patch(
    assineeId,
    patchBody,
    {},
    { date: lastUpdated }
  );
  dispatch({
    type: UPDATE_USERS,
    user: res,
  });
};

/**
 * @param {String} currentStatus RSVP_STATUS.accept || maybe || decline
 * @returns Icon to be showed above the avatar image
 */
const renderStatusImage = (currentStatus) => {
  switch (currentStatus) {
    case RSVP_STATUSES.accept:
      return acceptedIcon;
    case RSVP_STATUSES.maybe:
      return maybeIcon;
    case RSVP_STATUSES.decline:
      return declineIcon;
    default:
      return "";
  }
};

export {
  assignCompanyToContact,
  disassociateCompanyFromContact,
  formatUserPayload,
  formatPayloadUser,
  formatUserPreference,
  formatPreferenceUser,
  UserContactData,
  hasValidGenderValue,
  formatCurrentUserEditPayload,
  getInitials,
  getVCard,
  formatContactForExport,
  getFilteredAndReducedContactsByFirstLetter,
  validateContactsImportedCsvFile,
  getUserPostpayloadFromCsvImportedContact,
  getCompanyResourceForCsvImportedContact,
  handleUserImport,
  handleCompanyImport,
  getInviteMemberPayload,
  formatNonMemberToContact,
  formatUserPreferences,
  createUserPatchPayload,
  createPatchPreferencePayload,
  getFilteredContacts,
  isContactInfoSpecified,
  getCompanyInitials,
  getContactDisplayType,
  getUsedAndUnusedPreferences,
  createCompanyContact,
  inviteContact,
  renderStatusImage,
};
