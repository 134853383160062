/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { FILTER_TYPES, GET_SOP_DETAILS_PATH } from "../../../constants";
import DateCell from "../Table/DateCell";
import Table from "../Table/Table";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import TagList from "../Tags/TagList";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Category",
      value: "categoryFilter",
    },
    {
      label: "Tags",
      value: "tags",
    },
  ],
};

const SopTable = ({
  data,
  handleRowSelect,
  hideSiteHeaderTitle,
  allowSelection,
  fileActionsIcons,
  showColumnSettingsLeft,
}) => {
  const history = useHistory();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const [templateSettings, setTemplateSettings] = useState([]);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.sop
          ?.map((template) => ({
            ...template,
            isAdmin: !template.custom,
          }))
          ?.filter((template) => template.name !== "last_updated_column_view")
      );
    }
  }, [templatesConfiguration]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "sop",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return { ...view, custom: true };
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "sop",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const sopColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "categoryFilter",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      /*
       * uncomment when SOP isActive prop is enabled in 3.6
       */

      // {
      //   Header: "Status",
      //   accessor: "isActive",
      //   Cell: ({ value }) => `${value ? "Active" : "Inactive"}`,
      //   filterOptions: {
      //     filterType: FILTER_TYPES.boolean,
      //     equals: true,
      //     label: "Is Active?",
      //   },
      // },
      {
        Header: "Date Created",
        accessor: "metadata.createdAt",
        Cell: ({ value }) => <DateCell value={value} format="MMMM Do YYYY" />,
        filterOptions: {
          label: "Date Created",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: "metadata.lastUpdated",
        Cell: ({ value }) => <DateCell value={value} />,
        filterOptions: {
          label: "Date Modified",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
    ],
    []
  );

  const onSopRowClick = useCallback(
    (id) => {
      history.push(GET_SOP_DETAILS_PATH(id));
    },
    [history]
  );
  return (
    <>
      <Table
        onSelectedRowChange={handleRowSelect}
        allowSelection={allowSelection}
        fileActionsIcons={fileActionsIcons}
        showColumnSettingsLeft={showColumnSettingsLeft}
        columns={sopColumns}
        data={data}
        resourceName="sop"
        onRowClick={({ index }) => onSopRowClick(data[index]?.id)}
        groupOptions={groupOptions}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showConstantRowIndex
        hideSiteHeaderTitle={hideSiteHeaderTitle}
      />
    </>
  );
};

SopTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

SopTable.defaultProps = {
  data: undefined,
};

export default SopTable;
