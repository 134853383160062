import { isEqual } from "lodash";
import React from "react";
import * as yup from "yup";
import { array } from "yup";
import requiredIcon from "../stories/assets/images/requiredIcon.svg";
import { phoneRegExp, yupEmailRegExp } from "./Formatters";

// Encapsulates only the data required for continuing/submitting a form

export const signupContactInformationSchema = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  title: yup.string(),
  company: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const validUrlSchema = yup.string().required();

export const contactFormSchema = (emails, contactPoints, contactType) =>
  yup.lazy(() => {
    if (contactType !== "company") {
      return yup
        .object()
        .shape({
          name: yup.object().shape({
            firstName: yup.string().min(3, "Must be 3 characters").required(),
          }),
        })
        .when("invite", (invite, contactPoint) => {
          /**
           * First Condition:
           * - Invited
           * - Email Required
           */
          const email = contactPoints?.find((item) => item?.system === "Email");
          if (invite && !email) {
            return contactPoint.test({
              name: "email-check",
              message: "Email Required",
              test: () => false,
            });
          }

          /**
           * Second Condition:
           * - Contact Points > 0
           * - Regex Check Each Value
           */
          if (contactPoints?.length > 0) {
            return contactPoint.test({
              name: "contactpoint-test",
              test: (val) =>
                val.every((point) => {
                  // Phone Case
                  if (point?.system === "Phone") {
                    if (
                      point?.system &&
                      point?.use &&
                      phoneRegExp.test(point?.value)
                    )
                      return true;
                    return false;
                  }
                  // Email Case
                  if (point?.system === "Email") {
                    if (point?.value !== "" && emails.includes(point?.value)) {
                      return false;
                    }
                    return yupEmailRegExp.test(point?.value);
                  }

                  return true;
                }),
            });
          }
          return contactPoint;
        });
    }
    return yup.object().shape({
      companyName: yup.string().min(3, "Must be 3 characters").required(),
      address: yup.array().of(
        yup.object().shape({
          city: yup.string(),
          country: yup.string(),
          state: yup.string(),
          zipCode: yup.string(),
          street: yup.string(),
        })
      ),
      contactPoint: yup
        .array()
        .of(
          yup.object().shape({
            system: yup.string(),
            use: yup.string(),
            value: yup.string(),
          })
        )
        .test({
          name: "email-check",
          message: "Invalid Entry",
          test: (val) =>
            val.every((point) => {
              // Phone Case
              if (point?.system === "Phone") {
                if (
                  point?.system &&
                  point?.use &&
                  phoneRegExp.test(point?.value)
                )
                  return true;
                return false;
              }
              // Email Case
              if (point?.system === "Email") {
                if (point?.value !== "" && emails.includes(point?.value)) {
                  return false;
                }
                return yupEmailRegExp.test(point?.value);
              }

              return true;
            }),
        }),
    });
  });

export const contactDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const companyContactDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
});

export const contactInfoSchema = yup.object().shape({
  emails: array()
    .of(
      yup.object().shape({
        type: yup.string(),
        value: yup.string().email().required(),
      })
    )
    .min(1),
  phoneNumbers: array().of(
    yup.object().shape({
      type: yup.string(),
      value: yup.string().required().matches(phoneRegExp),
    })
  ),
});

export const assetInfoSchema = () =>
  yup.object().shape({
    name: yup.string().trim().required(),
    category: yup.string().required(),
    subcategory: yup.string().required(),
    property: yup.lazy(() =>
      yup.string().when("project", {
        is: undefined,
        then: yup.string().required(),
      })
    ),
    project: yup.lazy(() =>
      yup.string().when("property", {
        is: undefined,
        then: yup.string().required(),
      })
    ),
    links: yup.array().of(
      yup.object().shape(
        {
          url: yup.string().when("name", {
            is: (name) => !name || name.length === 0,
            then: yup.string().required(),
            otherwise: yup.string().required(),
          }),
        },
        ["name", "url"]
      )
    ),
  });

export const calendarEventSchema = yup.object().shape({
  name: yup.string().required(),
  association: yup.string().required(),
});

export const completeTaskSchema = yup.object().shape({
  duration: yup.object().shape({
    value: yup.number().required(),
    typeOfDuration: yup.string().required(),
  }),
});
export const propertyInfoSchema = yup.object().shape({
  title: yup.string().trim().required(),
  propertyType: yup.string().required(),
  timezone: yup.string().required(),
});

export const workflowInfoSchema = yup.string().required();

export const assetMaintenanceScheduleSchema = yup.object().shape({
  name: yup.string().required(),
  startDate: yup.string().required(),
  dueTime: yup.string().required(),
  recurrence: yup.string().required(),
});
export const maintenanceScheduleCompleteSchema = yup.object().shape({
  durationCode: yup.mixed().required(),
  durationValue: yup.number().required(),
});

export const projectBudgetInfoSchema = yup.object().shape({
  budgetType: yup.string().required(),
});

export const budgetLineitemInfoSchema = (isRequiredDisabled) =>
  yup.object().shape({
    costPerUnit: isRequiredDisabled ? yup.string() : yup.string().required(),
    financialCode: yup.object().shape({
      division: isRequiredDisabled ? yup.string() : yup.string().required(),
      code: isRequiredDisabled ? yup.string() : yup.string().required(),
      subcode: isRequiredDisabled ? yup.string() : yup.string().required(),
    }),
    quantity: isRequiredDisabled ? yup.string() : yup.string().required(),
    unitOfMeasure: isRequiredDisabled ? yup.string() : yup.string().required(),
  });

export const sopInfoSchema = yup.object().shape({
  category: yup.string().required(),
  name: yup.string().required(),
});

export const holidayModalSchema = (isAdding) =>
  yup.object().shape({
    display: yup.string().required(),
    date: yup.string().when("dateOption", {
      is: (dateOption) =>
        dateOption?.value === "specificDate" ||
        (!dateOption?.value && isAdding),
      then: yup.string().required(),
    }),
    recurrence: yup.object().when("dateOption", {
      is: (dateOption) => {
        return (
          dateOption?.value === "customRecurrence" ||
          (!dateOption?.value && isAdding)
        );
      },
      then: yup.object().shape({
        calendarDay: yup.number().required(),
        dayOfWeek: yup.string().required(),
        month: yup.string().required(),
      }),
    }),
  });

export const ptoSchema = yup.object().shape({
  location: yup.string().required(),
});

export const selectSectionSchema = ({ selectionItemValue }) =>
  yup.object().shape({
    [selectionItemValue]: yup.string().required(),
  });

export const ptoTypeSchema = yup.object().shape({
  numHours: yup.number().when(["selected", "type"], {
    is: (selected, type) => selected === true && type !== "unlimited",
    then: yup
      .number()
      .min(1, "Minimum atleast 1")
      .max(240, `Allowed maximum is 240`)
      .required(),
  }),
  maxRollover: yup.number().when(["selected", "accrualType", "type"], {
    is: (selected, accrualType, type) =>
      selected === true &&
      accrualType !== "non-accrual" &&
      type !== "unlimited",
    then: yup
      .number()
      .min(1, `Minimum at least 1`)
      .max(240, `Allowed maximum is 240`)
      .required(),
  }),
  availability: yup.object().when("selected", {
    is: (val) => val === true,
    then: yup.object().shape({
      date: yup.date().required(),
      waitPeriod: yup
        .number()
        .min(1, "Minimum atleast 1")
        .max(90, `Allowed maximum is 90`),
    }),
  }),
});

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const timesheetSchema = yup
  .array(
    yup
      .string()
      .matches(
        /^[a-zA-Z]+\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\|[0-9]{2}-[0-9]{2}-[0-9]{2}\|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        { message: "Missing value" }
      )
  )
  .test(
    "Unique",
    "Project, Category, & Rate combination must be unique",
    (values) => {
      return new Set(values).size === values.length;
    }
  );

export const timesheetEntryValidation = (entry) =>
  yup.lazy(() => {
    return yup.string().test({
      name: "entry-check",
      message: "Rejected",
      test: () => {
        if (entry?.status === "rejected") {
          return false;
        }
        return true;
      },
    });
  });

export const spaceFormSchema = yup.object().shape({
  name: yup.string().trim().required(),
});

export const strictString = yup
  .string("Must Enter String")
  .nullable()
  .required(
    <div className="flex gap-1">
      <img className="inline-block" alt="required-icon" src={requiredIcon} />
      <span>Required</span>
    </div>
  );

export const dynamicPhoneNumberCheck = (required) =>
  yup.lazy((value) => {
    if (required)
      return yup
        .string()
        .required(
          <div className="flex gap-1">
            <img
              className="inline-block"
              alt="required-icon"
              src={requiredIcon}
            />
            <span>Required</span>
          </div>
        )
        .matches(phoneRegExp, "Enter 10 Digit Number");

    if (value) {
      return yup
        .string()
        .matches(phoneRegExp, "Enter 10 Digit Number")
        .min(13, "Enter 10 Digit Number");
    }
    return yup.string();
  });
export const strictEmailCheck = (emails, required) =>
  yup.lazy(() => {
    if (required) {
      return yup
        .string()
        .required(
          <div className="flex gap-1">
            <img
              className="inline-block"
              alt="required-icon"
              src={requiredIcon}
            />
            <span>Required</span>
          </div>
        )
        .email("Must Enter Valid Email")
        .test({
          name: "email-check",
          message: "Email Exists",
          test: (val) => {
            if (emails.includes(val) && val !== "") {
              return false;
            }
            return true;
          },
        });
    }
    return yup
      .string()
      .nullable(true)
      .email("Must Enter Valid Email")
      .test({
        name: "email-check",
        message: "Email Exists",
        test: (val) => {
          if (emails.includes(val) && val !== "") {
            return false;
          }
          return true;
        },
      });
  });

export const strictEmailDropdownCheck = (emails) =>
  yup
    .mixed()
    .required(
      <div className="flex gap-1">
        <img className="inline-block" alt="required-icon" src={requiredIcon} />
        <span>Required</span>
      </div>
    )
    .test({
      name: "email-check",
      message: "Email Exists",
      test: (val) => {
        if (emails.includes(val?.value) && val?.value !== "") {
          return false;
        }
        return true;
      },
    });

export const magnoliaUrl = yup.string("Must Enter Url").url(
  <div className="flex gap-1">
    <img className="inline-block" alt="required-icon" src={requiredIcon} />
    <span>https://example.com</span>
  </div>
);

export const simpleUrl = yup.string().trim().required();

export const existingTimeoffRequestValidation = (val) =>
  yup.lazy(() => {
    return yup
      .mixed()
      .required(
        <div className="flex gap-1">
          <img
            className="inline-block"
            alt="required-icon"
            src={requiredIcon}
          />
          <span>Required</span>
        </div>
      )
      .test({
        name: "timeoff-request-check",
        message: "Existing request for days entered of this type.",
        test: () => {
          if (val) {
            return false;
          }
          return true;
        },
      });
  });

/**
 * Payroll Settings
 */
export const timesheetSettingsValidation = (locked) =>
  yup.lazy(() => {
    return yup
      .mixed()
      .required(
        <div className="flex gap-1 pb-1">
          <img
            className="inline-block"
            alt="required-icon"
            src={requiredIcon}
          />
        </div>
      )
      .test({
        name: "timeoff-request-check",
        message: "Must set before timesheets are accessible.",
        test: () => {
          if (!locked) {
            return false;
          }
          return true;
        },
      });
  });

/**
 * Payroll Settings
 */

// show the difference 2 objects. it only shows the key
export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        // eslint-disable-next-line no-param-reassign
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};
