import { useMemo, useRef, useState } from "react";

const useLinksRow = ({ handleAddLink, handleRemoveLink }) => {
  const [addLinksIsOpen, setAddLinksIsOpen] = useState(false);
  const [linkName, setLinkName] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const inputRef = useRef({});

  const validUrl = useMemo(() => {
    return !!linkUrl?.length;
  }, [linkUrl]);

  const addLink = () => {
    handleAddLink({ linkName, linkUrl });
  };

  const handleDeleteClick = (link) => {
    handleRemoveLink(link);
  };

  const clearFields = () => {
    setLinkName();
    setLinkUrl();
  };

  const confirmEdit = () => {
    if (validUrl) {
      addLink();
      clearFields();
      inputRef.current?.title?.focus();
    }
  };

  const handleChange = (e) => {
    if (e.key === "Enter") {
      confirmEdit();
    }
  };
  return {
    addLinksIsOpen,
    setAddLinksIsOpen,
    handleDeleteClick,
    confirmEdit,
    handleChange,
    inputRef,
    setLinkUrl,
    linkName,
    linkUrl,
    setLinkName,
  };
};

export default useLinksRow;
