import React, { useCallback, useEffect, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import RemoveIcon from "../RemoveIcon/RemoveIcon";
import BaseButton from "../Buttons/BaseButton";
import { getStatesDropdown } from "../../../helpers/Address";
import Dropdown from "../Dropdown/Dropdown";

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-2
`;

const TaxesRatesSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
}) => {
  const [dropdownStates, setDropdownStates] = useState([]);

  useEffect(() => {
    const states = getStatesDropdown();
    setDropdownStates(states);
  }, []);

  const onFicaChange = useCallback(
    (value, field) => {
      dispatchFinancialsSettings({
        type: "changeFica",
        field,
        value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onFutaChange = useCallback(
    (value, field) => {
      dispatchFinancialsSettings({
        type: "changeFuta",
        field,
        value,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onSutaChange = useCallback(
    (index, field, value) => {
      dispatchFinancialsSettings({
        type: "changeSuta",
        field,
        value,
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onAdd = useCallback(() => {
    dispatchFinancialsSettings({
      type: "addSuta",
    });
  }, [dispatchFinancialsSettings]);

  const onRemoveSuta = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "removeSuta",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  return (
    <div className="flex flex-row">
      <div className="flex flex-col w-1/3 border-r border-r-gray-200 px-4">
        <div>
          <h2 className="text-gray-300 font-bold">FICA</h2>
          <div className="flex flex-row pt-2">
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Soc. Security"
                label="Soc Security"
                value={financialsSettings?.taxRates?.fica?.socialSecurity}
                onChange={(val) => onFicaChange(val, "socialSecurity")}
                isPercentageValue
                disableClear
              />
            </div>
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Medicare"
                label="Medicare"
                value={financialsSettings?.taxRates?.fica?.medicare}
                onChange={(val) => onFicaChange(val, "medicare")}
                isPercentageValue
                disableClear
              />
            </div>
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Total FICA"
                label="Total FICA"
                value={
                  Math.round(
                    (parseFloat(
                      financialsSettings?.taxRates?.fica?.socialSecurity
                    ) +
                      parseFloat(
                        financialsSettings?.taxRates?.fica?.medicare
                      )) *
                      100
                  ) / 100
                }
                isPercentageValue
                disableClear
                disabled
                inputContainerClassName="border-4 rounded-none"
              />
            </div>
          </div>
        </div>
        <div className="py-8">
          <h2 className="text-gray-300 font-bold">FUTA</h2>
          <div className="flex flex-row pt-2">
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Cost"
                label="Cost"
                value={financialsSettings?.taxRates?.futa?.cost}
                onChange={(val) => onFutaChange(val, "cost")}
                isPercentageValue
                disableClear
              />
            </div>
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Credit"
                label="Credit"
                value={financialsSettings?.taxRates?.futa?.credit}
                onChange={(val) => onFutaChange(val, "credit")}
                isPercentageValue
                disableClear
              />
            </div>
            <div className="pr-6 w-1/3">
              <Input
                placeholder="Total FUTA"
                label="Total FUTA"
                value={
                  Math.round(
                    (parseFloat(financialsSettings?.taxRates?.futa?.cost) -
                      parseFloat(financialsSettings?.taxRates?.futa?.credit)) *
                      100
                  ) / 100
                }
                isPercentageValue
                disableClear
                disabled
                inputContainerClassName="border-4 rounded-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-2/3">
        <div className="p-4">
          <h2 className="text-gray-300 font-bold">SUTA</h2>
        </div>
        {(!!financialsSettings?.taxRates?.suta?.length && (
          <div className="flex flex-row w-full">
            <div className="py-2" style={{ width: "38%" }}>
              <div className="flex w-full px-4">
                <h3 className="font-semibold text-black">State</h3>
              </div>
            </div>
            <div className="py-2" style={{ width: "12%" }}>
              <div className="flex w-full px-4">
                <h3 className="font-semibold text-black">Cost</h3>
              </div>
            </div>
            <div className="py-2" style={{ width: "23%" }}>
              <div className="flex w-full px-4">
                <h3 className="font-semibold text-black">Per Employee</h3>
              </div>
            </div>
            <div className="py-2" style={{ width: "23%" }}>
              <div className="flex w-full px-4">
                <h3 className="font-semibold text-black">Total SUTA</h3>
              </div>
            </div>
            <div className="py-2" style={{ width: "4%" }}>
              <div className="flex w-full px-4" />
            </div>
          </div>
        )) || (
          <p className="text-gray-200 text-sm px-4 mb-4">
            Add SUTA based on the state(s) that you have employees in.
          </p>
        )}
        {financialsSettings?.taxRates?.suta?.map((rate, index) => {
          return (
            <div
              className="flex flex-row w-full"
              key={`${rate?.id}-${rate?.cost}-${rate?.perEmployee}-${rate?.state}-${rate?.total}`}
            >
              <div className="py-2" style={{ width: "38%" }}>
                <div className="flex w-full px-4">
                  <Dropdown
                    placeholder="Select"
                    options={dropdownStates.filter(
                      (state) =>
                        financialsSettings?.taxRates?.suta?.find(
                          (r) => r.state === state.value
                        ) === undefined
                    )}
                    value={dropdownStates.find(
                      (opt) => opt.value === rate?.state
                    )}
                    onChange={(val) => onSutaChange(index, "state", val.value)}
                  />
                </div>
              </div>
              <div className="py-2" style={{ width: "12%" }}>
                <div className="flex w-full px-4">
                  <Input
                    placeholder="Cost"
                    isPercentageValue
                    className="text-gray-300"
                    mainClassName="w-full"
                    value={rate?.cost}
                    onChange={(val) => onSutaChange(index, "cost", val)}
                    disableClear
                  />
                </div>
              </div>
              <div className="py-2" style={{ width: "23%" }}>
                <div className="flex w-full px-4">
                  <Input
                    placeholder="Value"
                    isDollarValue
                    className="text-gray-300"
                    mainClassName="w-full"
                    value={rate?.perEmployee}
                    onChange={(val) => onSutaChange(index, "perEmployee", val)}
                    disableClear
                  />
                </div>
              </div>
              <div className="py-2" style={{ width: "23%" }}>
                <div className="flex w-full px-4">
                  <Input
                    placeholder="Value"
                    isDollarValue
                    className="text-gray-300"
                    mainClassName="w-full"
                    value={parseFloat((rate?.cost * rate?.perEmployee) / 100)}
                    disableClear
                    disabled
                    inputContainerClassName="border-4 rounded-none"
                  />
                </div>
              </div>
              <div className="py-2" style={{ width: "4%" }}>
                <div className="flex w-full pt-2">
                  <RemoveIcon
                    color="#1DC29D"
                    onClick={() => onRemoveSuta(index)}
                    width="2rem"
                    height="2rem"
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <BaseButton
            style={{ minWidth: 120 }}
            className={tertiaryButtonCN}
            title="+ Add SUTA"
            onClick={onAdd}
          />
        </div>
      </div>
    </div>
  );
};

TaxesRatesSection.propTypes = {
  financialsSettings: PropTypes.shape({
    taxRates: PropTypes.shape({
      fica: PropTypes.shape({
        socialSecurity: PropTypes.string,
        medicare: PropTypes.string,
        total: PropTypes.string,
      }),
      futa: PropTypes.shape({
        cost: PropTypes.string,
        credit: PropTypes.string,
        total: PropTypes.string,
      }),
      suta: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          cost: PropTypes.string,
          perEmployee: PropTypes.string,
          total: PropTypes.string,
        })
      ),
    }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
};

TaxesRatesSection.defaultProps = {
  financialsSettings: undefined,
  dispatchFinancialsSettings: undefined,
};

export default TaxesRatesSection;
