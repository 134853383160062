import moment from "moment";
import isBegOfDay from "../Date/isBegOfDay";
import isEndOfDay from "../Date/isEndOfDay";
/**
 * Calculates the number of days in a span based on the given parameters.
 *
 * @param {Object} options - Options object containing parameters.
 * @param {boolean} options.isMultiWeek - A flag indicating whether the span covers multiple weeks.
 * @param {Object} options.item - An object representing the span, which should contain 'startDate' and 'endDate' properties.
 * @returns {number} The number of days in the span.
 */

// Define a function to calculate the number of days in a span
const getDaySpanCount = ({ isMultiWeek, item }) => {
  // Destructure 'startDate' from the 'item' object
  const { startDate, endDate } = item;
  // Check if the span covers multiple weeks
  if (isMultiWeek) {
    // Calculate the difference in days between the start date and the end of that week
    const difference = moment(startDate).diff(
      moment(startDate).endOf("week"),
      "days"
    );
    // Return the absolute value of the difference plus one to include the start day
    return Math.abs(difference) + 1;
  }

  // day span will not be defined if the event is coming from the backend

  const isBeginning = isBegOfDay(startDate);
  const isEnding = isEndOfDay(endDate);

  if (!item?.daySpan && isBeginning && isEnding) {
    // check to see if the item is an all day event.
    const difference = moment(endDate).diff(moment(startDate), "days");
    return Math.abs(difference) + 1;
  }

  return item.daySpan;
};

export default getDaySpanCount;
