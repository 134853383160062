import { useCallback } from "react";
import moment from "moment";
import { useAppState } from "../state/appState";
import { getFullName } from "../helpers/Formatters";
import useServiceRequests from "./useServiceRequests";
import { SERVICE_REQUEST_STATUS } from "../constants";

/**
 * Query selector to return Tickets data in the desire format table
 * @param {string} association (?) association reference
 * @returns tickets in custom format
 */
const useServiceRequestTableFormat = ({ association, asset } = {}) => {
  const [{ userDict, propertiesDict, projectDict, assetsDict }] = useAppState();

  const select = useCallback(
    (tickets) =>
      tickets.map((ticket) => {
        const user = userDict?.[ticket.createdBy];

        const associationSelected =
          assetsDict?.[ticket?.asset] ||
          propertiesDict?.[ticket.association] ||
          projectDict?.[ticket.association];

        return {
          ...ticket,
          createdAt: moment(ticket.metadata.createdAt).format("MMMM DD, YYYY"),
          createdBy: getFullName(user?.name),
          associationName:
            associationSelected?.name ?? associationSelected?.title,
          status: SERVICE_REQUEST_STATUS[ticket.status],
        };
      }),
    [userDict, projectDict, propertiesDict, assetsDict]
  );

  return useServiceRequests({ association, asset, select });
};

export default useServiceRequestTableFormat;
