import PropTypes from "prop-types";
import React from "react";
import { getFullName } from "../../helpers/Formatters";
import MemberAvatarListWithViewAll from "../../stories/Components/Avatar/MemberAvatarListWithViewAll";
import Avatar from "../../stories/Components/Message/Avatar";
import { renderStatusImage } from "../../helpers/User";

/**
 * Renders the horizontal list of Member Avatars for the Roles & Permissions
 * screens. If more than five members are assigned the role, only render five
 * avatars, and show an overflow and a View All option.
 *
 * TODO: ES-2947 Allow member images avatars in Roles & Permissions table
 *
 * @param members An array of member initials.
 * @param className Optional additional className values
 */
function RoleMembersAvatarList({
  members,
  className,
  viewAllClassName,
  viewAllButton,
  tooltipClassName,
  showRightTooltip,
  viewAllAlways,
  showExtraCount,
  membersToShowCount,
  buttonLeft,
  showRsvpStatus,
  rsvpData,
  avatarContainerClassname,
}) {
  return (
    <div className={`flex flex-row space-x-1 ${className}`}>
      {members?.slice(0, membersToShowCount).map((member) => (
        <div
          key={member?.id}
          className={`flex relative flex-row-reverse ${
            avatarContainerClassname ?? "w-8 h-8"
          }`}
        >
          {showRsvpStatus &&
            typeof rsvpData?.[member?.reference] === "string" && (
              <img
                alt="status"
                src={renderStatusImage(rsvpData?.[member?.reference])}
                className="absolute h-fit bottom-5"
                style={{ zIndex: 1 }}
                width="15px"
              />
            )}
          <Avatar
            className="text-xs w-full h-full"
            name={getFullName(member?.name)}
            isOnline={member?.isOnline}
            isExternalUser
            id={member?.id}
            avatar={member?.avatar}
            kind={member?.kind}
            tooltipClassName={tooltipClassName}
            showRightTooltip={showRightTooltip}
          />
        </div>
      ))}
      {showExtraCount && members?.length > membersToShowCount && (
        <div className="flex justify-center items-center bg-backgroundGrey rounded-3xl py-1 px-1.5">
          <p className="flex justify-center text-primaryGreen text-xs font-normal w-5">
            {`+${members?.length - membersToShowCount}`}
          </p>
        </div>
      )}
      {!showExtraCount &&
        ((!!members?.length && viewAllAlways) ||
          members?.length > membersToShowCount) && (
          <MemberAvatarListWithViewAll
            members={members}
            viewAllClassName={viewAllClassName}
            viewAllAlways={viewAllAlways}
            viewAllButton={viewAllButton}
            buttonLeft={buttonLeft}
            showRsvpStatus={showRsvpStatus}
            rsvpData={rsvpData}
          />
        )}
    </div>
  );
}

RoleMembersAvatarList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  viewAllButton: PropTypes.element,
  viewAllClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  showRightTooltip: PropTypes.bool,
  viewAllAlways: PropTypes.bool,
  showExtraCount: PropTypes.bool,
  membersToShowCount: PropTypes.number,
  buttonLeft: PropTypes.bool,
  showRsvpStatus: PropTypes.bool,
  rsvpData: PropTypes.shape({}),
  /* This is for adding custom height to avatar container */
  avatarContainerClassname: PropTypes.string,
};

RoleMembersAvatarList.defaultProps = {
  members: [],
  className: "",
  viewAllButton: undefined,
  viewAllClassName: undefined,
  tooltipClassName: undefined,
  showRightTooltip: false,
  viewAllAlways: false,
  showExtraCount: false,
  membersToShowCount: 5,
  buttonLeft: undefined,
  showRsvpStatus: undefined,
  rsvpData: undefined,
  avatarContainerClassname: undefined,
};

export default RoleMembersAvatarList;
