import Api from "@griffingroupglobal/eslib-api";

import useAuthenticatedQuery from "../useAuthenticatedQuery";
import { rsvpKeys } from "../../config/reactQuery/queryKeyFactory";

const getRsvp = async (association, instanceStartDate) => {
  const params = { association };
  if (instanceStartDate) params.instanceStartDate = instanceStartDate;
  const { data } = await Api.get("/api/Rsvp/", {
    params,
  });

  return data;
};

const useRsvp = (ref, instanceStartDate) =>
  useAuthenticatedQuery(
    rsvpKeys.byRefAndInstanceStartDate(ref, instanceStartDate),
    () => getRsvp(ref, instanceStartDate),
    {
      enabled: !!ref,
      onError: () => {
        console.error(`Unable to load rsvp for ${ref}`);
      },
    }
  );

export default useRsvp;
