import { useCallback } from "react";
import { TaskAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import { toastError, toastMessage } from "../../../Toast/Toast";
import { taskKeys } from "../../../../../config/reactQuery/queryKeyFactory";
import { useAppState } from "../../../../../state/appState";
import getUpdatedAllTaskSteps from "../../../../../helpers/Utilities/getUpdatedAllTaskSteps";
import editSingleItem from "../../../../../helpers/Task/editSingleTask";

const useSopStepStatusData = (sopsForTask, currentTask, setCurrentTask) => {
  const [
    { userEvents, tasks, tasksDict, unformattedEvents },
    appStateDispatch,
  ] = useAppState();
  const queryClient = useQueryClient();
  const isAllChecked = sopsForTask.every((step) => step?.stepData?.isCompleted);

  const handleCompleteStepCheck = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          // patch no-recurrence task
          const taskPatchResponse = await TaskAPI.patch(
            currentTask?.id,
            updatedTask,
            currentTask
          );
          setCurrentTask({
            ...taskPatchResponse.data,
          });
          toastMessage("Task updated successfully");
        } else {
          // patch single instance of recurring task
          const updatedTaskItem = await editSingleItem({
            currentTask,
            updatedTask,
            userEvents,
            tasks,
            tasksDict,
            appStateDispatch,
            unformattedEvents,
          });
          setCurrentTask({
            ...updatedTaskItem,
          });
        }

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        console.error(error);
        toastError("This task could not be updated");
      }
    },
    [
      appStateDispatch,
      currentTask,
      queryClient,
      setCurrentTask,
      tasks,
      tasksDict,
      userEvents,
      unformattedEvents,
    ]
  );

  const handleCompleteCheck = useCallback(
    (newIsAllChecked) => {
      const newCompletionStatus = newIsAllChecked;
      const updatedTaskSteps = getUpdatedAllTaskSteps(
        currentTask.steps,
        sopsForTask,
        newCompletionStatus
      );

      const updatedTask = { ...currentTask, steps: updatedTaskSteps };

      handleCompleteStepCheck(updatedTask);
      return newCompletionStatus;
    },
    [currentTask, handleCompleteStepCheck, sopsForTask]
  );

  return {
    isAllChecked,
    handleCompleteCheck,
  };
};

export default useSopStepStatusData;
