import moment from "moment";
import momentTz from "moment-timezone";

const { getName } = require("country-list");
/**
 * @returns - time as percentage of day
 */
const timeToPercentage = (instant) => {
  const now = moment(instant);

  const start = now.clone().startOf("day");
  const difference = now.diff(start, "minutes");
  return difference / 1440;
};

const getMinTime = (date) => {
  const today = moment().format("YYYY-MM-DD");
  if (date) {
    const inputDate = date.split("T");
    if (inputDate[0] === today) {
      return new Date();
    }
    return new Date().setHours(0, 0, 0);
  }
  return new Date();
};

const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
  const timeValueIsEarlier =
    moment(timeValue, "h:mmA").diff(moment(endLimit, "h:mmA")) < 0;
  const timeValueIsLaterThanLastValue =
    lastValue === undefined
      ? true
      : moment(lastValue, "h:mmA").diff(moment(timeValue, "h:mmA")) < 0;
  return timeValueIsEarlier && timeValueIsLaterThanLastValue;
};

const getTimeOptions = (intervalMinutes) => {
  const step = intervalMinutes || 30;
  let timeValue = "12:00AM";
  let lastValue;
  const endLimit = "11:00PM";

  const options = [];
  options.push({
    label: timeValue,
    value: timeValue,
  });

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, "h:mmA").add(step, "minutes").format("h:mmA");
    options.push({
      label: timeValue,
      value: timeValue,
    });
  }

  return options;
};

const getTimezonesByCountry = () => {
  const ll = momentTz.tz.countries();

  return ll.map((countryCode) => {
    const zones = momentTz.tz.zonesForCountry(countryCode);
    return {
      label: getName(countryCode),
      options: zones,
    };
  });
};

const getTimeZoneOptions = () => {
  return momentTz.tz.names().map((name) => {
    // Get the offset of the current time zone (e.g., +07:00)
    const offset = momentTz.tz(name).format("Z");

    return {
      label: name, // Name of the time zone
      value: offset, // Offset value from GMT
    };
  });
};

const guessTimeZone = () => {
  // Guess the user's current time zone.
  const name = moment.tz.guess();

  const timeZoneObject = {
    label: name,
    value: momentTz.tz(name).format("Z"),
  };

  return timeZoneObject;
};

const getTimeZoneValue = (timezoneName) => {
  return momentTz.tz(timezoneName).format("Z");
};

const typeOfDuration = [
  {
    label: "Days",
    value: "calendarDay",
  },
  {
    label: "Hours",
    value: "hour",
  },
];

/**
 *active xx ago
  logic go up to 60 min, then (1m)
  go from 1 hour to 23 hours, then (1h)
  got from 1 day to 6 days, then (1d)
  go from 1 week to 3 weeks, then (1w)
  go from 1 month to many months (1m)
 */
const getLastActiveDisplay = (lastActiveDate, isOnline) => {
  if (!lastActiveDate) {
    return null;
  }

  if (isOnline) {
    return "Online now";
  }

  const lastActive = moment(lastActiveDate);
  const today = moment();

  const minutesDifference = today.diff(lastActive, "minutes");
  if (minutesDifference < 60) {
    return minutesDifference <= 1
      ? "Active 1 minute ago"
      : `Active ${minutesDifference} minutes ago`;
  }

  const hoursDifference = today.diff(lastActive, "hours");
  if (hoursDifference < 24) {
    return hoursDifference <= 1
      ? `Active 1 hour ago`
      : `Active ${hoursDifference} hours ago`;
  }

  const daysDifference = today.diff(lastActive, "days");
  if (daysDifference < 7) {
    return daysDifference <= 1
      ? `Active 1 day ago`
      : `Active ${daysDifference} days ago`;
  }

  const weeksDifference = today.diff(lastActive, "weeks");
  if (weeksDifference < 4) {
    return weeksDifference <= 1
      ? `Active 1 week ago`
      : `Active ${weeksDifference} weeks ago`;
  }

  const monthsDifference = today.diff(lastActive, "months");
  return monthsDifference <= 1
    ? `Active 1 month ago`
    : `Active ${monthsDifference} months ago`;
};

const getLatestTime = (storedTime, newTime) => {
  if (storedTime) {
    if (new Date(newTime) > new Date(storedTime)) {
      return newTime;
    }
    return storedTime;
  }

  return newTime;
};
/**
 * Expects timeOfDay param in "5:00PM" string format
 * Expects date param in ISO date format
 * Expects timezone param in "(GMT-0500) EST" format
 */
const setHoursInDateFromConfiguration = (date, timeOfDay, timezone) => {
  // Parse the timeOfDay to get hours and minutes.
  const parsedTime = moment(timeOfDay, "h:mmA");

  // Set the hour, minute, second, and millisecond values of the date based on timeOfDay.
  // Adjust to the desired timezone and return as an ISO string.
  return moment(date)
    .tz(timezone?.split(" ")[1]) // Extract timezone ID from the input (e.g., "America/New_York" from "GMT America/New_York").
    .hour(parsedTime.hour())
    .minute(parsedTime.minute())
    .seconds(0)
    .milliseconds(0)
    .toISOString();
};

const getDateTimeSelect = (date, time) => {
  const splitDate = moment(date).format().split("T");
  const splitTime = moment(time).format().split("T");

  return `${splitDate[0]}T${splitTime[1]}`;
};

const taskRecurrenceOptions = [
  { label: "Does not repeat", value: "\nDoes not repeat" },
  {
    label: "Daily",
    value: "\nRRULE:FREQ=DAILY;INTERVAL=1",
  },
  {
    label: "Weekly",
    value: "\nRRULE:FREQ=WEEKLY;INTERVAL=1",
  },
  {
    label: "Monthly",
    value: "\nRRULE:FREQ=MONTHLY;INTERVAL=1",
  },
  {
    label: "Yearly",
    value: "\nRRULE:FREQ=YEARLY;INTERVAL=1",
  },
  {
    label: "Custom Recurrence",
  },
];

const getFullRrule = (rRule) => {
  if (!rRule || rRule === "Custom Recurrence") return undefined;

  if (rRule.includes("DTSTART")) {
    const rRulObject = {
      label: "Custom Recurrence",
      value: rRule,
    };

    return rRulObject;
  }

  let rRVal;
  if (rRule.includes(";UNTIL")) {
    // eslint-disable-next-line prefer-destructuring
    rRVal = rRule.split("\n")[1].split(";UNTIL")[0];
  } else if (rRule.includes(";INTERVAL")) {
    rRVal = rRule?.split("\n")[1];
  } else {
    // eslint-disable-next-line prefer-destructuring
    rRVal = `${rRule.split("\n")[1]};INTERVAL=1`;
  }
  const fullRrule = taskRecurrenceOptions.find(
    (item) => item.value.split("\n")[1] === rRVal
  );

  return fullRrule;
};

// time is in ISO format w/o Z
const getTimeZoneAbbreviation = (time, calendarTimezone = false) => {
  // get timezone object {label: "America/Chicago", value: "-6:00"}
  const timeZone =
    { label: calendarTimezone } || guessTimeZone(new Date(moment(time)));
  // get abbreviated timezone i.e. CST, PST...
  const myTimeZone = moment.tz(timeZone?.label).format("z");
  return myTimeZone;
};

export {
  getDateTimeSelect,
  getFullRrule,
  getLastActiveDisplay,
  getLatestTime,
  getMinTime,
  getTimeOptions,
  getTimeZoneOptions,
  getTimezonesByCountry,
  guessTimeZone,
  getTimeZoneValue,
  setHoursInDateFromConfiguration,
  taskRecurrenceOptions,
  timeToPercentage,
  typeOfDuration,
  getTimeZoneAbbreviation,
};
