/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../Table/Table";
import FlagButton from "../Buttons/FlagButton";
import circleCheckGreenIcon from "../../assets/images/circleCheckGreenIcon.png";
import { FILTER_TYPES, TABLE_COLUMN_WIDTH } from "../../../constants";
import redExclamationIcon from "../../assets/images/redExclamationIcon.svg";
import yellowExclamationIcon from "../../assets/images/yellowExclamationIcon.svg";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Property",
      value: "property",
    },
    {
      label: "Project",
      value: "project",
    },
    {
      label: "Status",
      value: "status",
    },
  ],
};

const displayTitle = (row) => {
  const daysLeft = moment(row?.values?.endDate).diff(
    moment().startOf("day"),
    "days"
  );
  if (daysLeft > 0) {
    return `Due in ${daysLeft} ${daysLeft === 1 ? "day" : "days"}`;
  }
  if (daysLeft === 0) {
    return "Due today";
  }
  return `Due ${Math.abs(daysLeft)} days ago`;
};

const PureWorkflowTable = ({
  tableData,
  handleRowClick,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  handleAddWorkflow,
  sortActiveCardsByOverdue,
  warningCount,
  alertCount,
  warningToggle,
  alertToggle,
  setWarningToggle,
  setAlertToggle,
  bannerComponent,
  tabs,
}) => {
  const tableColumns = useMemo(() => {
    return [
      {
        Header: "Completed",
        accessor: "resolved",
        Cell: ({ row }) =>
          row.values.resolved ? (
            <img
              className="w-4 h-4"
              alt="resolved"
              src={circleCheckGreenIcon}
            />
          ) : null,
        maxWidth: TABLE_COLUMN_WIDTH,
        show: false,
      },
      {
        Header: "Flag",
        accessor: "isFlagged",
        Cell: ({ row }) => <FlagButton isSelected={row.values.isFlagged} />,
        maxWidth: TABLE_COLUMN_WIDTH,
        filterOptions: {
          label: "Flagged",
          equals: true,
          filterType: FILTER_TYPES.boolean,
        },
      },

      {
        Header: "Name",
        accessor: "name",
        width: 280,
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Steps Advanced",
        accessor: "isStepAdvanced",
        Cell: ({ row }) => {
          return <p>{row.original?.progress}</p>;
        },
        width: TABLE_COLUMN_WIDTH,
        filterOptions: {
          label: "Steps advanced",
          equals: true,
          filterType: FILTER_TYPES.boolean,
        },
      },
      {
        Header: "Due Date",
        accessor: "endDate",
        filterOptions: {
          label: "Due Date",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Version #",
        accessor: "version",
        maxWidth: TABLE_COLUMN_WIDTH,
      },
      {
        Header: "Initiation Date",
        accessor: "initiationDate",
        filterOptions: {
          label: "Initiation Date",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Initiator Name",
        accessor: "initiatorName",
        Cell: ({ row }) => (
          <p className="capitalize">{row.values?.initiatorName}</p>
        ),
        show: false,
      },
      {
        Header: "Has Associated Workflows",
        accessor: "hasAssociatedWorkflows",
        show: false,
        width: 200,
      },
      {
        Header: "Warnings/Alerts",
        accessor: "warning",
        Cell: ({ row }) => {
          return row.values.warning ? (
            <div className="flex">
              <img
                src={
                  row.values.warning === "red"
                    ? redExclamationIcon
                    : yellowExclamationIcon
                }
                alt="warning"
              />
              <p className="pl-2">{displayTitle(row)}</p>
            </div>
          ) : null;
        },
        width: TABLE_COLUMN_WIDTH,
        Aggregated: () => null,
      },
      {
        Header: "Property",
        accessor: "property",
        show: false,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
    ];
  }, []);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      showConstantRowIndex
      hideCreateNewButton
      resourceName="workflows"
      onRowClick={handleRowClick}
      showFilterButton
      showChangeView
      groupOptions={groupOptions}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      buttonActions={handleAddWorkflow}
      onAlertWarningButtonClick={sortActiveCardsByOverdue}
      warningCount={warningCount}
      alertCount={alertCount}
      warningToggle={warningToggle}
      alertToggle={alertToggle}
      setWarningToggle={setWarningToggle}
      setAlertToggle={setAlertToggle}
      hideGroupedFooter
      bannerComponent={bannerComponent}
      tabs={tabs}
    />
  );
};

PureWorkflowTable.propTypes = {
  /**
   * data displayed on table
   */
  tableData: PropTypes.shape({
    associatedWorkflows: PropTypes.string,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        details: PropTypes.string,
        task: PropTypes.string,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          })
        ),
      })
    ),
    currentStep: PropTypes.number,
    dueDate: PropTypes.string,
    isFlagged: PropTypes.bool,
    initiationDate: PropTypes.string,
    initiatorName: PropTypes.string,
    resolved: PropTypes.bool,
    status: PropTypes.string,
    steps: PropTypes.string,
    totalStep: PropTypes.number,
    version: PropTypes.string,
    warning: PropTypes.bool,
    workflowName: PropTypes.string,
  }),
  /**
   * data displayed on table
   */
  handleRowClick: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  updateUserTemplateSettings: PropTypes.func,
  deleteUserTemplateSettings: PropTypes.func,
  warningToggle: PropTypes.bool,
  alertToggle: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.node),
};

PureWorkflowTable.defaultProps = {
  tableData: [],
  handleRowClick: undefined,
  templateSettings: [],
  updateUserTemplateSettings: undefined,
  deleteUserTemplateSettings: undefined,
  warningToggle: false,
  alertToggle: false,
  tabs: undefined,
};

export default PureWorkflowTable;
