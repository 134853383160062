import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../../Input/Input";
import EditRemoveActions from "../../EditRemoveActions/EditRemoveActions";
import Checkbox from "../../Checkbox/Checkbox";
import {
  expenseItemsHideRemoveEdit,
  EXPENSE_CATEGORIES,
} from "../../../../constants";

const textCN = (selected) => cntl`
pl-2
font-medium
truncate
${selected ? "" : "text-gray-200"}
`;

const TypesSectionItem = ({
  item,
  index,
  toggleEditing,
  handleChange,
  handleRemove,
  handleEnter,
  handleBlur,
  handleCheck,
  setAddMode,
  children,
  selectionValue,
}) => {
  const displayValue = item?.display || item?.city;
  const hideRemoveEditExpenseItem =
    selectionValue === EXPENSE_CATEGORIES &&
    expenseItemsHideRemoveEdit?.includes(displayValue);
  const hideEdit = hideRemoveEditExpenseItem;
  const hideRemove = hideRemoveEditExpenseItem || item?.count;

  return (
    <EditRemoveActions
      index={index}
      isEditing={item?.isEditing}
      toggleEditing={toggleEditing}
      handleRemove={handleRemove}
      className="py-1 pl-0 w-full"
      setAddMode={setAddMode}
      hideRemove={hideRemove}
      hideEdit={hideEdit}
    >
      <div className="w-max flex flex-row pb-2">
        <Checkbox
          checked={item?.selected}
          onChange={() => handleCheck(index)}
          disabled={item?.count}
        />
        {children ?? (
          <>
            {(item?.isEditing && (
              <Input
                placeholder="Value"
                autoFocus
                value={displayValue}
                onChange={(value) => handleChange(value, index)}
                handleEnter={(event) => handleEnter(event, item.display, index)}
                onBlur={() => handleBlur(displayValue, index)}
                className="noGrow"
                mainClassName="py-1 pl-2"
              />
            )) || <h3 className={textCN(item?.selected)}>{displayValue}</h3>}
          </>
        )}
      </div>
    </EditRemoveActions>
  );
};

TypesSectionItem.propTypes = {
  item: PropTypes.shape({
    isEditing: PropTypes.bool,
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    city: PropTypes.string,
    count: PropTypes.number,
    selected: PropTypes.bool,
  }),
  index: PropTypes.number,
  toggleEditing: PropTypes.func,
  handleChange: PropTypes.func,
  handleRemove: PropTypes.func,
  handleEnter: PropTypes.func,
  handleBlur: PropTypes.func,
  handleCheck: PropTypes.func,
  setAddMode: PropTypes.func,
  children: PropTypes.element,
  selectionValue: PropTypes.string,
};

TypesSectionItem.defaultProps = {
  item: undefined,
  index: undefined,
  toggleEditing: undefined,
  handleChange: undefined,
  handleRemove: undefined,
  handleEnter: undefined,
  handleBlur: undefined,
  handleCheck: undefined,
  setAddMode: undefined,
  children: undefined,
  selectionValue: undefined,
};

export default TypesSectionItem;
