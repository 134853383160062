import moment from "moment";
import returnRecurrenceDates from "../returnRecurrenceDates";
import getDaySpanCount from "./getDaySpanCount";

const getUpdatedInfoForSingleInstance = ({
  updatedItem,
  userEvents,
  tasks,
  tasksDict,
}) => {
  // Get the recurrence dates for the updated item
  const recurrenceDatesForEdit = returnRecurrenceDates({
    recurrence: updatedItem?.recurrence,
  });

  const userEventsUpdates = {};

  // Update the single instance in the recurrence dates
  recurrenceDatesForEdit.forEach((date) => {
    const dateObj = moment(date);
    dateObj.startOf("day");
    const formattedDate = dateObj.format("YYYY-MM-DDTHH:mm:ssZ");
    const dayToReview = userEvents[formattedDate];

    // Replace or update the all-day events with the new data
    const newAllDay = dayToReview?.allDay.map((item) => {
      if (item.instanceStartDate === updatedItem.instanceStartDate) {
        const isMultiWeekEvent = moment(updatedItem?.startDate).isBefore(
          moment(updatedItem?.endDate).startOf("week")
        );

        const daySpan = getDaySpanCount({
          isMultiWeek: isMultiWeekEvent,
          item: updatedItem,
        });

        return { ...updatedItem, daySpan };
      }
      if (item.id === updatedItem.id) {
        return { ...item, metadata: updatedItem.metadata };
      }
      return item;
    });

    // Replace or update the brief events with the new data
    const newBrief = dayToReview?.brief.map((item) => {
      if (item.instanceStartDate === updatedItem.instanceStartDate) {
        return updatedItem;
      }
      if (item.id === updatedItem.id) {
        return { ...item, metadata: updatedItem.metadata };
      }
      return item;
    });

    // Construct the day's updated review data
    const newDayAfterReview = {
      ...dayToReview,
      allDayCount: newAllDay.length,
      allDay: newAllDay,
      brief: newBrief,
    };

    // Add the updated review to the updates collection
    userEventsUpdates[formattedDate] = newDayAfterReview;
  });

  const newTasks = [...tasks];
  const newTasksDict = { ...tasksDict };

  if (updatedItem.resource === "Task") {
    const editedItemIndex = newTasks.findIndex(
      (task) =>
        task.id === updatedItem.id &&
        task.instanceStartDate === updatedItem.instanceStartDate
    );

    newTasks[editedItemIndex] = updatedItem;

    newTasksDict[
      `${updatedItem.id}&instanceStartDate=${updatedItem.instanceStartDate}`
    ] = updatedItem;

    // update remaining task instances metadata
    const remainingInstancesIndexes = newTasks.reduce((acc, task, index) => {
      if (
        task.id === updatedItem.id &&
        task.instanceStartDate !== updatedItem.instanceStartDate
      ) {
        acc.push({ index, instanceStartDate: task.instanceStartDate });
      }
      return acc;
    }, []);

    remainingInstancesIndexes?.forEach(({ index, instanceStartDate }) => {
      const updatedInstance = {
        ...newTasks[index],
        metadata: updatedItem.metadata,
      };
      newTasks[index] = updatedInstance;

      newTasksDict[`${updatedItem.id}&instanceStartDate=${instanceStartDate}`] =
        updatedInstance;
    });
  }

  return {
    newTasks,
    newTasksDict,
    userEventsUpdates,
  };
};

export default getUpdatedInfoForSingleInstance;
