import { useCallback, useMemo, useState } from "react";
import { TaskAPI } from "@griffingroupglobal/eslib-api";
import { useQueryClient } from "react-query";
import useSpaces from "../../../hooks/useSpaces";
import "../Spinner/styles.css";
import { useAppState } from "../../../state/appState";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import useAssets from "../../../hooks/useAssets";
import { toastError, toastMessage } from "../Toast/Toast";
import editSingleItem from "../../../helpers/Task/editSingleTask";
import { taskKeys } from "../../../config/reactQuery/queryKeyFactory";
import getUpdatedAllTaskSpacesAssets from "../../../helpers/Utilities/getUpdatedAllTaskSpacesAssets";

const useAddSpaceAssetWidget = ({
  form,
  setForm,
  resource,
  isEditing,
  showSpacesAssets,
  currentTask,
  setCurrentTask,
  disabled,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const [
    { assetsDict, userEvents, tasks, tasksDict, unformattedEvents },
    appStateDispatch,
  ] = useAppState();
  const queryClient = useQueryClient();
  const isPropertyParent = form?.association?.includes("Property");

  // Handle Checking off Spaces && Assets from Single View task and View Task Modal
  const handleMarkComplete = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          // patch non recurring task
          const taskPatchResponse = await TaskAPI.patch(
            currentTask?.id,
            updatedTask,
            currentTask
          );
          setCurrentTask({
            ...taskPatchResponse.data,
          });
          setForm({ ...taskPatchResponse.data });

          toastMessage("Task updated successfully");
        } else {
          // patch single instance task
          const updatedTaskItem = await editSingleItem({
            currentTask,
            updatedTask,
            userEvents,
            tasks,
            tasksDict,
            appStateDispatch,
            unformattedEvents,
          });
          setCurrentTask({ ...updatedTaskItem });
          setForm({ ...updatedTaskItem });
        }
        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        console.error(error);
        toastError("This task could not be updated");
      }
    },
    [
      appStateDispatch,
      currentTask,
      queryClient,
      setCurrentTask,
      setForm,
      tasks,
      tasksDict,
      userEvents,
      unformattedEvents,
    ]
  );

  const handleCompleteCheck = useCallback(
    (value, entity) => {
      // create array with all spaces/assets
      const currentEntities = [
        ...(form?.spaces ?? []),
        ...(form?.assets ?? []),
      ];
      const newCompletionStatus = value;

      // Helper to loop and check off the correct space || asset and return each list
      const { spaces, assets } = getUpdatedAllTaskSpacesAssets({
        currentEntities,
        newCompletionStatus,
        entity,
      });

      const updatedTask = {
        ...form,
        assets,
        spaces,
      };
      handleMarkComplete(updatedTask);
      return newCompletionStatus;
    },
    [form, handleMarkComplete]
  );

  const params = useMemo(
    () => ({
      propertyRef: isPropertyParent && form?.association,
      projectRef: !isPropertyParent && form?.association,
    }),
    [form?.association, isPropertyParent]
  );
  const [originalAssets] = useAssets(params);

  // Show both spaces & assets in DDL or just show the given resource type
  const listForDDL = showSpacesAssets
    ? [...form?.spaces, ...form?.assets]
    : form?.[resource];

  const selectedMembersDDL = listForDDL?.map((item) => {
    return item?.ref ?? item?.id;
  });

  const hookDeps = useMemo(() => {
    if (form?.association?.includes("Property")) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: form?.association?.split("Property/")[1],
        ref: form?.association,
        spaceId: form?.space,
        api: "PropertyAPI",
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: form?.association?.split("Project/")[1],
      spaceId: form?.space,
      ref: form?.association,
      api: "ProjectAPI",
    };
  }, [form?.association, form?.space]);

  const {
    base: {
      data: { spaces = [], spacesDict = {} },
    },
  } = useSpaces(hookDeps);
  // Decide witch dictionary to use to grab the full resource
  const currentDict = useMemo(() => {
    switch (resource) {
      case "assets":
        return assetsDict;
      case "spaces":
        return spacesDict;
      default:
        return spacesDict;
    }
  }, [assetsDict, resource, spacesDict]);

  // Format List for DistroSelectMembers popup DDL
  const formattedList = useMemo(() => {
    // pick list for DDL depending on resource type
    const resourceList = resource === "spaces" ? spaces : originalAssets;
    // if DDL should contain both
    const listToMap = showSpacesAssets
      ? [...(spaces ?? []), ...(originalAssets ?? [])]
      : resourceList;
    const updatedList = [];
    listToMap?.forEach((item) => {
      const refOrId = item?.resource === "Asset" ? item?.reference : item?.id;
      updatedList.push({
        ...item,
        reference: refOrId,
        id: refOrId,
        name: { firstName: item?.name },
        avatar: item?.primaryImage,
      });
    });

    return updatedList;
  }, [originalAssets, resource, showSpacesAssets, spaces]);

  const currentEntities = useMemo(() => {
    // For reference ->
    // form.spaces = [ "uuid" ] spacesDict is "space id": {}
    // form.assets = [ "assetReference"] assetsDict is "AssetRef": {}
    const list = [];
    // if List should handle Both Spaces & Assets
    if (showSpacesAssets) {
      const joinedList = [...(form?.spaces ?? []), ...(form?.assets ?? [])];
      // map over joinedList to grab the obj of each entity
      joinedList?.forEach((item) => {
        if (item?.ref) {
          return list.push({
            ...assetsDict[item?.ref],
            isCompleted: item.isCompleted,
          });
        }
        return list.push({
          ...spacesDict[item?.id],
          isCompleted: item.isCompleted,
        });
      });
    }

    // if list should only handle the passed "resource" type (ie: "spaces" || "assets")
    form?.[resource]?.forEach((entity) => {
      const entityType = entity?.ref ? entity?.ref : entity?.id;
      return list.push({
        ...currentDict?.[entityType],
        isCompleted: entity.isCompleted,
      });
    });
    return list;
  }, [assetsDict, currentDict, form, resource, showSpacesAssets, spacesDict]);

  const resourceTitle = showSpacesAssets ? "Spaces/Assets" : resource;
  // remove 's' at the end of the resource name for 'spaces' & 'assets'
  const addButtonTitle = `Add ${
    showSpacesAssets
      ? "Space/Asset"
      : capitalizeFirstLetter(resource?.slice(0, -1))
  }`;
  const distroListTitle = `Associated ${capitalizeFirstLetter(resourceTitle)}`;
  const searchPlaceHolder = `Search ${capitalizeFirstLetter(resourceTitle)}`;

  // if editing and DDL isn't showing
  const showAddButton = isEditing && !showSelect && !disabled;
  return {
    currentEntities,
    addButtonTitle,
    distroListTitle,
    searchPlaceHolder,
    formattedList,
    showSelect,
    setShowSelect,
    showAddButton,
    selectedMembersDDL,
    handleCompleteCheck,
  };
};

export default useAddSpaceAssetWidget;
