import React from "react";

/**
 * Component that renders a text paragraph with formatted text
 * @param {text} - Text to display
 * @param {props} -  All props accepted in <p/> tag
 */
const DescriptionField = ({ text = "", className, ...props }) => {
  return (
    <p
      className={`flex text-gray-450 max-h-24 overflow-y-auto whitespace-pre-wrap ${
        className ?? ""
      }`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {text}
    </p>
  );
};

export default DescriptionField;
