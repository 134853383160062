import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  useFilters,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import useCurrentUser from "../../hooks/useCurrentUser";
import useProjects from "../../hooks/useProjects";
import { useDocumentSwitchView } from "../../hooks/useSwitchView";

import DocumentMgmtFolderView from "../../stories/Components/DocumentFolderView/DocumentMgmtFolderView";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import SiteHeaderSearch from "../../stories/Components/SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../../stories/Components/SiteHeader/SiteHeaderFilter";
import SiteHeaderSwitchView from "../../stories/Components/SiteHeader/SiteHeaderSwitchView";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";

import folderIconGreen from "../../stories/assets/images/folderIconGreen.svg";
import folderIconWhite from "../../stories/assets/images/folderIconWhite.svg";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import { FILTER_TYPES, TABLE_FILTER } from "../../constants";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
} from "../../stories/Components/Table/tableFilters";

const DocumentMgmtFileFolders = ({
  contents,
  favorites,
  selectedDocuments,
  docTypeOptionsMap,
  addedFiles,
  onUpdateDocuments,
  onFileSelect,
  onFileClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileAdd,
  onFileDelete,
  onFavoriteClick,
  showHeader,
  hideButtons,
}) => {
  const { data: currentUser } = useCurrentUser();
  const { projectId } = useParams();
  const [isShowingTable, setIsShowingTable] = useDocumentSwitchView(false);
  const { currentProject } = useProjects(projectId);
  const [columns, setColumns] = useState([]);

  const documentColumns = useMemo(
    () => [
      {
        id: "favorited",
        Header: "",
        accessor: "isFavorited",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
      },
      {
        Header: "Name",
        accessor: "resource",
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "docType",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: (row) => moment(row.modifiedDate).format("MMM DD, YYYY"),
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const col = documentColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [documentColumns]);

  const {
    setAllFilters,
    setGlobalFilter,
    rows,
    allColumns,
    state: { filters, globalFilter },
  } = useTable(
    {
      columns,
      data: contents,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters,
    useGlobalFilter
  );

  const [value, setValue] = useState(globalFilter);
  const onSearchChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const handleUploadClick = () => {
    document.querySelector("#document-folders .document_upload_form")?.click();
  };

  return (
    <>
      {showHeader && (
        <SiteHeader
          title={currentProject?.name || "Documents"}
          dropdownRoutes={
            projectId && getProjectOptions(projectId, currentUser)
          }
          buttons={
            currentUser?.hasPermission?.("document", "can_write") && (
              <PrimaryButton
                title="+ add document"
                onClick={handleUploadClick}
                large
              />
            )
          }
          search={
            <SiteHeaderSearch
              handleSearch={(val) => {
                onSearchChange(val);
                setValue(val);
              }}
              globalFilter={value}
            />
          }
          filter={
            <SiteHeaderFilter
              allColumns={allColumns}
              tableData={contents}
              filters={filters}
              setAllFilters={setAllFilters}
            />
          }
          switchView={
            <SiteHeaderSwitchView
              isShowingTable={isShowingTable}
              setIsShowingTable={setIsShowingTable}
              firstIcon={folderIconGreen}
              firstSelectedIcon={folderIconWhite}
            />
          }
        />
      )}
      <div id="document-folders" className="flex flex-col">
        <DocumentMgmtFolderView
          documentData={rows}
          favorites={favorites}
          selectedDocuments={selectedDocuments}
          docTypeOptionsMap={docTypeOptionsMap}
          addedFiles={addedFiles}
          onUpdateDocuments={onUpdateDocuments}
          onFileSelect={onFileSelect}
          onFileClick={onFileClick}
          onFileEdit={onFileEdit}
          onShowHistory={onShowHistory}
          onSendToWorkflow={onSendToWorkflow}
          onUploadVersion={onUploadVersion}
          onFileAdd={onFileAdd}
          onFileDelete={onFileDelete}
          onFavoriteClick={onFavoriteClick}
          hideButtons={hideButtons}
        />
      </div>
    </>
  );
};

DocumentMgmtFileFolders.propTypes = {
  /**
   * array of document attachments
   */
  // eslint-disable-next-line react/forbid-prop-types
  contents: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of favortied documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * selected documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  /**
   * array of added files
   */
  // eslint-disable-next-line react/forbid-prop-types
  addedFiles: PropTypes.array,
  /**
   * function called after new document is uploaded
   */
  onUpdateDocuments: PropTypes.func,
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * function for handling file click
   */
  onFileClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * function for file upload
   */
  onFileAdd: PropTypes.func,
  /**
   * function for handling file delete
   */
  onFileDelete: PropTypes.func,
  /**
   * function for handling favoriting of a document
   */
  onFavoriteClick: PropTypes.func,
  /**
   * boolean for displaying site header
   */
  showHeader: PropTypes.bool,
  /**
   * boolean for hiding document buttons
   */
  hideButtons: PropTypes.bool,
};

DocumentMgmtFileFolders.defaultProps = {
  contents: [],
  favorites: [],
  selectedDocuments: undefined,
  docTypeOptionsMap: {},
  addedFiles: [],
  onUpdateDocuments: undefined,
  onFileSelect: undefined,
  onFileClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileAdd: () => {},
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  showHeader: true,
  hideButtons: false,
};

export default DocumentMgmtFileFolders;
