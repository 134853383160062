import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { CredentialManagerAPI, UserAPI } from "@griffingroupglobal/eslib-api";
import { isEqual, cloneDeep } from "lodash";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ContactInfo from "../ContactForm/ContactInfo";
import ContactPreferences from "../ContactForm/ContactPreferences";
import PrimaryButton from "../Buttons/PrimaryButton";
import {
  formatPayloadUser,
  formatUserPayload,
  getVCard,
} from "../../../helpers/User";
import useDirectReports from "../../../hooks/useDirectReports";
import SiteHeader from "../SiteHeader/SiteHeader";
import FormAvatar from "../Avatar/FormAvatar";
import InlineInput from "../Input/InlineInput";
import ContactTitleButtons from "./ContactTitleButtons";
import WidgetContainer from "../Widget/WidgetContainer";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import {
  ADD_OPEN_MODAL,
  GET_CONTACT_PATH,
  COMPANY_EMPTY,
  REMOVE_USER,
  TOGGLE_POSITIONED_POPUP,
  INVITE_CONTACT_POPUP,
  CONTACT_MANAGEMENT,
} from "../../../constants";
import ImagesAndVideosWidget from "../MediaWidget/ImagesAndVideosWidget";
import { useGetFilesByCategory } from "../../../hooks/useGetFilesByUser";
import FilesTable from "../FilesTable/FilesTable";
import useContactReducer from "../../../hooks/useContactReducer";
import { useModalState } from "../../../state/modalState";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import { toastError, toastMessage } from "../Toast/Toast";
import usePostCompanyMembers from "../../../hooks/useUserPostCompanyMembers";
import useUserPost from "../../../hooks/useUserPost";
import {
  useCompanyMembers,
  useMembers,
  useUsers,
} from "../../../hooks/useUsers.new";
import useUserPatch from "../../../hooks/useUserPatch";
import useUserDelete from "../../../hooks/useUserDelete";
import useUserPreferences from "../../../hooks/useUserPreferences";
import useManagementConfigurationPatch from "../../../hooks/useManagementConfigurationPatch";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useContactViewData from "./useContactViewData";
import { useUserById } from "../../../hooks/useUserById";
import usePreferencePatch from "../../../hooks/usePreferencePatch";
import { useAppState } from "../../../state/appState";
import { userKeys } from "../../../config/reactQuery/queryKeyFactory";
import useMouseTracker from "../../../hooks/useMouseTracker";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

/**
 * This is the component that renders data inside single contact/user/company page
 */
const ContactView = ({
  openChat,
  contacts,
  currentUser,
  roles,
  availableSeats,
  states,
  ptoCategory,
}) => {
  const { contactId } = useParams();
  // Get single user
  const { data: contactUser } = useUserById(contactId);

  const { unassigned } = useContactViewData();

  const { data: usersData } = useUsers();

  const { data: managementConfiguration } = useManagementConfiguration();
  const users = usersData?.users;

  const [, directReportOptions] = useDirectReports();
  const history = useHistory();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [, setAssociatedUsers] = useState([]);
  const [editingContact, setEditingContact] = useState(false);
  const [phoneOptions, setPhoneOptions] = useState();
  const [emailOptions, setEmailOptions] = useState();
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const downloadButton = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSaving, setIsSaving] = useState();

  const { data: entityContacts } = useCompanyMembers(`User/${contactId}`);
  const { data: companyList } = useMembers("company");
  // Clone the contactUser to keep from mutating the user in RQ Cache
  const { contactDetails, contactInfo } = useMemo(
    () => formatPayloadUser(cloneDeep(contactUser)),
    [contactUser]
  );

  const { data: preferences, isLoading: loadingPreferences } =
    useUserPreferences(contactId);

  const [preference, setPreference] = useState();
  const { mutate: patchPreferences } = usePreferencePatch();

  const [details, setDetails] = useState();
  const [detailsClone, setDetailsClone] = useState();
  const [info, setInfo] = useState(contactInfo);
  const [infoClone, setInfoClone] = useState();

  const [loadingDetails, setLoadingDetails] = useState(true);
  const [companyAssignees, setCompanyAssignees] = useState([]);
  const { mutate: deleteUser } = useUserDelete();
  const { mutateAsync: patchUser } = useUserPatch();
  const { mutateAsync: patchManagementConfig } =
    useManagementConfigurationPatch();
  const [newDisciplines, setNewDisciplines] = useState([]);
  const [companyContacts, setCompanyContacts] = useState();
  const [unassignedContacts, setUnassignedContacts] = useState();

  const { data: media, isLoading: isLoadingMedia } = useGetFilesByCategory(
    `User/${contactId}`,
    ["Photos", "Videos"]
  );
  const { data: files } = useGetFilesByCategory(`User/${contactId}`, [
    "Documents",
  ]);
  const [contactResource, contactDispatch] = useContactReducer();
  const [, modalDispatch] = useModalState();
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const contactKind = contactUser?.kind !== "company";
  const { mutate: postCompanyMembers } = usePostCompanyMembers(
    `company:members:${contactUser?.id}`
  );
  const [originalAssignees, setOriginalAssignees] = useState();
  const { mutateAsync: postNewUser } = useUserPost();
  const modalRef = useRef();
  const [, appStateDispatch] = useAppState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (unassigned) {
      setUnassignedContacts(unassigned);
    }
  }, [unassigned]);

  useEffect(() => {
    contactDispatch({
      type: "setOriginalResource",
      resource: contactInfo,
    });
  }, [contactDispatch, contactInfo]);

  useEffect(() => {
    if (entityContacts && !editingContact) {
      setCompanyContacts(entityContacts);
      setCompanyAssignees(entityContacts);
      setOriginalAssignees(entityContacts);
    }
  }, [
    companyContacts,
    contactId,
    editingContact,
    entityContacts,
    loadingPreferences,
  ]);

  // For Re-Rendering New Info if Save or Navigate
  // to a new contact
  useEffect(() => {
    if (contactUser && !editingContact) {
      setDetails(contactDetails);
      setInfo(contactInfo);
      setCompanyContacts(entityContacts);
      setCompanyAssignees(entityContacts);
    }
  }, [
    contactDetails,
    contactInfo,
    editingContact,
    entityContacts,
    contactUser,
  ]);

  useEffect(() => {
    if (contactUser && contactInfo && loadingDetails && contactDetails) {
      setDetails(contactDetails);
      setDetailsClone(contactDetails);
      setInfo(contactInfo);
      setInfoClone(contactInfo);
      setLoadingDetails(false);
    }
  }, [contactDetails, contactInfo, contactUser, loadingDetails]);

  useEffect(() => {
    if (preferences && !loadingPreferences) {
      setPreference(cloneDeep(preferences));
    }
  }, [loadingPreferences, preferences]);

  useEffect(() => {
    if (contactUser) {
      setEmailOptions(
        contactUser?.contactPoint
          ?.filter((cp) => cp.system === "Email")
          ?.map((email) => ({
            title: `${email?.use} ${email.value}`,
            onClick: () => {
              document.location = `mailto:${email.value}?subject=&body=`;
            },
          }))
      );
      setPhoneOptions(
        contactUser?.contactPoint
          ?.filter((cp) => cp.system === "Phone")
          ?.map((phone) => ({
            title: `${phone?.use} ${phone.value}`,
            anchorTag: (
              <a href={`tel:${phone.value}`}>
                {phone?.use} {phone.value}
              </a>
            ),
          }))
      );
    }
  }, [contactUser]);

  // TODO: Recfator company members logic to handle RQ (not covered in current ticket)
  useEffect(() => {
    if (contacts?.length && contactUser) {
      const companyAssociatedUsers = contacts?.filter(
        (c) =>
          c.company.value === contactUser?.reference && !c.metadata.deletedAt
      );
      setAssociatedUsers(
        companyAssociatedUsers?.map((usr) => ({
          label: `${usr?.name?.firstName} ${usr?.name?.lastName}`,
          name: {
            firstName: usr?.name?.firstName,
            lastName: usr?.name?.lastName,
          },
          title: usr?.title,
          value: usr?.id,
          contactPoint: usr?.contactPoint,
          avatar: usr?.avatar,
          isMember: usr?.kind === "member",
          titleCN: "text-black text-xs",
          lastActive: usr?.lastActive,
          isOnline: usr?.isOnline,
        }))
      );
    }
  }, [contacts, contactUser]);

  useEffect(() => {
    if (fileDownloadUrl) {
      downloadButton.current.click();

      setFileDownloadUrl(null);
    }
  }, [fileDownloadUrl]);

  const onRemoveUser = useCallback(async () => {
    // Delete User uses $bulkdelete endpoint and expects array of refs
    deleteUser([contactUser?.reference]);
    history.push("/contacts");
  }, [contactUser?.reference, deleteUser, history]);

  const onDeleteContact = useCallback(() => {
    const id = uuidv4();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      position: {
        centered: true,
      },
      ref: { id },
      modalData: {
        id,
        item: {
          id,
          removeFrom: CONTACT_MANAGEMENT,
          selectedUsers: [contactUser],
          onSave: onRemoveUser,
        },
      },
      modalType: REMOVE_USER,
    });
  }, [contactUser, modalDispatch, onRemoveUser]);

  const onFinish = useCallback(
    async (data) => {
      const hasEmail = contactUser?.contactPoint?.filter(
        (item) => item.system === "Email"
      );
      const payload = {
        ...contactUser,
        ...data,
        email: hasEmail?.[0]?.value,
      };
      try {
        await UserAPI.postWOP(`${payload.id}/$invitecontact`, payload);
        queryClient.invalidateQueries(userKeys?.users);
        toastMessage("Invite sent successfully");
      } catch (error) {
        toastError("There was an issue sending the invitation");
      }
    },
    [contactUser, queryClient]
  );

  const { mousePosition } = useMouseTracker();

  const handleInviteContact = useCallback(() => {
    const newData = {
      userInfo: { ...contactUser },
      directReportOptions,
      roles,
      availableSeats,
      states,
      ptoCategory,
      onFinish,
    };
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      ref: { id: uuidv4() },
      popupData: newData,
      position: {
        x: mousePosition.x - 500,
        y: mousePosition.y + 20,
      },
      popupType: INVITE_CONTACT_POPUP,
    });
  }, [
    appStateDispatch,
    availableSeats,
    contactUser,
    directReportOptions,
    mousePosition,
    onFinish,
    ptoCategory,
    roles,
    states,
  ]);

  useEffect(() => {
    const options = [];

    if (
      currentUser?.id !== contactUser?.id &&
      !contactUser?.isOwner &&
      (currentUser?.isAdmin ||
        currentUser?.isSuperAdmin ||
        currentUser?.hasPermission?.("contacts", "can_delete"))
    ) {
      options.push({
        onClick: () => {
          onDeleteContact();
        },
        title: "Delete",
      });
    }

    if (
      contactUser?.kind === "nonmember" &&
      currentUser?.kind !== "nonmember" &&
      (currentUser?.isAdmin || currentUser?.isSuperAdmin)
    ) {
      options.push({
        onClick: () => {
          handleInviteContact();
        },
        title: "Invite to EstateSpace",
      });
    }
    options.push({
      onClick: () => {
        const myVCard = getVCard(contactUser);

        const blob = new Blob([myVCard], {
          type: "text/vcard;charset=utf-8",
        });
        const url = URL.createObjectURL(blob);
        setFileDownloadUrl(url);
      },
      title: "Share vCard",
    });

    if (contactUser?.id === currentUser?.id) {
      options.push({
        onClick: () => {
          setChangePasswordModalOpen(true);
        },
        title: "Reset Password",
      });
    }

    setDropdownOptions(options);
  }, [
    activeIndex,
    contactUser,
    currentUser,
    handleInviteContact,
    history,
    onDeleteContact,
    users,
  ]);

  // Navigate to selected Contact
  const handleNavigate = useCallback(
    ({ id }) => {
      // Fires useEffect to update
      // contactDetails & contactInfo for new Contact
      history.push(GET_CONTACT_PATH(id, "0"));
    },
    [history]
  );
  const tabs = useMemo(() => {
    const tabsArray = {
      tabs: [
        {
          title: "Details",
          content: (
            <ContactInfo
              contactInfo={info}
              contactDetails={details}
              setContactDetails={setDetails}
              setContactInfo={setInfo}
              setActiveIndex={setActiveIndex}
              setEditingContact={setEditingContact}
              editingContact={editingContact}
              companyAssignees={companyAssignees}
              saving={isSaving}
              loading={loadingDetails}
              companyList={companyList}
              managementConfiguration={managementConfiguration}
              setNewDisciplines={setNewDisciplines}
              contactUser={contactUser}
              contactDispatch={contactDispatch}
              contactResource={contactResource}
              setCompanyAssignees={setCompanyAssignees}
              setUnassignedContacts={setUnassignedContacts}
              unassignedContacts={unassignedContacts}
              handleNavigate={handleNavigate}
            />
          ),
        },
        {
          title: "Media",
          content: (
            <ImagesAndVideosWidget
              border={false}
              disableEditing={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                )
              }
              isContact
              media={media}
              loadingMedia={isLoadingMedia}
            />
          ),
          // TODO: Needs Clarification From Chris/JF before implementation
          isHidden: true,
        },
        {
          title: "Preferences",
          content: (
            <ContactPreferences
              preferences={preference}
              setPreferences={setPreference}
              isEditing={editingContact}
              loading={loadingPreferences}
            />
          ),
          isHidden: !contactKind,
        },
        {
          title: "Files",
          content: (
            <FilesTable
              files={files}
              resourceName="User"
              association={contactUser?.reference}
              hasEditPermission={false}
            />
          ),
          // TODO: Needs Clarification from chris/jf before implementation
          isHidden: true,
        },
        /**
         * Commented for future release
         */
        // {
        //   title: "Financials",
        //   content: ContactFinancials({}),
        //   isHidden: userDetails?.kind === "company",
        // },
      ],
    };
    return tabsArray;
  }, [
    companyAssignees,
    companyList,
    contactDispatch,
    contactKind,
    contactResource,
    contactUser,
    currentUser,
    details,
    editingContact,
    files,
    handleNavigate,
    info,
    isLoadingMedia,
    isSaving,
    loadingDetails,
    loadingPreferences,
    managementConfiguration,
    media,
    preference,
    unassignedContacts,
  ]);

  const handleEditClick = useCallback(() => {
    setEditingContact((prevEditingContact) => !prevEditingContact);
  }, []);

  const handlePrimaryImageChange = async (fileResource) => {
    if (fileResource) {
      setDetails((prev) => ({
        ...prev,
        avatar: fileResource.reference,
      }));
    }
  };

  const onSave = useCallback(async () => {
    const patches = [];
    /**
     * Delay Saving
     * @summary - give Toast time to load
     */

    /**
     * Push API patches into array
     * For Promise All and Toast
     * Saving/Success/Error Message
     */

    setIsSaving(true);
    const payload = await formatUserPayload(
      details,
      info,
      contactUser?.kind,
      contactResource?.currentTags,
      contactResource?.links
    );
    const newPayload = { ...contactUser, ...payload };
    /**
     * Compare Details to Clone
     * @summary - Conditionally patch on changes
     */
    if (
      !isEqual(infoClone, info) ||
      !isEqual(detailsClone, details) ||
      !isEqual(
        contactResource?.currentTags?.map((tag) => tag?.value) || [],
        contactResource?.originalResource?.tags || []
      ) ||
      !isEqual(contactResource?.links, contactResource?.originalResource?.links)
    ) {
      // if creating new company in company dropdown
      if (details?.isNewCompany) {
        // New Company Object
        const newCompany = {
          ...COMPANY_EMPTY,
          company: { value: details?.companyName, type: "string" },
          companyName: details?.companyName,
        };

        // Post new Company
        postNewUser(newCompany).then((response) => {
          // Create Updated User Obj with the returned userRef and companyName
          const updatedUser = {
            ...newPayload,
            companyName: response?.companyName,
            company: { value: response?.reference, type: "reference" },
          };

          // Initiate Posting the new contact with updated reference to the new company
          patchUser({ oldUserInfo: contactUser, newUserInfo: updatedUser });
        });
      } else {
        // Patch Contact normally
        patchUser({
          oldUserInfo: contactUser,
          newUserInfo: newPayload,
        });
      }
    }
    /**
     * Check if Preferences have been updated
     * @summary - Patch Preferences if change detected
     */
    if (!isEqual(preference, preferences)) {
      let newPreferenceData;
      if (contactUser?.kind !== "company") {
        newPreferenceData = { ...preferences, ...preference };
        patchPreferences({
          preferenceId: preferences.id,
          newPreferenceData,
          oldPreferenceData: preferences,
        });
      }
    }

    /**
     * Check for new Members
     * @summary - Conditionally patch new Members
     */

    const removedMembers = originalAssignees.filter(
      (mem) => !companyAssignees.includes(mem)
    );
    const addedMembers = companyAssignees.filter(
      (mem) => !originalAssignees.includes(mem)
    );

    if (addedMembers?.length > 0 || removedMembers?.length > 0) {
      const newAddedAssignees = addedMembers?.map((a) => a?.reference);
      const memberPayload = {
        add: newAddedAssignees,
        remove: removedMembers?.map((item) => item.reference),
      };
      patches.push(() => {
        postCompanyMembers({
          userId: details?.id,
          payload: memberPayload,
        });
      });
    }

    // adding new discplines if newDisciplines state contains value
    if (newDisciplines?.length) {
      const newDisciplinesToAdd = newDisciplines.map((discipline) => ({
        selected: true,
        custom: true,
        id: discipline.id,
        display: discipline.display,
      }));
      const updatedManagement = {
        ...managementConfiguration?.management,
        contact: {
          ...managementConfiguration?.management?.contact,
          disciplines: [
            ...managementConfiguration?.management.contact.disciplines,
            ...newDisciplinesToAdd,
          ],
        },
      };
      try {
        patchManagementConfig({ updatedManagement });
      } catch (err) {
        console.error("Error updating management configuration");
      }
    }
    /**
     * Promise All Patches
     * @summary - Catch Patches Success/Error
     */
    let arrayOfPatches = patches?.map((patch) => patch());

    if (arrayOfPatches?.length > 0) {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      arrayOfPatches = [SavingDelay(), ...arrayOfPatches];
      setIsSaving(true);
      /**
       * Initialize Loading Toast
       */
      const savingToast = toast("Saving...", {
        isLoading: true,
        position: "top-center",
      });
      Promise.all(arrayOfPatches)
        .then(() => {
          /**
           * On Success
           * @summary - Update Toast Accordingly
           * Redirect
           */
          toast.update(savingToast, {
            isLoading: false,
            render: "Saved",
            closeButton: toastCloseIcon,
            className: "bg-brandGreen text-white",
            hideProgressBar: true,
            position: "top-center",
            icon: toastIcon,
            autoClose: 3000,
          });
          setIsSaving(false);
        })
        .catch(() => {
          /**
           * On Failure
           * @summary -  Update Toast Accordingly
           * Redirect
           */
          toast.update(savingToast, {
            isLoading: false,
            render: "Error Saving",
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
          setIsSaving(false);
          history.push(
            `/contacts/${details.id || contactUser?.id}/${
              contactUser?.kind === "company" ? 0 : activeIndex
            }`
          );
        });
    }
    setIsSaving(false);
  }, [
    details,
    info,
    contactUser,
    contactResource?.currentTags,
    contactResource?.links,
    contactResource?.originalResource?.tags,
    contactResource?.originalResource?.links,
    infoClone,
    detailsClone,
    preference,
    originalAssignees,
    companyAssignees,
    newDisciplines,
    postNewUser,
    patchUser,
    preferences,
    patchPreferences,
    postCompanyMembers,
    managementConfiguration?.management,
    patchManagementConfig,
    history,
    activeIndex,
  ]);

  const handleChangeName = (value, key) => {
    if (editingContact) {
      setDetails({
        ...details,
        [key]: value,
      });
    }
  };

  const resetContactState = useCallback(() => {
    setDetails(contactDetails);
    setInfo(contactInfo);
    setCompanyAssignees(originalAssignees);
    setUnassignedContacts(unassigned);
  }, [contactDetails, contactInfo, originalAssignees, unassigned]);

  /**
   * API Helper method to call the "$changepassword" api method, to update a User's password. Promise resolves to true
   * if the password was updated successfully, or false if there was an error.
   */
  const saveUpdatedPassword = async (newPassword) => {
    setChangePasswordModalOpen(false);
    try {
      const res = await CredentialManagerAPI.postWOP("$changepassword", {
        newPassword1: newPassword,
        newPassword2: newPassword,
      });
      if (res.data.issue.code === "successful") {
        // success toast
        toastMessage("Updated Password");
      }
    } catch (e) {
      toastError("Error Updating Password");
    }
  };

  return (
    <>
      <>
        <SiteHeader
          title={
            <div
              ref={modalRef}
              className="relative flex flex-row items-center min-w-max"
            >
              <FormAvatar
                user={contactKind}
                isEditing={editingContact}
                editing={editingContact}
                image={details?.avatar}
                loading={loadingDetails}
                onChange={handlePrimaryImageChange}
                disabled={!editingContact}
                setLoading={setLoadingDetails}
              />
              <div className="flex flex-col min-w-full">
                {!editingContact ? (
                  <>
                    <p className="gray-650 font-bold text-4xl ml-6 mb-1 min-w-max ">
                      {contactKind
                        ? `${contactUser?.name?.firstName || ""} ${
                            contactUser?.name?.lastName || ""
                          }`
                        : contactUser?.company?.value}
                    </p>
                    <ContactTitleButtons
                      contact={contactUser}
                      openChat={openChat}
                      emailOptions={emailOptions}
                      phoneOptions={phoneOptions}
                      currentUser={currentUser}
                    />
                  </>
                ) : (
                  <div className="flex flex-col w-1/2 gap-2 ml-6">
                    <InlineInput
                      size="3xl"
                      width="w-full"
                      value={details?.firstName ?? ""}
                      editing={editingContact}
                      loading={loadingDetails}
                      fontWeight="bold"
                      color="gray-650"
                      onChangeCallback={(value) =>
                        handleChangeName(value, "firstName")
                      }
                      validation={yup
                        ?.string()
                        ?.trim()
                        .min(3, "Must be over 3 characters")
                        .required()}
                      hidePencil
                    />
                    {contactKind ? (
                      <InlineInput
                        hidePencil
                        size="3xl"
                        value={details?.lastName ?? ""}
                        editing={editingContact}
                        fontWeight="bold"
                        loading={loadingDetails}
                        color="gray-650"
                        width="w-full"
                        onChangeCallback={(value) =>
                          handleChangeName(value, "lastName")
                        }
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          }
          buttons={
            currentUser?.hasPermission?.("contacts", "can_write") && (
              <PrimaryButton
                title="Actions"
                dropdownItems={dropdownOptions}
                className="dropdown-btn"
                large
              />
            )
          }
        />
      </>
      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        isEditing={editingContact}
        handleEditClick={handleEditClick}
        onFinishEditing={onSave}
        tabs={tabs.tabs}
        loading={isSaving || loadingDetails}
        disableEditing={
          !(
            currentUser?.hasPermission?.("contacts", "can_write") ||
            currentUser?.id === contactId
          ) || details?.firstName?.trim().length < 3
        }
        activeIndex={activeIndex}
        onTabClick={(index) => {
          setActiveIndex(index);
        }}
        resetResourceState={resetContactState}
      />
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          isModalOpen={isChangePasswordModalOpen}
          setModalOpen={setChangePasswordModalOpen}
          onSaveNewPassword={saveUpdatedPassword}
        />
      )}
      <a
        className="hidden"
        download={`${contactUser?.name?.firstName} ${contactUser?.name?.lastName}-VCard.vcf`}
        href={fileDownloadUrl}
        ref={downloadButton}
      >
        export_vcard
      </a>
    </>
  );
};

ContactView.propTypes = {
  contactUser: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
    kind: PropTypes.string,
    isOwner: PropTypes.bool,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    company: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }),
    contactPoint: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  openChat: PropTypes.func,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        value: PropTypes.string,
      }),
    })
  ),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    hasPermission: PropTypes.func,
  }),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  availableSeats: PropTypes.number,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  ptoCategory: PropTypes.string,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      contact: PropTypes.shape({
        disciplines: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

ContactView.defaultProps = {
  contactUser: undefined,
  openChat: undefined,
  contacts: undefined,
  currentUser: undefined,
  roles: [],
  availableSeats: 0,
  states: [],
  ptoCategory: undefined,
  managementConfiguration: undefined,
};

export default ContactView;
