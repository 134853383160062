import React from "react";
import Input from "../../../Input/Input";
import activityLogIcon from "../../../../assets/images/activityLogIcon.svg";
import IconButton from "../../../Buttons/IconButton";

const TaskTitle = ({
  isEditing,
  value,
  onChange,
  currentTask,
  page,
  onActivityClick,
  showActivityButton,
  disabled,
}) => {
  // if in editing mode, show input
  if (isEditing)
    return (
      <div className="mb-6 mt-3">
        <Input
          placeholder="Name"
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    );

  // if in page mode, show title in 4xl
  if (page) {
    return (
      <div className="flex justify-between items-end">
        <h1 className="text-gray-500 font-semibold text-4xl mt-6 mb-6">
          {`${currentTask?.name}`}
        </h1>
        <div className={`pb-6 ${!showActivityButton && "hidden"}`}>
          <IconButton
            imgClassName="w-7 h-8"
            iconClassName="w-7 h-8"
            icon={activityLogIcon}
            onClick={onActivityClick}
          />
        </div>
      </div>
    );
  }

  // if not in editing mode, show title
  return (
    <div className="flex justify-between items-end">
      <h1 className="text-gray-500 font-semibold text-2xl mt-6 mb-4">
        {`${currentTask?.name}`}
      </h1>
      <div className={`pb-4 ${!showActivityButton && "hidden"}`}>
        <IconButton
          imgClassName="w-7 h-8"
          iconClassName="w-7 h-8"
          icon={activityLogIcon}
          onClick={onActivityClick}
        />
      </div>
    </div>
  );
};

export default TaskTitle;
