import cntl from "cntl";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { useAppState } from "../../../state/appState";
import dragIcon from "../../assets/images/drag_icon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import stopEditIcon from "../../assets/images/editIconStop.svg";
import plusIcon from "../../assets/images/plusCircleIconGreen.svg";
import plusIconDisabled from "../../assets/images/plusCircleIconGrey.svg";
import settingsIcon from "../../assets/images/settingsIconGray.svg";
import IconButton from "../Buttons/IconButton";
import CircledNumber from "../CircledNumber/CircledNumber";
import ToTopButton from "../ToTopButton/ToTopButton";
import WidgetSettings from "./WidgetSettings";
import "./WidgetStyles.css";

const containerCN = (className, width, hideBorder, padding) => cntl`
  bg-white
  ${`px-${padding ? padding + 2 : "8"}`}
  ${`py-${padding ?? "6"}`}
  rounded-md
  ${`w-${width}`}
  ${hideBorder ? "" : "border"}
  border-gray-200
  ${className}
  transition-all
  `;

const childCN = (childClass, overflow) => cntl`
${!overflow && "overflow-hidden"}
relative
${childClass}
`;

const Widget = ({
  key,
  className,
  childClassName,
  children,
  footer,
  title,
  settings,
  count,
  fullRow,
  draggable,
  overflow,
  handleEditClick,
  handleAddClick,
  isEditing,
  width,
  disableEditing,
  loading,
  hideBorder,
  padding,
  backToTop,
}) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const settingsRef = useRef();
  const [hovering, setHovering] = useState(false);
  const wrapperRef = useRef(null);
  const [showGoTop, setShowGoTop] = useState();
  const [{ mainContainerRef }] = useAppState();

  const handleScrollUp = () => {
    wrapperRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  /**
   * Track scroll position
   * @Summary - Track Position To show "Back to Top" Button on Scroll
   */

  const handleScroll = useCallback(() => {
    const position = mainContainerRef?.scrollTop;
    if (position > 500) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
  }, [mainContainerRef?.scrollTop]);

  useEffect(() => {
    mainContainerRef?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      mainContainerRef?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, mainContainerRef]);

  const backtoTopButton = useCallback(
    () => <ToTopButton isVisible={showGoTop} onClick={handleScrollUp} />,
    [showGoTop]
  );

  const actionCN = useMemo(() => {
    let colCount = 0;
    if (settings) colCount += 1;
    if (handleEditClick) colCount += 1;
    if (handleAddClick) colCount += 1;

    return `grid grid-cols-${colCount ?? 1} gap-3`;
  }, [handleAddClick, handleEditClick, settings]);

  const handleHover = (mouseOver) => {
    if (mouseOver) {
      setHovering(true);
    } else {
      setHovering();
    }
  };

  const onAddClick = useCallback(() => {
    if (!isEditing) handleAddClick();
  }, [handleAddClick, isEditing]);

  const blurSettings = useCallback(() => {
    setSettingsOpen(false);
  }, []);

  useOutsideAlerter(settingsRef, blurSettings);

  const onSettingsClick = useCallback(() => {
    if (settingsOpen) {
      setSettingsOpen(false);
    } else {
      setSettingsOpen(true);
    }
  }, [settingsOpen]);
  return (
    <div
      key={key}
      className={containerCN(className, width, hideBorder, padding)}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover()}
      onFocus={() => handleHover(true)}
      onBlur={() => handleHover()}
      ref={wrapperRef}
    >
      <div className="flex w-full mb-4 justify-between">
        <div className="flex w-full" id="widget-title">
          {draggable && (
            <img
              className="h-4 cursor-move mt-1 select-none mr-4"
              src={dragIcon}
              alt="drag icon"
              draggable={false}
            />
          )}
          <div className="relative flex">
            {title && <p className="ESTitle">{title}</p>}
            {count && (
              <CircledNumber
                aspectRatio="xMinYMid meet"
                number={count}
                size="1"
                className="absolute left-full ml-2"
              />
            )}
          </div>
        </div>
        <div className={actionCN} id="widget-actions">
          {handleAddClick && (
            <IconButton
              wrapperClassName={
                hovering
                  ? `${!isEditing && "icon-transition"} col-span-1`
                  : "invisible"
              }
              imgClassName="mt-1 w-4 h-4"
              iconClassName="w-5 h-5"
              onClick={onAddClick}
              icon={isEditing ? plusIconDisabled : plusIcon}
            />
          )}
          {handleEditClick && !disableEditing && (
            <div className="flex">
              {!isEditing && (
                <IconButton
                  wrapperClassName={
                    hovering ? "icon-transition col-span-1" : "invisible"
                  }
                  imgClassName="w-6 h-6"
                  iconClassName="w-6 h-6"
                  onClick={handleEditClick}
                  icon={editIcon}
                />
              )}
              {isEditing && (
                <IconButton
                  wrapperClassName={
                    hovering ? "icon-transition col-span-1" : "invisible"
                  }
                  imgClassName="w-6 w-6"
                  iconClassName="w-6 h-6"
                  onClick={handleEditClick}
                  icon={stopEditIcon}
                />
              )}
            </div>
          )}
          {settings && (
            <div className="flex relative col-span-1" ref={settingsRef}>
              <div
                onClick={() => onSettingsClick()}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                <img
                  className="h-4 cursor-move mt-1 select-none mr-4 icon-transition"
                  src={settingsIcon}
                  alt="settings icon"
                  draggable={false}
                />
              </div>
              {settingsOpen && <WidgetSettings settings={settings} />}
            </div>
          )}
        </div>
      </div>
      <div className={`relative ${loading && "loading"}`}>
        <div
          className={childCN(childClassName, overflow)}
          style={fullRow ? { height: "450px" } : {}}
        >
          {children}
        </div>
        {footer}
        {backToTop ? backtoTopButton() : null}
      </div>
    </div>
  );
};

Widget.propTypes = {
  /**
   * to use if you want to use widget as a list item
   */
  key: PropTypes.string,
  /**
   * className to pass to the container
   */
  className: PropTypes.string,
  /**
   * Tailwind value ex. full, 1/2, 1/3
   */
  width: PropTypes.string,
  /**
   * Title of widget
   */
  title: PropTypes.string,
  /**
   * Widget Children
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /**
   * Widget Footer
   */
  footer: PropTypes.arrayOf(PropTypes.element),
  /**
   * Settings Array Of Objects
   */
  settings: PropTypes.arrayOf(PropTypes.shape({})),
  childClassName: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Full row flags
   */
  fullRow: PropTypes.bool,
  draggable: PropTypes.bool,
  /**
   * Show Overflow
   */
  overflow: PropTypes.bool,
  /**
   * Show Back To Top button on scroll
   */
  backToTop: PropTypes.bool,
  /**
   * Embedded Edit Toggle
   */
  handleEditClick: PropTypes.func,
  /**
   * Add Action Toggle
   */
  handleAddClick: PropTypes.func,
  /**
   * Editing State
   */
  isEditing: PropTypes.bool,
  /**
   * Disable Editing Widget Data
   */
  disableEditing: PropTypes.bool,
  loading: PropTypes.bool,
  /**
   * Hides border if you want widget without border
   */
  hideBorder: PropTypes.bool,
  /**
   * padding
   * tailwind class number
   * x = y + 2
   */
  padding: PropTypes.number,
};

Widget.defaultProps = {
  key: undefined,
  className: undefined,
  width: "full",
  title: "[Widget Title]",
  children: undefined,
  footer: undefined,
  settings: undefined,
  childClassName: undefined,
  count: undefined,
  fullRow: false,
  draggable: true,
  overflow: false,
  handleEditClick: undefined,
  handleAddClick: undefined,
  isEditing: false,
  disableEditing: false,
  loading: false,
  hideBorder: false,
  padding: undefined,
  backToTop: undefined,
};

export default Widget;
