import cntl from "cntl";
import { isEmpty } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../../stories/Components/Buttons/PrimaryButton";
import SaveButton from "../../../../../stories/Components/Buttons/SaveButton";
import Chevron from "../../../../../stories/Components/Chevron/Chevron";
import CommentButton from "../../../../../stories/Components/CommentButton";
import historyIcon from "../../../../../stories/assets/images/historyIcon.svg";
import historyIconDisabled from "../../../../../stories/assets/images/historyIconDisabled.svg";
import thisWeekIcon from "../../../../../stories/assets/images/tsThisWeekIcon.svg";
import useTimesheetControlData from "./useTimesheetControlData";

const wrapperCN = cntl`
  flex
  flex-col
  pl-2
`;

const TimesheetControl = ({
  isLoading,
  handleCommentClick,
  changesDetected,
  resetTimesheet,
  timesheet,
  isApprover,
  saveTimesheet,
  canCreateTimesheet,
  createNewTimesheet,
  handelTimesheetSubmit,
  handleApproveTimesheet,
  handleRejectTimesheet,
}) => {
  const {
    formattedDateText,
    forwardBtnDisabled,
    dropDownActionsForSubmitted,
    prevBtnDisabled,
    timesheetHistoryPath,
    handlePrevWeekClick,
    handleNextWeekClick,
    handleCurrentWeekClick,
  } = useTimesheetControlData({
    timesheet,
    handleApproveTimesheet,
    handleRejectTimesheet,
  });

  return (
    <div className={wrapperCN}>
      <div className="flex flex-row justify-between w-full">
        <div className="flex gap-3">
          <Link
            to={timesheetHistoryPath}
            aria-label="History Button"
            type="button"
            className="flex pr-2 items-center"
            disabled={!timesheet?.resource}
          >
            {!timesheet?.resource ? (
              <img
                src={historyIconDisabled}
                className="h-5.5 w-5.5"
                alt="history-button"
              />
            ) : (
              <img
                src={historyIcon}
                className="h-5.5 w-5.5"
                alt="history-button"
              />
            )}
          </Link>

          <button
            type="button"
            onClick={handlePrevWeekClick}
            className="select-none cursor-pointer flex items-center h-full"
            tabIndex="0"
            aria-label="Previous button"
            disabled={prevBtnDisabled}
          >
            <Chevron
              color={prevBtnDisabled ? "#b6b6b6" : "#191919"}
              className="h-4 transform rotate-180"
            />
          </button>

          <button
            type="button"
            onClick={handleNextWeekClick}
            className="select-none cursor-pointer flex items-center h-full"
            tabIndex="0"
            aria-label="Next button"
            disabled={forwardBtnDisabled}
          >
            <Chevron
              color={forwardBtnDisabled ? "#b6b6b6" : "#191919"}
              className="h-4"
            />
          </button>
          <div
            className="flex h-full items-center whitespace-nowrap"
            style={{
              maxWidth: "180px",
              minWidth: "180px",
            }}
          >
            <h3 className="text-xl text-gray-600 font-semibold">
              {formattedDateText}
            </h3>
          </div>

          <button onClick={handleCurrentWeekClick} type="button">
            <img src={thisWeekIcon} alt="this-week-icon" />
          </button>
        </div>
        {isLoading ? (
          <h3 className="w-10 text-center relative">
            <span className="inline-block loadingInline" />
          </h3>
        ) : (
          <div className="flex flex-row items-center gap-2">
            {changesDetected && (
              <>
                <PrimaryButton
                  addButton
                  buttonTitle="Undo"
                  onClick={resetTimesheet}
                  className="flex h-full"
                />
                {!isEmpty(timesheet) && <SaveButton onClick={saveTimesheet} />}
              </>
            )}
            {!changesDetected &&
              !isEmpty(timesheet) &&
              timesheet?.resource?.status === "open" && (
                <PrimaryButton
                  addButton
                  buttonTitle="Submit"
                  onClick={handelTimesheetSubmit}
                />
              )}
            {!isEmpty(timesheet) &&
              timesheet?.resource?.status === "rejected" && (
                <PrimaryButton
                  addButton
                  buttonTitle="Submit"
                  onClick={handelTimesheetSubmit}
                />
              )}
            {!isEmpty(timesheet) &&
              !changesDetected &&
              timesheet?.resource?.status === "submitted" &&
              isApprover && (
                <PrimaryButton
                  title="Actions"
                  dropdownItems={dropDownActionsForSubmitted}
                />
              )}
            {isEmpty(timesheet) && canCreateTimesheet && (
              <PrimaryButton
                addButton
                buttonTitle="Add Timesheet"
                onClick={createNewTimesheet}
              />
            )}

            {!isEmpty(timesheet) && (
              <CommentButton onClick={handleCommentClick} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimesheetControl;
