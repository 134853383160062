import React from "react";
import PropTypes from "prop-types";
import MediaWidgetBody from "./MediaWidgetBody";
import MediaWidgetHeader from "./MediaWidgetHeader";

const PureMediaWidget = ({
  images,
  primary,
  addImage,
  resource,
  selected,
  selectImage,
  deselectImage,
  deselectAll,
  openImageDialog,
  handleAddResolvedMedia,
  handleOnFinishUpload,
  actionPerforming,
  disableEditing,
  modal,
  showHeader,
  isSop,
  isContact,
  sopClassName,
  sopStyle,
  sopMediaStyle,
  border,
  handleDownloadSelected,
  loadingMedia,
}) => {
  return (
    <div
      className={`${
        sopClassName ||
        `flex flex-col ${!modal && ""} ${
          border ? "border border-gray-200" : ""
        } rounded w-full p-5 ${loadingMedia && "loading"}`
      }`}
    >
      {showHeader && (
        <MediaWidgetHeader
          selected={selected}
          deselectAll={deselectAll}
          openImageDialog={openImageDialog}
          disableEditing={disableEditing}
          isSop={isSop}
          isContact={isContact}
          modal={modal}
          handleDownloadSelected={handleDownloadSelected}
        />
      )}
      <div className={`flex ${!modal && "rounded-lg"}`}>
        <MediaWidgetBody
          images={images}
          primary={primary}
          addImage={addImage}
          resource={resource}
          selected={selected}
          selectImage={selectImage}
          deselectImage={deselectImage}
          compileResolved={handleAddResolvedMedia}
          handleOnFinishUpload={handleOnFinishUpload}
          actionPerforming={actionPerforming}
          disableEditing={disableEditing}
          isContact={isContact}
          isSop={isSop}
          sopStyle={sopStyle}
          sopMediaStyle={sopMediaStyle}
        />
      </div>
    </div>
  );
};

PureMediaWidget.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      isPrimary: PropTypes.bool,
    })
  ),
  primary: PropTypes.shape({}),
  addImage: PropTypes.func,
  resource: PropTypes.shape({}),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  selectImage: PropTypes.func,
  deselectImage: PropTypes.func,
  deselectAll: PropTypes.func,
  openImageDialog: PropTypes.func,
  handleAddResolvedMedia: PropTypes.func,
  handleOnFinishUpload: PropTypes.func,
  actionPerforming: PropTypes.bool,
  disableEditing: PropTypes.bool,
  modal: PropTypes.bool,
  showHeader: PropTypes.bool,
  isSop: PropTypes.bool,
  isContact: PropTypes.bool,
  sopClassName: PropTypes.string,
  sopStyle: PropTypes.shape({}),
  sopMediaStyle: PropTypes.shape({}),
  border: PropTypes.bool,
  loadingMedia: PropTypes.bool,
  handleDownloadSelected: PropTypes.func,
};

PureMediaWidget.defaultProps = {
  images: undefined,
  primary: undefined,
  addImage: undefined,
  resource: undefined,
  selected: undefined,
  selectImage: undefined,
  deselectImage: undefined,
  deselectAll: undefined,
  openImageDialog: undefined,
  handleAddResolvedMedia: undefined,
  handleOnFinishUpload: undefined,
  handleDownloadSelected: undefined,
  actionPerforming: false,
  disableEditing: false,
  modal: false,
  showHeader: true,
  isSop: false,
  isContact: false,
  loadingMedia: false,
  sopClassName: undefined,
  sopStyle: undefined,
  sopMediaStyle: undefined,
  border: true,
};

export default PureMediaWidget;
