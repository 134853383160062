// Framework Tools
import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

// hooks
import useRequestFormReducer from "../../../../../hooks/useRequestFormReducer";
import { useAppState } from "../../../../../state/appState";
import { usePostSubmittal } from "../../../../../hooks/useSubmittal.new";
import useManagementConfiguration from "../../../../../hooks/useManagementConfiguration";

// constants
import { SUBMITTAL_EMPTY } from "../../../../../constants";
import { getFullName } from "../../../../../helpers/Formatters";

const useSubmittalsCreateModal = ({ modalData }) => {
  const disableAssociation = modalData?.item?.disableAssociation;
  const association = modalData?.item?.association;

  /* Hooks */
  const [{ projectDD, userDict, projectDict }] = useAppState([]);
  const { data: managementConfiguration } = useManagementConfiguration();
  /* tracking request form for submittal post call */
  const [requestForm, requestFormDispatch] = useRequestFormReducer();
  /* create submittal hook for posting to submittal api */
  const { mutate: createSubmittal, isLoading: isSaving } = usePostSubmittal();

  /* state controls */
  const [distroSelectOpen, setDistroSelectOpen] = useState(false);
  const [distroSelectOptions, setDistroSelectOptions] = useState([]);
  const [project, setProject] = useState({});
  const [submittalTypes, setSubmittalTypes] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const initialTask = useMemo(
    () => ({
      ...SUBMITTAL_EMPTY,
      association: association || project?.reference,
      startDate: {
        actual: requestForm?.startDate?.actual,
      },
    }),
    [association, project?.reference, requestForm?.startDate?.actual]
  );

  useEffect(() => {
    const isSame = isEqual(initialTask, requestForm);
    setShowConfirm(!isSame);
  }, [setShowConfirm, initialTask, requestForm]);

  /* when creating a submittal from a project, set the association */
  useEffect(() => {
    if (association)
      requestFormDispatch({
        type: "association",
        payload: association,
      });
  }, [requestFormDispatch, association]);

  /* all fields are required before save is enabled */
  useEffect(() => {
    if (
      requestForm?.customName &&
      requestForm?.association &&
      requestForm?.startDate?.actual &&
      requestForm?.type
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    requestForm?.association,
    requestForm?.customName,
    requestForm?.distribution?.length,
    requestForm.impacts.budget,
    requestForm.impacts.schedule,
    requestForm?.startDate,
    requestForm?.type,
    association,
  ]);

  /* sets the current project after userDictionary, and project dictionary are loaded */
  useEffect(() => {
    if (userDict && projectDict && requestForm.association) {
      const currentProject = projectDict[requestForm.association];
      setProject(currentProject);
    }
  }, [projectDict, requestForm.association, userDict]);

  /* sets the distroselectoptions formated into {label: value:} */
  useEffect(() => {
    const options = project?.members?.map((mem) => {
      const member = userDict?.[mem?.user];
      const fullName = getFullName(member?.name);
      const ref = member?.reference;
      return { ...member, label: fullName, value: ref };
    });
    setDistroSelectOptions(options);
  }, [project?.members, userDict]);

  /* sets submittal types */
  useEffect(() => {
    const types = managementConfiguration?.management?.submittal?.types;
    const dropdownTypes = types?.map((type) => {
      return { label: type?.display, value: type?.id };
    });
    setSubmittalTypes(dropdownTypes);
  }, [managementConfiguration?.management?.submittal?.types]);

  /* when a form value changes dispatches to reducer by key and value */
  const handleChange = (key, value) => {
    return requestFormDispatch({
      type: key,
      payload: value,
    });
  };

  /* closes create modal */
  const handleModalClose = () => {
    requestFormDispatch({ type: "reset" });
  };

  const handleDeleteFromDistro = (ref) => {
    requestFormDispatch({ type: "removeDistroMember", payload: ref });
  };
  const resetDistroList = () => {
    requestFormDispatch({ type: "resetDistroList", payload: [] });
  };

  const handlePostSubmittal = async () => {
    createSubmittal(requestForm);
    handleModalClose();
  };

  return {
    requestFormDispatch,
    handleModalClose,
    handlePostSubmittal,
    disableSave,
    isSaving,
    showConfirm,
    requestForm,
    handleChange,
    projectDD,
    projectDict,
    userDict,
    resetDistroList,
    submittalTypes,
    handleDeleteFromDistro,
    setDistroSelectOpen,
    distroSelectOpen,
    distroSelectOptions,
    disableAssociation,
    association,
  };
};

export default useSubmittalsCreateModal;
