import React from "react";
import CommentIcon from "../../assets/images/commentGreenIcon.svg";
import IconButton from "../Buttons/IconButton";

const CommentButton = ({ onClick, style, commentClassName }) => {
  return (
    <button
      style={style}
      type="button"
      onClick={onClick}
      className={`flex flex-row items-center rounded-lg px-2 bg-backgroundGreen mr-4 py-2 ${commentClassName}`}
    >
      <IconButton
        imgClassName="w-4 h-5"
        iconClassName="w-4 h-5"
        icon={CommentIcon}
      />
      <p className="text-xs font-semibold text-darkenedGreen pl-1">Comment</p>
    </button>
  );
};

export default CommentButton;
