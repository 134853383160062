import React, { useState, useEffect, useCallback, useMemo } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import { UserAPI } from "@griffingroupglobal/eslib-api";
import { useUsers } from "../../../hooks/useUsers.new";
import PureMemberTable from "./PureMemberTable";
import useSubscription from "../../../hooks/useSubscription";
import link from "../../assets/images/linkGreen.svg";
import BaseButton from "../Buttons/BaseButton";
import {
  ADD_OPEN_MODAL,
  ADMIN_BILLING_PATH,
  PTO_CATEGORY,
  REMOVE_USER,
  USER_MANAGEMENT,
} from "../../../constants";
import ChangeRoleModal from "./ChangeRoleModal";
import ChangeActiveStatusModal from "./ChangeActiveStatusModal";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useRoles from "../../../hooks/useRoles";
import ReinviteUserModal from "../UserModal/ReinviteUserModal";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import ChangeStateOfEmploymentModal from "./ChangeStateOfEmploymentModal";
import ChangeEmploymentTypeModal from "./ChangeEmploymentTypeModal";
import { toastError } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import ChangeDirectReportsModal from "./ChangeDirectReportsModal";
import useDirectReports from "../../../hooks/useDirectReports";
import ChangeEmployeeIdModal from "./ChangeEmployeeIdModal";
import useUserPatch from "../../../hooks/useUserPatch";
import useUserDelete from "../../../hooks/useUserDelete";
import { useModalState } from "../../../state/modalState";
import handleDownloadUsers from "../../../helpers/Users/handleDownloadusers";
import { findMobileValue } from "../../../helpers/Formatters";
import { useAppState } from "../../../state/appState";
import ChangeEmployeeHireDateModal from "./ChangeEmployeeHireDateModal";
import UserSeatsWarning from "../Warning/UserSeatsWarning/UserSeatsWarning";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const menuButtonCN = (disabled) => cntl`
  w-64
  ${disabled && "opacity-50"}
 `;

const primaryHeaderCN = cntl`
  text-xl
  font-medium
  uppercase
  pb-2
`;

const MemberTable = ({ isExportingMemberList, setIsExportingMemberList }) => {
  const { data, isLoading } = useUsers();
  const users = data?.users;
  const userDict = data?.userDict;

  const [allRoles] = useRoles();
  const [, directReportOptions] = useDirectReports();
  const history = useHistory();

  const { data: currentUser } = useCurrentUser();
  const [originalSubscription] = useSubscription();
  const { data: systemConfiguration } = useSystemConfiguration();

  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [changeType, setChangeType] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [states, setStates] = useState([]);
  const [ptoCategory, setPtoCategory] = useState();
  const [availableSeats, setAvailableSeats] = useState();

  const { mutateAsync: patchUser } = useUserPatch();
  const { mutate: deleteUser } = useUserDelete();
  const [, modalDispatch] = useModalState();

  const [{ financialsConfiguration }] = useAppState();

  const isLocked = useMemo(() => {
    return (
      financialsConfiguration?.financials?.payroll?.locked ||
      financialsConfiguration?.financials?.period?.locked
    );
  }, [financialsConfiguration]);

  // Dictionaries of company names
  const companyContacts = useMemo(() => {
    if (users?.length) {
      return users.reduce((acc, current) => {
        if (current?.kind === "company") {
          acc[current?.reference] = current?.company?.value;
        }

        return acc;
      }, {});
    }

    return [];
  }, [users]);

  // Members list
  const members = useMemo(() => {
    if (users?.length) {
      return users
        .filter((user) => user?.kind === "member")
        .map((member) => {
          const company = companyContacts[member?.company?.value];

          const stateOfEmployment = states?.find(
            (state) => state.value === member?.employeeInfo?.pto?.locationId
          )?.label;

          const hideRowAssociationMenuList =
            (currentUser?.id === member?.id || member?.isOwner) &&
            !currentUser?.isAdmin;

          return {
            ...member,
            company,
            stateOfEmployment,
            employeeId: member?.employeeInfo?.id,
            hireDate: member?.employeeInfo?.hireDate,
            hideRowAssociationMenuList,
            isCurentUser: currentUser?.id === member?.id,
            fullName: `${member?.name?.firstName} ${member?.name?.lastName}`,
            contactPointValue: findMobileValue(member?.contactPoint),
          };
        });
    }

    return [];
  }, [users, states, currentUser, companyContacts]);

  useEffect(() => {
    if (originalSubscription) {
      const seats = originalSubscription?.userCount;
      setAvailableSeats(
        seats > activeUsersCount ? seats - activeUsersCount : 0
      );
    }
  }, [activeUsersCount, originalSubscription]);

  useEffect(() => {
    setPtoCategory(systemConfiguration?.system?.pto?.category);
    if (systemConfiguration?.system?.pto?.locations?.length) {
      setStates(
        systemConfiguration?.system?.pto?.locations?.map((loc) => ({
          label: loc.location,
          value: loc.id,
        }))
      );
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(
        allRoles.map((role) => ({
          label: role.name,
          value: role.reference,
        }))
      );
    }
  }, [allRoles]);

  useEffect(() => {
    if (isExportingMemberList) {
      const formattedMembers = members.map((member) => {
        let Mobile;
        member?.contactPoint?.map((cp) => {
          if (cp?.system === "Phone") {
            Mobile = cp?.value;
          }
          return cp;
        });

        return {
          ...member,
          Company: member?.company,
          Name: `${member?.name?.firstName} ${member?.name?.lastName}`,
          Status: member?.active ? "Active" : "Inactive",
          Mobile,
          Email: member?.email,
          Role: member?.role,
        };
      });

      handleDownloadUsers(formattedMembers, "Member List");

      setIsExportingMemberList(false);
    }
  }, [isExportingMemberList, members, setIsExportingMemberList]);

  useEffect(() => {
    let count = 0;
    if (members?.length) {
      members?.forEach((member) => {
        if (member?.active) {
          count += 1;
        }
      });
    }
    setActiveUsersCount(count);
  }, [members]);

  const navigate = useCallback(() => {
    history.push(ADMIN_BILLING_PATH);
  }, [history]);

  const clear = useCallback(() => {
    setChangeType(null);
    setSelectedUser(null);
  }, []);

  const onRemoveUser = useCallback(
    async (userToRemove = []) => {
      // RemoveUserModal returns an array of users, however
      // Members Table allow to delete a single user per batch
      const user = userToRemove[0];

      if (!user) {
        toastError(
          "There are no users selected to remove",
          toastErrorIcon,
          toastCloseIcon
        );

        return;
      }

      // If user is active, it needs to be deactivated then removed
      if (user?.active) {
        setIsSaving(true);

        const updatedUser = {
          ...user,
          active: false,
        };

        await patchUser({
          oldUserInfo: user,
          newUserInfo: updatedUser,
        })
          .then(() => {
            deleteUser([user?.reference]);
          })
          .finally(() => {
            setIsSaving(false);
          });
      } else {
        deleteUser([user?.reference]);
      }
    },
    [deleteUser, patchUser]
  );

  const rowAssociationMenuList = useCallback(
    (row) => {
      /**
       * Temporary locking of state changing
       * Will be re-enabled once backend has solved this issue
       */
      const lockChangingOfStateLocation =
        row.original.registered && row.original.stateOfEmployment && isLocked;

      return [
        {
          title: "Change Role",
          className: menuButtonCN(),
          onClick: (rowId) => {
            setChangeType("role");
            setSelectedUser(members?.find((user) => user?.id === rowId));
          },
        },
        {
          title: "Change Direct Reports",
          className: menuButtonCN(),
          onClick: (rowId) => {
            setChangeType("directReports");
            setSelectedUser(members?.find((user) => user?.id === rowId));
          },
          hideForNonEmployees: true,
        },
        ...(!lockChangingOfStateLocation
          ? [
              {
                title: "Change Employment Type",
                className: menuButtonCN(),
                onClick: (rowId) => {
                  setChangeType("employmentType");
                  setSelectedUser(members?.find((user) => user?.id === rowId));
                },
              },
            ]
          : []),
        {
          title: "Change Employee ID",
          className: menuButtonCN(),
          onClick: (rowId) => {
            setChangeType("employeeId");
            setSelectedUser(members?.find((user) => user?.id === rowId));
          },
          hideForNonEmployees: true,
        },
        {
          title: "Change Hire Date",
          className: menuButtonCN(),
          onClick: (rowId) => {
            setChangeType("hireDate");
            setSelectedUser(members?.find((user) => user?.id === rowId));
          },
          hideForNonEmployees: true,
        },
        ...(ptoCategory === PTO_CATEGORY.location &&
        !lockChangingOfStateLocation
          ? [
              {
                title: "Change State Of Employment",
                className: menuButtonCN(),
                onClick: (rowId) => {
                  const selectedUserFromRow = members?.find(
                    (user) => user?.id === rowId
                  );

                  if (selectedUserFromRow?.stateOfEmployment !== undefined) {
                    toastError(
                      "State of employment can only be changed once. Please contact support for further assistance",
                      toastErrorIcon,
                      toastCloseIcon,
                      1000
                    );
                    return;
                  }

                  setChangeType("stateOfEmployment");
                  setSelectedUser(selectedUserFromRow);
                },
                hideForNonEmployees: true,
              },
            ]
          : []),
        {
          useDynamicTitleForActiveUserStatus: true,
          className: menuButtonCN(!availableSeats),
          onClick: (rowId) => {
            const member = members?.find((user) => user?.id === rowId);
            // can toggle active/inactive if seats are available
            if (availableSeats) {
              setChangeType("active");
              setSelectedUser(member);
              // if no seats available, then can only deactivate
            } else if (!availableSeats && member?.active) {
              setChangeType("active");
              setSelectedUser(member);
            }
          },
          hideForCurrentUser: true,
        },
        {
          title: "Delete",
          className: menuButtonCN(),
          onClick: (rowId) => {
            const selectedMember = [
              members?.find((user) => user?.id === rowId),
            ];
            const id = uuidv4();
            modalDispatch({
              type: ADD_OPEN_MODAL,
              position: {
                centered: true,
              },
              ref: { id },
              modalData: {
                id,
                item: {
                  id,
                  removeFrom: USER_MANAGEMENT,
                  selectedUsers: selectedMember,
                  onSave: onRemoveUser,
                },
              },
              modalType: REMOVE_USER,
            });
          },
          hideForCurrentUser: true,
        },
        {
          title: "Reinvite",
          className: menuButtonCN(),
          onClick: (rowId) => {
            setChangeType("reinvite");
            setSelectedUser(members?.find((user) => user?.id === rowId));
          },
          hideForRegisteredUser: true,
        },
      ];
    },
    [
      isLocked,
      ptoCategory,
      members,
      availableSeats,
      modalDispatch,
      onRemoveUser,
    ]
  );

  const onChangeRole = useCallback((value) => {
    setSelectedUser((prev) => ({
      ...prev,
      role: value?.value,
    }));
  }, []);

  const onChangeUserEmploymentType = useCallback((value) => {
    setSelectedUser((prev) => ({
      ...prev,
      isEmployee: value,
    }));
  }, []);

  const onChangeUserStateOfEmployment = useCallback((value) => {
    setSelectedUser((prev) => ({
      ...prev,
      employeeInfo: {
        ...prev.employeeInfo,
        pto: {
          ...prev.pto,
          category: value?.value ? PTO_CATEGORY.location : PTO_CATEGORY.general,
          locationId: value?.value,
        },
      },
    }));
  }, []);

  const onChangeUserEmployeeId = useCallback((value) => {
    setSelectedUser((prev) => ({
      ...prev,
      employeeInfo: {
        ...prev.employeeInfo,
        id: value,
      },
    }));
  }, []);

  const onChangeUserHireDate = useCallback((value) => {
    const hireDate = new Date(
      Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate())
    );

    setSelectedUser((prev) => ({
      ...prev,
      employeeInfo: {
        ...prev.employeeInfo,
        hireDate,
      },
    }));
  }, []);

  const onSaveUserStateOfEmployment = useCallback(
    async (memberId) => {
      setIsSaving(true);

      try {
        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          role: selectedUser?.role,
          employeeInfo: selectedUser?.employeeInfo,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, selectedUser?.employeeInfo, selectedUser?.role, users]
  );

  const onSaveUserDirectReports = useCallback(
    async (memberId, directReport) => {
      setIsSaving(true);

      try {
        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          directReport,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, users]
  );

  const onSaveUserEmploymentType = useCallback(
    async (memberId) => {
      setIsSaving(true);

      try {
        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          role: selectedUser?.role,
          employeeInfo: selectedUser?.employeeInfo,
          isEmployee: selectedUser?.isEmployee,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [
      clear,
      patchUser,
      selectedUser?.employeeInfo,
      selectedUser?.isEmployee,
      selectedUser?.role,
      users,
    ]
  );

  const onSaveUserEmploymentId = useCallback(
    async (memberId) => {
      setIsSaving(true);

      try {
        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          employeeInfo: selectedUser?.employeeInfo,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, selectedUser?.employeeInfo, users]
  );
  const onSaveUserHireDate = useCallback(
    async (memberId) => {
      setIsSaving(true);

      try {
        const hd = new Date(selectedUser?.employeeInfo?.hireDate);
        const newHd = new Date(
          Date.UTC(hd.getFullYear(), hd.getMonth(), hd.getDate())
        );

        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          employeeInfo: {
            ...selectedUser?.employeeInfo,
            hireDate: newHd,
          },
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, selectedUser?.employeeInfo, users]
  );

  const onSaveUserRole = useCallback(
    async (memberId) => {
      setIsSaving(true);

      try {
        const originalUser = userDict[`User/${memberId}`];
        const updatedUser = {
          ...originalUser,
          role: selectedUser?.role,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, selectedUser?.role, userDict]
  );

  const onSaveUserStatus = useCallback(
    async (memberId) => {
      setIsSaving(true);
      try {
        const originalUser = users.find((user) => user.id === memberId);
        const updatedUser = {
          ...originalUser,
          active: !originalUser.active,
        };

        await patchUser({
          oldUserInfo: originalUser,
          newUserInfo: updatedUser,
        });

        clear();
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    },
    [clear, patchUser, users]
  );

  const onReinviteUser = useCallback(
    async (memberId) => {
      setIsSaving(true);
      try {
        await UserAPI.getWOP(`${memberId}/$resendinvitation`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Resend invitation failed ", err);
      }

      setIsSaving(false);
      clear();
    },
    [clear]
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className={primaryHeaderCN}>Members</h1>
          {!!activeUsersCount && !!originalSubscription?.userCount && (
            <div className="flex flex-row">
              <p className="font-semibold">
                {activeUsersCount}/{originalSubscription?.userCount}
              </p>
              <p className="px-2">user seats occupied</p>
              <BaseButton
                iconLeft={<img src={link} alt="link" className="w-5 h-5" />}
                className="pt-1 pl-4"
                onClick={navigate}
              />
            </div>
          )}
        </div>
        {availableSeats === 0 && <UserSeatsWarning />}
      </div>
      <PureMemberTable
        data={members}
        rowAssociationMenuList={(row) => rowAssociationMenuList(row)}
        ptoCategory={ptoCategory}
        isLoading={isLoading}
      />
      {changeType === "role" && (
        <ChangeRoleModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserRole}
          changeUserRole={onChangeRole}
          isSaving={isSaving}
          roles={roles}
        />
      )}
      {changeType === "stateOfEmployment" && (
        <ChangeStateOfEmploymentModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserStateOfEmployment}
          changeUserStateOfEmployment={onChangeUserStateOfEmployment}
          isSaving={isSaving}
          states={states}
          isPtoGeneral={ptoCategory === PTO_CATEGORY.general}
        />
      )}
      {changeType === "directReports" && (
        <ChangeDirectReportsModal
          directReportOptions={directReportOptions}
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserDirectReports}
          isSaving={isSaving}
        />
      )}
      {changeType === "employmentType" && (
        <ChangeEmploymentTypeModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserEmploymentType}
          changeUserStateOfEmployment={onChangeUserStateOfEmployment}
          onChangeUserEmploymentType={onChangeUserEmploymentType}
          isSaving={isSaving}
          states={states}
          ptoCategory={ptoCategory}
        />
      )}
      {changeType === "employeeId" && (
        <ChangeEmployeeIdModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserEmploymentId}
          onChangeEmployeeId={onChangeUserEmployeeId}
          isSaving={isSaving}
        />
      )}
      {changeType === "hireDate" && (
        <ChangeEmployeeHireDateModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserHireDate}
          onChangeUserHireDate={onChangeUserHireDate}
          isSaving={isSaving}
        />
      )}
      {changeType === "active" && (
        <ChangeActiveStatusModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserStatus}
          isSaving={isSaving}
          roles={roles}
        />
      )}
      {changeType === "reinvite" && (
        <ReinviteUserModal
          user={selectedUser}
          onCancel={clear}
          onSave={onReinviteUser}
          isSaving={isSaving}
        />
      )}
    </div>
  );
};

MemberTable.propTypes = {
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

MemberTable.defaultProps = {
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
};

export default MemberTable;
