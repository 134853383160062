/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ScheduleBody from "./Schedule/ScheduleBody";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../state/appState";

const DEFAULT_PAGE_SIZE = 40;

const ScheduleCalendar = ({ now, events, handleEventClick }) => {
  const [{ calendarTimezone }] = useAppState();
  const { eventFilter } = useRelativeAssociations();
  const [scheduleDays, setScheduleDays] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  /**
   * @mixes - allDay & brief event arrays for the day
   */

  const getScheduleDays = useCallback(
    (limit) => {
      const daysArray = [];

      for (let i = 0; i < limit; i += 1) {
        const day = moment
          .tz(now, calendarTimezone)
          .add(i, "days")
          .startOf("day")
          .format();
        daysArray.push(day);
      }

      setScheduleDays(daysArray);
      return daysArray;
    },
    [now, calendarTimezone]
  );

  useEffect(() => {
    const daysArray = [];

    for (let i = 0; i < DEFAULT_PAGE_SIZE; i += 1) {
      const day = moment
        .tz(now, calendarTimezone)
        .add(i, "days")
        .startOf("day")
        .format();
      daysArray.push(day);
    }

    setPageSize(DEFAULT_PAGE_SIZE);
    setScheduleDays(daysArray);
  }, [now, calendarTimezone]);

  const loadMore = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const newPageSize = pageSize + DEFAULT_PAGE_SIZE;
          setPageSize(newPageSize);
          getScheduleDays(newPageSize);
        }
      });
    },
    [getScheduleDays, pageSize]
  );

  const loadMoreTarget = document.querySelector("#load-more");

  useEffect(() => {
    const options = {
      root: document.querySelector("#schedule-calendar"),
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(loadMore, options);
    if (loadMoreTarget) observer.observe(loadMoreTarget);
  }, [loadMore, loadMoreTarget]);

  return (
    <div
      style={{ maxHeight: "1000px" }}
      className="flex-col w-full overflow-y-scroll pr-4"
      id="schedule-calendar"
    >
      {scheduleDays?.map((day, i) => {
        const { allDay, brief } = eventFilter(events?.[day]) ?? {
          allDay: [],
          brief: [],
        };

        /**
         * @mixes - allDay & brief event arrays for the day
         */
        const collapsed = [...allDay, ...brief];

        return (
          <ScheduleBody
            key={day}
            collapsed={collapsed}
            dayValue={day}
            dateStart={i === 0}
            handleEventClick={handleEventClick}
            pageSize={pageSize}
            count={i}
          />
        );
      })}
    </div>
  );
};

ScheduleCalendar.propTypes = {
  now: PropTypes.string,
  events: PropTypes.shape({}),
  handleEventClick: PropTypes.func,
};

ScheduleCalendar.defaultProps = {
  now: undefined,
  events: {},
  handleEventClick: undefined,
};

export default ScheduleCalendar;
