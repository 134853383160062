import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import moment from "moment";
import esLogo from "../../assets/images/estatespace-logo-dark.svg";

const containerCN = cntl`
  flex
  flex-col
  container
  px-8
  py-8
  m-auto
`;

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const { STRIPE_TRIAL_PERIOD_DAYS } = window.runConfig;

const SignupComplete = ({ orderDetails }) => {
  return (
    <div className={containerCN}>
      <img alt="Estatespace Logo" src={esLogo} width="150" />
      <div className={innerCN("mb-6 mt-10")}>
        <p className="font-bold text-xl">THANK YOU</p>
      </div>
      <div className={innerCN()}>
        <p className="text-gray-400 text-sm font-normal w-2/3">
          Your order has been received and we’re working on setting up your
          EstateSpace.
        </p>
      </div>
      <div className={innerCN()}>
        <p className="text-gray-400 text-sm font-normal w-2/3">
          Once your account is ready, we’ll send you an email with details on
          how to log in.
        </p>
      </div>
      <div className={innerCN("mb-6")}>
        <p className="text-gray-400 text-sm font-normal w-2/3">
          This can take up to 1 business day.
        </p>
      </div>
      <div className={innerCN("mb-6")}>
        <p className="text-gray-400 text-sm font-normal w-2/3">
          Your credit card will not be charged for {STRIPE_TRIAL_PERIOD_DAYS}{" "}
          days.
        </p>
      </div>

      <div className="border p-6">
        <div className={innerCN()}>
          <p className="text-xl font-normal">ORDER CONFIRMATION</p>
        </div>
        <div className={innerCN("mb-8")}>
          <p className="text-base text-gray-400">
            Order Date: {moment().format("MMMM Do, YYYY")}
          </p>
        </div>
        <div className={innerCN("flex-col lg:flex-row")}>
          <div className="flex flex-col pt-4 px-4 w-full lg:w-1/2 border-b lg:border-r lg:border-b-0">
            <div className="mb-4">
              <p className="text-gray-400 font-semibold text-base">
                Plan Selection
              </p>
            </div>
            <div className="mb-">
              <p className="font-semibold text-base">
                {orderDetails.selectedPlan.planName.toUpperCase()}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-base inline-block">
                {orderDetails.userCount} users |
              </p>
              <p className="text-gray-400 text-base inline-block capitalize px-1">
                {orderDetails.frequency}
              </p>
              <p className="text-gray-400 text-base inline-block">
                ($
                {orderDetails.frequency === "monthly"
                  ? orderDetails.selectedPlan.monthlyCost
                  : orderDetails.selectedPlan.annualCost}{" "}
                per user/month)
              </p>
            </div>
            <div className="mb-4">
              <p className="text-base font-semibold">
                Total Due: ${orderDetails.total}
              </p>
            </div>
          </div>
          <div className="flex flex-col pt-4 w-full lg:w-1/2 pl-4 lg:pl-8">
            <div className={innerCN("mb-4")}>
              <p className="flex text-gray-400 font-semibold text-base">
                Contact Information
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.contactInfo.firstName}{" "}
                {orderDetails.contactInfo.lastName}
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.contactInfo.title}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-base">
                {orderDetails.contactInfo.company}
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.contactInfo.email}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-base">
                {orderDetails.contactInfo.phone}
              </p>
            </div>
          </div>
        </div>
        <div className={innerCN("flex-col lg:flex-row border-t")}>
          <div className="flex flex-col pt-4 px-4 w-full lg:w-1/2 border-b lg:border-r lg:border-b-0">
            <div className="mb-4">
              <p className="text-gray-400 font-semibold text-base">
                Credit Card Information
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-base">
                {orderDetails.billingInfo.cardType.toUpperCase()}
              </p>
            </div>
          </div>
          <div className="flex flex-col pt-4 w-full lg:w-1/2 pl-4 lg:pl-8">
            <div className={innerCN("mb-4")}>
              <p className="flex text-gray-400 font-semibold text-base">
                Billing Address
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.billingInfo.name}
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.billingInfo?.street}
                {orderDetails.billingInfo?.street && ", "}
                {orderDetails?.billingInfo?.street2}
              </p>
            </div>
            <div className="">
              <p className="text-gray-400 text-base">
                {orderDetails.billingInfo?.city}
                {orderDetails.billingInfo?.city && ", "}
                {orderDetails.billingInfo?.state} {orderDetails.billingInfo.zip}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-base">
                {orderDetails.billingInfo.country?.value}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignupComplete.propTypes = {
  orderDetails: PropTypes.shape({
    selectedPlan: PropTypes.shape({
      planName: PropTypes.string,
      userText: PropTypes.string,
      minUsers: PropTypes.number,
      maxUsers: PropTypes.number,
      monthlyCost: PropTypes.string,
      annualCost: PropTypes.string,
      discount: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      priceIdMonthly: PropTypes.string,
      priceIdAnnual: PropTypes.string,
    }),
    userCount: PropTypes.number,
    frequency: PropTypes.string,
    total: PropTypes.number,
    billingInfo: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string,
      state: {
        label: PropTypes.string,
        value: PropTypes.string,
      },
      cardType: PropTypes.string,
      street: PropTypes.string,
      street2: PropTypes.string,
      country: {
        label: PropTypes.string,
        value: PropTypes.string,
        countryCode: PropTypes.string,
      },
      zip: PropTypes.string,
    }),
    contactInfo: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
};

SignupComplete.defaultProps = {
  orderDetails: undefined,
};

export default SignupComplete;
