import React from "react";
import { isEmpty } from "lodash";
import { PRIMARY_DARK_GREEN } from "../../../../../constants";
import calculateTotalHours from "../../../../../helpers/Timesheets/calculateTotalsByDate";
import PlusCircleButton from "../../../../../stories/Components/Buttons/PlusCircleButton/PlusCircleButton";
import Dropdown from "../../../../../stories/Components/Dropdown/Dropdown";
import Input from "../../../../../stories/Components/Input/Input";
import TimeSheetTooltipCard from "../../../../../stories/Components/TimeSheetTable/TimeSheetTooltipCard";
import crossIconGreen from "../../../../../stories/assets/images/crossIconGreen.svg";
import PtoRow from "./PtoRow";
import TotalsRow from "./TotalsRow";
import useEmployeeTimesheet from "./useEmployeeTimesheet";
import IconButton from "../../../../../stories/Components/Buttons/IconButton";

const EmployeeTimesheet = ({
  isLoading,
  ptoEntryState,
  ptoTotal,
  entriesForEditing,
  timesheetEntryTotal,
  overtimeTotal,
  handleProjectChange,
  handleRateChange,
  handleCategoryChange,
  handleEntryChange,
  handleEntryNoteChange,
  handleBillableChange,
  addTimesheetRow,
  removeTimesheetRow,
  timesheetIsLocked,
}) => {
  const {
    dropDownStyles,
    toolTip,
    wrapperRef,
    projectsDD,
    csiCodesMap,
    projectsState,
    projectDict,
    financialsConfiguration,
    isBeforeHire,
    setToolTip,
    formatEntryValue,
    filterRatesByProject,
    filteredCategoriesByProjectRate,
    roundEntryToNearestQuarter,
    showActiveIcon,
  } = useEmployeeTimesheet({ handleEntryChange });

  if (isLoading)
    return (
      <h3 className="h-10 text-center relative w-10">
        <span className="inline-block loadingInline" />
      </h3>
    );

  return (
    <>
      <div className="grid grid-cols-11 px-4" ref={wrapperRef}>
        {!isEmpty(entriesForEditing) && (
          <div className="grid col-span-4 grid-cols-3 gap-3">
            {["Project", "Rate", "Categories"].map((item) => (
              <h1
                key={item}
                style={{
                  fontWeight: 600,
                  fontSize: "medium",
                  color: "#6a6a6a",
                }}
              >
                {item}
              </h1>
            ))}
          </div>
        )}
      </div>
      <div className="grid grid-cols-11 px-4" ref={wrapperRef}>
        <div className="grid col-span-4 grid-cols-3 gap-3">
          {entriesForEditing &&
            Object.entries(entriesForEditing || {})?.map(([key, values]) => {
              const projectReference = values.find(
                (value) => value.project
              )?.project;

              const financialCodes = values.find(
                (value) => value.financialCode
              )?.financialCode;

              const financialCodeString =
                financialCodes.division !== ""
                  ? `${financialCodes?.division}-${financialCodes?.code}-${financialCodes?.subcode}`
                  : "";

              const rates = values.find((value) => value.rate)?.rate;
              let ratesArr = projectsState[projectReference]?.codes?.map(
                (code) => code.rate
              );

              if (
                (ratesArr === undefined || ratesArr.length === 0) &&
                projectsState[projectReference]?.useall === false
              ) {
                ratesArr =
                  financialsConfiguration?.financials?.rateSheet?.rates;
              }

              if (projectsState[projectReference]?.useall === true) {
                ratesArr = projectDict[projectReference]?.rates;
              }

              let rateValue = rates
                ? {
                    label: ratesArr?.find((r) => r?.id === rates)?.category,
                    value: rates,
                  }
                : undefined;

              if (projectsState[projectReference]?.useall === true) {
                rateValue = ratesArr?.find((r) => r?.value === rates);
              }

              const categoryValue = financialCodeString
                ? {
                    label: csiCodesMap[financialCodeString],
                    value: financialCodeString,
                  }
                : undefined;

              const projectsValue = projectReference
                ? {
                    label: projectDict[projectReference]?.name,
                    value: projectReference,
                  }
                : undefined;

              return (
                <React.Fragment key={key}>
                  <Dropdown
                    className={dropDownStyles}
                    placeholder="Select"
                    value={projectsValue}
                    options={projectsDD}
                    onChange={(val) => handleProjectChange(val, key)}
                  />
                  <Dropdown
                    placeholder="Select"
                    className={dropDownStyles}
                    value={rateValue}
                    options={filterRatesByProject(projectReference) || []}
                    onChange={(val) => handleRateChange(val, key)}
                    isDisabled={!projectReference}
                  />
                  <Dropdown
                    placeholder="Select"
                    className={dropDownStyles}
                    value={categoryValue}
                    options={
                      filteredCategoriesByProjectRate(
                        projectReference,
                        rates
                      ) || []
                    }
                    onChange={(event) => handleCategoryChange(event, key)}
                    isDisabled={!rates}
                  />
                </React.Fragment>
              );
            })}
        </div>

        <div className="col-span-6 grid grid-cols-7 gap-4 ml-4">
          {entriesForEditing &&
            Object.entries(entriesForEditing || {}).map(([key, value]) => {
              if (!value) return null;
              const projectRef = value.find((item) => item.project)?.project;
              const rate = value.find((item) => item.rate)?.rate;
              const financialCodes = value.find(
                (item) => item.financialCode
              )?.financialCode;

              return value.map((entry) => (
                <div
                  className="col-span-1 text-gray-700 font-light"
                  key={entry?.id}
                >
                  <div
                    className="rounded-lg"
                    style={{
                      maxWidth: "78px",
                    }}
                  >
                    <Input
                      disabled={
                        !projectRef ||
                        !rate ||
                        !financialCodes.division ||
                        isBeforeHire(entry?.date)
                      }
                      key={entry?.id}
                      value={
                        isBeforeHire(entry?.date)
                          ? ""
                          : formatEntryValue(entry.value)
                      }
                      onBlur={() => roundEntryToNearestQuarter(entry, key)}
                      onChange={(val) => handleEntryChange(val, entry, key)}
                      className="text-gray-200 font-normal w-full rounded-xl"
                      activeIcon={showActiveIcon(entry)}
                      onFocus={() => setToolTip({ open: true, id: entry?.id })}
                      inputContainerClassName="rounded-lg"
                      title={entry?.date}
                      disableClear
                      tooltipElement={
                        toolTip.open &&
                        toolTip.id === entry?.id && (
                          <TimeSheetTooltipCard
                            date={entry?.date}
                            note={entry?.note}
                            isBillable={entry?.isBillable}
                            onChangeBillable={(val) => {
                              handleBillableChange(!val, entry, key);
                            }}
                            onChangeNote={(val) =>
                              handleEntryNoteChange(val, entry, key)
                            }
                            disabled={false}
                            onClose={() =>
                              setToolTip({ open: false, id: null })
                            }
                          />
                        )
                      }
                    />
                  </div>
                </div>
              ));
            })}
        </div>

        <div className="text-gray-700 font-light flex flex-col">
          {!timesheetIsLocked &&
            Object.entries(entriesForEditing || {}).map(([key, value]) => {
              return (
                <div
                  key={key}
                  className="flex h-full justify-between text-gray-700 font-light items-center"
                >
                  <h2 className="text-lg text-gray-450 font-semibold">
                    {calculateTotalHours(value)} {"  "}
                    <span className="text-gray-300 font-normal">hrs</span>
                  </h2>

                  <IconButton
                    className="cursor-pointer p-2"
                    icon={crossIconGreen}
                    onClick={() => removeTimesheetRow(key)}
                  />
                </div>
              );
            })}
        </div>

        <div className="col-span-11">
          {!timesheetIsLocked && (
            <PlusCircleButton
              title="Add Project"
              className="mt-4 mb-1"
              onClick={() => addTimesheetRow()}
              style={{ color: PRIMARY_DARK_GREEN }}
            />
          )}
        </div>

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <TotalsRow entriesForEditing={entriesForEditing} />

        {timesheetEntryTotal === 0 ? (
          <p className="col-start-11 text-gray-450 font-semibold">-- </p>
        ) : (
          <p className="col-start-11 text-gray-450 font-semibold">
            {timesheetEntryTotal} hrs
          </p>
        )}

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <PtoRow
          entriesForEditing={entriesForEditing}
          ptoEntryState={ptoEntryState}
        />

        {ptoTotal === 0 ? (
          <p className="col-start-11 text-gray-450 font-semibold">-- </p>
        ) : (
          <p className="col-start-11 text-gray-450 font-semibold">
            {ptoTotal} hrs
          </p>
        )}

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <div className="col-start-9 mt-10">
          <h2 className="text-lg text-gray-450" style={{ width: "200px" }}>
            Overtime Hours
          </h2>
          <h2 className="text-lg text-gray-450" style={{ width: "200px" }}>
            Time Off
          </h2>
          <h2
            className="text-lg text-gray-450 font-semibold"
            style={{ width: "200px" }}
          >
            Total Hours
          </h2>
        </div>

        <div className="col-start-11 mt-10">
          <h2 className="text-lg text-gray-450">{overtimeTotal} hrs</h2>
          <h2 className="text-lg text-gray-450">{ptoTotal} hrs</h2>
          <h2 className="text-lg text-gray-450 font-semibold">
            {" "}
            {timesheetEntryTotal + ptoTotal} hrs
          </h2>
        </div>
      </div>
    </>
  );
};

export default EmployeeTimesheet;
