import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContactInfoSubComponents from "./ContactInfoSubComponents";
import { useAppState } from "../../../state/appState";
import Widget from "../Widget/Widget";
import ContactProfileInfo from "./ContactProfileInfo";
import ContactAddressWidget from "./ContactAddressWidget";
import ContactAssigneesForm from "./ContactAssigneesForm";

const ContactInfo = ({
  contactInfo,
  contactDetails,
  setContactDetails,
  setEditingContact,
  setActiveIndex,
  editingContact,
  loading,
  setContactInfo,
  saving,
  companyList,
  reloadCompanies,
  managementConfiguration,
  setNewDisciplines,
  contactUser,
  contactResource,
  contactDispatch,
  onBoarding,
  companyAssignees,
  setCompanyAssignees,
  setUnassignedContacts,
  unassignedContacts,
  handleNavigate,
}) => {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [{ currentUser }] = useAppState();

  const hasEditPermissions =
    currentUser?.isAdmin ||
    currentUser?.isSuperAdmin ||
    currentUser?.hasPermission?.("contacts", "can_delete");

  useEffect(() => {
    if (contactInfo && contactDetails) {
      setLoadingDetails(false);
    }
  }, [contactDetails, contactInfo]);

  return (
    <>
      <Widget
        title="Contact Information"
        draggable={false}
        isEditing={editingContact}
        disableEditing={!hasEditPermissions}
        className="mb-4"
        loading={loading || loadingDetails}
        overflow
      >
        <ContactInfoSubComponents
          setContactInfo={setContactInfo}
          setEditingContact={setEditingContact}
          editingContact={editingContact}
          contactInfo={contactInfo}
          setActiveIndex={setActiveIndex}
          hasEditPermissions={hasEditPermissions}
          loading={loading}
          saving={saving}
          contactDispatch={contactDispatch}
          contactResource={contactResource}
          onBoarding={onBoarding}
        />
      </Widget>
      <ContactAddressWidget
        isEditing={editingContact}
        value={contactInfo}
        setValue={setContactInfo}
        loading={loading}
      />
      <Widget
        className={contactDetails?.kind === "company" && "mb-4"}
        title="Profile"
        draggable={false}
        isEditing={editingContact}
        disableEditing={!hasEditPermissions}
        loading={loading || loadingDetails}
        overflow
      >
        <ContactProfileInfo
          contactUser={contactUser}
          setContactInfo={setContactInfo}
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
          setEditingContact={setEditingContact}
          editingContact={editingContact}
          contactInfo={contactInfo}
          setActiveIndex={setActiveIndex}
          hasEditPermissions={hasEditPermissions}
          saving={saving}
          companyList={companyList}
          reloadCompanies={reloadCompanies}
          setNewDisciplines={setNewDisciplines}
          managementConfiguration={managementConfiguration}
          onBoarding={onBoarding}
        />
      </Widget>
      {contactDetails?.kind === "company" && (
        <ContactAssigneesForm
          companyAssignees={companyAssignees}
          setCompanyAssignees={setCompanyAssignees}
          setUnassignedContacts={setUnassignedContacts}
          isEditing={editingContact}
          unassignedContacts={unassignedContacts}
          isLoading={!unassignedContacts}
          handleNavigate={handleNavigate}
          contactResource={contactResource}
          contactDispatch={contactDispatch}
          contactInfo={contactInfo}
        />
      )}
    </>
  );
};

const reviewItemShape = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  })
);

ContactInfo.propTypes = {
  /**
   * OnBoarding: Boolean (to show or not show tags as they currently do not work in create profile flow)
   */
  onBoarding: PropTypes.bool,
  contactInfo: PropTypes.shape({
    phoneNumbers: reviewItemShape,
    emails: reviewItemShape,
    addresses: reviewItemShape,
    websites: reviewItemShape,
    notes: PropTypes.string,
    socials: reviewItemShape,
    dates: reviewItemShape,
    disciplines: PropTypes.arrayOf(PropTypes.string),
  }),
  contactDetails: PropTypes.shape({
    kind: PropTypes.string,
  }),
  setContactDetails: PropTypes.func,
  reloadCompanies: PropTypes.func,
  managementConfiguration: PropTypes.shape({}),
  setNewDisciplines: PropTypes.func,
  contactUser: PropTypes.shape({}),
  companyList: PropTypes.arrayOf(PropTypes.shape({})),
  editingContact: PropTypes.bool,
  loading: PropTypes.bool,
  setContactInfo: PropTypes.func,
  saving: PropTypes.bool,
  setEditingContact: PropTypes.func,
  handleNavigate: PropTypes.func,
  setActiveIndex: PropTypes.func,
  contactResource: PropTypes.shape({}),
  contactDispatch: PropTypes.func,
  companyAssignees: PropTypes.arrayOf(PropTypes.shape({})),
  setCompanyAssignees: PropTypes.func,
  setUnassignedContacts: PropTypes.func,
  unassignedContacts: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}),
};

ContactInfo.defaultProps = {
  companyAssignees: undefined,
  handleNavigate: undefined,
  setCompanyAssignees: undefined,
  setUnassignedContacts: undefined,
  unassignedContacts: undefined,
  history: undefined,
  contactInfo: undefined,
  onBoarding: undefined,
  contactDetails: undefined,
  reloadCompanies: undefined,
  managementConfiguration: undefined,
  setNewDisciplines: undefined,
  contactUser: undefined,
  companyList: [],
  setEditingContact: undefined,
  setActiveIndex: undefined,
  editingContact: undefined,
  loading: true,
  saving: false,
  setContactInfo: () => {},
  setContactDetails: () => {},
  contactResource: undefined,
  contactDispatch: undefined,
};

export default ContactInfo;
