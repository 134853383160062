import React from "react";
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../../Checkbox/Checkbox";
import Step from "../../../Step";
import TaskStepText from "./TaskStepText";
import useSopStepStatusData from "./useSopStepStatusData";

const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };
const TaskStepsSection = ({
  editingMode,
  sopsForTask,
  taskForm,
  currentTask,
  setCurrentTask,
  sopOptions,
  handleStepAdd,
  handleStepEdit,
  handleStepRemove,
  noTitle,
  isPastTask,
}) => {
  const { handleCompleteCheck, isAllChecked } = useSopStepStatusData(
    sopsForTask,
    currentTask,
    setCurrentTask
  );

  return (
    <div className="flex flex-row mb-6 mt-8">
      {!noTitle && (
        <p style={labelWidth} className="font-semibold text-gray-450 text-md">
          Steps
        </p>
      )}
      <div className="flex-1">
        {!editingMode && (
          <>
            {sopsForTask?.length > 0 ? (
              <div className="flex flex-col">
                <div className="flex pb-6">
                  <Checkbox
                    className="flex items-start h-4 mr-2 mt-0.5"
                    size={6}
                    checked={isAllChecked}
                    disabled={currentTask?.status === "done"}
                    onChange={() => handleCompleteCheck(!isAllChecked)}
                  />
                  <p className="text-gray-450 text-sm mr-2">Check All Steps</p>
                </div>
                <div className="px-4">
                  {sopsForTask?.map((sop, index) => (
                    <TaskStepText
                      key={sop.id}
                      sop={sop}
                      index={index}
                      currentTask={currentTask}
                      setCurrentTask={setCurrentTask}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <p className="text-sm text-gray-450">
                There are no steps saved to this task
              </p>
            )}
          </>
        )}
        {editingMode &&
          taskForm.steps?.map((sop, index) => (
            <div key={sop.id} className="mt-3 mb-2 ml-2 mr-4">
              <Step
                index={index}
                key={`${sop.id}-step`}
                form={taskForm}
                handleAddStep={handleStepAdd}
                handleStepEdit={handleStepEdit}
                handleStepRemove={handleStepRemove}
                sopOptions={sopOptions?.options || []}
                disabled={isPastTask}
              />
            </div>
          ))}
        {editingMode && !isPastTask && (
          <PlusCircleButton
            title="Add Step"
            className="mt-0"
            titleClassName="text-darkenedGreen font-semibold"
            // if style is false overrides titleClassName
            style={{}}
            onClick={handleStepAdd}
          />
        )}
      </div>
    </div>
  );
};

export default TaskStepsSection;
