import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

// Images & Icons
import crossIconWhite from "../../assets/images/crossIconWhite.svg";

// Components
import Avatar from "../Message/Avatar";
import { truncateLabel } from "../../../helpers/Tag";
import Checkbox from "../Checkbox/Checkbox";

const rowItemCN = (isEditing) => cntl`
  flex flex-row 
  items-center 
  py-2 
  pr-3
  relative 
  ${isEditing && "bg-backgroundGreen"}
  ${isEditing && "hover:shadow-light-lift"}
  `;
const entityToPatch = (entity) => {
  return entity?.resource === "Asset"
    ? {
        ref: entity?.reference,
        isCompleted: entity?.isCompleted,
      }
    : { id: entity?.id, isCompleted: entity?.isCompleted };
};
const EntityPillList = ({
  list,
  handleClick,
  className,
  isEditing,
  checkbox,
  disabled,
  handleCompleteCheck,
}) => {
  return (
    <div className="w-full h-full flex justify-start">
      {/* This Div is a wrapper, puts view all button in line with avatars */}
      <div className={`flex ${className ?? ""}`}>
        <div className="flex flex-row w-full flex-wrap gap-2">
          {list?.map((entity) => {
            return (
              <div
                className="flex flex-row items-center"
                key={entity?.reference ?? entity?.id}
              >
                {checkbox && !isEditing && (
                  <Checkbox
                    className="pr-2"
                    size={6}
                    checked={entity?.isCompleted}
                    disabled={disabled}
                    onChange={(val) =>
                      handleCompleteCheck(val, entityToPatch(entity))
                    }
                  />
                )}
                <div
                  style={{ borderRadius: "24px", height: "56px" }}
                  className={rowItemCN(isEditing)}
                  onClick={() => {
                    return isEditing && !disabled
                      ? handleClick(entity?.reference ?? entity?.id)
                      : null;
                  }}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  role="button"
                  key={entity?.reference ?? entity?.id}
                >
                  <Avatar
                    avatar={entity?.primaryImage}
                    name={entity?.name}
                    isExternalUser
                    disableTooltip
                    id={entity?.id}
                    className={`w-10 h-10 shadow-medium-lift border-none ${
                      isEditing && "ml-2"
                    }`}
                  />
                  <p className="text-gray-500 text-xs">
                    {truncateLabel(entity?.name, 14)}
                  </p>
                  {isEditing && !disabled && (
                    <button
                      type="button"
                      className="absolute top-0 right-0"
                      style={{
                        width: 16,
                        height: 16,
                      }}
                    >
                      <img
                        alt="delete tag"
                        className="top-0 right-0 absolute rounded-xl bg-darkenedGreen p-1 shadow-light-lift"
                        src={crossIconWhite}
                      />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// prop types
EntityPillList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      member: PropTypes.shape({
        name: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    })
  ),
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  handleClick: PropTypes.func,
  checkbox: PropTypes.bool,
  disabled: PropTypes.bool,
  handleCompleteCheck: PropTypes.func,
};

// default props
EntityPillList.defaultProps = {
  list: [
    {
      member: {
        name: {
          firstName: undefined,
          lastName: undefined,
        },
      },
    },
  ],
  className: undefined,
  isEditing: false,
  handleClick: () => {},
  checkbox: undefined,
  disabled: false,
  handleCompleteCheck: () => {},
};

export default EntityPillList;
