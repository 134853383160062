/* eslint-disable no-return-assign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { truncate } from "mml-react";
import Input from "../Input/Input";
import FormAvatar from "../Avatar/FormAvatar";
import {
  strictEmailCheck,
  strictString,
  dynamicPhoneNumberCheck,
} from "../../../helpers/FormValidations";
import Dropdown from "../Dropdown/Dropdown";
import Widget from "../Widget/Widget";
import BillingAddress from "../AddressInput/BillingAddress";
import inviteIcon from "../../assets/images/inviteIcon.svg";
import Pill from "../Pill/Pill";

const fieldTitle = cntl`
text-gray-450 
font-bold
text-sm
`;

const CreateContactForm = ({
  companyContacts,
  contact,
  contactDispatch,
  memberEmails,
  contactType,
  handleInviteButton,
  modalRef,
  inviteStatus,
}) => {
  const [contactAddress, setContactAddress] = useState([]);

  const inputRefs = useRef({
    firstName: {},
    lastName: {},
    phoneNumbers: {},
    email: {},
    notes: {},
    title: {},
    company: {},
    address: {},
  });

  const handleForwardRef = (key, val) => {
    if (inputRefs?.current) inputRefs.current[key] = val;
  };

  useEffect(() => {
    setContactAddress(contact?.address);
  }, [contact?.address]);

  /**
   * Company Form Functions
   */
  const company = useMemo(() => {
    if (contactType === "company") {
      return true;
    }
    return false;
  }, [contactType]);

  const resetForm = useCallback(
    (initial) => {
      contactDispatch({
        type: "reset",
        initial,
      });
    },
    [contactDispatch]
  );

  useEffect(() => {
    resetForm(company && { kind: "company", name: {} });
  }, [company, resetForm]);

  /**
   * New Handlers
   */
  const handleChange = useCallback(
    (key, value, phoneNumber, isNewCompany) => {
      if (phoneNumber) {
        contactDispatch({
          type: key,
          value: phoneNumber.replace(/[()-\s]/g, ""),
          display: phoneNumber,
        });
        return;
      }
      // if creating new company for the contact
      if (isNewCompany) {
        contactDispatch({
          type: key,
          value,
          // eslint-disable-next-line no-underscore-dangle
          isNewCompany,
        });
        return;
      }
      contactDispatch({
        type: key,
        company,
        value,
      });
    },
    [company, contactDispatch]
  );

  const handleAddressInput = (val) => {
    contactDispatch({
      type: "address",
      address: val,
    });
  };

  const changeAvatar = useCallback(
    ({ fileRef, image }) => {
      contactDispatch({
        type: "avatar",
        fileRef,
        image,
      });
    },
    [contactDispatch]
  );

  /**
   * Memoized Values
   */
  const contactCompany = useMemo(() => {
    return companyContacts?.find((item) => item.value === contact?.companyName);
  }, [companyContacts, contact?.companyName]);

  const contactEmail = useMemo(() => {
    return contact?.contactPoint?.find((item) => item?.system === "Email")
      ?.value;
  }, [contact?.contactPoint]);

  const contactPhone = useMemo(() => {
    return contact?.contactPoint?.find((item) => item?.system === "Phone")
      ?.display;
  }, [contact?.contactPoint]);

  const handleMailingSameAsPhysicalInput = (val) => {
    contactDispatch({
      type: "mailingSameAsPhysical",
      mailingSameAsPhysical: val,
    });

    // create matching address
    const newMailing = { ...contact?.address?.[0], use: "Mailing" };

    // If checkbox selected (true) ->
    // set mailing address same as physical address
    if (val) {
      contactDispatch({
        type: "address",
        address: [contact?.address?.[0], newMailing],
      });
    }
  };

  const renderInviteSection = () => {
    if (inviteStatus === "pending") {
      return (
        <Pill
          value="Member Invite Pending"
          border="border border-es-dark-grey"
          background="bg-white"
          textColor="text-es-dark-grey"
        />
      );
    }
    return (
      <button
        title="Open Invite Popup"
        onClick={() => handleInviteButton()}
        type="button"
        className="flex flex-row gap-2 items-center"
        ref={modalRef}
      >
        <p className="flex text-es-sm text-es-green font-es-semibold">
          Invite as a Member
        </p>
        <img src={inviteIcon} alt="Open Invite as Member popup" />
      </button>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div id="col-1" className="flex justify-between items-center">
        {/* Header */}
        <div className="flex justify-between items-center gap-4">
          <FormAvatar
            width="99px"
            user={!company}
            company={company}
            image={contact?.image}
            onChange={changeAvatar}
            isEditing
          />
          <span className={fieldTitle}>Add Image</span>
        </div>
        {!company && renderInviteSection()}
      </div>
      {!company ? (
        <>
          {/* // Contact Information Widget */}
          <Widget title="Contact Information" draggable={false} overflow>
            <>
              <div className="flex items-end gap-6 w-full mb-4 mt-6">
                <div className="flex items-center w-1/2 border-b h-full pb-5">
                  <span className={`flex items-center w-32 ${fieldTitle}`}>
                    First Name
                  </span>
                  <div className="flex flex-col w-52">
                    <Input
                      validation={strictString}
                      value={contact?.name?.firstName}
                      name="firstName"
                      inputClassName="ESInput bg-backgroundGreen"
                      placeholder="First Name"
                      onChange={(val) => handleChange("firstName", val)}
                      forwardedRef={(el) => (inputRefs.current.firstName = el)}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2 border-b h-full pb-5">
                  <p className={`flex items-center w-32 ${fieldTitle}`}>
                    Last Name
                  </p>
                  <div className="flex flex-col w-52 pl-1">
                    <Input
                      value={contact?.name?.lastName}
                      placeholder="Last Name"
                      name="lastName"
                      inputClassName="ESInput bg-backgroundGreen"
                      onChange={(val) => handleChange("lastName", val)}
                      forwardedRef={(el) => (inputRefs.current.lastName = el)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end gap-7 w-full mb-5 mt-7">
                <div className="flex items-center w-1/2">
                  <p className={`flex items-center w-32 ${fieldTitle}`}>
                    Mobile
                  </p>
                  <div className="flex flex-col w-52">
                    <Input
                      value={contactPhone}
                      placeholder="(800) 123-4567"
                      isPhoneNumber
                      inputClassName="ESInput bg-backgroundGreen"
                      onChange={(val) => handleChange("phone", null, val)}
                      name="phoneNumbers"
                      forwardedRef={(el) =>
                        (inputRefs.current.phoneNumbers = el)
                      }
                      validation={
                        contactPhone?.length && dynamicPhoneNumberCheck()
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                  <p className={`flex items-center w-32 ${fieldTitle}`}>
                    Email
                  </p>
                  <div className="flex flex-col w-52 pr-1">
                    <Input
                      validation={strictEmailCheck(
                        memberEmails,
                        inviteStatus === "pending" && !company
                      )}
                      showValidationErrorAtBottom
                      inputClassName="ESInput bg-backgroundGreen"
                      value={contactEmail}
                      placeholder="example@domain.com"
                      onChange={(val) => handleChange("email", val)}
                      name="email"
                      forwardedRef={(el) => (inputRefs.current.email = el)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full pt-6 border-t mb-2">
                <span className={`flex w-40 ${fieldTitle}`}>Notes</span>
                <div className="flex flex-col w-full">
                  <Input
                    value={contact?.notes}
                    placeholder="Notes"
                    onChange={(val) => handleChange("note", val)}
                    name="notes"
                    forwardedRef={(el) => (inputRefs.current.notes = el)}
                    isTextarea
                    inputClassName="ESInput p-1 pl-2 w-full h-full bg-backgroundGreen"
                    mainClassName="p-0"
                    className="p-0"
                    inputContainerClassName="p-0"
                  />
                </div>
              </div>
            </>
          </Widget>
          {/* //* Profile Widget */}
          <Widget draggable={false} title="Profile" overflow>
            <div className="flex items-end gap-6 w-full mb-2">
              <div className="flex flex-row w-full pt-5">
                <span className={`flex items-center w-32 ${fieldTitle}`}>
                  Title
                </span>
                <div className="flex flex-col w-52">
                  <Input
                    value={contact?.title}
                    placeholder="Title"
                    onChange={(val) => handleChange("title", val)}
                    name="title"
                    inputClassName="ESInput bg-backgroundGreen"
                    forwardedRef={(el) => (inputRefs.current.title = el)}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full pt-5">
                <span className={`flex items-center w-32 ${fieldTitle}`}>
                  Company
                </span>
                <div className="flex flex-col w-52">
                  <Dropdown
                    options={companyContacts}
                    value={
                      !contact?.isNewCompany ? contactCompany : contact?.company
                    }
                    onChange={(val) =>
                      handleChange(
                        "company",
                        val.value,
                        false,
                        // eslint-disable-next-line no-underscore-dangle
                        val?.__isNew__
                      )
                    }
                    isCreatable
                    placeholder="Select"
                    name="Company"
                    forwardedRef={(el) => (inputRefs.current.company = el)}
                  />
                </div>
              </div>
            </div>
          </Widget>
        </>
      ) : (
        // Company Name Field if Company Contact
        <>
          <Widget
            title="Contact Information"
            draggable={false}
            overflow
            className="mt-2"
            childClassName="pt-1 pb-2"
          >
            <div className="flex flex-row w-full items-center justify-between mb-6 mt-2">
              <span className="text-gray-450 w-1/5 font-bold text-sm flex">
                Company Name
              </span>
              <Input
                validation={strictString}
                mainWrapperClassName="flex w-full pl-3"
                showValidationErrorAtBottom
                inputClassName="ESInput p-1 pl-2 flex w-full h-full bg-backgroundGreen"
                mainClassName="p-0 flex w-full"
                inputContainerClassName="p-0 flex w-full"
                value={contact?.company?.value ?? ""}
                placeholder={
                  contact?.company && contact?.id
                    ? // If Existing company make placeholder existing name
                      truncate(contact?.company, 18, "...")
                    : "Company name"
                }
                onChange={(val) => handleChange("company", val)}
                forwardedRef={(el) => (inputRefs.current.company = el)}
              />
            </div>
            <div className="flex items-end gap-7 w-full mb-6 pt-6 border-t">
              <div className="flex items-center w-1/2">
                <span className={`flex items-center w-3/5 mr-2 ${fieldTitle}`}>
                  Mobile
                </span>
                <div className="flex flex-col w-full">
                  <Input
                    value={contactPhone}
                    placeholder="(800) 123-4567"
                    isPhoneNumber
                    inputClassName="ESInput p-1 pl-2 w-full h-full bg-backgroundGreen"
                    mainClassName="p-0"
                    className="p-0"
                    inputContainerClassName="p-0"
                    onChange={(val) => handleChange("phone", null, val)}
                    name="phoneNumbers"
                    forwardedRef={(el) => (inputRefs.current.phoneNumbers = el)}
                    validation={
                      contactPhone?.length && dynamicPhoneNumberCheck()
                    }
                  />
                </div>
              </div>
              <div className="flex items-center w-1/2">
                <span className={`flex items-center w-32 ${fieldTitle}`}>
                  Email
                </span>
                <div className="flex flex-col w-full">
                  <Input
                    validation={strictEmailCheck(
                      memberEmails,
                      contact?.invite && !company
                    )}
                    showValidationErrorAtBottom
                    inputClassName="ESInput p-1 pl-2 w-full h-full bg-backgroundGreen"
                    mainClassName="p-0"
                    className="p-0"
                    inputContainerClassName="p-0"
                    value={contactEmail}
                    placeholder="example@domain.com"
                    onChange={(val) => handleChange("email", val)}
                    name="email"
                    forwardedRef={(el) => (inputRefs.current.email = el)}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full pt-6 border-t">
              <span className={`flex w-40 ${fieldTitle}`}>Notes</span>
              <div className="flex flex-col w-full">
                <Input
                  value={contact?.notes}
                  placeholder="Notes"
                  onChange={(val) => handleChange("note", val)}
                  name="notes"
                  forwardedRef={(el) => (inputRefs.current.notes = el)}
                  isTextarea
                  inputClassName="ESInput p-1 pl-2 w-full h-full bg-backgroundGreen"
                  mainClassName="p-0"
                  className="p-0"
                  inputContainerClassName="p-0"
                />
              </div>
            </div>
          </Widget>
        </>
      )}
      {/* Address Widget */}
      <Widget draggable={false} title={null} className="mt-2" overflow>
        <BillingAddress
          address={contactAddress}
          mailingSameAsPhysical={contact?.mailingSameAsPhysical}
          onChange={handleAddressInput}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
          onForwardRef={handleForwardRef}
          inputRefs={inputRefs}
        />
      </Widget>
    </div>
  );
};

CreateContactForm.propTypes = {
  companyContacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  contact: PropTypes.shape({
    mailingSameAsPhysical: PropTypes.bool,
    isNewCompany: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    companyName: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
    address: PropTypes.arrayOf(
      PropTypes.shape({ inputValue: PropTypes.shape({}) })
    ),
    contactPoint: PropTypes.arrayOf(
      PropTypes.shape({ display: PropTypes.string })
    ),
    social: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
    notes: PropTypes.string,
    invite: PropTypes.bool,
  }),
  memberEmails: PropTypes.arrayOf(PropTypes.string),
  contactDispatch: PropTypes.func,
  contactType: PropTypes.string,
  handleInviteButton: PropTypes.func,
  inviteStatus: PropTypes.string,
  modalRef: PropTypes.shape({}),
};

CreateContactForm.defaultProps = {
  contact: undefined,
  contactDispatch: () => {},
  companyContacts: undefined,
  memberEmails: [],
  contactType: "contact",
  handleInviteButton: undefined,
  inviteStatus: undefined,
  modalRef: undefined,
};

export default CreateContactForm;
