import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import ContactCreateModal from "../../../Pages/Contact/ContactCreateModal";
import PropertyCreateModal from "../../../Pages/Overviews/Property/PropertyCreateModal";
import {
  ADD_PREFERENCE_MODAL,
  ALERT_MODAL,
  CONFIRM_MODAL,
  CREATE_ASSET,
  CREATE_CONTACT_MODAL,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  CREATE_WF_COMMENT_MODAL,
  EDIT_EVENT_MODAL,
  EXPENSE_CREATE_MODAL,
  FEEDBACK_MODAL,
  MARK_COMPLETE,
  MINIMIZE_ALL_MODALS,
  PAYROLL_CSV_COLUMNS_MODAL,
  PROPERTY_CREATE_MODAL,
  REMOVE_USER,
  REPORT_CREATE_MODAL,
  REPORT_EDIT_MODAL,
  REPORT_TEMPLATE_MODAL,
  SETTINGS_HOLIDAYS_CREATE_MODAL,
  SUBMITTAL_CREATE_MODAL,
  TIMEOFF_REQUEST_MODAL,
  VIEW_EVENT_MODAL,
  VIEW_TASK_MODAL,
  VIEW_TODO_MODAL,
  GIVE_TIMEOFF_MODAL,
  CREATE_SPACE_MODAL,
  CREATE_SOP_MODAL,
} from "../../../constants";
import { useModalState } from "../../../state/modalState";
import EditEventModal from "./EditEventModal";
import ExpenseCreateModal from "../ExpenseForm/ExpenseCreateModal";
import CreateEditTaskModal from "./CreateEditTaskModal";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import CreateEventModal from "./CreateEventModal";
import FeedBackModal from "./FeedBackModal";
import MarkComplete from "./MarkCompleteModal/MarkComplete";
import PayrollCsvModal from "./PayrollCsvModal/PayrollCsvModal";
import TimeoffRequestModal from "./TimeoffRequestModal";
import ViewEventModal from "./ViewEventModal";
import ViewTaskModal from "./ViewTaskModal";
import ReportsCreateModal from "../ReportsCreateModal";
import ReportsEditModal from "../ReportsEditModal";
import ReportsManageTemplateModal from "../ReportsManageTemplateModal";
import Alert from "./Alert/Alert";

import CreateWFCommentModal from "../Workflow/CreateWFCommentModal";
import ViewTodoModal from "./ViewTodoModal";
import RemoveUserModal from "../UserModal/RemoveUserModal";
import CreateAssetModal from "../AssetModal/index";
import SubmittalsCreateModal from "../Submittals/SubmittalsModal/SubmittalsCreateModal";
import HolidaysSectionModal from "../SystemSettings/HolidaysSectionModal";
import AddPreferenceModal from "./AddPreferenceModal";
import GiveTimeOffModal from "./GiveTimeOffModal";
import AddSpaceModal from "./AddSpaceModal";
import CreateSopModal from "./CreateSopModal";

const EsModal = ({ reloadEvents }) => {
  const location = useLocation();
  const [{ modals: allModals }, disptach] = useModalState();
  // Detect Page Change
  // Minimize All Modals
  useEffect(() => {
    disptach({ type: MINIMIZE_ALL_MODALS });
    return () => {};
  }, [disptach, location?.pathname]);

  const getModal = useCallback(
    (item) => {
      switch (item.modalType) {
        case VIEW_EVENT_MODAL:
          return <ViewEventModal modalData={item.modalData} />;
        case CREATE_EVENT_MODAL:
          return <CreateEventModal modalData={item.modalData} />;
        case CREATE_SOP_MODAL:
          return <CreateSopModal modalData={item.modalData} />;
        case EDIT_EVENT_MODAL:
          return <EditEventModal modalData={item.modalData} />;
        case VIEW_TASK_MODAL:
          return <ViewTaskModal modalData={item.modalData} />;
        case CREATE_TASK_MODAL:
          return (
            <CreateEditTaskModal item={item} reloadEvents={reloadEvents} />
          );
        case CONFIRM_MODAL:
          return <ConfirmModal modalData={item.modalData} />;
        case MARK_COMPLETE:
          return <MarkComplete modalData={item.modalData} />;
        case PAYROLL_CSV_COLUMNS_MODAL:
          return <PayrollCsvModal modalData={item.modalData} />;
        case TIMEOFF_REQUEST_MODAL:
          return <TimeoffRequestModal modalData={item.modalData} />;
        case FEEDBACK_MODAL:
          return <FeedBackModal modalData={item.modalData} />;
        case PROPERTY_CREATE_MODAL:
          return <PropertyCreateModal modalData={item.modalData} />;
        case CREATE_CONTACT_MODAL:
          return <ContactCreateModal modalData={item.modalData} />;
        case EXPENSE_CREATE_MODAL:
          return (
            <ExpenseCreateModal
              modalData={item.modalData}
              {...item.modalData}
            />
          );
        case REPORT_CREATE_MODAL:
          return <ReportsCreateModal modalData={item.modalData} />;
        case REPORT_EDIT_MODAL:
          return <ReportsEditModal modalData={item.modalData} />;
        case REPORT_TEMPLATE_MODAL:
          return <ReportsManageTemplateModal modalData={item.modalData} />;
        case CREATE_WF_COMMENT_MODAL:
          return <CreateWFCommentModal modalData={item.modalData} />;
        case ALERT_MODAL:
          return <Alert modalData={item.modalData} />;
        case VIEW_TODO_MODAL:
          return <ViewTodoModal modalData={item.modalData} />;
        case REMOVE_USER:
          return <RemoveUserModal modalData={item.modalData} />;
        case CREATE_ASSET:
          return <CreateAssetModal modalData={item.modalData} />;
        case SUBMITTAL_CREATE_MODAL:
          return <SubmittalsCreateModal modalData={item.modalData} />;
        case SETTINGS_HOLIDAYS_CREATE_MODAL:
          return <HolidaysSectionModal modalData={item.modalData} />;
        case ADD_PREFERENCE_MODAL:
          return <AddPreferenceModal modalData={item.modalData} />;
        case GIVE_TIMEOFF_MODAL:
          return <GiveTimeOffModal modalData={item.modalData} />;
        case CREATE_SPACE_MODAL:
          return <AddSpaceModal modalData={item.modalData} />;
        default:
          return null;
      }
    },
    [reloadEvents]
  );

  const openModals = useMemo(() => {
    return allModals.reduce((list, item) => {
      if (item.modalType) {
        list.push(getModal(item));
      }
      return list;
    }, []);
  }, [allModals, getModal]);

  return <>{openModals}</>;
};

export default EsModal;
