const smoothScrollTo = (target) => {
  // Set a delay (100ms) before scrolling to allow for potential render operations or other reasons
  const renderBuffer = setTimeout(() => {
    // Fetch the DOM element with the provided ID
    const element = document.getElementById(target);

    // Scroll the identified element into the viewport with smooth behavior
    // block: "start" ensures the element will be aligned to the start of the visible part of the scroll area
    // inline: "nearest" ensures the element will be scrolled into view in the block and inline directions
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, 100);

  // Return the timeout ID. This is useful for any cleanup operations if needed elsewhere
  return renderBuffer;
};

export default smoothScrollTo;
