import moment from "moment";
import { TaskAPI } from "@griffingroupglobal/eslib-api";
import {
  SINGLE_EVENT_INSTANCE,
  UNFORMATTED_EVENTS_ACTIONS,
  UPDATE_GLOBAL_STATE,
} from "../../constants";
import getUpdatedInfoForSingleInstance from "../Calendar/getUpdatedInfoForSingleInstance";
import { toastMessage } from "../../stories/Components/Toast/Toast";
import updateUnformattedEvents from "../Calendar/updateUnformattedEvents";

const editSingleItem = async ({
  currentTask,
  updatedTask,
  userEvents,
  tasks,
  tasksDict,
  appStateDispatch,
  unformattedEvents,
}) => {
  // Convert the selected event date into a moment object
  const dateToParse = moment.parseZone(currentTask.instanceStartDate);

  // Convert the date to UTC format
  const dateFormattedToUTC = dateToParse
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  // Construct the request URL for updating the single event instance
  const updateRequestString = `${currentTask.id}/$${SINGLE_EVENT_INSTANCE}?instanceStartDate=${dateFormattedToUTC}`;
  // Merge the uploaded files into the form data

  // Send a patch request to update the event instance
  const updateResponse = await TaskAPI.patch(
    updateRequestString,
    updatedTask,
    currentTask
  );

  // Extract the updated event data from the response
  const updatedItem = updateResponse?.data;

  const { newTasks, userEventsUpdates, newTasksDict } =
    getUpdatedInfoForSingleInstance({
      updatedItem,
      userEvents,
      tasks,
      tasksDict,
    });
  // Dispatch the event updates to the application state
  appStateDispatch({
    type: UPDATE_GLOBAL_STATE,
    payload: {
      tasks: newTasks,
      userEvents: { ...userEvents, ...userEventsUpdates },
      tasksDict: newTasksDict,
    },
  });

  updateUnformattedEvents({
    appStateDispatch,
    unformattedEvents,
    item: updatedItem,
    action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE_INSTANCE,
  });

  // Notify user of the successful update
  toastMessage("Task updated successfully");

  // Return the updated item
  return updatedItem;
};

export default editSingleItem;
