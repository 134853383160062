import moment from "moment";

/**
 * Recursively updates the user event data by filtering out specific events
 * that wrap around the week based on the given criteria.
 *
 * @param {UserEvents} userEvents - The current state of user event data.
 * @param {UserEvent} event - The event object to check against. Should contain `startDate` and `endDate`.
 * @param {string|null} initialWeekStartDate - (Optional) The starting date of the week to begin processing.
 *                                               If not provided, it defaults to a week after the event's start date.
 * @returns {UpdatedUserEvents} The updated user event data after removing the specified wrapped events.
 */

const handleDeleteWrappingDay = (
  userEvents,
  event,
  initialWeekStartDate = null
) => {
  // Determine the week's start date, defaulting to one week after the event's start date

  const weekStartDate =
    initialWeekStartDate ||
    moment(event?.startDate).local().add(1, "week").startOf("week").format();

  // Identify if the event ends at the very end of the week
  const endOfWeek = moment(event.endDate).endOf("week").format();
  const keepSearching = endOfWeek === event?.endDate;

  // Base case for recursion: stop if the week start date is after the event's end date and it's not the end of the week
  if (moment(weekStartDate).isAfter(moment(event?.endDate)) && !keepSearching) {
    return userEvents;
  }

  // Access events starting on the calculated week start date
  const nextWeekStartEvents = userEvents[weekStartDate];

  // If no events are found for the next week, return the current state of user events
  if (!nextWeekStartEvents) {
    return userEvents;
  }

  let updatedUserEvents = { ...userEvents };

  // Process the events of the next week
  if (nextWeekStartEvents) {
    // Find the specific event in the next week's all-day events
    const inNextWeekAllDay = nextWeekStartEvents.allDay.find(
      (item) =>
        item.id === event.id &&
        item.instanceStartDate === event.instanceStartDate
    );

    // If the specific event is not in the next week's all-day events, return the current state
    if (!inNextWeekAllDay) {
      return userEvents;
    }

    // Filter out the specific event from the next week's all-day events
    const newNextWeekAllDay = nextWeekStartEvents.allDay.filter(
      (item) =>
        item.id !== event.id ||
        item.instanceStartDate !== event.instanceStartDate
    );

    // Create a new event object for the next week with the updated all-day events
    const newNextWeekEvent = {
      ...nextWeekStartEvents,
      allDay: newNextWeekAllDay,
      allDayCount: newNextWeekAllDay.length,
    };

    // Update the user events with the new event object for the next week
    updatedUserEvents = {
      ...updatedUserEvents,
      [weekStartDate]: newNextWeekEvent,
    };
  }

  // Calculate the beginning of the next week for the recursive call
  const beginningOfNextWeek = moment(weekStartDate).add(1, "week").format();

  // Recursive call to process the next week
  return handleDeleteWrappingDay(updatedUserEvents, event, beginningOfNextWeek);
};

export default handleDeleteWrappingDay;
