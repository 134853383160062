import { SubmittalAPI, FileAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useAppState } from "../state/appState";
import { getFullName } from "../helpers/Formatters";
import {
  commentsKeys,
  submittalKeys,
} from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import useManagementConfiguration from "./useManagementConfiguration";
import { isSubmittalStartDateInPast } from "../helpers/Submittal";
import useCurrentUser from "./useCurrentUser";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";

// Helper functions to transform data
const mapFilesData = (filesData) =>
  filesData.reduce((acc, attachment) => {
    const id = attachment.reference;
    acc[id] = attachment;
    return acc;
  }, {});

const mapDistroData = (data, userDict) => {
  const list = data?.distribution?.map((ref) => {
    const userInfo = userDict[ref];
    return userInfo;
  });
  return list;
};

const mapWorkflowSteps = (steps, userDict) =>
  steps.map((step) => {
    const fullUsers = step?.users?.reduce((acc, user) => {
      const fullUser = {
        ...user,
        data: userDict[user.reference],
      };
      return [...acc, fullUser];
    }, []);
    return { ...step, users: fullUsers };
  });

const mapProjectMembers = (project, userDict) => {
  const membersList = project?.members?.map((mem) => {
    const member = userDict[mem.user];
    const name = getFullName(member?.name);
    const ref = member?.reference;
    return { label: name, value: ref };
  });
  return membersList;
};

const formatSubmittal = async ({
  data,
  usersDict,
  projectsDict,
  managementConfiguration,
}) => {
  const allFileRefs = [...data.attachments?.map((file) => file?.ref)];

  const allsteps = data.requestWorkflow[0]?.steps || [];
  allsteps.forEach((step) =>
    allFileRefs.push(...step.attachments?.map((file) => file?.ref))
  );

  data.rounds.forEach((round) =>
    round?.requestWorkflow?.[0]?.steps.forEach((step) => {
      allFileRefs.push(...step.attachments?.map((file) => file?.ref));
    })
  );

  const allFilesData = await FileAPI.get({
    params: { reference: allFileRefs.toString() },
  });
  const files = allFilesData.data.entries.map((item) => item.resource);

  const initiatedUser = usersDict[data?.initiated?.user];
  const ballInCourt =
    data.ballInCourt?.map((bic) => usersDict[bic?.user]) ?? [];
  const type = managementConfiguration.management.submittal.types.find(
    (item) => item.id === data.type
  );

  return {
    submittal: data,
    attachmentMap: mapFilesData(files),
    project: projectsDict[data.association],
    distroList: mapDistroData(data, usersDict),
    initiated: {
      ...initiatedUser,
      date: data?.initiated?.date,
    },
    ballInCourt,
    type,
    workflowSteps: mapWorkflowSteps(allsteps, usersDict),
    projectMembers: mapProjectMembers(
      projectsDict[data.association],
      usersDict
    ),
  };
};

const fetchSubmittal = async (
  submittalId,
  usersDict,
  projectsDict,
  managementConfiguration
) => {
  const { data } = await SubmittalAPI.getById(submittalId);

  const rv = await formatSubmittal({
    data,
    usersDict,
    projectsDict,
    managementConfiguration,
  });
  return rv;
};

const postReminder = async ({ submittalId, userId, note }) => {
  await SubmittalAPI.postByIdWOP(submittalId, "$sendreminder", {
    note: note || "",
    sentTo: [`User/${userId}`],
  });
};

/**
 * Query hook to fetch submittal by Id
 * @param {string} submittalId submittal id
 */
export const useGetSubmittalById = (submittalId) => {
  const [{ projectDict, userDict }] = useAppState();

  const { data: managementConfiguration } = useManagementConfiguration();

  return useAuthenticatedQuery({
    queryKey: submittalKeys.submittalById(submittalId),
    enabled:
      !!managementConfiguration?.management && !!userDict && !!projectDict,
    queryFn: () =>
      fetchSubmittal(
        submittalId,
        userDict,
        projectDict,
        managementConfiguration
      ),
    onError: (error) => {
      toastError("There was an error reading submittal");
      console.error("useSubmittals: error getting submittal", error);
    },
  });
};

/**
 * Mutation hook to send submittal reminders
 * @param {string} mutationKey allow to track mutations (optional)
 */
export const useSendSubmittalReminder = (submittalId) =>
  useMutation({
    mutationFn: (args) => postReminder({ submittalId, ...args }),
    onError: (error) => console.error("Failed to send reminder", error),
  });

const initiateById = async (
  submittalData,
  currentUser,
  usersDict,
  projectsDict,
  managementConfiguration
) => {
  const oldBody = cloneDeep(submittalData);
  const newBody = {
    ...submittalData,
    status: "in-progress",
    startDate: {
      ...submittalData.startDate,
      actual: isSubmittalStartDateInPast(submittalData)
        ? moment().toISOString()
        : moment(submittalData.startDate.actual).toISOString(),
    },
    initiated: {
      ...submittalData.initiated,
      date: moment(),
      user: currentUser?.reference,
    },
  };
  const { data } = await SubmittalAPI.patch(submittalData.id, newBody, oldBody);

  const rv = formatSubmittal({
    data,
    usersDict,
    projectsDict,
    managementConfiguration,
  });
  return rv;
};

export const useInitiateSubmittal = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState();
  const { data: currentUser } = useCurrentUser();
  const { data: managementConfiguration } = useManagementConfiguration();

  return useMutation({
    mutationFn: (submittal) =>
      initiateById(
        submittal,
        currentUser,
        userDict,
        projectDict,
        managementConfiguration
      ),
    onError: (error) => {
      console.error("Failed to initiate submittal", error);
      toastError(" Failed to initiate submittal. Try again later.");
    },
    onSuccess: () => {
      toastMessage("Successfully initiated submittal.");
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

const sendReminder = async ({ submittalId, note, userArr }) => {
  const { data: results } = await SubmittalAPI.postByIdWOP(
    submittalId,
    "$sendreminder",
    {
      note: note || "",
      sentTo: [...userArr],
    }
  );
  return results;
};

export const useSendReminderSubmittal = () => {
  const [{ userDict }] = useAppState();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendReminder,
    onError: (error) => {
      console.error("Failed to send reminder", error);
      toastError("Send reminder operation failed. Try again later.");
    },
    onSuccess: (results) => {
      const sentToNames = results?.reminders[
        results?.reminders?.length - 1
      ]?.sentTo
        ?.map((userRef) => getFullName(userDict?.[userRef]?.name))
        ?.join(", ");

      toastMessage(
        `Reminder(s) successfully sent to: ${sentToNames || "members"}`
      );
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

const patch = async (
  newSubmittal,
  submittal,
  usersDict,
  projectsDict,
  managementConfiguration
) => {
  const { data } = await SubmittalAPI.patch(
    submittal.id,
    newSubmittal,
    submittal
  );

  const rv = formatSubmittal({
    data,
    usersDict,
    projectsDict,
    managementConfiguration,
  });
  return rv;
};

export const usePatchSubmittal = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();

  return useMutation({
    mutationFn: (params) =>
      patch(
        params.updatedResource,
        params.originalResource,
        userDict,
        projectDict,
        managementConfiguration
      ),
    onError: (error) => {
      console.warn("Error updating submittal", error);
      toastError("Error updating submittal. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

const deleteById = async (id) => {
  await SubmittalAPI.delete(id);
};

export const useDeleteSubmittal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteById,
    onError: (error) => {
      console.warn("Error deleting submittal", error);
      toastError("Error deleting submittal. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

const voidById = async (submittalId) => {
  const updatedSubmittal = await SubmittalAPI.postWOP(
    `${submittalId}/$action`,
    {
      action: "void",
    }
  );

  return updatedSubmittal;
};

export const useVoidSubmittal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: voidById,
    onError: (error) => {
      console.warn("Error voiding submittal", error);
      toastError("Error voiding submittal. Try again later.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

const submitStep = async ({
  id,
  stepId,
  comment,
  projectDict,
  userDict,
  managementConfiguration,
}) => {
  const { data } = await SubmittalAPI.postWOP(`${id}/$action`, {
    action: "submit",
    step: stepId,
    comment,
  });

  const rv = formatSubmittal({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    managementConfiguration,
  });
  return rv;
};

export const useSubmitSubmittalStep = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();

  return useMutation({
    mutationFn: ({ id, stepId, comment }) =>
      submitStep({
        id,
        stepId,
        comment,
        projectDict,
        userDict,
        managementConfiguration,
      }),
    onError: (error) => {
      console.warn("Error submitting step", error);
      toastError("Error submitting the step. Try again later.");
    },
    onSuccess: ({ submittal }) => {
      queryClient.invalidateQueries(submittalKeys.submittals);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(submittal?.reference)
      );
    },
  });
};

const completeSubmittal = async ({
  submittalId,
  submittalImpacts,
  comment,
  submittalStatus,
  projectDict,
  userDict,
  managementConfiguration,
}) => {
  const { data } = await SubmittalAPI.postWOP(`${submittalId}/$action`, {
    action: "complete",
    impacts: submittalImpacts,
    status: submittalStatus,
    comment,
  });

  const rv = formatSubmittal({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    managementConfiguration,
  });
  return rv;
};

export const useCompleteSubmittal = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();

  return useMutation({
    mutationFn: ({ submittalId, submittalImpacts, submittalStatus, comment }) =>
      completeSubmittal({
        submittalId,
        submittalImpacts,
        submittalStatus,
        comment,
        projectDict,
        userDict,
        managementConfiguration,
      }),
    onError: (error) => {
      console.warn("Error completing submittal", error);
      toastError("Error completing the submittal. Try again later.");
    },
    onSuccess: ({ submittal }) => {
      toastMessage("Successfully completed submittal.");
      queryClient.invalidateQueries(submittalKeys.submittals);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(submittal?.reference)
      );
    },
  });
};

const onStepAction = async ({
  id,
  stepId,
  stepAction,
  comment,
  stepStatus,
  projectDict,
  userDict,
  managementConfiguration,
}) => {
  const { data } = await SubmittalAPI.postWOP(`${id}/$action`, {
    action: stepAction,
    step: stepId,
    status: stepStatus,
    comment,
  });

  const rv = formatSubmittal({
    data,
    usersDict: userDict,
    projectsDict: projectDict,
    managementConfiguration,
  });
  return rv;
};

export const useActionOnSubmittalStep = () => {
  const queryClient = useQueryClient();
  const [{ projectDict, userDict }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();

  return useMutation({
    mutationFn: ({ id, stepAction, stepId, stepStatus, comment }) =>
      onStepAction({
        id,
        stepId,
        stepAction,
        stepStatus,
        comment,
        projectDict,
        userDict,
        managementConfiguration,
      }),
    onError: (error) => {
      console.warn("Error completing submittal", error);
    },
    onSuccess: ({ submittal }) => {
      queryClient.invalidateQueries(submittalKeys.submittals);
      queryClient.invalidateQueries(
        commentsKeys.commentsByAssociation(submittal?.reference)
      );
    },
  });
};
