import React, { useCallback } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import * as yup from "yup";
import { HashLink } from "react-router-hash-link";
import RemoveIcon from "../RemoveIcon/RemoveIcon";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import Checkbox from "../Checkbox/Checkbox";
import { calendarDaysOfWeekOptions } from "../../../helpers/Holidays";
import link from "../../assets/images/linkGreen.svg";

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-2
`;

const days = [
  ...calendarDaysOfWeekOptions,
  {
    label: "Holidays",
    value: "holidays",
  },
];

const RateSheetSection = ({
  financialsSettings,
  dispatchFinancialsSettings,
  navigateToHolidaysSection,
}) => {
  const onAdd = useCallback(() => {
    dispatchFinancialsSettings({
      type: "addRateSheet",
    });
  }, [dispatchFinancialsSettings]);

  const onRemove = useCallback(
    (index) => {
      dispatchFinancialsSettings({
        type: "removeRateSheet",
        index,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onChange = useCallback(
    (index, field, value) => {
      dispatchFinancialsSettings({
        type: "changeRateSheet",
        index,
        value,
        field,
      });
    },
    [dispatchFinancialsSettings]
  );

  const onSelectPremiumRateDay = useCallback(
    (day) => {
      dispatchFinancialsSettings({
        type: "selectRateSheetPremiumRateDays",
        day,
      });
    },
    [dispatchFinancialsSettings]
  );

  const navigate = useCallback(() => {
    navigateToHolidaysSection();
  }, [navigateToHolidaysSection]);

  const handleEnter = useCallback(
    (event) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        onAdd();
      }
    },
    [onAdd]
  );

  return (
    <div>
      <div className="flex flex-col">
        {!!financialsSettings?.rateSheet?.length && (
          <div className="flex flex-row w-full">
            <div className="flex w-1/2 pr-6">
              <h3 className="font-semibold">Category</h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">Rate Per Hour</h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">
                Rate Over 40 Hours
              </h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">Premium Rate</h3>
            </div>
          </div>
        )}
        {financialsSettings?.rateSheet?.map((rs, index) => {
          return (
            <div className="flex flex-row w-full" key={rs?.id}>
              <div className="py-2 flex w-1/2 pr-6">
                <Input
                  placeholder="Category"
                  mainClassName="w-full"
                  className="text-gray-300"
                  value={rs?.category}
                  onChange={(val) => onChange(index, "category", val)}
                  disableClear
                  handleEnter={handleEnter}
                  autoFocus={
                    index === financialsSettings?.rateSheet?.length - 1
                  }
                />
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div
                  className="w-1/2 justify-end"
                  style={{ "min-width": "100px" }}
                >
                  <Input
                    placeholder="Per Hr"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.ratePerHr}
                    onChange={(val) => onChange(index, "ratePerHr", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                    handleEnter={handleEnter}
                  />
                </div>
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div className="w-1/2" style={{ "min-width": "100px" }}>
                  <Input
                    placeholder="> 40 Hrs"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.rateOver40Hrs}
                    onChange={(val) => onChange(index, "rateOver40Hrs", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                    handleEnter={handleEnter}
                  />
                </div>
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div className="w-1/2" style={{ "min-width": "100px" }}>
                  <Input
                    placeholder="Premium"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.premiumRate}
                    onChange={(val) => onChange(index, "premiumRate", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                    handleEnter={handleEnter}
                  />
                </div>
              </div>
              <div className="pt-3">
                <RemoveIcon
                  color="#1DC29D"
                  onClick={() => onRemove(index)}
                  width="2rem"
                  height="2rem"
                />
              </div>
            </div>
          );
        })}
        <div className="py-4">
          <BaseButton
            className={tertiaryButtonCN}
            labelClassName="mx-0"
            title="+ Add Rate"
            onClick={onAdd}
          />
        </div>
        <div className="border-t border-gray-150 py-4">
          <h3 className="font-semibold py-4">
            Apply premium rates on the following days:
          </h3>
          <div className="flex flex-row">
            {days?.map((day) => (
              <div className="flex flex-row" key={day?.value}>
                <Checkbox
                  label={day?.label}
                  checked={
                    financialsSettings?.premiumRateDaysOfWeek &&
                    financialsSettings?.premiumRateDaysOfWeek[day?.value]
                  }
                  onChange={() => onSelectPremiumRateDay(day?.value)}
                  className={day?.value === "holidays" ? "pr-2" : "pr-6"}
                />
                {day?.value === "holidays" && (
                  <HashLink to="#chartOfAccounts" smooth replace>
                    <BaseButton
                      iconLeft={
                        <img src={link} alt="link" className="w-5 h-5" />
                      }
                      className="pt-1 pl-2"
                      onClick={navigate}
                    />
                  </HashLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

RateSheetSection.propTypes = {
  financialsSettings: PropTypes.shape({
    rateSheet: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        ratePerHr: PropTypes.string,
        rateOver40Hrs: PropTypes.string,
        premiumRate: PropTypes.string,
        selected: PropTypes.bool,
      })
    ),
    premiumRateDaysOfWeek: PropTypes.shape({
      sunday: PropTypes.string,
      monday: PropTypes.string,
      tuesday: PropTypes.string,
      wednesday: PropTypes.string,
      thursday: PropTypes.string,
      friday: PropTypes.string,
      saturday: PropTypes.string,
      holidays: PropTypes.string,
    }),
  }),
  dispatchFinancialsSettings: PropTypes.func,
  navigateToHolidaysSection: PropTypes.func,
};

RateSheetSection.defaultProps = {
  dispatchFinancialsSettings: undefined,
  financialsSettings: undefined,
  navigateToHolidaysSection: undefined,
};

export default RateSheetSection;
