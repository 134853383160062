import { useEffect, useMemo, useState } from "react";
import { useGetTags } from "../../../../../hooks/useTags";

const useTagFilterData = ({
  allColumns,
  data,
  currentCol,
  stagedFilters,
  setStagedFilters,
}) => {
  // Query hook to get all tags from cache
  const { data: tags } = useGetTags();

  // State to display tags in key/value form
  const [tagsOptions, setTagsOptions] = useState([]);

  const filterValue = {
    label: currentCol?.filterOptions?.label || currentCol.Header,
    value: currentCol.id,
    equals: currentCol?.filterOptions?.equals,
  };

  // Filter options available
  const filterOptions = useMemo(() => {
    return allColumns.reduce((acc, col) => {
      if (
        col?.filterOptions?.filterType &&
        !stagedFilters.some((filter) => filter.id === col.id)
      ) {
        acc.push({
          label: col?.filterOptions?.label || col.Header,
          value: col.id,
          equals: col?.filterOptions?.equals,
        });
      }
      return acc;
    }, []);
  }, [allColumns, stagedFilters]);

  // List of tags selected in the dropdown
  const tagsSelected = useMemo(() => {
    const currentFilter =
      stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

    return currentFilter?.value?.values?.map((val) => ({
      label: currentFilter?.value?.valueLabels?.[val],
      value: val,
    }));
  }, [stagedFilters, currentCol]);

  // This effect resolves tags to be displayed
  useEffect(() => {
    if (!tags?.tagsDict) return;

    // Concatenate the current resource's tags with the existing tags
    const allTags = data.reduce((acc, resource) => {
      return acc.concat(resource.tags);
    }, []);

    // Remove duplicate tags (if needed)
    const uniqueTags = [...new Set(allTags)];

    const labels = Array.from(uniqueTags).map((tag) => ({
      label: tags.tagsDict?.[tag?.value || tag]?.label,
      value: tag?.value || tag,
    }));

    setTagsOptions(labels);
  }, [data, tags?.tagsDict]);

  const handleChangeFilterOptions = ({ value, equals }) => {
    setStagedFilters((prev) => {
      if (value) {
        return [
          ...prev.filter((filter) => filter.id !== currentCol.id),
          {
            id: value,
            value: {
              value: equals,
              type: "is",
              isTrue: true,
            },
          },
        ];
      }
      return prev.filter((filter) => filter.id !== currentCol.id);
    });
  };

  const handleSelectedTags = (values) => {
    setStagedFilters((prev) =>
      prev.map((filter) => {
        if (filter.id === currentCol.id) {
          return {
            ...filter,
            value: {
              ...filter.value,
              type: "includes",
              values: values?.map((v) => v?.value),
              valueLabels: Object.fromEntries(
                values?.map((type) => [type.value, type.label])
              ),
            },
          };
        }
        return filter;
      })
    );
  };

  return {
    filterOptions,
    filterValue,
    tagsOptions,
    tagsSelected,
    handleChangeFilterOptions,
    handleSelectedTags,
  };
};

export default useTagFilterData;
