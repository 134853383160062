/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../Table/Table";
import displayTime, {
  MinutesToHours,
  timeoffValue,
} from "../../../helpers/TimeSheet";
import TimeSheetDatePicker from "../TimeSheetTable/TimeSheetDatePicker";
import {
  FILTER_TYPES,
  PTO_TYPE_DISPLAY,
  WEEKLY_TIME_LIMIT,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import "./approvalTable.css";
import Pill from "../Pill/Pill";
import underIcon from "../../assets/images/tsUnderIcon.svg";
import overIcon from "../../assets/images/tsOverIcon.svg";
import exemptIcon from "../../assets/images/exemptIcon.svg";
import Checkbox from "../Checkbox/Checkbox";
import { getFullName } from "../../../helpers/Formatters";
import usePagePersistance from "../../../hooks/usePagePersistence";

const dash = "items-center w-full justify-start";

const getStatus = (value) => {
  switch (value) {
    case "draft":
      return (
        <Pill
          alt="draft"
          value="Not Submitted"
          className="h-5"
          textColor="text-pillGray"
          textSize="text-xxs"
          border="border border-pillGray"
          background="bg-transparent"
          padding="p-2"
        />
      );
    case "rejected":
      return (
        <Pill
          alt="rejected"
          value="Rejected"
          className="h-5"
          textColor="text-brandRed"
          textSize="text-xxs"
          background="bg-transparent"
          border="border border-brandRed"
          padding="p-2"
        />
      );
    case "submitted":
      return (
        <Pill
          alt="submitted"
          value="Submitted"
          className="h-5"
          textColor="text-darkenedGreen"
          textSize="text-xxs"
          background="bg-transparent"
          border="border border-darkenedGreen"
          padding="p-2"
        />
      );
    case "approved":
      return (
        <Pill
          alt="approved"
          value="Approved"
          className="h-5"
          padding="p-2"
          textSize="text-xxs"
          background="bg-darkenedGreen"
          border="border-darkenedGreen"
        />
      );
    case "payrollApproved":
      return (
        <Pill
          alt="payroll approved"
          value="Payroll Approved"
          className="h-5"
          textSize="text-xxs"
          textColor="text-white"
          background="bg-pillBlue"
          border="border border-pillBlue"
          padding="p-1"
        />
      );
    case "locked":
      return (
        <Pill
          alt="approved"
          value="Payroll Approved"
          className="h-5"
          padding="p-2"
          textSize="text-xxs"
          background="bg-pillBlue"
          border="border-pillBlue"
        />
      );
    default:
      return "";
  }
};

const PureApprovalTable = ({
  data,
  ptodata,
  historyData,
  dates,
  showNextWeek,
  showPrevWeek,
  showThisWeek,
  warnings,
  setSelectedRows,
  handleApproveRow,
  handleRejectRow,
  loading,
  submitCumulativeRow,
  financials,
  customSiteHeader,
  templateOptions,
  alerts,
  projects,
}) => {
  const [{ userDict }] = useAppState();
  const { pageState, setPersistentPageItem } = usePagePersistance();

  const getStatusIcon = useCallback(
    (status, id) => {
      if (status.includes("rejected")) {
        if (!alerts.current.includes(id)) {
          alerts.current[alerts.current?.length] = id;
        }
        return "rejected";
      }
      if (status.includes("open")) {
        return "draft";
      }

      if (status.includes("submitted")) {
        return "submitted";
      }
      if (
        status.includes("approved") ||
        status.includes("approved-with-changes")
      ) {
        return "approved";
      }

      return null;
    },
    [alerts]
  );

  const tableColumns = useMemo(() => {
    const cols = [
      {
        maxWidth: 100,
        minWidth: 100,
        id: "checkbox",
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return (
            <div className="flex items-center ml-5">
              <Checkbox
                onChangeSendEvent
                {...getToggleAllRowsSelectedProps()}
              />
            </div>
          );
        },
        Cell: ({ row, state }) => {
          let checked =
            Object.keys(state?.selectedRowIds ?? {})?.includes(
              row?.original?.id
            ) || row?.getToggleRowSelectedProps()?.checked;

          const hideSelection = row?.original?.hideSelection;
          if (hideSelection) checked = false;
          return (
            <Checkbox
              onChangeSendEvent
              stopEventPropagation
              {...row.getToggleRowSelectedProps()}
              disabled={row?.original?.hideSelection}
              // Experimental
              checked={checked}
            />
          );
        },
        Footer: {},
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Employee",
        accessor: "employee.fullName",
        id: "a",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        Cell: ({ row: { original }, value }) => (
          <div
            className="capitalize truncate justify-start flex flex-row w-full"
            title={value}
          >
            {value?.includes("undefined")
              ? getFullName(userDict?.[original?.userRef]?.name)
              : value}
          </div>
        ),
      },
      {
        Header: "Status",
        id: "b",
        accessor: (row) => {
          let stats;
          if (row?.values?.statusRollUp) {
            stats = row?.values?.statusRollUp;

            return getStatusIcon(stats, data[row?.index]?.id);
          }

          stats = Object.values(row?.entries ?? {})?.map((item) => {
            return item.status;
          });

          // needs array brackets to use .includes
          return !row?.timeOff
            ? getStatusIcon(stats, data[row?.index]?.id)
            : getStatusIcon([row?.status]);
        },
        Cell: ({ value, row }) => {
          let newVal = value;
          if (
            (row?.original?.timeOff && row?.original?.status === "approved") ||
            row?.original?.payrollStatus === "approved"
          ) {
            newVal = "payrollApproved";
          }
          if (row?.original?.status === "submitted" && value !== "approved") {
            newVal = "submitted";
          }
          return getStatus(newVal);
        },
      },
      {
        Header: "Exempt",
        id: "c",
        accessor: "employee.employeeInfo.isExempt",
        maxWidth: 100,
        minWidth: 100,
        Cell: ({ value }) =>
          value ? (
            <img src={exemptIcon} alt="under-time" className="mr-1 h-6 w-6" />
          ) : (
            <p className={dash}>--</p>
          ),
      },
      {
        Header: "Under",
        id: "d",
        accessor: "summary",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row, value }) => {
          let under = MinutesToHours(
            Object.values(value ?? {})?.reduce((a, b) => a + b, 0)
          );

          under = WEEKLY_TIME_LIMIT - under;
          under -= timeoffValue(row?.original?.userRef, ptodata, dates);
          return (
            <div className="flex flex-row items-center w-full">
              {under > 0 ? (
                <>
                  <img
                    src={underIcon}
                    alt="under-time"
                    className="mr-1 h-5.5 w-4.5"
                  />
                  <p className="">{displayTime(under)}</p>
                </>
              ) : (
                <p className={dash}>--</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Over",
        id: "e",
        accessor: "summary",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row, value }) => {
          let over = MinutesToHours(
            Object.values(value ?? {})?.reduce((a, b) => a + b, 0)
          );
          over += timeoffValue(row?.original?.userRef, ptodata, dates);
          over -= WEEKLY_TIME_LIMIT;
          return (
            <div className="flex flex-row items-center w-full">
              {over > 0 ? (
                <>
                  <img
                    src={overIcon}
                    alt="over-time"
                    className="mr-1 h-5.5 w-4.5"
                  />
                  <p>{displayTime(over)}</p>
                </>
              ) : (
                <p className={dash}>--</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Time Off",
        id: "f",
        accessor: "userRef",
        Cell: ({ value }) => {
          const total = timeoffValue(value, ptodata, dates);
          return total ? (
            <div className="flex flex-row items-center">
              <>
                <p className="truncate">{displayTime(total)}</p>
              </>
            </div>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Time Worked",
        id: "g",
        accessor: "summary",
        Cell: ({ value, row }) => {
          const timeoff = timeoffValue(row?.original?.userRef, ptodata, dates);
          const total = value
            ? MinutesToHours(Object.values(value)?.reduce((a, b) => a + b, 0))
            : 0;
          return total || timeoff ? (
            <div className="flex flex-row items-center">
              <>
                <p className="truncate">{displayTime(total)}</p>
              </>
            </div>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Cumulative",
        accessor: "summary",
        id: "h",
        Cell: ({ value, row }) => {
          const timeoff = timeoffValue(row?.original?.userRef, ptodata, dates);
          const total = value
            ? MinutesToHours(Object.values(value)?.reduce((a, b) => a + b, 0))
            : 0;
          return total || timeoff ? (
            <div className="flex flex-row items-center">
              <>
                <p className="truncate">{displayTime(total + timeoff)}</p>
              </>
            </div>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Approver",
        id: "i",
        accessor: (row) => {
          if (row?.resource === "Timesheet" && !row?.timeOff) {
            return (
              <p className="truncate">
                {getFullName(
                  historyData?.[row?.reference]?.approved?.user?.[0]?.name
                )}
              </p>
            );
          }
          return (
            <p className="truncate">
              {getFullName(userDict[row?.reviewedBy]?.name)}
            </p>
          );
        },
        Cell: ({ value }) => {
          return value ? (
            <p className="truncate" title={value}>
              {value}
            </p>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Submitted",
        id: "j",
        accessor: (row) => {
          if (row.simulated) {
            <p className={dash}>--</p>;
          }

          if (historyData?.[row?.reference]?.submitted?.when) {
            return moment(
              historyData?.[row?.reference]?.submitted?.when
            ).format("MMM Do, YYYY");
          }
          return <p className={dash}>--</p>;
        },
        Cell: ({ value }) => {
          return value ? (
            <p className="truncate" title={value}>
              {value}
            </p>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Project",
        hidden: true,
        show: false,
        accessor: (row) => {
          if (row?.timeOff) {
            return PTO_TYPE_DISPLAY[row?.type];
          }
          return projects?.[row?.project]?.name ?? "Unavailable";
        },
        id: "project",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        Cell: ({ value }) => (
          <div className="truncate" title={value}>
            {value}
          </div>
        ),
      },
    ];

    return cols;
  }, [data, dates, getStatusIcon, historyData, projects, ptodata, userDict]);

  const handleTableSelect = useCallback(
    (arr) => {
      const shownRows = arr?.filter((info) => !info.hideSelection);
      setSelectedRows(shownRows);
    },
    [setSelectedRows]
  );

  const handleRowClick = useCallback(
    (id) => {
      setPersistentPageItem("timesheet", {
        ...pageState?.timesheet,
        userId: id,
        tab: "approve-time",
      });
    },
    [pageState?.timesheet, setPersistentPageItem]
  );

  const hideLockedData = useMemo(() => {
    return [...(data || [])]?.map((item) => {
      if (
        item?.status === "locked" ||
        item?.status === "approved" ||
        item?.status === "Not Submitted" ||
        item?.status === "open" ||
        item?.payrollStatus === "approved" ||
        item?.timeOff ||
        item?.simulated
      ) {
        return { ...item, hideSelection: true };
      }
      return item;
    });
  }, [data]);

  const groupBy = (rows) => {
    return Object.entries(rows)?.reduce((list, [key, item]) => {
      let cumulative = 0;
      item.forEach(({ values: { total } }) => {
        Object.values(total).forEach((day) => {
          cumulative += day.value;
        });
      });
      list[key] = [{ ...item[0], values: { ...item[0].values, cumulative } }];
      return list;
    }, {});
  };
  /**
   * New Approval Funcs
   */

  const rowMenu = ({ original: { timeOff, entries, ...og }, ...more }) => {
    const actions = [];
    /**
     * If Cumulative View Is Shown Must submit with userRows property from row group
     */
    switch (true) {
      case more?.values?.userRows &&
        !more?.values?.statusRollUp?.includes("locked"): {
        if (
          more?.values?.statusRollUp?.some((stat) =>
            ["open", "rejected", "submitted"].includes(stat)
          ) &&
          !timeOff
        ) {
          actions.push({
            title: "Approve Row",
            className: "w-64 bg-white hover:bg-gray-100 text-gray-900",
            onClick: () =>
              submitCumulativeRow(
                "approved",
                false,
                undefined,
                more.values.userRows
              ),
          });
        }
        if (
          more?.values?.statusRollUp?.some((stat) =>
            ["open", "approved", "submitted"].includes(stat)
          ) &&
          og.payrollStatus !== "approved" &&
          !timeOff
        ) {
          actions.push({
            title: "Reject Row",
            className: "w-64 bg-white hover:bg-gray-100 text-gray-900",
            onClick: () =>
              submitCumulativeRow(
                "rejected",
                false,
                undefined,
                more.values.userRows
              ),
          });
        }

        return actions;
      }

      default: {
        const statae = Object.values(entries ?? {})?.map((stat) => stat.status);

        if (
          statae?.some((stat) =>
            ["open", "rejected", "submitted"].includes(stat)
          ) &&
          og.simulated !== true
        ) {
          actions.push({
            title: "Approve Row",
            className: "w-64 bg-white hover:bg-gray-100 text-gray-900",
            onClick: (id) => handleApproveRow(id),
          });
        }
        if (
          statae?.some((stat) =>
            ["open", "approved", "submitted"].includes(stat)
          ) &&
          og.simulated !== true
        ) {
          actions.push({
            title: "Reject Row",
            className: "w-64 bg-white hover:bg-gray-100 text-gray-900",
            onClick: (id) => handleRejectRow(id),
          });
        }
        return actions;
      }
    }
  };

  const initialTemplateViewName = useMemo(() => {
    return "Cumulative";
  }, []);

  return (
    <div className="flex flex-col">
      {financials && (
        <>
          <Table
            isLoading={loading}
            disableDragging
            customGroupByFn={groupBy}
            initialTemplateViewName={initialTemplateViewName}
            hideCreateNewButton
            pagination
            hideMiniMap
            disableCreateTemplate
            disableClearView
            showConstantRowIndex
            hideGroupedFooter
            hiddenColumns={["project"]}
            headerCellStyle="important-justify-center"
            cellStyling="h-20 flex justify-center items-center"
            cellTextStyling="flex w-full justify-center pr-5 text-base font-light text-gray-300"
            onSelectedRowChange={handleTableSelect}
            columns={tableColumns}
            data={hideLockedData}
            onRowClick={({ original: row }) => {
              const userId = row?.employee?.id;
              handleRowClick(userId);
            }}
            enableEditing
            hideEditRowButton
            hideDeleteRowButton
            rowAssociationMenuList={rowMenu}
            tableHeaderClassName="flex w-full justify-center"
            resourceName="timesheetApproval"
            warningCount={warnings.current?.length}
            customSiteHeader={(props) => (
              <>
                {customSiteHeader({
                  ...props,
                  datePicker: (
                    <div className="relative">
                      <TimeSheetDatePicker
                        approvalView
                        dates={dates}
                        loading={loading}
                        showNextWeek={showNextWeek}
                        showPrevWeek={showPrevWeek}
                        showThisWeek={showThisWeek}
                      />
                    </div>
                  ),
                })}
              </>
            )}
            templateSettings={templateOptions}
          />
        </>
      )}
    </div>
  );
};

export default PureApprovalTable;

PureApprovalTable.propTypes = {
  /**
   * classes to apply to the container div
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      entries: PropTypes.arrayOf(PropTypes.shape({})),
      id: PropTypes.string,
    })
  ),
  /**
   * Employee Pto Objects
   */
  ptodata: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Time sheet history data
   */
  historyData: PropTypes.shape({}),
  /**
   * function that updates status of selected TS Project to approved
   */
  handleApproveRow: PropTypes.func,
  /**
   * function that updates status of selected TS Project to approved
   */
  handleRejectRow: PropTypes.func,
  /**
   * csi Codes Dict
   */
  projectCodes: PropTypes.shape({}),
  /**
   * projects Dict
   */
  projects: PropTypes.shape({}),
  /**
   * Warnings
   */
  warnings: PropTypes.shape({
    current: PropTypes.arrayOf(),
  }),
  /**
   * function called that displays next weeks timesheets
   */
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /**
   * function called that displays next weeks timesheets
   */
  showNextWeek: PropTypes.func,
  /**
   * function called that displays previous weeks timesheets
   */
  showPrevWeek: PropTypes.func,
  /**
   * function called that displays this weeks timesheets
   */
  showThisWeek: PropTypes.func,
  /**
   * set selected rows
   */
  setSelectedRows: PropTypes.func,
  /**
   * Loading State Of Parent Hook
   */
  loading: PropTypes.bool,
  /**
   * Timesheet Financials Config
   */
  financials: PropTypes.shape({ period: PropTypes.string }),
  customSiteHeader: PropTypes.element,
  templateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Default Settings & Configuration
   */
  defaultCsiDict: PropTypes.shape({}),
  defaultRates: PropTypes.shape({}),
  /**
   * Function for submitting rows in cumulative view
   */
  submitCumulativeRow: PropTypes.func,
  /**
   * Active View for tracking visible columns
   */
  alerts: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }),
};

PureApprovalTable.defaultProps = {
  data: [],
  ptodata: [],
  historyData: {},
  handleApproveRow: undefined,
  handleRejectRow: undefined,
  dates: [],
  showNextWeek: undefined,
  showPrevWeek: undefined,
  showThisWeek: undefined,
  setSelectedRows: undefined,
  projectCodes: undefined,
  projects: undefined,
  warnings: undefined,
  loading: true,
  financials: undefined,
  customSiteHeader: undefined,
  templateOptions: undefined,
  defaultCsiDict: undefined,
  defaultRates: undefined,
  submitCumulativeRow: undefined,
  alerts: undefined,
};
