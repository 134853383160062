import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Dropdown from "../Dropdown/Dropdown";
import InlineInput from "../Input/InlineInput";
import TagsContainer from "../Tags/TagsContainer";
import Widget from "../Widget/Widget";
import DescriptionField from "../DescriptionField";

const boldCN = (isFirst, className) => cntl`
  font-bold 
  text-gray-450
  text-md
  w-1/3
  self-start
  ${className}
`;

const textCN = cntl`
  text-gray-450 
  text-base
  font-normal
  flex
  w-1/2
`;

const AssetOverview = ({
  editing,
  assetTypes,
  onChange,
  getAssetCategoryValue,
  getAssetCategory,
  asset,
  dispatch,
}) => {
  const [category, setCategory] = useState(false);

  useEffect(() => {
    const cat = getAssetCategoryValue();
    setCategory(cat);
  }, [getAssetCategoryValue]);

  return (
    <Widget
      draggable={false}
      title="Asset Information"
      className="mb-4"
      overflow
    >
      <div className="relative pt-6">
        <div className="col-span-1">
          <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
            <h3 className={boldCN(true)}>Category</h3>
            <p className={textCN}>
              {getAssetCategory(asset?.category) ?? "Not specified"}
            </p>
          </div>
          <div className="flex w-full border-b border-gray-450 border-opacity-50 py-6">
            <h3 className={boldCN(true)}>Subcategory</h3>
            <p className={textCN}>
              {!editing ? (
                getAssetCategoryValue()?.label ?? "Not specified"
              ) : (
                <Dropdown
                  maxMenuHeight={200}
                  className="w-1/2"
                  options={assetTypes}
                  value={category}
                  onChange={(val) => {
                    onChange("assetType", val);
                  }}
                  disableClear
                />
              )}
            </p>
          </div>
          <div className="flex w-full py-6 text-area-padding">
            <h3 className={boldCN(true)}>Description</h3>
            {!editing ? (
              <div className="w-2/3">
                <DescriptionField text={asset?.description} />
              </div>
            ) : (
              <InlineInput
                background="backgroundGreen"
                className={textCN && "bg-transparent"}
                inputStyle={{ background: "#e8f9f5" }}
                value={asset?.description}
                editing={editing}
                textarea
                disabled={!editing}
                size="base"
                color="gray-450"
                onConfirm={(val) => onChange("description", val)}
                onChangeCallback={(val) => onChange("description", val)}
                hidePencil
              />
            )}
          </div>
          <div className="flex">
            <TagsContainer
              isEditing={editing}
              className="flex w-full"
              tagsWrapperClassName="mt-1"
              dispatch={dispatch}
              resource={asset}
            />
          </div>
        </div>
      </div>
    </Widget>
  );
};

AssetOverview.propTypes = {
  asset: PropTypes.shape({
    category: PropTypes.string,
    description: PropTypes.string,
  }),
  getAssetCategory: PropTypes.func,
  editing: PropTypes.bool,
  assetTypes: PropTypes.PropTypes.arrayOf(PropTypes.shape({})),
  getAssetCategoryValue: PropTypes.func,
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
};

AssetOverview.defaultProps = {
  asset: undefined,
  getAssetCategory: undefined,
  onChange: () => {},
  editing: false,
  assetTypes: undefined,
  getAssetCategoryValue: undefined,
  dispatch: undefined,
};

export default AssetOverview;
