import React from "react";
import PropTypes from "prop-types";
import MemberCard from "./MemberCard";

const MembersRow = ({
  currentMembers = [],
  onClick,
  handleRemove,
  resource,
  isEditing,
}) => {
  return (
    <div
      style={{ width: "96%" }}
      className="flex flex-col w-full h-full justify-start gap-y-1 scrollbar"
    >
      {currentMembers
        // filter out empty items {}
        ?.filter(
          ({ name, label }) => name?.firstName || name?.lastName || label
        )
        // sort by first name (members) or by label (company)
        ?.sort((a, b) =>
          (a?.name?.firstName?.toLowerCase() || a?.label?.toLowerCase()) <
          (b?.name?.firstName?.toLowerCase() || b?.label?.toLowerCase())
            ? -1
            : 1
        )
        ?.map((item, index) => {
          return (
            <MemberCard
              key={item?.id}
              isEditing={isEditing}
              onClick={onClick}
              handleRemove={handleRemove}
              user={item}
              index={index}
            />
          );
        })}

      {currentMembers.length === 0 && (
        <p className="w-full text-center text-gray-200">{`No ${resource}`}</p>
      )}
    </div>
  );
};

MembersRow.propTypes = {
  currentMembers: PropTypes.arrayOf(PropTypes.shape([])),
  onClick: PropTypes.func,
  handleRemove: PropTypes.func,
  isEditing: PropTypes.bool,
  resource: PropTypes.string,
};

MembersRow.defaultProps = {
  currentMembers: [],
  onClick: () => {},
  handleRemove: () => {},
  isEditing: undefined,
  resource: "[Resource]",
};

export default MembersRow;
