import React, { useCallback } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import RemoveIcon from "../RemoveIcon/RemoveIcon";
import Input from "../Input/Input";
import BaseButton from "../Buttons/BaseButton";
import Checkbox from "../Checkbox/Checkbox";
import { calendarDaysOfWeekOptions } from "../../../helpers/Holidays";

const tertiaryButtonCN = cntl`
  text-brandGreen
  py-2
`;

const days = [
  ...calendarDaysOfWeekOptions,
  {
    label: "Holidays",
    value: "holidays",
  },
];

const BudgetRateSheet = ({ project, dispatch }) => {
  const onAdd = useCallback(() => {
    dispatch({
      type: "addRateSheet",
    });
  }, [dispatch]);

  const onRemove = useCallback(
    (index) => {
      dispatch({
        type: "removeRateSheet",
        index,
      });
    },
    [dispatch]
  );

  const onChange = useCallback(
    (index, field, value) => {
      dispatch({
        type: "changeRateSheet",
        index,
        value,
        field,
      });
    },
    [dispatch]
  );

  const onSelectPremiumRateDay = useCallback(
    (day) => {
      dispatch({
        type: "selectRateSheetPremiumRateDays",
        day,
      });
    },
    [dispatch]
  );

  return (
    <div>
      <div className="flex flex-col border-2 border-gray-150 p-4">
        <p className="font-bold text-gray-300 pb-4">Project Rate Sheet</p>
        {!!project?.rateSheet?.rates?.length && (
          <div className="flex flex-row w-full">
            <div className="flex w-1/2 pr-6">
              <h3 className="font-semibold">Category</h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">Rate Per Hour</h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">
                Rate Over 40 Hours
              </h3>
            </div>
            <div className="flex w-1/6 pr-6 mr-2">
              <h3 className="font-semibold w-1/2 text-right">Premium Rate</h3>
            </div>
          </div>
        )}
        {project?.rateSheet?.rates?.map((rs, index) => {
          return (
            <div className="flex flex-row w-full" key={rs?.id}>
              <div className="py-2 flex w-1/2 pr-6">
                <Input
                  placeholder="Category"
                  mainClassName="w-full"
                  className="text-gray-300"
                  value={rs?.category}
                  onChange={(val) => onChange(index, "category", val)}
                  disableClear
                />
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div
                  className="w-1/2 justify-end"
                  style={{ "min-width": "100px" }}
                >
                  <Input
                    placeholder="Per Hr"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.ratePerHr}
                    onChange={(val) => onChange(index, "ratePerHr", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                  />
                </div>
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div className="w-1/2" style={{ "min-width": "100px" }}>
                  <Input
                    placeholder="> 40 Hrs"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.rateOver40Hrs}
                    onChange={(val) => onChange(index, "rateOver40Hrs", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                  />
                </div>
              </div>
              <div className="py-2 flex w-1/6 pl-6">
                <div className="w-1/2" style={{ "min-width": "100px" }}>
                  <Input
                    placeholder="Premium"
                    isDollarValue
                    className="text-gray-300"
                    value={rs?.premiumRate}
                    onChange={(val) => onChange(index, "premiumRate", val)}
                    disableClear
                    validation={yup
                      .number()
                      .positive()
                      .transform((v, o) => (o === "" ? undefined : v))}
                    showValidationErrorAtBottom
                  />
                </div>
              </div>
              <div className="pt-3">
                <RemoveIcon
                  color="#1DC29D"
                  onClick={() => onRemove(index)}
                  width="2rem"
                  height="2rem"
                />
              </div>
            </div>
          );
        })}
        <div className="py-4">
          <BaseButton
            className={tertiaryButtonCN}
            labelClassName="mx-0"
            title="+ Add Rate"
            onClick={onAdd}
          />
        </div>
        <div className="border-t border-gray-150 py-4">
          <h3 className="font-semibold py-4">
            Apply premium rates on the following days:
          </h3>
          <div className="flex flex-row">
            {days?.map((day) => (
              <div className="flex flex-row" key={day?.value}>
                <Checkbox
                  label={day?.label}
                  checked={
                    project?.rateSheet?.premiumRateDaysOfWeek &&
                    project?.rateSheet?.premiumRateDaysOfWeek[day?.value]
                  }
                  onChange={() => onSelectPremiumRateDay(day?.value)}
                  className={day?.value === "holidays" ? "pr-2" : "pr-6"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BudgetRateSheet.propTypes = {
  project: PropTypes.shape({
    rateSheet: PropTypes.shape({
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          premiumRate: PropTypes.string,
          category: PropTypes.string,
          rateOver40Hrs: PropTypes.string,
          ratePerHr: PropTypes.string,
        })
      ),
      premiumRateDaysOfWeek: PropTypes.shape({}),
    }),
  }),
  dispatch: PropTypes.func,
};

BudgetRateSheet.defaultProps = {
  project: undefined,
  dispatch: undefined,
};
export default BudgetRateSheet;
