import { LoginAPI, UsertenantAPI } from "@griffingroupglobal/eslib-api";
import { QueryClient } from "react-query";
import {
  AUTH_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  TENANT_ID_STORAGE_KEY,
} from "../constants";

const setStorage = (authToken, refreshToken) => {
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
};

const setTenant = (tenantId) => {
  localStorage.setItem(TENANT_ID_STORAGE_KEY, tenantId);
};

const getTenant = () => {
  return localStorage.getItem(TENANT_ID_STORAGE_KEY);
};

/**
 * @param {string} email - Current User Email
 * @returns - Current User Tenant Object
 */
const getUserTenant = async (email) => {
  try {
    let currentTenant = getTenant();
    const response = await UsertenantAPI.get({
      params: { email },
    });

    const {
      data: { tenants },
    } = response;
    currentTenant = tenants.find((ut) => ut["x-es-tenantid"] === currentTenant);

    return currentTenant;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("Error retrieving user tenant", err);
    return null;
  }
};

const setRegistered = (email, value) => {
  const tenantId = getTenant();
  localStorage.setItem(`${email}-${tenantId}`, value);
};

/**
 * @param {String} email - Current User Email
 * @returns {Boolean} registered - Has user completed onboarding
 */
const isRegistered = async (email) => {
  const tenantId = getTenant();
  const isReg = localStorage.getItem(`${email}-${tenantId}`);
  if (!isReg) {
    const tenant = await getUserTenant(email);
    setRegistered(email, tenant.registered);
    return tenant.registered;
  }
  return isReg === "true";
};

/**
 * signs the user into the app and sets auth on interceptors
 * @param {string} username
 * @param {string} password
 * @returns {boolean} signin success
 */
const signIn = async (username, password) => {
  try {
    const {
      data: { access_token: authToken, refresh_token: refreshToken },
    } = await LoginAPI.post({
      username,
      password,
    });
    setStorage(authToken, refreshToken);
    return true;
  } catch (err) {
    return false;
  }
};

const queryClient = new QueryClient();

const signOut = () => {
  queryClient.clear();
  localStorage.clear();
};

/**
 * refreshes the authToken using the refreshToken
 */
const refresh = async (setIsSignedIn) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  try {
    const {
      data: { access_token: authToken, refresh_token: newRefreshToken },
    } = await LoginAPI.refresh({ refresh_token: refreshToken });
    setStorage(authToken, newRefreshToken);
  } catch (err) {
    setIsSignedIn(false);
  }
};

const isSignedIn = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  return !!authToken;
};

/**
 * Function to retry token refresh with exponential backoff
 * @param {number} retryCount - current retry count
 * @param {Function} setIsSignedIn - function to sign out user
 */
const retryTokenRefresh = (retryCount, setIsSignedIn) => {
  const MAX_RETRIES = 2;
  const RETRY_INTERVAL = 1000;

  if (retryCount >= MAX_RETRIES) {
    setIsSignedIn(false);
    return Promise.reject(new Error("Max retries exceeded for token refresh"));
  }

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await refresh(setIsSignedIn);
        resolve();
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        retryTokenRefresh(retryCount + 1, setIsSignedIn)
          .then(resolve)
          .catch(reject);
      }
    }, RETRY_INTERVAL * 2 ** retryCount);
  });
};

export default {
  signIn,
  signOut,
  refresh,
  isSignedIn,
  setTenant,
  getTenant,
  getUserTenant,
  setRegistered,
  isRegistered,
  setStorage,
  retryTokenRefresh,
};
