import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import cntl from "cntl";
import _ from "lodash";
import { formatCurrency } from "../../../helpers/Formatters";
import whiteCrossIcon from "../../assets/images/es-close-white.svg";
import { USER_SETTINGS_KEYS } from "../../../constants";

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const CurrentSubscription = ({
  subscriptionDetails,
  billingSettings,
  updateSettings,
}) => {
  const onClose = () => {
    updateSettings({
      key: USER_SETTINGS_KEYS.BILLING.KEY,
      subKey: USER_SETTINGS_KEYS.BILLING.SUBKEY_1,
      value: true,
    });
  };
  return (
    <div className="flex flex-col">
      {!billingSettings?.[USER_SETTINGS_KEYS.BILLING.SUBKEY_1] && (
        <div className="flex flex-row items-start tracking-es-wide rounded-lg p-3 mb-4 bg-es-dark-green w-max text-es-white text-es-normal">
          <span className="font-semibold">Update:</span>
          <span className="pl-1">
            Plans now have a minimum seat count. Please visit
          </span>
          <span className="pl-1">
            <a
              href="https://estatespace.com/pricing"
              className="underline tracking-es-wide"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://estatespace.com/pricing
            </a>
          </span>
          <span className="pl-1">for details.</span>
          <span
            className="pl-2"
            onClick={onClose}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
          >
            <img src={whiteCrossIcon} alt="Close notice" />
          </span>
        </div>
      )}
      <div className="flex flex-row">
        <h1 className="font-normal text-2xl uppercase pr-2">
          current subscription
        </h1>
        {subscriptionDetails?.subscriptionStatus === "canceled" && (
          <h1 className="font-normal text-xl text-red-500">
            ({subscriptionDetails?.subscriptionStatus})
          </h1>
        )}
      </div>
      <div className={innerCN("flex-row my-8")}>
        <div className="border-r pr-4 w-1/3">
          <p className="text-gray-400 text-lg font-semibold">Plan</p>
          <div className="flex flex-row">
            <p className="text-lg font-semibold">
              {_.capitalize(subscriptionDetails?.planName)}
            </p>
            <p className="text-lg text-gray-400 mx-2">
              ({subscriptionDetails?.userText})
            </p>
          </div>
        </div>
        <div className="border-r px-4 w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            Number of Users
          </p>
          <p className="text-lg font-semibold">
            {subscriptionDetails?.userCount}
          </p>
        </div>
        <div className="border-r px-4 w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            Billing Cycle
          </p>
          <p className="text-lg font-semibold capitalize">
            {subscriptionDetails?.billingCycle}
          </p>
        </div>
        <div className="px-4 w-1/3">
          <p className="flex text-gray-400 text-md font-semibold">
            Next Payment
          </p>
          {(subscriptionDetails?.subscriptionStatus === "canceled" && (
            <p className="text-lg font-semibold">N/A</p>
          )) || (
            <div className="flex flex-row">
              <p className="text-lg font-semibold">
                {moment(subscriptionDetails?.nextPaymentDate).format(
                  "MMMM Do, YYYY"
                )}
              </p>
              <p className="text-lg mx-2">
                ({formatCurrency(subscriptionDetails?.nextPayment)})
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CurrentSubscription.propTypes = {
  subscriptionDetails: PropTypes.shape({
    nextPayment: PropTypes.string,
    nextPaymentDate: PropTypes.string,
    planName: PropTypes.string,
    userText: PropTypes.string,
    userCount: PropTypes.string,
    subscriptionStatus: PropTypes.string,
    billingCycle: PropTypes.string,
  }),
  billingSettings: PropTypes.shape({}),
  updateSettings: PropTypes.func,
};

CurrentSubscription.defaultProps = {
  subscriptionDetails: undefined,
  billingSettings: {},
  updateSettings: () => {},
};

export default CurrentSubscription;
