import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toastError } from "../../../../helpers/Toast";
import usePto from "../../../../hooks/usePto.jsx";
import useTimeOffRequestReducer from "../../../../hooks/useTimeOffRequestReducer";

/**
 * Graphic Assets
 */
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../../constants";
import convertFromCamelCase from "../../../../helpers/Format/convertFromCamelCase/index";
import { parseTimeString, payrollCheck } from "../../../../helpers/TimeSheet";
import onlyShowPtoWithoutAccrual from "../../../../helpers/Timesheets/onlyShowPtoWithoutAccrual/index";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import { toastMessage } from "../../Toast/Toast";

export default (modalData) => {
  const [{ payrollPeriod, systemConfiguration, userDict }] = useAppState();
  const [, modalDispatch] = useModalState();
  const [timeOffRequest, dispatch] = useTimeOffRequestReducer(modalData?.item);
  const {
    base,
    actions: {
      post: { mutateAsync: post },
      patch: { mutateAsync: patch },
    },
  } = usePto();
  const [activeUser, setActiveUser] = useState({});

  useEffect(() => {
    const pageData = localStorage.getItem("pageData");
    if (!pageData) return;

    const parsedData = JSON.parse(pageData);
    const userId = parsedData?.timesheet?.userId;

    if (!userId) return;
    if (!userDict) return;

    const userRef = `User/${userId}`;
    setActiveUser(userDict[userRef]);
  }, [userDict, activeUser]);

  const unlimited = useMemo(() => {
    return systemConfiguration?.system?.pto?.locations?.find(
      (item) =>
        activeUser?.employeeInfo?.pto?.locationId === item.id &&
        item?.unlimited?.selected
    );
  }, [
    activeUser?.employeeInfo?.pto?.locationId,
    systemConfiguration?.system?.pto?.locations,
  ]);

  // UserPtoData From React-Query State
  const ptoData = useMemo(() => {
    return base?.data?.timeoffDict?.[activeUser?.reference]?.original;
  }, [activeUser?.reference, base?.data?.timeoffDict]);

  // State of Radio Buttons
  const [dayType, setDayType] = useState({
    type: "full",
    toggle: (val) => {
      if (val === "full") {
        dispatch({ type: "resetHours" });
      }
      setDayType((prev) => ({
        ...prev,
        type: val,
      }));
    },
  });

  const typeOptions =
    modalData?.ptoAvailBtns &&
    Object.entries(modalData.ptoAvailBtns).map(([key]) => {
      return { label: convertFromCamelCase(key), value: key };
    });

  const selectedType = useMemo(() => {
    const requestType = typeOptions?.find(
      (item) => item?.value === timeOffRequest?.requestType
    );
    return { dropDown: requestType, label: requestType?.label ?? "" };
  }, [typeOptions, timeOffRequest?.requestType]);

  /**
   * Check Request for total time requested
   * - Prevent Requests for 0 hours
   */
  const ptoTotalTime = Object.values(timeOffRequest?.dateList ?? {}).reduce(
    (a, item) => {
      if (item?.checked) {
        return a + parseFloat(item.hours);
      }
      return a;
    },
    0
  );

  /**
   * Check if any dates selected in the request are 'closed'
   * set -> processed -> true
   */
  const [processed, setProcessed] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkProcessed = useCallback(
    debounce(() => {
      const start = timeOffRequest?.startDate.trim();
      const end = timeOffRequest?.endDate.trim();
      const query = `${start},${end}`;

      TimesheetAPI.getWOP("$isopen", {
        params: {
          user: activeUser.id,
          range: query,
        },
      })
        .then(({ data }) => payrollCheck(data))
        .then(() => setProcessed(false))
        .catch(({ dates }) => setProcessed(dates));
    }, 7000),
    [timeOffRequest?.endDate, timeOffRequest?.startDate]
  );

  /**
   * Effect to trigger payroll check when date is changed
   */
  useEffect(() => {
    setProcessed();
    checkProcessed();
  }, [checkProcessed]);

  const disableSubmit =
    !timeOffRequest?.endDate ||
    !timeOffRequest?.startDate ||
    !timeOffRequest?.requestType;

  // Conditional End Date Props
  const endDateProps = useMemo(() => {
    const end = new Date(
      moment.utc(timeOffRequest?.endDate).startOf("day").format()
    ).toISOString();
    // If Modifying a request
    if (modalData?.item) {
      // If the request has been processed start through end disable end date selector
      if (processed?.[end]) return { disabled: true };

      // if the request end date is outside of the processed period make the min date the last date of the period
      if (processed) {
        const processedDays = Object.entries(processed);
        const min = processedDays?.findIndex((_, index) => {
          if (index === processedDays?.length - 1) {
            return true;
          }
          return processedDays?.[index + 1]?.[1] === "open";
        });
        return {
          minDate: new Date(
            moment.utc(processedDays?.[min][0]).format("MM/DD/YYYY")
          ),
        };
      }
      // Modified request cannot set an end date earlier than the start date
      if (!processed && typeof processed === "boolean")
        return {
          minDate: new Date(
            moment.utc(timeOffRequest?.startDate).format("MM/DD/YYYY")
          ),
        };
    }
    // If processed check has yet to finish disable end date
    if (!["object", "boolean"].includes(typeof processed))
      return { disabled: true };
    return {};
  }, [
    modalData?.item,
    processed,
    timeOffRequest?.endDate,
    timeOffRequest?.startDate,
  ]);

  /**
   * Form Validation
   */

  /**
   * CRUD Operations
   */

  // Modify Existing Request
  const modifyRequest = useCallback(async () => {
    const id = timeOffRequest?.id;

    const body = {
      metadata: {
        lastUpdated: modalData?.lastUpdated,
      },
      dates: Object.entries(timeOffRequest?.dateList).map(([key, item]) => {
        return {
          date: key,
          id: item.id,
          numHours: item?.checked ? item.hours : 0,
        };
      }),
      status: "pending",
      type: timeOffRequest?.type,
      note: timeOffRequest?.note,
    };

    try {
      await patch({
        op: "$modify",
        id: modalData?.parentId,
        body,
        requestId: id,
      });
      toastMessage("Request modified.");
    } catch (error) {
      toastError("Error modifiying request.");
    }
  }, [
    modalData?.lastUpdated,
    modalData?.parentId,
    patch,
    timeOffRequest?.dateList,
    timeOffRequest?.id,
    timeOffRequest?.note,
    timeOffRequest?.type,
  ]);

  // Create New Request
  const createRequest = useCallback(async () => {
    const dateList = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in timeOffRequest?.dateList) {
      dateList.push({
        date: key,
        numHours: timeOffRequest?.dateList[key].hours,
        numMinutes: timeOffRequest?.dateList[key].minutes,
      });
    }

    const body = {
      type: timeOffRequest?.requestType,
      note: timeOffRequest?.note,
      dates: dateList,
    };

    if (modalData?.isCurrentUser === false) {
      body.user = activeUser?.reference;
    }

    post(body, { timeOffRequest }).then(() => {
      toastMessage("Request created.");
    });
  }, [activeUser?.reference, modalData, post, timeOffRequest]);

  /**
   * CRUD Operations
   */

  /**
   * Handlers
   */

  // Change Start Date
  const setRequestStart = useCallback(
    (start) => {
      dispatch({
        type: "startDate",
        start: moment(start)?.format()?.split("T")[0] || "",
      });
      setProcessed();
    },
    [dispatch]
  );

  // Change Duration
  const setDurations = useCallback(
    (date, hours) => {
      dispatch({ type: "durations", key: date, hours });
    },
    [dispatch]
  );

  // Deselct Request Date
  const setUncheckedDate = useCallback(
    (date, value) => {
      dispatch({ type: "uncheckDate", key: date, value });
    },
    [dispatch]
  );

  // Change date input
  const onChangeTimeAmount = (val, date) => {
    if (val < 1 || !val) {
      setUncheckedDate(date, false);
    }
    if (!timeOffRequest?.dateList[date].checked && val > 0) {
      setUncheckedDate(date, true);
    }
    setDurations(date, parseTimeString(val || 0));
  };

  // Change Request End
  const setRequestEnd = useCallback(
    (end) => {
      dispatch({
        type: "endDate",
        end: moment(end)?.format()?.split("T")[0] || "",
        toModify: modalData?.item,
        periodStart: payrollPeriod?.periodStart,
      });
      setProcessed();
    },
    [dispatch, payrollPeriod?.periodStart, modalData?.item]
  );

  // Change Request Type
  const setRequestType = useCallback(
    (requestType) => {
      dispatch({
        type: "type",
        requestType,
      });
    },
    [dispatch]
  );

  // Edit Request Note
  const setNote = useCallback(
    (note) => {
      dispatch({
        type: "note",
        note,
      });
    },
    [dispatch]
  );

  const submit = useMemo(() => {
    switch (true) {
      case typeof modalData?.item === "object":
        return () =>
          modalDispatch({
            type: ADD_OPEN_MODAL,
            modalType: CONFIRM_MODAL,
            ref: { id: `${modalData?.item?.id}-confirm-modify` },
            modalData: {
              item: {
                prompt: "Are you sure you want to modify this request?",
                confirm: "Yes",
                cancel: "No",
                title: "Modify Time Off",
                onConfirm: () => modifyRequest(),
                toast: "Modified Request.",
              },
            },
            position: { x: 0, y: 0 },
          });
      default:
        return () => createRequest();
    }
  }, [createRequest, modalData?.item, modalDispatch, modifyRequest]);

  const showHours = useMemo(() => {
    const length = Object.values(timeOffRequest?.dateList ?? {}).reduce(
      (len, item) => {
        if (item?.checked) return len + 1;
        return len;
      },
      0
    );
    if (length === 1 || dayType.type === "partial" || modalData?.item) {
      return null;
    }
    return length;
  }, [dayType.type, modalData?.item, timeOffRequest?.dateList]);

  const accrualTypes = useMemo(() => {
    return onlyShowPtoWithoutAccrual(modalData?.ptoAvailBtns);
  }, [modalData]);

  const requestTotal = Object.values(timeOffRequest?.dateList ?? {}).reduce(
    (a, item) => {
      if (item?.checked) {
        return a + parseFloat(item.hours);
      }
      return a;
    },
    0
  );

  const hoursRemaining =
    ptoData?.pto[timeOffRequest?.requestType]?.available -
    Object.values(timeOffRequest?.dateList ?? {}).reduce((a, item) => {
      if (item?.checked) {
        return a + parseFloat(item.hours);
      }
      return a;
    }, 0);

  const beyondAccrualAmount = useMemo(() => {
    const typeSelected = selectedType?.dropDown?.value;

    if (accrualTypes[typeSelected]) {
      const available = parseFloat(accrualTypes[typeSelected]?.available);

      if (available - requestTotal < 0) {
        return true;
      }
    }
    return false;
  }, [accrualTypes, requestTotal, selectedType]);

  const typeNotSelectedAndFull =
    !timeOffRequest?.requestType && dayType.type === "full";

  return {
    timeOffRequest,
    hoursRemaining,
    typeNotSelectedAndFull,
    dispatch,
    resourceId: modalData?.resourceId,
    data: ptoData,
    submit,
    disableSubmit,
    showHours,
    typeOptions,
    selectedType,
    dayType,
    processed,
    unlimited,
    amountRequested: ptoTotalTime,
    setRequestType,
    setRequestStart,
    setRequestEnd,
    setUncheckedDate,
    setDurations,
    setNote,
    endDateProps,
    onChangeTimeAmount,
    requestTotal,
    beyondAccrualAmount,
  };
};
