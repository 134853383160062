import React, { useState, useEffect, useCallback, useMemo } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import PlanSelection from "./PlanSelection";
import ContactInformation from "./ContactInformation";
import BillingInformation from "./BillingInformation";
import ReviewOrder from "./ReviewOrder";
import PlanSelectionHeader from "./PlanSelectionHeader";
import esLogo from "../../assets/images/estatespace-logo-dark.svg";
import BillingInformationHeader from "./BillingInformationHeader";
import ContactInformationHeader from "./ContactInformationHeader";
import useSubscriptionPlans from "../../../hooks/useSubscriptionPlans";
import { signupContactInformationSchema } from "../../../helpers/FormValidations";
import { formatCountriesDropdown } from "../../../helpers/Address";

const countryList = require("country-list");

const containerCN = cntl`
  flex
  flex-col
  container
  px-8
  m-auto
`;

const collapsibleContainerCN = () => cntl`
  mt-3
  px-4
  text-black
  rounded
  border-2
  mb-6
  pb-2
  border-gray-200
`;

const collapsibleContainerOpenCN = cntl`
  mt-3
  px-4
  text-black
  rounded
  border-4
  mb-6
  pb-2
  border-brandGreen
`;

const collapsibleContainerDisableCN = cntl`
  mt-3
  px-4
  text-gray-100
  rounded
  border-2
  mb-6
  pb-2
  border-gray-100
`;

const collapsibleContainerDisableTitleCN = cntl`
  text-gray-100
`;

const SubscriptionCheckoutForm = ({
  selected,
  billingFrequency,
  setOrderDetails,
}) => {
  const [selectedPlan, setSelectedPlan] = useState({
    ...selected,
    label: selected.planName,
    value: selected.planName,
    subLabel: selected.userText,
  });
  const [plans] = useSubscriptionPlans();
  const [userCount, setUserCount] = useState(selected.minUsers);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userCountError, setUserCountError] = useState(null);
  const [supportedPlan, setSupportedPlan] = useState(null);
  const [frequency, setFrequency] = useState(billingFrequency || "monthly");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [contactInfo, setContactInfo] = useState({});
  const [billingInfo, setBillingInfo] = useState({});
  const [togglePlanSelection, setTogglePlanSelection] = useState(true);
  const [toggleContactSelection, setToggleContactSelection] = useState(false);
  const [toggleBillingSelection, setToggleBillingSelection] = useState(false);
  const [toggleReviewSelection, setToggleReviewSelection] = useState(false);
  const [isPlanComplete, setIsPlanComplete] = useState(true);
  const [isContactComplete, setIsContactComplete] = useState(false);
  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    const countries = countryList.getNameList();
    const list = formatCountriesDropdown(countries);
    setDropdownCountries(list);
  }, []);

  useEffect(() => {
    let totalCost = 0.0;
    let planDiscount = 0.0;
    if (frequency === "monthly") {
      totalCost = userCount * parseFloat(selectedPlan.monthlyCost);
    } else if (frequency === "annual") {
      totalCost = userCount * parseFloat(selectedPlan.annualCost) * 12;
      planDiscount =
        userCount * parseFloat(selectedPlan.monthlyCost) * 12 - totalCost;
    }

    setTotal(totalCost.toFixed(2));
    setDiscount(planDiscount.toFixed(2));
  }, [userCount, frequency, selectedPlan]);

  useEffect(() => {
    setSubscriptionPlans(
      plans.map((p) => {
        return {
          ...p,
          label: p.planName,
          value: p.planName,
          subLabel: p.userText,
        };
      })
    );
  }, [plans]);

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsContactComplete(isValid);
  }, []);

  useEffect(() => {
    checkValidation(contactInfo, signupContactInformationSchema);
  }, [checkValidation, contactInfo]);

  const billingComplete = useMemo(() => {
    return (
      !!billingInfo?.name &&
      !!billingInfo?.name?.length &&
      !!billingInfo?.cardNumber &&
      !!billingInfo?.expiration &&
      !!billingInfo?.cvc &&
      !!billingInfo?.country &&
      !!billingInfo?.country?.value?.length &&
      !!billingInfo?.zipCode &&
      !!billingInfo?.zipCode?.length
    );
  }, [
    billingInfo?.cardNumber,
    billingInfo?.country,
    billingInfo?.cvc,
    billingInfo?.expiration,
    billingInfo?.name,
    billingInfo?.zipCode,
  ]);

  const onUserChange = useCallback(
    (value) => {
      const re = /^$|[0-9\b]+$/;
      if (!re.test(value)) return;

      setUserCount(value);
      if (value === "") {
        setUserCountError(
          `The plan you selected can only support a minimum of ${selectedPlan.minUsers} users.`
        );
        setIsPlanComplete(false);
      } else if (
        selectedPlan.maxUsers &&
        parseInt(value, 10) > selectedPlan.maxUsers
      ) {
        setUserCountError(
          `The plan you selected can only support up to ${selectedPlan.maxUsers} users.`
        );
        setIsPlanComplete(false);

        // find supported plan
        setSupportedPlan(
          plans.find(
            (plan) =>
              parseInt(value, 10) <= plan.maxUsers &&
              parseInt(value, 10) >= plan.minUsers
          )
        );
      } else if (parseInt(value, 10) < selectedPlan.minUsers || value === "") {
        setUserCountError(
          `The plan you selected can only support a minimum of ${selectedPlan.minUsers} users.`
        );
        setIsPlanComplete(false);
        // find supported plan
        setSupportedPlan(
          plans.find(
            (plan) =>
              parseInt(value, 10) <= plan.maxUsers &&
              parseInt(value, 10) >= plan.minUsers
          )
        );
      } else {
        setUserCountError(null);
        setSupportedPlan(null);
        setIsPlanComplete(true);
      }
    },
    [plans, selectedPlan]
  );

  const onPlanChange = useCallback(
    (value) => {
      setSelectedPlan(value);
      if (userCount < value.minUsers || userCount > value.maxUsers) {
        setUserCount(
          plans.find((plan) => plan.planName === value.value).minUsers
        );
      }

      setUserCountError(null);
      setIsPlanComplete(true);
    },
    [plans, userCount]
  );

  const onPlanUpdate = useCallback(() => {
    setSelectedPlan({
      ...supportedPlan,
      label: supportedPlan.planName,
      value: supportedPlan.planName,
      subLabel: supportedPlan.userText,
    });
    setUserCountError(null);
    setIsPlanComplete(true);
  }, [supportedPlan]);

  const changeFrequency = useCallback((freq) => {
    setFrequency(freq);
  }, []);

  const onChangeContactInfo = useCallback((val, field) => {
    setContactInfo((prev) => ({
      ...prev,
      [field]: val,
    }));
  }, []);
  const onChangeBillingInfo = useCallback((val, field) => {
    setBillingInfo((prev) => ({
      ...prev,
      [field]: val,
    }));
  }, []);

  const onChangeBillingAddress = useCallback(
    (val) => {
      setBillingInfo((prev) => ({
        ...prev,
        ...val,
        country: {
          label: val?.country?.label ?? val.country,
          value: val?.country?.value ?? val.country,
          countryCode: dropdownCountries.find((country) =>
            val?.country?.label
              ? val?.country?.label === country.label
              : val?.country?.includes(country.label)
          )?.countryCode,
        },
      }));
    },
    [dropdownCountries]
  );

  const handleNextBtn = useCallback(() => {
    if (!isPlanComplete) {
      setTogglePlanSelection(true);
      setToggleContactSelection(false);
      setToggleBillingSelection(false);
      setToggleReviewSelection(false);
    } else if (!isContactComplete) {
      setToggleContactSelection(true);
      setTogglePlanSelection(false);
      setToggleBillingSelection(false);
      setToggleReviewSelection(false);
    } else if (!billingComplete) {
      setToggleBillingSelection(true);
      setTogglePlanSelection(false);
      setToggleContactSelection(false);
      setToggleReviewSelection(false);
    } else {
      setToggleReviewSelection(true);
      setTogglePlanSelection(false);
      setToggleContactSelection(false);
      setToggleBillingSelection(false);
    }
  }, [billingComplete, isContactComplete, isPlanComplete]);

  return (
    <div className={containerCN}>
      <div className="mt-6">
        <img alt="Estatespace Logo" src={esLogo} width="150" />
      </div>
      <div className="flex mb-6 mt-10">
        <p className="font-bold text-xl">PURCHASE YOUR PLAN</p>
      </div>
      <CollapsibleSection
        id="planSelection"
        className={collapsibleContainerCN()}
        openContainerClassName={collapsibleContainerOpenCN}
        disabledContainerClassName={collapsibleContainerDisableCN}
        disabledTitleClassName={collapsibleContainerDisableTitleCN}
        title="1. PLAN SELECTION"
        isOpen={togglePlanSelection}
        setIsOpen={setTogglePlanSelection}
        useChevronIcon
        showCheckOnClose={isPlanComplete}
        disable={!isPlanComplete}
        subComponent={
          isPlanComplete &&
          (() => (
            <PlanSelectionHeader
              selectedPlan={selectedPlan}
              userCount={userCount}
              frequency={frequency}
              total={total}
            />
          ))
        }
      >
        <PlanSelection
          changeFrequency={changeFrequency}
          discount={discount}
          frequency={frequency}
          onPlanChange={onPlanChange}
          onPlanUpdate={onPlanUpdate}
          onUserChange={onUserChange}
          selectedPlan={selectedPlan}
          subscriptionPlans={subscriptionPlans}
          supportedPlan={supportedPlan}
          total={total}
          userCount={userCount}
          userCountError={userCountError}
          setTogglePlanSelection={setTogglePlanSelection}
          setToggleContactSelection={setToggleContactSelection}
          isPlanComplete={isPlanComplete}
          handleNextBtn={handleNextBtn}
        />
      </CollapsibleSection>
      <CollapsibleSection
        id="contactInformation"
        className={collapsibleContainerCN()}
        openContainerClassName={collapsibleContainerOpenCN}
        disabledContainerClassName={collapsibleContainerDisableCN}
        disabledTitleClassName={collapsibleContainerDisableTitleCN}
        title="2. CONTACT INFORMATION"
        defaultOpen
        isOpen={toggleContactSelection}
        setIsOpen={setToggleContactSelection}
        showCheckOnClose={isContactComplete}
        useChevronIcon
        disable={!isContactComplete}
        subComponent={
          isContactComplete &&
          (() => <ContactInformationHeader contactInfo={contactInfo} />)
        }
      >
        <ContactInformation
          contactInfo={contactInfo}
          onChangeContactInfo={onChangeContactInfo}
          userCountError={userCountError}
          setToggleContactSelection={setToggleContactSelection}
          setToggleBillingSelection={setToggleBillingSelection}
          isContactComplete={isContactComplete}
          handleNextBtn={handleNextBtn}
        />
      </CollapsibleSection>
      <CollapsibleSection
        id="billingInformation"
        className={collapsibleContainerCN()}
        openContainerClassName={collapsibleContainerOpenCN}
        disabledContainerClassName={collapsibleContainerDisableCN}
        disabledTitleClassName={collapsibleContainerDisableTitleCN}
        title="3. BILLING INFORMATION"
        defaultOpen
        isOpen={toggleBillingSelection}
        setIsOpen={setToggleBillingSelection}
        showCheckOnClose={billingComplete}
        useChevronIcon
        disable={!billingComplete}
        subComponent={
          billingComplete &&
          (() => <BillingInformationHeader billingInfo={billingInfo} />)
        }
      >
        <BillingInformation
          billingInfo={billingInfo}
          onChangeBillingInfo={onChangeBillingInfo}
          onChangeBillingAddress={onChangeBillingAddress}
          userCountError={userCountError}
          setToggleBillingSelection={setToggleBillingSelection}
          setToggleReviewSelection={setToggleReviewSelection}
          isBillingComplete={billingComplete}
          handleNextBtn={handleNextBtn}
        />
      </CollapsibleSection>
      <CollapsibleSection
        className={collapsibleContainerCN()}
        openContainerClassName={collapsibleContainerOpenCN}
        disabledContainerClassName={collapsibleContainerDisableCN}
        disabledTitleClassName={collapsibleContainerDisableTitleCN}
        title="4. REVIEW ORDER"
        defaultOpen
        disable
        isOpen={toggleReviewSelection}
        setIsOpen={setToggleReviewSelection}
        useChevronIcon
      >
        <ReviewOrder
          selectedPlan={selectedPlan}
          userCount={userCount}
          frequency={frequency}
          total={total}
          contactInfo={contactInfo}
          billingInfo={billingInfo}
          setToggleReviewSelection={setToggleReviewSelection}
          setOrderDetails={setOrderDetails}
          setTogglePlanSelection={setTogglePlanSelection}
          setToggleContactSelection={setToggleContactSelection}
          setToggleBillingSelection={setToggleBillingSelection}
          isPlanComplete={isPlanComplete}
          isBillingComplete={billingComplete}
          isContactComplete={isContactComplete}
        />
      </CollapsibleSection>
    </div>
  );
};

SubscriptionCheckoutForm.propTypes = {
  selected: PropTypes.shape({
    planName: PropTypes.string,
    userText: PropTypes.string,
    minUsers: PropTypes.number,
    maxUsers: PropTypes.number,
    monthlyCost: PropTypes.string,
    annualCost: PropTypes.string,
    discount: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    priceIdMonthly: PropTypes.string,
    priceIdAnnual: PropTypes.string,
  }),
  billingFrequency: PropTypes.string,
  setOrderDetails: PropTypes.func,
};

SubscriptionCheckoutForm.defaultProps = {
  selected: undefined,
  billingFrequency: undefined,
  setOrderDetails: false,
};

export default SubscriptionCheckoutForm;
