import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { useMutation } from "react-query";
import { useAppState } from "../state/appState";
import { SET_PROJECT_DICT } from "../constants";
import queryClient from "../config/reactQuery/queryClient";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { projectCodeKeys } from "../config/reactQuery/queryKeyFactory";

export default (query) => {
  const [{ projectDict }, dispatch] = useAppState();
  const getFincancials = async () => {
    const { data } = await ProjectAPI.getWOP(query);
    return { projectCodes: data };
  };

  const base = useAuthenticatedQuery(projectCodeKeys, getFincancials, {
    enabled: !!query,
    placeholderData: {
      projectCodes: {},
    },
  });
  // Update Time & Materials Project
  const updateTnM = (current, { reference, rateSheet: { rates } }) => {
    let newCodes = { ...current.projectCodes };
    const prevProject = newCodes?.[reference];
    /**
     * Update Project Dict in app state with new rates
     * @TODO - Update after/if useProjects is converted to useQuery
     */
    dispatch({
      type: SET_PROJECT_DICT,
      projectDict: {
        ...projectDict,
        [reference]: {
          ...projectDict[reference],
          rateSheet: { ...projectDict[reference].rateSheet, rates },
        },
      },
    });
    if (prevProject?.useall) {
      newCodes = {
        ...newCodes,
        [reference]: { ...newCodes[reference], rates },
      };
    }
    return { projectCodes: newCodes };
  };

  // Fetch Updated Non T&M Project
  const fetchNonTnM = async (res) => {
    if (typeof res !== "string") return res;
    const { data } = await ProjectAPI.getWOP(
      `$gettimesheetrates?reference=${res}`
    );
    return data;
  };

  const updateNonTnM = (current, res) => {
    const newCodes = { ...current.projectCodes, ...res };
    return { projectCodes: newCodes };
  };
  const update = useMutation({
    mutationFn: (res) => fetchNonTnM(res),
    onSuccess: (res) => {
      queryClient.setQueryData(projectCodeKeys, (current) => {
        switch (!!res.resource) {
          case false:
            return updateNonTnM(current, res);
          default:
            return updateTnM(current, res);
        }
      });
    },
  });

  const fetchMemberCodes = async ({ query: q, ref }) => {
    const { data } = await ProjectAPI.getWOP(
      `$gettimesheetrates?reference=${q}`
    );
    return { data, userRef: ref };
  };

  const fetchMember = useMutation({
    mutationFn: (res) => fetchMemberCodes(res),
    onSuccess: ({ data, userRef }) => {
      queryClient.setQueryData(projectCodeKeys, (current) => {
        return {
          ...current,
          members: {
            ...(current?.members ?? {}),
            [userRef]: data,
          },
        };
      });
    },
  });

  return { base: base ?? {}, actions: { update, fetchMember } };
};
