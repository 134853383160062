import React from "react";

import useCurrentUser from "../../../hooks/useCurrentUser";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import ProjectForm from "./ProjectForm";

const ProjectCreateModal = () => {
  const { data: currentUser } = useCurrentUser();
  const { data: systemConfiguration } = useSystemConfiguration();

  return (
    <ProjectForm
      currentUser={currentUser}
      systemConfiguration={systemConfiguration}
    />
  );
};

export default ProjectCreateModal;
