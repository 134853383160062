import { useQueryClient } from "react-query";
import { LOGOUT_USER, SET_IS_SIGNED_IN } from "../constants";
import Auth from "../helpers/Auth";
import { useAppState } from "../state/appState";

export default () => {
  const [{ isSignedIn }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  const setIsSignedIn = (status) => {
    dispatch({
      type: SET_IS_SIGNED_IN,
      isSignedIn: status,
    });
    if (!status) {
      // Clean react query cache
      queryClient.clear();

      dispatch({
        type: LOGOUT_USER,
      });
      // clear all local storage
      localStorage.clear();
      Auth.signOut();
    }
  };

  return [isSignedIn, setIsSignedIn];
};
