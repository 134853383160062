/* eslint-disable no-param-reassign */
import { TimeoffAPI, TimesheetAPI } from "@griffingroupglobal/eslib-api";
import cntl from "cntl";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { timesheetKeys } from "../../../../config/reactQuery/queryKeyFactory";
import {
  ADD_OPEN_MODAL,
  PAYROLL_CSV_COLUMNS_MODAL,
  SETTINGS_PAYROLL_CSV_COLUMNS,
} from "../../../../constants";
import { formatPTODict, payrollToCsv } from "../../../../helpers/TimeSheet";
import { toastError, toastMessage } from "../../../../helpers/Toast";
import createNonSubmittedTimesheet from "../../../../helpers/Users/createNonSubmittedTimesheet";
import findEmployeesWithoutTimesheets from "../../../../helpers/Users/findEmployeesWithoutTimesheets";
import removeDuplicateRef from "../../../../helpers/Utilities/removeDuplicateRef";
import useEmployeeTimeSheets from "../../../../hooks/useEmployeeTimeSheets";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import usePayroll from "../../../../hooks/usePayroll";
import usePayrollReducer from "../../../../hooks/usePayrollReducer";
import useProjectCodes from "../../../../hooks/useProjectCodes";
import useSettings from "../../../../hooks/useSettings";
import { useUsers } from "../../../../hooks/useUsers.new";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import PrimaryButton from "../../../../stories/Components/Buttons/PrimaryButton";
import Modal from "../../../../stories/Components/Modal/Modal";
import PayrollTable from "../../../../stories/Components/TimeSheetPayrollTable/PayrollTable";
import whiteCircleCheckIcon from "../../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../../stories/assets/images/whiteExclamationIcon.svg";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const Header = ({ buttons, extras, search, className }) => {
  return (
    <div className={`${className} flex`}>
      <div className="flex flex-col w-1/2"> {extras}</div>
      <div className="flex flex-col w-1/2 self-end">
        <div className="flex justify-end">{buttons}</div>
        <div className="self-end">{search}</div>
      </div>
    </div>
  );
};

const PayrollView = ({ currentUser, usersDict }) => {
  const [
    {
      timesheetstate: { approvalDate: date },
    },
  ] = useAppState();

  const { data, isLoading } = useUsers();

  const employees = data?.employees;

  const {
    timesheets,
    loading,
    financials,
    periodFrame,
    payrollHistory,
    updateCache,
    reload,
    reloadHistory,
  } = usePayroll({
    users: usersDict,
    permission: currentUser?.hasPermission("corporate", "can_approve_payroll"),
    currentUser,
  });

  const { pto } = useEmployeeTimeSheets(undefined, date);

  const {
    base: { data: { projectCodes } = {} },
  } = useProjectCodes();
  const [{ projectDict, ptoLocationsLibrary }] = useAppState();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const [saving, setSaving] = useState(false);
  const [editedTimesheets, dispatch] = usePayrollReducer();
  const [selectedRows, setSelectedRows] = useState([]);
  const warnings = useRef([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [, update] = useSettings(SETTINGS_PAYROLL_CSV_COLUMNS);
  const queryClient = useQueryClient();
  const [, modalDispatch] = useModalState();

  // Temp work around for req. only approve payroll if all items selected
  // total rows in payroll approval table
  // compare total list to selected list
  // TODO: Create new prop? for Table component if selecting 1 it selects all.
  const totalRows = useMemo(() => {
    const total = [...(editedTimesheets ?? []), ...(pto ?? [])];
    return total?.filter((item) => {
      if (
        item?.status === "approved" &&
        item?.resource === "Timesheet" &&
        item?.payrollStatus !== "approved"
      ) {
        return true;
      }
      return false;
    });
  }, [editedTimesheets, pto]);

  /**
   * Default Settings & Configuration
   */

  const defaultRatesDict = useMemo(
    () =>
      financialsConfiguration?.financials?.rateSheet?.rates?.reduce(
        (list, item) => {
          list[item?.id] = { rate: item };
          return list;
        }
      ),
    [financialsConfiguration?.financials?.rateSheet?.rates]
  );

  const csiCodes = useMemo(
    () => financialsConfiguration?.financials?.csiCodeMappingObject,
    [financialsConfiguration?.financials?.csiCodeMappingObject]
  );

  /**
   * Default Settings & Configuration
   */

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
      timesheets,
    });
  }, [dispatch, timesheets]);

  useEffect(() => {
    reset();
  }, [reset]);

  const openConfirmModal = useCallback((action, index) => {
    setSubmitValues({ action, index });
    setConfirmModal(true);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setSubmitValues();
    setConfirmModal(false);
  }, []);

  const submitTimeSheets = useCallback(
    async (action) => {
      setSaving(true);
      const savingToast = toast("Saving...", {
        isLoading: true,
        position: "top-center",
      });

      const onlyApprovedEntries = selectedRows?.filter(
        (item) => item.payrollStatus !== "status"
      );

      const arrOfPatches = onlyApprovedEntries?.map((item) => {
        const timesheetId = item?.reference?.split("/")[1];
        return TimesheetAPI.postWOP(`${timesheetId}/$approvePayroll`);
      });

      try {
        await Promise.all(arrOfPatches);
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
        toast.update(savingToast, {
          isLoading: false,
          render: "Saved Timesheet",
          closeButton: toastCloseIcon,
          className: "bg-brandGreen text-white",
          hideProgressBar: true,
          position: "top-center",
          icon: toastIcon,
          autoClose: 3000,
        });
        updateCache(selectedRows, action);
        reloadHistory();
        // update TS for budget
        queryClient.invalidateQueries(timesheetKeys);
      }
    },
    [queryClient, reloadHistory, selectedRows, updateCache]
  );

  const getPTO = useCallback(async () => {
    const queries = timesheets?.map(
      (item) => () =>
        TimeoffAPI.getWOP(`?user=${item?.userRef}&status=approved`)
    );
    return queries ? Promise.all(queries.map((query) => query())) : [];
  }, [timesheets]);

  const memoizedEditedTimesheets = useMemo(() => {
    if (isLoading) return [];

    const employeesWithputTimesheets = findEmployeesWithoutTimesheets(
      employees,
      timesheets
    );

    const convertedToTimesheets = employeesWithputTimesheets?.map((employee) =>
      createNonSubmittedTimesheet(employee)
    );

    const tsWithoutDuplicates = removeDuplicateRef(editedTimesheets);

    const arrToReturn = [...tsWithoutDuplicates, ...convertedToTimesheets].map(
      (sheet, index) => {
        return {
          ...sheet,
          index,
          id: index,
        };
      }
    );

    return arrToReturn;
  }, [editedTimesheets, employees, isLoading, timesheets]);

  const handlePayrollToCsv = useCallback(
    async (byProject = false, selectedCsvColumns) => {
      getPTO()
        .then((res) => {
          return res?.reduce((arr, response) => {
            const {
              data: { entries },
            } = response;
            return [...arr, ...entries];
          }, []);
        })
        .then((entries) => formatPTODict(entries))
        .then((dict) =>
          payrollToCsv(
            timesheets,
            ptoLocationsLibrary?.dict,
            dict,
            projectCodes,
            byProject,
            defaultRatesDict,
            csiCodes,
            payrollHistory,
            selectedCsvColumns,
            projectDict,
            memoizedEditedTimesheets
          )
        )
        .then(() =>
          toastMessage("Payroll Downloaded", toastIcon, toastCloseIcon)
        )
        .catch(() => {
          toastError(
            "Unable to Download Payroll, try again. If error persists try refreshing the page.",
            toastErrorIcon,
            toastCloseIcon
          );
        });

      try {
        await update({
          key: SETTINGS_PAYROLL_CSV_COLUMNS,
          value: selectedCsvColumns,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [
      csiCodes,
      defaultRatesDict,
      getPTO,
      memoizedEditedTimesheets,
      payrollHistory,
      projectCodes,
      projectDict,
      ptoLocationsLibrary?.dict,
      timesheets,
      update,
    ]
  );

  const primaryActions = useMemo(() => {
    let options = [];

    /**
     * Only allow Approval of Payroll if all timesheets are selected - ES-8645
     */

    if (
      selectedRows?.length === totalRows?.length &&
      selectedRows.length !== 0 &&
      selectedRows?.find((item) => item.payrollStatus !== "approved")
    ) {
      options = [
        {
          title: "Approve",
          onClick: () => openConfirmModal("approved"),
        },
      ];
    }

    if (memoizedEditedTimesheets?.length !== 0) {
      options.push({
        title: "Download CSV",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            ref: { id: uuidv4() },
            modalData: {
              onClick: (val) => {
                handlePayrollToCsv(false, val);
              },
            },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
      options.push({
        title: "Download CSV By Project",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            ref: { id: uuidv4() },
            modalData: {
              onClick: (val) => {
                handlePayrollToCsv("byproject", val);
              },
            },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
    }
    return options;
  }, [
    handlePayrollToCsv,
    memoizedEditedTimesheets,
    modalDispatch,
    openConfirmModal,
    selectedRows,
    totalRows,
  ]);

  const primaryButtonActionsDisabled = useMemo(() => {
    return saving || loading || [memoizedEditedTimesheets]?.length === 0;
  }, [memoizedEditedTimesheets, loading, saving]);

  const numberOfApproved = timesheets?.filter(
    (item) => item.payrollStatus === "approved"
  );

  const customSiteHeader = useCallback(
    () => (
      <Header
        className="items-center h-full w-1/2"
        buttons={
          <>
            {numberOfApproved && !isLoading && (
              <div className="flex flex-row border border-gray-450 rounded-md mr-4 justify-center items-center">
                <p className="flex text-gray-450 w-full text-base font-semibold px-3">
                  {`${numberOfApproved?.length} / ${employees?.length} Approved`}
                </p>
              </div>
            )}
            <PrimaryButton
              title={
                selectedRows.length === 0
                  ? "Actions"
                  : `(${selectedRows?.length}) Selected`
              }
              large
              dropdownItems={primaryActions}
              disabled={primaryButtonActionsDisabled}
              className="whitespace-nowrap"
            />
          </>
        }
      />
    ),
    [
      employees?.length,
      isLoading,
      numberOfApproved,
      primaryActions,
      primaryButtonActionsDisabled,
      selectedRows.length,
    ]
  );

  return (
    <div className={PageCN}>
      <PayrollTable
        financials={financials}
        periodFrame={periodFrame}
        currentUser={currentUser}
        timesheets={memoizedEditedTimesheets}
        payrollHistory={payrollHistory}
        loading={loading}
        reload={reload}
        pto={pto}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        warnings={warnings}
        handleEditRow={openConfirmModal}
        customSiteHeader={customSiteHeader}
      />
      <Modal
        title="Approve?"
        isOpen={confirmModal}
        alert
        primaryButtonTitle="Approve Payroll"
        primaryButtonOnClick={() => {
          submitTimeSheets(submitValues?.action, submitValues?.index);
          closeConfirmModal();
        }}
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => closeConfirmModal()}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            This action cannot be undone, are you sure?
          </p>
        </>
      </Modal>
    </div>
  );
};

PayrollView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
    reference: PropTypes.string,
  }),
  usersDict: PropTypes.shape({}),
};

PayrollView.defaultProps = {
  currentUser: undefined,
  usersDict: undefined,
};

export default PayrollView;
