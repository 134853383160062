import Api from "@griffingroupglobal/eslib-api";
import { helpKeys } from "../config/reactQuery/queryKeyFactory";
import { HELP_REQUEST_URL } from "../constants";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

const populateHelpGridItems = (helpData) => {
  const helpItems = helpData?.faq?.reduce((acc, item) => {
    if (!acc?.[item?.category])
      return {
        ...acc,
        [item?.category]: {
          description: helpData?.sections[item?.category],
          helpData: [item],
        },
      };
    acc[item?.category].helpData.push(item);

    return acc;
  }, {});

  return helpItems;
};

const fetchHelpPageData = async () => {
  const { data: helpResponseData } = await Api.get(HELP_REQUEST_URL);
  if (helpResponseData) {
    return populateHelpGridItems(helpResponseData);
  }
  return {};
};

const useGetHelpList = () => {
  const queryKey = helpKeys.helps;

  return useAuthenticatedQuery({
    queryKey,
    enabled: true,
    queryFn: fetchHelpPageData,
    onError: (error) => {
      console.error("useGetHelpList", error);
    },
  });
};

export default useGetHelpList;
