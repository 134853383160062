import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPropertyOptions } from "../../../helpers/SiteHeaderOptions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import usePropertyById from "../../../hooks/usePropertyById";
import AssetList from "../Asset/AssetList";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../../helpers/File";

/**
 * @deprecated - Remove this file
 */
const PropertyAssets = () => {
  const { propertyId } = useParams();
  const { property } = usePropertyById(propertyId);
  const { data: currentUser } = useCurrentUser();

  const [propertyImage, setPropertyImage] = useState();

  useEffect(() => {
    if (property?.images?.length) {
      resolvePrimaryImage(property.images)
        .then((imageFile) => setPropertyImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve property primary image:${err}`)
        );
    }
  }, [property]);

  return (
    <AssetList
      associatedResource={`Property/${propertyId}`}
      siteHeaderProps={{
        title: property?.title || "Loading",
        dropdownRoutes: getPropertyOptions(propertyId, currentUser),
      }}
      bannerComponent={
        <DetailViewBanner
          resource={property}
          resourceImage={propertyImage}
          disableUpload
        />
      }
      disableCreate={!currentUser?.hasPermission?.("asset", "can_write")}
    />
  );
};

export default PropertyAssets;
