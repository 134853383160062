import { useAppState } from "../state/appState";

export default function useGetAssociatedResource(associationRef) {
  const [{ assetDict, projectDict, propertiesDict }] = useAppState();
  const association = {};
  if (associationRef?.includes("Project")) {
    return projectDict?.[associationRef];
  }
  if (associationRef?.includes("Property")) {
    return {
      ...propertiesDict?.[associationRef],
      name: propertiesDict?.[associationRef]?.title,
    };
  }
  if (associationRef?.includes("Asset")) {
    return assetDict?.[associationRef];
  }

  return {
    value: association?.reference,
    reference: association?.reference,
    label: association?.title || association?.name,
  };
}
