import React from "react";
// import PropTypes from "prop-types";

const RemoveIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      color="#1DC29D"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM5.4 12h13.2"
      />
    </svg>
  );
};

RemoveIcon.propTypes = {};

RemoveIcon.defaultProps = {};

export default RemoveIcon;
