import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Input from "../Input/Input";
import TertiaryButton from "../Buttons/TertiaryButton";
import plusIcon from "../../assets/images/plusIcon.svg";
import closeIcon from "../../assets/images/crossIcon.svg";
import Select from "../Select/Select";
import DatePicker from "../DatePicker/DatePicker";
import { phoneRegExp, formatPhoneNumber } from "../../../helpers/Formatters";
import { GENDER_TYPES } from "../../../constants";
import Dropdown from "../Dropdown/Dropdown";
import RemoveIcon from "../RemoveIcon/RemoveIcon";
import SelectOne from "../SelectOne/SelectOne";
import BaseAddressInput from "../AddressInput/BaseAddressInput";
import IconButton from "../Buttons/IconButton";
import WorldClockSelect from "../SystemSettings/WorldClockSelect";
import { simpleUrl } from "../../../helpers/FormValidations";
import TagsContainer from "../Tags/TagsContainer";

const DICSIPLINES_TOOLTIP_DATA = [
  {
    label:
      "Disciplines are custom tags to help with filtering or sorting contacts (eg. Electrician, Sales Team, Housekeeping...).",
  },
];

const buttonCN = (size) => cntl`
whitespace-nowwrap
ml-4
${size > 0 && "mt-4"}
`;

// added PhoneOptions array
const Options = ["Home", "Work", "Physical", "Mailing", "Other"];
const PhoneOptions = ["Mobile", "Work", "Home", "Other"];
const DateOptions = ["Birthday", "Anniversary"];
const SocialOptions = ["LinkedIn", "Twitter", "Facebook"];

const ContactInfoForm = ({
  contactInfo,
  setContactInfo,
  emailValidationError,
  phoneValidationError,
  saving,
  onSave,
  isEditing,
  setIsEditing,
  setTabIndex,
  setNewDisciplines,
  managementConfiguration,
  setEditingContact,
  contact,
  contactResource,
  contactDispatch,
}) => {
  const [dateOptions, setDateOptions] = useState([]);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [socialOptions, setSocialOptions] = useState([]);
  const [disciplinesOptions, setDisciplinesOptions] = useState([]);

  useEffect(() => {
    const options = managementConfiguration?.management?.contact?.disciplines
      .filter((d) => d.selected)
      .map((item) => ({
        label: item.display,
        value: item.id,
      }));
    setDisciplinesOptions(options);
  }, [managementConfiguration?.management?.contact?.disciplines]);

  const [hovering, setHovering] = useState(false);

  const toggleEdit = () => {
    onSave();
    setTabIndex(0);
    if (contact) {
      setEditingContact((prev) => !prev);
    } else {
      setIsEditing((prev) => ({ ...prev, details: !prev.details }));
    }
  };

  const handleHovering = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  const handleBlur = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHovering(value);
  };

  useEffect(() => {
    if (contactInfo) {
      setDateOptions(
        _.uniq([
          ...DateOptions,
          ...contactInfo?.dates?.map((d) => d?.type?.label || d?.type),
        ])
      );

      setSocialOptions(
        _.uniq([
          ...SocialOptions,
          ...contactInfo?.socials?.map((s) => s?.type?.label || s?.type),
        ])
      );

      setPhoneOptions(
        _.uniq([
          ...PhoneOptions,
          ...contactInfo?.phoneNumbers?.map((s) => s?.type?.label || s?.type),
        ])
      );
    }
  }, [contactInfo, dateOptions.length]);

  const add = useCallback(
    (type, option, defaultValue) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: [
          ...prev[type],
          {
            type:
              option ||
              `Custom ${
                type.charAt(0).toUpperCase() + type.slice(1, type.length - 1)
              }`,
            value: defaultValue,
          },
        ],
      }));
    },
    [setContactInfo]
  );

  const changeAddress = useCallback(
    async (value, id, type) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: contactInfo[type]?.map((item, index) => {
          if (index === id) {
            if (value?.key) {
              return { ...item, [value?.key]: value?.value };
            }
            return {
              ...item,
              ...value,
            };
          }
          return item;
        }),
      }));
    },
    [contactInfo, setContactInfo]
  );

  const changeGender = useCallback(
    async (value) => {
      setContactInfo((prev) => ({
        ...prev,
        gender: value.value,
      }));
    },
    [setContactInfo]
  );

  const changeDiscipline = useCallback(
    (value) => {
      // eslint-disable-next-line no-underscore-dangle
      if (value?.__isNew__) {
        const newDisciplineId = uuidv4();
        setDisciplinesOptions((prev) => [
          ...prev,
          { label: value.value, value: newDisciplineId },
        ]);
        setContactInfo((prev) => ({
          ...prev,
          disciplines: _.uniq([...prev.disciplines, newDisciplineId]),
        }));
        setNewDisciplines((prev) => [
          ...prev,
          { id: newDisciplineId, display: value.value },
        ]);
      } else {
        setContactInfo((prev) => ({
          ...prev,
          disciplines: _.uniq([...prev.disciplines, value.value]),
        }));
      }
    },
    [setContactInfo, setNewDisciplines]
  );

  const removeDiscipline = (id) => {
    setContactInfo((prev) => ({
      ...prev,
      disciplines: contactInfo.disciplines.filter((item) => item !== id),
    }));
  };

  const change = useCallback(
    (value, id, type, subType) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: contactInfo[type]?.map((item, index) => {
          if (index === id) {
            if (subType) {
              return {
                ...item,
                [`${subType}`]: value,
              };
            }
            if (type === "phoneNumbers") {
              return { ...item, value: formatPhoneNumber(value, item?.value) };
            }
            return { ...item, value };
          }
          return item;
        }),
      }));
    },
    [contactInfo, setContactInfo]
  );

  const changeType = useCallback(
    (value, id, type) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: contactInfo[type]?.map((item, index) => {
          if (typeof value !== "string" && index === id)
            return { ...item, type: value?.label, label: value?.label };
          if (index === id) {
            return { ...item, type: value, label: value };
          }
          return item;
        }),
      }));
    },
    [contactInfo, setContactInfo]
  );

  const remove = useCallback(
    (id, type) => {
      const newValues = contactInfo[type]?.filter(
        (item, index) => index !== id
      );

      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: newValues,
      }));
    },
    [contactInfo, setContactInfo]
  );

  const [nextInputType, setNextInputType] = useState(null);
  const inputRefs = useRef({
    phoneNumbers: {},
    emails: {},
    addresses: {},
    websites: {},
    dates: {},
    socials: {},
    notes: null,
  });

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event, idx) => {
      // Checks if Enter key is pressed
      if (event.keyCode === 13) {
        event.preventDefault();
        const currentInputCategory = event.target.name;
        /** If a current form Category has multiple entries, move cursor to next entry field
         * within the same category (e.g. for a client that has multiple email fields open)
         */
        if (contactInfo[currentInputCategory].length > idx + 1) {
          const nextInputField =
            inputRefs.current[currentInputCategory][(idx + 1).toString()];
          nextInputField.focus();
        } else {
          const inputCategories = Object.keys(inputRefs.current);
          const currentInputCategoryIdx =
            inputCategories.indexOf(currentInputCategory);
          const nextCategory = inputCategories[currentInputCategoryIdx + 1];
          /* Checks if contactInfo is setup to receive data for next input field;
           * If not, it calls/simulates onClick by calling add() to open input field
           */
          if (contactInfo[nextCategory].length === 0) {
            if (nextCategory === "dates")
              add(nextCategory, "Birthday", new Date());
            else if (nextCategory !== "notes") add(nextCategory);
          }
          setNextInputType(nextCategory);
        }
      }
    },
    [add, contactInfo]
  );
  /**
   * Ensures ref is set on conditionally rendered input field in order to
   * move the cursor there on pressing Enter
   */
  useEffect(() => {
    if (nextInputType) {
      let next;
      if (nextInputType === "dates")
        next = inputRefs.current[nextInputType][0]?.input;
      else if (nextInputType === "notes") next = inputRefs.current.notes;
      // eslint-disable-next-line prefer-destructuring
      else next = inputRefs.current[nextInputType][0];
      next?.focus();
    }
    return () => setNextInputType(null);
  }, [nextInputType]);

  const getAvailableOptions = (field, options) => {
    const traversedOptions = field.map((val) => val.type);

    return options.filter((opt) => traversedOptions.indexOf(opt) === -1);
  };

  const addTerritory = (value) => {
    setContactInfo((prev) => ({
      ...prev,
      territories: _.uniqBy(
        [
          ...prev.territories,
          {
            area: value.city,
            cityCode: value.timezone,
          },
        ],
        "area"
      ),
    }));
  };

  const removeTerritory = (area) => {
    setContactInfo((prev) => ({
      ...prev,
      territories: prev.territories.filter(
        (t) => t.area.toLowerCase() !== area.toLowerCase()
      ),
    }));
  };

  return (
    <div
      className={
        isEditing
          ? "grid grid-flow-row grid-cols-3 grid-rows-1 gap-4"
          : "flex flex-col"
      }
    >
      <div>
        <div
          className="p-6 border rounded"
          onMouseOver={(e) => handleHovering(e, true)}
          onFocus={(e) => handleHovering(e, true)}
          onMouseOut={(e) => handleHovering(e, false)}
          onBlur={(e) => handleBlur(e, false)}
          role="presentation"
        >
          <div className="flex justify-between items-center w-full">
            <h3 className="text-lg font-medium text-gray-300">Contact Info</h3>
            <IconButton
              wrapperClassName={hovering ? "" : "hidden"}
              imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              onClick={toggleEdit}
              icon={closeIcon}
            />
          </div>
          <div className="border-b pb-4">
            {contactInfo?.phoneNumbers?.map((phone, index) => (
              <div className="mt-2 w-2/3 pr-8" key={phone?.value}>
                <Select
                  options={getAvailableOptions(
                    contactInfo?.phoneNumbers,
                    phoneOptions
                  )}
                  value={contactInfo?.phoneNumbers[index].type}
                  onChange={(type) => changeType(type, index, "phoneNumbers")}
                  disabled={saving}
                />
                <div className="flex items-center">
                  <Input
                    placeholder="Phone Number"
                    mainClassName="w-full"
                    validation={yup
                      .string()
                      .matches(phoneRegExp, "Phone number is not valid")}
                    value={contactInfo?.phoneNumbers[index].value}
                    onChange={(val) => change(val, index, "phoneNumbers")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "phoneNumbers")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.phoneNumbers[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="phoneNumbers"
                    autoFocus
                    isPhoneNumber
                    disabled={saving}
                  />
                </div>
              </div>
            ))}
            {contactInfo?.phoneNumbers?.length !== 4 && (
              <TertiaryButton
                iconLeft={<img alt="" src={plusIcon} />}
                title="Add phone"
                className={buttonCN(contactInfo?.phoneNumbers?.length)}
                onClick={() =>
                  add(
                    "phoneNumbers",
                    getAvailableOptions(
                      contactInfo?.phoneNumbers,
                      PhoneOptions
                    )[0]
                  )
                }
                disabled={saving}
              />
            )}

            {phoneValidationError && (
              <div className="py-2 ml-4">
                <p className="text-brandRed italic">{phoneValidationError}</p>
              </div>
            )}
          </div>
          <div className="border-b py-4 w-full">
            {contactInfo?.emails?.map((email, index) => (
              <div className="mt-2" key={email?.value}>
                <Select
                  options={getAvailableOptions(contactInfo?.emails, Options)}
                  value={contactInfo?.emails[index].type}
                  onChange={(type) => changeType(type, index, "emails")}
                  disabled={saving}
                />
                <div className="flex items-center">
                  <Input
                    placeholder="Email"
                    className="pr-3"
                    inputContainerClassName="flex-none w-2/3"
                    mainClassName="w-full"
                    validation={yup.string().email().required()}
                    value={contactInfo?.emails[index].value}
                    onChange={(val) => change(val, index, "emails")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "emails")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.emails[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="emails"
                    autoFocus
                    disabled={saving}
                  />
                </div>
              </div>
            ))}
            {contactInfo?.emails?.length !== 2 && (
              <TertiaryButton
                iconLeft={<img alt="" src={plusIcon} />}
                title="Add email"
                className={buttonCN(contactInfo?.emails?.length)}
                onClick={() =>
                  add(
                    "emails",
                    getAvailableOptions(contactInfo?.emails, Options)[0]
                  )
                }
                disabled={saving}
              />
            )}

            {emailValidationError && (
              <div className="py-2 ml-4">
                <p className="text-brandRed italic">{emailValidationError}</p>
              </div>
            )}
          </div>
          <div className="border-b py-4">
            {contactInfo?.addresses?.map((address, index) => (
              <div
                className="grid grid-cols-12 gap-2 mt-2 flex-nowrap"
                key={address?.value}
              >
                <div className="col-span-11">
                  <Select
                    options={getAvailableOptions(
                      contactInfo?.addresses,
                      Options
                    )}
                    value={contactInfo?.addresses[index].type}
                    onChange={(type) => changeType(type, index, "addresses")}
                    disabled={saving}
                  />
                  <div className="flex items-center">
                    <div className="mr-2 mb-2 w-full">
                      <BaseAddressInput
                        remove={remove}
                        saving={saving}
                        index={index}
                        onChange={(val) =>
                          changeAddress(val, index, "addresses")
                        }
                        address={contactInfo?.addresses[index]}
                        onForwardRef={(el) => {
                          inputRefs.current.addresses[index.toString()] = el;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="items-center col-span-1 inline-block self-start pt-16">
                  <RemoveIcon
                    onClick={() => !saving && remove(index, "addresses")}
                  />
                </div>
              </div>
            ))}
            {contactInfo?.addresses?.length !== 5 && (
              <TertiaryButton
                iconLeft={<img alt="" src={plusIcon} />}
                title="Add address"
                className={buttonCN(contactInfo?.addresses?.length)}
                onClick={() =>
                  add(
                    "addresses",
                    getAvailableOptions(contactInfo?.addresses, Options)[0]
                  )
                }
                disabled={saving}
              />
            )}
          </div>
          <div className="pt-4">
            {contactInfo?.websites?.map((website, index) => (
              <div className="mt-2" key={website?.value}>
                <Select
                  options={getAvailableOptions(contactInfo?.websites, Options)}
                  value={contactInfo?.websites[index].type}
                  onChange={(type) => changeType(type, index, "websites")}
                  disabled={saving}
                />
                <div className="flex items-center">
                  <Input
                    placeholder="Website Url"
                    mainClassName="w-full"
                    validation={simpleUrl}
                    value={contactInfo?.websites[index].value}
                    onChange={(val) => change(val, index, "websites")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "websites")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.websites[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="websites"
                    autoFocus
                    disabled={saving}
                  />
                </div>
              </div>
            ))}
            <TertiaryButton
              iconLeft={<img alt="" src={plusIcon} />}
              title="Add website"
              className={buttonCN(contactInfo?.websites?.length)}
              onClick={() =>
                add(
                  "websites",
                  getAvailableOptions(contactInfo?.websites, Options)[0]
                )
              }
              disabled={saving}
            />
          </div>
        </div>
      </div>
      {isEditing && (
        <div>
          <div
            className="p-6 border rounded"
            onMouseOver={(e) => handleHovering(e, true)}
            onFocus={(e) => handleHovering(e, true)}
            onMouseOut={(e) => handleHovering(e, false)}
            onBlur={(e) => handleBlur(e, false)}
            role="presentation"
          >
            <div className="flex justify-between items-center w-full">
              <h3 className="text-lg font-medium text-gray-300">
                Personal Profile
              </h3>
              <IconButton
                wrapperClassName={hovering ? "" : "hidden"}
                imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
                iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
                onClick={toggleEdit}
                icon={closeIcon}
              />
            </div>
            {contactInfo?.dates?.map((date, index) => (
              <div className="mt-2" key={date?.value}>
                <SelectOne
                  options={getAvailableOptions(
                    contactInfo?.dates,
                    dateOptions
                  ).map((opt) => ({
                    label: opt,
                    value: opt,
                  }))}
                  value={contactInfo?.dates[index].type}
                  onChange={(type) => changeType(type, index, "dates")}
                  hideText
                  type="date"
                  disabled={saving}
                />
                <div className="flex items-center">
                  <DatePicker
                    className="flex-1 min-w-0 mr-2"
                    validation={yup.date()}
                    value={contactInfo?.dates[index].value}
                    onChange={(val) => change(val, index, "dates")}
                    showValidationErrorAtBottom
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.dates[index.toString()] = el)
                    }
                    name="dates"
                    disabled={saving}
                  />
                  <div>
                    <RemoveIcon
                      onClick={() => !saving && remove(index, "dates")}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="border-b pb-4">
              <TertiaryButton
                iconLeft={<img alt="" src={plusIcon} />}
                title="Add date"
                className={buttonCN(contactInfo?.dates?.length)}
                onClick={() =>
                  add(
                    "dates",
                    getAvailableOptions(contactInfo?.dates, dateOptions)[0],
                    new Date()
                  )
                }
                disabled={saving}
              />
            </div>
            <div className="border-b py-4">
              {contactInfo?.socials?.map((social, index) => (
                <div className="mt-2" key={social?.value}>
                  <SelectOne
                    options={getAvailableOptions(
                      contactInfo?.socials,
                      socialOptions
                    ).map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    value={contactInfo?.socials[index].type}
                    onChange={(type) => changeType(type, index, "socials")}
                    hideText
                    type="social"
                    disabled={saving}
                  />
                  <div className="flex items-center">
                    <Input
                      placeholder="Socials"
                      mainClassName="w-full"
                      validation={yup.string()}
                      value={contactInfo?.socials[index].value}
                      onChange={(val) => change(val, index, "socials")}
                      onRemove={() => remove(index, "socials")}
                      // eslint-disable-next-line no-return-assign
                      forwardedRef={(el) =>
                        (inputRefs.current.socials[index.toString()] = el)
                      }
                      handleEnter={(event) => handleEnter(event, index)}
                      name="socials"
                      autoFocus
                      disabled={saving}
                    />
                  </div>
                </div>
              ))}

              <TertiaryButton
                iconLeft={<img alt="" src={plusIcon} />}
                title="Add social"
                className={buttonCN(contactInfo?.socials?.length)}
                onClick={() =>
                  add(
                    "socials",
                    getAvailableOptions(contactInfo?.socials, socialOptions)[0]
                  )
                }
                disabled={saving}
              />
            </div>
            {contactInfo?.kind !== "company" && (
              <div className="py-4">
                <Dropdown
                  label="Gender"
                  options={GENDER_TYPES}
                  value={GENDER_TYPES.find(
                    (gender) => gender.value === contactInfo?.gender
                  )}
                  disableClear
                  onChange={changeGender}
                  isDisabled={saving}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        onMouseEnter={(e) => handleHovering(e, true)}
        onMouseLeave={(e) => handleHovering(e, false)}
        onBlur={(e) => handleBlur(e, false)}
        role="presentation"
      >
        <div
          className={`${isEditing ? "border rounded p-6" : "mt-6"}`}
          onFocus={(e) => handleHovering(e, true)}
        >
          <div className="flex justify-between items-center w-full mb-2">
            <h3 className="text-lg font-medium text-gray-300">
              Additional Information
            </h3>
            <IconButton
              wrapperClassName={hovering ? "" : "hidden"}
              imgClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              iconClassName={`${isEditing ? "w-4 h-4" : "w-6 h-6"}`}
              onClick={toggleEdit}
              icon={closeIcon}
            />
          </div>
          <div className="mb-6">
            <p className="ESInputLabel">Notes</p>
            <Input
              isTextarea
              placeholder="Notes"
              value={contactInfo?.notes}
              onChange={(val) =>
                setContactInfo((prev) => ({ ...prev, notes: val }))
              }
              // eslint-disable-next-line no-return-assign
              forwardedRef={(el) => (inputRefs.current.notes = el)}
              name="notes"
              disabled={saving}
            />
          </div>
          <hr className="mb-6" />
          <div className="mt-4">
            <p className="ESInputLabel">Territories</p>
            <WorldClockSelect
              key={uuidv4()}
              handleCustomCityChange={addTerritory}
              handleBlur={() => {}}
              autoFocus={false}
            />
            <div className="mt-4">
              {contactInfo?.territories?.map((territory) => {
                return (
                  <div className="flex mb-2" key={territory.area}>
                    <div className="mr-2">{territory.area}</div>
                    <RemoveIcon
                      onClick={() => removeTerritory(territory.area)}
                      role="button"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="mb-6 mt-6" />
          <div className="mt-4">
            <Dropdown
              labelClassName="text-gray-300 self-center"
              label="Disciplines"
              name="Disciplines"
              options={disciplinesOptions}
              tooltipData={DICSIPLINES_TOOLTIP_DATA}
              tooltipDataWrapperClassName="mb-1.5"
              toolTipStyle={{
                overflowY: "auto",
                width: "350px",
                left: "700px",
                color: "6a6a6a",
              }}
              onChange={changeDiscipline}
              placeholder="Select"
              isCreatable
            />
            <div className="mt-4">
              {contactInfo?.disciplines?.map((id) => {
                return (
                  <div className="flex mb-2" key={id}>
                    <div className="mr-2">
                      {
                        disciplinesOptions?.find((item) => item.value === id)
                          ?.label
                      }
                    </div>
                    <RemoveIcon
                      onClick={() => removeDiscipline(id)}
                      role="button"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="mb-6 mt-6" />
          <TagsContainer
            isEditing={isEditing}
            className="flex-col w-full"
            tagsWrapperClassName="mt-6"
            resource={contactResource}
            dispatch={contactDispatch}
          />
        </div>
      </div>
    </div>
  );
};

ContactInfoForm.propTypes = {
  contactInfo: PropTypes.shape({
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape),
    emails: PropTypes.arrayOf(PropTypes.shape),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        street: PropTypes.string,
        street2: PropTypes.string,
        address1: PropTypes.shape({}),
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
      })
    ),
    websites: PropTypes.arrayOf(PropTypes.shape),
    dates: PropTypes.arrayOf(PropTypes.shape),
    socials: PropTypes.arrayOf(PropTypes.shape),
    notes: PropTypes.string,
    kind: PropTypes.string,
    gender: PropTypes.string,
    disciplines: PropTypes.arrayOf(PropTypes.string),
    territories: PropTypes.arrayOf(
      PropTypes.shape({
        area: PropTypes.string,
        cityCode: PropTypes.string,
      })
    ),
  }),
  setContactInfo: PropTypes.func,
  emailValidationError: PropTypes.func,
  phoneValidationError: PropTypes.func,
  saving: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  setIsEditing: PropTypes.func,
  setTabIndex: PropTypes.func,
  setNewDisciplines: PropTypes.func,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      contact: PropTypes.shape({
        disciplines: PropTypes.arrayOf({
          display: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  }),
  setEditingContact: PropTypes.func,
  contact: PropTypes.bool,
  contactResource: PropTypes.shape({}),
  contactDispatch: PropTypes.func,
};

ContactInfoForm.defaultProps = {
  contactInfo: undefined,
  setContactInfo: undefined,
  emailValidationError: undefined,
  phoneValidationError: undefined,
  saving: false,
  isEditing: false,
  onSave: undefined,
  setIsEditing: undefined,
  setTabIndex: undefined,
  setNewDisciplines: undefined,
  managementConfiguration: undefined,
  setEditingContact: undefined,
  contact: false,
  contactResource: undefined,
  contactDispatch: undefined,
};

export default ContactInfoForm;
