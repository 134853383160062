import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { useAppState } from "../../../state/appState";
import useExpenseDocuments from "../../../hooks/useExpenseDocuments";
import { getFullName } from "../../../helpers/Formatters";
import { getAssociationNameFromReference } from "../../../helpers/Utilities";
import PureExpenseTable from "./PureExpenseTable";
import { ADD_OPEN_MODAL, EXPENSE_CREATE_MODAL } from "../../../constants";
import { useGetExpenses } from "../../../hooks/useExpenses";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import { useModalState } from "../../../state/modalState";

const ExpenseTable = ({
  associationLock,
  assetLock,
  setCreateExpenseModal,
  openNewExpenseModal,
  hideHeader,
  isTabView,
  setButtonActions,
  hideSiteHeaderTitle,
  hideSwitchView,
}) => {
  const { assetId, projectId, propertyId } = useParams();
  const [, modalDispatch] = useModalState();

  const { data, isLoading } = useGetExpenses(assetId, projectId, propertyId);
  const { csiCodes } = useExpenseDocuments();

  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();

  const [
    {
      userDict,
      propertiesDict,
      projectDict,
      assetsDict,
      financialsConfiguration,
      currentUser,
    },
  ] = useAppState();

  const templateSettings = useMemo(() => {
    if (!templatesConfiguration) return [];

    // Extract 'expense' templates
    const expenseTemplates = templatesConfiguration.templates?.expense;
    if (!expenseTemplates) return [];

    // Add 'isAdmin' property based on 'custom' property of the template
    const templatesWithAdminFlag = expenseTemplates.map((template) => ({
      ...template,
      isAdmin: !template.custom,
    }));

    return templatesWithAdminFlag;
  }, [templatesConfiguration]);

  useEffect(() => {
    // optionally add CTA to add event when in Calendar tab view
    if (isTabView && currentUser?.hasPermission?.("expense", "can_write")) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add Expense")) {
          return [
            {
              title: "Add Expense",
              onClick: () => {
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: EXPENSE_CREATE_MODAL,
                  modalData: {
                    viewMode: "create",
                    associationLock,
                    assetLock,
                  },
                });
              },
              tabAction: true,
            },
            ...prev,
          ];
        }
        return prev;
      });
    }
  }, [
    associationLock,
    assetLock,
    currentUser,
    isTabView,
    modalDispatch,
    setButtonActions,
  ]);

  useEffect(() => {
    setCreateExpenseModal(openNewExpenseModal);
  }, [openNewExpenseModal, setCreateExpenseModal]);

  const expenseData = useMemo(() => {
    if (userDict) {
      const mappedEntries = data?.expenses?.map((entry) => {
        const userRef = entry?.metadata?.createdBy;
        const user = userDict[userRef];
        const employeeName = getFullName(user?.name);

        // If `asset` shows the Asset name instead
        const isAssetAssociated = !!entry?.asset;

        const associationName = getAssociationNameFromReference(
          isAssetAssociated ? entry?.asset : entry.association,
          propertiesDict,
          projectDict,
          assetsDict
        );
        const csiCodeObj = csiCodes?.find(
          (c) =>
            c.value ===
            `${entry?.financialCode?.division} ${entry?.financialCode?.code} ${entry?.financialCode?.subcode}`
        );

        return {
          ...entry,
          financialCode: csiCodeObj?.label,
          associationName,
          employeeName,
          category:
            financialsConfiguration?.financials?.expense?.categories?.find(
              (category) => category.id === entry?.category
            )?.display,
        };
      });

      return mappedEntries;
    }

    return [];
  }, [
    userDict,
    assetsDict,
    projectDict,
    propertiesDict,
    data?.expenses,
    csiCodes,
    financialsConfiguration,
  ]);

  const handleShowDetails = useCallback(
    (row) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        modalData: {
          expenseData: data?.expenses[row?.index],
          viewMode: "viewDetails",
        },
        ref: { id: uuidv4() },
        modalType: EXPENSE_CREATE_MODAL,
      });
    },
    [data?.expenses, modalDispatch]
  );

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "expense",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return { ...view, custom: true };
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "expense",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  return (
    <div className={`relative ${isLoading && "loading"} min-h-200`}>
      <PureExpenseTable
        expenseData={expenseData}
        setCreateExpenseModal={setCreateExpenseModal}
        isLoading={isLoading}
        onRowClick={handleShowDetails}
        hideHeader={hideHeader}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        hideSwitchView={hideSwitchView}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        fileActionsIcons={<TableActionsIconsGroup showColumnSettingsRight />}
      />
    </div>
  );
};

ExpenseTable.propTypes = {
  associationLock: PropTypes.string,
  assetLock: PropTypes.string,
  setCreateExpenseModal: PropTypes.func,
  openNewExpenseModal: PropTypes.bool,
  hideHeader: PropTypes.bool,
  setButtonActions: PropTypes.func,
  isTabView: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
  hideSwitchView: PropTypes.bool,
};

ExpenseTable.defaultProps = {
  associationLock: undefined,
  assetLock: undefined,
  setCreateExpenseModal: () => {},
  openNewExpenseModal: false,
  hideHeader: false,
  setButtonActions: () => {},
  isTabView: false,
  hideSiteHeaderTitle: false,
  hideSwitchView: false,
};

export default ExpenseTable;
