import React, { useCallback, useEffect, useState } from "react";
import cntl from "cntl";
import * as yup from "yup";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import PrimaryButton from "../Buttons/PrimaryButton";
import CrossButton from "../CrossButton/CrossButton";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";
import Dropdown from "../Dropdown/Dropdown";
import {
  formatCountriesDropdown,
  getStatesDropdown,
} from "../../../helpers/Address";

const countryList = require("country-list");

const popOverCN = cntl`
  bg-white
  flex
  flex-col
  pt-2
  px-2
  overflow-x-auto
  sticky
  left-0
  pb-24
`;

const rowCN = cntl`
  flex
`;

const rowItemCN = cntl`
  px-2
  flex-1
  min-w-max
`;

const PropertyTableInLineForm = ({
  row,
  togglePopOver,
  isEdit,
  propertyTypes,
  onAddSaveCallback,
  onEditSaveCallback,
  prefillFieldsOnAddAnother,
}) => {
  const initialAddState = useCallback(() => {
    const rv = {};

    if (row?.original?.addAnother) {
      prefillFieldsOnAddAnother.map((columnId) => {
        rv[columnId] = row.original[columnId];
        return columnId;
      });
    }

    return rv;
  }, [prefillFieldsOnAddAnother, row]);

  const [lineItem, dispatch] = usePropertyFormReducer(
    isEdit ? row.original : initialAddState()
  );

  const [addAnother, setAddAnother] = useState(row?.original?.addAnother);
  const [isSaving, setIsSaving] = useState(false);
  const [dropdownStates, setDropdownStates] = useState([]);
  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    const states = getStatesDropdown();
    setDropdownStates(states);

    const countries = countryList.getNameList();
    const list = formatCountriesDropdown(countries);
    setDropdownCountries(list);
  }, []);

  const onEditSave = useCallback(async () => {
    setIsSaving(true);
    const editedLineItem = { ...lineItem };

    await onEditSaveCallback(editedLineItem);
    setIsSaving(false);
    togglePopOver();
  }, [lineItem, onEditSaveCallback, togglePopOver]);

  const onAddSave = useCallback(async () => {
    setIsSaving(true);
    if (addAnother) {
      lineItem.addAnother = true;
    }
    const createdProperty = { ...lineItem };

    await onAddSaveCallback(createdProperty);
    setIsSaving(false);
  }, [lineItem, onAddSaveCallback, addAnother]);

  // list of column content to be shown in the popover
  const columns = [
    {
      content: (
        <>
          <Input
            label="Name"
            placeholder="Name"
            value={lineItem?.title}
            onChange={(newVal) => {
              dispatch({
                type: "title",
                title: newVal,
              });
            }}
            validation={yup.string().required()}
          />
          <Dropdown
            label="Type"
            options={propertyTypes}
            value={propertyTypes.find(
              (type) => type.value === lineItem?.propertyType
            )}
            onChange={(newVal) => {
              dispatch({
                type: "propertyType",
                propertyType: newVal?.value,
              });
            }}
            validation={yup.mixed().required()}
          />
        </>
      ),
    },
    {
      content: (
        <Input
          label="Description"
          isTextarea
          placeholder="Description"
          value={lineItem?.description}
          onChange={(newVal) => {
            dispatch({
              type: "description",
              description: newVal,
            });
          }}
          validation={yup.string()}
        />
      ),
    },
    {
      content: (
        <>
          <Input
            label="Street Address 1"
            placeholder="Address 1"
            value={lineItem?.address?.street}
            onChange={(newVal) => {
              dispatch({
                type: "street",
                street: newVal,
              });
            }}
            validation={yup.string()}
          />
          <Input
            label="Street Address 2"
            placeholder="Address 2"
            value={lineItem?.address?.street2}
            onChange={(newVal) => {
              dispatch({
                type: "street2",
                street2: newVal,
              });
            }}
            validation={yup.string()}
          />
          <div className="flex items-center mb-2">
            <Input
              className="flex-3 mb-2 mr-4"
              name="city"
              label="City"
              placeholder="City"
              value={lineItem?.address?.city}
              onChange={(newVal) => {
                dispatch({
                  type: "city",
                  city: newVal,
                });
              }}
              validation={yup.string()}
            />
            <Dropdown
              className="flex-2 mb-2"
              name="state"
              label="State"
              options={dropdownStates}
              value={dropdownStates?.find(
                (opt) => opt.value === lineItem?.address?.state
              )}
              onChange={({ value: val }) => {
                dispatch({
                  type: "state",
                  state: val,
                });
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <Dropdown
              className="flex-3 mb-2 mr-4"
              name="country"
              label="Country"
              options={dropdownCountries}
              value={dropdownCountries?.find(
                (opt) => opt.value === lineItem?.address?.country
              )}
              onChange={({ value: val }) => {
                dispatch({
                  type: "country",
                  country: val,
                });
              }}
              disableSort
            />
            <Input
              className="flex-2 mb-2"
              name="zipCode"
              label="Postal Code"
              placeholder="Postal Code"
              value={lineItem?.address?.zipCode}
              onChange={(val) => {
                dispatch({
                  type: "zipCode",
                  zipCode: val,
                });
              }}
              validation={yup.string()}
            />
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <Input
            label="Acreage"
            placeholder="Acreage"
            value={lineItem?.lotSize?.value ?? 0}
            onChange={(newVal) => {
              dispatch({
                type: "lotSize",
                lotSize: newVal,
              });
            }}
            validation={yup
              .number()
              .positive()
              .transform((v, o) => (o === "" ? undefined : v))}
          />
          <Input
            label="Purchase Price"
            placeholder="Price"
            value={lineItem?.purchase?.price}
            onChange={(newVal) => {
              dispatch({
                type: "purchasePrice",
                purchasePrice: newVal,
              });
            }}
            validation={yup
              .number()
              .positive()
              .transform((v, o) => (o === "" ? undefined : v))}
          />
        </>
      ),
    },
  ];

  return (
    <div className={popOverCN}>
      <div className="flex justify-end py-2">
        <CrossButton className="color" onClick={() => togglePopOver()} />
      </div>
      <div className={rowCN}>
        {columns?.map((option) => (
          <div className={rowItemCN} key={option.id}>
            {option.content}
          </div>
        ))}
        <div className="flex flex-col px-2 pt-7">
          <PrimaryButton
            title="Save"
            className="mb-2"
            onClick={async () => {
              if (isEdit) {
                onEditSave();
              } else {
                await onAddSave();
              }
            }}
            disabled={
              isSaving || !lineItem?.propertyType || lineItem.title === ""
            }
          />
          {!isEdit && (
            <Checkbox
              className="flex items-center"
              label="Add Another"
              checked={addAnother}
              onChange={setAddAnother}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PropertyTableInLineForm.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number,
    original: {
      line: PropTypes.string,
    },
  }),
  togglePopOver: PropTypes.func,
  isEdit: PropTypes.bool,
  onAddSaveCallback: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  propertyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  prefillFieldsOnAddAnother: PropTypes.arrayOf(PropTypes.string),
};

PropertyTableInLineForm.defaultProps = {
  row: undefined,
  togglePopOver: undefined,
  isEdit: false,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  propertyTypes: [],
  prefillFieldsOnAddAnother: [],
};

export default PropertyTableInLineForm;
