import moment from "moment";
import { RRule, RRuleSet } from "rrule";

// format rrule generated dates in utc
const fromRRuleOutput = (d) => {
  // this date is in local system's timezone without "Z".
  // Ref: https://www.npmjs.com/package/rrule#important-use-utc-dates
  const formattedDate = new Date(d).toISOString().split("Z")[0];
  // convert to utc format
  const date = moment.utc(new Date(formattedDate).toISOString());
  return date.format("YYYY-MM-DDTHH:mm:ssZ");
};

const returnRecurrenceDates = ({ recurrence, sd }) => {
  // Initializing a new rule set for recurrence rules
  const rRuleSet = new RRuleSet();

  // Adding the recurrence rule to the rule set
  rRuleSet.rrule(
    new RRule({
      ...RRule.parseString(recurrence), // Parsing the rule string
    })
  );

  // Calculate the start and end dates for the range
  const startDate = moment(sd)
    .startOf("month")
    .startOf("day")
    .subtract(3, "months");
  const endDate = moment(sd).endOf("month").endOf("day").add(3, "months");

  // Getting all the dates that match the recurrence rule
  const dates = rRuleSet.between(startDate.toDate(), endDate.toDate());

  // Getting all the dates that match the recurrence rule within the range
  const formattedDates = dates.map((date) => {
    return fromRRuleOutput(date);
  });

  // Returning the formatted dates
  return formattedDates;
};

// Exporting the function
export default returnRecurrenceDates;
