/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTable } from "react-table";
import PropTypes from "prop-types";
import _ from "lodash";

import cntl from "cntl";
import { RoleAPI, UserAPI } from "@griffingroupglobal/eslib-api";
import {
  ROLES_PERMISSIONS_CREATE_PATH,
  ROLES_PERMISSIONS_EDIT_PATH,
  ROLES_PERMISSIONS_VIEW_PATH,
  TABLE_COLUMN_WIDTH,
} from "../../constants";
import RolesTableRowProps from "../../props/RolesTableRowProps";
import RoleMembersAvatarList from "./RoleMembersAvatarList";
import { transformUsersResponseToUserAvatars } from "../../helpers/User";
import moreInformationIcon from "../../stories/assets/images/moreInformation.svg";
import BaseButton from "../../stories/Components/Buttons/BaseButton";

const associatedUserCN = (membersLength) => cntl`
  bg-gray-150
  border-2
  border-gray-100
  z-40
  rounded-full
  ${membersLength > 9 ? "w-full p-1" : "w-8"}
  h-8
  flex
  items-center
  justify-center
`;

const ViewAllButton = ({ members }) => {
  return (
    <div className={associatedUserCN(members.length)}>
      <p className="uppercase text-gray-400 text-sm font-semibold truncate">
        +{members?.length - 5}
      </p>
    </div>
  );
};

/**
 * Renders the table cell with the Role name.
 * @param roleName The name of the Role
 */
function RoleCell({ value: roleName }) {
  return <span className="text-sm font-normal tracking-wider">{roleName}</span>;
}

RoleCell.propTypes = {
  value: PropTypes.string.isRequired,
};

/**
 * Renders the table cell with the member initials.
 */
function MembersCell({ value: members }) {
  return (
    <RoleMembersAvatarList
      members={members}
      viewAllClassName="text-xs"
      viewAllButton={<ViewAllButton members={members} />}
      tooltipClassName="top-8 left-8 z-10"
      showRightTooltip
    />
  );
}

MembersCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

/**
 * Render the actions menu available for a role.
 *
 * @param id The id value for a role
 * @param isAdmin Whether the user viewing the roles table is an admin
 */
function RoleActionsCell({ value: { id, isAdmin } }) {
  const history = useHistory();

  const getRowOptions = useCallback(() => {
    const options = [
      {
        title: "View Permissions",
        onClick: () => history.push(ROLES_PERMISSIONS_VIEW_PATH(id)),
      },
    ];
    if (!isAdmin) {
      options.push({
        title: "Edit Role",
        onClick: () => history.push(ROLES_PERMISSIONS_EDIT_PATH(id)),
      });
    }
    return options;
  }, [history, id, isAdmin]);

  return (
    <BaseButton
      className="flex items-center justify-center"
      iconRight={<img src={moreInformationIcon} alt="" />}
      dropdownItems={getRowOptions()}
    />
  );
}

RoleActionsCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
};

/**
 * Constant definition for the columns for the Roles & Permissions table.
 */
const RolesWithMembersColumns = () => {
  return [
    {
      Header: "Role",
      accessor: "role",
      width: 300,
      Cell: (props) => <RoleCell {...props} />,
    },
    {
      Header: "Members",
      accessor: "members",
      width: TABLE_COLUMN_WIDTH,
      Cell: (props) => <MembersCell {...props} />,
    },
    {
      Header: "",
      accessor: "actionProps",
      width: 100,
      Cell: (props) => <RoleActionsCell {...props} />,
    },
  ];
};

/**
 * Component to render the Roles & Permissions table. Uses react-table.
 * @param data Roles and Members to render.
 * @param className (Optional) className values for the table.
 */
function RolesWithMembersTable({ data, className }) {
  const columns = React.useMemo(() => RolesWithMembersColumns(), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()} className={className}>
      <thead className="text-left border-b border-b-black">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={headerGroup.headers.map(({ Header }) => Header).join("-")}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                key={column?.id}
                className="text-sm font-semibold tracking-wider py-2"
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="border-b" key={row?.id}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="py-2"
                    key={cell?.value}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

RolesWithMembersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

RolesWithMembersTable.defaultProps = {
  className: "",
};

/**
 * Renders the Roles and Permissions tab in the Account & Admin section.
 *
 * TODO: ES-2949 Integrate Roles & Permissions table with Get Roles and Get Role Members apis
 */
export default function RolesAndPermissions() {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [roleMembers, setRoleMembers] = useState({});
  const tableData = React.useMemo(
    () =>
      _.chain(roles)
        .sortBy("name")
        .map(
          (role) =>
            new RolesTableRowProps({
              role: role.name,
              id: role.id,
              members: roleMembers[role.id] ?? [],
            })
        )
        .value(),
    [roles, roleMembers]
  );

  /**
   * When the page is loaded, fetch the member roles
   */
  useEffect(() => {
    RoleAPI.get()
      .then(({ data }) =>
        data.entries.map((entry) => _.pick(entry.resource, ["name", "id"]))
      )
      .then(setRoles);
  }, []);

  /**
   * When Roles is updated, fetch the members for each role.
   */
  useEffect(() => {
    Promise.all(
      roles.map((r) =>
        UserAPI.get({ params: { role: `Role/${r.id}` } })
          .then(transformUsersResponseToUserAvatars)
          .then((memberAvatars) => [r.id, memberAvatars])
      )
    )
      .then(Object.fromEntries)
      .then(setRoleMembers);
  }, [roles]);

  return (
    <>
      <h1 className="text-xl font-medium uppercase tracking-widest">
        Member Roles
      </h1>

      <RolesWithMembersTable data={tableData} className="min-w-full mt-8" />

      <button
        type="button"
        className="mt-6 text-lg font-medium tracking-wider text-brandGreen"
        onClick={() => history.push(ROLES_PERMISSIONS_CREATE_PATH)}
      >
        + Add Role
      </button>
    </>
  );
}
