/* eslint-disable no-param-reassign */
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { spaceFormSchema } from "../../../helpers/FormValidations";
import useSpaceReducer from "../../../hooks/useSpaceReducer";
import { useAppState } from "../../../state/appState";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useManagementConfigurationPatch from "../../../hooks/useManagementConfigurationPatch";
import { getSpaceConfiguration } from "../../../helpers/Formatters";
import { SPACE_EMPTY } from "../../../constants";

const useCreateSpaceModalData = ({ onClose, onCreate }) => {
  const [space, dispatch] = useSpaceReducer();
  const [valid, setValid] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [{ currentUser }] = useAppState();
  const { data: managementConfiguration } = useManagementConfiguration();
  const configuration = getSpaceConfiguration(managementConfiguration);
  const { mutateAsync } = useManagementConfigurationPatch();

  const canCreateType = currentUser?.isAdmin;

  const initialSpace = useMemo(
    () => ({
      ...SPACE_EMPTY,
      id: space.id,
    }),
    [space.id]
  );

  useEffect(() => {
    const isSame = isEqual(initialSpace, space);
    setShowConfirm(!isSame);
  }, [initialSpace, space]);

  const handleClose = useCallback(() => {
    onClose();
    dispatch({ type: "reset" });
  }, [dispatch, onClose]);

  const handleCreate = useCallback(async () => {
    dispatch({ type: "reset" });
    if (space.isNewType) {
      mutateAsync({
        updatedManagement: {
          propertySpace: {
            types: [
              ...managementConfiguration.management.propertySpace.types,
              {
                custom: true,
                selected: true,
                display: space.type,
              },
            ],
          },
        },
      })
        .then((res) => {
          const newType = res.management.propertySpace?.types?.find(
            (item) => item.display === space.type
          );
          const { id: newId } = newType;
          const spaceWithNewType = {
            ...space,
            type: newId,
          };
          onCreate(spaceWithNewType);
        })
        .catch((e) => console.error("ERROR:", e));
    } else {
      // set area value to 0 if it is not defined
      const formattedSpace = {
        ...space,
        area: { value: space?.area.value || 0 },
      };
      onCreate(formattedSpace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onCreate, space]);

  const spaceTypeOptions = useMemo(
    () =>
      Object.values(configuration?.spaces ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.spaces]
  );

  const levelTypeOptions = useMemo(
    () =>
      Object.values(configuration?.levels ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.levels]
  );

  const changeName = useCallback(
    (name) => {
      dispatch({
        type: "name",
        name,
      });
    },
    [dispatch]
  );

  const changeArea = useCallback(
    (value) => {
      if (value !== null) {
        dispatch({
          type: "area",
          value,
        });
      }
    },
    [dispatch]
  );

  const changeLevel = useCallback(
    (value) => {
      dispatch({
        type: "level",
        value,
      });
    },
    [dispatch]
  );

  const changeType = useCallback(
    (value, isNewType) => {
      dispatch({
        type: "type",
        value,
        isNewType,
      });
    },
    [dispatch]
  );

  const changePrimaryImage = useCallback(
    ({ fileRef, image, category }) => {
      dispatch({
        type: "updatePrimaryImage",
        fileRef,
        image,
        category,
      });
    },
    [dispatch]
  );

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const validForm = await validationSchema.isValid(formData);
    setValid(validForm);
  }, []);

  useEffect(() => {
    checkValidation(space, spaceFormSchema);
  }, [checkValidation, space]);

  return {
    space,
    dispatch,
    setValid,
    handleClose,
    valid,
    handleCreate,
    checkValidation,
    changePrimaryImage,
    changeName,
    spaceTypeOptions,
    changeType,
    levelTypeOptions,
    changeLevel,
    changeArea,
    canCreateType,
    showConfirm,
  };
};

export default useCreateSpaceModalData;
