import { useCallback } from "react";
import moment from "moment";
import { useAppState } from "../state/appState";
import useReportById from "./useReportById";

/**
 * Query selector to return Report data in custom format
 * @param {string} reportId single report id to fetch
 * @returns report object in custom format:
 * original = raw data from backend
 * readOnly = transfomed data with associations, user information, etc
 */
const useReportSelectorById = (reportId) => {
  const [{ propertiesDict, projectDict, assetsDict }] = useAppState();

  const select = useCallback(
    (report) => {
      const associationSelected =
        propertiesDict?.[report.association] ||
        projectDict?.[report.association] ||
        assetsDict?.[report.association];

      return {
        original: report,
        readOnly: {
          ...report,
          createdAt: moment(report.metadata.createdAt).format("MMMM DD, YYYY"),
          association:
            (associationSelected?.name ?? associationSelected?.title) || "N/A",
        },
      };
    },
    [projectDict, propertiesDict, assetsDict]
  );

  return useReportById(reportId, select);
};

export default useReportSelectorById;
