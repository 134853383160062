import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AssetAPI, EventAPI } from "@griffingroupglobal/eslib-api";

import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";

import { getAssetOptions } from "../../../helpers/SiteHeaderOptions";
import AssetFormMaintenance from "../../../stories/Components/AssetForms/FormMaintenance";
import { resolvePrimaryImage } from "../../../helpers/File";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import useUsers from "../../../hooks/useUsers";

import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";

import { formatServerErrorMessage } from "../../../helpers/Formatters";

import MaintenanceScheduleModal from "../../../stories/Components/AssetForms/MaintenanceScheduleModal";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useAssets from "../../../hooks/useAssets";
import useAssetById from "../../../hooks/useAssetById";
import { getEventQueryLeftRight } from "../../../helpers/getEventQueryLeftRight";
import { useAppState } from "../../../state/appState";

const AssetMaintenance = ({ hideSideHeader }) => {
  const [{ currentUser, userDict }] = useAppState();
  const { assetId, propertyId, projectId, locationId, spaceId } = useParams();
  const { asset, assetMembers, setAsset } = useAssetById(assetId);
  const [assets] = useAssets({ assetId });
  const [users] = useUsers();
  const { data: managementConfiguration } = useManagementConfiguration();

  const [editedAsset, dispatch] = useAssetFormReducer();
  const [assetImage, setAssetImage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showGetStarted, setShowGetStarted] = useState(false);

  const fetchEvents = useCallback(() => {
    const getEvents = async () => {
      try {
        setIsLoading(true);
        const { left, right } = getEventQueryLeftRight(
          asset?.metadata?.createdAt
        );
        const { data } = await EventAPI.get({
          params: {
            left,
            right,
            association: `Asset/${asset?.id}`,
          },
        });
        setShowGetStarted(data?.entries?.length === 0);
        setEventsData(data?.entries);
        if (data?.entries?.length === 0) setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.warn("Failed to fetch events");
      }
    };

    getEvents();
  }, [asset?.id, asset?.metadata?.createdAt]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (asset?.id !== assetId && assets?.[0]?.id === assetId) {
      setAssetImage(assets?.[0]?.primaryImage?.contentsUrl ?? null);
    }
  }, [assets, asset, assetId]);

  const resetAssetState = React.useCallback(() => {
    dispatch({
      type: "reset",
      asset,
    });
  }, [asset, dispatch]);

  useEffect(() => {
    if (asset) {
      resetAssetState();
    }
  }, [asset, resetAssetState]);

  useEffect(() => {
    const resolvePrimary = async () => {
      if (asset?.images?.length) {
        const primaryImage = await resolvePrimaryImage(asset.images);
        setAsset((prev) => {
          return { ...prev, primaryImage };
        });
        setAssetImage(primaryImage?.contentsUrl);
      }
    };
    resolvePrimary();
  }, [asset.images, setAsset]);

  const onFinishEditing = React.useCallback(
    async (key, val) => {
      const finishedAsset = {
        ...editedAsset,
        [key]: val,
      };
      try {
        const newAsset = await AssetAPI.patch(assetId, finishedAsset, asset);
        setAsset((prev) => newAsset?.data ?? prev);
      } catch (err) {
        const serverMsg = formatServerErrorMessage(err);
        console.error(serverMsg);
      }
    },
    [editedAsset, assetId, asset, setAsset]
  );

  const handleChangeImage = (image) => {
    const newImage = { file: image, isPrimary: true };
    dispatch({
      type: "addImage",
      image: newImage,
    });

    let tempImages = [];
    if (editedAsset.images?.length) {
      tempImages = editedAsset.images;
    }
    onFinishEditing("images", [
      ...tempImages.map((item) => {
        return { ...item, isPrimary: false };
      }),
      newImage,
    ]);
  };

  const handleOnCloseModal = useCallback(async () => {
    setIsModalOpen(false);
  }, []);

  const handleUpdatedAsset = useCallback(
    (updated) => {
      setAsset(updated);
    },
    [setAsset]
  );

  return (
    <div className="mb-10">
      {!hideSideHeader && (
        <>
          <SiteHeader
            title={asset?.name}
            dropdownRoutes={
              editedAsset &&
              getAssetOptions(
                asset?.id,
                propertyId,
                projectId,
                locationId,
                spaceId
              )
            }
            buttons={
              currentUser?.hasPermission?.("task", "can_create") && (
                <PrimaryButton
                  title="+ Add Maintenance"
                  large
                  onClick={() => setIsModalOpen(true)}
                />
              )
            }
          />{" "}
          <DetailViewBanner
            resource={asset}
            resourceImage={assetImage}
            onChangeImage={handleChangeImage}
          />
        </>
      )}

      <AssetFormMaintenance
        maintenanceTitle="Asset Maintenance"
        setIsModalOpen={setIsModalOpen}
        isLoading={isLoading}
        setLoading={setIsLoading}
        showGetStarted={showGetStarted}
        eventsData={eventsData}
        maintenaceObject={asset}
        currentUser={currentUser}
        membersOptions={assetMembers}
        reloadObject={fetchEvents}
        users={users}
        usersDict={userDict}
        onFinishEditing={onFinishEditing}
        isTaskDurationRequired={
          managementConfiguration?.management?.managementTasks
            ?.isCompletionTimeRequired
        }
        association={{
          reference: editedAsset?.reference,
          name: editedAsset?.name,
          [asset?.property ? "property" : "project"]: asset?.property
            ? asset?.property?.split("/")[1]
            : asset?.project?.split("/")[1],
        }}
      />

      {isModalOpen && (
        <MaintenanceScheduleModal
          onCloseModal={handleOnCloseModal}
          assetMembersOptions={assetMembers}
          setUpdatedAsset={handleUpdatedAsset}
          reloadAsset={fetchEvents}
          association={{
            reference: editedAsset?.reference,
            name: editedAsset?.name,
            [asset?.property ? "property" : "project"]: asset?.property
              ? asset?.property?.split("/")[1]
              : asset?.project?.split("/")[1],
          }}
        />
      )}
    </div>
  );
};

export default AssetMaintenance;
