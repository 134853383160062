import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { getProjectSpaceOptions } from "../../helpers/SiteHeaderOptions";
import ProjectAssetList from "../Overviews/Asset/ProjectAssetList";
import DetailViewBanner from "../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../helpers/File";
import useProject from "../../hooks/useProject";
import useAssets from "../../hooks/useAssets";
import { maintenanceServiceData } from "../../helpers/MaintenanceUtilities";
import SiteHeaderAlert from "../../stories/Components/SiteHeader/SiteHeaderAlert";

const ProjectSpaceAssets = ({ currentUser }) => {
  const { projectId, locationId, spaceId } = useParams();
  const [project] = useProject(projectId);
  const [, , assetEvents] = useAssets({
    projectRef: `Project/${projectId}`,
    events: true,
    locationId,
    spaceId,
  });

  const [warningCount, setWarningCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [spaceImage, setSpaceImage] = useState();
  const [space, setSpace] = useState();

  useEffect(() => {
    const { dueSoons, pastDues } = maintenanceServiceData(assetEvents);
    const alerts = dueSoons?.filter(
      (item) =>
        moment(item.dueDate).diff(moment(new Date().toISOString()), "days") <= 5
    );

    setWarningCount(pastDues?.length);
    setAlertCount(alerts?.length);
  }, [assetEvents]);

  useEffect(() => {
    const locationResource = project?.buildings?.filter((loc) => {
      return loc.id === locationId;
    })[0];

    const spaceResource = locationResource?.spaces?.filter((spc) => {
      return spc.id === spaceId;
    })[0];

    setSpace(spaceResource);
    if (spaceResource?.images?.length) {
      resolvePrimaryImage(spaceResource.images)
        .then((imageFile) => setSpaceImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve project primary image:${err}`)
        );
    }
  }, [locationId, project, spaceId]);

  const getAlerts = () => (
    <div
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
      className="flex"
      aria-label="alert-header"
    >
      <SiteHeaderAlert total={warningCount} isRed onClick={() => {}} />
      <SiteHeaderAlert total={alertCount} onClick={() => {}} />
    </div>
  );

  return (
    <>
      <ProjectAssetList
        associatedResource={`Project/${projectId}`}
        locationId={locationId}
        spaceId={spaceId}
        siteHeaderProps={{
          title: space?.name || "Loading",
          dropdownRoutes: getProjectSpaceOptions(
            projectId,
            currentUser,
            locationId,
            spaceId
          ),
          alerts: getAlerts(),
        }}
        bannerComponent={
          <DetailViewBanner
            resource={project}
            resourceImage={spaceImage}
            disableUpload
          />
        }
        alertCount={alertCount}
        warningCount={warningCount}
        disableCreate={!currentUser?.hasPermission?.("asset", "can_write")}
      />
    </>
  );
};

ProjectSpaceAssets.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: () => {},
  }),
};

ProjectSpaceAssets.defaultProps = {
  currentUser: undefined,
};

export default ProjectSpaceAssets;
