import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { SHOW_COLUMNS_SETTING_RIGHT } from "../../../constants";
import useContactList from "./useContactList";
import CardsContactsView from "../../../stories/Components/ContactView/CardsContactsView";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import SiteHeaderAlphabetIndex from "../../../stories/Components/SiteHeader/SiteHeaderAlphabetIndex";
import Widget from "../../../stories/Components/Widget/Widget";
import Dropdown from "../../../stories/Components/Dropdown/Dropdown";
import SiteHeaderSwitchView from "../../../stories/Components/SiteHeader/SiteHeaderSwitchView";
import SiteHeaderSearch from "../../../stories/Components/SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../../../stories/Components/SiteHeader/SiteHeaderFilter";
import TableActionsIconsGroup from "../../../stories/Components/Table/TableActionsIconsGroup";
import Table from "../../../stories/Components/Table/Table";
import ImportContactsModal from "../../../stories/Components/ContactView/ImportContactsModal";
import { contactsViewColumns } from "../../../stories/Components/ContactView/ContactsViewColumns";

const contactHeader = ({
  setTabIndex,
  tabIndex,
  options,
  isFetching,
  isShowingTable,
  setIsShowingTable,
  onSearchChange,
  searchKeyword,
  allColumns,
  tableData,
  filters,
  setAllFilters,
  fileActionsIcons,
}) => (
  <div className="flex flex-col gap-2">
    <div className="flex flex-row gap-4 w-full items-center">
      <div className="flex" style={{ width: "288px" }}>
        <Dropdown
          onChange={({ value }) => setTabIndex(value)}
          placeholder={tabIndex === 0 ? "Quick View" : options[tabIndex]?.label}
          options={options}
        />
      </div>
      {!isFetching && (
        <SiteHeaderSwitchView
          isShowingTable={isShowingTable}
          setIsShowingTable={setIsShowingTable}
          className="flex ml-2"
        />
      )}
    </div>
    <div className="flex flex-row w-full gap-2 items-center">
      <SiteHeaderSearch
        handleSearch={(val) => {
          onSearchChange(val);
        }}
        globalFilter={searchKeyword}
      />
      <SiteHeaderFilter
        allColumns={allColumns}
        tableData={tableData}
        filters={filters}
        setAllFilters={setAllFilters}
      />
      <div className="flex flex-row items-center w-full h-8 justify-end">
        {fileActionsIcons}
      </div>
    </div>
  </div>
);

const options = [
  { label: "All", value: 0 },
  { label: "Company", value: 1 },
  { label: "Contact", value: 2 },
  { label: "Member", value: 3 },
];

// TODO(Parker) Noticed Filter Functionality and setGroupBy errors out Looking into that
// (so group options are not being used currently)
const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Tags",
      value: "tags",
    },
    {
      label: "Company",
      value: "companyName",
    },
  ],
};

const ContactList = ({ setShowMessages }) => {
  const {
    contacts,
    currentUser,
    sortedContacts,
    openChat,
    tabIndex,
    selectedRows,
    isShowingTable,
    setIsShowingTable,
    isFetching,
    setTabIndex,
    searchKeyword,
    allColumns,
    tableData,
    filters,
    setAllFilters,
    disciplines,
    history,
    showImportModal,
    setShowImportModal,
    onImport,
    handleRowSelect,
    handleRemoveUser,
    onSearchChange,
    buttonActions,
  } = useContactList({ setShowMessages });

  // Tabs for QuickView
  const tabs = useMemo(
    () => [
      {
        content: () => (
          <CardsContactsView
            contacts={contacts}
            currentUser={currentUser}
            sortedContacts={sortedContacts}
            openChat={openChat}
          />
        ),
      },
      {
        title: "Company",
        content: () => (
          <CardsContactsView
            contacts={contacts}
            currentUser={currentUser}
            sortedContacts={sortedContacts}
            openChat={openChat}
          />
        ),
      },
      {
        title: "Contact",
        content: () => (
          <CardsContactsView
            contacts={contacts}
            currentUser={currentUser}
            sortedContacts={sortedContacts}
            openChat={openChat}
          />
        ),
      },
      {
        title: "Members",
        content: () => (
          <CardsContactsView
            contacts={contacts}
            currentUser={currentUser}
            sortedContacts={sortedContacts}
            openChat={openChat}
          />
        ),
      },
    ],
    [contacts, currentUser, openChat, sortedContacts]
  );

  const renderTabs = () => {
    return <div style={{ minHeight: 320 }}>{tabs[tabIndex].content()}</div>;
  };

  return (
    <>
      <SiteHeader
        title="Contacts"
        buttons={
          <PrimaryButton
            large
            title="Actions"
            className="dropdown-btn"
            dropdownItems={buttonActions}
          />
        }
        alphabetIndex={
          <SiteHeaderAlphabetIndex
            showIndex={!isShowingTable}
            sortedElements={sortedContacts}
          />
        }
      />
      <Widget
        draggable={false}
        title={null}
        backToTop
        overflow
        loading={isFetching}
        className="shadow-md"
      >
        <div className={`mx-3 flex flex-col gap-2 ${isFetching && "loading"}`}>
          {/* Need this separate contactHeader for Card View as Table View will have fileActionIcons */}
          {!isShowingTable &&
            contactHeader({
              setTabIndex,
              tabIndex,
              options,
              isFetching,
              isShowingTable,
              setIsShowingTable,
              onSearchChange,
              searchKeyword,
              allColumns,
              tableData,
              filters,
              setAllFilters,
            })}
          {/* Separate Rendering of Card View Instead of using cardGrid prop for table, as these cards are sorted by alphabetIndex */}
          {isShowingTable ? null : renderTabs(tabIndex)}
        </div>
        {isShowingTable && (
          <Table
            isLoading={isFetching}
            resourceName="contacts"
            data={tableData}
            columns={contactsViewColumns(disciplines)}
            onRowClick={(row) => history.push(`/contacts/${row.id}/0`)}
            allowSelection={currentUser?.hasPermission?.(
              "contacts",
              "can_delete"
            )}
            onSelectedRowChange={handleRowSelect}
            fileActionsIcons={
              <TableActionsIconsGroup
                handleShowDelete={handleRemoveUser}
                canDelete={
                  isShowingTable &&
                  currentUser?.hasPermission?.("contacts", "can_delete")
                }
                disabled={selectedRows?.length === 0}
                selectedRows={selectedRows}
                showColumnSettingsRight={
                  SHOW_COLUMNS_SETTING_RIGHT && isShowingTable
                }
                disabledColumnSettings={!isShowingTable}
              />
            }
            customSiteHeader={({ fileActionsIcons }) =>
              contactHeader({
                setTabIndex,
                tabIndex,
                options,
                isFetching,
                isShowingTable,
                setIsShowingTable,
                onSearchChange,
                searchKeyword,
                allColumns,
                tableData,
                filters,
                setAllFilters,
                fileActionsIcons,
              })
            }
            showConstantRowIndex
            groupOptions={groupOptions}
            hideDeleteRowButton={false}
            onDeleteRowClick={() => {}}
          />
        )}
      </Widget>
      {showImportModal && (
        <ImportContactsModal
          onClose={() => setShowImportModal(false)}
          onCSVUpload={onImport}
        />
      )}
    </>
  );
};

ContactList.propTypes = {
  setShowMessages: PropTypes.func.isRequired,
};

export default ContactList;
