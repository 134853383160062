import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import cntl from "cntl";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";
import { formatPhoneNumber } from "../../../helpers/Formatters";
import { toastError, toastMessage } from "../Toast/Toast";

const titleCN = cntl`
  text-lg
  font-semibold
  py-2
`;

const valueCN = cntl`
  text-lg
  py-2
  text-gray-200
  truncate
`;

const BillingContact = ({
  isEditingBilling,
  setIsEditingBilling,
  subscriptionDetails,
  dispatch,
}) => {
  const [billingDetails, setBillingDetails] = useState();
  const [isSaving, setIsSaving] = useState();

  useEffect(() => {
    setBillingDetails(subscriptionDetails);
  }, [subscriptionDetails]);

  const onChange = useCallback((val, field) => {
    setBillingDetails((prev) => ({
      ...prev,
      [field]: val,
    }));
  }, []);

  const onCancel = useCallback(() => {
    setBillingDetails(subscriptionDetails);
    setIsEditingBilling(false);
  }, [setIsEditingBilling, subscriptionDetails]);

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      const body = {
        name: `${billingDetails.contactFirstName} ${billingDetails.contactLastName}`,
        metadata: {
          title: billingDetails.contactTitle,
          company: billingDetails.contactCompany,
        },
        email: billingDetails.contactEmail,
        phone: billingDetails.contactPhone,
      };

      await SignUpAPI.updateCustomer(billingDetails?.customerId, body);
      dispatch({
        type: "reset",
        subscription: billingDetails,
      });

      setIsEditingBilling(false);
      toastMessage("Successfully updated billing contact");
    } catch (e) {
      console.error("billing error", e);
      toastError(
        `Error updating billing contact. Please enter valid details. ${
          e?.response?.data?.issues[0]?.location || e.message || ""
        }`
      );
    } finally {
      setIsSaving(false);
    }
  }, [dispatch, billingDetails, setIsEditingBilling]);

  return (
    <div className="flex flex-col w-1/3 border rounded mr-8">
      <div className="flex justify-between w-full pt-8 pb-4 pl-8 pr-4">
        <div className="pt-1">
          <SecondaryHeader>Billing Contact</SecondaryHeader>
        </div>
        {!isEditingBilling && (
          <div>
            <TertiaryButton
              title="Edit"
              className="text-lg"
              onClick={() => setIsEditingBilling(true)}
              disabled={subscriptionDetails?.subscriptionStatus === "canceled"}
            />
          </div>
        )}
      </div>
      {(isEditingBilling && (
        <div className="px-8 pb-4">
          <div className="flex flex-col">
            <Input
              placeholder="First Name"
              className="mb-3"
              label="FirstName"
              validation={yup.string()}
              value={billingDetails.contactFirstName}
              onChange={(val) => onChange(val, "contactFirstName")}
            />
            <Input
              placeholder="Last Name"
              className="mb-3"
              label="LastName"
              validation={yup.string()}
              value={billingDetails.contactLastName}
              onChange={(val) => onChange(val, "contactLastName")}
            />
            <Input
              placeholder="Title"
              className="mb-3"
              label="Title"
              validation={yup.string()}
              value={billingDetails.contactTitle}
              onChange={(val) => onChange(val, "contactTitle")}
            />
            <Input
              placeholder="Company"
              className="mb-3"
              label="Company"
              validation={yup.string()}
              value={billingDetails.contactCompany}
              onChange={(val) => onChange(val, "contactCompany")}
            />
            <div className="flex border-b border-gray-200 my-4" />
            <Input
              placeholder="Email"
              className="mb-3"
              label="Email"
              validation={yup.string()}
              value={billingDetails.contactEmail}
              onChange={(val) => onChange(val, "contactEmail")}
            />
            <Input
              placeholder="Phone"
              className="mb-3"
              label="Phone"
              validation={yup.string()}
              value={billingDetails.contactPhone}
              onChange={(val) => onChange(val, "contactPhone")}
            />
            <div className="flex justify-end py-4">
              <TertiaryButton
                title="Cancel"
                onClick={onCancel}
                disabled={isSaving}
              />
              <PrimaryButton
                title="Save Changes"
                onClick={onSave}
                disabled={isSaving}
                className="ml-2"
              />
            </div>
          </div>
        </div>
      )) || (
        <div className="px-8 pb-4">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-1/3">
              <p className={titleCN}>Name</p>
              <p className={titleCN}>Title</p>
              <p className={titleCN}>Company</p>
            </div>
            <div className="flex flex-col w-2/3">
              <p className={valueCN}>
                {subscriptionDetails.contactFirstName}{" "}
                {subscriptionDetails.contactLastName}
              </p>
              <p className={valueCN}>{subscriptionDetails.contactTitle}</p>
              <p className={valueCN}>{subscriptionDetails.contactCompany}</p>
            </div>
          </div>
          <div className="flex border-b border-gray-200 my-4 mr-8" />
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-1/3">
              <p className={titleCN}>Email</p>
              <p className={titleCN}>Phone</p>
            </div>
            <div className="flex flex-col w-2/3 pb-8">
              <p className={valueCN}>{subscriptionDetails.contactEmail}</p>
              <p className={valueCN}>
                {formatPhoneNumber(subscriptionDetails.contactPhone)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

BillingContact.propTypes = {
  isEditingBilling: PropTypes.bool,
  setIsEditingBilling: PropTypes.func,
  subscriptionDetails: PropTypes.shape({
    contactFirstName: PropTypes.string,
    contactLastName: PropTypes.string,
    contactTitle: PropTypes.string,
    contactCompany: PropTypes.string,
    contactPhone: PropTypes.string,
    contactEmail: PropTypes.string,
    subscriptionStatus: PropTypes.string,
  }),
  dispatch: PropTypes.func,
};

BillingContact.defaultProps = {
  isEditingBilling: false,
  setIsEditingBilling: undefined,
  subscriptionDetails: undefined,
  dispatch: undefined,
};

export default BillingContact;
