import { useCallback, useEffect, useState } from "react";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";

export default function useProjectById(projectId, leaveChildren = false) {
  const [project, setProject] = useState({});
  const [isLoading, setLoading] = useState(false);

  const reload = useCallback(
    async (leave) => {
      if (projectId) {
        setLoading(true);

        try {
          const { data } = await ProjectAPI.getById(projectId);
          let buildings;
          let formatedProject;
          if (!leave) {
            buildings = await Promise.all(
              data?.buildings?.map(async (building) => {
                return {
                  ...building,
                  spaces: await Promise.all(
                    building?.spaces?.map(async (space) => {
                      return {
                        ...space,
                      };
                    }),
                    []
                  ),
                };
              }) || []
            );

            formatedProject = await Promise.resolve({ ...data, buildings });

            setProject(formatedProject);
          } else {
            setProject((prev) => ({
              ...data,
              buildings: prev?.buildings || data.buildings,
            }));
          }
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    },
    [projectId, setProject, setLoading]
  );

  useEffect(() => {
    reload(leaveChildren);
  }, [reload, leaveChildren]);

  return { project, isLoading, reload, setProject };
}
