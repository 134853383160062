/* eslint no-underscore-dangle: 0 */
import {
  BudgetAPI,
  LineitemAPI,
  ProjectAPI,
} from "@griffingroupglobal/eslib-api";
import PropTypes from "prop-types";
import { cloneDeep, isEqual } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  EXPENSE_CREATE_MODAL,
  PROJECTS_PATH,
  SUBMITTAL_CREATE_MODAL,
} from "../../constants";
import { onUpdateFile } from "../../helpers/File";
import { toastError, toastMessage } from "../../helpers/Toast";
import smoothScrollTo from "../../helpers/smoothScroll";
import useAssociatedFiles from "../../hooks/useAssociatedFiles";
import useAttributes from "../../hooks/useAttributes";
import useEditModal from "../../hooks/useEditModal";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import useProject from "../../hooks/useProject";
import useProjectFormReducer from "../../hooks/useProjectFormReducer";
import useProjects from "../../hooks/useProjects";
import useRelativeAssociations from "../../hooks/useRelativeAssociations";
import useReportTopButtons from "../../hooks/useReportTopButtons";
import useServiceRequestTopButtons from "../../hooks/useServiceRequestTopButtons";
import useWidgetTabNavigation from "../../hooks/useWidgetTabNavigation";
import { useModalState } from "../../state/modalState";
import ActivateProjectForm from "../../stories/Components/ActivateProjectForm/ActivateProjectForm";
import MaintenanceScheduleModal from "../../stories/Components/AssetForms/MaintenanceScheduleModal";
import FormAvatar from "../../stories/Components/Avatar/FormAvatar";
import BudgetTable from "../../stories/Components/BudgetTable/BudgetTable";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import ExpenseTable from "../../stories/Components/ExpenseTable/ExpenseTable";
import FilesTable from "../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import Modal from "../../stories/Components/Modal/Modal";
import RequestTable from "../../stories/Components/RequestTable/RequestTable";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import UploadFile from "../../stories/Components/UploadFile/UploadFile";
import Widget from "../../stories/Components/Widget/Widget";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import WorkflowTable from "../../stories/Components/WorkflowTableNew";
import whiteCircleCheckIcon from "../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";
import CalendarView from "../Calendar/CalendarView";
import Assets from "../Overviews/Asset/Assets";
import ServiceRequestsOverview from "../Overviews/ServiceRequests";
import TaskList from "../Overviews/Task/TaskList";
import ReportsOverview from "../Reports/ReportOverview";
import Spaces from "../Spaces/Spaces";
import ProjectDeleteModal from "./ProjectDeleteModal";
import ProjectDetailView from "./ProjectDetailView";
import ProjectDocuments from "./ProjectDocuments";
import ProjectFinances from "./ProjectFinances";
import useProjectBudgetFormReducer from "../../hooks/useProjectBudgetFormReducer";
import { projectBudgetInfoSchema } from "../../helpers/FormValidations";
import useBudgetLineItems from "../../hooks/useBudgetLineItems";
import BetaTag from "../../stories/Components/BetaTag";
import clearSearchField from "../../helpers/clearSearchField";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const LEAVE_PROJECT_SPACES = true;
const THROW_ERROR = true;

const ProjectView = ({
  userEvents,
  updateUserEvent,
  isTest,
  reloadEvents,
  currentUser,
}) => {
  const { projectId } = useParams();
  const { data: managementConfiguration } = useManagementConfiguration();

  // Hook to calculate buttons for SR tab
  const { getRequestButtonProps } = useServiceRequestTopButtons();

  // Hook to calculate buttons for Report tab
  const { getReportButtonProps } = useReportTopButtons();

  const [
    project,
    reload,
    setProject,
    patchProject,
    removeProject,
    isLoading,
    projectMembers,
  ] = useProject(projectId);

  const params = useMemo(
    () => ({ association: `Project/${projectId}` }),
    [projectId]
  );
  const {
    associatedFiles,
    addFile,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const { associationLock } = useRelativeAssociations();
  const [, setIsEditModalOpen] = useEditModal();

  const history = useHistory();

  const [reloadMeasurements] = useAttributes();

  const [editedProject, dispatch] = useProjectFormReducer();

  const [projectBudget, dispatchProjectBudget] = useProjectBudgetFormReducer();
  const [, , , , , , reloadBudgetLineItems] = useBudgetLineItems(
    project?.budget.split("/")[1]
  );
  const {
    projects,
    setProjects,
    changeProjectStatus,
    /* ** Commented out for future use: Clone Project ** */
    // reload: reloadProjects,
  } = useProjects();
  const [isEditing, setIsEditing] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isReconcileBudgetValid, setIsReconcileBudgetValid] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [completeProjectModal, setCompleteProjectModal] = useState(false);
  const [activateProjectModal, setActivateProjectModal] = useState(false);
  const [isActivatingProject, setIsActivatingProject] = useState(false);
  const [budgetButtonActions, setBudgetButtonActions] = useState([]);
  const [draftBudgetLineItems, setDraftBudgetLineItems] = useState([]);

  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [buttonActions, setButtonActions] = useState([]);
  const [isEditableTab, setIsEditableTab] = useState(false);
  const isDraft = editedProject.status === "draft";
  const isActive = editedProject.status === "active";

  const handleEditClick = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
      smoothScrollTo("about-project-row-2");
      return;
    }
    setIsEditing(false);
  }, [isEditing]);

  const resetProjectState = useCallback(() => {
    dispatch({
      type: "reset",
      project,
    });
  }, [project, dispatch]);

  useEffect(() => {
    if (project) {
      resetProjectState();
    }
  }, [project, resetProjectState]);

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...project.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        project.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProject(updatedProject);
      // update associated files state
      addFiles(imageResources);
    },
    [addFiles, patchProject, project]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProject(updatedProject);
      // update associated files state
      removeFilesAndUpdateApi(imageRefs);
    },
    [patchProject, project, removeFilesAndUpdateApi]
  );

  const updateProjectFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [patchProject, project]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedProject = {
        ...project,
        primaryImage: imageRef,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [patchProject, project]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...project.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage:
          project?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };
      // patch resource
      patchProject(updatedProject);
    },
    [addFiles, patchProject, project]
  );

  const onFinishEditingRef = useRef(null);

  useEffect(() => {
    return () => {
      if (onFinishEditingRef.current) clearTimeout(onFinishEditingRef.current);
    };
  }, []);

  const handleEdit = useCallback(
    (key, value) => {
      dispatch({
        type: "edit",
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleEditTwo = useCallback(
    (key1, key2, value) => {
      dispatch({
        type: "edit2",
        key1,
        key2,
        value,
      });
    },
    [dispatch]
  );

  const onFinishEditing = useCallback(
    async (key, val) => {
      const finishedProject = {
        ...editedProject,
        tags:
          editedProject?.currentTags?.map((tag) => tag?.value) ||
          editedProject?.tags,
        [key]: val,
      };

      delete finishedProject?.originalResource;
      delete finishedProject?.currentTags;

      patchProject(finishedProject, THROW_ERROR)
        .then((returnedProject) => {
          const projectIndex = projects.findIndex(
            ({ id }) => id === returnedProject.id
          );
          const newProjects = [...projects];
          newProjects[projectIndex] = returnedProject;
          setProjects(newProjects);
          toastMessage(
            `Successfully updated Project`,
            toastIcon,
            toastCloseIcon
          );
        })
        .catch(() => {
          toastError(
            "Failed to update project",
            toastErrorIcon,
            toastCloseIcon
          );
        });

      const SavingDelay = () => {
        return new Promise((resolve) => {
          onFinishEditingRef.current = setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      const posts = [SavingDelay];

      const arrayOfPosts = posts?.map((post) => post());

      if (arrayOfPosts?.length) {
        arrayOfPosts.push(() => {
          reloadMeasurements();
        });
      }
      Promise.all(arrayOfPosts);
    },
    [editedProject, patchProject, reloadMeasurements, setProjects, projects]
  );

  const handleOnCloseModal = useCallback(async () => {
    setShowMaintenanceModal(false);
  }, []);

  const handleUpdatedProject = useCallback(
    (updated) => {
      setProject(updated);
    },
    [setProject]
  );

  /**
   * New Handlers
   */

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedProject = {
            ...project,
            files: [
              ...project?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patchProject(updatedProject, true);
        })
        .then((resource) =>
          toastMessage(
            `Recent file successfully attached to ${resource?.name}`,
            toastIcon,
            toastCloseIcon
          )
        )
        .catch(() => {
          toastError(
            `Error attaching recent file`,
            toastErrorIcon,
            toastCloseIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patchProject, project, removeFilesAndUpdateApi]
  );

  const handlePrimaryImageChange = async (fileResource) => {
    const updatedProject = {
      ...project,
      files: [
        ...project?.files,
        { ref: fileResource.reference, category: fileResource.category },
      ],
      primaryImage: fileResource.reference,
    };
    patchProject(updatedProject)
      .then(() => {
        addFile(fileResource);
        toastMessage(`Primary Image Updated`, toastIcon, toastCloseIcon);
      })
      .catch(() => {
        toastError(
          `Error updating primary image`,
          toastErrorIcon,
          toastCloseIcon
        );
      });
  };

  const handleCompleteProject = useCallback(
    async (currentProject) => {
      const completedProject = {
        ...currentProject,
        status: "complete",
      };
      try {
        ProjectAPI.patch(projectId, completedProject, currentProject);
        changeProjectStatus(projectId, "complete");
      } catch (err) {
        //  Todo Error handling
      }
      history.push(PROJECTS_PATH);
    },
    [changeProjectStatus, history, projectId]
  );

  const handleChangeName = useCallback(
    (name) => {
      dispatch({
        type: "name",
        name,
      });
      if (!isEditing) onFinishEditing("name", name);
    },
    [dispatch, isEditing, onFinishEditing]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    checkValidation(projectBudget, projectBudgetInfoSchema);
  }, [projectBudget, checkValidation]);

  useEffect(() => {
    const isAnyUnselected = draftBudgetLineItems.find(
      (li) => !li.isHighRangeSelected && !li.isLowRangeSelected
    );
    setIsReconcileBudgetValid(!isAnyUnselected);
  }, [draftBudgetLineItems]);

  const onCreateBudget = useCallback(async () => {
    try {
      setIsActivatingProject(true);

      // reconcile budget
      Promise.all(
        draftBudgetLineItems.map(async (li) => {
          if (li?.isHighRangeSelected) {
            await LineitemAPI.patch(
              li.id,
              {
                ...li,
                costPerUnit: li?.highRange?.costPerUnit,
                quantity: li?.highRange?.quantity,
                adjustment: {
                  arithmeticUnit: li?.highRange?.arithmeticUnit,
                  adjustmentUnit: li?.highRange?.adjustmentUnit,
                  amount: li?.highRange?.amount,
                },
                isCostRange: false,
              },
              li
            );
          }
          return li;
        })
      );

      const { data } = await BudgetAPI.getById(project.budget.split("/")[1]);
      // set budget type
      await BudgetAPI.patch(
        project.budget.split("/")[1],
        {
          ...data,
          ...projectBudget,
        },
        data
      );

      reloadBudgetLineItems();

      // update project status
      await ProjectAPI.patch(
        project?.id,
        {
          ...editedProject,
          status: "active",
        },
        project
      );

      changeProjectStatus(project.id, "active");
      dispatch({
        type: "changeStatus",
        value: "active",
      });
    } catch (err) {
      toastError(
        `Error Activating Project. ${err.message || ""}.`,
        toastErrorIcon,
        toastCloseIcon
      );
    } finally {
      setIsActivatingProject(false);
      setActivateProjectModal(false);
    }
  }, [
    changeProjectStatus,
    draftBudgetLineItems,
    setActivateProjectModal,
    project,
    editedProject,
    projectBudget,
    reloadBudgetLineItems,
    dispatch,
  ]);

  const handleContinue = async () => {
    await onCreateBudget();
  };

  /* ** Commented out for future use: Clone Project ** */

  // clone this project
  // const cloneProject = useCallback(async () => {
  /* ** TO DO: Set primaryImage and SetProject to DRAFT ** */
  //   try {
  //     const projectClone = cloneDeep(project);
  //     projectClone.name = `${project.name} (Clone)`;
  //     delete projectClone?.id;
  //     delete projectClone?._id;
  //     delete projectClone?.__v;

  //     const { data: clonedProject } = await ProjectAPI.post(projectClone);

  //     if (clonedProject?.id) {
  //       toastMessage("Clone project successful", toastIcon, toastCloseIcon);
  //       history.push(`/projects/${clonedProject?.id}`);
  //     }
  //   } catch (err) {
  //     toastError("Clone project failed", toastErrorIcon, toastCloseIcon);
  //   }
  //   reloadProjects();
  // }, [history, project, reloadProjects]);

  /**
   * New Handlers
   */

  // in editing mode, state of save button
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  // in editing mode, detect if resource object has changed
  const hasDetectedChanges = useCallback((edited, original) => {
    const editedClone = cloneDeep(edited);
    const originalClone = cloneDeep(original);

    editedClone.tags = editedClone?.currentTags;
    delete editedClone?.currentTags;
    delete editedClone?.originalResource;

    const isSame = isEqual(editedClone, originalClone);

    return !isSame;
  }, []);

  // in editing mode, enable/disable save button base on whether resource object has changed
  useEffect(() => {
    const changesDetected = !!hasDetectedChanges(editedProject, project);
    setDisableSaveBtn(!changesDetected);
  }, [editedProject, project, hasDetectedChanges, setDisableSaveBtn]);

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: "details",
          title: "Details",
          content: (
            <ProjectDetailView
              editedProject={editedProject}
              loading={isLoading}
              isEditing={isEditing}
              config={managementConfiguration?.management}
              dispatch={dispatch}
              handleEdit={handleEdit}
              handleEditTwo={handleEditTwo}
              onFinishEditing={onFinishEditing}
              originalResource={project}
              setMemberLoading={setMemberLoading}
              memberLoading={memberLoading}
              disableEditing={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_project"
                )
              }
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;

    if (currentUser?.hasPermission?.("budget", "can_read")) {
      permissionedTabs.tabs.push({
        id: "budget",
        title: (
          <div className="flex">
            <span>Budget</span>
            <BetaTag />
          </div>
        ),
        content: (
          <Widget draggable={false} title={null} overflow>
            <BudgetTable
              budgetId={project?.budget.split("/")[1]}
              projectId={projectId}
              projectData={editedProject}
              setButtonActions={setBudgetButtonActions}
              hideSiteHeaderTitle
            />
          </Widget>
        ),
      });
      permissionedTabs.budgetIndex = permissionedTabs.tabs.length - 1;
    }

    permissionedTabs.tabs.push({
      id: "findocs",
      title: (
        <div className="flex">
          <span>Financial Documents</span>
          <BetaTag />
        </div>
      ),
      content: (
        <Widget draggable={false} title={null} overflow>
          <ProjectDocuments currentUser={currentUser} />
        </Widget>
      ),
    });

    if (currentUser?.hasPermission?.("expense", "can_read")) {
      permissionedTabs.tabs.push({
        id: "expenses",
        title: "Expenses",
        content: (
          <Widget draggable={false} title={null} overflow>
            <ExpenseTable
              association={editedProject}
              createExpenseModal={createExpenseModal}
              setCreateExpenseModal={setCreateExpenseModal}
              isTabView
              hideSwitchView
              hideSiteHeaderTitle
            />
          </Widget>
        ),
      });
      permissionedTabs.expenseIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("project", "can_read_financials")) {
      permissionedTabs.tabs.push({
        id: "finances",
        title: "Finances",
        content: (
          <ProjectFinances
            editedProject={editedProject}
            isEditing={isEditing}
            dispatch={dispatch}
          />
        ),
      });
      permissionedTabs.financesIndex = permissionedTabs.tabs.length - 1;
    }

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read")
    ) {
      permissionedTabs.tabs.push({
        id: "calendar",
        title: "Calendar",
        content: (
          <CalendarView
            currentUser={currentUser}
            userEvents={userEvents}
            updateUserEvent={updateUserEvent}
            isTest={isTest}
            reloadEvents={reloadEvents}
            isTabView
          />
        ),
      });
      permissionedTabs.calendarIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      permissionedTabs.tabs.push({
        id: "tasks",
        title: "Tasks",
        content: <TaskList setButtonActions={setButtonActions} />,
      });
      permissionedTabs.taskIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("ticket", "can_read")) {
      permissionedTabs.tabs.push({
        id: "requests",
        title: "Service Requests",
        content: (
          <ServiceRequestsOverview
            association={`Project/${projectId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });
      permissionedTabs.ticketIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("submittal", "can_see")) {
      permissionedTabs.tabs.push({
        id: "submittals",
        title: "Submittals",
        content: (
          <Widget draggable={false} title={null} overflow>
            <RequestTable
              associatedResource={`Project/${projectId}`}
              setButtonActions={setButtonActions}
              isTabView
            />
          </Widget>
        ),
      });
      permissionedTabs.submittalsIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("workflow", "can_read")) {
      permissionedTabs.tabs.push({
        id: "workflows",
        title: "Workflows",
        content: (
          <Widget draggable={false} title={null} overflow>
            <WorkflowTable
              associatedResource={`Project/${projectId}`}
              setButtonActions={setButtonActions}
              isTabView
            />
          </Widget>
        ),
      });
      permissionedTabs.workflowIndex = permissionedTabs.tabs.length - 1;
    }

    permissionedTabs.tabs.push({
      id: "files",
      title: "Files",
      content: (
        <Widget draggable={false} title={null} overflow>
          <FilesTable
            files={associatedFiles}
            onAddFilesCallback={onAddFilesCallback}
            removeFilesAndUpdateApi={removeFilesAndUpdateApi}
            onRemoveFilesCallback={updateProjectFiles}
            setIsEditModalOpen={setIsEditModalOpen}
            resourceName="Project"
            association={`Project/${projectId}`}
            hasDeletePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_project"
            )}
            hasWritePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_project"
            )}
            handleFileClone={handleFileClone}
            hasEditPermission
            handleUpdateFile={handleUpdateFile}
          />
        </Widget>
      ),
    });
    permissionedTabs.filesIndex = permissionedTabs.tabs.length - 1;

    permissionedTabs.tabs.push({
      id: "media",
      title: "Media",
      content: (
        <ImagesAndVideosWidget
          resource={project}
          disableEditing={
            !currentUser?.hasPermission?.("administrative", "can_write_project")
          }
          handleAddMedia={handleAddMedia}
          handleSetPrimaryMedia={handleSetPrimaryMedia}
          handleRemoveMedia={handleRemoveMedia}
        />
      ),
    });
    permissionedTabs.mediaIndex = permissionedTabs.tabs.length - 1;

    if (currentUser?.hasPermission?.("asset", "can_read")) {
      permissionedTabs.tabs.push({
        id: "assets",
        title: "Assets",
        content: <Assets />,
      });
    }
    permissionedTabs.assetIndex = permissionedTabs.tabs.length - 1;

    permissionedTabs.tabs.push({
      id: "spaces",
      title: "Spaces",
      content: <Spaces setActions={setButtonActions} />,
    });
    permissionedTabs.spaceIndex = permissionedTabs.tabs.length - 1;

    if (currentUser?.hasPermission?.("report", "can_read")) {
      permissionedTabs.tabs.push({
        id: "reports",
        title: (
          <div className="flex">
            <span>Reports</span>
            <BetaTag />
          </div>
        ),
        content: (
          <ReportsOverview
            association={`Project/${projectId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });

      permissionedTabs.reportIndex = permissionedTabs.tabs.length - 1;
    }

    return permissionedTabs;
  }, [
    currentUser,
    editedProject,
    project,
    createExpenseModal,
    handleAddMedia,
    handleSetPrimaryMedia,
    handleRemoveMedia,
    userEvents,
    updateUserEvent,
    isTest,
    reloadEvents,
    isLoading,
    handleEdit,
    handleEditTwo,
    setMemberLoading,
    memberLoading,
    managementConfiguration?.management,
    onFinishEditing,
    associatedFiles,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
    updateProjectFiles,
    setIsEditModalOpen,
    projectId,
    handleFileClone,
    handleUpdateFile,
    dispatch,
    isEditing,
  ]);

  const { activeTabIndex, setTabIndex } = useWidgetTabNavigation({
    tabs: tabs.tabs,
  });

  // Render Tab Specific CTA Button
  const [, modalDispatch] = useModalState();
  /**
   * @TODO - TechDebt: Convert All Create Modals legacy->ESModal(Can open modal from any component)
   * @summary - will remove the need for the button action pingpong
   * Parent (setter) -> Child (btnAction) -> Parent
   */

  const ActionButton = useMemo(() => {
    // By default, hide the edit pencil
    setIsEditableTab(false);

    // Spreadable Prop Object
    let buttonProps = { dropdownItems: [] };

    switch (activeTabIndex) {
      case tabs.detailsIndex: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          setIsEditableTab(true);
          /* ** Commented out for future use: Clone Project ** */

          // buttonProps = {
          //   ...buttonProps,
          //   dropdownItems: [
          //     ...buttonProps?.dropdownItems,
          //     {
          //       title: "Clone Project",
          //       onClick: () => cloneProject(),
          //     },
          //   ],
          // };

          /* ** ES-9586: “Complete Project” (we should hide complete until we add more logic) ** */

          // if (isActive) {
          //   buttonProps = {
          //     ...buttonProps,
          //     dropdownItems: [
          //       ...buttonProps?.dropdownItems,
          //       {
          //         title: "Complete Project",
          //         onClick: () => {
          //           setCompleteProjectModal(true);
          //         },
          //       },
          //     ],
          //   };
          // }

          if (isDraft) {
            buttonProps = {
              ...buttonProps,
              dropdownItems: [
                ...buttonProps?.dropdownItems,
                {
                  title: "Activate Project",
                  onClick: () => {
                    setActivateProjectModal(true);
                  },
                },
              ],
            };
          }
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_delete_project")
        ) {
          buttonProps = {
            ...buttonProps,
            dropdownItems: [
              ...buttonProps?.dropdownItems,
              {
                title: "Delete Project",
                onClick: () => {
                  setShowDeleteModal(true);
                },
              },
            ],
          };
        }
        break;
      }
      case tabs.assetIndex: {
        if (currentUser?.hasPermission?.("asset", "can_write")) {
          buttonProps = {
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                modalData: {
                  item: {
                    projectId,
                    association: project?.reference,
                    associationType: "Project",
                    disableAssociation: true,
                  },
                },
                modalType: CREATE_ASSET,
                ref: { id: uuidv4() },
              }),
            addButton: true,
            resourceName: "Asset",
          };
        }
        break;
      }
      case tabs.calendarIndex: {
        const newDDItems = [];

        if (currentUser?.hasPermission?.("task", "can_create")) {
          newDDItems.push({
            title: "Add Event",
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock: false },
                modalType: CREATE_EVENT_MODAL,
              }),
          });

          newDDItems.push({
            title: "Add Task",
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock },
                modalType: CREATE_TASK_MODAL,
              }),
          });

          buttonProps = {
            dropdownItems: newDDItems,
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      }

      case tabs.expenseIndex: {
        if (currentUser?.hasPermission?.("expense", "can_write")) {
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalType: EXPENSE_CREATE_MODAL,
                modalData: {
                  viewMode: "create",
                  associationLock: `Project/${projectId}`,
                },
              });
            },
            addButton: true,
            resourceName: "Expense",
          };
        }
        break;
      }

      case tabs.taskIndex: {
        if (currentUser?.hasPermission?.("task", "can_create")) {
          buttonProps = {
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock },
                modalType: CREATE_TASK_MODAL,
              }),
            addButton: true,
            resourceName: "Task",
          };
        }
        break;
      }

      case tabs.spaceIndex: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            onClick: () =>
              buttonActions.find((opt) => opt.title === "Add Space").onClick(),
            addButton: true,
            resourceName: "Space",
          };
        }
        break;
      }
      case tabs.ticketIndex: {
        if (currentUser?.hasPermission?.("ticket", "can_create")) {
          buttonProps = getRequestButtonProps(buttonActions);
        }
        break;
      }

      case tabs.mediaIndex: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            addButton: true,
            resourceName: "Media",
            onClick: () => document.getElementById("upload-form-input").click(),
          };
        }
        break;
      }

      case tabs.submittalsIndex: {
        if (
          isActive &&
          currentUser?.hasPermission?.("submittal", "can_create")
        ) {
          buttonProps = {
            onClick: () => {
              return modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: "create-submittal-modal-project" },
                modalData: {
                  id: "create-submittal-modal-project",
                  item: {
                    association: project?.reference,
                    disableAssociation: true,
                  },
                },
                modalType: SUBMITTAL_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Submittal",
          };
        }
        break;
      }

      case tabs.workflowIndex: {
        if (
          isActive &&
          currentUser?.hasPermission?.("workflow", "can_create")
        ) {
          buttonProps = {
            onClick: () =>
              buttonActions
                .find((opt) => opt.title === "Add Workflow")
                .onClick(),
            addButton: true,
            resourceName: "Workflow",
          };
        }
        break;
      }

      case tabs.filesIndex: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            addButton: true,
            resourceName: "Files",
            onClick: () =>
              document
                .querySelector(`div.project-table-view .upload_area_click`)
                ?.click(),
          };
        }
        break;
      }

      case tabs.budgetIndex: {
        if (!editedProject) {
          return null;
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          if (isDraft) {
            buttonProps = {
              ...buttonProps,
              dropdownItems: [
                ...buttonProps?.dropdownItems,
                {
                  title: "Activate Project",
                  onClick: () => {
                    setActivateProjectModal(true);
                  },
                },
              ],
            };
          }

          buttonProps = {
            dropdownItems: [
              ...buttonProps?.dropdownItems,
              ...budgetButtonActions,
            ],
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      }
      case tabs.financesIndex: {
        if (currentUser?.hasPermission?.("property", "can_update_financials")) {
          setIsEditableTab(true);
        }
        break;
      }
      case tabs.reportIndex: {
        if (currentUser?.hasPermission?.("report", "can_write")) {
          buttonProps = getReportButtonProps(buttonActions);
        }
        break;
      }

      default:
        break;
    }

    if (!buttonProps?.dropdownItems?.length && !buttonProps.addButton) {
      return null;
    }

    return <PrimaryButton {...buttonProps} />;
  }, [
    activeTabIndex,
    tabs.detailsIndex,
    tabs.assetIndex,
    tabs.calendarIndex,
    tabs.expenseIndex,
    tabs.taskIndex,
    tabs.spaceIndex,
    tabs.ticketIndex,
    tabs.mediaIndex,
    tabs.submittalsIndex,
    tabs.workflowIndex,
    tabs.filesIndex,
    tabs.budgetIndex,
    tabs.financesIndex,
    tabs.reportIndex,
    currentUser,
    isActive,
    isDraft,
    modalDispatch,
    projectId,
    project?.reference,
    associationLock,
    buttonActions,
    getRequestButtonProps,
    editedProject,
    budgetButtonActions,
    getReportButtonProps,
  ]);

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      setTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
    },
    [setTabIndex]
  );

  /**
   * Clean Up Actions CTA Menu
   */

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing
              editing={isEditing}
              image={project?.primaryImage}
              loading={!project?.name}
              onChange={handlePrimaryImageChange}
              resourceName="Project"
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={project?.name?.toUpperCase()}
              editing={isEditing}
              loading={!project?.name}
              disabled={!project?.name}
              fontWeight="bold"
              color="gray-650"
              onChangeCallback={handleChangeName}
              hidePencil
              isHeaderTitle
            />
          </div>
        }
        buttons={ActionButton}
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={isEditing}
        handleEditClick={isEditableTab && handleEditClick}
        onFinishEditing={onFinishEditing}
        tabs={tabs?.tabs}
        loading={isLoading}
        disableEditing={
          !currentUser?.hasPermission?.("administrative", "can_write_project")
        }
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs, "Project");
        }}
        resetResourceState={resetProjectState}
        disableSaveBtn={disableSaveBtn}
        hideBottomCancelSaveButtons
      />
      <UploadFile
        id="project-details"
        association={`Project/${projectId}`}
        onAddFilesCallback={onAddFilesCallback}
      />
      <ProjectDeleteModal
        project={project}
        removeProject={removeProject}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />

      {showMaintenanceModal && (
        <MaintenanceScheduleModal
          onCloseModal={handleOnCloseModal}
          assetMembersOptions={projectMembers}
          setUpdatedAsset={handleUpdatedProject}
          reloadAsset={() => reload(LEAVE_PROJECT_SPACES)}
          association={{
            reference: editedProject?.reference,
            name: editedProject?.name,
            project: editedProject?.reference,
          }}
        />
      )}
      <Modal
        title="Activate Project"
        isOpen={activateProjectModal}
        alert
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        primaryButtonTitle="Activate"
        tertiaryButtonTitle="Cancel"
        primaryButtonOnClick={handleContinue}
        onRequestModalClose={() => setActivateProjectModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        disabled={!isInputValid || !isReconcileBudgetValid}
        hideFooter
        childContainerClassName={`modal-content-class-no-padding
          ${isActivatingProject && "loading"}`}
      >
        <div className="ml-7 mt-5">
          <ActivateProjectForm
            hideSiteHeaderName
            projectData={editedProject}
            projectBudget={projectBudget}
            dispatchProjectBudget={dispatchProjectBudget}
            setActivateProjectModal={setActivateProjectModal}
            setDraftBudgetLineItems={setDraftBudgetLineItems}
          />
        </div>
      </Modal>
      <Modal
        title="Complete Project"
        isOpen={completeProjectModal}
        alert
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        primaryButtonTitle="Yes, Complete"
        tertiaryButtonTitle="Cancel"
        primaryButtonOnClick={() => {
          setCompleteProjectModal(false);
          handleCompleteProject(editedProject);
        }}
        onRequestModalClose={() => setCompleteProjectModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want complete {editedProject.name}?
          </p>
        </>
      </Modal>
    </>
  );
};

ProjectView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
  userEvents: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  isTest: PropTypes.bool,
  reloadEvents: PropTypes.func,
  usersDict: PropTypes.shape({}),
  setOpenNewExpenseModal: PropTypes.bool,
};

ProjectView.defaultProps = {
  currentUser: undefined,
  userEvents: undefined,
  updateUserEvent: undefined,
  isTest: false,
  reloadEvents: undefined,
  usersDict: {},
  setOpenNewExpenseModal: false,
};

export default ProjectView;
