const onlyShowPtoWithoutAccrual = (ptoAvailBtns) => {
  const ptoWithAccrual = Object.entries(ptoAvailBtns ?? {}).reduce(
    (acc, [key, item]) => {
      if (!item?.accrual) {
        acc[key] = item;
      }
      return acc;
    },
    {}
  );

  return ptoWithAccrual;
};

export default onlyShowPtoWithoutAccrual;
