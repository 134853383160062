// if err: 9 is shown in the csv, it means that the data is not available in the timesheet object

const createCsvRowsFromTs = ({ simulatedTimesheets, columns }) => {
  const csvRows = simulatedTimesheets.map((timesheet) => {
    const newRow = columns.map((column) => {
      if (column === "Name") {
        return timesheet?.employee?.fullName ?? "err: 9";
      }

      if (column === "Employee ID") {
        return timesheet?.employee?.employeeInfo?.id ?? "err: 9";
      }

      return "NA";
    });
    return newRow;
  });

  return csvRows.map((row) => row.join(",").concat("\n")).join("");
};

export default createCsvRowsFromTs;
