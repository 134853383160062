import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, isEqual } from "lodash";
import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { useHistory } from "react-router";
import {
  PROPERTY_ADD_NEW_PATH,
  SET_PROPERTIES,
  SHOW_COLUMNS_SETTING_RIGHT,
} from "../../../constants";
import useProperties from "../../../hooks/useProperties";
import { useAppState } from "../../../state/appState";
import PurePropertyTable from "./PurePropertyTable";
import PropertyData from "../../../helpers/Property";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useFavorites from "../../../hooks/useFavorites";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import PropertyDeleteModal from "../../../Pages/Overviews/Property/PropertyDeleteModal";
import { usePropertySwitchView } from "../../../hooks/useSwitchView";
import { useGetTags } from "../../../hooks/useTags";

const PropertyTable = ({ onRowClick, hideSiteHeaderTitle }) => {
  const history = useHistory();
  const [originalProperties, reload, , , removeProperty] = useProperties();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: tagsData } = useGetTags();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const [, dispatch] = useAppState([]);
  const [properties, setProperties] = useState([]);
  const [editCount, setEditCount] = useState(0);
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowingPropertiesTable] = usePropertySwitchView();

  const propertyTypes = useMemo(() => {
    if (!managementConfiguration?.management?.property?.types) {
      return [];
    }

    return managementConfiguration.management.property.types
      .filter((type) => type.selected)
      .map((type) => ({
        label: type.display,
        value: type.id,
      }));
  }, [managementConfiguration]);

  useEffect(() => {
    if (editCount === 0) {
      setProperties(cloneDeep(originalProperties));
    }
  }, [editCount, originalProperties]);

  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.property) {
      return [];
    }

    return templatesConfiguration?.templates?.property
      ?.filter((template) => template.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
  }, [templatesConfiguration]);

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "property",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "property",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const onAddPropertyClick = () => {
    history.push(PROPERTY_ADD_NEW_PATH);
  };

  const onSaveRowClick = async (index) => {
    const editedProperty = properties[index];
    const current = originalProperties[index];
    const { primaryImage, ...rest } = editedProperty;

    const newPropertyArray = originalProperties;
    newPropertyArray[index] = editedProperty;
    dispatch({
      type: SET_PROPERTIES,
      properties: newPropertyArray,
    });

    if (!isEqual(current, editedProperty)) {
      await PropertyAPI.patch(editedProperty.id, rest, current);
    }
  };

  const onEditSaveCallback = useCallback(
    async (row, property) => {
      const current = originalProperties[row?.index];
      const editedProperty = {
        ...JSON.parse(JSON.stringify(current)),
        ...property,
      };

      setProperties((prevProperties) =>
        prevProperties.map((prop, index) => {
          if (index === row?.index) {
            return {
              ...prop,
              ...editedProperty,
            };
          }
          return prop;
        })
      );

      if (!isEqual(current, editedProperty)) {
        await PropertyAPI.patch(editedProperty.id, editedProperty, current);
        reload();
      }
    },
    [originalProperties, reload]
  );

  const onAddSaveCallback = useCallback(
    async (row, property) => {
      const newPropertiesArray = [
        ...properties.map((li, idx) => {
          if (property.addAnother && idx === row.index) {
            return {
              ...li,
              addAnother: true,
            };
          }
          return {
            ...li,
            addAnother: false,
          };
        }),
      ];
      const insertIndex = row.index;

      const createdProperty = {
        ...property,
        id: uuidv4(),
        addAnother: false,
      };

      newPropertiesArray.splice(insertIndex, 0, createdProperty);

      await PropertyAPI.post(createdProperty);

      dispatch({
        type: SET_PROPERTIES,
        properties: newPropertiesArray,
      });
      reload();
    },
    [dispatch, properties, reload]
  );

  const onDeleteRowClick = async (propertyIdToDelete) => {
    setProperties((prev) => prev.filter((a) => a.id !== propertyIdToDelete));
    const newPropertyArray = [...originalProperties];
    dispatch({
      type: SET_PROPERTIES,
      properties: newPropertyArray.filter((a) => a.id !== propertyIdToDelete),
    });

    await PropertyAPI.delete(propertyIdToDelete);
  };

  const onEditRowCancelClick = (row) => {
    const { index: resetIndex } = row;
    setProperties((prev) =>
      prev.map((property, index) => {
        if (index === resetIndex) {
          return originalProperties[index];
        }
        return property;
      })
    );
  };

  const onStartRowEditing = () => {
    setEditCount((prev) => prev + 1);
  };

  const onEndRowEditing = () => {
    setEditCount((prev) => prev - 1);
  };

  const handleFavoriteClick = React.useCallback(
    (id) => {
      const foundItem = favorites.find((favorite) => {
        return favorite.item.reference.includes(id);
      });

      if (foundItem) {
        deleteFavorite([foundItem]);
      } else {
        postFavorite(id, "Property");
      }
    },
    [deleteFavorite, favorites, postFavorite]
  );

  return (
    <>
      <PurePropertyTable
        handleRowSelect={handleRowSelect}
        allowSelection
        data={React.useMemo(
          () =>
            properties?.map((property) => {
              return {
                ...PropertyData.formatTableData(
                  property,
                  propertyTypes,
                  tagsData?.tagsDict
                ),
                isFavorited:
                  property.isFavorited ??
                  favorites.some((fav) =>
                    fav.item.reference.includes(property.id)
                  ),
              };
            }),
          [favorites, properties, propertyTypes, tagsData?.tagsDict]
        )}
        name="property"
        setProperties={setProperties}
        onAddPropertyClick={onAddPropertyClick}
        onRowClick={onRowClick}
        onSaveRowClick={onSaveRowClick}
        onEditRowCancelClick={onEditRowCancelClick}
        onDeleteRowClick={onDeleteRowClick}
        onStartRowEditing={onStartRowEditing}
        onEndRowEditing={onEndRowEditing}
        propertyTypes={propertyTypes}
        onEditSaveCallback={onEditSaveCallback}
        onAddSaveCallback={onAddSaveCallback}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        handleFavoriteClick={handleFavoriteClick}
        showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
        fileActionsIcons={
          <TableActionsIconsGroup
            handleShowDelete={() => {
              setShowDeleteModal(true);
            }}
            canDelete={isShowingPropertiesTable}
            disabled={selectedRows?.length === 0}
            showColumnSettingsRight={
              SHOW_COLUMNS_SETTING_RIGHT && isShowingPropertiesTable
            }
            selectedRows={selectedRows}
            disabledColumnSettings={!isShowingPropertiesTable}
          />
        }
        hideSiteHeaderTitle={hideSiteHeaderTitle}
      />

      <PropertyDeleteModal
        property={selectedRows[0]}
        removeProperty={removeProperty}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

PropertyTable.propTypes = {
  /**
   * function called when a row is clicked
   */
  onRowClick: PropTypes.func,
  hideSiteHeaderTitle: PropTypes.bool,
};

PropertyTable.defaultProps = {
  onRowClick: undefined,
  hideSiteHeaderTitle: false,
};

export default PropertyTable;
