import { v4 as uuidv4 } from "uuid";
import { ADD_OPEN_MODAL, VIEW_TODO_MODAL } from "../../../../constants";
import { useModalState } from "../../../../state/modalState";

export default ({ create }) => {
  const [, modalDispatch] = useModalState();

  const handleSubmit = (todo) => {
    create(todo);
  };

  const openCreate = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { edit: true, onFinished: handleSubmit },
      modalType: VIEW_TODO_MODAL,
    });
  };

  return { openCreate };
};
