import { useCallback, useEffect, useState } from "react";
import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { getFullName } from "../helpers/Formatters";
import { useAppState } from "../state/appState";
import { SET_PROJECTS, SET_PROJECT_DD, SET_PROJECT_DICT } from "../constants";
import { toastError } from "../stories/Components/Toast/Toast";

export default (projectId) => {
  const [
    { projects, projectDict: dictionary, projectDD: dropDown, userDict },
    dispatch,
  ] = useAppState();
  const [project, setProject] = useState();
  const [projectMembers, setProjectMembers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const reload = useCallback(async () => {
    if (projectId) {
      setLoading(true);
      try {
        const { data } = await ProjectAPI.getById(projectId);

        setProject(data);

        const membersRefs = data?.members?.map(({ user }) => user) || [];

        setProjectMembers(
          membersRefs?.map((ref) => {
            const user = userDict[ref];
            return {
              label: user?.companyName || getFullName(user?.name),
              value: user?.reference,
            };
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  }, [projectId, userDict]);

  const patchProject = async (finishedProject, throwError) => {
    let rv;
    try {
      const { data } = await ProjectAPI.patch(
        project.id,
        finishedProject,
        project
      );

      setProject(data);
      rv = data;
    } catch (err) {
      if (throwError) {
        throw err;
      }
    }

    return rv;
  };

  const removeProject = useCallback(
    async (proId) => {
      try {
        await ProjectAPI.delete(proId);

        dispatch({
          type: SET_PROJECTS,
          projects: projects?.filter((item) => item.id !== proId),
        });
        const tempDict = { ...dictionary };

        /**
         * Remove Project From Dictionary
         */
        delete tempDict?.[`Project/${proId}`];
        dispatch({
          type: SET_PROJECT_DICT,
          projectDict: tempDict,
        });

        /**
         * Remove Project From DropDown
         */
        dispatch({
          type: SET_PROJECT_DD,
          dropDown: dropDown?.filter((item) => item.id !== proId),
        });
      } catch (err) {
        toastError(`Error deleting project. Try again later`);
      }
    },
    [dispatch, projects, dictionary, dropDown]
  );

  useEffect(() => {
    reload();
  }, [reload]);

  return [
    project,
    reload,
    setProject,
    patchProject,
    removeProject,
    isLoading,
    projectMembers,
  ];
};
