import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ADD_OPEN_MODAL, CREATE_TASK_MODAL } from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useEsTasks from "../../../hooks/useEsTasks";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import {
  getAssociationNameFromReference,
  getWorkflowNameFromReference,
} from "../../../helpers/Utilities";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import useWorkflow from "../../../hooks/useWorkflow";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";
import useTaskById from "../../../hooks/useTaskById";
import getUniqueTasksWithClosestStartDate from "../../../helpers/Date/getUniqueTasksWithClosestStartDate";
import { getFullName } from "../../../helpers/Formatters";
import formatTasks from "../../../helpers/ServiceRequest/formatTasks";
import getFormattedRecurrence from "../../../helpers/Date/getFormattedRecurrence";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";

// This custom hook manages the task list-related data.
const useTaskListData = (setButtonActions) => {
  const [{ userDict, projectDict, propertiesDict, calendarTimezone }] =
    useAppState();
  const { data: workflowsData } = useWorkflow();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();

  const taskIdParam = useURLQueryParameter("id");
  const instanceStartDateQueryParam = useURLQueryParameter("instanceStartDate");

  // Fetch a single task if url has the `id` param
  const { data: singleTask, isLoading: isSingleTaskLoading } = useTaskById(
    taskIdParam,
    instanceStartDateQueryParam
  );

  // State to manage visibility of header items based on the current page
  const [showHeaderItems, setShowHeaderItems] = useState(false);
  const [{ modals }, modalDispatch] = useModalState();

  const { getTaskListByAssociation, reloadTaskList } = useEsTasks();
  const [formattedTasks, setFormattedTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const { associationLock, assetLock } = useRelativeAssociations();
  const { usedSopDict } = useSopVersionForTask({ currentTask });

  // templates for Quick View Drop Down
  const { data: templatesConfiguration } = useTemplatesConfiguration();
  const { mutate: patchTemplates } = useTemplatesConfigurationPatch();
  const { mutate: deleteTemplate } = useTemplatesConfigurationDelete();
  const [tasksTemplates, setTasksTemplates] = useState([]);
  const [commentsData, setCommentsData] = useState({
    taskReference: null,
    association: null,
  });

  const handleCloseComments = () => {
    setCommentsData({
      taskReference: null,
      association: null,
    });
  };

  const handleOpenComments = ({ reference, association }) => {
    setCommentsData({
      taskReference: reference,
      association,
    });
  };
  // Set templates for spaces (Quick View)
  useEffect(() => {
    if (templatesConfiguration?.templates?.task) {
      setTasksTemplates(templatesConfiguration.templates.task);
    }
  }, [templatesConfiguration, templatesConfiguration?.templates.task]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      patchTemplates({
        resource: "task",
        updatedTemplates: [
          ...tasksTemplates.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return {
              ...view,
              custom: true,
            };
          }),
        ],
      });
    },
    [patchTemplates, tasksTemplates]
  );
  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        resource: "task",
        id: template.id,
      });
    },
    [deleteTemplate]
  );
  const openNewTaskDialog = useCallback(
    (lock, childLock) => {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: uuidv4() },
        modalData: { associationLock: lock, assetLock: childLock },
        modalType: CREATE_TASK_MODAL,
      });
    },
    [modalDispatch]
  );

  useEffect(() => {
    reloadTaskList();
  }, [reloadTaskList]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/tasks/123", currentPage will be "tasks"
    const currentPage = location.pathname.split("/")[1];

    // If the current page is "tasks", show the header items
    if (currentPage === "tasks") setShowHeaderItems(true);

    // Check if the current page isn't the "tasks" page
    if (currentPage !== "tasks") {
      // Update the buttonActions state
      setButtonActions((prev) => {
        // Prevent Multiple create task modals from opening
        if (modals?.find((item) => item?.modalType === CREATE_TASK_MODAL)) {
          return prev?.filter((opt) => opt.title !== "Add Task");
        }

        // Check if there's no action with the title "Add Task" in the previous state
        // If "Add Task" is not found, prepend it to the current actions
        if (
          !prev?.find((opt) => opt?.title === "Add Task") &&
          !modals?.find((item) => item?.modalType === CREATE_TASK_MODAL) &&
          // Association lock will either be value or false(add to list after)
          (associationLock || typeof associationLock === "boolean")
        ) {
          return [
            {
              title: "Add Task",
              onClick: () => openNewTaskDialog(associationLock, assetLock),
              tabAction: true,
              associationLock,
            },
            ...prev, // Spread the previous state (existing actions)
          ];
        }

        // If "Add Task" is already in the actions, return the previous state unchanged
        return prev;
      });
    }
  }, [
    location,
    setButtonActions,
    openNewTaskDialog,
    modals,
    associationLock,
    assetLock,
  ]);

  // TODO: (Tech Debt) Could refactor to improve performance and move to helper file
  useEffect(() => {
    const allTasks = getTaskListByAssociation();

    const newTasks = allTasks?.map((item) => {
      const association = item?.association?.split("/")[0];
      return {
        ...item,
        lastCompleted: item?.metadata.lastUpdated,
        createdBy: getFullName(userDict[item?.metadata?.createdBy]?.name),
        completedBy: item?.closing?.closedBy
          ? getFullName(userDict[item?.closing?.closedBy]?.name)
          : "N/A",
        formattedStatus: formatTasks(item)?.formattedStatus,
        formattedRecurrence: getFormattedRecurrence(item.recurrence),
        associatedResource: association,
        associatedName:
          getAssociationNameFromReference(
            item.association,
            propertiesDict,
            projectDict
          ) ?? "",
        workflowName: getWorkflowNameFromReference(
          item.reference,
          workflowsData?.workflows?.filter((workflow) => !workflow.isTemplate)
        ),
      };
    });

    const tasksWithRecurrences = newTasks.filter(
      (newTask) => newTask?.recurrence
    );

    const tasksWithoutRecurrences = newTasks.filter(
      (newTask) => !newTask?.recurrence
    );

    const trimmedTasks =
      getUniqueTasksWithClosestStartDate(tasksWithRecurrences);

    setFormattedTasks([...trimmedTasks, ...tasksWithoutRecurrences]);
  }, [
    projectDict,
    propertiesDict,
    userDict,
    workflowsData?.workflows,
    getTaskListByAssociation,
    calendarTimezone,
  ]);

  useEffect(() => {
    if (singleTask) {
      setCurrentTask(singleTask);
    }
  }, [singleTask]);

  const onTaskClick = (rowData) => {
    const pageViewedFrom = location.pathname.split("/")[1];
    if (pageViewedFrom === "tasks") {
      if (rowData?.original?.instanceStartDate) {
        history.push(
          `/tasks/${rowData?.original.id}&instanceStartDate=${rowData?.original.instanceStartDate}`
        );

        return;
      }

      history.push(`/tasks/${rowData?.original.id}`);
      return;
    }
    setCurrentTask(rowData?.original);
  };

  const clearCurrentTask = () => {
    setCurrentTask(null);
  };
  // Check if the current user has a modal open of create task type
  const newTaskButtonDisabled = modals?.find(
    (item) => item?.modalType === CREATE_TASK_MODAL
  );

  // Check if the current user has the correct privileges to create a new task
  const hasCorrectPrivileges =
    currentUser?.isAdmin || currentUser?.hasPermission?.("task", "can_create");

  return {
    showHeaderItems,
    modals,
    newTaskButtonDisabled,
    hasCorrectPrivileges,
    formattedTasks,
    currentTask,
    isLoading: isSingleTaskLoading && !!taskIdParam,
    tasksTemplates,
    commentsData,
    openNewTaskDialog,
    onTaskClick,
    setCurrentTask,
    clearCurrentTask,
    usedSopDict,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    handleCloseComments,
    handleOpenComments,
  };
};

export default useTaskListData;
