import { useMemo } from "react";
import getAssociatedMembersForTaskEvent from "../helpers/Calendar/getAssociatedMembersForTaskEvent";
import { useAppState } from "../state/appState";

const useAssociatedMembersAndContactsForEventTask = ({ eventForm }) => {
  const [{ userDict, propertiesDict, projectDict, assetsDict }] = useAppState();

  const membersAndContacts = useMemo(() => {
    // If the form has an association, return associated members.
    if (eventForm?.association) {
      return getAssociatedMembersForTaskEvent({
        associationReference: eventForm.association,
        userDict,
        propertiesDict,
        assetsDict,
        projectDict,
        full: true,
      });
    }

    // If there's no association, return empty array
    return [];
  }, [assetsDict, projectDict, propertiesDict, userDict, eventForm]);

  return membersAndContacts;
};

export default useAssociatedMembersAndContactsForEventTask;
