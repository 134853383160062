/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import Assets from "../../../Pages/Overviews/Asset/Assets";

const ProjectAssetCardGrid = ({ onAssetClick }) => {
  return <Assets noFilter onAssetClick={onAssetClick} />;
};

ProjectAssetCardGrid.propTypes = {
  /**
   * function called when a row is clicked
   */
  onAssetClick: PropTypes.func,
};

ProjectAssetCardGrid.defaultProps = {
  onAssetClick: undefined,
};

export default ProjectAssetCardGrid;
