import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";

import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useDocumentFormReducer from "../../../hooks/useDocumentFormReducer";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";

import { getBudgetLineItems } from "../../../hooks/useBudgetLineItems";
import useProjects from "../../../hooks/useProjects";
import usePropertyById from "../../../hooks/usePropertyById";
import useProperties from "../../../hooks/useProperties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useUsers from "../../../hooks/useUsers";

import ResourceDropDown from "../WorkflowCreation/ResourceDropDown";
import RequestForInformation from "../DocumentDetailsForm/RequestForInformation";
import RequestForProposal from "../DocumentDetailsForm/RequestForProposal";
import ChangeOrder from "../DocumentDetailsForm/ChangeOrder";
import ContingencyAuthorization from "../DocumentDetailsForm/ContingencyAuthorization";
import PurchaseAuthorization from "../DocumentDetailsForm/PurchaseAuthorization";
import PurchaseOrder from "../DocumentDetailsForm/PurchaseOrder";
// eslint-disable-next-line import/no-cycle
import DocumentReviewForm from "../DocumentReviewForm/DocumentReviewForm";
// eslint-disable-next-line import/no-cycle
import DocumentAdjustments from "../DocumentAdjustments/DocumentAdjustments";
// eslint-disable-next-line import/no-cycle
import DocumentReferences from "../DocumentReferences/DocumentReferences";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import Dropdown from "../Dropdown/Dropdown";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import Spinner from "../Spinner/Spinner";
import ModalConfirmAll from "../Modal/ModalConfirmAll";
import { getCSICodeDescription } from "../../../helpers/Budget";
import { formatSelectUser } from "../../../helpers/Formatters";
import {
  RESET,
  DOCUMENT_TYPE,
  DOCUMENT_PROJECT,
  DOCUMENT_PROPERTY,
  PURCHASE_AUTHORIZATION,
  PURCHASE_ORDER,
  CHANGE_ORDER,
  REQUEST_FOR_PROPOSAL,
  REQUEST_FOR_INFORMATION,
  CONTINGENCY_AUTHORIZATION,
  GET_PROJECT_DOCUMENTS_PATH,
  GET_PROPERTY_DOCUMENT_PATH,
  DOCUMENTS_PATH,
  COA_CODE_TYPES,
  SIMPLE_INPUT,
  BUDGET_COST_PLUS,
  budgetDocTypeMap,
  budgetLockedDocTypeMap,
  BUDGET_FIXED_FIRM,
  budgetTypeMap,
  GET_PROJECTS_PATH,
  CONTINGENGY_CSI_CODE,
  docLineItemAdjustmentKeys,
} from "../../../constants";
import { getLocationState } from "../../../helpers/Navigation";

const PRIMARY_HEADER = "Create Document";

const DocumentForm = ({ existingDoc, onClose }) => {
  const { state } = useLocation();
  const { lineItems, isFlowFromBudgetTable } = getLocationState(state);
  const { projectId, propertyId } = useParams();
  const { projects: allProjects, currentProject: project } = useProjects(
    existingDoc?.project ?? projectId
  );
  const { currentProperty: property } = usePropertyById(
    existingDoc?.property ?? propertyId
  );
  const [allProperties] = useProperties();

  const [users] = useUsers();
  const { data: currentUser } = useCurrentUser();
  const { data: docConfig } = useDocumentsConfiguration();
  const [document, dispatch] = useDocumentFormReducer();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const { data: systemConfiguration } = useSystemConfiguration();

  const [isLoading, setIsLoading] = useState();
  const [budget, setBudget] = useState({});
  const [budgetId, setBudgetId] = useState();
  const [userData, setUserData] = useState();
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [associatedResource, setAssociatedResource] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);

  const [budgetName, setBudgetName] = useState();
  const [costPlusLocked, setCostPlusLocked] = useState();
  const [, setFixedFirmLocked] = useState();
  const [selectedBudgetRows, setSelectedBudgetRows] = useState();

  const [workingLineItems, setWorkingLineItems] = useState();
  const [originalLineItems, setOriginalLineItems] = useState();
  const [csiCodes, setCsiCodes] = useState([]);
  const [divisionCodes, setDivisionCodes] = useState();
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [budgetExists, setBudgetExists] = useState(true);
  const [contingencyExists, setContingencyExists] = useState(false);

  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [handledRequirements, setHandledRequirements] = useState(false);
  const [hasAdjustmentError, setHasAdjustmentError] = useState(false);
  const [hasAdjustment, setHasAdjustment] = useState(false);

  useEffect(() => {
    if (document?.state?.lineItemData) {
      const hasError = Object.entries(document.state.lineItemData).some(
        ([, obj]) => {
          return obj?.hasInputError;
        }
      );

      const hasAdjustmentInLineitems = Object.entries(
        document.state.lineItemData
      ).some(([, obj]) => {
        return !!obj[docLineItemAdjustmentKeys[document.docType]];
      });

      setHasAdjustment(hasAdjustmentInLineitems);
      setHasAdjustmentError(hasError);
    }
  }, [document.docType, document.state.lineItemData]);

  useEffect(() => {
    if (existingDoc) {
      dispatch({
        type: RESET,
        value: existingDoc,
      });
    }
  }, [dispatch, existingDoc]);

  useEffect(() => {
    const { budgetType, isLockGmp, isLockFixedFirm } = budget ?? {};

    const lockGMP = budgetType === BUDGET_COST_PLUS && isLockGmp;
    const lockFF = budgetType === BUDGET_FIXED_FIRM && isLockFixedFirm;

    if (lockGMP) {
      setBudgetName(budgetTypeMap[`${BUDGET_COST_PLUS}L`]);
    } else if (lockFF) {
      setBudgetName(budgetTypeMap[`${BUDGET_FIXED_FIRM}L`]);
    } else {
      setBudgetName(budgetTypeMap[budgetType]);
    }

    setCostPlusLocked(lockGMP);
    setFixedFirmLocked(lockFF);
  }, [budget]);

  useEffect(() => {
    if (!(document.project || document.property)) {
      setHandledRequirements(false);
    } else if (!(document.dueDate?.date && document.customName)) {
      setHandledRequirements(false);
    } else if (document.docType === REQUEST_FOR_PROPOSAL) {
      setHandledRequirements(!!document.bidType);
    } else if (
      document.docType === CHANGE_ORDER ||
      document.docType === PURCHASE_ORDER ||
      document.docType === PURCHASE_AUTHORIZATION
    ) {
      setHandledRequirements(!!document.scheduleImpacts);
    } else if (document.docType === CONTINGENCY_AUTHORIZATION) {
      setHandledRequirements(!!document.scheduleImpacts);
    } else {
      setHandledRequirements(true);
    }
  }, [
    document.project,
    document.property,
    document.docType,
    document.workingLineItems,
    document.dueDate,
    document.bidType,
    document.customName,
    document.scheduleImpacts,
  ]);

  useEffect(() => {
    if (originalLineItems?.length && lineItems && !workingLineItems) {
      setWorkingLineItems(lineItems ?? []);
    }
  }, [lineItems, workingLineItems, originalLineItems]);

  useEffect(() => {
    if (!(document.property || document.project)) {
      if (propertyId) {
        dispatch({
          type: DOCUMENT_PROPERTY,
          value: `Property/${propertyId}`,
        });
      } else if (projectId) {
        dispatch({
          type: DOCUMENT_PROJECT,
          value: `Project/${projectId}`,
        });
      } else if (associatedResource) {
        if (associatedResource.type === "project") {
          dispatch({
            type: DOCUMENT_PROJECT,
            value: associatedResource.value,
          });
        } else {
          dispatch({
            type: DOCUMENT_PROPERTY,
            value: associatedResource.value,
          });
        }
      }
    }
  }, [
    document.property,
    document.project,
    document.docType,
    associatedResource,
    projectId,
    propertyId,
    dispatch,
  ]);

  useEffect(() => {
    const getLineItems = async (id) => {
      if (!id) return;

      setIsLoading(true);
      try {
        const { budget: currentBudget, lineItems: lines } =
          await getBudgetLineItems(id);
        setBudgetExists(true);
        setBudget(currentBudget);
        setBudgetId(id);
        setOriginalLineItems(lines);
        setContingencyExists(
          lines.some((line) => CONTINGENGY_CSI_CODE.includes(line.csiCode))
        );
        dispatch({
          type: SIMPLE_INPUT,
          key: "budget",
          value: `Budget/${id}`,
        });
        dispatch({
          type: SIMPLE_INPUT,
          key: "lineItems",
          value: lines.filter((item) => workingLineItems?.includes(item.id)),
        });
      } catch (err) {
        dispatch({
          type: SIMPLE_INPUT,
          key: "budget",
          value: undefined,
        });
        dispatch({
          type: SIMPLE_INPUT,
          key: "lineItems",
          value: [],
        });
        setBudget({});
        setBudgetId();
        setOriginalLineItems([]);
        setBudgetExists(false);
        console.error(err);
      }
      setIsLoading(false);
    };

    let currentBudgetId;
    if (property?.budget) {
      currentBudgetId = property.budget?.split("/")[1];
    } else if (project?.budget) {
      currentBudgetId = project.budget?.split("/")[1];
    } else if (document.project) {
      currentBudgetId = allProjects
        ?.find((item) => item.reference === document.project)
        ?.budget?.split("/")[1];
    } else if (document.property) {
      currentBudgetId = allProperties
        ?.find((item) => item.reference === document.property)
        ?.budget?.split("/")[1];
    }

    if (budgetId !== currentBudgetId && budgetExists) {
      getLineItems(currentBudgetId);
    }
  }, [
    budgetId,
    project,
    property,
    allProjects,
    allProperties,
    document.project,
    document.property,
    workingLineItems,
    budgetExists,
    dispatch,
  ]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const divisionsFormatted = [];
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.map((div) => {
        const division = {
          label: `${div.division} - ${div.description}`,
          value: div.division,
        };
        divisionsFormatted.push(division);

        div.csiCodes.map((csi) => {
          csi.subCodes.map((sub) => {
            const csiCodeFormatted = {
              label: `${div.division} ${csi.code} ${
                sub.code
              } - ${getCSICodeDescription(
                { division: div.division, code: csi.code, subcode: sub.code },
                financialsConfiguration?.financials?.csiCodeMapping,
                revenueAccountingCodes,
                expenseAccountingCodes
              )}`,
              value: `${div.division} ${csi.code} ${sub.code}`,
            };
            csiCodesFormatted.push(csiCodeFormatted);
            return sub;
          });

          return csi;
        });
        return div;
      });
      setCsiCodes(csiCodesFormatted);
      setDivisionCodes(divisionsFormatted);
    }
  }, [expenseAccountingCodes, financialsConfiguration, revenueAccountingCodes]);

  useEffect(() => {
    if (users?.length) {
      setUserData(() => {
        return users
          ?.filter((usr) => usr.kind === "company")
          .map((usr) => {
            return formatSelectUser({
              ...usr,
              role: usr.roleName,
            });
          });
      });
    }
  }, [users]);

  useEffect(() => {
    if (systemConfiguration?.system?.unitsOfMeasure) {
      const lengthOptions =
        systemConfiguration?.system?.unitsOfMeasure?.length_area
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const qtyOptions = systemConfiguration?.system?.unitsOfMeasure?.quantity
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const timeOptions = systemConfiguration?.system?.unitsOfMeasure?.time
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const volumeOptions =
        systemConfiguration?.system?.unitsOfMeasure?.volume_weight
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));

      setUnitsOfMeasure([
        ...lengthOptions,
        ...qtyOptions,
        ...timeOptions,
        ...volumeOptions,
      ]);
    }
  }, [systemConfiguration]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter(
          (doc) => !doc.internalUseOnly && doc.selected && (doc.value || doc.id)
        )
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const filteredDocTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        ?.filter(
          (doc) =>
            !doc.internalUseOnly &&
            doc.selected &&
            doc.custom === false &&
            (doc.value || doc.id)
        )
        .filter((doc) => {
          if (!budget?.budgetType) return true;
          if (budget.isLockGmp || budget.isLockFixedFirm) {
            return budgetLockedDocTypeMap[budget.budgetType]?.includes(
              doc.value
            );
          }
          return budgetDocTypeMap[budget.budgetType]?.includes(doc.value);
        })
        .filter((doc) => {
          if (contingencyExists) {
            return true;
          }
          return doc.value !== CONTINGENCY_AUTHORIZATION;
        })
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [
    docConfig?.documents?.documentType,
    budget.budgetType,
    budget.isLockGmp,
    budget.isLockFixedFirm,
    contingencyExists,
  ]);

  const docTypes = React.useMemo(() => {
    return Object.values(filteredDocTypeOptionsMap)?.sort(
      (a, b) => a.label - b.label
    );
  }, [filteredDocTypeOptionsMap]);

  const currentDocType = React.useMemo(
    () => document.docType,
    [document.docType]
  );

  const handleAdjustmentValidation = React.useCallback(
    (formDataOverride, stepReturn) => {
      const lineItemData = document.state?.lineItemData ?? {};
      const formData = document.state?.formData?.purchaseAuthorization ?? {};
      let isValidCoPending;
      // let hasContingency;

      switch (document.docType) {
        case CHANGE_ORDER:
          isValidCoPending = document.workingLineItems.every(
            (item) => lineItemData[item.id]?.coTotalPending
          );
          if (stepReturn && !isValidCoPending) {
            setSelectedStepIndex(2);
            return false;
          }
          return isValidCoPending;
        case CONTINGENCY_AUTHORIZATION:
          // Note: Dont need a value (uncommittedValuePending) in contingency to procedd, but need a contingency LI in budget,
          // so commenting out for now

          // hasContingency = originalLineItems
          //   .filter((item) => {
          //     return CONTINGENGY_CSI_CODE.includes(item.csiCode);
          //   })
          //   .reduce((val, item) => {
          //     return val + (Number(item.uncommittedValuePending) || 0);
          //   }, 0);
          // if (stepReturn && !hasContingency) {
          //   setSelectedStepIndex(2);
          //   return false;
          // }
          return (
            // hasContingency &&
            document.workingLineItems.every(
              (item) => lineItemData[item.id]?.caAdjustment
            ) && !hasAdjustmentError
          );
        case PURCHASE_AUTHORIZATION:
          return document.workingLineItems.every((item) => {
            let validLineData = true;
            let validFormData = true;

            const { coAssignment, caAssignment } = formData[item.id] ?? {};
            const paAmount = Number(
              `${lineItemData[item.id]?.paAmount}`.replaceAll(",", "")
            );

            validLineData = paAmount > 0;

            if (costPlusLocked) {
              const paAdjustment = Number(
                `${lineItemData[item.id]?.paAdjustment}`.replaceAll(",", "")
              );
              const uncommittedValuePending =
                document.workingLineItems?.find((line) => line.id === item.id)
                  ?.uncommittedValuePending ?? -1;
              const uncommittedPendingAmount = Number(
                `${uncommittedValuePending}`.replaceAll(",", "")
              );

              validLineData =
                uncommittedPendingAmount > 0 &&
                uncommittedPendingAmount >= paAmount;

              const coAmount = Number(`${coAssignment}`.replaceAll(",", ""));
              const caAmount = Number(`${caAssignment}`.replaceAll(",", ""));
              validFormData =
                !paAdjustment || paAdjustment === coAmount + caAmount;

              if (stepReturn && !validFormData) {
                setSelectedStepIndex(2);
                return false;
              }
            }

            if (stepReturn && !validLineData) {
              setSelectedStepIndex(1);
              return false;
            }

            return validLineData && (validFormData || formDataOverride);
          });
        default:
          return true;
      }
    },
    [
      document.state?.lineItemData,
      document.state?.formData?.purchaseAuthorization,
      document.docType,
      document.workingLineItems,
      hasAdjustmentError,
      costPlusLocked,
    ]
  );

  const handleSetAssociation = React.useCallback(
    ({ value, type, name }) => {
      if (type === "project") {
        dispatch({
          type: DOCUMENT_PROJECT,
          value,
        });
      } else {
        dispatch({
          type: DOCUMENT_PROPERTY,
          value,
        });
      }
      dispatch({
        type: SIMPLE_INPUT,
        key: "docType",
        value: null,
      });
      setAssociatedResource({ value, type, name });
      setBudgetExists(true);
    },
    [dispatch]
  );

  const handleTableRowSelect = React.useCallback(
    (val) => {
      setSelectedBudgetRows(_.uniq(val));
    },
    [setSelectedBudgetRows]
  );

  const handleFinalValidation = React.useCallback(() => {
    const formIsValid = handleAdjustmentValidation(false, true);
    return formIsValid;
  }, [handleAdjustmentValidation]);

  const steps = React.useMemo(() => {
    const details = {
      name: "Details",
      component: (params) => {
        let comp;
        switch (params.docType) {
          case PURCHASE_AUTHORIZATION:
            comp = (
              <>
                <PurchaseAuthorization
                  document={document}
                  dispatch={dispatch}
                  configs={docConfig}
                  userData={userData}
                  {...params}
                />
              </>
            );
            break;
          case PURCHASE_ORDER:
            comp = (
              <>
                <PurchaseOrder
                  document={document}
                  dispatch={dispatch}
                  configs={docConfig}
                  userData={userData}
                  {...params}
                />
              </>
            );
            break;
          case CHANGE_ORDER:
            comp = (
              <ChangeOrder
                document={document}
                dispatch={dispatch}
                configs={docConfig}
                userData={userData}
                {...params}
              />
            );
            break;
          case REQUEST_FOR_PROPOSAL:
            comp = (
              <RequestForProposal
                document={document}
                dispatch={dispatch}
                configs={docConfig}
                userData={userData}
                {...params}
              />
            );
            break;
          case REQUEST_FOR_INFORMATION:
            comp = (
              <RequestForInformation
                document={document}
                dispatch={dispatch}
                configs={docConfig}
                userData={userData}
                currentUser={currentUser}
                docTypeOptionsMap={docTypeOptionsMap}
                {...params}
              />
            );
            break;
          case CONTINGENCY_AUTHORIZATION:
            comp = (
              <ContingencyAuthorization
                document={document}
                dispatch={dispatch}
                configs={docConfig}
                userData={userData}
                {...params}
              />
            );
            break;
          default:
            break;
        }
        return <div className="xl:w-1/3 lg:w-1/2">{comp}</div>;
      },
    };
    const references = {
      name: "References",
      component: () => (
        <div className="w-full">
          <DocumentReferences
            document={document}
            dispatch={dispatch}
            property={property}
            project={project}
            budget={budget}
            budgetId={budgetId}
            currentUser={currentUser}
            docTypeOptionsMap={docTypeOptionsMap}
            selectedBudgetRows={selectedBudgetRows}
            onBudgetRowSelect={handleTableRowSelect}
            originalLineItems={originalLineItems}
            csiCodes={csiCodes}
            divisionCodes={divisionCodes}
            unitsOfMeasure={unitsOfMeasure}
          />
        </div>
      ),
    };
    const adjustments = {
      name: "Adjustments",
      component: () => (
        <div className="w-full">
          <DocumentAdjustments
            document={document}
            dispatch={dispatch}
            property={property}
            project={project}
            budget={budget}
            budgetId={budgetId}
            selectedBudgetRows={selectedBudgetRows}
            onBudgetRowSelect={handleTableRowSelect}
            originalLineItems={originalLineItems}
            csiCodes={csiCodes}
            unitsOfMeasure={unitsOfMeasure}
            userList={userData}
            disableAdd
          />
        </div>
      ),
    };
    const review = {
      name: "Review",
      component: (params) => {
        return (
          <div className="w-full">
            <DocumentReviewForm
              {...params}
              document={document}
              dispatch={dispatch}
              budget={budget}
              userList={userData}
              property={property}
              project={project}
              budgetId={budgetId}
              docTypeOptionsMap={docTypeOptionsMap}
              originalLineItems={originalLineItems}
              csiCodes={csiCodes}
              unitsOfMeasure={unitsOfMeasure}
              onFormValidate={handleFinalValidation}
              associatedResource={associatedResource}
              onSelectAssociatedResource={handleSetAssociation}
              selectedBudgetRows={selectedBudgetRows}
              onBudgetRowSelect={handleTableRowSelect}
              onClose={onClose}
              disableAssociation
              createMode
              isFlowFromBudgetTable={isFlowFromBudgetTable}
              hasAdjustmentError={hasAdjustmentError}
              disableEdit
            />
          </div>
        );
      },
    };

    switch (document.docType) {
      case PURCHASE_AUTHORIZATION:
        return [
          details,
          references,
          ...(costPlusLocked ? [adjustments] : []),
          review,
        ];
      case CHANGE_ORDER:
        return [details, references, adjustments, review];
      case CONTINGENCY_AUTHORIZATION:
        return [details, references, adjustments, review];
      case REQUEST_FOR_INFORMATION:
        return [details, review];
      default:
        return [details, references, review];
    }
  }, [
    document,
    dispatch,
    docConfig,
    userData,
    currentUser,
    docTypeOptionsMap,
    property,
    project,
    budget,
    budgetId,
    selectedBudgetRows,
    handleTableRowSelect,
    originalLineItems,
    csiCodes,
    divisionCodes,
    unitsOfMeasure,
    handleFinalValidation,
    associatedResource,
    handleSetAssociation,
    onClose,
    isFlowFromBudgetTable,
    costPlusLocked,
    hasAdjustmentError,
  ]);

  const lastStep = React.useMemo(() => steps.length - 1, [steps]);

  useEffect(() => {
    let enabled = true;
    switch (selectedStepIndex) {
      case 0:
        enabled = handledRequirements;
        break;
      case 1:
        enabled =
          document.docType === PURCHASE_AUTHORIZATION
            ? !!document.workingLineItems.length &&
              handleAdjustmentValidation(true)
            : !!document.workingLineItems.length;
        break;
      case 2:
        enabled =
          steps.length > 3
            ? handleAdjustmentValidation()
            : handledRequirements && !!document.workingLineItems.length;
        break;
      case 3:
        enabled = handledRequirements && !!document.workingLineItems.length;
        break;
      default:
        break;
    }
    setNextButtonEnabled(enabled);
  }, [
    steps,
    document.docType,
    document.state?.lineItemData,
    document.state?.formData,
    document.workingLineItems,
    selectedStepIndex,
    handledRequirements,
    handleAdjustmentValidation,
  ]);

  const docTypeChangeHandler = ({ value }) => {
    dispatch({
      type: RESET,
      value: null,
    });
    dispatch({
      type: DOCUMENT_TYPE,
      value,
    });
    dispatch({
      type: SIMPLE_INPUT,
      key: "budget",
      value: `Budget/${budgetId}`,
    });
    setWorkingLineItems();
  };

  const getCurrentStepView = (params) => {
    return steps[selectedStepIndex].component(params);
  };

  const discardButtonHandler = () => {
    if (onClose) {
      onClose();
    } else {
      setShowConfirm(true);
    }
  };

  const saveButtonHandler = () => {
    if (
      document.docType === PURCHASE_AUTHORIZATION &&
      selectedStepIndex === 3
    ) {
      setSelectedStepIndex(2);
    }
  };

  const handleContinue = async () => {
    if (selectedStepIndex === steps.length - 1) {
      saveButtonHandler();
      setSelectedStepIndex(0);
    } else if (
      // if PA and no lineitems have any adjustment, then skip the adjustments tab
      selectedStepIndex === 1 &&
      costPlusLocked &&
      document?.state?.lineItemData &&
      Object.entries(document.state.lineItemData)?.length &&
      !hasAdjustment
    ) {
      setSelectedStepIndex((prev) => prev + 2);
    } else {
      setSelectedStepIndex((prev) => prev + 1);
    }
  };

  const handleStepClick = (index) => {
    if (nextButtonEnabled || selectedStepIndex > index) {
      setSelectedStepIndex(index);
    }
  };

  const handleNextClick = () => {
    handleContinue();
  };

  const handleDiscard = () => {
    if (isFlowFromBudgetTable) {
      return `${GET_PROJECTS_PATH(projectId)}?tab=1`;
    }
    if (projectId) {
      return GET_PROJECT_DOCUMENTS_PATH(projectId);
    }
    if (propertyId) {
      return GET_PROPERTY_DOCUMENT_PATH(propertyId);
    }
    return DOCUMENTS_PATH;
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col flex-1">
          <PrimaryHeader className="pt-6">{PRIMARY_HEADER}</PrimaryHeader>
        </div>
        <div className="flex flex-1 justify-end pr-14">
          <div
            className={`flex flex-1 justify-end ${
              currentDocType ? "" : "invisible"
            }`}
          >
            <ProgressTracker
              steps={steps.map((s) => s.name)}
              selectedIndex={selectedStepIndex}
              onStepClick={(index) => handleStepClick(index)}
            />
          </div>
        </div>
      </div>
      <hr />

      {(!currentUser || isLoading) && (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner notFullScreen />
        </div>
      )}

      {currentUser && !isLoading && (
        <div className="mb-40">
          <div className="xl:w-1/3 lg:w-1/2">
            {selectedStepIndex === 0 && (
              <div className="flex flex-col  mt-5">
                <div className="h-20">
                  {currentUser &&
                    (currentUser.isAdmin || currentUser.isSuperAdmin) && (
                      <ResourceDropDown
                        resource={
                          associatedResource?.value ??
                          document.project ??
                          document.property
                        }
                        setResource={(value, type, name) =>
                          handleSetAssociation({ value, type, name })
                        }
                        disableAssociation={
                          !!(propertyId || projectId || existingDoc)
                        }
                      />
                    )}
                </div>

                {budgetName && (
                  <div className="flex justify-end mt-3 -mb-8 text-xs text-brandGreen font-semibold">
                    {budgetName}
                  </div>
                )}
                <Dropdown
                  className="mt-3"
                  label="Document Type"
                  options={
                    propertyId
                      ? docTypes.filter((d) => d.label.includes("RFI"))
                      : docTypes
                  }
                  placeholder="Search Types..."
                  value={docTypes?.find(
                    (item) => item.value === document.docType
                  )}
                  onChange={docTypeChangeHandler}
                  isDisabled={!budgetId}
                />
              </div>
            )}
          </div>

          {getCurrentStepView({
            docType: currentDocType,
          })}

          {currentDocType && selectedStepIndex < lastStep && (
            <div className="relative right-0 bottom-0 flex justify-end mt-20">
              <div
                className="flex w-fit py-4 px-4 border-gray-100 rounded-lg bg-white"
                style={{
                  borderWidth: "3px",
                  bottom: "75px",
                  right: "32px",
                  zIndex: "20",
                }}
              >
                <TertiaryButton
                  className="mr-2"
                  title="Discard"
                  onClick={discardButtonHandler}
                />
                {/* Removed for 3.5 */}
                {/* <SecondaryButton
                  className="mr-6"
                  title="Save Draft"
                  onClick={saveButtonHandler}
                /> */}
                <PrimaryButton
                  title="Next"
                  onClick={handleNextClick}
                  disabled={!nextButtonEnabled || hasAdjustmentError}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <ModalConfirmAll
        navigateToPath={handleDiscard()}
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={`Discard ${PRIMARY_HEADER}`}
      />
    </>
  );
};

DocumentForm.propTypes = {
  /**
   * existing document data
   */
  // eslint-disable-next-line react/forbid-prop-types
  existingDoc: PropTypes.object,
  /**
   * close functionality when in modal
   */
  onClose: PropTypes.func,
};

DocumentForm.defaultProps = {
  existingDoc: undefined,
  onClose: undefined,
};

export default DocumentForm;
