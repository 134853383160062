import cntl from "cntl";
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./ActionsButton.css";
import Chevron from "../Chevron";

const buttonStyle = (disabled, buttonClassName) => cntl`
border-2
rounded-lg
${!disabled && "border-borderGreen bg-white text-gray-500"}
${disabled && "bg-gray-100 border-gray-150 text-gray-150"}
font-semibold
h-10
flex
items-center
relative
${buttonClassName}
`;

const chevronCN = (disabled, chevronClassName) => cntl`
flex 
items-center 
h-full 
border-l-2 
${!disabled && "border-borderGreen"}
${disabled && "border-gray-150"}
${chevronClassName}
`;

const menuContainerCN = (open) => cntl`
  flex
  absolute
  right-0
  z-50
  transition-all
  mt-2
  rounded-md
  overflow-hidden
  border
  ${!open && "hidden"}
`;

const menuItem = (subMenuItem, isFirstItem, isLastItem) => cntl`
slide 
flex 
justify-start 
py-3 
px-6
${isFirstItem ? "rounded-t-md" : ""}
${isLastItem ? "rounded-b-md" : ""}
${subMenuItem ? "bg-gray-100" : "bg-white"}
w-full
whitespace-nowrap
text-gray-450
hover:bg-lightGreen
hover:text-gray-300
focus:bg-gray-100
focus:text-gray-300
menuItem
items-center
`;

/*
${index === 0 && "rounded-t-sm border-l border-r"}
${index === length - 1 && "rounded-b-sm"}
${index !== length - 1 && index !== 0 && "border-l border-r"}
*/

const ActionsButton = ({
  dropdownItems,
  disabled,
  subMenuItems,
  actionLabel,
  handleOnSubmenuClose,
  buttonClassName,
  chevronClassName,
  chevronColor,
  actionLabelCN,
}) => {
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const buttonRef = useRef({});

  const handleOpen = useCallback(
    (e) => {
      const subMenuControl = e.target.id.includes("subBtn");
      if (!open && !disabled) {
        setOpen(true);
      } else if (!subMenuControl) {
        setOpen(false);
        setSubMenuOpen(false);
      }
    },
    [disabled, open]
  );

  const handleBlur = useCallback(
    (e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setOpen(false);
        setSubMenuOpen(false);
        handleOnSubmenuClose();
      }
    },
    [handleOnSubmenuClose]
  );

  return (
    <button
      className={buttonStyle(disabled, buttonClassName)}
      onClick={(e) => {
        handleOpen(e);
      }}
      onBlur={(e) => handleBlur(e)}
      type="button"
      tabIndex={0}
      id="dropdown-button"
      aria-label="dropdown-button"
      ref={(el) => {
        buttonRef.current.parent = el;
      }}
    >
      <p className={`whitespace-nowrap px-3 ${actionLabelCN}`}>
        {actionLabel ?? "Actions"}
      </p>
      <div
        className={chevronCN(disabled, chevronClassName)}
        style={{ padding: "0rem 0.375rem" }}
      >
        <Chevron open={open} disabled={disabled} color={chevronColor} />
      </div>
      <div className={menuContainerCN(open)} style={{ top: "100%" }}>
        <div className="lift flex flex-col w-full">
          {dropdownItems.map((item, index) => {
            const isFirstItem = !index;
            const isLastItem = index === dropdownItems.length - 1;
            return (
              <React.Fragment key={`dropdown-key-${index + 1}`}>
                {!item?.openSubMenu && (
                  <button
                    // enabled by default if item.disabled is undefined
                    disabled={item?.disabled}
                    className={menuItem(false, isFirstItem, isLastItem)}
                    style={{ animationDelay: `${index * 0.05 + 0.05}s` }}
                    onClick={item?.onClick}
                    ref={(el) => {
                      buttonRef.current[`menu-item-child${index}-button`] = el;
                    }}
                    type="button"
                    tabIndex={0}
                    id={`menu-item-child${index}-button`}
                    aria-label={`${item?.title}-button`}
                  >
                    {item?.title}
                  </button>
                )}
                {item?.openSubMenu && (
                  <button
                    className={menuItem(true)}
                    style={{ animationDelay: `${index * 0.05 + 0.05}s` }}
                    onClick={() => {
                      setSubMenuOpen(!subMenuOpen);
                    }}
                    ref={(el) => {
                      buttonRef.current[
                        `subBtn-menu-item-child${index}-button`
                      ] = el;
                    }}
                    type="button"
                    tabIndex={0}
                    id={`subBtn-menu-item-child${index}-button`}
                    aria-label={`${item?.title}-button`}
                  >
                    {item?.title}
                    <div className="ml-4">
                      <Chevron open={subMenuOpen} disabled={disabled} />
                    </div>
                  </button>
                )}
              </React.Fragment>
            );
          })}
          {subMenuOpen &&
            subMenuItems.map((item, index) => (
              <button
                className={menuItem(true)}
                style={{ animationDelay: `${index * 0.05 + 0.05}s` }}
                onClick={item?.onClick}
                key={`${item?.title}-button`}
                ref={(el) => {
                  buttonRef.current[`menu-item-child${index}-button`] = el;
                }}
                type="button"
                tabIndex={0}
                id={`submenu-item-child${index}-button`}
                aria-label={`${item?.title}-button`}
              >
                {item.title}
              </button>
            ))}
        </div>
      </div>
    </button>
  );
};

ActionsButton.propTypes = {
  dropdownItems: PropTypes.arrayOf(PropTypes.shape({})),
  subMenuItems: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  actionLabel: PropTypes.string,
  handleOnSubmenuClose: PropTypes.func,
  buttonClassName: PropTypes.string,
  chevronClassName: PropTypes.string,
  chevronColor: PropTypes.string,
  actionLabelCN: PropTypes.string,
};

ActionsButton.defaultProps = {
  dropdownItems: [],
  subMenuItems: [],
  disabled: false,
  actionLabel: undefined,
  handleOnSubmenuClose: () => {},
  buttonClassName: undefined,
  chevronClassName: undefined,
  chevronColor: undefined,
  actionLabelCN: undefined,
};

export default ActionsButton;
