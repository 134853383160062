import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPropertySpaceOptions } from "../../../helpers/SiteHeaderOptions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import usePropertyById from "../../../hooks/usePropertyById";
import SpaceAssetList from "../Asset/PropertySpaceAssetList";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../../helpers/File";

const PropertySpaceAssets = () => {
  const { propertyId, locationId, spaceId } = useParams();
  const { property } = usePropertyById(propertyId);
  const { data: currentUser } = useCurrentUser();

  const [propertyImage, setPropertyImage] = useState();
  const [space, setSpace] = useState();

  useEffect(() => {
    const loctionResource = property?.buildings?.filter((loc) => {
      return loc.id === locationId;
    })[0];

    const spaceResource = loctionResource?.spaces?.filter((spc) => {
      return spc.id === spaceId;
    })[0];

    setSpace(spaceResource);
    if (spaceResource?.images?.length) {
      resolvePrimaryImage(spaceResource.images)
        .then((imageFile) => setPropertyImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve property primary image:${err}`)
        );
    }
  }, [spaceId, property, locationId]);

  return (
    <>
      <SpaceAssetList
        associatedResource={`Property/${propertyId}`}
        spaceId={spaceId}
        propertyId={propertyId}
        locationId={locationId}
        siteHeaderProps={{
          title: space?.name || "Loading",
          dropdownRoutes: getPropertySpaceOptions(
            propertyId,
            currentUser,
            locationId,
            spaceId
          ),
        }}
        bannerComponent={
          <DetailViewBanner
            resource={property}
            resourceImage={propertyImage}
            disableUpload
          />
        }
        disableCreate={!currentUser?.hasPermission?.("asset", "can_write")}
      />
    </>
  );
};

export default PropertySpaceAssets;
