import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PermissionAPI, RoleAPI, UserAPI } from "@griffingroupglobal/eslib-api";

import { transformUsersResponseToUserAvatars } from "../../helpers/User";
import editIcon from "../../stories/assets/images/editIcon.svg";
import checkIcon from "../../stories/assets/images/checkGreen.svg";
import RoleMembersAvatarList from "./RoleMembersAvatarList";
import SecondaryButton from "../../stories/Components/Buttons/SecondaryButton";
import Checkbox from "../../stories/Components/Checkbox/Checkbox";
import RoleDropdownItemProps from "../../props/RoleDropdownItemProps";
import RolePermissionsList from "./RolePermissionsList";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import Dropdown from "../../stories/Components/Dropdown/Dropdown";
import Input from "../../stories/Components/Input/Input";
import { ADMIN_ROLES_PERMISSIONS_PATH } from "../../constants";
import isRoleValid from "../../helpers/Role";

export async function fetchRoleMembers(roleId) {
  return UserAPI.get({ params: { role: `Role/${roleId}` } });
}

/**
 * Encapsulates the payload values for updating a Role.
 */
class PatchRolePayload {
  metadata = {};

  id = "";

  reference = "";

  sanitized = "";

  definition = "";

  name = "";

  permissions = {};

  withName(name) {
    this.name = name;
    return this;
  }

  withPermissions(selectedPermissions, allResourcePermissions) {
    this.permissions = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const resource of Object.keys(allResourcePermissions)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const permission of Object.keys(allResourcePermissions[resource])) {
        this.permissions[resource] = this.permissions[resource] || {};
        this.permissions[resource][permission] = !!(
          selectedPermissions[resource] &&
          selectedPermissions[resource][permission]
        );
      }
    }
    return this;
  }

  /**
   * Constructor
   */
  constructor({ metadata, id, reference, sanitized, definition }) {
    this.metadata = metadata;
    this.id = id;
    this.reference = reference;
    this.sanitized = sanitized;
    this.definition = definition;
  }
}

/**
 * Renders the "Edit Role" page for Roles & Permissions.
 */
function EditRole() {
  const { roleId } = useParams();
  const history = useHistory();

  const [role, setRole] = useState();
  const [isEditingRoleName, setEditingRoleName] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleMembers, setRoleMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [allResourcePermissions, setAllResourcePermissions] = useState({});
  const [expandedResources, setExpandedResources] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRoleToApply, setSelectedRoleToApply] = useState();

  /**
   * When a resource is toggled, add or remove it from the expandedResources list.
   */
  const onResourceExpansionToggled = (resource) => {
    if (expandedResources.indexOf(resource) < 0) {
      setExpandedResources([...expandedResources, resource]);
    } else {
      setExpandedResources(expandedResources.filter((res) => res !== resource));
    }
  };

  /**
   * Check whether a permission is selected or not, looking it up in the
   * selectedPermissions object.
   */
  const isPermissionSelected = useCallback(
    (resource, permission) => {
      return (
        selectedPermissions[resource] &&
        selectedPermissions[resource][permission]
      );
    },
    [selectedPermissions]
  );

  /**
   * Click handler for the checkbox next to a permission. Toggles it in the
   * selectedPermissions object.
   */
  const onPermissionSelected = (resource, permission) => {
    const newPermissions = { ...selectedPermissions };
    newPermissions[resource] = newPermissions[resource] || {};
    newPermissions[resource][permission] = !isPermissionSelected(
      resource,
      permission
    );
    // If can_read is unchecked, then uncheck all other permissions for that resource
    if (
      (permission === "can_read" ||
        (resource === "submittal" && permission === "can_see")) &&
      !newPermissions[resource][permission]
    ) {
      Object.keys(newPermissions[resource]).forEach((perm) => {
        newPermissions[resource][perm] = false;
      });
    }

    setSelectedPermissions(newPermissions);
  };

  /**
   * Check whether all permissions have been checked/selected. Used for the
   * "Select All" checkbox.
   */
  const isAllPermissionsChecked = useCallback(() => {
    if (Object.keys(allResourcePermissions).length === 0) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const resource of Object.keys(allResourcePermissions)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const permission of Object.keys(allResourcePermissions[resource])) {
        if (!isPermissionSelected(resource, permission)) {
          return false;
        }
      }
    }
    return true;
  }, [allResourcePermissions, isPermissionSelected]);

  /**
   * Click Handler for the "Apply Permissions From" dropdown. selectedRoleToApply
   * has a permissions prop. When the button is clicked, apply those permissions.
   */
  const onApplyPermissionClicked = () => {
    if (selectedRoleToApply) {
      setSelectedPermissions(selectedRoleToApply.permissions);
    }
  };

  /**
   * Click handler for the "Select All" checkbox.
   */
  const onSelectAllChecked = () => {
    if (selectAll) {
      setSelectedPermissions({});
    } else {
      const allPermissionsSelected = Object.fromEntries(
        Object.keys(allResourcePermissions).map((resource) => {
          const resourcePermissions = allResourcePermissions[resource];

          return [
            resource,
            Object.fromEntries(
              Object.keys(resourcePermissions).map((permission) => [
                permission,
                true,
              ])
            ),
          ];
        })
      );

      setSelectedPermissions(allPermissionsSelected);
    }
  };

  /**
   * Click handler for the "Save Changes" button.
   */
  const onSaveChangesClicked = () => {
    const updatedRole = new PatchRolePayload(role)
      .withName(roleName)
      .withPermissions(selectedPermissions, allResourcePermissions);

    RoleAPI.patch(roleId, updatedRole, role)
      .then(() => history.push(ADMIN_ROLES_PERMISSIONS_PATH))
      .catch((err) =>
        console.error(
          `EditRole#onSaveChangesClicked - error:${JSON.stringify(err)}`
        )
      );
  };

  /**
   * When the selected permissions change, check to see if the Select All
   * checkbox should be checked.
   */
  useEffect(() => {
    setSelectAll(isAllPermissionsChecked());
  }, [selectedPermissions, isAllPermissionsChecked]);

  useEffect(() => {
    Promise.all([
      PermissionAPI.getWOP("$master"),
      RoleAPI.get(),
      RoleAPI.getById(roleId, {}),
      UserAPI.get({ params: { role: `Role/${roleId}` } }),
    ])
      .then(
        ([
          permissionsResponse,
          allRolesResponse,
          roleResponse,
          roleMembersResponse,
        ]) => {
          setAllResourcePermissions(permissionsResponse.data);
          setAllRoles(
            RoleDropdownItemProps.fromGetRolesResponse(allRolesResponse.data)
          );
          // The JSON.parse(JSON.stringify(...)) call here is to create a deep
          // copy of the original role data, which is needed by RoleAPI#patch
          // when we later update the role.
          setRole(JSON.parse(JSON.stringify(roleResponse.data)));
          setRoleName(roleResponse.data.name);
          setSelectedPermissions(roleResponse.data.permissions);
          setRoleMembers(
            transformUsersResponseToUserAvatars(roleMembersResponse)
          );
        }
      )
      .catch((err) => console.error(`EditRole#useEffect - error:${err}`));
  }, [roleId]);

  return (
    <div className="roles-permission-edit-role flex flex-col">
      <div className="flex flex-row border-b-2 border-gray-150 pb-2.5">
        <h1 className="text-3xl pb-4 font-semibold tracking-widest flex-1">
          Edit Role
        </h1>
        <PrimaryButton
          title="Save Changes"
          disabled={!isRoleValid({ roleName })}
          onClick={onSaveChangesClicked}
          large
        />
      </div>

      <div className="flex flex-row mt-8 space-x-2 items-end justify-start">
        <p className="text-base font-normal tracking-wide flex-1 max-w-1/2">
          For all features, members may only see what they’re associated with.
          Members may only see entities, projects, and properties they are
          invite to.
        </p>
        <Dropdown
          className="max-w-xs"
          placeholder="Select"
          label="Apply Permissions"
          options={allRoles}
          value={selectedRoleToApply}
          onChange={setSelectedRoleToApply}
        />
        <PrimaryButton
          title="Apply"
          minWidth={120}
          disabled={!selectedRoleToApply}
          onClick={onApplyPermissionClicked}
        />
      </div>

      <div className="flex flex-row mt-6">
        {(isEditingRoleName || roleName.length === 0) && (
          <>
            <div className="max-w-xs mr-3">
              <Input
                className=""
                placeholder="Name"
                labelClassName="text-base font-bold mb-1"
                type="input"
                inputClassName=""
                value={roleName}
                onChange={setRoleName}
              />
            </div>
            <button
              type="button"
              className="inline"
              onClick={() => roleName.length > 0 && setEditingRoleName(false)}
            >
              <img src={checkIcon} alt="Set Name" className="-mt-1 w-5 h-5" />
            </button>
          </>
        )}
        {!isEditingRoleName && roleName.length > 0 && (
          <button
            type="button"
            className="text-xl font-medium tracking-widest text-gray-900 uppercase"
            onClick={() => setEditingRoleName(true)}
          >
            {roleName}{" "}
            <img
              src={editIcon}
              alt="Edit Name"
              className="inline -mt-1 w-5 h-5"
            />
          </button>
        )}
      </div>

      <RoleMembersAvatarList
        members={roleMembers}
        className="flex-1 mt-4 relative"
      />

      <div className="flex flex-row justify-end space-x-2 pb-4 mt-4 border-b-2 border-gray-100">
        <Checkbox
          label="Select All"
          labelClassName=""
          className="flex-grow"
          checked={selectAll}
          onChange={onSelectAllChecked}
        />
        <SecondaryButton
          title="Expand All"
          onClick={() =>
            setExpandedResources(Object.keys(allResourcePermissions))
          }
        />
        <SecondaryButton
          title="Collapse All"
          onClick={() => setExpandedResources([])}
        />
      </div>

      <RolePermissionsList
        allResourcePermissions={allResourcePermissions}
        expandedResources={expandedResources}
        onResourceExpansionToggled={onResourceExpansionToggled}
        isPermissionSelected={isPermissionSelected}
        onPermissionSelected={onPermissionSelected}
      />
    </div>
  );
}

EditRole.propTypes = {};

export default EditRole;
