import moment from "moment";

const getWeekBeforeAndAfter = (dateString) => {
  const date = moment(dateString);

  const weekBefore = date
    .clone()
    .subtract(1, "week")
    .format("YYYY-MM-DD")
    .split("T")[0];

  const weekAfter = date
    .clone()
    .add(1, "week")
    .format("YYYY-MM-DD")
    .split("T")[0];

  return {
    weekBefore,
    weekAfter,
  };
};

export default getWeekBeforeAndAfter;
