import React, { useCallback, useEffect, useMemo, useState } from "react";
import cntl from "cntl";
import { differenceBy } from "lodash";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import NewInviteeForm from "./NewInviteeForm";
import BaseButton from "../Buttons/BaseButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import { MEMBERS_PATH, PTO_CATEGORY } from "../../../constants";
import useSubscription from "../../../hooks/useSubscription";
import useRoles from "../../../hooks/useRoles";
import { formatNonMemberToContact, inviteContact } from "../../../helpers/User";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import useDirectReports from "../../../hooks/useDirectReports";
import { toastError } from "../Toast/Toast";
import { useUsers } from "../../../hooks/useUsers.new";
import useUserPost from "../../../hooks/useUserPost";
import getErrorUserApi from "../../../helpers/Error/getErrorUserApi";
import UserSeatsWarning from "../Warning/UserSeatsWarning/UserSeatsWarning";

const tertiaryButtonCN = cntl`
  text-brandGreen
  font-medium
  text-base
  rounded
  py-2
`;

const InviteMember = () => {
  const history = useHistory();
  const { data: usersData } = useUsers();
  const [allRoles] = useRoles();
  const [, directReportOptions] = useDirectReports();
  const [originalSubscription] = useSubscription();
  const { data: systemConfiguration } = useSystemConfiguration();
  const [contacts, setContacts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [invitees, setInvitees] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [availableSeats, setAvailableSeats] = useState();
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [memberEmails, setMemberEmails] = useState([]);
  const [hasValidationError, setHasValiidationError] = useState(false);
  const [states, setStates] = useState([]);
  const [ptoCategory, setPtoCategory] = useState();

  const users = useMemo(() => usersData?.users || [], [usersData?.users]);

  // Mutation hook to create new users
  const { mutateAsync: postUser } = useUserPost();

  useEffect(() => {
    setPtoCategory(systemConfiguration?.system?.pto?.category);
    if (systemConfiguration?.system?.pto?.locations?.length) {
      setStates(
        systemConfiguration?.system?.pto?.locations?.map((loc) => ({
          label: loc.location,
          value: loc.id,
        }))
      );
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(
        allRoles.map((role) => ({
          label: role.name,
          value: role.reference,
        }))
      );
    }
  }, [allRoles]);

  useEffect(() => {
    // also counting deleted users
    const hasAtleastOneError = invitees?.some((inv) => {
      if (
        inv?.hasEmailError ||
        !inv?.email?.value ||
        !inv?.name?.firstName?.trim() ||
        !inv?.name?.lastName?.trim() ||
        !inv?.phone?.value ||
        !inv?.role?.value
      ) {
        return true;
      }

      if (
        inv.isEmployee &&
        ptoCategory === PTO_CATEGORY.location &&
        !inv.stateOfEmployment?.value
      ) {
        return true;
      }

      if (inv.isEmployee && !inv.hireDate) {
        return true;
      }
      return false;
    });
    setHasValiidationError(hasAtleastOneError);
  }, [invitees, ptoCategory]);

  useEffect(() => {
    // also counting deleted users
    const emails = users
      ?.filter((user) => user?.kind === "member")
      .map((member) => member?.email);
    setMemberEmails(emails);
  }, [users]);

  useEffect(() => {
    if (users && originalSubscription) {
      const activeMemberCount = users?.filter(
        (user) =>
          user?.kind === "member" && user?.active && !user?.metadata?.deletedAt
      )?.length;
      setActiveUsersCount(activeMemberCount);
      const seats = originalSubscription?.userCount;
      setAvailableSeats(
        seats > activeMemberCount ? seats - activeMemberCount : 0
      );
    }
  }, [originalSubscription, users]);

  useEffect(() => {
    if (users?.length) {
      setContacts(
        differenceBy(
          users
            .filter(
              (user) => user.kind === "nonmember" && !user?.metadata?.deletedAt
            )
            .map((user) => formatNonMemberToContact(user, users)),
          invitees,
          "value"
        )
      );

      const existingCompanies = users
        .filter((user) => user.kind === "company" && !user?.metadata?.deletedAt)
        .map((user) => ({
          label: `${user?.company?.value}`,
          value: user?.reference,
        }));

      const newCompanies = invitees
        ?.filter(
          (invitee) =>
            invitee?.isNewCompany && !invitee?.wasCreatedWithAnotherContact
        )
        ?.map((newCompany) => ({
          label: newCompany.company?.value,
          value: uuidv4(),
          wasCreatedWithAnotherContact: true,
        }));
      setCompanyContacts([...newCompanies, ...existingCompanies]);
    }
  }, [users, invitees]);

  const onAddInvitee = useCallback(() => {
    setInvitees((prev) => [
      ...prev,
      {
        isExisting: true,
      },
    ]);
  }, []);

  const onRemoveAllInvitees = useCallback(() => {
    setInvitees([]);
  }, []);

  /**
   * Send invitations to given users to join Estatespace
   */
  const onSave = useCallback(async () => {
    setIsSaving(true);
    const companiesCreated = [];

    try {
      await Promise.all(
        invitees.map(async (invitee) => {
          await inviteContact(postUser, invitee, users, companiesCreated);
        })
      );

      history.push(MEMBERS_PATH);
    } catch (error) {
      const errorMessage = getErrorUserApi(error);
      toastError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  }, [postUser, history, invitees, users]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-end py-4 border-b-2">
        <PrimaryHeader>Invite members</PrimaryHeader>
        {availableSeats === 0 && <UserSeatsWarning />}
      </div>
      {(originalSubscription && availableSeats !== undefined && (
        <>
          <h2 className="py-4 text-sm">
            Select one or more people to invite to EstateSpace
          </h2>
          {!!invitees?.length && (
            <>
              {invitees?.map((invitee, index) => {
                const key = index;

                return (
                  <div key={key} className="pb-4">
                    <NewInviteeForm
                      directReportOptions={directReportOptions}
                      invitee={invitee}
                      contacts={contacts}
                      companyContacts={companyContacts}
                      inviteeIndex={index}
                      setInvitees={setInvitees}
                      memberEmails={memberEmails}
                      roles={roles}
                      states={states}
                      ptoCategory={ptoCategory}
                    />
                  </div>
                );
              })}
              {invitees?.length < availableSeats && (
                <div className="flex fex-row px-4">
                  <p className="text-gray-200 font-semibold text-xs pr-1">
                    {activeUsersCount}/{originalSubscription?.userCount}
                  </p>
                  <p className="text-gray-200 text-xs">user seats occupied</p>
                </div>
              )}
            </>
          )}
          {invitees?.length < availableSeats && (
            <BaseButton
              title={
                invitees?.length > 0 ? "+ Add Another Invitee" : "+ Add Invitee"
              }
              onClick={onAddInvitee}
              className={tertiaryButtonCN}
            />
          )}
          {availableSeats === 0 && (
            <BaseButton
              title="+ Add Invitee"
              onClick={onAddInvitee}
              className={tertiaryButtonCN}
              disabled
            />
          )}
          {!!invitees?.length && (
            <div className="flex justify-end pt-10">
              <TertiaryButton title="Cancel" onClick={onRemoveAllInvitees} />
              <PrimaryButton
                title="Invite"
                onClick={onSave}
                disabled={isSaving || hasValidationError}
              />
            </div>
          )}
        </>
      )) || <p className="font-semibold py-2">Loading...</p>}
    </div>
  );
};

InviteMember.propTypes = {};

export default InviteMember;
