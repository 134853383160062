/* eslint-disable import/prefer-default-export */
import moment from "moment";

export const maintenanceServiceData = (events, usersDict) => {
  const pastDue = [];
  const dueSoon = [];
  const upcoming = [];
  const noMaintenance = { pastDues: pastDue, dueSoons: dueSoon };

  if (events?.length === 0) return noMaintenance;
  events?.forEach((resource) => {
    if (resource.status === "scheduled") {
      const assetId = resource.association;
      const invitee = usersDict
        ? usersDict[resource?.invitees[0]?.split("/")[1]]
        : {};
      const servicer = {
        id: invitee?.id,
        name: invitee?.name,
        avatar: invitee?.avatar,
      };
      let stepsCompleted = 0;
      resource.steps.forEach((step) => {
        if (step.isCompleted) {
          stepsCompleted += 1;
        }
      });
      const maintenanceInfo = {
        assetId,
        id: resource.id,
        taskName: resource.name,
        dueDate: resource.instanceStartDate
          ? resource.instanceStartDate
          : resource.startDate,
        steps: {
          completed: stepsCompleted,
          total: resource.steps.length,
          nextStep:
            stepsCompleted !== resource.steps.length
              ? {
                  number: stepsCompleted + 1,
                  description:
                    resource.steps[stepsCompleted]?.description ||
                    resource.steps[stepsCompleted]?.name,
                }
              : null,
        },
        servicer,
        reminders: resource.reminders,
      };

      if (
        (resource.instanceStartDate &&
          moment(resource.instanceStartDate).diff(
            moment(new Date().toISOString()),
            "days"
          ) < 0) ||
        moment(resource.startDate).diff(
          moment(new Date().toISOString()),
          "days"
        ) < 0
      ) {
        maintenanceInfo.isPastDue = true;
        pastDue.push(maintenanceInfo);
      } else {
        maintenanceInfo.isPastDue = false;
        dueSoon.push(maintenanceInfo);
      }
    }
  });
  pastDue.sort((a, b) => moment(b.dueDate).diff(moment(a.dueDate)));
  dueSoon.sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate)));

  return {
    pastDues: pastDue,
    dueSoons: dueSoon,
    upcomingServices: upcoming,
    service: dueSoon[0],
    noMaintenance,
  };
};
