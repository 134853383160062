import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { useAppState } from "../../../state/appState";
import {
  REMOVE_POSITIONED_MODALS,
  SET_CALENDAR_DATA,
  TOGGLE_POSITIONED_POPUP,
  VIEW_MORE_POPUP,
} from "../../../constants";
import "./YearCalendar.css";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useModalState } from "../../../state/modalState";
import useMouseTracker from "../../../hooks/useMouseTracker";

export default function YearCalendar({ events }) {
  const [{ calendarData, calendarTimezone }] = useAppState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearDates, setYearDates] = useState([]);
  const [, dispatch] = useAppState();
  const { eventFilter } = useRelativeAssociations();
  const [, modalDispatch] = useModalState();

  useEffect(() => {
    const currentYear = new Date(calendarData).getFullYear();
    setSelectedYear(currentYear);
  }, [calendarData]);

  useEffect(() => {
    const array1 = [];

    for (let i = 0; i <= 11; i += 1) {
      const year = moment
        .tz(calendarData, calendarTimezone)
        .startOf("year")
        .startOf("day")
        .add(i, "months")
        .format();

      array1.push(year);
    }

    setYearDates(array1);
  }, [selectedYear, calendarData, calendarTimezone]);

  const { mousePosition } = useMouseTracker();

  function handleClickDay(dateSelected) {
    dispatch({
      type: SET_CALENDAR_DATA,
      data: dateSelected,
    });
    const newOffset = moment.tz(new Date(), calendarTimezone).utcOffset();
    const formattedDate = moment(dateSelected)
      .utcOffset(newOffset, true)
      .format();
    const { allDay, brief } = eventFilter(events?.[formattedDate]);

    const calendarDayEvents =
      allDay?.length > 0 || brief?.length > 0 ? [...allDay, ...brief] : [];

    dispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: mousePosition.x,
        y: mousePosition.y,
      },
      popupData: {
        calendarDayEvents,
        startDate: formattedDate,
        calendarTimezone,
        popupWidth: 239,
      },
      popupType: VIEW_MORE_POPUP,
    });

    modalDispatch({
      type: REMOVE_POSITIONED_MODALS,
    });
  }

  return (
    <div className="calendarContainer">
      {yearDates.map((year) => {
        return (
          <Calendar
            onClickDay={(dateSelected) => handleClickDay(dateSelected)}
            events={events}
            value={new Date(calendarData)}
            className="monthClass"
            tileClassName="dayClass"
            key={year}
            currentDate={new Date(calendarData)}
            activeStartDate={new Date(year)}
            prevLabel={null}
            prev2Label={null}
            nextLabel={null}
            next2Label={null}
            view="month"
          />
        );
      })}
    </div>
  );
}
