/**
 * Returns path and id based on association
 * Ex: association = Property/1111-2222-3333-4444
 * @param {String} association association reference
 * @returns {String[]} [properties, 1111-2222-3333-4444]
 */
const getIdPathResource = (association) => {
  if (!association) return [undefined];

  const associationsPaths = {
    Asset: "assets",
    Property: "properties",
    Project: "projects",
    Ticket: "tickets",
    Sop: "sops",
    Submittal: "submittals",
    User: "contacts",
    Report: "reports",
    Space: "space",
    Workflow: "workflow",
    Timeoff: "timesheet",
    Timesheet: "timesheet",
  };

  const [resource, id] = association.split("/");
  const path = associationsPaths[resource];

  return { path, id, resource };
};

/**
 * Return single path for a resource with given association
 * Ex: Property/1111-2222-3333-4444
 * @param {String} association - association reference
 * @param {Object} currentUser - (OPTIONAL) current authenticated user
 * @param {Object} pageState - (OPTIONAL)
 * @param {Object} setPersistentPageItem - (OPTIONAL)
 * @param {Object} data - (OPTIONAL)
 * @returns path: /properties/1111-2222-3333-4444
 */
export const getSingleResourcePath = (
  association,
  currentUser,
  pageState,
  setPersistentPageItem,
  data
) => {
  const { path, id, resource } = getIdPathResource(association);

  if (!path) return undefined;

  // TODO: If adding zero is still need for contacts path, use query paramaters instead.
  // Workaround in the meantime
  if (path === "contacts") {
    return `/${path}/${id}/0`;
  }

  if (path === "timesheet") {
    // Switch for cases where user will navigate to other tabs in timesheets
    switch (resource) {
      case "Timeoff":
        setPersistentPageItem("timesheet", {
          ...pageState.timesheet,
          tab: "user-timeoff",
          userId: currentUser?.id,
        });
        break;
      case "Timesheet":
        setPersistentPageItem("timesheet", {
          ...pageState.timesheet,
          periodEnd: data?.periodEnd,
          periodStart: data?.periodStart,
          tab: "my-timesheet",
          userId: data?.userRef.split("/")[1],
        });
        break;
      default:
        break;
    }
    return `/${path}`;
  }

  return `/${path}/${id}`;
};

/**
 * Return single resource nested path with given association and resource id
 * Ex: Property/1111-2222-3333-4444, Ticket/6666-7777-8888-9999
 * @param {String} association association reference
 * @param {String} childResourceReference resource reference
 * @returns path: /properties/1111-2222-3333-4444/tickets/6666-7777-8888-9999
 */
export const getNestedResourcePath = (association, childResourceReference) => {
  const { path: parentPath, id: parentId } = getIdPathResource(association);
  const { path: childPath, id: childId } = getIdPathResource(
    childResourceReference
  );

  return `/${parentPath}/${parentId}/${childPath}/${childId}`;
};

/**
 * Feature flag to check if user is browsing in prod or staging
 * @param {Object} window represent global broser window
 * @returns true if absolute path is for prod or staging
 */
export const shouldHideNavigationInStagingAndProd = (window) => {
  return (
    window.location.host.includes("console.estatespace") ||
    window.location.host.includes("console.es-staging")
  );
};

/**
 * useLocation (react-router) state parser
 * @param {Object} state string or object
 * @returns state object
 */
export const getLocationState = (state) => {
  if (state) {
    if (typeof state === "string") {
      return JSON.parse(state);
    }
    return state;
  }
  return {};
};
