import React, { useMemo } from "react";
import useViewTodoModalData from "./useViewTodoModalData";
import memberIcon from "../../../assets/images/memberIconWhite.svg";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ViewTodoModalContent from "./ViewTodoModalContent";
import ModalWrapper from "../ModalWrapper";
import EditIcon from "../../EditIcon/EditIcon";
import TrashButton from "../../Buttons/TrashButton";

const ViewTodoModal = ({ modalData }) => {
  const {
    todo,
    members,
    completeTodo,
    deleteTodo,
    handlePatch,
    completed,
    userCreated,
    editedTodo,
    formDispatch,
    handleSave,
    closeAllPopups,
    handleEditTodo,
  } = useViewTodoModalData(modalData);

  const modalTitle = useMemo(() => {
    if (modalData?.edit) {
      if (!modalData?.item) {
        return "Add To-Do";
      }
      if (modalData?.item) {
        return "Edit To-Do";
      }
    }
    if (modalData?.item?.members?.length > 1) {
      return (
        <div className="flex gap-3 items-center justify-center">
          <img src={memberIcon} className="w-5 h-5" alt="member-icon" />
          <p className="text-white">Group To-Do</p>
        </div>
      );
    }

    if (members?.length === 1) {
      return "My To-Do";
    }

    return "";
  }, [members, modalData?.edit, modalData?.item]);

  return (
    <ModalWrapper
      modalData={modalData}
      width="454px"
      alert
      float
      resourceName="Todo"
      onRequestClose={closeAllPopups}
      title={modalTitle}
      buttons={({ closeModal }) => (
        <div className="flex flex-row items-center px-4 gap-2">
          {!completed && !modalData?.edit && (
            <PrimaryButton
              saveButton
              saveButtonTitle="Mark as Complete"
              onClick={() => {
                completeTodo(todo);
                closeAllPopups();
                closeModal();
              }}
            />
          )}

          {modalData?.edit && (
            <div className="flex">
              <PrimaryButton
                saveButton
                onClick={() => {
                  handleSave();
                  closeModal();
                }}
              />
            </div>
          )}

          {
            // `userCreated` means owner
            userCreated && (
              <div className="flex items-center">
                <TrashButton
                  className="-ml-2"
                  onClick={() => {
                    closeAllPopups();
                    closeModal();
                    deleteTodo(todo);
                  }}
                />

                {
                  // Shows edit icon if modal is in view mode
                  !modalData?.edit && (
                    <button
                      type="button"
                      aria-label="edit todo button"
                      onClick={() => {
                        closeModal();
                        handleEditTodo();
                      }}
                    >
                      <EditIcon />
                    </button>
                  )
                }
              </div>
            )
          }
        </div>
      )}
    >
      <ViewTodoModalContent
        todo={editedTodo}
        dispatch={formDispatch}
        edit={modalData?.edit}
        members={members}
        patch={handlePatch}
      />
    </ModalWrapper>
  );
};

export default ViewTodoModal;
