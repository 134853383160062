import moment from "moment";
import { EventAPI } from "@griffingroupglobal/eslib-api";
import {
  ALL_EVENT_INSTANCES,
  SET_USER_EVENTS,
  SINGLE_EVENT_INSTANCE,
  MOMENT_UTC_ES_FORMAT,
  UNFORMATTED_EVENTS_ACTIONS,
  MOMENT_CALENDAR_FORMAT,
} from "../constants";
import { isFullDay } from "../helpers/Calendar";
import returnRecurrenceDates from "../helpers/returnRecurrenceDates";
import { useAppState } from "../state/appState";
import updateCalendarDay from "../helpers/Calendar/updateCalendarDay";
import handleAddMultiWeekItem from "../helpers/Calendar/handleMultiWeekEvent";
import getDaySpanCount from "../helpers/Calendar/getDaySpanCount";
import handleDeleteWrappingDay from "../helpers/Calendar/handleDeleteWrappingDay";
import updateUnformattedEvents from "../helpers/Calendar/updateUnformattedEvents";
import getEndDateForInstance from "../helpers/Calendar/getEndDateForInstance";

const useEsEvents = () => {
  const [{ userEvents, unformattedEvents }, appStateDispatch] = useAppState();

  const editEventInDictionaries = (event, originalEvent) => {
    const isFullDayEvent = isFullDay(
      event.startDate,
      event.endDate,
      event.allDay
    );
    const editedStartOfDay = moment(event?.startDate).startOf("day").format();
    const originalStartOfDay = moment(originalEvent?.startDate)
      .startOf("day")
      .format();
    const editedEndOfDay = moment(event?.endDate).endOf("day").format();
    const originalEndOfDay = moment(originalEvent?.endDate)
      .endOf("day")
      .format();

    let newUserEvents = JSON.parse(JSON.stringify(userEvents));

    if (!newUserEvents[editedStartOfDay]) {
      newUserEvents[editedStartOfDay] = { allDay: [], brief: [] };
    }

    // Remove the original event from its previous day and location, if the date has changed
    if (
      originalStartOfDay !== editedStartOfDay ||
      originalEndOfDay !== editedEndOfDay ||
      isFullDayEvent !==
        isFullDay(
          originalEvent.startDate,
          originalEvent.endDate,
          originalEvent.allDay
        )
    ) {
      const itemFoundInBrief = newUserEvents[originalStartOfDay].brief.find(
        (item) => item.id === originalEvent.id
      );

      const itemFoundInAllDay = newUserEvents[originalStartOfDay].allDay.find(
        (item) => item.id === originalEvent.id
      );

      if (itemFoundInBrief) {
        const index = newUserEvents[originalStartOfDay].brief.findIndex(
          (item) => item.id === originalEvent.id
        );

        const isWrapping = moment(originalEvent.endDate).isAfter(
          moment(originalEvent.startDate).endOf("day")
        );

        newUserEvents[originalStartOfDay].brief.splice(index, 1);

        if (isWrapping) {
          newUserEvents = handleDeleteWrappingDay(
            { ...newUserEvents },
            originalEvent
          );
        }
      }

      if (itemFoundInAllDay) {
        const index = newUserEvents[originalStartOfDay].allDay.findIndex(
          (item) => item.id === originalEvent.id
        );

        newUserEvents[originalStartOfDay].allDay.splice(index, 1);

        const isWrapping = moment(originalEvent.endDate).isAfter(
          moment(originalEvent.startDate).endOf("day")
        );

        if (isWrapping) {
          newUserEvents = handleDeleteWrappingDay(
            { ...newUserEvents },
            originalEvent
          );
        }
      }
    }

    // Determine the new location for the updated event
    const newLocation = isFullDayEvent ? "allDay" : "brief";

    // Add or update the event in its new location and day
    const eventIndex = newUserEvents[editedStartOfDay][newLocation].findIndex(
      (e) => e.id === event.id
    );

    if (eventIndex !== -1) {
      // Update the existing event
      const isAllDay = isFullDay(event.startDate, event.endDate, event.allDay);
      // const itemWraps = moment(event.endDate).isAfter(
      //   moment(event.startDate).endOf("day")
      // );

      const endOfWeek = moment(event.startDate).endOf("week");
      const itemWraps = moment(event.endDate).isAfter(endOfWeek);

      if (isAllDay && itemWraps) {
        newUserEvents = handleDeleteWrappingDay(
          { ...newUserEvents },
          originalEvent
        );

        newUserEvents = handleAddMultiWeekItem({
          event,
          isFullDayEvent,
          startDate: event.startDate,
          endDate: event.endDate,
          userEvents: newUserEvents,
          userEventUpdates: newUserEvents,
        });

        const daySpan = getDaySpanCount({
          isMultiWeek: true,
          item: event,
        });

        newUserEvents[editedStartOfDay][newLocation][eventIndex] = {
          ...event,
          daySpan,
        };
      } else {
        newUserEvents[editedStartOfDay][newLocation][eventIndex] = event;
      }
    } else {
      const itemWraps = moment(event.endDate).isAfter(
        moment(event.startDate).endOf("day")
      );

      newUserEvents[editedStartOfDay][newLocation].push(event);

      if (itemWraps) {
        newUserEvents = handleAddMultiWeekItem({
          event,
          isFullDayEvent,
          startDate: event.startDate,
          endDate: event.endDate,
          userEvents: newUserEvents,
          userEventUpdates: newUserEvents,
        });
      }
    }
    // Dispatch the updated user events dictionary
    appStateDispatch({
      type: SET_USER_EVENTS,
      userEvents: newUserEvents,
    });

    updateUnformattedEvents({
      appStateDispatch,
      unformattedEvents,
      item: event,
      action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE,
    });
  };

  const deleteEventFromDictionaries = async (event, instanceStartDate) => {
    try {
      const startOfDay = moment(event?.startDate)
        .local()
        .startOf("day")
        .format();

      const newEventBrief = userEvents[startOfDay].brief.reduce((acc, item) => {
        if (
          item.id === event.id &&
          item.instanceStartDate === event.instanceStartDate
        ) {
          return acc;
        }
        if (item.id === event.id) {
          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            {
              ...item,
              metadata: event.metadata,
            },
          ];
          return acc;
        }
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, item];
        return acc;
      }, []);

      const newEventAllDay = userEvents[startOfDay].allDay.reduce(
        (acc, item) => {
          if (
            item.id === event.id &&
            item.instanceStartDate === event.instanceStartDate
          ) {
            return acc;
          }
          if (item.id === event.id) {
            // eslint-disable-next-line no-param-reassign
            acc = [
              ...acc,
              {
                ...item,
                metadata: event.metadata,
              },
            ];
            return acc;
          }
          // eslint-disable-next-line no-param-reassign
          acc = [...acc, item];
          return acc;
        },
        []
      );

      const newEventAllDayCount = newEventAllDay.length;
      const newEvent = {
        ...userEvents[startOfDay],
        brief: newEventBrief,
        allDay: newEventAllDay,
        allDayCount: newEventAllDayCount,
      };

      const newUserEvents = {
        ...userEvents,
        [startOfDay]: newEvent,
      };

      const updatedUserEvents = handleDeleteWrappingDay(newUserEvents, event);

      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: updatedUserEvents,
      });

      if (instanceStartDate) {
        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: event,
          instanceStartDate: event.instanceStartDate,
          action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE_INSTANCE,
        });
      } else {
        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: event,
          action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const addEventToDictionaries = async (event) => {
    // decipher whether this is a full day event or not
    const isFullDayEvent = isFullDay(
      event.startDate,
      event.endDate,
      event.allDay
    );

    if (!event.recurrence) {
      // get the start of the day for calendar day
      const startOfDay = moment(event?.startDate)
        .local()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");
      // if the calendar date is not available make sure there is a dictionary for it
      const calendarDay = userEvents[startOfDay] || {};
      // get the start and end date for the event formatted to es standars
      const startDate = moment(event.startDate).format(MOMENT_UTC_ES_FORMAT);
      const endDate = moment(event.endDate).format(MOMENT_UTC_ES_FORMAT);

      // determine if the event carries over to the next week
      const isMultiWeekEvent = moment(startDate).isBefore(
        moment(endDate).startOf("week")
      );

      // update the calendar day with the new event
      const updatedDaySpan = getDaySpanCount({
        isMultiWeek: isMultiWeekEvent,
        item: event,
      });
      // update the calendar day with the new event
      const updatedCalendarDay = updateCalendarDay(
        calendarDay,
        {
          ...event,
          daySpan: updatedDaySpan,
        },
        isFullDayEvent
      );
      // make a copy of userEvents to add new event to
      const newUserEventsDict = {
        ...userEvents,
      };
      // create a new dictionary with the new calendar day
      newUserEventsDict[startOfDay] = updatedCalendarDay;

      // add the event to the start of each following week
      if (isMultiWeekEvent) {
        const updatesForMultiWeekEvent = handleAddMultiWeekItem({
          event,
          isFullDayEvent,
          startDate,
          endDate,
          userEvents,
          userEventUpdates: newUserEventsDict,
        });
        Object.assign(newUserEventsDict, updatesForMultiWeekEvent);
      }
      // update the app state with the new dictionary
      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: newUserEventsDict,
      });

      updateUnformattedEvents({
        appStateDispatch,
        unformattedEvents,
        item: event,
        action: UNFORMATTED_EVENTS_ACTIONS.ADD_SINGLE,
      });
    } else {
      const { recurrence } = event;
      const userEventUpdates = { ...userEvents };
      const recurrenceDates = returnRecurrenceDates({ recurrence });
      const eventsArrUpdates = [];

      recurrenceDates.forEach((date) => {
        const startOfDay = moment(date)
          .startOf("day")
          .format(MOMENT_CALENDAR_FORMAT);
        const calendarDay = userEventUpdates[startOfDay] || {};

        const newEndDate = getEndDateForInstance(event, date);

        const recurringEvent = {
          ...event,
          instanceStartDate: moment(date).utc().format(MOMENT_UTC_ES_FORMAT),
          startDate: moment(date).utc().format(MOMENT_UTC_ES_FORMAT),
          endDate: moment(newEndDate).utc().format(MOMENT_UTC_ES_FORMAT),
        };

        eventsArrUpdates.push(recurringEvent);

        const isMultiWeekEvent = moment(newEndDate).isAfter(
          moment(date).endOf("week")
        );

        if (isMultiWeekEvent && isFullDayEvent) {
          const updatesForMultiWeekEvent = handleAddMultiWeekItem({
            event: recurringEvent,
            isFullDayEvent,
            startDate: date,
            endDate: newEndDate,
            userEvents,
            userEventUpdates,
          });

          Object.assign(userEventUpdates, updatesForMultiWeekEvent);
        }

        recurringEvent.daySpan = getDaySpanCount({
          isMultiWeek: isMultiWeekEvent && isFullDayEvent,
          item: recurringEvent,
        });

        userEventUpdates[startOfDay] = updateCalendarDay(
          calendarDay,
          recurringEvent,
          isFullDayEvent
        );
      });

      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: userEventUpdates,
      });

      updateUnformattedEvents({
        appStateDispatch,
        unformattedEvents,
        items: eventsArrUpdates,
        action: UNFORMATTED_EVENTS_ACTIONS.ADD_RECURRING,
      });
    }
  };

  const deleteEventAndAllRecurrences = async (event) => {
    // Create a delete request string for the event and its instances
    const deleteRequestString = `${event.id}/$${ALL_EVENT_INSTANCES}`;

    try {
      // Send a delete request to remove the event and its instances
      await EventAPI.delete(deleteRequestString);
      // Clone the userEvents object to avoid modifying the original
      const userEventsUpdates = { ...userEvents };
      // Iterate over each day in userEventsUpdates
      Object.keys(userEventsUpdates).forEach((dayEvent) => {
        // Filter out the event from the brief array for the current day
        const newBrief = userEventsUpdates[dayEvent].brief.filter(
          (item) => item.id !== event.id
        );
        // Filter out the event from the allDay array for the current day
        const newAllDay = userEventsUpdates[dayEvent].allDay.filter(
          (item) => item.id !== event.id
        );
        // Calculate the count of allDay events after filtering
        const newAllDayCount = newAllDay.length;
        // Create a new event object with updated data for the current day
        const newEvent = {
          ...userEventsUpdates[dayEvent],
          brief: newBrief,
          allDay: newAllDay,
          allDayCount: newAllDayCount,
        };
        // Update the userEventsUpdates object with the new event data for the current day
        userEventsUpdates[dayEvent] = newEvent;
      });

      // Dispatch an action to update the application state with the modified userEventsUpdates
      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: { ...userEventsUpdates },
      });

      updateUnformattedEvents({
        appStateDispatch,
        unformattedEvents,
        item: event,
        action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_RECURRING,
      });
    } catch (e) {
      // Handle any errors that may occur during the process
      throw new Error(e);
    }
  };

  const deleteSingleEventRecurrence = async (event, timestampIso) => {
    // Determine the start of the day based on the provided timestamp
    const startOfDay = moment(timestampIso).startOf("day").format();

    // Construct the request string for the API to delete a single event instance
    const deleteRequestString = `${event?.id}/$${SINGLE_EVENT_INSTANCE}`;

    // Parse the timestamp into a moment object with time zone information
    const dateToParse = moment.parseZone(timestampIso);

    // Convert the date to UTC format, required for the API call
    const dateFormattedToUTC = dateToParse
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Create an object containing the parameters for the delete request
    const deleteParamsObject = {
      params: `instanceStartDate=${dateFormattedToUTC}`,
    };

    try {
      // Perform the API call to delete the specific event instance
      const { data: deletedEvent } = await EventAPI.delete(
        deleteRequestString,
        deleteParamsObject
      );
      deletedEvent.instanceStartDate = event.instanceStartDate;

      // Find the event in the allDay array for the specified day
      const matchingFromAllDay = userEvents[startOfDay].allDay.find(
        (item) => item.id === deletedEvent.id
      );

      // Find the event in the brief array for the specified day
      const matchingFromBrief = userEvents[startOfDay].brief.find(
        (item) => item.id === deletedEvent.id
      );

      // Flags to check if the event was found in the respective arrays
      const foundInAllDay = matchingFromAllDay !== undefined;
      const foundInBrief = matchingFromBrief !== undefined;

      // If the event was found in the allDay array, delete it using a helper function
      if (foundInAllDay) {
        deleteEventFromDictionaries(deletedEvent, dateFormattedToUTC);
      }

      // If the event was found in the brief array, delete it using the same helper function
      if (foundInBrief) {
        deleteEventFromDictionaries(deletedEvent, dateFormattedToUTC);
      }
    } catch (error) {
      // Handle any errors that may occur during the deletion process
      throw new Error(error);
    }
  };

  const deleteFutureEventsFromDictionaries = async (event, timestampIso) => {
    const userEventUpdates = { ...userEvents };

    const itemId = event.id;

    Object.keys(userEventUpdates).forEach((day) => {
      const dayToReview = userEventUpdates[day];
      const isSameOrAfter = moment(day).isSameOrAfter(timestampIso);

      if (!dayToReview) return;

      let newBrief = [];
      let newAllDay = [];

      const containedInBrief = dayToReview.brief.find(
        (item) => item.id === itemId
      );

      const containedInAllDay = dayToReview.allDay.find(
        (item) => item.id === itemId
      );

      if (!containedInBrief && !containedInAllDay) return;

      if (containedInBrief && isSameOrAfter) {
        newBrief = dayToReview.brief.filter((item) => item.id !== itemId);
      } else if (containedInBrief && !isSameOrAfter) {
        newBrief = dayToReview.brief.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              metadata: event.metadata,
              recurrence: event.recurrence,
            };
          }
          return item;
        });
      } else {
        newBrief = dayToReview.brief;
      }

      if (containedInAllDay && isSameOrAfter) {
        newAllDay = dayToReview.allDay.filter((item) => item.id !== itemId);
      } else if (containedInAllDay && !isSameOrAfter) {
        newAllDay = dayToReview.allDay.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              metadata: event.metadata,
              recurrence: event.recurrence,
            };
          }
          return item;
        });
      } else {
        newAllDay = dayToReview.allDay;
      }

      const newAllDayCount = newAllDay.length;

      const newEvent = {
        ...day,
        brief: newBrief,
        allDay: newAllDay,
        allDayCount: newAllDayCount,
      };

      userEventUpdates[day] = newEvent;
    });

    appStateDispatch({
      type: SET_USER_EVENTS,
      userEvents: userEventUpdates,
    });

    updateUnformattedEvents({
      appStateDispatch,
      unformattedEvents,
      item: event, // pre split event
      instanceStartDate: timestampIso, // date for the split
      action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_FUTURE_INSTANCES,
    });
  };

  return {
    addEventToDictionaries,
    editEventInDictionaries,
    deleteEventAndAllRecurrences,
    deleteSingleEventRecurrence,
    deleteEventFromDictionaries,
    deleteFutureEventsFromDictionaries,
  };
};

export default useEsEvents;
