/* eslint-disable no-param-reassign */
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useModalState } from "../../../../state/modalState";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  VIEW_TODO_MODAL,
} from "../../../../constants";
import useTodosDelete from "../../../../hooks/useTodosDelete";
import TodoDeletePrompt from "./TodoDeletePrompt";

export default ({ todo, complete }) => {
  const { data: currentUser } = useCurrentUser();
  const { mutate: deleteTodo } = useTodosDelete();
  const [, modalDispatch] = useModalState();
  const completed = useMemo(() => {
    return todo?.members?.reduce(
      (list, item) => {
        if (item?.checked) {
          list[0] += 1;
        }
        list[1] += 1;
        return list;
      },
      [0, 0]
    );
  }, [todo?.members]);

  const checked = useMemo(() => {
    const self = todo?.members?.find(
      (item) => item?.user === currentUser?.reference
    );
    return self?.checked;
  }, [currentUser?.reference, todo?.members]);

  const handleComplete = useMemo(() => {
    if (!checked && todo?.id) {
      return () => complete();
    }
    if (checked && todo?.id) {
      return () => complete();
    }
    return () => {};
  }, [checked, complete, todo?.id]);

  const userCreated = useMemo(() => {
    return todo?.metadata?.createdBy === currentUser?.reference;
  }, [currentUser?.reference, todo?.metadata?.createdBy]);

  const viewTodo = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: todo.id ?? uuidv4() },
      modalData: { item: todo },
      modalType: VIEW_TODO_MODAL,
    });
  };

  const editTodo = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: todo.id ?? uuidv4() },
      modalData: { item: todo, edit: true },
      modalType: VIEW_TODO_MODAL,
    });
  };

  const handleDeleteTodo = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: `delete-todo-${todo.id}` ?? uuidv4() },
      modalData: {
        item: {
          prompt: <TodoDeletePrompt />,
          onConfirm: () => deleteTodo(todo),
          confirm: "Delete",
          cancel: "Cancel",
          title: "Delete To-Do",
        },
      },
      modalType: CONFIRM_MODAL,
    });
  };

  const groupTodo = useMemo(() => {
    const members = todo?.members?.filter(
      (item) => item?.user !== currentUser?.reference
    );
    if (members?.length > 0) return true;

    return false;
  }, [currentUser?.reference, todo?.members]);

  return {
    handleComplete,
    checked,
    userCreated,
    completed,
    viewTodo,
    editTodo,
    handleDeleteTodo,
    groupTodo,
  };
};
