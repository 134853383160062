import { TaskAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "react-query";
import { toastError, toastMessage } from "../../../../Toast/Toast";
import toggleCompletionStatus from "../../../../../../helpers/Text/toggleCompletionStatus";
import getUpdatedTaskSteps from "../../../../../../helpers/Utilities/getUpdatedTaskSteps";
import { useAppState } from "../../../../../../state/appState";
import { taskKeys } from "../../../../../../config/reactQuery/queryKeyFactory";
import getUpdatedTaskSopSteps from "../../../../../../helpers/Utilities/getUpdatedTaskSopSteps";
import editSingleItem from "../../../../../../helpers/Task/editSingleTask";
import useEsTasks from "../../../../../../hooks/useEsTasks";

const useTaskStepTextData = (sop, currentTask, setCurrentTask) => {
  const [
    { userEvents, tasks, tasksDict, unformattedEvents },
    appStateDispatch,
  ] = useAppState();
  // custom hooks
  const { editTaskInDictionary } = useEsTasks();
  const queryClient = useQueryClient();
  const [completeStep, setCompleteStep] = useState("No");

  const getInitialCheckedState = useCallback(() => {
    return sop?.stepData?.isCompleted ? "Yes" : "No";
  }, [sop?.stepData?.isCompleted]);

  useEffect(() => {
    // set initial step UI complete state and save it
    const completeState = getInitialCheckedState();
    setCompleteStep(completeState);
  }, [getInitialCheckedState, sop.stepData.isCompleted]);

  const onCancelCleanExitFn = useCallback(() => {
    // on pupup cancel reset the checkbox state back to initial
    const completeState = getInitialCheckedState();
    setCompleteStep(completeState);
  }, [getInitialCheckedState]);

  const handleCompleteStepCheck = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          // patch no-recurrence task
          const taskPatchResponse = await TaskAPI.patch(
            currentTask?.id,
            updatedTask,
            currentTask
          );

          // Update task in the local dictionary with the response
          editTaskInDictionary(taskPatchResponse.data, currentTask);

          setCurrentTask({
            ...taskPatchResponse.data,
          });

          toastMessage("Task updated successfully");
        } else {
          // patch single instance of recurring task
          const updatedTaskItem = await editSingleItem({
            currentTask,
            updatedTask,
            userEvents,
            tasks,
            tasksDict,
            appStateDispatch,
            unformattedEvents,
          });
          setCurrentTask({
            ...updatedTaskItem,
          });
        }

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        // restore checkbox UI state
        onCancelCleanExitFn();
        toastError("This task could not be updated");
      }
    },
    [
      currentTask,
      queryClient,
      setCurrentTask,
      userEvents,
      tasks,
      tasksDict,
      appStateDispatch,
      onCancelCleanExitFn,
      unformattedEvents,
      editTaskInDictionary,
    ]
  );

  // handler for marking task steps
  const handleStepCheck = useCallback(
    // set the checkbox state both in the UI and API
    (editedStep) => {
      setCompleteStep((prev) => {
        const newCompletionStatus = toggleCompletionStatus(prev);
        const updatedTaskSteps = getUpdatedTaskSteps(
          currentTask.steps,
          editedStep,
          newCompletionStatus
        );

        const updatedTask = { ...currentTask, steps: updatedTaskSteps };

        handleCompleteStepCheck(updatedTask);
        return newCompletionStatus;
      });
    },
    [currentTask, handleCompleteStepCheck]
  );

  // handler for marking individual sop steps
  const handleSopStepCheck = useCallback(
    // set the checkbox state both in the UI and API
    (sopId, sopStep, totalSteps) => {
      const updatedTaskSteps = getUpdatedTaskSopSteps(
        currentTask.steps,
        sopId,
        sopStep,
        totalSteps
      );

      const updatedTask = { ...currentTask, steps: updatedTaskSteps };

      handleCompleteStepCheck(updatedTask);
    },
    [currentTask, handleCompleteStepCheck]
  );

  return {
    handleCompleteStepCheck,
    onCancelCleanExitFn,
    handleStepCheck,
    handleSopStepCheck,
    completeStep,
  };
};

export default useTaskStepTextData;
