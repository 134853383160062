import cntl from "cntl";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import taskIcon from "../../assets/images/taskIcon.svg";
import timezoneIcon from "../../assets/images/timezone.svg";
import PrimaryButton from "../Buttons/PrimaryButton";
import Chevron from "../Chevron/Chevron";
import Dropdown from "../Dropdown/Dropdown";
import TaskPopup from "../Task/TaskPopup";
import TimezonePopup from "./Timezone/TimezonePopup";

const wrapperCN = cntl`
  flex 
  w-full
  justify-between
  items-center
`;
const datePickerCN = cntl`
  flex
  gap-5
  items-center
  rounded-md
  self-center
  ml-4
`;
const CalendarControl = ({
  date,
  showNext,
  showPrev,
  showToday,
  saving,
  loading,
  views,
  view,
  onChange,
  tasks,
  tasksOpen,
  setTasksOpen,
  timezoneOpen,
  setTimezoneOpen,
  calendarTimezone,
}) => {
  const keyDownShowNext = (event) => {
    if (event.key === "Enter") {
      showNext();
    }
  };
  const keyDownShowPrev = (event) => {
    if (event.key === "Enter") {
      showPrev();
    }
  };

  const display = (option) => {
    let dates = moment
      .tz(date, calendarTimezone)
      .startOf("week")
      .subtract(1, "days");
    dates = Array(7)
      .fill(0)
      .map(() => dates.add(1, "day").clone());
    switch (option) {
      case "week":
        return (
          <h3 className="text-xl whitespace-nowrap w-60 mx-2">{`${moment
            .tz(dates[0], calendarTimezone)
            .format("MMM D, YYYY")} - ${moment
            .tz(dates[6], calendarTimezone)
            .format("MMM D, YYYY")}`}</h3>
        );
      case "year":
        return (
          <h3 className="text-xl w-60 mx-2">{`${moment
            .tz(dates[6], calendarTimezone)
            .format("YYYY")}`}</h3>
        );
      case "day":
        return (
          <h3 className="text-xl mx-2">{`${moment
            .tz(date, calendarTimezone)
            .format("MMM D, YYYY")}`}</h3>
        );
      default:
        return (
          <h3 className="text-xl mx-2">{`${moment
            .tz(date, calendarTimezone)
            .format("MMMM YYYY")}`}</h3>
        );
    }
  };
  /**
   * @TODO - Change Chevron color & weight
   */
  return (
    <div className={wrapperCN}>
      <div className="flex">
        <Dropdown
          value={view}
          options={views}
          disableSort
          onChange={(val) => onChange(val)}
          disableClear
        />
        <PrimaryButton
          buttonTitle="Today"
          addButton
          onClick={!saving ? showToday : undefined}
          className="ml-8"
          disabled={loading || saving}
        />
        <div className={datePickerCN}>
          <div
            onClick={!saving && !loading ? showPrev : undefined}
            role="button"
            onKeyDown={!saving && !loading ? keyDownShowPrev : undefined}
            tabIndex="0"
            className="flex items-center h-full"
            aria-label="Previous button"
          >
            <Chevron color="#1DC29d" className="h-3 transform rotate-180" />
          </div>

          <div
            className={`select-none cursor-pointer flex items-center h-full ${
              !showNext ? "bg-gray-100" : ""
            }`}
            onClick={!saving && !loading ? showNext : undefined}
            role="button"
            onKeyDown={!saving && !loading ? keyDownShowNext : undefined}
            tabIndex="0"
            aria-label="Next button"
          >
            <Chevron className="h-3" color="#1DC29D" />
          </div>
          <div
            id="calendar-control-display"
            className="select-none py-1 flex h-full items-center justify-center font-semibold"
          >
            {!loading && date ? (
              display(view.value)
            ) : (
              <h3 className="w-60 mx-2">Loading...</h3>
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <button
          className="font-semibold flex gap-2 items-center mr-4"
          style={{ color: "#027D61" }}
          onClick={() => setTimezoneOpen((prev) => !prev)}
          type="button"
        >
          <img src={timezoneIcon} alt="timezone-icon" />
          Timezone
          {calendarTimezone && `: ${moment.tz(calendarTimezone).zoneAbbr()}`}
        </button>

        <button
          className="font-semibold flex gap-2 items-center"
          style={{ color: "#027D61" }}
          onClick={() => setTasksOpen((prev) => !prev)}
          type="button"
        >
          <img src={taskIcon} alt="task-icon" />
          Tasks
        </button>
      </div>

      {tasksOpen && (
        <TaskPopup tasks={tasks} close={() => setTasksOpen(false)} />
      )}
      {timezoneOpen && <TimezonePopup close={() => setTimezoneOpen(false)} />}
    </div>
  );
};
CalendarControl.propTypes = {
  /**
   * Iso String of date within month being viewed
   */
  date: PropTypes.string,
  /**
   * Increments month view
   */
  showNext: PropTypes.func,
  /**
   * Decrecrements month view
   */
  showPrev: PropTypes.func,
  /**
   * Jumps to now in time
   */
  showToday: PropTypes.func,
  /**
   * Saving in progress
   */
  saving: PropTypes.bool,
  /**
   * Loading default: false
   */
  loading: PropTypes.bool,
  /**
   * Dropdown View Options
   */
  views: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Current view
   */
  view: PropTypes.shape({
    value: PropTypes.string,
  }),
  /**
   * On changed view
   */
  onChange: PropTypes.func,
  /**
   * Tasks
   */
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Tasks open
   */
  tasksOpen: PropTypes.bool,
  /**
   * Set tasks open
   */
  setTasksOpen: PropTypes.func,
  /**
   * Timezone selector open
   */
  timezoneOpen: PropTypes.bool,
  /**
   * Set Timezone selector open
   */
  setTimezoneOpen: PropTypes.func,
  // selected timezone name
  calendarTimezone: PropTypes.string,
};
CalendarControl.defaultProps = {
  date: undefined,
  showNext: undefined,
  showPrev: undefined,
  showToday: undefined,
  saving: false,
  loading: false,
  views: undefined,
  view: { label: "Month", value: "month" },
  onChange: undefined,
  tasks: [],
  tasksOpen: false,
  setTasksOpen: undefined,
  timezoneOpen: false,
  setTimezoneOpen: undefined,
  calendarTimezone: undefined,
};
export default CalendarControl;
