import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import ProjectAssetList from "../Overviews/Asset/ProjectAssetList";
import DetailViewBanner from "../../stories/Components/DetailViewBanner/DetailViewBanner";
import { resolvePrimaryImage } from "../../helpers/File";
import useProject from "../../hooks/useProject";
import useAssets from "../../hooks/useAssets";
import { maintenanceServiceData } from "../../helpers/MaintenanceUtilities";
import SiteHeaderAlert from "../../stories/Components/SiteHeader/SiteHeaderAlert";

const ProjectAssets = ({ currentUser }) => {
  const { projectId } = useParams();
  const [project] = useProject(projectId);
  const [, , assetEvents] = useAssets({
    projectRef: `Project/${projectId}`,
    events: true,
  });

  const [projectImage, setProjectImage] = useState();

  const [warningCount, setWarningCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);

  useEffect(() => {
    const { dueSoons, pastDues } = maintenanceServiceData(assetEvents);
    const alerts = dueSoons?.filter(
      (item) =>
        moment(item.dueDate).diff(moment(new Date().toISOString()), "days") <= 5
    );

    setWarningCount(pastDues?.length);
    setAlertCount(alerts?.length);
  }, [assetEvents]);

  useEffect(() => {
    if (project?.images?.length) {
      resolvePrimaryImage(project.images)
        .then((imageFile) => setProjectImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve project primary image:${err}`)
        );
    }
  }, [project]);

  const getAlerts = () => (
    <div
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
      className="flex"
      aria-label="div as button"
    >
      <SiteHeaderAlert total={warningCount} isRed onClick={() => {}} />
      <SiteHeaderAlert total={alertCount} onClick={() => {}} />
    </div>
  );

  return (
    <>
      <ProjectAssetList
        associatedResource={`Project/${projectId}`}
        siteHeaderProps={{
          title: project?.name || "Loading",
          dropdownRoutes: getProjectOptions(projectId, currentUser),
          alerts: getAlerts(),
        }}
        bannerComponent={
          <DetailViewBanner
            resource={project}
            resourceImage={projectImage}
            disableUpload
          />
        }
        alertCount={alertCount}
        warningCount={warningCount}
      />
    </>
  );
};

ProjectAssets.propTypes = {
  currentUser: PropTypes.shape({}),
};

ProjectAssets.defaultProps = {
  currentUser: undefined,
};

export default ProjectAssets;
