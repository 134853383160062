import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { uniq, uniqBy, toLower } from "lodash";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";
import { truncate } from "mml-react";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Pill from "../Pill/Pill";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import WorldClockSelect from "../SystemSettings/WorldClockSelect";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import { truncateLabel } from "../../../helpers/Tag";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import useUserDict from "../../../hooks/useUserDict";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import DescriptionField from "../DescriptionField";

const boldCN = (hasDropdown, className) => cntl`
  font-bold 
  text-gray-450
  text-md
  w-1/3
  self-start
  ${className}
  ${hasDropdown ? "mr-8" : ""}
`;

const textCN = cntl`
  text-gray-450 
  text-sm
  font-normal
  flex
  self-center
`;

const displayItem = ({ type, value, isPill = false, key, onClick }) =>
  value ? (
    <div
      key={key}
      style={{ width: "99%" }}
      className="flex border-b border-gray-450 h-full border-opacity-50 py-2 items-center"
    >
      <div className="flex flex-row min-w-full items-center mb-4">
        <h3 className={boldCN("flex")}>{type}</h3>
        {!isPill ? (
          value && (
            <button
              onClick={type === "Company" ? () => onClick() : null}
              type="button"
              className={`flex ml-5 flex-row w-2/3 pr-10 justify-start ${
                type === "Company" && "underline"
              }`}
            >
              <p className={textCN}>{truncate(value, 35, "...")}</p>
            </button>
          )
        ) : (
          <div className="flex flex-row w-2/3 pr-10 justify-start">
            <Pill
              value={value === "Nonmember" ? "Contact" : value}
              background="bg-gray-500"
              textSize="text-xs"
            />
          </div>
        )}
      </div>
    </div>
  ) : null;

const ContactProfileInfo = ({
  contactInfo,
  contactDetails,
  setContactDetails,
  setContactInfo,
  editingContact,
  saving,
  companyList,
  setNewDisciplines,
  contactUser,
  onBoarding,
}) => {
  const [disciplinesOptions, setDisciplinesOptions] = useState([]);
  const { data: managementConfiguration } = useManagementConfiguration();
  const history = useHistory();

  // Get the current user's company
  const { data: userCompany, isLoading } = useUserDict(
    contactDetails?.company?.value
  );

  const onClick = () => {
    history.push(getSingleResourcePath(contactDetails.company.value));
  };

  useEffect(() => {
    const options = managementConfiguration?.management?.contact?.disciplines
      .filter((d) => d.selected)
      .map((item) => ({
        label: item.display,
        value: item.id,
      }));
    setDisciplinesOptions(options);
  }, [managementConfiguration?.management?.contact?.disciplines]);

  const inputRefs = useRef({
    notes: null,
    company: null,
  });

  const add = useCallback(
    (type, option, defaultValue) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: [
          ...prev[type],
          {
            type:
              option ||
              `Custom ${
                type.charAt(0).toUpperCase() + type.slice(1, type.length - 1)
              }`,
            value: defaultValue,
          },
        ],
      }));
    },
    [setContactInfo]
  );

  const addTerritory = (value) => {
    setContactInfo((prev) => ({
      ...prev,
      territories: uniqBy(
        [
          ...prev.territories,
          {
            area: value.city,
            cityCode: value.timezone,
          },
        ],
        "area"
      ),
    }));
  };

  const removeTerritory = (area) => {
    setContactInfo((prev) => ({
      ...prev,
      territories: prev.territories.filter(
        (t) => t.area.toLowerCase() !== area.toLowerCase()
      ),
    }));
  };

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event, idx) => {
      // Checks if Enter key is pressed
      if (event.keyCode === 13) {
        event.preventDefault();
        const currentInputCategory = event.target.name;
        /** If a current form Category has multiple entries, move cursor to next entry field
         * within the same category (e.g. for a client that has multiple email fields open)
         */
        if (contactInfo[currentInputCategory].length > idx + 1) {
          const nextInputField =
            inputRefs.current[currentInputCategory][(idx + 1).toString()];
          nextInputField.focus();
        } else {
          const inputCategories = Object.keys(inputRefs.current);
          const currentInputCategoryIdx =
            inputCategories.indexOf(currentInputCategory);
          const nextCategory = inputCategories[currentInputCategoryIdx + 1];
          /* Checks if contactInfo is setup to receive data for next input field;
           * If not, it calls/simulates onClick by calling add() to open input field
           */
          if (contactInfo[nextCategory].length === 0) {
            if (nextCategory === "dates")
              add(nextCategory, "Birthday", new Date());
            else if (nextCategory !== "notes") add(nextCategory);
          }
          // setNextInputType(nextCategory);
        }
      }
    },
    [contactInfo, add]
  );

  const changeDiscipline = useCallback(
    (value) => {
      // eslint-disable-next-line no-underscore-dangle
      if (value?.__isNew__) {
        const newDisciplineId = uuidv4();
        setDisciplinesOptions((prev) => [
          ...prev,
          { label: value.value, value: newDisciplineId },
        ]);
        setContactInfo((prev) => ({
          ...prev,
          disciplines: uniq([...prev.disciplines, newDisciplineId]),
        }));
        setNewDisciplines((prev) => [
          ...prev,
          { id: newDisciplineId, display: value.value },
        ]);
      } else {
        setContactInfo((prev) => ({
          ...prev,
          disciplines: uniq([...prev.disciplines, value.value]),
        }));
      }
    },
    [setContactInfo, setNewDisciplines]
  );

  const removeDiscipline = (id) => {
    setContactInfo((prev) => ({
      ...prev,
      disciplines: prev.disciplines.filter((item) => item !== id),
    }));
  };

  const formattedCompanyList = useMemo(() => {
    return companyList.map((item) => {
      return {
        ...item,
        value: item.reference,
        label: item.company?.value,
        valueType: "reference",
        companyName: item.company?.value,
      };
    });
  }, [companyList]);
  return (
    <div
      className={`${
        isLoading && "loading"
      } overflow-visible min-w-full grid relative`}
      style={{
        gridTemplateColumns: "repeat(2, 1fr)",
        columnGap: "3.5rem",
      }}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        contactDetails?.kind === "company" ? null : !editingContact ? (
          displayItem({
            type: "Company",
            value: userCompany?.company?.value,
            isPill: false,
            onClick,
          })
        ) : (
          <div
            style={{ width: "99%" }}
            className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
          >
            <div className="flex h-24 flex-row min-w-full justify-between items-center">
              <div className="flex flex-row">
                <h3 className={boldCN()}>Company</h3>
              </div>
              <div className="flex items-center ml-5 justify-start w-2/3">
                <Dropdown
                  options={formattedCompanyList}
                  value={
                    contactDetails?.isNewCompany
                      ? contactDetails?.company
                      : {
                          label: userCompany?.company?.value,
                          value: userCompany?.reference,
                        }
                  }
                  onChange={(val) =>
                    setContactDetails({
                      ...contactDetails,
                      company: { value: val?.value, label: val?.value },
                      companyName: val?.value,
                      // eslint-disable-next-line no-underscore-dangle
                      isNewCompany: val?.__isNew__,
                    })
                  }
                  className="col-span-6"
                  placeholder={
                    contactDetails?.companyName
                      ? truncate(contactDetails?.companyName, 18, "...")
                      : "Enter company name"
                  }
                  isCreatable
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  forwardedRef={(el) => (inputRefs.current.Company = el)}
                  onKeyUp={handleEnter}
                  name="Company"
                  isDisabled={saving || onBoarding}
                />
              </div>
            </div>
          </div>
        )
      }
      {!editingContact
        ? displayItem({
            type: "Type",
            value: capitalizeFirstLetter(contactDetails?.kind),
            isPill: true,
          })
        : null}
      {!contactUser?.disciplines?.length && !editingContact ? null : (
        <div className="flex flex-col min-w-full border-b justify-between mt-2 border-gray-450 border-opacity-50 items-center">
          <div className="flex flex-row h-full overflow-visible min-w-full items-center">
            <div className="flex flex-col w-1/3 justify-center h-full items-center">
              <h3 className={boldCN()}>Disciplines</h3>
            </div>
            <div className="justify-center w-full overflow-visible pb-2 flex">
              {editingContact ? (
                <div className="flex items-center pr-10 w-full">
                  <Dropdown
                    labelClassName="text-gray-300 self-center"
                    name="Disciplines"
                    options={disciplinesOptions}
                    onChange={changeDiscipline}
                    placeholder="Select"
                    isCreatable
                  />
                </div>
              ) : null}
              <div className="flex flex-row ml-6 justify-end">
                <div className="flex flex-row flex-wrap mt-4 h-full mb-2 gap-4 w-full">
                  {contactInfo?.disciplines?.map((disciplineId) => {
                    const label = disciplinesOptions?.find(
                      (item) => item.value === disciplineId
                    )?.label;
                    return (
                      <div key={disciplineId} className="relative m-w-full">
                        {editingContact && (
                          <button
                            type="button"
                            className="absolute -top-1.5 right-0"
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                            onClick={() => removeDiscipline(disciplineId)}
                          >
                            <img
                              alt="delete tag"
                              className="rounded-xl p-1"
                              style={{ backgroundColor: "#027D61" }}
                              src={crossIconWhite}
                            />
                          </button>
                        )}
                        {label && (
                          <div className="bg-tagGreen rounded-3xl truncate overflow-ellipsis p-2">
                            <p className="text-primaryGreen text-xs font-light truncate text-ellipsis">
                              {truncateLabel(
                                toLower(label).replace(/ /g, "_"),
                                20,
                                "..."
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!contactUser?.territories?.length && !editingContact ? null : (
        <div className="flex flex-col min-w-full overflow-visible border-b justify-between pb-2 mt-2 border-gray-450 min-h-24 border-opacity-50 items-center">
          <div className="flex min-h-full flex-row flex-wrap min-w-full justify-between items-center">
            <div className="flex flex-row">
              <h3 className={boldCN()}>Territories</h3>
            </div>
            <div className="justify-end w-2/3 ml-5 overflow-visible">
              {editingContact ? (
                <div className="flex items-center w-full mt-10 pr-10 overflow-visible">
                  <WorldClockSelect
                    key={uuidv4()}
                    handleCustomCityChange={addTerritory}
                    handleBlur={() => {}}
                    autoFocus={false}
                  />
                </div>
              ) : null}
              <div className="flex flex-row ml-6">
                <div className="flex flex-row flex-wrap mt-4 h-full mb-2 gap-4 w-full">
                  {contactInfo?.territories?.map((territory) => {
                    return (
                      <div key={territory?.area} className="relative m-w-full">
                        {editingContact && (
                          <button
                            type="button"
                            className="absolute -top-1.5 right-0"
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                            onClick={() => removeTerritory(territory?.area)}
                          >
                            <img
                              alt="delete tag"
                              className="rounded-xl p-1"
                              style={{ backgroundColor: "#027D61" }}
                              src={crossIconWhite}
                            />
                          </button>
                        )}
                        {territory?.area && (
                          <div className="bg-tagGreen rounded-3xl truncate overflow-ellipsis p-2">
                            <p className="text-primaryGreen text-xs font-light truncate text-ellipsis">
                              {truncateLabel(
                                toLower(territory?.area).replace(/ /g, "_"),
                                20,
                                "..."
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {contactInfo?.notes || editingContact ? (
        <div className="flex flex-col min-w-full border-b justify-between pb-2 mt-2 border-gray-450 border-opacity-50 items-center">
          <div className="flex overflow-y-scroll flex-row min-w-full justify-between items-center h-full">
            <div className="flex flex-col justify-center h-full">
              <p className={boldCN()}>Notes</p>
            </div>
            <div className="flex w-full ml-16">
              {editingContact ? (
                <Input
                  mainWrapperClassName="w-full p-12 ml-4"
                  isTextarea
                  placeholder="Notes"
                  value={contactInfo?.notes}
                  onChange={(val) =>
                    setContactInfo((prev) => ({ ...prev, notes: val }))
                  }
                  // eslint-disable-next-line no-return-assign
                  forwardedRef={(el) => (inputRefs.current.notes = el)}
                  name="notes"
                  disabled={saving}
                />
              ) : (
                <div className="flex h-full ml-2 items-center justify-center">
                  <DescriptionField
                    className="flex text-gray-450 text-sm ml-6"
                    text={contactInfo?.notes}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {!editingContact ? (
        <div className="bg-white absolute w-full h-1 bottom-0 right-0" />
      ) : null}
    </div>
  );
};

const reviewItemShape = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  })
);

ContactProfileInfo.propTypes = {
  /**
   * OnBoarding: Bool - to block company member dropdown
   */
  onBoarding: PropTypes.bool,
  contactInfo: PropTypes.shape({
    phoneNumbers: reviewItemShape,
    emails: reviewItemShape,
    addresses: reviewItemShape,
    websites: reviewItemShape,
    notes: PropTypes.string,
    socials: reviewItemShape,
    dates: reviewItemShape,
    gender: PropTypes.string,
    kind: PropTypes.string,
    disciplines: PropTypes.arrayOf(PropTypes.string),
    territories: PropTypes.arrayOf(
      PropTypes.shape({
        area: PropTypes.string,
        cityCode: PropTypes.string,
      })
    ),
  }),
  contactDetails: PropTypes.shape({
    kind: PropTypes.string,
    company: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    companyName: PropTypes.string,
    isNewCompany: PropTypes.bool,
  }),
  setContactDetails: PropTypes.func,
  setContactInfo: PropTypes.func,
  editingContact: PropTypes.bool,
  saving: PropTypes.bool,
  contactUser: PropTypes.shape({
    territories: PropTypes.arrayOf({}),
    disciplines: PropTypes.arrayOf({}),
  }),
  companyList: PropTypes.arrayOf({}),
  setNewDisciplines: PropTypes.func,
};

ContactProfileInfo.defaultProps = {
  onBoarding: undefined,
  contactInfo: undefined,
  editingContact: undefined,
  contactUser: undefined,
  setContactInfo: () => {},
  setContactDetails: () => {},
  saving: false,
  contactDetails: undefined,
  companyList: undefined,
  setNewDisciplines: () => {},
};

export default ContactProfileInfo;
