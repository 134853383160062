import moment from "moment";
import React, { useMemo } from "react";
import { formatCurrency } from "../../../helpers/Formatters";
import { FILTER_TYPES } from "../../../constants";
import ReceiptPopup from "./ReceiptPopup";
import Table from "../Table/Table";
import Pill from "../Pill/Pill";

const groupOptions = {
  hierarchicalOptions: [],
  nonHierarchicalOptions: [
    {
      label: "Association",
      value: "associationName",
    },
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Employee",
      value: "employeeName",
    },
    {
      label: "Merchant",
      value: "merchant",
    },
  ],
};

const PureExpenseTable = ({
  expenseData,
  setCreateExpenseModal,
  isLoading,
  onRowClick,
  hideHeader,
  hideSiteHeaderTitle,
  hideSwitchView,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  fileActionsIcons,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Receipt",
        accessor: "receipts",
        minWidth: 60,
        maxWidth: 100,
        Cell: ({ value }) => {
          return <ReceiptPopup value={value} />;
        },
        Aggregated: () => null,
      },
      {
        Header: "Expense Date",
        accessor: "date",
        Cell: ({ value }) =>
          value ? <span>{moment(value).format("LL")}</span> : "",
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Merchant",
        accessor: "merchant",
      },
      {
        Header: "Category",
        accessor: "category",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      { Header: "Description", accessor: "description" },
      {
        Header: "CSI Code",
        accessor: "financialCode",
        width: 250,
        Cell: ({ value }) => value || "",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <Pill
              className="h-5"
              value={value}
              textSize="text-xxs capitalize"
              border="border border-primaryGreen"
              background="bg-white"
              textColor="text-primaryGreen"
              textWeight="font-semibold"
              padding="p-2"
            />
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: "amount",
        width: 150,
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: "Non-Billable",
        accessor: "billable",
        width: 140,
        Cell: ({ value }) => {
          if (!value) {
            return (
              <span
                className="rounded-full flex h-2 w-2 mr-1 mt-1 bg-brandGreen"
                aria-label="yes"
              />
            );
          }
          return <></>;
        },
      },
      {
        Header: "Credit Card",
        accessor: "creditCard",
        width: 130,
        Cell: ({ value }) => {
          if (value) {
            return (
              <span
                className="rounded-full flex h-2 w-2 mr-1 mt-1 bg-brandGreen"
                aria-label="yes"
              />
            );
          }
          return <></>;
        },
      },
      {
        Header: "Invoiced",
        accessor: "isInvoiced",
        width: 130,
        Cell: ({ value }) => {
          if (value) {
            return (
              <span
                className="rounded-full flex h-2 w-2 mr-1 mt-1 bg-brandGreen"
                aria-label="yes"
              />
            );
          }
          return <></>;
        },
      },
      { Header: "Notes", accessor: "note" },
      {
        Header: "Employee",
        accessor: "employeeName",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Association",
        accessor: "associationName",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.isOrNot,
        },
      },
    ],
    []
  );

  return (
    <Table
      resourceName="expenses"
      data={expenseData}
      columns={columns}
      groupOptions={groupOptions}
      showConstantRowIndex
      setCreateExpenseModal={setCreateExpenseModal}
      isLoading={isLoading}
      onRowClick={onRowClick}
      hideSiteHeader={hideHeader}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      hideSwitchView={hideSwitchView}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft={false}
    />
  );
};

export default PureExpenseTable;
