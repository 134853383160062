import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

import useProjects from "../../../hooks/useProjects";
import {
  useAssetSwitchView,
  useDocumentSwitchView,
  useContactSwitchView,
  useProjectSwitchView,
  usePropertySwitchView,
  useWorkflowSwitchView,
  useRfiSwitchView,
  useSpaceSwitchView,
  useTaskSwitchView,
} from "../../../hooks/useSwitchView";
import {
  getAssetOptions,
  getProjectLocationOptions,
  getProjectOptions,
  getPropertyLocationOptions,
  getPropertyOptions,
  getPropertySpaceOptions,
  getTimeCardOptions,
} from "../../../helpers/SiteHeaderOptions";
import {
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  SOP_ADD_NEW_PATH,
  GET_PROJECT_ACTIVATE_PATH,
  SET_SOP_MODAL_STATUS,
  SET_PROJECT_MODAL_STATUS,
  SET_CALENDAR_MODAL_STATUS,
  PROJECT_STATUS_TYPES,
  SET_RFI_MODAL_STATUS,
  SET_PROPERTY_MODAL_STATUS,
  ADD_OPEN_MODAL,
  CREATE_ASSET,
} from "../../../constants";
import PrimaryButton from "../Buttons/PrimaryButton";
import SiteHeader from "./SiteWidgetHeader";
import SiteHeaderOld from "./SiteHeader";
import SiteHeaderSwitchView from "./SiteHeaderSwitchView";
import FinancialCharts from "../../../Pages/Overviews/Financials/FinancialCharts";
import usePropertyById from "../../../hooks/usePropertyById";
import folderIconGreen from "../../assets/images/folderIconGreen.svg";
import folderIconWhite from "../../assets/images/folderIconWhite.svg";
import useAssetById from "../../../hooks/useAssetById";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";

const SiteHeaderTableBlock = ({
  setCreateExpenseModal,
  setCreateMaintenanceModal,
  selectedRowIds,
  selectedLength,
  currentRoute,
  buttonActions,
  viewOptions,
  alerts,
  search,
  filter,
  tabs,
  hideSwitchView,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  hideSiteHeaderActionButtons,
  hideDropdownContainer,
  hideSiteHeaderTitle,
  onTemplateChange,
  showReportButton,
}) => {
  const [primaryResource, , nestedResource] = currentRoute
    .substring(1)
    .split("/");

  const queryTab = useURLQueryParameter("tab");

  const assetTableHeader = () => {
    return (
      <AssetTableSiteHeader
        nestedResource={nestedResource}
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
        filter={filter}
        setCreateExpenseModal={setCreateExpenseModal}
        hideSwitchView={hideSwitchView}
        hideSiteHeaderActionButtons={hideSiteHeaderActionButtons}
        hideDropdownContainer={hideDropdownContainer}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        filesView={filesView}
        fileActionsIcons={fileActionsIcons}
        showColumnSettingsLeft={showColumnSettingsLeft}
        showReportButton={showReportButton}
      />
    );
  };

  const projectTableSiteHeader = () => {
    return (
      <ProjectsTableSiteHeader
        nestedResource={nestedResource}
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
        filter={filter}
        buttonActions={buttonActions}
        hideSwitchView={hideSwitchView}
        setCreateExpenseModal={setCreateExpenseModal}
        hideSiteHeaderActionButtons={hideSiteHeaderActionButtons}
        hideDropdownContainer={hideDropdownContainer}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        filesView={filesView}
        fileActionsIcons={fileActionsIcons}
        showColumnSettingsLeft={showColumnSettingsLeft}
        showReportButton={showReportButton}
      />
    );
  };

  switch (primaryResource) {
    case "assets":
      return assetTableHeader();
    case "account-admin":
      return (
        <SettingsTableSiteHeader
          viewOptions={viewOptions}
          search={search}
          filter={filter}
          fileActionsIcons={fileActionsIcons}
        />
      );
    case "sops":
      return (
        <SopsTableSiteHeader
          nestedResource={nestedResource}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          hideSwitchView={hideSwitchView}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          fileActionsIcons={fileActionsIcons}
          showColumnSettingsLeft={showColumnSettingsLeft}
        />
      );
    case "contacts":
      return (
        <ContactTableSiteHeader
          buttonActions={buttonActions}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );
    case "documents":
      return (
        <DocumentTableSiteHeader
          selectedRowIds={selectedRowIds}
          buttonActions={buttonActions}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );
    case "financial":
      return (
        <FinancialActualTableSiteHeader
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );
    case "properties": {
      if (queryTab === "assets") {
        return assetTableHeader();
      }
      if (queryTab === "projects") {
        return projectTableSiteHeader();
      }

      return (
        <PropertiesTableSiteHeader
          nestedResource={nestedResource}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          setCreateExpenseModal={setCreateExpenseModal}
          filesView={filesView}
          fileActionsIcons={fileActionsIcons}
          showColumnSettingsLeft={showColumnSettingsLeft}
          hideSwitchView={hideSwitchView}
          hideSiteHeaderActionButtons={hideSiteHeaderActionButtons}
          hideDropdownContainer={hideDropdownContainer}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          onTemplateChange={onTemplateChange}
          showReportButton={showReportButton}
        />
      );
    }
    case "files":
      return (
        <FilesTableSiteHeader
          viewOptions={viewOptions}
          search={search}
          filter={filter}
          fileActionsIcons={fileActionsIcons}
        />
      );
    case "projects":
      if (queryTab === "assets") {
        return assetTableHeader();
      }

      return projectTableSiteHeader();
    case "workflow":
      return (
        <WorkflowsTableSiteHeader
          buttonActions={buttonActions}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          tabs={tabs}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          fileActionsIcons={fileActionsIcons}
          showColumnSettingsLeft={showColumnSettingsLeft}
        />
      );
    case "submittals":
      return (
        <SubmittalsTableSiteHeader
          buttonActions={buttonActions}
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          tabs={tabs}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          fileActionsIcons={fileActionsIcons}
          showColumnSettingsLeft={showColumnSettingsLeft}
        />
      );
    case "maintenance":
      return (
        <MaintenanceTasksTableSiteHeader
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          setCreateMaintenanceModal={setCreateMaintenanceModal}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );
    case "submitals":
      return (
        <SubmitalsTableSiteHeader
          viewOptions={viewOptions}
          alerts={alerts}
          search={search}
          filter={filter}
          setCreateMaintenanceModal={setCreateMaintenanceModal}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );

    case "reports":
      return (
        <ReportsTableSiteHeader
          fileActionsIcons={fileActionsIcons}
          search={search}
          filter={filter}
          viewOptions={viewOptions}
        />
      );
    case "spaces":
      return (
        <SpacesTableSiteHeader
          search={search}
          filter={filter}
          hideSwitchView={hideSwitchView}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          viewOptions={viewOptions}
          showColumnSettingsLeft={showColumnSettingsLeft}
          fileActionsIcons={fileActionsIcons}
        />
      );

    case "timeoff":
      return (
        <TimeOffTableSiteHeader
          search={search}
          filter={filter}
          viewOptions={viewOptions}
          selectedLength={selectedLength}
          buttonActions={buttonActions}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );

    case "expenses":
      return (
        <ExpenseTableSiteHeader
          search={search}
          filter={filter}
          viewOptions={viewOptions}
          setCreateExpenseModal={setCreateExpenseModal}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          fileActionsIcons={fileActionsIcons}
        />
      );

    case "tasks":
      return (
        <TaskTableSiteHeader
          search={search}
          filter={filter}
          viewOptions={viewOptions}
          setCreateExpenseModal={setCreateExpenseModal}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          fileActionsIcons={fileActionsIcons}
        />
      );

    case "rfi":
      return (
        <RfiTableSiteHeader
          search={search}
          filter={filter}
          hideSwitchView={hideSwitchView}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
        />
      );

    case "tickets":
      return (
        <TicketsTableSiteHeader
          fileActionsIcons={fileActionsIcons}
          search={search}
          filter={filter}
          viewOptions={viewOptions}
        />
      );
    default:
      return null;
  }
};

/**
 * Table header for Service Requests/Tickets
 */
function TicketsTableSiteHeader({
  fileActionsIcons,
  search,
  filter,
  viewOptions,
}) {
  return (
    <SiteHeader
      fileActionsIcons={fileActionsIcons}
      search={search}
      filter={filter}
      viewOptions={viewOptions}
    />
  );
}

function AssetTableSiteHeader({
  nestedResource,
  viewOptions,
  alerts,
  search,
  filter,
  setOpenModal,
  hideSwitchView,
  hideSiteHeaderActionButtons,
  hideDropdownContainer,
  hideSiteHeaderTitle,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  showReportButton,
}) {
  const { projectId, assetId, propertyId } = useParams();
  const [, modalDispatch] = useModalState();
  const { asset } = useAssetById(assetId);
  const [isShowingTable, setIsShowingTable] = useAssetSwitchView();
  const [{ currentUser }] = useAppState();

  if (nestedResource === "documents") return null;

  return (
    <SiteHeader
      fileActionsIcons={fileActionsIcons}
      filesView={filesView}
      showColumnSettingsLeft={showColumnSettingsLeft}
      title={!hideSiteHeaderTitle && (asset.name || "Assets")}
      dropdownRoutes={
        assetId && getAssetOptions(assetId, propertyId, projectId)
      }
      buttons={
        !hideSiteHeaderActionButtons && (
          <>
            {nestedResource === "expenses" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("expense", "can_create")) && (
                <PrimaryButton
                  large
                  title="+ Add Expense"
                  onClick={() => setOpenModal(true)}
                />
              )}
            {!nestedResource &&
              currentUser?.hasPermission?.("asset", "can_write") && (
                <PrimaryButton
                  resourceName="Asset"
                  onClick={() =>
                    modalDispatch({
                      type: ADD_OPEN_MODAL,
                      modalData: {
                        item: {
                          disableAssociation: false,
                        },
                      },
                      ref: { id: uuidv4() },
                      modalType: CREATE_ASSET,
                    })
                  }
                  addButton
                />
              )}
          </>
        )
      }
      viewOptions={viewOptions}
      alerts={alerts}
      search={search}
      filter={filter}
      switchView={
        !hideSwitchView &&
        nestedResource !== "expenses" && (
          <SiteHeaderSwitchView
            isShowingTable={isShowingTable}
            setIsShowingTable={setIsShowingTable}
          />
        )
      }
      hideDropdownContainer={hideDropdownContainer}
      hideSiteHeaderActionButtons={hideSiteHeaderActionButtons}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      showReportButton={showReportButton}
    />
  );
}

function DocumentTableSiteHeader({
  /*
   * passes selectedRowIds from Table to Documents buttonActions delete onClick
   */
  // selectedRowIds,
  // buttonActions,
  viewOptions,
  alerts,
  search,
  filter,
}) {
  const [{ currentUser }] = useAppState();
  const { projectId } = useParams();
  const { documentId } = useParams();
  const { currentProject } = useProjects(projectId);
  const [isShowingTable, setIsShowingTable] = useDocumentSwitchView();

  if (documentId) return null;

  const {
    props: { children: viewOption },
  } = viewOptions;

  /*
   * passes selectedRowIds from Table to Documents buttonActions delete onClick
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const newButtonActions = React.useMemo(() => {
  //   if (Array.isArray(buttonActions)) {
  //     // eslint-disable-next-line react/destructuring-assignment
  //     return buttonActions?.map((item) => {
  //       if (item?.title === "Delete") {
  //         return { ...item, onClick: () => item.onClick(selectedRowIds) };
  //       }
  //       return item;
  //     });
  //   }
  //   return [];
  // }, [buttonActions, selectedRowIds]);

  const handleUploadClick = () => {
    document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  return (
    <SiteHeader
      title={currentProject?.name || "Documents"}
      dropdownRoutes={projectId && getProjectOptions(projectId, currentUser)}
      buttons={
        currentUser?.hasPermission?.("document", "can_write") && (
          <PrimaryButton
            title="+ add document"
            onClick={handleUploadClick}
            large
          />
        )
      }
      viewOptions={viewOption?.[1]}
      alerts={alerts}
      search={search}
      filter={filter}
      switchView={
        <SiteHeaderSwitchView
          isShowingTable={isShowingTable}
          setIsShowingTable={setIsShowingTable}
          firstIcon={folderIconGreen}
          firstSelectedIcon={folderIconWhite}
        />
      }
    />
  );
}

function ContactTableSiteHeader({
  buttonActions,
  viewOptions,
  alerts,
  search,
  filter,
  hideSiteHeaderTitle,
}) {
  const [isShowingTable, setIsShowingTable] = useContactSwitchView();
  const {
    props: { children: viewOption },
  } = viewOptions;
  return (
    <SiteHeader
      title="Contacts"
      buttons={
        <PrimaryButton
          large
          title="Actions"
          className="dropdown-btn"
          dropdownItems={buttonActions}
        />
      }
      viewOptions={viewOption?.[1]}
      alerts={alerts}
      search={search}
      filter={filter}
      switchView={
        <SiteHeaderSwitchView
          isShowingTable={isShowingTable}
          setIsShowingTable={setIsShowingTable}
        />
      }
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function FinancialActualTableSiteHeader({
  viewOptions,
  alerts,
  search,
  filter,
}) {
  const createNewForecast = () => {
    // TODO!
  };

  const createNewTableRow = () => {
    // TODO!
  };

  const createNewTableColumn = () => {
    // TODO!
  };

  return (
    <>
      <SiteHeader
        title="Financials"
        buttons={
          <PrimaryButton
            title="Create"
            className="dropdown-btn"
            large
            dropdownItems={[
              {
                title: "Forecast",
                onClick: () => createNewForecast(),
              },
              {
                title: "Table Row",
                onClick: () => createNewTableRow(),
              },
              {
                title: "Table Column",
                onClick: () => createNewTableColumn(),
              },
            ]}
          />
        }
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
        filter={filter}
      />
      <FinancialCharts />
    </>
  );
}

function FilesTableSiteHeader({
  viewOptions,
  search,
  filter,
  fileActionsIcons,
}) {
  return (
    <SiteHeader
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft
      viewOptions={viewOptions}
      search={search}
      filter={filter}
      hideDropdownContainer
      hideSiteHeaderTitle
    />
  );
}

function SettingsTableSiteHeader({ search, filter }) {
  return (
    <SiteHeader
      showColumnSettingsLeft
      search={search}
      filter={filter}
      hideDropdownContainer
      hideSiteHeaderTitle
    />
  );
}

function PropertiesTableSiteHeader({
  nestedResource,
  viewOptions,
  alerts,
  search,
  filter,
  setOpenModal,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  hideSwitchView,
  hideSiteHeaderActionButtons,
  hideDropdownContainer,
  hideSiteHeaderTitle,
  onTemplateChange,
  showReportButton,
}) {
  const { propertyId, assetId, projectId, locationId, spaceId } = useParams();
  const { property: currentProperty } = usePropertyById(propertyId, true);
  const { asset } = useAssetById(assetId);
  const { push } = useHistory();
  const [{ currentUser }, dispatch] = useAppState();
  const [, modalDispatch] = useModalState();
  const [isShowingPropertiesTable, setIsShowingPropertiesTable] =
    usePropertySwitchView();
  const [isShowingAssetTable, setIsShowingAssetTable] = useAssetSwitchView();
  const [isShowingWorkflowsTable, setIsShowingWorkflowsTable] =
    useWorkflowSwitchView();
  const [isShowingSpacesTable, setIsShowingSpacesTable] = useSpaceSwitchView();

  const onCreateNewClicked = () => {
    if (nestedResource === "assets" || nestedResource === "locations") {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        allowAssocSelect: false,
        modalData: {
          item: {
            propertyId,
            projectId,
            spaceId,
            disableAssociation: true,
          },
        },
        ref: { id: uuidv4() },
        modalType: CREATE_ASSET,
      });
    } else if (nestedResource === "workflows") {
      push(GET_PROPERTY_WORKFLOW_CREATE_PATH(propertyId));
    } else {
      dispatch({
        type: SET_PROPERTY_MODAL_STATUS,
        open: true,
      });
    }
  };

  const tableView = () => {
    switch (nestedResource) {
      case "assets":
        return {
          var: isShowingAssetTable,
          func: setIsShowingAssetTable,
        };
      case "locations":
        return {
          var: isShowingAssetTable,
          func: setIsShowingAssetTable,
        };
      case "workflows":
        return {
          var: isShowingWorkflowsTable,
          func: setIsShowingWorkflowsTable,
        };
      case "spaces":
        return {
          var: isShowingSpacesTable,
          func: setIsShowingSpacesTable,
        };
      default:
        return {
          var: isShowingPropertiesTable,
          func: isShowingPropertiesTable
            ? () => {
                onTemplateChange({ label: undefined, value: undefined });
                setIsShowingPropertiesTable();
              }
            : setIsShowingPropertiesTable,
        };
    }
  };
  const getSiteHeaderTitle = () => {
    if (asset?.name) {
      return asset?.name;
    }
    if (spaceId) {
      const loctionResource = currentProperty?.buildings?.filter((loc) => {
        return loc.id === locationId;
      })[0];

      const spaceResource = loctionResource?.spaces?.filter((spc) => {
        return spc.id === spaceId;
      })[0];
      return spaceResource?.name;
    }
    if (locationId) {
      const loation = currentProperty?.buildings?.filter((loc) => {
        return loc.id === locationId;
      })[0];
      return loation?.name;
    }
    if (currentProperty?.title) {
      return currentProperty?.title;
    }

    return "Properties";
  };

  if (nestedResource === "documents") return null;
  return (
    <SiteHeader
      filesView={filesView}
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft={showColumnSettingsLeft}
      title={!hideSiteHeaderTitle && getSiteHeaderTitle()}
      dropdownRoutes={
        !filesView &&
        ((propertyId &&
          assetId &&
          locationId &&
          spaceId &&
          getAssetOptions(
            assetId,
            propertyId,
            projectId,
            locationId,
            spaceId
          )) ||
          (propertyId &&
            assetId &&
            locationId &&
            getAssetOptions(assetId, propertyId, projectId, locationId)) ||
          (propertyId &&
            assetId &&
            getAssetOptions(assetId, propertyId, projectId)) ||
          (propertyId &&
            locationId &&
            spaceId &&
            getPropertySpaceOptions(
              propertyId,
              currentUser,
              locationId,
              spaceId
            )) ||
          (propertyId &&
            locationId &&
            getPropertyLocationOptions(propertyId, currentUser, locationId)) ||
          (propertyId && getPropertyOptions(propertyId, currentUser)))
      }
      showReportButton={showReportButton}
      buttons={
        !hideSiteHeaderActionButtons && (
          <>
            {nestedResource === "locations" &&
              currentUser?.hasPermission?.("asset", "can_write") && (
                <PrimaryButton
                  large
                  title="+ Add Asset"
                  onClick={onCreateNewClicked}
                />
              )}
            {nestedResource === "workflows" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("workflow", "can_create")) && (
                <PrimaryButton
                  large
                  title="Create New"
                  onClick={onCreateNewClicked}
                />
              )}
            {nestedResource === "assets" &&
              currentUser?.hasPermission?.("asset", "can_write") && (
                <PrimaryButton
                  large
                  title="+ Add Asset"
                  onClick={onCreateNewClicked}
                />
              )}
            {nestedResource === "expenses" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("expense", "can_write")) && (
                <PrimaryButton
                  large
                  title="+ Add Expense"
                  onClick={() => setOpenModal(true)}
                />
              )}
            {nestedResource === "asset" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("expense", "can_write")) && (
                <PrimaryButton
                  large
                  title="+ Add Expense"
                  onClick={() => setOpenModal(true)}
                />
              )}
            {!nestedResource &&
              currentUser?.hasPermission?.(
                "administrative",
                "can_write_property"
              ) && (
                <PrimaryButton
                  resourceName="Property"
                  onClick={onCreateNewClicked}
                  addButton
                />
              )}
          </>
        )
      }
      viewOptions={viewOptions}
      alerts={alerts}
      search={search}
      filter={filter}
      switchView={
        !filesView &&
        !hideSwitchView &&
        nestedResource !== "expenses" &&
        nestedResource !== "asset" && (
          <SiteHeaderSwitchView
            isShowingTable={tableView().var}
            setIsShowingTable={tableView().func}
          />
        )
      }
      hideDropdownContainer={hideDropdownContainer}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function ProjectsTableSiteHeader({
  nestedResource,
  viewOptions,
  alerts,
  search,
  filter,
  buttonActions,
  hideSwitchView,
  setOpenModal,
  hideSiteHeaderActionButtons,
  hideDropdownContainer,
  hideSiteHeaderTitle,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  showReportButton,
}) {
  const [, dispatch] = useAppState();
  const { projectId, assetId, propertyId, locationId, spaceId } = useParams();
  const { currentProject } = useProjects(projectId);
  const { asset } = useAssetById(assetId);
  const { location, push } = useHistory();
  const [isShowingTable, setIsShowingTable] = useProjectSwitchView();
  const [isShowingAssetTable, setIsShowingAssetTable] = useAssetSwitchView();
  const [{ currentUser }] = useAppState();
  const isOnProjectAssets = location.pathname.match("/assets$") && true;
  const [isShowingWorkflowsTable, setIsShowingWorkflowsTable] =
    useWorkflowSwitchView();
  const [isShowingSpacesTable, setIsShowingSpacesTable] = useSpaceSwitchView();
  const [, modalDispatch] = useModalState();

  if (nestedResource === "documents-and-media")
    return (
      <DocumentTableSiteHeader
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
      />
    );

  const onCreateNewButtonClicked = () => {
    if (
      isOnProjectAssets ||
      nestedResource === "locations" ||
      nestedResource === "assets"
    ) {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        allowAssocSelect: false,
        modalData: {
          item: {
            disableAssociation: false,
          },
        },
        ref: { id: uuidv4() },
        modalType: CREATE_ASSET,
      });
    } else if (nestedResource === "workflows") {
      push(GET_PROJECT_WORKFLOW_CREATE_PATH(projectId));
    } else if (nestedResource === "budget") {
      push(GET_PROJECT_ACTIVATE_PATH(projectId));
    } else {
      // push(PROJECT_CREATION_PATH);
      dispatch({
        type: SET_PROJECT_MODAL_STATUS,
        open: true,
      });
    }
  };

  const tableView = () => {
    switch (nestedResource) {
      case "assets":
        return {
          var: isShowingAssetTable,
          func: setIsShowingAssetTable,
        };
      case "locations":
        return {
          var: isShowingAssetTable,
          func: setIsShowingAssetTable,
        };
      case "workflows":
        return {
          var: isShowingWorkflowsTable,
          func: setIsShowingWorkflowsTable,
        };
      case "submittals":
        return {
          var: isShowingWorkflowsTable,
          func: setIsShowingWorkflowsTable,
        };
      case "details":
      case "spaces":
        return {
          var: isShowingSpacesTable,
          func: setIsShowingSpacesTable,
        };
      default:
        return {
          var: isShowingTable,
          func: setIsShowingTable,
        };
    }
  };

  const getSiteHeaderTitle = () => {
    if (asset?.name) {
      return asset?.name;
    }
    if (spaceId) {
      const loctionResource = currentProject?.buildings?.filter((loc) => {
        return loc.id === locationId;
      })[0];

      const spaceResource = loctionResource?.spaces?.filter((spc) => {
        return spc.id === spaceId;
      })[0];
      return spaceResource?.name;
    }
    if (locationId) {
      const loation = currentProject?.buildings?.filter((loc) => {
        return loc.id === locationId;
      })[0];
      return loation?.name;
    }
    if (currentProject?.name) {
      return currentProject?.name;
    }

    return "Projects";
  };

  return (
    <SiteHeader
      filesView={filesView}
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft={showColumnSettingsLeft}
      title={!hideSiteHeaderTitle && getSiteHeaderTitle()}
      dropdownRoutes={
        !filesView &&
        ((projectId &&
          assetId &&
          locationId &&
          spaceId &&
          getAssetOptions(
            assetId,
            propertyId,
            projectId,
            locationId,
            spaceId
          )) ||
          (projectId &&
            assetId &&
            locationId &&
            getAssetOptions(assetId, propertyId, projectId, locationId)) ||
          (projectId &&
            locationId &&
            getProjectLocationOptions(projectId, currentUser, locationId)) ||
          (projectId &&
            assetId &&
            getAssetOptions(assetId, propertyId, projectId)) ||
          (projectId && getProjectOptions(projectId, currentUser)))
      }
      buttons={
        !hideSiteHeaderActionButtons && (
          <>
            {nestedResource === "locations" &&
              currentUser?.hasPermission?.("asset", "can_write") && (
                <PrimaryButton
                  large
                  title="+ Add Asset"
                  onClick={onCreateNewButtonClicked}
                />
              )}
            {nestedResource === "assets" &&
              currentUser?.hasPermission?.("asset", "can_write") && (
                <PrimaryButton
                  resourceName="Asset"
                  onClick={onCreateNewButtonClicked}
                  addButton
                />
              )}
            {nestedResource === "workflows" &&
              currentUser?.hasPermission?.("workflow", "can_create") && (
                <PrimaryButton
                  large
                  title="+ Add Workflow"
                  onClick={onCreateNewButtonClicked}
                />
              )}
            {nestedResource === "budget" && !!currentProject?.status && (
              <PrimaryButton
                large
                title={
                  currentProject?.status === PROJECT_STATUS_TYPES.draft
                    ? "Activate Project"
                    : "Actions"
                }
                onClick={() => {
                  if (currentProject?.status === PROJECT_STATUS_TYPES.draft) {
                    onCreateNewButtonClicked();
                  }
                }}
                dropdownItems={!!buttonActions?.length && buttonActions}
              />
            )}
            {nestedResource === "expenses" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("expense", "can_write")) && (
                <PrimaryButton
                  large
                  title="+ Add Expense"
                  onClick={() => setOpenModal(true)}
                />
              )}
            {nestedResource === "asset" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("asset", "can_write")) && (
                <PrimaryButton
                  large
                  title="+ Add Expense"
                  onClick={() => setOpenModal(true)}
                />
              )}
            {nestedResource === "submittals" &&
              (currentUser?.isAdmin ||
                currentUser?.hasPermission?.("submittal", "can_create")) && (
                <PrimaryButton
                  large
                  title="+ Add Submittal"
                  onClick={() => {}}
                />
              )}
            {!nestedResource &&
              currentUser?.hasPermission?.(
                "administrative",
                "can_write_project"
              ) && (
                <PrimaryButton
                  addButton
                  resourceName={buttonActions ? "Actions" : "Project"}
                  onClick={onCreateNewButtonClicked}
                  dropdownItems={buttonActions}
                />
              )}
          </>
        )
      }
      viewOptions={viewOptions}
      alerts={alerts}
      search={search}
      filter={filter}
      switchView={
        !filesView &&
        !hideSwitchView &&
        nestedResource !== "expenses" &&
        nestedResource !== "asset" && (
          <SiteHeaderSwitchView
            isShowingTable={tableView().var}
            setIsShowingTable={tableView().func}
          />
        )
      }
      hideDropdownContainer={hideDropdownContainer}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      showReportButton={showReportButton}
    />
  );
}

function MaintenanceTasksTableSiteHeader({
  alerts,
  search,
  filter,
  setCreateMaintenanceModal,
  hideSiteHeaderTitle,
}) {
  const [{ currentUser }] = useAppState();

  return (
    <SiteHeader
      title="Maintenance"
      buttons={
        currentUser?.hasPermission?.("task", "can_create") && (
          <PrimaryButton
            addButton
            resourceName="Maintenance"
            onClick={() => setCreateMaintenanceModal(true)}
          />
        )
      }
      alerts={alerts}
      search={search}
      filter={filter}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function SubmitalsTableSiteHeader({
  viewOptions,
  alerts,
  search,
  filter,
  hideSiteHeaderTitle,
}) {
  const [{ currentUser }] = useAppState();

  return (
    <SiteHeader
      title="Submittals"
      buttons={
        currentUser?.hasPermission?.("submittal", "can_create") && (
          <PrimaryButton large title="+ Add Submittal3" onClick={() => {}} />
        )
      }
      alerts={alerts}
      search={search}
      filter={filter}
      viewOptions={viewOptions}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

/**
 * Table header for Reports
 */
function ReportsTableSiteHeader({
  fileActionsIcons,
  search,
  filter,
  viewOptions,
}) {
  return (
    <SiteHeader
      fileActionsIcons={fileActionsIcons}
      search={search}
      filter={filter}
      viewOptions={viewOptions}
    />
  );
}

function TimeOffTableSiteHeader({
  search,
  filter,
  viewOptions,
  selectedLength,
  buttonActions,
  hideSiteHeaderTitle,
}) {
  const [{ currentUser }] = useAppState();
  return (
    <SiteHeaderOld
      title="Timesheets"
      search={search}
      filter={filter}
      viewOptions={viewOptions}
      buttons={
        <PrimaryButton
          title={
            selectedLength <= 0 ? "Actions" : `(${selectedLength}) Selected`
          }
          large
          dropdownItems={[
            {
              title: "Deny",
              onClick: () => buttonActions("$decline"),
            },
            {
              title: "Approve",
              onClick: () => buttonActions("$approve"),
            },
          ]}
          disabled={selectedLength <= 0}
          className="whitespace-nowrap"
        />
      }
      dropdownRoutes={getTimeCardOptions(currentUser)}
      dropDownIgnoreList={["Submitted Time", "Time Off Requests"]}
      timeOffDisableSort
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function ExpenseTableSiteHeader({
  search,
  filter,
  viewOptions,
  setOpenModal,
  hideSiteHeaderTitle,
  fileActionsIcons,
}) {
  const [{ currentUser }] = useAppState();
  return (
    <SiteHeader
      title="Expenses"
      search={search}
      filter={filter}
      viewOptions={viewOptions}
      buttons={
        (currentUser?.isAdmin ||
          currentUser?.hasPermission?.("expense", "can_write")) && (
          <PrimaryButton
            addButton
            resourceName="Expense"
            onClick={() => setOpenModal(true)}
          />
        )
      }
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      fileActionsIcons={fileActionsIcons}
    />
  );
}

export function TaskTableSiteHeader({
  search,
  filter,
  viewOptions,
  fileActionsIcons,
}) {
  const [{ currentUser }, dispatch] = useAppState();
  const [isShowingTable, setIsShowingTable] = useTaskSwitchView();
  return (
    <SiteHeader
      title="Tasks"
      search={search}
      filter={filter}
      viewOptions={viewOptions}
      fileActionsIcons={fileActionsIcons}
      buttons={
        (currentUser?.isAdmin ||
          currentUser?.hasPermission?.("task", "can_create")) && (
          <PrimaryButton
            addButton
            resourceName="Task"
            onClick={() =>
              dispatch({
                type: SET_CALENDAR_MODAL_STATUS,
                create: false,
                edit: false,
                view: false,
                isTask: true,
                sideBarOpen: true,
              })
            }
          />
        )
      }
      switchView={
        <SiteHeaderSwitchView
          isShowingTable={isShowingTable}
          setIsShowingTable={setIsShowingTable}
        />
      }
      hideSiteHeaderTitle
    />
  );
}

function SopsTableSiteHeader({
  nestedResource,
  viewOptions,
  alerts,
  search,
  filter,
  buttonActions,
  hideSiteHeaderTitle,
  fileActionsIcons,
  showColumnSettingsLeft,
}) {
  const [{ currentUser }, dispatch] = useAppState();
  const { push } = useHistory();
  if (nestedResource === "documents-and-media")
    return (
      <DocumentTableSiteHeader
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
      />
    );

  const onCreateNewButtonClicked = () => {
    push(SOP_ADD_NEW_PATH);
  };

  return (
    <SiteHeader
      fileActionsIcons={fileActionsIcons}
      showColumnSettingsLeft={showColumnSettingsLeft}
      title="Standard Operating Procedures"
      buttons={
        <>
          {(nestedResource === "workflows" &&
            currentUser?.hasPermission?.("workflow", "can_create") && (
              <PrimaryButton
                large
                title="Create New"
                onClick={onCreateNewButtonClicked}
              />
            )) || (
            <PrimaryButton
              addButton
              resourceName={buttonActions ? "Actions" : "SOP"}
              onClick={() => {
                dispatch({
                  type: SET_SOP_MODAL_STATUS,
                  open: true,
                });
              }}
              dropdownItems={buttonActions}
            />
          )}
        </>
      }
      viewOptions={viewOptions}
      alerts={alerts}
      search={search}
      filter={filter}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function WorkflowsTableSiteHeader({
  buttonActions,
  showColumnSettingsLeft,
  viewOptions,
  alerts,
  search,
  filter,
  tabs,
  hideSiteHeaderTitle,
  fileActionsIcons,
}) {
  const [{ currentUser }] = useAppState();

  return (
    <div className={`${!fileActionsIcons && "mb-20"}`}>
      <SiteHeader
        title="Workflows"
        buttons={
          (currentUser?.isAdmin ||
            currentUser?.hasPermission?.("workflow", "can_create")) && (
            <PrimaryButton
              addButton
              resourceName="Workflow"
              onClick={buttonActions}
            />
          )
        }
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
        filter={filter}
        tabs={tabs}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        fileActionsIcons={fileActionsIcons}
        showColumnSettingsLeft={showColumnSettingsLeft}
      />
    </div>
  );
}

function SubmittalsTableSiteHeader({
  fileActionsIcons,
  showColumnSettingsLeft,
  buttonActions,
  viewOptions,
  alerts,
  search,
  filter,
  tabs,
  hideSiteHeaderTitle,
}) {
  const [{ currentUser }] = useAppState();
  /* ** hide submittals cardView ** */
  // const [isShowingTable, setIsShowingTable] = useWorkflowSwitchView();

  return (
    <div className={`${!fileActionsIcons && "mb-20"}`}>
      <SiteHeader
        title="Submittals"
        buttons={
          (currentUser?.isAdmin ||
            currentUser?.hasPermission?.("submittal", "can_create")) && (
            <PrimaryButton
              addButton
              resourceName="Submittal"
              onClick={buttonActions}
            />
          )
        }
        viewOptions={viewOptions}
        alerts={alerts}
        search={search}
        filter={filter}
        /* ** hide submittals cardView ** */
        // switchView={
        //   <SiteHeaderSwitchView
        //     isShowingTable={isShowingTable}
        //     setIsShowingTable={setIsShowingTable}
        //   />
        // }
        tabs={tabs}
        hideSiteHeaderTitle={hideSiteHeaderTitle}
        fileActionsIcons={fileActionsIcons}
        showColumnSettingsLeft={showColumnSettingsLeft}
      />
    </div>
  );
}

function RfiTableSiteHeader({
  search,
  filter,
  hideSwitchView,
  hideSiteHeaderTitle,
}) {
  const [, dispatch] = useAppState();
  const [isShowingTable, setIsShowingTable] = useRfiSwitchView();

  return (
    <SiteHeader
      title="RFI"
      buttons={
        <PrimaryButton
          large
          title="+ Add RFI"
          onClick={() =>
            dispatch({
              type: SET_RFI_MODAL_STATUS,
              open: true,
            })
          }
        />
      }
      search={search}
      filter={filter}
      switchView={
        !hideSwitchView && (
          <SiteHeaderSwitchView
            isShowingTable={isShowingTable}
            setIsShowingTable={setIsShowingTable}
          />
        )
      }
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

function SpacesTableSiteHeader({
  search,
  filter,
  hideSwitchView,
  hideSiteHeaderTitle,
  viewOptions,
  fileActionsIcons,
  showColumnSettingsLeft,
}) {
  const [isShowingTable, setIsShowingTable] = useSpaceSwitchView();

  return (
    <SiteHeader
      title="Spaces"
      showColumnSettingsLeft={showColumnSettingsLeft}
      fileActionsIcons={fileActionsIcons}
      viewOptions={viewOptions}
      search={search}
      filter={filter}
      switchView={
        !hideSwitchView && (
          <SiteHeaderSwitchView
            isShowingTable={isShowingTable}
            setIsShowingTable={setIsShowingTable}
          />
        )
      }
      hideSiteHeaderTitle={hideSiteHeaderTitle}
    />
  );
}

SiteHeaderTableBlock.propTypes = {
  selectedRowIds: PropTypes.shape({
    id: PropTypes.bool,
  }),
  selectedLength: PropTypes.number,
  currentRoute: PropTypes.string,
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  viewOptions: PropTypes.elementType,
  alerts: PropTypes.elementType,
  search: PropTypes.elementType,
  filter: PropTypes.elementType,
  tabs: PropTypes.arrayOf(PropTypes.node),
  hideSwitchView: PropTypes.bool,
  setCreateExpenseModal: PropTypes.func,
  setCreateMaintenanceModal: PropTypes.func,
  hideSiteHeaderActionButtons: PropTypes.bool,
  hideDropdownContainer: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
  filesView: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
  showColumnSettingsLeft: PropTypes.bool,
  /**
   * Clear template when switching to card view
   */
  onTemplateChange: PropTypes.func,
  /**
   * Show create report button in table header
   */
  showReportButton: PropTypes.bool,
};

SiteHeaderTableBlock.defaultProps = {
  selectedRowIds: {},
  selectedLength: 0,
  currentRoute: undefined,
  buttonActions: undefined,
  viewOptions: undefined,
  alerts: undefined,
  search: undefined,
  filter: undefined,
  tabs: undefined,
  hideSwitchView: false,
  setCreateExpenseModal: undefined,
  setCreateMaintenanceModal: undefined,
  hideSiteHeaderActionButtons: false,
  hideDropdownContainer: false,
  hideSiteHeaderTitle: false,
  filesView: false,
  fileActionsIcons: undefined,
  showColumnSettingsLeft: false,
  onTemplateChange: undefined,
  showReportButton: false,
};

WorkflowsTableSiteHeader.propTypes = {
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  viewOptions: PropTypes.elementType,
  alerts: PropTypes.elementType,
  search: PropTypes.elementType,
  filter: PropTypes.elementType,
  tabs: PropTypes.arrayOf(PropTypes.node),
  hideSiteHeaderTitle: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
  showColumnSettingsLeft: PropTypes.bool,
};

WorkflowsTableSiteHeader.defaultProps = {
  buttonActions: undefined,
  viewOptions: undefined,
  alerts: undefined,
  search: undefined,
  filter: undefined,
  tabs: undefined,
  hideSiteHeaderTitle: false,
  fileActionsIcons: undefined,
  showColumnSettingsLeft: false,
};

export default SiteHeaderTableBlock;
