import React from "react";
import PropTypes from "prop-types";

const PlanSelectionHeader = ({ selectedPlan, userCount, frequency, total }) => {
  return (
    <div className="flex flex-col pr-2" style={{ width: "28rem" }}>
      <div className="flex flex-row">
        <p className="text-black font-normal">
          {selectedPlan.planName} plan for {userCount} users
        </p>
      </div>
      <div className="flex flex-row">
        <p className="text-black font-semibold">
          {frequency.toUpperCase()} TOTAL: ${total}
        </p>
      </div>
    </div>
  );
};

PlanSelectionHeader.propTypes = {
  selectedPlan: PropTypes.shape({
    planName: PropTypes.string,
  }),
  frequency: PropTypes.string,
  userCount: PropTypes.number,
  total: PropTypes.number,
};

PlanSelectionHeader.defaultProps = {
  selectedPlan: undefined,
  frequency: undefined,
  userCount: undefined,
  total: undefined,
};

export default PlanSelectionHeader;
