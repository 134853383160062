import moment from "moment-timezone";
import cntl from "cntl";
import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { FILTER_TYPES } from "../../../constants";
import { isFullDay } from "../../../helpers/Calendar";
import { useTaskSwitchView } from "../../../hooks/useSwitchView";
import { useGetTags } from "../../../hooks/useTags";
import { TaskTableSiteHeader } from "../SiteHeader/SiteHeaderTableBlock";
import Table from "../Table/Table";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import TableDescriptionCell from "../Table/TableDescriptionCell";
import TagList from "../Tags/TagList";
import SingleTaskView from "./SingleTaskView";
import TaskCardGrid from "./TaskCardGrid";
import { useAppState } from "../../../state/appState";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";
import { getUserFirstAndInitial } from "../../../helpers/Utilities";
import { statusPillCN } from "../../../helpers/ServiceRequest/formatTasks";
import Pill from "../Pill/Pill";
import timezoneIcon from "../../assets/images/timezoneGrey.svg";
import infoIcon from "../../assets/images/infoDarkenedGreenIcon.svg";
import { useRsvp } from "../../../hooks/rsvp";

const statusCN = (status) => cntl`
  border 
  flex 
  items-center 
  rounded-full
  font-medium
  text-sm
  py-2 
  px-3
  h-7
  min-w-min
  ${status}
`;

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Association",
      value: "associatedName",
    },
    {
      label: "Recurrence",
      value: "formattedRecurrence",
    },
    {
      label: "Tags",
      value: "tags",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Completed By",
      value: "completedBy",
    },
  ],
};

const PureTaskTable = ({
  data,
  onTaskClick,
  hideSiteHeaderTitle,
  currentTask,
  clearCurrentTask,
  setCurrentTask,
  usedSopDict,
  tasksTemplates,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  handleOpenComments,
}) => {
  const [isShowingTaskTable] = useTaskSwitchView();
  const { data: tagsData } = useGetTags();
  const [{ userDict, calendarTimezone }] = useAppState();
  const [isOpen, setIsOpen] = useState(false);

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
        width: 200,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row, index }) => (
          <TableDescriptionCell row={row} index={index} />
        ),
        width: 200,
      },
      {
        Header: "Due Date",
        accessor: "startDate",
        Cell: ({ row }) => {
          return (
            <div className="flex">
              <p>
                {isFullDay(
                  row?.original?.startDate,
                  row?.original?.endDate,
                  row?.original?.allDay
                )
                  ? moment
                      .tz(row?.original?.startDate, calendarTimezone)
                      .format("dddd, MMMM Do YYYY")
                  : moment
                      .tz(row?.original?.startDate, calendarTimezone)
                      .format("dddd, MMMM Do YYYY, h:mm a")}
              </p>
            </div>
          );
        },
        filterOptions: {
          label: "Due Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
        Aggregated: () => null,
      },
      {
        Header: "Association",
        accessor: "associatedName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Status",
        accessor: "formattedStatus",
        Cell: ({ value }) => {
          return (
            <Pill
              value={value}
              background=""
              className={statusCN(statusPillCN(value))}
            />
          );
        },
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Workflow",
        accessor: "workflowName",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ value }) => {
          const tags = value?.map((tagRef) => ({
            label: tagsData?.tagsDict?.[tagRef]?.label,
            value: tagsData?.tagsDict?.[tagRef]?.reference,
          }));
          return (
            <TagList
              tags={tags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 250,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        minWidth: 300,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Assignees",
        accessor: "invitees",
        Cell: ({ value, row }) => {
          const users = value?.map((userRef) => {
            return {
              ...userDict?.[userRef],
              name: getUserFirstAndInitial(userDict?.[userRef]),
              value: userRef,
            };
          });

          const { data: rsvpData } = useRsvp(
            row?.original?.reference,
            row?.original?.instanceStartDate
          );
          return (
            <RoleMembersAvatarList
              showExtraCount
              showRightTooltip
              members={users}
              tooltipClassName="top-8 left-5 z-10"
              viewAllClassName="text-xs pl-3"
              className="mr-28 ml-2 py-4 h-fit"
              showRsvpStatus
              rsvpData={rsvpData}
            />
          );
        },
        minWidth: 300,
        filterOptions: {
          label: "Assignees",
          filterType: FILTER_TYPES.userRefs,
        },
      },
      {
        Header: "Completed By",
        accessor: "completedBy",
        minWidth: 300,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Recurrence",
        accessor: "formattedRecurrence",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Last Completed",
        accessor: "lastCompleted",
        Cell: ({ value }) => (
          <div className="flex">
            <p>
              {value ? moment(value).format("dddd, MMMM Do YYYY, h:mm a") : "-"}
            </p>
          </div>
        ),
        width: 350,
      },
    ];
  }, [calendarTimezone, tagsData?.tagsDict, userDict]);

  // If a current task is selected, show the single task view
  if (currentTask) {
    return (
      <SingleTaskView
        currentTask={currentTask}
        setCurrentTask={setCurrentTask}
        usedSopDict={usedSopDict}
        clearCurrentTask={clearCurrentTask}
      />
    );
  }

  // if no current task is selected, show list in ether table or card grid view

  return (
    <Table
      resourceName="tasks"
      data={data}
      columns={tableColumns}
      showConstantRowIndex
      groupOptions={groupOptions}
      onRowClick={onTaskClick}
      cardGrid={!isShowingTaskTable}
      customSiteHeader={(props) => TaskTableSiteHeader(props)}
      cardGridComponent={(rows) => (
        <TaskCardGrid
          handleOpenComments={handleOpenComments}
          onTaskClick={onTaskClick}
          rows={rows}
        />
      )}
      hideSiteHeaderTitle={hideSiteHeaderTitle}
      fileActionsIcons={
        <div className="flex flex-col">
          <div className="flex flex-row items-center flex-nowrap w-full pr-10 h-full">
            <img src={timezoneIcon} alt="timezone" className="flex pr-1" />
            <p className="text-es-normal font-es-bold text-es-medium-grey pr-2">
              Timezone:
            </p>
            <p className="text-es-normal font-es-bold text-es-medium-grey">
              {calendarTimezone}
            </p>
            <Popover
              isOpen={isOpen}
              content={
                <div className="border border-es-light-grey rounded-lg shadow-light-lift py-3 px-4 flex flex-row items-center bg-white mr-24 mb-2">
                  <p className="text-es-medium-grey font-es-normal text-es-sm">
                    Calendar determines the task list time zone.
                  </p>
                </div>
              }
            >
              <img
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                src={infoIcon}
                alt="info"
                className="flex w-4 ml-2"
              />
            </Popover>
          </div>
          <TableActionsIconsGroup
            showColumnSettingsRight={isShowingTaskTable}
          />
        </div>
      }
      templateSettings={tasksTemplates}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      hideGroupedFooter
    />
  );
};

export default PureTaskTable;
