/* eslint-disable no-return-assign */
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import CrossButton from "../CrossButton/CrossButton";
import crossDarkGrey from "../../assets/images/crossDarkGrey.svg";

const TimeSheetTooltipCard = ({
  date,
  note = "",
  isBillable,
  onChangeBillable,
  onChangeNote,
  disabled,
  onClose,
}) => {
  const checkboxRef = useRef();
  const [noteState, setNoteState] = useState(note);

  const handleChanges = (val) => setNoteState(val);

  const handleClose = useCallback(() => {
    onChangeNote(noteState);
    onClose();
  }, [noteState, onChangeNote, onClose]);

  return (
    <div
      className="z-10 flex flex-col bg-white px-6 pb-6 pt-4 rounded overflow-hidden shadow-medium-lift"
      style={{ width: "300px" }}
      tabIndex={-1}
      role="menu"
      onMouseLeave={() => {
        if (noteState !== note) onChangeNote?.(noteState);
      }}
    >
      <div className="flex flex-row w-full justify-between h-10">
        <div className="flex font-bold text-base text-gray-500 items-end">
          {moment.utc(date).format("MMMM DD, YYYY")}
        </div>
        <CrossButton
          icon={crossDarkGrey}
          className="flex items-start w-full h-full"
          style={{ height: 12, width: 12 }}
          onClick={handleClose}
        />
      </div>
      <div className="mt-3 mb-3 text-sm font-normal text-gray-450">Note</div>
      <Input
        className="p-0 m-0"
        inputContainerClassName="p-0 m-0 border rounded-lg"
        inputClassName="bg-backgroundGreen w-full p-2 text-gray-450"
        mainClassName="text-sm font-normal"
        placeholder="Note"
        value={noteState}
        onChange={handleChanges}
        disabled={disabled}
        isTextarea
        tabIndex={-1}
        resizable={false}
        disableClear
      />

      <div className="">
        <Checkbox
          className="mt-3"
          labelClassName="text-sm font-normal text-gray-450"
          label="Non-Billable"
          checked={!isBillable}
          onChange={onChangeBillable}
          disabled={disabled}
          ref={checkboxRef}
        />
      </div>
    </div>
  );
};

TimeSheetTooltipCard.propTypes = {
  /**
   * maintance data
   */
  date: PropTypes.string,
  note: PropTypes.string,
  isBillable: PropTypes.bool,
  onChangeBillable: PropTypes.func,
  onChangeNote: PropTypes.func,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

TimeSheetTooltipCard.defaultProps = {
  date: undefined,
  note: undefined,
  isBillable: undefined,
  onChangeBillable: undefined,
  onChangeNote: undefined,
  disabled: false,
  onClose: () => {},
};

export default TimeSheetTooltipCard;
