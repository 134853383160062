import cntl from "cntl";
import PropTypes from "prop-types";
import React from "react";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";
import { useAppState } from "../../../state/appState";

const associatedUserCN = cntl`
  bg-gray-150
  border-2
  border-gray-100
  z-40
  rounded-full
  w-8
  h-8
  flex
  items-center
  justify-center
`;

const ViewAllButton = ({ members }) => {
  return (
    <div className={associatedUserCN}>
      <p className="uppercase text-gray-400 text-sm font-semibold truncate">
        +{members?.length - 5}
      </p>
    </div>
  );
};

const TableDirectReportCell = ({ value }) => {
  const [{ userDict, rolesDict }] = useAppState();
  const values = Object.values(value);
  let dr = [];

  // eslint-disable-next-line no-return-assign
  values?.forEach((item) => (dr = [...dr, ...item]));
  const members = dr?.map((item) =>
    item?.startsWith("User/")
      ? userDict?.[item]
      : {
          id: item?.split("/")[1],
          name: { firstName: rolesDict?.[item]?.name },
          kind: "role",
        }
  );

  return (
    // (Parker) Hardcoded width for cell here as the tooltip gets cut-off otherwise
    <div style={{ width: "400px" }}>
      <RoleMembersAvatarList
        className="pl-1"
        members={members}
        viewAllClassName="text-xs"
        viewAllButton={<ViewAllButton members={members} />}
        tooltipClassName="top-8 left-8 z-10"
        showRightTooltip
        avatarContainerClassname="w-6 h-6"
      />
    </div>
  );
};

TableDirectReportCell.propTypes = {
  value: PropTypes.shape({
    original: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
};

TableDirectReportCell.defaultProps = {
  value: undefined,
};

export default TableDirectReportCell;
