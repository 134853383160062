import React from "react";
import PropTypes from "prop-types";

const BillingInformationHeader = ({ billingInfo }) => {
  return (
    <div className="flex flex-col pr-2" style={{ width: "28rem" }}>
      <div className="flex flex-row">
        <p className="text-black font-normal">{billingInfo.name}</p>
      </div>
      <div className="flex flex-row">
        <p className="text-black font-semibold">
          {billingInfo.cardType?.toUpperCase()}
        </p>
      </div>
    </div>
  );
};
BillingInformationHeader.propTypes = {
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    cardType: PropTypes.string,
  }),
};

BillingInformationHeader.defaultProps = {
  billingInfo: undefined,
};

export default BillingInformationHeader;
