import React, { useRef, lazy, Suspense } from "react";
import {
  ADD_ATTRIBUTE_POPUP,
  ADD_CATEGORY_POPUP,
  CONFIRMATION_POPUP,
  DELETE_RECURRENCE_POPUP,
  EDIT_RECURRENCE_POPUP,
  EXPORT_FILE_TYPE_POPUP,
  INVITE_CONTACT_POPUP,
  MARK_COMPLETE_POPUP,
  RECURRENCE_FORM_POPUP,
  TODO_MEMBERS_DROPDOWN,
  TODO_SELECT_MEMBER,
  VIEW_MORE_POPUP,
} from "../../../constants";
import usePopupData from "./usePopupData";
import PopupWrapper from "./PopupWrapper";

// Create a map to associate popup types with lazy-loaded components
const POPUP_IMPORT_MAP = {
  [VIEW_MORE_POPUP]: () => import("./ViewMorePopup"),
  [RECURRENCE_FORM_POPUP]: () => import("./CustomRecurrencePopup"),
  [EDIT_RECURRENCE_POPUP]: () => import("./EditRecurrencePopup"),
  [DELETE_RECURRENCE_POPUP]: () => import("./DeleteRecurrencePopup"),
  [MARK_COMPLETE_POPUP]: () => import("./MarkCompletePopup"),
  [EXPORT_FILE_TYPE_POPUP]: () => import("./ExportFileTypePopup"),
  [CONFIRMATION_POPUP]: () => import("./ConfirmationPopup"),
  [TODO_MEMBERS_DROPDOWN]: () => import("./TodoMembersDropdown"),
  [TODO_SELECT_MEMBER]: () => import("./TodoSelectMember"),
  [ADD_CATEGORY_POPUP]: () => import("./AddCategoryPopup"),
  [ADD_ATTRIBUTE_POPUP]: () => import("./AddAttributePopup"),
  [INVITE_CONTACT_POPUP]: () => import("./InviteContactPopup"),
};

const EsPopup = ({ reloadEvents }) => {
  // useRef to get a reference to the DOM element where the popup will be mounted
  const containerRef = useRef(null);
  // Extracting necessary values and methods from the custom hook usePopupData
  const { popupWrapperStyles, popupData, closePopup } =
    usePopupData(containerRef);
  // Determine which lazy-loaded component to render based on the popup type
  const LazyPopupImport = POPUP_IMPORT_MAP[popupData.popupType];
  let LazyPopupContent = null;
  // If the popup type is valid, import the corresponding component
  if (LazyPopupImport) {
    LazyPopupContent = lazy(LazyPopupImport);
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PopupWrapper
        containerRef={containerRef}
        popupWrapperStyles={popupWrapperStyles}
      >
        {LazyPopupContent && (
          <LazyPopupContent
            closePopup={closePopup}
            popupData={popupData.popupData}
            reloadEvents={reloadEvents}
          />
        )}
      </PopupWrapper>
    </Suspense>
  );
};

export default EsPopup;
