import moment from "moment";

const determineTimeSpan = (startUtc, endUtc) => {
  // Parse the timestamps using Moment.js
  const startTime = moment(startUtc);
  const endTime = moment(endUtc);

  // Calculate the difference in days
  const daySpan = endTime.diff(startTime, "days") + 1; // Adding 1 to include the start day

  return daySpan;
};

export default determineTimeSpan;
