import React from "react";
import PropTypes from "prop-types";
import SecondaryHeader from "../TextHeaders/SecondaryHeader";
import TertiaryHeader from "../TextHeaders/TertiaryHeader";
import RemoveIcon from "../RemoveIcon/RemoveIcon";
import Avatar from "../Message/Avatar";

const ListRow = ({ title, subtitle, onDelete }) => {
  return (
    <div className="grid grid-cols-12 gap-4 items-center mb-4">
      <div className="flex flex-row col-span-3">
        <Avatar className="rounded-full mr-4" name={title} />
        <div className="flex-row">
          <SecondaryHeader className="capitalize">{title}</SecondaryHeader>
          <TertiaryHeader>{subtitle}</TertiaryHeader>
        </div>
      </div>
      <RemoveIcon className="col-span-1 cursor-pointer" onClick={onDelete} />
    </div>
  );
};

ListRow.propTypes = {
  /**
   * Title of the ComponentDear Sir
   */
  title: PropTypes.string,

  /**
   * Subtitle of the Component
   */
  subtitle: PropTypes.string,

  /**
   * function to call on click of remove Button
   */
  onDelete: PropTypes.func,
};

ListRow.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onDelete: null,
};

export default ListRow;
