import React, { useEffect, useState, useCallback, useMemo } from "react";
import { debounce } from "lodash";
import moment from "moment";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { AssetAPI, DocumentAPI } from "@griffingroupglobal/eslib-api";
import PropTypes from "prop-types";

import { useAppState } from "../../../state/appState";
import useDocuments from "../../../hooks/useDocuments";
import useAssets from "../../../hooks/useAssets";
import useAssetById from "../../../hooks/useAssetById";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import useDocumentsConfiguration from "../../../hooks/useDocumentsConfiguration";
import useFavorites from "../../../hooks/useFavorites";

import Modal from "../../../stories/Components/Modal/Modal";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import SiteHeaderEditColumns from "../../../stories/Components/SiteHeader/SiteHeaderEditColumns";
import SiteHeaderSearch from "../../../stories/Components/SiteHeader/SiteHeaderSearch";
import SiteHeaderListFilter from "../../../stories/Components/SiteHeader/SiteHeaderListFilter";
import SiteHeaderSwitchView from "../../../stories/Components/SiteHeader/SiteHeaderSwitchView";
import DocumentTable from "../../../stories/Components/DocumentTable/DocumentTable";
import DocumentUploadForm from "../../../stories/Components/DocumentUploadForm/DocumentUploadForm";
import DetailViewBanner from "../../../stories/Components/DetailViewBanner/DetailViewBanner";
import FileCard from "../../../stories/Components/FileCard/FileCard";
import InfoTooltip from "../../../stories/Components/InfoTooltip/InfoTooltip";

import folderIconGreen from "../../../stories/assets/images/folderIconGreen.svg";
import folderIconWhite from "../../../stories/assets/images/folderIconWhite.svg";

import { formatServerErrorMessage } from "../../../helpers/Formatters";
import { getAssetDocuments } from "../../../helpers/AssetDocuments";
import { getAssetOptions } from "../../../helpers/SiteHeaderOptions";
import { resolvePrimaryImage } from "../../../helpers/File";
import { toastError } from "../../../helpers/Toast";
import {
  WORKFLOWS_ADD_NEW_PATH,
  SET_WORKFLOW_DOCUMENT,
  createAssetTooltipTitle,
  createAssetDocuments,
  documentFilterOptions,
  fileCardStyle,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  DOCUMENT_DISPLAY_STATUS,
  GET_ASSET_DOCUMENTS_DOC_PATH,
  GET_ASSET_DOCUMENTS_DOC_EDIT_PATH,
  GET_ASSET_DOCUMENTS_DOC_HISTORY_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_HISTORY_PATH,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
  GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH,
} from "../../../constants";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";

import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import { getDocumentUser } from "../../../helpers/Document";

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PureAssetDocuments = ({
  hideHeader,
  isShowingTable,
  setIsShowingTable,
  setButtonActions,
}) => {
  const history = useHistory();
  const [{ currentUser, userDict }, dispatchAppState] = useAppState();
  const { assetId, propertyId, projectId, locationId, spaceId } = useParams();
  const { asset, setAsset } = useAssetById(assetId);
  const { documents, addDocument, removeDocument } = useDocuments();
  const { data: docConfig } = useDocumentsConfiguration();
  const [editedAsset, dispatch] = useAssetFormReducer();
  const [assets] = useAssets({ assetId });
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const [loading, setLoading] = useState(true);
  const [assetImage, setAssetImage] = useState();
  const [clonedAsset, setClonedAsset] = useState(asset);
  const [filterType, setFilterType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [assetDocuments, setAssetDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [resetDocs, setResetDocs] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();

  useEffect(() => {
    // optionally add CTA to add file when in Property files tab view
    if (currentUser?.hasPermission?.("document", "can_write") && !loading) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add File")) {
          return [
            {
              title: "Add File",
              onClick: () => {
                document
                  .querySelector("#document-folders .document_upload_form")
                  ?.click();
              },
              tabAction: true,
            },
            ...prev,
          ];
        }
        return prev;
      });
    }
  }, [currentUser, loading, setButtonActions]);

  const resetDocuments = React.useCallback(
    async (docList) => {
      const result = await getAssetDocuments(
        asset,
        docList,
        currentUser,
        userDict
      );

      setAssetDocuments(result?.documents);
      setFilteredDocuments(result?.documents);

      if (result?.returnedAsset) {
        const updatedAsset = {
          ...result.returnedAsset,
          primaryImage: clonedAsset.primaryImage,
        };
        setAsset(updatedAsset);
        setClonedAsset(updatedAsset);
      }

      setResetDocs(false);
      setLoading(false);
    },
    [asset, clonedAsset.primaryImage, currentUser, setAsset, userDict]
  );

  useEffect(() => {
    if (clonedAsset?.id !== assetId && assets?.[0]?.id === assetId) {
      setAssetImage(assets?.[0]?.primaryImage?.contentsUrl ?? null);
      setClonedAsset(assets?.[0]);
    }
  }, [assets, clonedAsset, assetId]);

  useEffect(() => {
    const resolvePrimary = async () => {
      if (clonedAsset?.images?.length) {
        const primaryImage = await resolvePrimaryImage(clonedAsset.images);
        setAssetImage(primaryImage?.contentsUrl);
        setClonedAsset((prev) => {
          return { ...prev, primaryImage };
        });
      }
    };
    resolvePrimary();
  }, [clonedAsset?.images]);

  useEffect(() => {
    if (asset?.documents?.length && !assetDocuments?.length) {
      setLoading(true);
      resetDocuments(asset.documents);
    }
    if (asset?.documents && !asset.documents?.length) {
      setLoading(false);
    }
  }, [asset?.documents, assetDocuments, resetDocuments]);

  useEffect(() => {
    const filesUploading = assetDocuments?.some((item) => item.isUploading);
    if (!filesUploading && resetDocs) {
      const finishedAsset = {
        ...asset,
        documents: [...assetDocuments.map((item) => item.reference)],
      };

      AssetAPI.patch(assetId, finishedAsset, asset)
        .then(({ data }) => {
          setAsset(data);
          resetDocuments(assetDocuments.map((item) => item.reference));
        })
        .catch((err) => {
          const serverMsg = formatServerErrorMessage(err);
          console.error(serverMsg);
        });
    }
  }, [assetId, asset, assetDocuments, resetDocs, resetDocuments, setAsset]);

  const resetAssetState = React.useCallback(() => {
    dispatch({
      type: "reset",
      asset,
    });
  }, [asset, dispatch]);

  useEffect(() => {
    if (asset) {
      resetAssetState();
    }
  }, [asset, resetAssetState]);

  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const handleFileClick = (doc) => {
    if (propertyId) {
      history.push(GET_PROPERTY_DOCUMENTS_DOC_PATH(propertyId, doc.id));
    } else if (projectId) {
      history.push(GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, doc.id));
    } else {
      history.push(GET_ASSET_DOCUMENTS_DOC_PATH(assetId, doc.id));
    }
  };
  const handleRowClick = (doc) => {
    if (propertyId) {
      history.push(GET_PROPERTY_DOCUMENTS_DOC_PATH(propertyId, doc.id));
    } else if (projectId) {
      history.push(GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, doc.id));
    } else {
      history.push(GET_ASSET_DOCUMENTS_DOC_PATH(assetId, doc.id));
    }
  };
  const handleFileEdit = async (doc) => {
    const { data } = await DocumentAPI.getWOP(`${doc.id}/$canedit`);
    if (data.canedit) {
      if (propertyId) {
        history.push(
          GET_PROPERTY_DOCUMENTS_DOC_EDIT_PATH(
            propertyId,
            typeof doc === "string" ? doc : doc.id
          )
        );
      } else if (projectId) {
        history.push(
          GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH(
            projectId,
            typeof doc === "string" ? doc : doc.id
          )
        );
      } else {
        history.push(
          GET_ASSET_DOCUMENTS_DOC_EDIT_PATH(
            assetId,
            typeof doc === "string" ? doc : doc.id
          )
        );
      }
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be edited`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };
  const handleShowHistory = (doc) => {
    if (propertyId) {
      history.push(
        GET_PROPERTY_DOCUMENTS_DOC_HISTORY_PATH(
          propertyId,
          typeof doc === "string" ? doc : doc.id
        )
      );
    } else if (projectId) {
      history.push(
        GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH(
          projectId,
          typeof doc === "string" ? doc : doc.id
        )
      );
    } else {
      history.push(
        GET_ASSET_DOCUMENTS_DOC_HISTORY_PATH(
          assetId,
          typeof doc === "string" ? doc : doc.id
        )
      );
    }
  };

  const handleSendToWorkflow = useCallback(
    ({ id, project, property }) => {
      const document = documents.find((doc) => doc.id === id);

      const parentDocument = documents.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      const doesParentDocHasCurrentDocAsAdditionalDoc =
        parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
      // if document has parent, then block the access and toast msg
      if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
        toastError(
          `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
          toastErrorIcon,
          toastCloseIcon
        );
      } else {
        const allChildDocs = [];
        // if document has children then also attach the children docs to the WF
        if (documents?.length && document?.additionalDocuments?.length) {
          document?.additionalDocuments?.forEach((child) => {
            const childDoc = documents.find((doc) => doc.reference === child);
            if (childDoc) {
              allChildDocs.push(childDoc);
            }
          });
        }
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          workflowDocument: [id, ...allChildDocs.map((child) => child.id)],
        });

        if (project) {
          history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(project.split("/")[1]));
        } else if (property) {
          history.push(
            GET_PROPERTY_WORKFLOW_CREATE_PATH(property.split("/")[1])
          );
        } else {
          history.push(WORKFLOWS_ADD_NEW_PATH);
        }
      }
    },
    [dispatchAppState, documents, history]
  );
  const handleUploadVersion = () => {};
  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = async () => {
    const { data } = await DocumentAPI.getWOP(`${deleteDoc.id}/$canedit`);
    if (data.canedit) {
      setDeleting(true);
      removeDocument(deleteDoc.id);
      DocumentAPI.delete(deleteDoc.id)
        .then(({ data: docData }) => {
          const newAsset = {
            ...asset,
            documents: asset.documents?.filter(
              (item) => item !== docData.reference
            ),
          };
          AssetAPI.patch(asset.id, newAsset, asset)
            .then(({ data: assetData }) => {
              setAsset(assetData);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });

      setAssetDocuments((prev) =>
        prev.filter((item) => item.id !== deleteDoc.id)
      );
      setFilteredDocuments((prev) =>
        prev.filter((item) => item.id !== deleteDoc.id)
      );
      setDeleting(false);
      handleHideDelete();
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be deleted`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleFavoriteClick = (id) => {
    const foundItem = favorites.find((favorite) => {
      return favorite.item.reference.includes(id);
    });
    const foundDoc = filteredDocuments.find((item) => item.id === id);

    if (foundItem) {
      deleteFavorite([foundItem]);
    } else {
      postFavorite(id, "Document");
    }

    if (foundDoc) {
      delete foundDoc.isFavorited;
      addDocument(foundDoc);
      setFilteredDocuments((prev) => {
        return prev.map((item) => {
          if (item.id === foundDoc.id) {
            return foundDoc;
          }
          return item;
        });
      });
    }
  };

  const handleFilter = (val) => {
    setFilterType(filterType === val ? null : val);
  };

  const handleFilesAdded = (fileRef, fileBody) => {
    const currentFile = { isUploading: true, reference: fileRef };
    setAssetDocuments((prev) => [currentFile, ...prev]);
    setFilteredDocuments((prev) => [currentFile, ...prev]);

    if (fileBody) {
      const tempDocument = {
        customName: fileBody.name,
        docType: fileBody.docType,
        contentReference: fileRef,
        asset: `Asset/${assetId}`,
        status: "open",
        lineItems: [],
      };

      if (asset?.project) {
        tempDocument.project = asset?.project;
      } else if (asset?.property) {
        tempDocument.property = asset?.property;
      }

      DocumentAPI.post(tempDocument)
        .then(({ data: newDocument }) => {
          let docResult;
          setFilteredDocuments((prev) => {
            docResult = [
              {
                creator: currentUser,
                isFavorited: fileBody.isFavorited,
                ...newDocument,
              },
              ...prev.filter((item) => item.reference !== fileRef),
            ];
            return docResult;
          });

          setAssetDocuments(docResult);
          setFilteredDocuments(docResult);
          addDocument(newDocument);

          if (fileBody.isFavorited) {
            handleFavoriteClick(newDocument.id);
          }
        })
        .catch((err) => console.error(err));
    }

    setResetDocs(true);
  };

  const getDocType = useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((input) => {
        if (input !== "") {
          const reg = new RegExp(input.toLowerCase());
          setFilteredDocuments(() =>
            assetDocuments.filter(
              (doc) =>
                reg.test(doc?.name?.toLowerCase()) ||
                reg.test(getDocType(doc?.docType)?.toLowerCase())
            )
          );
        } else {
          setFilteredDocuments(assetDocuments);
        }
      }, 700),
    [assetDocuments, getDocType]
  );

  const handleChange = useCallback(
    (input) => {
      setSearchText(() => input);
      debouncedHandleSearch(input);
    },
    [debouncedHandleSearch]
  );

  const onFinishEditing = React.useCallback(
    async (key, val) => {
      const finishedAsset = {
        ...editedAsset,
        [key]: val,
      };
      try {
        const newAsset = await AssetAPI.patch(asset.id, finishedAsset, asset);
        setClonedAsset((prev) => newAsset?.data ?? prev);
      } catch (err) {
        const serverMsg = formatServerErrorMessage(err);
        console.error(serverMsg);
      }
    },
    [editedAsset, asset]
  );

  const handleChangeImage = (image) => {
    const newImage = { file: image, isPrimary: true };
    dispatch({
      type: "addImage",
      image: newImage,
    });

    let tempImages = [];
    if (editedAsset.images?.length) {
      tempImages = editedAsset.images;
    }
    onFinishEditing("images", [
      ...tempImages.map((item) => {
        return { ...item, isPrimary: false };
      }),
      newImage,
    ]);
  };

  const handleUploadClick = () => {
    document.querySelector("#document-folders .document_upload_form")?.click();
  };

  return (
    <div className={`relative ${loading && "loading"} min-h-200`}>
      {!hideHeader && (
        <>
          <SiteHeader
            title={asset?.name}
            dropdownRoutes={
              editedAsset &&
              getAssetOptions(
                assetId,
                propertyId,
                projectId,
                locationId,
                spaceId
              )
            }
            buttons={
              currentUser?.hasPermission?.("document", "can_write") && (
                <PrimaryButton
                  title="+ add document"
                  onClick={handleUploadClick}
                  large
                />
              )
            }
            viewOptions={isShowingTable && <SiteHeaderEditColumns />}
            search={
              <SiteHeaderSearch
                globalFilter={searchText}
                handleSearch={handleChange}
              />
            }
            filter={
              <SiteHeaderListFilter
                currentFilter={filterType}
                filterOptions={documentFilterOptions}
                handleFilter={handleFilter}
                dateFilter
              />
            }
          />

          <DetailViewBanner
            resource={clonedAsset}
            resourceImage={assetImage}
            onChangeImage={handleChangeImage}
            disableUpload={!currentUser?.hasPermission?.("asset", "can_write")}
          />

          <div className="flex items-center justify-between min-w-full">
            <InfoTooltip
              title={createAssetTooltipTitle}
              infoData={createAssetDocuments}
            />
            <SiteHeaderSwitchView
              isShowingTable={isShowingTable}
              setIsShowingTable={setIsShowingTable}
              firstIcon={folderIconGreen}
              firstSelectedIcon={folderIconWhite}
            />
          </div>
        </>
      )}

      <div className="mt-4 pb-10">
        {!loading && !isShowingTable && (
          <div className="flex flex-wrap">
            <div id="document-folders">
              <DocumentUploadForm
                resource={asset}
                onAddFile={handleFilesAdded}
                onAddPhoto={handleFilesAdded}
                onAddVideo={handleFilesAdded}
                showCorner
                small
                disableEditing={
                  !currentUser?.hasPermission?.("asset", "can_write")
                }
              />
            </div>
            {filteredDocuments?.map((doc) => {
              return (
                <div key={doc.id} className="">
                  <FileCard
                    {...doc}
                    docType={getDocType(doc.docType)}
                    createdAt={
                      doc.metadata?.createdAt
                        ? moment(doc.metadata.createdAt).format("MM/DD/YYYY")
                        : "--"
                    }
                    isFavorited={
                      doc.isFavorited
                        ? doc.isFavorited
                        : favorites.some((fav) =>
                            fav.item.reference.includes(doc.id)
                          )
                    }
                    style={fileCardStyle}
                    onFileClick={() => handleFileClick(doc)}
                    onFileEdit={handleFileEdit}
                    onShowHistory={handleShowHistory}
                    onSendToWorkflow={() => handleSendToWorkflow(doc)}
                    onUploadVersion={handleUploadVersion}
                    onFileDelete={handleShowDelete}
                    onFavoriteClick={handleFavoriteClick}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!loading && isShowingTable && (
          <div className="-mt-5">
            <div id="document-folders" className="hidden">
              <DocumentUploadForm
                resource={asset}
                onAddFile={handleFilesAdded}
                onAddPhoto={handleFilesAdded}
                onAddVideo={handleFilesAdded}
                showCorner
                small
                disableEditing={
                  !currentUser?.hasPermission?.("asset", "can_write")
                }
              />
            </div>
            <DocumentTable
              resourceName="asset-document-table"
              documents={filteredDocuments.map((doc) => {
                return {
                  ...doc,
                  resource: doc.name,
                  docType: getDocType(doc.docType),
                  createdBy: getDocumentUser(doc.creator),
                  modifiedBy: getDocumentUser(doc.modifier),
                  modifiedDate: doc.metadata?.lastUpdated
                    ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
                    : "--",
                };
              })}
              favorites={favorites}
              onRowClick={handleRowClick}
              onFileEdit={handleFileEdit}
              onShowHistory={handleShowHistory}
              onSendToWorkflow={handleSendToWorkflow}
              onUploadVersion={handleUploadVersion}
              onFileDelete={handleShowDelete}
              onFavoriteClick={handleFavoriteClick}
              docTypeOptionsMap={docTypeOptionsMap}
              hideSiteHeader={hideHeader}
            />
          </div>
        )}
      </div>
      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={deleting}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
    </div>
  );
};

PureAssetDocuments.propTypes = {
  hideHeader: PropTypes.bool,
  isShowingTable: PropTypes.bool,
  setIsShowingTable: PropTypes.func,
  setButtonActions: PropTypes.func,
};

PureAssetDocuments.defaultProps = {
  hideHeader: false,
  isShowingTable: false,
  setIsShowingTable: undefined,
  setButtonActions: () => {},
};

export default PureAssetDocuments;
