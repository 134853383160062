import { ClientAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useState } from "react";

export default () => {
  const [subscriptionId, setSubscriptionId] = useState();

  const reload = useCallback(async () => {
    const { data } = await ClientAPI.getWOP("$subscriptionid");
    setSubscriptionId(data.subscriptionId);
  }, []);

  useEffect(() => {
    reload();
  }, [reload]);

  return { subscriptionId };
};
