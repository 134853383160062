import React from "react";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  GET_PROPERTY_VIEW_PATH,
  PROPERTY_CREATE_MODAL,
} from "../../../constants";
import PropertyTable from "../../../stories/Components/PropertyTable/PropertyTable";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { useModalState } from "../../../state/modalState";

const PropertyList = () => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();

  const onPropertyRowClick = (id) => {
    history.push(GET_PROPERTY_VIEW_PATH(id));
  };

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Properties"
        buttonTitle="Property"
        customButton={
          currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          ) && (
            <PrimaryButton
              resourceName="Property"
              onClick={() =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: PROPERTY_CREATE_MODAL,
                })
              }
              addButton
            />
          )
        }
      />
      <Widget draggable={false} title={null} overflow>
        <PropertyTable onRowClick={onPropertyRowClick} hideSiteHeaderTitle />
      </Widget>
    </>
  );
};

PropertyList.propTypes = {};

PropertyList.defaultProps = {};

export default PropertyList;
