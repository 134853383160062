/* eslint-disable no-param-reassign */
import Api, { CommentAPI } from "@griffingroupglobal/eslib-api";
import cntl from "cntl";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  APPROVED_TS,
  COA_CODE_TYPES,
  CUMULATIVE_VIEW,
  REJECTED_TS,
} from "../../../../constants";
import { getCSICodeDescription } from "../../../../helpers/Budget";
import { zipTimesheetPatch } from "../../../../helpers/TimeSheet";
import createNonSubmittedTimesheet from "../../../../helpers/Users/createNonSubmittedTimesheet";
import findEmployeesWithoutTimesheets from "../../../../helpers/Users/findEmployeesWithoutTimesheets";
import removeDuplicateRef from "../../../../helpers/Utilities/removeDuplicateRef";
import useApprovalReducer from "../../../../hooks/useApprovalReducer";
import useEmployeeTimesheets from "../../../../hooks/useEmployeeTimeSheets";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import useProjects from "../../../../hooks/useProjects";
import { useUsers } from "../../../../hooks/useUsers.new";
import { useAppState } from "../../../../state/appState";
import whiteCircleCheckIcon from "../../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../../stories/assets/images/whiteExclamationIcon.svg";
import PrimaryButton from "../../../../stories/Components/Buttons/PrimaryButton";
import Input from "../../../../stories/Components/Input/Input";
import Modal from "../../../../stories/Components/Modal/Modal";
import ApprovalTable from "../../../../stories/Components/TimeSheetApprovalTable/ApprovalTable";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PageCN = (financials) => cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mt-3
  mb-12
  ${!financials && "loading"}
`;
const TimeSheetApprovalView = ({ currentUser }) => {
  const [
    {
      timesheetstate: { approvalDate: date },
    },
  ] = useAppState();

  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const { data, isLoading: isLoadingEmployees } = useUsers();
  const {
    timesheets,
    timesheetHistory,
    pto,
    loading,
    periodFrame,
    financials,
    reload,
    reloadHistory,
    updateCache,
  } = useEmployeeTimesheets(undefined, date);

  const [editedTimesheets, dispatch] = useApprovalReducer();

  const { projectDict, projectCodes: rawProjectCodes } = useProjects(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState({});
  const [saving, setSaving] = useState(false);
  const [rejectedModalOpen, setRejectedModalOpen] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState("");
  const warnings = useRef([]);
  const alerts = useRef([]);

  /**
   * Default Settings & Configuration
   */

  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);
  const [csiDict, setCsiDict] = useState({});
  const [cumulativeView, setCumulativeView] = useState(true);
  const [activeView, setActiveView] = useState(CUMULATIVE_VIEW);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    const csiCodesList = {};

    financialsConfiguration?.financials?.csiCodeMapping?.forEach((div) => {
      div.csiCodes.forEach((csi) => {
        csi.subCodes.forEach((sub) => {
          const csiCodeFormatted = {
            label: `${getCSICodeDescription(
              { division: div.division, code: csi.code, subcode: sub.code },
              financialsConfiguration?.financials?.csiCodeMapping,
              revenueAccountingCodes,
              expenseAccountingCodes
            )}`,
            value: `${div.division}-${csi.code}-${sub.code}`,
          };
          csiCodesList[`${div.division}-${csi.code}-${sub.code}`] =
            csiCodeFormatted;
        });
      });
    });

    setCsiDict(csiCodesList);
  }, [
    expenseAccountingCodes,
    financialsConfiguration?.financials?.csiCodeMapping,
    revenueAccountingCodes,
  ]);

  const defaultRatesDict = useMemo(
    () =>
      financialsConfiguration?.financials?.rateSheet?.rates?.reduce(
        (list, item) => {
          list[item?.id] = item?.category;
          return list;
        }
      ),
    [financialsConfiguration?.financials?.rateSheet?.rates]
  );

  /**
   * Default Settings & Configuration
   */

  const projectCodes = useMemo(() => {
    return rawProjectCodes
      ? Object.values(rawProjectCodes)?.reduce((list, item) => {
          item?.codes?.forEach((code) => {
            list[code.key] = code;
          });
          return list;
        }, {})
      : null;
  }, [rawProjectCodes]);

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
      timesheets,
    });
  }, [dispatch, timesheets]);

  useEffect(() => {
    reset();
  }, [reset]);

  const handleApproveRows = useCallback(
    (cumulativeRow) => {
      dispatch({
        type: "approveRows",
        selected: cumulativeRow ?? selectedRows,
      });
    },
    [dispatch, selectedRows]
  );

  const handleRejectRows = useCallback(
    (cumulativeRow) => {
      dispatch({
        type: "rejectRows",
        selected: cumulativeRow ?? selectedRows,
      });
    },
    [dispatch, selectedRows]
  );

  /**
   * @function forwardPairCheck
   * @summary - Recursively traverse timesheets to reconstruct parent sheet
   * @returns children from index to length - 1
   */
  const forwardPairCheck = useCallback((id, index, pairs = [], parent) => {
    const scopedPairs = pairs;
    if (parent[index]?.id?.includes(id)) {
      scopedPairs.push(parent?.[index]);
      if (index + 1 <= parent?.length - 1) {
        return forwardPairCheck(id, index + 1, scopedPairs, parent);
      }
      return scopedPairs;
    }
    return scopedPairs;
  }, []);

  /**
   * @function backwardsPairCheck
   * @summary - Recursively traverse timesheets to reconstruct parent sheet
   * @returns children from index to 0
   */
  const backwardsPairCheck = useCallback((id, index, pairs = [], parent) => {
    const scopedPairs = pairs;
    if (parent[index]?.id?.includes(id)) {
      scopedPairs.push(parent?.[index]);
      if (index - 1 >= 0) {
        return backwardsPairCheck(id, index - 1, scopedPairs, parent);
      }
      return scopedPairs;
    }
    return scopedPairs;
  }, []);

  const prepareEntryPatch = useCallback((sheets, entries) => {
    const values = Object.values(entries)?.reduce((list, item) => {
      list[moment.utc(item.date).format("YYYY-MM-DD")] = true;
      return list;
    }, {});

    const selected = [sheets?.[0]?.id?.split("$=")?.[0]];
    return Object.values(zipTimesheetPatch(sheets, selected))?.map((item) => ({
      ...item,
      id: item?.id?.split("$=")[0],
      entries: item?.entries?.reduce((list, ent) => {
        const key = ent.date.split("T")[0];
        if (values[key]) {
          list.push(ent.id);
        }
        return list;
      }, []),
    }));
  }, []);

  /**
   * @function preparePatch
   * @summary - traverses list of timesheets and reconstructs the parent
   * of selected timesheets to be patched
   */
  const preparePatch = useCallback(
    (sheets, selectedInput) => {
      let patchReady = [];
      let selected = selectedInput ?? selectedRows;
      selected = selected?.map((item) => {
        const key = item?.reference?.split("/")[1];

        patchReady = [
          ...patchReady,
          ...forwardPairCheck(key, item?.index + 1, [], sheets),
          ...backwardsPairCheck(key, item?.index - 1, [], sheets),
          sheets[item?.index],
        ];
        return key;
      });
      return Object.values(zipTimesheetPatch(patchReady, selected)).map(
        (item) => {
          return { id: item?.reference.split("/")[1], entries: item?.entries };
        }
      );
    },
    [backwardsPairCheck, forwardPairCheck, selectedRows]
  );

  const handlePostComment = async (value, association, author) => {
    if (value.trim().length > 0) {
      const tempObj = {
        content: value,
        association,
        author,
      };
      const { data: commentData } = await CommentAPI.post(tempObj);
      return commentData;
    }
    return {};
  };

  const entryActionPatch = useCallback(() => {
    return {
      sheets: timesheets,
      patch: prepareEntryPatch(timesheets, selectedEntries),
    };
  }, [prepareEntryPatch, selectedEntries, timesheets]);

  const rowActionPatch = useCallback(
    (selected, cumulative) => {
      /**
       * Rows passed as arguements ?? rows selected in table
       */
      let rowGroup;

      // Full list of entries passed as param
      if (cumulative) {
        rowGroup = cumulative;
      }

      // Selected Row Passed as param
      else if (selected) {
        rowGroup = selected;
      }

      // Table Selected Rows from component state
      else if (selectedRows) {
        rowGroup = selectedRows;
      }

      return { sheets: timesheets, patch: preparePatch(timesheets, rowGroup) };
    },
    [preparePatch, selectedRows, timesheets]
  );
  /**
   * @param {String} action - Action Performed on timesheet
   * @param {Boolean} entry - Whether action is performed on row/entry
   * @param {Array} selected - One/Many rows passed directly as parameter (for actions on rows not selected within table component)
   * @param {Array} cumulative - One/Many rows associated with the selection
   * @summary - different implementations for patching Rows/Entries
   *
   */

  // TODO: (Parker) Update to RQ when refactoring. (this is slow)
  const submitTimeSheets = useCallback(
    async (action, entry = false, selected, cumulative) => {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      const patches = [SavingDelay];
      setSaving(true);
      const savingToast = toast("Saving...", {
        isLoading: true,
        position: "top-center",
      });
      let method = "$edit";
      /**
       * Eager Update Table
       */
      switch (action) {
        case "rejected":
          handleRejectRows(cumulative);
          method = "$decline";
          break;
        case "approved":
          handleApproveRows(cumulative);
          method = "$approve";
          break;
        default:
      }
      /**
       * Eager Update Table
       */

      /**
       * Prepare Timesheets
       * Note: Separate functions for user focused view(entry) vs all employees
       */

      let preparedTimeSheets;

      if (entry) {
        preparedTimeSheets = entryActionPatch();
      } else {
        preparedTimeSheets = rowActionPatch(selected, cumulative);
      }

      /**
       * Prepare Timesheets
       */

      /**
       * Prepare Patch(s)
       */
      preparedTimeSheets?.patch.forEach((item) => {
        patches.push(async () => {
          return Api.post(`/api/Timesheet/${item?.id}/${method}`, {
            entries:
              ["$approve", "$decline"].includes(method) && !entry
                ? []
                : item?.entries,
          });
        });
      });

      if (action === "rejected" && preparedTimeSheets?.patch?.length === 1) {
        patches.push(async () =>
          handlePostComment(
            rejectedMessage,
            `Timesheet/${preparedTimeSheets?.patch?.[0]?.id}`,
            currentUser?.reference
          )
        );
      }
      const arrayOfPatches = patches?.map((patch) => patch());

      /**
       * Prepare Patch(s)
       */

      Promise.all(arrayOfPatches)
        .then(() => {
          toast.update(savingToast, {
            isLoading: false,
            render: "Saved Timesheet",
            closeButton: toastCloseIcon,
            className: "bg-brandGreen text-white",
            hideProgressBar: true,
            position: "top-center",
            icon: toastIcon,
            autoClose: 3000,
          });
          /**
           * Remove Alerts If Approved
           */
          if (action === "approved") {
            const removeAlerts = selected ?? selectedRows;
            removeAlerts?.forEach((row) => {
              alerts.current = alerts.current?.filter(
                (alert) => alert !== row?.id
              );
            });
          }
          updateCache(preparedTimeSheets?.sheets);
          reloadHistory();
          setSaving(false);
        })
        .catch((err) => {
          toast.update(savingToast, {
            isLoading: false,
            render: `${err}`,
            style: {
              backgroundColor: "#BC2727",
              color: "white",
            },
            closeButton: toastCloseIcon,
            position: "top-center",
            hideProgressBar: true,
            icon: toastErrorIcon,
            autoClose: 3000,
          });
          reset();
          setSaving(false);
        });
    },
    [
      currentUser?.reference,
      entryActionPatch,
      handleApproveRows,
      handleRejectRows,
      rejectedMessage,
      reloadHistory,
      reset,
      rowActionPatch,
      selectedRows,
      updateCache,
    ]
  );

  const handleApproveRow = useCallback(
    (id) => {
      const foundRow = editedTimesheets.find((row) => row.id === id);
      dispatch({
        type: "approveRow",
        index: foundRow?.index,
      });
      setSelectedRows([foundRow]);
      submitTimeSheets("approved", false, [foundRow]);
    },
    [dispatch, editedTimesheets, submitTimeSheets]
  );

  const handleInitRejectRow = (id) => {
    const foundRow = editedTimesheets.find((row) => row.id === id);
    setSelectedRows([foundRow]);
    setRejectedModalOpen(true);
  };

  const handleRejectedModalClose = () => {
    setSelectedRows([]);
    setRejectedModalOpen(false);
    setRejectedMessage("");
  };

  const handleRejectRow = useCallback(() => {
    dispatch({
      type: "rejectRow",
      index: selectedRows?.[0]?.index,
    });
    setRejectedModalOpen(false);
    setRejectedMessage("");
    submitTimeSheets("rejected");
  }, [dispatch, selectedRows, submitTimeSheets]);

  const handleApprovalEntries = useCallback(
    (value) => {
      dispatch({
        type: "editEntries",
        entries: selectedEntries,
        value,
      });
      submitTimeSheets(value, true);
      setSelectedEntries({});
    },
    [dispatch, selectedEntries, submitTimeSheets]
  );

  const statusRollup = useMemo(() => {
    const rollup = editedTimesheets?.reduce((list, item) => {
      Object.values(item?.entries)?.forEach((entry) => {
        list.push(entry?.status);
        list.push(item?.status);
      });
      return list;
    }, []);
    if (rollup.includes("rejected")) {
      return "rejected";
    }
    /**
     * Approved With Changes
     * - No Entries are open,rejected,or submitted
     */
    if (
      rollup.includes("approved-with-changes") &&
      !rollup.includes("open") &&
      !rollup.includes("rejected") &&
      !rollup.includes("submitted")
    ) {
      return "approved-with-changes";
    }
    if (rollup.includes("locked")) {
      return "payrollApproved";
    }
    // - all are approved then rollup is approved
    if (rollup.every((s) => s === "approved")) {
      return "approved";
    }

    return "open";
  }, [editedTimesheets]);

  /**
   * @summary - grouping rows by user edge case
   * - only one row is selected by table component
   * - must add associated rows to selection for patch
   */
  const cumulativeSelectedSheets = useMemo(() => {
    const cumulativeSheets = [];
    selectedRows?.forEach(({ reference }) => {
      timesheets?.forEach((sheet) => {
        if (sheet.id.includes(reference?.split("/")[1])) {
          cumulativeSheets.push(sheet);
        }
      });
    });
    return cumulativeView ? cumulativeSheets : null;
  }, [cumulativeView, selectedRows, timesheets]);

  const customSiteHeader = useCallback(
    (props) => {
      return (
        <div className="flex flex-col w-full gap-5 pt-1 pb-3">
          <div className="flex justify-between items-center">
            {props?.datePicker}
            <PrimaryButton
              title={
                selectedRows.length <= 0
                  ? "Actions"
                  : `(${selectedRows?.length}) Selected`
              }
              large
              dropdownItems={[
                {
                  title: "Approve",
                  onClick: () =>
                    submitTimeSheets(
                      APPROVED_TS,
                      false,
                      undefined,
                      cumulativeSelectedSheets
                    ),
                },
                {
                  title: "Reject",
                  onClick: () =>
                    submitTimeSheets(
                      REJECTED_TS,
                      false,
                      undefined,
                      cumulativeSelectedSheets
                    ),
                },
              ]}
              disabled={selectedRows.length <= 0 || saving || loading}
              className="whitespace-nowrap"
              onClick={() => submitTimeSheets()}
            />
          </div>
          <div className="flex gap-4">
            {props?.viewOptions}
            {props?.search}
          </div>
        </div>
      );
    },
    [
      cumulativeSelectedSheets,
      loading,
      saving,
      selectedRows.length,
      submitTimeSheets,
    ]
  );

  const memoizedEditedTimesheets = useMemo(() => {
    if (isLoadingEmployees || loading) {
      return [];
    }
    const emplyeesWithoutTimesheets = findEmployeesWithoutTimesheets(
      data?.employees,
      editedTimesheets
    );

    const convertedToTimesheets = emplyeesWithoutTimesheets.map((employee) =>
      createNonSubmittedTimesheet(employee)
    );

    const tsWithoutDuplicates = removeDuplicateRef(editedTimesheets);

    const newArr = [...tsWithoutDuplicates, ...convertedToTimesheets];

    const arrToReturn = newArr?.map((sheet, index) => {
      return {
        ...sheet,
        index,
      };
    });

    return arrToReturn;
  }, [isLoadingEmployees, loading, data?.employees, editedTimesheets]);

  return (
    <div className={PageCN(financials)}>
      {/* Note: wait until financials are fetched to limit rerenders */}
      {financials && (
        <ApprovalTable
          timesheets={memoizedEditedTimesheets}
          ptoSheets={pto}
          historyData={timesheetHistory}
          loading={loading}
          dateQuery={date}
          periodFrame={periodFrame}
          financials={financials}
          projects={projectDict}
          projectCodes={projectCodes}
          defaultCsiDict={csiDict}
          defaultRates={defaultRatesDict}
          currentUser={currentUser}
          warnings={warnings}
          alerts={alerts}
          userSheetStatus={statusRollup}
          reload={reload}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          handleApproveRow={handleApproveRow}
          handleRejectRow={handleInitRejectRow}
          handleApprovalEntries={handleApprovalEntries}
          submitCumulativeRow={submitTimeSheets}
          customSiteHeader={customSiteHeader}
          activeView={activeView}
          setActiveView={setActiveView}
          setCumulativeView={setCumulativeView}
        />
      )}
      <Modal
        title="Send Back Time Sheet?"
        isOpen={rejectedModalOpen}
        onRequestModalClose={handleRejectedModalClose}
        hideFooter
        alert
        primaryButtonTitle="Send"
        primaryButtonOnClick={handleRejectRow}
      >
        <div className="flex flex-col">
          <p className="text-center mb-4">
            {`The Time Sheet will be sent back to ${selectedRows?.[0]?.employee?.fullName} for revision.`}
          </p>
          <Input
            isTextarea
            placeholder="Message"
            value={rejectedMessage}
            onChange={(val) => setRejectedMessage(val)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TimeSheetApprovalView;
