import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import Widget from "../../../stories/Components/Widget/Widget";
import WidgetEditorView from "../../../stories/Components/WidgetEditorView";
import FaqListNew from "../../../stories/Components/Help/FaqListNew";
import { CONTACT_US_PATH } from "../../../constants";

const categoryStyle = {
  fontSize: "24px;",
  lineHeight: "32px;",
  letterSpacing: "1.125px;",
  textAlign: "left;",
};

const HelpSingleCategoryView = ({ category, helpData, setCategoryView }) => {
  const history = useHistory();
  return (
    <div className="border rounded-md p-2">
      <h1
        style={categoryStyle}
        className="text-xl font-semibold ml-2 mt-3 mb-5"
      >
        {category}
      </h1>
      <WidgetEditorView
        className="mb-4"
        showBackButton
        hideFooter
        hideBorder
        onBackPressed={() => {
          setCategoryView(null);
        }}
      >
        <Widget title="Tutorial Videos" draggable={null} className="mb-4">
          {/* ***** TODO: implement tutorial videos thumbnails ***** */}
          <div className="flex items-center justify-start">
            <p>Tutorial videos coming soon!</p>
          </div>
        </Widget>

        <Widget draggable={null} title="Frequently Asked Questions">
          <FaqListNew items={helpData} />
        </Widget>
      </WidgetEditorView>

      <div className="flex flex-col ml-3 mb-5">
        <p className="font-semibold">
          Still can’t find what your looking for?{" "}
        </p>
        <p>
          <button
            type="button"
            onClick={() => history.push(CONTACT_US_PATH)}
            className="text-brandDarkGreen font-semibold hover:underline cursor-pointer"
          >
            Contact Us
          </button>{" "}
          to speak with a real human.
        </p>
      </div>
    </div>
  );
};

HelpSingleCategoryView.defaultProps = {
  category: "",
  helpData: [{}],
  setCategoryView: () => {},
};

HelpSingleCategoryView.propTypes = {
  category: PropTypes.string,
  helpData: PropTypes.arrayOf(PropTypes.shape({})),
  setCategoryView: PropTypes.func,
};

export default HelpSingleCategoryView;
