import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { useAssetSwitchView } from "../../../hooks/useSwitchView";
import {
  ADD_OPEN_MODAL,
  GET_ASSET_VIEW_PATH,
  GET_LOCATION_ASSET_VIEW_PATH,
  GET_LOCATION_SPACE_ASSET_VIEW_PATH,
  GET_PROJECT_ASSET_PATH,
} from "../../../constants";
import ProjectAssetCardGrid from "../../../stories/Components/AssetCardGrid/ProjectAssetCardGrid";
import AssetTable from "../../../stories/Components/AssetTable/AssetTable";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useAssets from "../../../hooks/useAssets";
import { useAppState } from "../../../state/appState";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import InnerPageSwitcher from "../../../stories/Components/InnerPageSwitcher/InnerPageSwitcher";
import { useModalState } from "../../../state/modalState";

const ProjectAssetList = ({
  associatedResource,
  siteHeaderProps,
  bannerComponent,
  locationId,
  spaceId,
}) => {
  const { pathname: currentRoute } = useLocation();
  const history = useHistory();
  const [{ users }] = useAppState();
  const { projectId, propertyId, assetId } = useParams();
  const [originalAssets, reload] = useAssets({
    projectRef: associatedResource,
    locationId,
    spaceId,
  });
  const [, modalDispatch] = useModalState();

  const { data: managementConfiguration } = useManagementConfiguration();
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptionsMap, setSubCatOptionsMap] = useState({});
  const [isShowingTable] = useAssetSwitchView(false);

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCatOptions(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubCatOptionsMap(subcatMap);
    }
  }, [managementConfiguration]);

  const onAssetRowClick = (id) => {
    const view = projectId && projectId[0];
    const ref = projectId && projectId[1];
    if (view && view?.toLowerCase() === "project") {
      if (spaceId) {
        history.replace(
          GET_LOCATION_SPACE_ASSET_VIEW_PATH.project(
            ref,
            locationId,
            spaceId,
            id
          )
        );
      } else if (locationId) {
        history.replace(
          GET_LOCATION_ASSET_VIEW_PATH.project(ref, locationId, id)
        );
      } else {
        history.replace(GET_PROJECT_ASSET_PATH(ref, id));
      }
    } else {
      history.push(GET_ASSET_VIEW_PATH(id));
    }
  };

  const headerOnClick = () =>
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalData: {
        item: {
          disableAssociation: projectId || propertyId,
          propertyId,
          projectId,
          assetId,
          locationId,
          spaceId,
        },
      },
      ref: { id: uuidv4() },
    });

  return (
    <>
      {isShowingTable && (
        <>
          <PrimaryHeaderItems
            headerTitle={siteHeaderProps?.title}
            buttonTitle="Asset"
            onClick={headerOnClick}
          />
          <InnerPageSwitcher
            innerRoutes={siteHeaderProps?.dropdownRoutes}
            currentPath={currentRoute}
            history={history}
          />
          <AssetTable
            originalAssets={originalAssets}
            categoryOptionsList={catOptions}
            subCatOptionsMap={subCatOptionsMap}
            onRowClick={onAssetRowClick}
            projectRef={associatedResource}
            bannerComponent={bannerComponent}
            locationId={locationId}
          />
        </>
      )}
      {!isShowingTable && (
        <>
          <PrimaryHeaderItems
            headerTitle={siteHeaderProps?.title}
            buttonTitle="Asset"
            onClick={headerOnClick}
          />
          <InnerPageSwitcher
            innerRoutes={siteHeaderProps?.dropdownRoutes}
            currentPath={currentRoute}
            history={history}
          />
          <Widget title={null} draggable={false} overflow>
            <ProjectAssetCardGrid
              users={users}
              reload={reload}
              categoryOptionsList={catOptions}
              subCatOptionsMap={subCatOptionsMap}
              onAssetClick={onAssetRowClick}
              siteHeaderProps={siteHeaderProps}
              projectRef={associatedResource}
              bannerComponent={bannerComponent}
              locationId={locationId}
              spaceId={spaceId}
              hideSiteHeaderTitle
            />
          </Widget>
        </>
      )}
    </>
  );
};

ProjectAssetList.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,
  /**
   * alter siteHeader props
   */
  // eslint-disable-next-line react/forbid-prop-types
  siteHeaderProps: PropTypes.object,

  bannerComponent: PropTypes.node,
  locationId: PropTypes.string,
  spaceId: PropTypes.string,
};

ProjectAssetList.defaultProps = {
  associatedResource: undefined,
  siteHeaderProps: {
    title: "ProjectAssets",
    dropdownRoutes: undefined,
  },
  bannerComponent: undefined,
  locationId: undefined,
  spaceId: undefined,
};

export default ProjectAssetList;
