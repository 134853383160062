import React from "react";
import "./NotificationTray.css";
import Checkbox from "../Checkbox/Checkbox";
import useTrayNotificationData from "./useTrayNotificationData";
import TrayNotificationFlag from "./TrayNotificationFlag";

const TrayNotification = ({
  reference,
  title,
  created,
  read,
  flagged,
  type,
  handleSelect,
  selected: selectedNotifs,
  close,
  ...rest
}) => {
  const { selected, createdAt, onClick, ellipsedTitle } =
    useTrayNotificationData({
      reference,
      selectedNotifs,
      flagged,
      type,
      title,
      close,
      ...rest,
    });

  return (
    <li className="grid grid-cols-8 px-5 pt-6 items-start" key={reference}>
      <Checkbox
        size="6"
        className="col-span-1"
        checked={!!selected}
        onChange={() =>
          handleSelect({
            reference,
            title,
            created,
            read,
            flagged,
            type,
            ...rest,
          })
        }
      />
      <button
        id="noti-title"
        className={`flex flex-col gap-2  text-sm ${
          read ? "text-gray-450" : "font-semibold text-gray-500"
        } col-span-6 noti-tray-notification text-left pr-2`}
        onClick={onClick}
        type="button"
        title={title}
      >
        <span>{ellipsedTitle}</span>
        <span className="text-xs font-normal block hover:no-underline">
          {createdAt(created)}
        </span>
      </button>
      <TrayNotificationFlag flagged={flagged} type={type} />
    </li>
  );
};

export default TrayNotification;
