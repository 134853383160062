import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import useClientSubscriptionId from "./useClientSubscriptionId";
import SignUpAPI from "../Pages/SignUp/SignUpAPI";
import { formatCountriesDropdown } from "../helpers/Address";

const countryList = require("country-list");

const useSubscription = () => {
  const { subscriptionId } = useClientSubscriptionId();
  const [subscription, setSubscription] = useState();

  const reload = useCallback(async () => {
    try {
      if (subscriptionId) {
        const countries = countryList.getNameList();
        const countrieslist = formatCountriesDropdown(countries);

        const sub = await SignUpAPI.getSubscription(subscriptionId);
        const customer = await SignUpAPI.getCustomer(sub?.customer);
        const paymentCard = await SignUpAPI.getPaymentCard(sub?.customer);
        const product = await SignUpAPI.getProduct(sub?.plan?.product);

        const planName = sub?.plan?.id.split("-")[0];
        const capitalizedPlanName =
          planName.charAt(0).toUpperCase() + planName.slice(1);

        const countryLabel =
          countrieslist.find(
            (item) => item.countryCode === customer?.address?.country
          )?.label || customer?.address?.country;

        const subDetails = {
          subscriptionId,
          customerId: sub?.customer,
          subscriptionStatus: sub.status,
          subscriptionItem: sub?.items?.data[0]?.id,
          contactFirstName: customer.name.split(" ")[0],
          contactLastName: customer.name.split(" ")[1],
          contactTitle: customer.metadata?.title,
          contactCompany: customer.metadata?.company,
          contactEmail: customer.email,
          contactPhone: customer.phone,
          nameOnCard: paymentCard?.data[0]?.billing_details?.name,
          cardType: paymentCard?.data[0]?.card?.brand,
          billingCountry: {
            label: countryLabel,
            value: countryLabel,
          },
          billingStreet: customer?.address?.line1 || "",
          billingStreet2: customer?.address?.line2,
          billingCity: customer?.address?.city,
          billingState: customer?.address?.state,
          billingZipCode: customer?.address?.postal_code,
          userCount: sub.quantity,
          billingCycle: sub.plan.interval === "month" ? "monthly" : "annual",
          nextPaymentDate: moment.unix(sub.current_period_end),
          nextPayment: (
            Number.parseFloat(sub?.plan?.amount / 100) * sub.quantity
          ).toFixed(2),
          planName: capitalizedPlanName,
          userText: product.description,
          minUsers: product.metadata.minUsers,
          maxUsers: product.metadata.maxUsers,
          monthlyCost: Number.parseFloat(
            product.prices.find((price) => price.recurring.interval === "month")
              .unit_amount / 100
          ),
          annualCost: Number.parseFloat(
            product.prices.find((price) => price.recurring.interval === "year")
              .unit_amount /
              (100 * 12)
          ),
          discount: product.metadata.discount,
          features: product.metadata.features.split(","),
        };

        setSubscription(subDetails);
      }
    } catch (error) {
      console.error("❌ useSubscription", error);
    }
  }, [subscriptionId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [subscription, reload];
};

export default useSubscription;
