const rearrangeTotalsToEnd = (array) => {
  // Define the order in which items should be moved to the end
  const orderToEnd = [
    "Total Hours",
    "Total Hours Non-Billable",
    "Total Hours Billable",
  ];

  // Filter the array to first contain items that are not in the 'orderToEnd'
  const newArr = array.filter((item) => !orderToEnd.includes(item));

  // Then append the items in the 'orderToEnd' if they existed in the original array
  orderToEnd.forEach((item) => {
    if (array.includes(item)) {
      newArr.push(item);
    }
  });

  return newArr;
};

export default rearrangeTotalsToEnd;
