// Importing required modules and hooks
import { TaskAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
  SET_TASKS,
  SET_TASKS_DICT,
  SET_USER_EVENTS,
  ALL_EVENT_INSTANCES,
  SINGLE_EVENT_INSTANCE,
  MOMENT_UTC_ES_FORMAT,
  UPDATE_GLOBAL_STATE,
  UNFORMATTED_EVENTS_ACTIONS,
  MOMENT_CALENDAR_FORMAT,
} from "../constants";
import { isFullDay } from "../helpers/Calendar";
import returnRecurrenceDates from "../helpers/returnRecurrenceDates";
import { useAppState } from "../state/appState";
import formatUrlResource from "../helpers/Format/formatUrlResource";
import { taskKeys } from "../config/reactQuery/queryKeyFactory";
import getAssetForSpaceLookupTable from "../helpers/Calendar/getAssetForSpaceLookupTable";
import updateUnformattedEvents from "../helpers/Calendar/updateUnformattedEvents";
import formatTasks, {
  formattedTaskStatus,
} from "../helpers/ServiceRequest/formatTasks";
import getEndDateForInstance from "../helpers/Calendar/getEndDateForInstance";

// Custom hook to manage tasks
const useEsTasks = () => {
  const queryClient = useQueryClient();
  // Get tasks dictionary and appStateDispatch method from global app state
  const [
    { tasksDict, userEvents, assetsDict, tasks, unformattedEvents },
    appStateDispatch,
  ] = useAppState();

  /**
   * @param {String} association P/P Reference
   * @returns {associatedTasks, associatedTaskDict}
   */
  const getTaskByAssociation = async (association) => {
    const associatedTasks = [];
    let associatedTaskDict = {};
    try {
      const response = await TaskAPI.getWOP(
        `overview?association=${association}`
      );
      response?.data?.entries.forEach((item) => {
        const taskItem = { ...formatTasks(item.resource) };
        taskItem.formattedStatus = formattedTaskStatus(item.resource.status);
        associatedTaskDict = {
          ...associatedTaskDict,
          [taskItem.id]: taskItem,
        };
        associatedTasks.push(taskItem);
      });
    } catch (e) {
      console.warn(e, "useEsTasks.js, line 48");
    }
    return { associatedTasks, associatedTaskDict };
  };

  const moveTaskDate = useCallback(
    ({ priorTaskDate, newTask }) => {
      const userEventsCopy = JSON.parse(JSON.stringify(userEvents));

      const timeStampToRemove = moment(priorTaskDate)
        .local()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");

      const timeStampToAdd = moment(newTask.startDate)
        .local()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");

      const dayWithItemRemoved = {
        ...userEventsCopy[timeStampToRemove],
        allDay: userEventsCopy[timeStampToRemove].allDay.filter(
          (item) => item.id !== newTask.id
        ),
        brief: userEventsCopy[timeStampToRemove].brief.filter(
          (item) => item.id !== newTask.id
        ),
      };

      userEventsCopy[timeStampToRemove] = dayWithItemRemoved;

      const isTaskAllDay = isFullDay(
        newTask.startDate,
        newTask.endDate,
        newTask.allDay
      );

      const dayWithItemAdded = {
        allDay: [],
        brief: [],
        ...userEventsCopy[timeStampToAdd],
      };

      if (isTaskAllDay) {
        dayWithItemAdded.allDay.push(newTask);
      }

      if (!isTaskAllDay) {
        dayWithItemAdded.brief.push(newTask);
      }

      userEventsCopy[timeStampToAdd] = dayWithItemAdded;

      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: userEventsCopy,
      });

      updateUnformattedEvents({
        appStateDispatch,
        unformattedEvents,
        item: newTask,
        action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE,
      });

      if (newTask.instanceStartDate) {
        appStateDispatch({
          type: SET_TASKS_DICT,
          tasksDict: {
            ...tasksDict,
            [`${newTask.id}&instanceStartDate=${newTask.instanceStartDate}`]:
              newTask,
          },
        });
      } else {
        appStateDispatch({
          type: SET_TASKS_DICT,
          tasksDict: { ...tasksDict, [newTask.id]: newTask },
        });
      }
    },
    [appStateDispatch, tasksDict, unformattedEvents, userEvents]
  );

  // Callback to add a new task to the tasks dictionary
  const addToTaskDictionary = useCallback(
    (task) => {
      if (!task.recurrence) {
        const timeStamp = moment(task.startDate)
          .local()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ");

        const dayCopy = { ...userEvents[timeStamp] };
        const isAllDayItem = isFullDay(
          task.startDate,
          task.endDate,
          task.allDay
        );
        const newAllDay = dayCopy?.allDay ? [...dayCopy?.allDay] : [];
        const newBrief = dayCopy?.brief ? [...dayCopy?.brief] : [];

        if (isAllDayItem) {
          newAllDay.push(task);
        }

        if (!isAllDayItem) {
          newBrief.push(task);
        }

        dayCopy.allDay = newAllDay;
        dayCopy.brief = newBrief;

        appStateDispatch({
          type: UPDATE_GLOBAL_STATE,
          payload: {
            userEvents: { ...userEvents, [timeStamp]: dayCopy },
            tasksDict: { ...tasksDict, [task.id]: task },
            tasks: [...tasks, task],
          },
        });

        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: task,
          action: UNFORMATTED_EVENTS_ACTIONS.ADD_SINGLE,
        });
      } else {
        const { recurrence } = task;
        const userEventsUpdates = {};
        const taskMapUpdates = {};
        const tasksArrUpdates = [];

        const recurrenceDates = returnRecurrenceDates({ recurrence });

        recurrenceDates.forEach((date) => {
          // Convert the date to the beginning of the day
          const dateBeginningOfDay = moment(date)
            .startOf("day")
            .format(MOMENT_CALENDAR_FORMAT);
          // Get the calendar day for the beginning of the day
          const calendarDay = userEvents[dateBeginningOfDay] || {};
          // Check if the task is an all-day item
          const isAllDayItem = isFullDay(
            task.startDate,
            task.endDate,
            task.allDay
          );

          // Clone existing arrays or create new ones if they don't exist
          const newAllDay = calendarDay.allDay ? [...calendarDay.allDay] : [];
          const newBrief = calendarDay.brief ? [...calendarDay.brief] : [];

          // Convert the date to UTC beginning
          const dateUtcBeginning = moment
            .utc(date)
            .format(MOMENT_UTC_ES_FORMAT);

          const newEndDate = getEndDateForInstance(task, date);

          // Add the task to the appropriate array

          const updatedTask = {
            ...task,
            instanceStartDate: dateUtcBeginning,
            startDate: dateUtcBeginning,
            endDate: newEndDate,
          };

          if (isAllDayItem) newAllDay.push(updatedTask);
          else newBrief.push(updatedTask);

          // Update the calendar day properties
          calendarDay.allDay = newAllDay;
          calendarDay.allDayCount = newAllDay.length;
          calendarDay.brief = newBrief;

          // Store the updated calendar day in userEventsUpdates
          userEventsUpdates[dateBeginningOfDay] = calendarDay;
          // Store the updated task in taskMapUpdates
          taskMapUpdates[
            `${updatedTask.id}&instanceStartDate=${updatedTask.instanceStartDate}`
          ] = updatedTask;

          tasksArrUpdates.push(updatedTask);
        });

        appStateDispatch({
          type: UPDATE_GLOBAL_STATE,
          payload: {
            userEvents: { ...userEvents, ...userEventsUpdates },
            tasksDict: { ...tasksDict, ...taskMapUpdates },
            tasks: [...tasks, ...tasksArrUpdates],
          },
        });

        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          items: tasksArrUpdates,
          action: UNFORMATTED_EVENTS_ACTIONS.ADD_RECURRING,
        });
      }
    },
    [appStateDispatch, tasks, tasksDict, unformattedEvents, userEvents]
  );

  const deleteTaskAndAllRecurrences = useCallback(
    async (task) => {
      const deleteRequestString = `${task.id}/$${ALL_EVENT_INSTANCES}`;

      try {
        await TaskAPI.delete(deleteRequestString);

        const recurrenceDatesForDeletion = returnRecurrenceDates({
          recurrence: task.recurrence,
        });

        const userEventsUpdates = {};
        const newTasksDict = { ...tasksDict };
        let newTasks = [...tasks];

        newTasks = newTasks.filter((item) => {
          return item.id !== task.id;
        });

        recurrenceDatesForDeletion.forEach((date) => {
          // Convert the date string to a moment object.
          const dateObj = moment(date);
          // Set the time of the date object to the start of the day.
          dateObj.startOf("day");

          // Format the date object for further processing.
          const formattedDate = dateObj.format("YYYY-MM-DDTHH:mm:ssZ");
          // Retrieve events associated with the formatted date.
          const dayToReview = userEvents[formattedDate];

          // delete from dict
          delete newTasksDict[
            `${task.id}&instanceStartDate=${moment(date)
              .utc()
              .format(MOMENT_UTC_ES_FORMAT)}`
          ];

          // If there are no events for the day, return.
          if (!dayToReview) return;

          // Filter the all-day events, excluding the one being deleted.
          const newAllDay = dayToReview?.allDay.filter((item) => {
            // Exclude the event if its ID matches the one being deleted.
            if (item.id === task.id) {
              return false;
            }
            return true;
          });

          // Filter the brief events, excluding the one being deleted.
          const newBrief = dayToReview?.brief.filter((item) => {
            // Exclude the event if its ID matches the one being deleted.
            if (item.id === task?.id) {
              return false;
            }
            return true;
          });

          // Construct the updated event data for the day.
          const newDayAfterReview = {
            ...dayToReview,
            allDayCount: newAllDay.length,
            allDay: newAllDay,
            brief: newBrief,
          };

          // Store the updated event data in the user events update object.
          userEventsUpdates[formattedDate] = newDayAfterReview;
        });

        // appStateDispatch the updated events to the application state.
        appStateDispatch({
          type: UPDATE_GLOBAL_STATE,
          payload: {
            userEvents: { ...userEvents, ...userEventsUpdates },
            tasks: newTasks,
            tasksDict: newTasksDict,
          },
        });

        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: task,
          action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_RECURRING,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    [appStateDispatch, unformattedEvents, userEvents, tasks, tasksDict]
  );

  const deleteTaskFromDictionary = useCallback(
    (task) => {
      const newTasksDict = { ...tasksDict };
      let newTasks = [...tasks];

      if (task?.instanceStartDate) {
        delete newTasksDict[
          `${task.id}&instanceStartDate=${task.instanceStartDate}`
        ];
        newTasks = newTasks.filter((item) => {
          const found =
            item.id === task.id &&
            item.instanceStartDate === task.instanceStartDate;
          return !found;
        });
      } else {
        delete newTasksDict[task.id];
        newTasks = newTasks.filter((item) => item.id !== task.id);
      }

      // Calculate the timestamp for the task's start date
      const timeStamp = moment(task.startDate)
        .local()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");

      appStateDispatch({
        type: UPDATE_GLOBAL_STATE,
        payload: {
          tasks: newTasks,
          tasksDict: newTasksDict,
        },
      });

      // only delete the task from the user events if it is not a recurring task
      // recurring tasks are deleted from the the user events when the recurrence deleted popup
      if (!task?.recurrence) {
        const dayCopy = { ...userEvents[timeStamp] };

        // Update the 'allDay' array by removing the deleted task
        const newAllDay =
          userEvents?.[timeStamp].allDay.filter(
            (item) => item.id !== task.id
          ) || [];

        // Update the 'brief' array by removing the deleted task
        const newBrief =
          userEvents?.[timeStamp].brief.filter((item) => item.id !== task.id) ||
          [];

        // Update the 'allDay' and 'brief' arrays in the day's copy
        dayCopy.allDay = newAllDay;
        dayCopy.brief = newBrief;

        // appStateDispatch an action to update user events with the edited day copy
        appStateDispatch({
          type: SET_USER_EVENTS,
          userEvents: { ...userEvents, [timeStamp]: dayCopy },
        });

        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: task,
          action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE,
        });
      }
    },
    [appStateDispatch, tasksDict, unformattedEvents, userEvents, tasks]
  );

  // Callback to edit an existing task in the tasks dictionary
  // !NOTE this doesn't work for a task in recurrence
  const editTaskInDictionary = useCallback(
    (task, originalTask) => {
      // Update the task in the tasks dictionary

      if (task.instanceStartDate) {
        const indexOfItem = tasks.findIndex(
          (item) =>
            item.id === task.id &&
            item.instanceStartDate === task.instanceStartDate
        );

        const newTasks = [...tasks];
        newTasks[indexOfItem] = task;

        const newTasksDict = { ...tasksDict };

        newTasksDict[`${task.id}&instanceStartDate=${task.instanceStartDate}`] =
          task;

        // update remaining task instances metadata
        const remainingInstancesIndexes = newTasks.reduce(
          (acc, existingTask, index) => {
            if (
              existingTask.id === task.id &&
              existingTask.instanceStartDate !== task.instanceStartDate
            ) {
              acc.push({
                index,
                instanceStartDate: existingTask.instanceStartDate,
              });
            }
            return acc;
          },
          []
        );

        remainingInstancesIndexes?.forEach(({ index, instanceStartDate }) => {
          const updatedInstance = {
            ...newTasks[index],
            metadata: task.metadata,
          };
          newTasks[index] = updatedInstance;

          newTasksDict[`${task.id}&instanceStartDate=${instanceStartDate}`] =
            updatedInstance;
        });

        appStateDispatch({
          type: UPDATE_GLOBAL_STATE,
          payload: {
            tasks: newTasks,
            tasksDict: newTasksDict,
          },
        });
      } else {
        const indexOfItem = tasks.findIndex((item) => item.id === task.id);
        const newTasks = [...tasks];

        newTasks[indexOfItem] = task;

        appStateDispatch({
          type: UPDATE_GLOBAL_STATE,
          payload: {
            tasksDict: { ...tasksDict, [task.id]: task },
            tasks: newTasks,
          },
        });
      }

      if (originalTask) {
        const userEventsUpdates = { ...userEvents };

        const originalTaskStartDate = moment(originalTask.startDate).format();
        const editedTaskStartDate = moment(task.startDate).format();

        const sameDay = moment(originalTaskStartDate).isSame(
          editedTaskStartDate,
          "day"
        );

        if (!sameDay) {
          const originalStartOfDay = moment(originalTaskStartDate)
            .local()
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ssZ");

          const editedStartOfDay = moment(editedTaskStartDate)
            .local()
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ssZ");

          // If the start date has changed, remove the task from the original start date
          const originalDayCopy = { ...userEventsUpdates[originalStartOfDay] };

          // Update the 'allDay' array by removing the edited task
          const newAllDay = userEventsUpdates[originalStartOfDay].allDay.filter(
            (item) => item.id !== task.id
          );

          // Update the 'brief' array by removing the edited task
          const newBrief = userEventsUpdates[originalStartOfDay].brief.filter(
            (item) => item.id !== task.id
          );

          // Update the 'allDay' and 'brief' arrays in the day's copy
          originalDayCopy.allDay = newAllDay;
          originalDayCopy.brief = newBrief;

          userEventsUpdates[originalStartOfDay] = originalDayCopy;

          // If the start date has changed, add the task to the new start date
          const newDayCopy = {
            ...(userEventsUpdates[editedStartOfDay] || {
              allDay: [],
              brief: [],
            }),
          };

          const isAllDayItem = isFullDay(
            task.startDate,
            task.endDate,
            task.allDay
          );

          if (isAllDayItem) {
            newDayCopy.allDay.push(task);
          }

          if (!isAllDayItem) {
            newDayCopy.brief.push(task);
          }

          userEventsUpdates[editedStartOfDay] = newDayCopy;

          // appStateDispatch an action to update user events with the edited day copy
          appStateDispatch({
            type: SET_USER_EVENTS,
            userEvents: userEventsUpdates,
          });

          updateUnformattedEvents({
            appStateDispatch,
            unformattedEvents,
            item: task,
            action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE,
          });

          return;
        }
      }

      // Calculate the timestamp for the task's start date
      const timeStamp = moment(task.startDate)
        .local()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ");

      // Create a copy of the day's events at the calculated timestamp
      const dayCopy = { ...userEvents[timeStamp] };

      // Update the 'allDay' array by replacing the edited task
      const newAllDay = userEvents?.[timeStamp]?.allDay?.map((item) => {
        if (item.id === task.id) {
          return task;
        }
        return item;
      });

      // Update the 'brief' array by replacing the edited task
      const newBrief = userEvents?.[timeStamp]?.brief?.map((item) => {
        if (item.id === task.id) {
          return task;
        }
        return item;
      });

      // Update the 'allDay' and 'brief' arrays in the day's copy
      dayCopy.allDay = newAllDay;
      dayCopy.brief = newBrief;

      // appStateDispatch an action to update user events with the edited day copy
      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: { ...userEvents, [timeStamp]: dayCopy },
      });

      if (task.instanceStartDate) {
        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: task,
          action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE_INSTANCE,
        });
      } else {
        updateUnformattedEvents({
          appStateDispatch,
          unformattedEvents,
          item: task,
          action: UNFORMATTED_EVENTS_ACTIONS.EDIT_SINGLE,
        });
      }
    },
    [appStateDispatch, tasks, tasksDict, unformattedEvents, userEvents]
  );

  // Callback to reload the task list from the API
  const reloadTaskList = useCallback(async () => {
    try {
      // Make API call to fetch tasks
      const response = await TaskAPI.get({
        params: {
          left: moment()
            .startOf("month")
            .startOf("day")
            .subtract(3, "months")
            .format(),
          right: moment().endOf("month").endOf("day").add(3, "months").format(),
        },
      });

      // Sort tasks by start date
      const tasksSortedByStartDate = response?.data?.entries?.sort((a, b) => {
        return (
          moment(a?.resource?.instanceStartDate).unix() -
          moment(b?.resource?.instanceStartDate).unix()
        );
      });

      // Extract task resources from sorted array
      const taskResources = tasksSortedByStartDate.map((task) => task.resource);

      // appStateDispatch action to set tasks array
      appStateDispatch({
        type: SET_TASKS,
        tasks: taskResources,
      });

      // Create a dictionary for fast look-up and appStateDispatch action to set it

      const taskDictionary = taskResources.reduce((acc, task) => {
        if (task?.instanceStartDate) {
          return {
            ...acc,
            [`${task.id}&instanceStartDate=${task.instanceStartDate}`]: task,
          };
        }
        return { ...acc, [task.id]: task };
      }, {});

      appStateDispatch({
        type: SET_TASKS_DICT,
        tasksDict: taskDictionary,
      });
    } catch (e) {
      // Handle errors
      throw new Error(e);
    }
  }, [appStateDispatch]);

  const deleteSingleTaskRecurrence = useCallback(
    async (task, timestampIso) => {
      const startOfDay = moment(timestampIso).startOf("day").format();
      const deleteRequestString = `${task?.id}/$${SINGLE_EVENT_INSTANCE}`;
      const dateToParse = moment.parseZone(timestampIso);

      const dateFormattedToUTC = dateToParse
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      const deleteParamsObject = {
        params: `instanceStartDate=${dateFormattedToUTC}`,
      };

      const { data: deletedTask } = await TaskAPI.delete(
        deleteRequestString,
        deleteParamsObject
      );
      deletedTask.instanceStartDate = task.instanceStartDate;
      // update tasks activity
      queryClient.invalidateQueries(taskKeys.taskHistory);

      const newTaskBrief = userEvents[startOfDay].brief.reduce((acc, item) => {
        if (
          item.id === deletedTask.id &&
          item.instanceStartDate === deletedTask.instanceStartDate
        ) {
          return acc;
        }
        if (item.id === deletedTask.id) {
          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            {
              ...item,
              metadata: deletedTask.metadata,
            },
          ];
          return acc;
        }
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, item];
        return acc;
      }, []);

      const newTaskAllDay = userEvents[startOfDay].allDay.reduce(
        (acc, item) => {
          if (
            item.id === deletedTask.id &&
            item.instanceStartDate === deletedTask.instanceStartDate
          ) {
            return acc;
          }
          if (item.id === deletedTask.id) {
            // eslint-disable-next-line no-param-reassign
            acc = [
              ...acc,
              {
                ...item,
                metadata: deletedTask.metadata,
              },
            ];
            return acc;
          }
          // eslint-disable-next-line no-param-reassign
          acc = [...acc, item];
          return acc;
        },
        []
      );

      const newTaskAllDayCount = newTaskAllDay.length;
      const newTask = {
        ...userEvents[startOfDay],
        brief: newTaskBrief,
        allDay: newTaskAllDay,
        allDayCount: newTaskAllDayCount,
      };

      const newUserEvents = {
        ...userEvents,
        [startOfDay]: newTask,
      };

      appStateDispatch({
        type: SET_USER_EVENTS,
        userEvents: newUserEvents,
      });

      const newTasksDict = { ...tasksDict };
      let newTasks = [...tasks];

      delete newTasksDict[`${task.id}&instanceStartDate=${dateFormattedToUTC}`];
      newTasks = newTasks.filter((item) => {
        const found =
          item.id === task.id && item.instanceStartDate === dateFormattedToUTC;
        return !found;
      });

      appStateDispatch({
        type: UPDATE_GLOBAL_STATE,
        payload: {
          tasks: newTasks,
          tasksDict: newTasksDict,
        },
      });

      updateUnformattedEvents({
        appStateDispatch,
        unformattedEvents,
        item: deletedTask,
        instanceStartDate: timestampIso,
        action: UNFORMATTED_EVENTS_ACTIONS.REMOVE_SINGLE_INSTANCE,
      });
    },
    [
      appStateDispatch,
      queryClient,
      tasks,
      tasksDict,
      unformattedEvents,
      userEvents,
    ]
  );

  // Define a useCallback hook for filtering tasks based on resource reference and resource type key
  const filterTasksByResourceAndAssets = useCallback(
    (resourceRef) => {
      // Filter tasks directly associated with the resource reference
      const tasksForResource = tasks.filter(
        (task) => task.association === resourceRef
      );

      // Combine and return tasks directly associated with the resource and tasks associated with related assets
      return tasksForResource;
    },
    [tasks]
  );

  const filterBySpaceAndAssets = useCallback(
    (spaceId) => {
      // Create a lookup table for assets in the specified space
      const assetsLookUpTable = getAssetForSpaceLookupTable(
        assetsDict,
        spaceId
      );

      // Filter tasks to include only those belonging to the specified space OR
      // include those associated with assets in the specified space
      const tasksForSpaceAndAssets = tasks.filter(
        (task) =>
          task?.spaces?.some((space) => space.id === spaceId) ||
          task?.assets?.some((asset) => !!assetsLookUpTable[asset.ref])
      );

      // Return the combined list of tasks
      return tasksForSpaceAndAssets;
    },
    [assetsDict, tasks] // Dependencies for useCallback: assetsDict and tasks
  );

  // Define a useCallback hook for getting a task list based on URL association
  const getTaskListByAssociation = useCallback(() => {
    // Get the current window location URL
    const url = new URL(window.location.href);

    // Extract and format the main resource from the URL path
    const resource = formatUrlResource(url.pathname.split("/")[1]);
    const resourceId = url.pathname.split("/")[2];
    const resourceRef = `${resource}/${resourceId}`;
    // Extract and format the supporting resource from the URL path
    const supportingResource = formatUrlResource(url.pathname.split("/")[3]);
    const supportingResourceId = url.pathname.split("/")[4];
    const supportingResourceRef = `${supportingResource}/${supportingResourceId}`;
    // Check if the main resource is either a Property or a Project
    const needsPropertyOrProjectHandling =
      resource === "Property" || resource === "Project";
    // Handle cases where the main resource is a Property or a Project without a supporting resource
    if (!supportingResource && needsPropertyOrProjectHandling) {
      return filterTasksByResourceAndAssets(resourceRef);
    }
    // Handle cases where the main resource is an Asset without a supporting resource
    if (!supportingResource && resource === "Asset") {
      const tasksForResource = tasks.filter((task) =>
        task?.assets?.some((asset) => asset.ref === resourceRef)
      );
      return tasksForResource;
    }
    // Handle cases where the supporting resource is a Space
    if (supportingResource && supportingResource === "Space") {
      return filterBySpaceAndAssets(supportingResourceId);
    }
    // Handle cases where the supporting resource is an Asset
    if (supportingResource && supportingResource === "Asset") {
      const tasksForSupportingResource = tasks.filter((task) =>
        task?.assets?.some((asset) => asset.ref === supportingResourceRef)
      );
      return tasksForSupportingResource;
    }
    // Default case: return all tasks if none of the above conditions are met
    return tasks;
  }, [filterBySpaceAndAssets, filterTasksByResourceAndAssets, tasks]); // Include dependencies for useCallback hook

  return {
    tasks,
    tasksDict,
    getTaskListByAssociation,
    getTaskByAssociation,
    reloadTaskList,
    moveTaskDate,
    addToTaskDictionary,
    editTaskInDictionary,
    deleteTaskFromDictionary,
    deleteTaskAndAllRecurrences,
    deleteSingleTaskRecurrence,
  };
};

// Export the custom hook
export default useEsTasks;
