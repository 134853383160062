// Framework Tools
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

// Providers
import { useAppState } from "../../../../state/appState";

// Hooks
import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";

// Components
import RequestGridViewInitiateCell from "./RequestGridViewInitiateCell"; // Cell 1
import RequestGridViewProjectCell from "./RequestGridViewProjectCell"; // Cell 2
import RequestGridViewTypeCell from "./RequestGridViewTypeCell"; // Cell 3
import RequestGridViewVendorCell from "./RequestGridViewVendorCell"; // Cell 4
import RequestGridViewDistroListCell from "./RequestGridViewDistroListCell";
import RequestGridViewBallInCourtCell from "./RequestGridViewBallInCourtCell";
import RequestGridViewStartDateCell from "./RequestGridViewStartDateCell";
import RequestGridViewDurationCell from "./RequestGridViewDurationCell";
import RequestGridViewTargetCompletionCell from "./RequestGridViewTargetCompletionCell";
import RequestGridViewProjectedCompletionCell from "./RequestGridViewProjectedCompletionCell";
import RequestGridViewDescriptionCell from "./RequestGridViewDescriptionCell";
import RequestGridViewScheduleCell from "./RequestGridViewScheduleCell";
import RequestGridViewBudgetCell from "./RequestGridViewBudgetCell";
import RequestGridViewAssociationsCell from "./RequestGridViewAssociationsCell";
import useProjectById from "../../../../hooks/useProjectById";
import RequestGridViewLinksCell from "./RequestGridViewLinksCell";

export default function RequestGridView({ gridData, index, associationId }) {
  // User dictionary abstracted from app state provider to get full user info in getUserInfo Function
  const [{ userDict }] = useAppState();
  const { project } = useProjectById(associationId);

  // Managment configuration hook to get full info on types by type reference
  const { data: managementConfiguration } = useManagementConfiguration();

  // Filters through all types to find type with matching type.id
  const typeData = managementConfiguration?.management?.submittal?.types?.find(
    (item) => item?.id === gridData?.type
  );

  const [users, setUsers] = useState([]);
  const [vendor, setVendor] = useState([]);
  // association = Project/id, this splits just the id to pass to SubmittalProjectCell

  // function that takes in an array of userRef User/123 and returns an array of objects for each user
  const getUserInfo = (userRefArray) => {
    const userInfoArray = [];
    userRefArray?.forEach((ref) => {
      userInfoArray?.push(userDict?.[ref?.reference ?? ref]);
    });
    return userInfoArray;
  };

  useEffect(() => {
    const userObjs = gridData?.distribution?.map((u) => {
      return userDict?.[u];
    });
    setUsers(userObjs);
  }, [gridData?.distribution, userDict]);

  useEffect(() => {
    if (gridData?.vendor?.length < 1) {
      setVendor(gridData?.requestWorkflow?.[0]?.steps?.[0]?.users);
    }
  }, [gridData?.requestWorkflow, gridData?.vendor?.length]);

  // function that returns an object to be passed into projected information cell
  const handleProjectedInformation = (steps) => {
    let totalDuration = 0;
    steps?.forEach((step) => {
      if (step?.duration?.actual) {
        totalDuration += step?.duration?.actual;
      } else if (step?.duration?.projected) {
        totalDuration += step?.duration?.actual;
      } else totalDuration += 0;
    });

    const projectedEndDate = moment(gridData?.completion?.projected)
      .add(totalDuration, "days")
      .format();

    const projectedObj = {
      totalDuration,
      projectedEndDate,
    };
    return projectedObj;
  };

  return (
    <div className="grid grid-cols-5 grid-rows-3 gap-y-2 h-auto mb-4 mt-6">
      {/* row 1 */}
      {/* Cell1 - currently contains Initiation Details */}
      <RequestGridViewInitiateCell
        initiationDate={gridData?.initiated?.date}
        initiatedBy={gridData?.initiated?.user}
      />
      {/* Cell 2 - currently contains - project details - notes: Pass through Project id to generate Project / Project Name for this cell */}
      <RequestGridViewProjectCell project={project} />
      {/* Cell 3 - currently contains - type details - notes: Submittal type reference id, used to generate submittal type */}
      <RequestGridViewTypeCell typeData={typeData} />
      {/* Cell 4 - currently contains - responsible vendor */}
      <RequestGridViewVendorCell vendorList={getUserInfo(vendor)} />
      {/* Cell 5 - currently contains -  Distrolist */}
      <RequestGridViewDistroListCell distroList={users} />

      {/* row 2 */}
      {/* Cell 1 - currently contains - Ball In Court */}
      <RequestGridViewBallInCourtCell
        ballInCourtMember={userDict?.[gridData?.ballInCourt?.[0]?.user]}
      />
      {/* Cell 2 - currently contains - Start date, long date */}
      <RequestGridViewStartDateCell
        startDate={gridData?.startDate?.projected}
      />
      {/* Cell 3 - currently contains - Duration - notes: needs end date projected and actual using startDate again as a place holder for rendering */}
      <RequestGridViewDurationCell duration={gridData?.duration} />
      {/* Cell 4 - currently contains - Target Completion Date, duration?.end */}
      <RequestGridViewTargetCompletionCell
        targetCompletionDate={gridData?.completion?.target}
      />
      {/* Initiated by, initiation Date */}
      <RequestGridViewProjectedCompletionCell
        projected={handleProjectedInformation(
          gridData?.requestWorkflow?.[index]?.steps
        )}
      />

      {/* row 3 */}
      {/* Description */}
      <RequestGridViewDescriptionCell description={gridData?.description} />
      {/* Schedule Reference */}
      <RequestGridViewScheduleCell schedule={gridData?.impacts?.schedule} />
      {/* Budget Reference */}
      <RequestGridViewBudgetCell budget={gridData?.impacts?.budget} />
      {/* Initiated by, initiation Date */}
      <RequestGridViewAssociationsCell
        associations={gridData?.associatedArtifacts}
      />
      <RequestGridViewLinksCell links={gridData?.links} />
    </div>
  );
}

RequestGridView.propTypes = {
  associationId: PropTypes.string,
  gridData: PropTypes.shape({
    initiated: PropTypes.shape({
      user: PropTypes.string,
      date: PropTypes.string,
    }),
    association: PropTypes.string,
    completion: PropTypes.shape({
      projected: PropTypes.string,
      target: PropTypes.string,
    }),
    type: PropTypes.string,
    distribution: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.shape({ projected: PropTypes.string }),
    duration: PropTypes.number,
    requestWorkflow: PropTypes.arrayOf(
      PropTypes.shape({
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            step: PropTypes.shape({}),
            users: PropTypes.arrayOf(PropTypes.shape({})),
          })
        ),
      })
    ),
    associatedArtifacts: PropTypes.arrayOf(PropTypes.string),
    ballInCourt: PropTypes.string,
    description: PropTypes.string,
    impacts: PropTypes.shape({
      budget: PropTypes.shape({}),
      schedule: PropTypes.shape({}),
    }),
    vendor: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
  index: PropTypes.number,
};

RequestGridView.defaultProps = {
  associationId: undefined,
  index: 0,
  gridData: {
    initiated: {},
    association: undefined,
    type: undefined,
    distribution: [],
    startDate: undefined,
    duration: undefined,
    requestWorkflow: [],
    associatedArtifacts: [],
    ballInCourt: [],
    description: undefined,
    impacts: {
      budget: {},
      schedule: {},
    },
    vendor: [],
    links: [],
  },
};
