import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "./MemberCard.css";
import deleteIcon from "../../assets/images/magnoliaDeleteIcon.svg";
import placeholderImage from "../../assets/images/esShortLogo.svg";
import { getFullName } from "../../../helpers/Formatters";
import { useGetFile } from "../../../hooks/useGetFile";

const pillStyle = `
flex
items-center
justify-between
gap-3
w-full
flex-1
rounded-md
hover:shadow-medium-lift
hover:bg-backgroundGreen
p-1
py-2
pl-2
m-1
`;

const MemberCard = ({ user, onClick, isEditing, handleRemove }) => {
  const { data: avatar } = useGetFile(user?.avatar);

  const handleClick = useCallback(() => {
    if (!isEditing) {
      onClick(user);
    }
  }, [isEditing, onClick, user]);
  const name = useMemo(() => {
    return user?.kind === "company"
      ? user?.companyName
      : getFullName(user?.name);
  }, [user?.companyName, user?.kind, user?.name]);

  return (
    <div className={pillStyle}>
      <button
        type="button"
        className="flex items-center gap-3 w-full"
        style={{
          height: "40px",
          maxHeight: "40px",
          fontSize: "12px",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            minWidth: "40px",
            overflow: "hidden",
            borderRadius: "50%",
            border: "1px solid #3EECC4",
          }}
          className="shadow-light-lift"
        >
          <img
            src={avatar?.contentsUrl || placeholderImage}
            alt={name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <p className="font-medium text-gray-500 shrink truncate" title={name}>
          {name}
        </p>
      </button>
      {isEditing && (
        <button
          onClick={() => handleRemove(user)}
          className="w-4 h-4 mr-4"
          style={{ minWidth: "1rem" }}
          type="button"
        >
          <img src={deleteIcon} className="w-4 h-4" alt="delete-icon" />
        </button>
      )}
    </div>
  );
};

export default MemberCard;

MemberCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    role: PropTypes.string,
    kind: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
  }),
  onClick: PropTypes.func,
  isEditing: PropTypes.bool,
  handleRemove: PropTypes.func,
};

MemberCard.defaultProps = {
  user: {},
  onClick: () => {},
  isEditing: undefined,
  handleRemove: () => {},
};
