import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { uniqBy } from "lodash";

import {
  checkWFRequestStatus,
  SET_WORKFLOW_MODAL_STATUS,
  WF_ACTIVE_STEP_TASK_INFO,
  WF_STATUS_TYPES,
  WORKFLOW_PATH,
} from "../../constants";

import { useAppState } from "../../state/appState";
import useAssociatedFiles from "../../hooks/useAssociatedFiles";
import { useWorkflowComments } from "../../hooks/useComments";

import useWorkflowFormReducer from "../../hooks/useWorkflowFormReducer";
import useCurrentUser from "../../hooks/useCurrentUser";
import useWorkflow from "../../hooks/useWorkflow";
import {
  useCompleteWorkflow,
  useDeleteWorkflow,
  useGetWorkflowById,
  useInitiateWorkflow,
  usePatchWorkflow,
  useVoidWorkflow,
  useSendReminderWorkflow,
  useSubmitWorkflowStep,
  useActionOnWorkflowStep,
} from "../../hooks/useWorkflowById.new";
import usePostWorkflow from "../../hooks/useWorkflowPost";
import { toastMessage } from "../../stories/Components/Toast/Toast";
import { onUpdateFile } from "../../helpers/File";
import { isWorkflowStartDateInPast } from "../../helpers/WorkflowHelpers";
import { useWorkflowHistory } from "../../hooks/useHistory";
import { getSingleResourcePath } from "../../helpers/Navigation";
import useEsTasks from "../../hooks/useEsTasks";
import clearSearchField from "../../helpers/clearSearchField";
import removeFilesFromWorkflow from "../../helpers/Workflow/removeFilesFromWorkflow";
import { wfAllStepsCompleted } from "../../helpers/worflowStepHelpers";

const useWFSingleViewData = ({ workflowId, isCommentNotification }) => {
  const history = useHistory();

  const params = useMemo(
    () => ({ association: `Workflow/${workflowId}` }),
    [workflowId]
  );

  // Hooks
  const { data: currentUser } = useCurrentUser();
  const {
    associatedFiles,
    addFiles,
    patchFile,
    removeFilesAndUpdateApi,
    cloneFile,
    reload: reloadAssociatedFiles,
  } = useAssociatedFiles(params);

  const { mutate: sendReminder } = useSendReminderWorkflow(workflowId);
  const {
    data: workflowData,
    isLoading,
    refetch,
  } = useGetWorkflowById(workflowId);
  const { mutateAsync: patchWorkflow, isLoading: isUpdating } =
    usePatchWorkflow(workflowId);
  const { mutate: initiateById, isLoading: isInitiating } =
    useInitiateWorkflow();
  const { mutate: workflowActionComplete, isLoading: isCompleting } =
    useCompleteWorkflow();
  const { mutateAsync: workflowActionSubmit, isLoading: isSubmitting } =
    useSubmitWorkflowStep();
  const { mutateAsync: workflowActionReview, isLoading: isPendingStepAction } =
    useActionOnWorkflowStep();
  const { data: workflowHistory } = useWorkflowHistory(
    `Workflow/${workflowId}`
  );
  const { mutate: voidWorkflow } = useVoidWorkflow();
  const { mutate: deleteWorkflow } = useDeleteWorkflow();
  const { mutate: createWorkflow } = usePostWorkflow();
  const { data: rqData } = useWorkflow();
  const { data: commentMap } = useWorkflowComments(`Workflow/${workflowId}`);

  const [workflowForm, workflowFormDispatch] = useWorkflowFormReducer();

  const association = workflowData?.association ?? {};
  const distroList = workflowData?.distroList ?? [];
  const initiated = workflowData?.initiated ?? {};
  const ballInCourt = workflowData?.ballInCourt ?? [];
  // const workflowSteps = workflowForm?.workflowSteps ?? [];
  const workflowSteps = workflowData?.workflowSteps ?? [];
  const associationMembers = workflowData?.associationMembers ?? [];
  const workflowDD = rqData?.workflowDD ?? [];

  const { deleteTaskAndAllRecurrences, reloadTaskList } = useEsTasks();
  const [
    {
      projectDD,
      propertyDD,
      userDict,
      tasksDict,
      workflowModalStatus,
      wfActiveStepTaskInfo,
    },
    dispatch,
  ] = useAppState();

  // State controls

  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [workflow, setWorkflow] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [canComplete, setCanComplete] = useState(false);
  const [canInitiate, setCanInitiate] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [workflowVoid, setWorkflowVoid] = useState(false);
  const [dropdownItems, setDropDownItems] = useState([]);
  const [subMenuOptions, setSubMenuOptions] = useState(false);
  const [showInitiateModal, setShowInitiateModal] = useState(false);

  const [editedWorkflow, setEditedWorkflow] = useState([]);
  const [disableEdit, setDisableEdit] = useState(false);
  const [showCommentsPopover, setShowCommentsPopover] = useState(
    isCommentNotification
  );
  const [attachmentMap, setAttachmentMap] = useState({});
  const [stepTasksInfo, setStepTasksInfo] = useState(false);
  const [tasksToBeDeleted, setTasksToBeDeleted] = useState([]);
  const [showStepDetails, setShowStepDetails] = useState(false);

  useEffect(() => {
    // reload the taskList to populate tasksDict
    (async () => {
      if (!Object?.keys(tasksDict)?.length) {
        await reloadTaskList();
      }
    })();
  }, [reloadTaskList, tasksDict]);

  useEffect(() => {
    // if previously on a step task view set params to the step tasks
    if (wfActiveStepTaskInfo?.show && wfActiveStepTaskInfo?.backFromTasksView) {
      setStepTasksInfo({
        status: wfActiveStepTaskInfo?.status,
        index: wfActiveStepTaskInfo?.index,
        show: wfActiveStepTaskInfo?.show,
      });
      // show workflow Tab
      setActiveTabIndex(1);
      // restore view stepdetails status
      setShowStepDetails(wfActiveStepTaskInfo?.show);
    }
  }, [
    dispatch,
    wfActiveStepTaskInfo?.backFromTasksView,
    wfActiveStepTaskInfo?.index,
    wfActiveStepTaskInfo?.show,
    wfActiveStepTaskInfo?.status,
  ]);

  useEffect(() => {
    if (workflowModalStatus?.activeTabIndex === 0) {
      setActiveTabIndex(0);
      dispatch({
        type: SET_WORKFLOW_MODAL_STATUS,
        activeTabIndex: undefined,
      });
    }
  }, [dispatch, workflowModalStatus?.activeTabIndex]);

  useEffect(() => {
    setLoading(
      isLoading ||
        isInitiating ||
        isCompleting ||
        isUpdating ||
        isSubmitting ||
        isPendingStepAction
    );
  }, [
    isInitiating,
    isLoading,
    isCompleting,
    isUpdating,
    isSubmitting,
    isPendingStepAction,
  ]);

  // Clone behavior before RQ migration
  useEffect(() => {
    if (workflowData) {
      setWorkflow(workflowData?.workflow);
      setAttachmentMap(workflowData?.attachmentMap);
    }
  }, [workflowData]);

  const getSubMenuItems = useCallback(() => {
    const options = [];
    options.push({
      title: "RFI",
      onClick: () => {},
    });
    options.push({
      title: "RFP",
      onClick: () => {},
    });
    options.push({
      title: "Submittal",
      onClick: () => {},
    });
    options.push({
      title: "Workflow",
      onClick: () => {
        dispatch({
          type: SET_WORKFLOW_MODAL_STATUS,
          artifactFor: workflowData?.workflow,
          open: true,
        });
      },
    });
    options.push({
      title: "Contingency Authorization",
      onClick: () => {},
    });
    options.push({
      title: "Purchase Authorization",
      onClick: () => {},
    });
    options.push({
      title: "Change Order",
      onClick: () => {},
    });
    return options;
  }, [dispatch, workflowData]);

  // delete submittal
  const handleDeleteWorkflow = async () => {
    deleteWorkflow(workflow?.id);
    history.push(WORKFLOW_PATH);
  };

  // void submittal
  const handleVoidWorkflow = async () => {
    voidWorkflow(workflow?.id);
    history.push(WORKFLOW_PATH);
  };

  const onCompleteWorkflow = ({ impacts, status, comment }) => {
    workflowActionComplete({
      workflowId: workflow?.id,
      workflowImpacts: impacts,
      workflowStatus: status,
      comment,
    });
  };

  const handleDeleteClick = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const handleVoidClick = useCallback(() => {
    setShowConfirm(true);
    setWorkflowVoid(true);
  }, []);

  const handleInitiateWorkflow = useCallback(async () => {
    setShowInitiateModal(false);
    initiateById(workflow);
  }, [initiateById, workflow]);

  const handleCompleteWorkflow = useCallback(() => {
    dispatch({
      type: SET_WORKFLOW_MODAL_STATUS,
      complete: true,
    });
  }, [dispatch]);

  useEffect(() => {
    // Check if all steps are complete
    const areAllStepsComplete = wfAllStepsCompleted(
      workflowData?.workflowSteps,
      workflowData?.workflow?.status
    );
    setCanComplete(areAllStepsComplete);
  }, [workflowData?.workflowSteps, workflowData?.workflow?.status]);

  useEffect(() => {
    if (!showConfirm) {
      setWorkflowVoid(false);
    }
  }, [showConfirm]);

  useEffect(() => {
    setEditedWorkflow(rqData?.workflows?.find((wf) => wf?.id === workflowId));
  }, [rqData?.workflows, workflowId]);

  /* dispatches current workflow to requestForm */
  useEffect(() => {
    workflowFormDispatch({
      type: "edit",
      payload: workflow,
    });
  }, [workflow, workflowFormDispatch]);

  /* checks if workflow is in-draft or in-progress and sets isDraft for determining editable fields in edit mode */
  useEffect(() => {
    setIsDraft(workflow?.status?.includes(WF_STATUS_TYPES.DRAFT));
  }, [workflow?.status]);

  useEffect(() => {
    const subOptions = getSubMenuItems();
    setSubMenuOptions(subOptions);
  }, [getSubMenuItems]);

  useEffect(() => {
    if (
      workflow?.status?.includes(WF_STATUS_TYPES?.COMPLETED_OPEN) ||
      workflow?.status?.includes(WF_STATUS_TYPES?.COMPLETED_RESOLVED) ||
      (workflow?.status?.includes(WF_STATUS_TYPES?.IN_PROGRESS) &&
        !workflowForm?.requestWorkflow?.[0]?.isFreeFlow) ||
      workflow?.status?.includes(WF_STATUS_TYPES?.VOID)
    ) {
      setDisableEdit(true);
    } else {
      setDisableEdit(false);
    }
  }, [showStepDetails, workflow?.status, workflowForm?.requestWorkflow]);

  // This watches the workflow for changes and checks all the required info to verify if workflow can be initiated
  useEffect(() => {
    const verifyInitiate = (data) => {
      const draft = Boolean(data?.status === "draft"); // workflow must be draft to initiate
      const hasAssociation = Boolean(data?.association?.trim()); // must have an association
      const hasTitle = Boolean(data?.name?.trim()); // must have title
      const hasCustomName = Boolean(data?.customName?.trim()); // must have a customName
      const hasStartDate = Boolean(data?.startDate?.actual); // must have a startDate

      /* This checks every step to ensure it has a user and every user has a role */
      const stepsVerified = data?.requestWorkflow?.[0]?.steps?.every(
        (step) =>
          step?.users?.length > 0 &&
          step?.users?.every((user) => Boolean(user?.reference?.trim()))
      );
      /* If all conditions are met set state value canInitiate to true */
      const workflowCanInitiate =
        draft &&
        hasAssociation &&
        hasTitle &&
        hasCustomName &&
        hasStartDate &&
        stepsVerified;
      setCanInitiate(workflowCanInitiate);
    };
    // function is called everytime workflow changes
    verifyInitiate(workflow);
  }, [workflow]);

  const handleCloneWorkflow = useCallback(() => {
    const {
      association: associationClone,
      customName,
      type: typeClone,
      requestWorkflow,
      distribution,
      description,
    } = workflow;

    const newSteps = requestWorkflow?.[0]?.steps?.map((stp, index) => ({
      position: stp?.position,
      description: stp?.description,
      duration: {
        projected: stp?.duration?.projected || 0,
      },
      users: stp?.users?.map((user) => ({ ...user, status: "waiting" })),
      dueDate: {
        projected:
          index === 0
            ? moment(workflow?.startDate?.actual).add(
                stp?.duration?.projected || 0,
                "days"
              )
            : moment(
                requestWorkflow?.[0]?.steps[index - 1]?.dueDate?.projected
              ).add(stp?.duration?.projected || 0, "days"),
      },
    }));

    const clonedWorkflow = {
      description,
      customName: `${customName} (Clone)`,
      association: associationClone,
      type: typeClone,
      distribution,
      startDate: {
        actual: moment().toISOString(),
      },
      requestWorkflow: [{ steps: newSteps }],
      status: "draft",
    };

    createWorkflow(clonedWorkflow, {
      onSuccess: (response) => {
        // Always set tabIndex to 0 after creating a new submittal
        dispatch({
          type: SET_WORKFLOW_MODAL_STATUS,
          activeTabIndex: 0,
        });

        const path = getSingleResourcePath(`Workflow/${response?.id}`);
        history.push(path, { activeTabIndex: 0 });
      },
    });
  }, [createWorkflow, dispatch, history, workflow]);

  // This watches submitttal, can complete, can delete, and can initiate and sets values for dropdown action buttons
  useEffect(() => {
    const workflowActions = () => {
      const options = [];

      if (
        currentUser &&
        currentUser?.permissions?.workflow?.can_update // user must be able to write to workflow to perform these actions
      ) {
        if (
          workflow?.status === WF_STATUS_TYPES?.COMPLETED_OPEN ||
          workflow?.status === WF_STATUS_TYPES?.COMPLETED_RESOLVED
        ) {
          options.push({
            title: "Clone",
            onClick: () => {
              handleCloneWorkflow();
            },
          });
        }

        if (
          canInitiate &&
          currentUser?.permissions?.workflow?.can_update &&
          workflow?.status === WF_STATUS_TYPES?.DRAFT
        ) {
          options.push({
            title: "Initiate",
            onClick: () => {
              // if start date is in the past
              if (isWorkflowStartDateInPast(workflow)) {
                setShowInitiateModal(true);
              } else {
                handleInitiateWorkflow();
              }
            },
          });
        }
        if (
          currentUser?.hasPermission?.("workflow", "can_update") &&
          workflow?.status === WF_STATUS_TYPES?.IN_PROGRESS &&
          canComplete
        ) {
          options.push({
            title: "Complete",
            onClick: () => handleCompleteWorkflow(),
          });
        }
        if (
          currentUser?.hasPermission?.("workflow", "can_update") &&
          workflow?.status === WF_STATUS_TYPES?.DRAFT
        ) {
          options.push({
            title: "Edit",
            onClick: () => {
              setEditing(true);
            },
          });
        }
        // as long as the workflow isn't voided or completed it can be voided
        if (
          currentUser?.hasPermission?.("workflow", "can_update") &&
          workflow?.status !== WF_STATUS_TYPES?.DRAFT &&
          checkWFRequestStatus(workflow?.status)
        ) {
          options.push({
            title: "Void",
            onClick: () => handleVoidClick(workflow),
          });
        }
      }
      // as long as the workflow isn't voided or completed it can be deleted
      if (
        currentUser?.hasPermission?.("workflow", "can_delete") &&
        workflow?.status !== WF_STATUS_TYPES?.COMPLETED_OPEN &&
        workflow?.status !== WF_STATUS_TYPES?.COMPLETED_RESOLVED &&
        checkWFRequestStatus(workflow?.status)
      ) {
        if (workflow?.status !== WF_STATUS_TYPES?.IN_PROGRESS)
          options.push({
            title: "Delete",
            onClick: () => handleDeleteClick(workflow),
          });
        options.push({
          title: "Association",
          openSubMenu: true,
        });
      }

      if (options.length === 0) {
        options.push({
          title: "No options available",
        });
      }

      return options;
    };
    const ddOptions = workflowActions();
    setDropDownItems(ddOptions);
  }, [
    canComplete,
    canInitiate,
    currentUser,
    handleCloneWorkflow,
    handleCompleteWorkflow,
    handleDeleteClick,
    handleInitiateWorkflow,
    handleVoidClick,
    workflow,
  ]);
  // checks if a step is complete by look at the users in the step. If any user status !== "waiting" the step is considered complete
  // i.e. step can have multiple users - needs 1 to advance.
  const checkStepComplete = (user) => {
    return user.status !== "waiting";
  };

  const handleDeleteTaskAndAllRecurrences = useCallback(() => {
    if (tasksToBeDeleted?.length > 0) {
      const TaskIdsArray = tasksToBeDeleted?.map((taskRef) => ({
        id: taskRef?.split("/")[1],
      }));

      Promise.allSettled(
        TaskIdsArray?.map((task) => deleteTaskAndAllRecurrences(task))
      );
    }
  }, [deleteTaskAndAllRecurrences, tasksToBeDeleted]);

  const handleEditClick = useCallback(() => {
    try {
      if (editing) {
        // reset original request
        workflowFormDispatch({
          type: "edit",
          payload: editedWorkflow,
        });
      }
    } catch (error) {
      console.warn(error.message);
    } finally {
      setEditing(!editing);
    }
  }, [editedWorkflow, editing, workflowFormDispatch]);

  const handleSendReminder = (note, userArr) => {
    sendReminder({ workflowId: workflow.id, note, userArr });
  };

  /* called when check mark next to title is clicked, patches workflow customName */
  const handleChangeWorkflowTitle = async (value) => {
    workflowFormDispatch({
      type: "customName",
      payload: value,
    });
  };

  const handleDistroList = useCallback(() => {
    const result = workflowForm?.distribution?.map((ref) => {
      const member = userDict?.[ref];
      return member;
    });
    return result;
  }, [workflowForm?.distribution, userDict]);

  /* called when workflow is in edit mode and start date changes to update target completion */
  const handleTargetCompletion = useCallback(() => {
    const start = workflowForm?.startDate?.target;
    const target = moment(start)
      .add(workflow?.duration?.numDays, "days")
      .format("MMMM DD, YYYY");
    return target;
  }, [workflow?.duration?.numDays, workflowForm?.startDate?.target]);

  /* called when workflow is in edit mode and start date changes to update projected completion */
  const handleProjectedCompletion = useCallback(() => {
    const start = workflowForm?.startDate?.projected;
    const projected = moment(start)
      .add(workflow?.duration?.numDays, "days")
      .format("MMMM DD, YYYY");
    return projected;
  }, [workflow?.duration?.numDays, workflowForm?.startDate?.projected]);

  const handleArtifactsList = useCallback(() => {
    if (rqData?.workflowDict) {
      // find all workflows created from this WF as associated artifact
      const workflowDict = rqData?.workflowDict || {};
      const associatedWorkflows = [];
      Object.keys(workflowDict)?.forEach((wfKey) => {
        const wf = workflowDict[wfKey];

        if (wf?.associatedArtifacts?.includes(`Workflow/${workflowId}`))
          associatedWorkflows.push(wf);
      });

      // find workflows that are on associated artifacts list
      const result = [];
      workflowForm?.associatedArtifacts?.forEach((item) => {
        const wfItem = rqData?.workflowDict?.[item];
        if (wfItem) result.push(wfItem);
      });

      return uniqBy([...result, ...associatedWorkflows], "reference");
    }
    return [];
  }, [workflowForm?.associatedArtifacts, rqData?.workflowDict, workflowId]);

  const handleFinishEditing = useCallback(() => {
    patchWorkflow(
      {
        updatedResource: workflowForm,
        originalResource: editedWorkflow,
      },
      {
        onSuccess: () => {
          toastMessage("Workflow has been edited successfully");
          // remove any tasks, if any, added to the step
          handleDeleteTaskAndAllRecurrences();
        },
      }
    );
  }, [
    editedWorkflow,
    handleDeleteTaskAndAllRecurrences,
    patchWorkflow,
    workflowForm,
  ]);

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const newFileRefs = filesUploaded.map((file) => file.reference);
      const updatedFiles = [
        ...(editedWorkflow?.attachments ?? []),
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedWorkflow = {
        ...editedWorkflow,
        attachments: updatedFiles,
      };
      // patch resource
      const patchedResult = await patchWorkflow({
        updatedResource: updatedWorkflow,
        originalResource: editedWorkflow,
      });

      if (patchedResult) {
        toastMessage(
          `File changes successfully applied to ${editedWorkflow?.name}`
        );
      } else {
        // remove created files if PATCH fails
        removeFilesAndUpdateApi(newFileRefs);
      }
    },
    [addFiles, editedWorkflow, patchWorkflow, removeFilesAndUpdateApi]
  );

  const removeWorkflowFiles = useCallback(
    async (fileRefs) => {
      const updatedWorkflow = removeFilesFromWorkflow(editedWorkflow, fileRefs);

      // patch resource
      const patchedResult = await patchWorkflow({
        updatedResource: updatedWorkflow,
        originalResource: editedWorkflow,
      });

      if (patchedResult) {
        toastMessage(
          `File changes successfully applied to ${editedWorkflow?.name}`
        );
      }
    },
    [editedWorkflow, patchWorkflow]
  );

  const handleFileClone = useCallback(
    async (fileId) => {
      cloneFile(fileId)
        .then(async (clonedFile) => {
          const updatedWorkflow = {
            ...editedWorkflow,
            attachments: [
              ...editedWorkflow?.attachments,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          const patchedResult = await patchWorkflow({
            updatedResource: updatedWorkflow,
            originalResource: editedWorkflow,
          });

          return patchedResult;
        })
        .then(() =>
          toastMessage(
            `Recent file successfully attached to ${editedWorkflow?.name}`
          )
        )
        .catch(() => {
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patchWorkflow, editedWorkflow, removeFilesAndUpdateApi]
  );

  const handleUpdateFile = useCallback(
    async ({ originalResource, currentTags, name }) => {
      await onUpdateFile({ originalResource, currentTags, name, patchFile });
      refetch();
    },
    [patchFile, refetch]
  );

  const getIconsToHide = () => ({
    delete:
      workflow?.status === WF_STATUS_TYPES?.IN_PROGRESS ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_OPEN ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_RESOLVED,
    edit:
      workflow?.status === WF_STATUS_TYPES?.VOID ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_OPEN ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_RESOLVED,
    upload:
      workflow?.status === WF_STATUS_TYPES?.VOID ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_OPEN ||
      workflow?.status === WF_STATUS_TYPES?.COMPLETED_RESOLVED,
  });

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      // reset active workflow step info
      if (wfActiveStepTaskInfo?.show) {
        dispatch({
          type: WF_ACTIVE_STEP_TASK_INFO,
          payload: {},
        });
      }
      // if files tab get latest files
      if (index === 3) {
        reloadAssociatedFiles();
      }

      setActiveTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
    },
    [
      dispatch,
      reloadAssociatedFiles,
      setActiveTabIndex,
      wfActiveStepTaskInfo?.show,
    ]
  );

  return {
    associationMembers,
    activeTabIndex,
    subMenuOptions,
    editedWorkflow,
    attachmentMap,
    workflowSteps,
    workflowForm,
    currentUser,
    disableEdit,
    ballInCourt,
    association,
    commentMap,
    distroList,
    workflowDD,
    propertyDD,
    loading,
    initiated,
    projectDD,
    userDict,
    workflow,
    editing,
    isDraft,
    dropdownItems,
    workflowVoid,
    showConfirm,
    showInitiateModal,
    associatedFiles,
    showCommentsPopover,
    workflowHistory,
    stepTasksInfo,
    wfActiveStepTaskInfo,
    dispatch,
    canComplete,
    handleTabClick,
    setLoading,
    setShowConfirm,
    setShowInitiateModal,
    handleInitiateWorkflow,
    onCompleteWorkflow,
    addFiles,
    patchFile,
    removeWorkflowFiles,
    removeFilesAndUpdateApi,
    handleUpdateFile,
    cloneFile,
    onAddFilesCallback,
    handleFileClone,
    patchWorkflow,
    handleEditClick,
    setAttachmentMap,
    handleDistroList,
    checkStepComplete,
    setActiveTabIndex,
    handleSendReminder,
    handleArtifactsList,
    handleFinishEditing,
    workflowFormDispatch,
    handleTargetCompletion,
    handleChangeWorkflowTitle,
    handleProjectedCompletion,
    setShowCommentsPopover,
    handleDeleteWorkflow,
    handleVoidWorkflow,
    getIconsToHide,
    workflowActionReview,
    workflowActionSubmit,
    setStepTasksInfo,
    reloadAssociatedFiles,
    setTasksToBeDeleted,
    setDisableEdit,
    setShowStepDetails,
  };
};

export default useWFSingleViewData;
