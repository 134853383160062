/* eslint-disable no-param-reassign */
import { useEffect, useMemo, useState } from "react";
// API
import {
  AssetAPI,
  CommentAPI,
  ProjectAPI,
  TaskAPI,
} from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useQueryClient } from "react-query";
import {
  filePaginatedKeys,
  taskKeys,
  ticketsKeys,
} from "../../../../config/reactQuery/queryKeyFactory";
import {
  PUSH_USER_EVENTS_DICT,
  REMOVE_USER_EVENT,
  SET_USER_EVENTS_DICT,
  UPDATE_USER_EVENTS_DICT,
} from "../../../../constants";
import { formatEvents } from "../../../../helpers/Calendar";
import describeRruleFromString from "../../../../helpers/Calendar/describeRruleFromString";
import { uploadFileWithData } from "../../../../helpers/File";
import { formatTaskObj } from "../../../../helpers/Formatters";
import { getTagOptions } from "../../../../helpers/Tag";
import { useSop } from "../../../../hooks/useSop";
import { useGetTags } from "../../../../hooks/useTags";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import { toastError } from "../../Toast/Toast";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";

const useEventModalData = (modalData) => {
  // Destructure the user dictionary from the app state
  const [{ userDict, reloadEvents: reload, assetsDict, userEvents }, dispatch] =
    useAppState();

  const { data: tagsData } = useGetTags();
  const [allInviteesInfo, setAllInviteesInfo] = useState([]);
  const [createdBy, setCreatedBy] = useState();
  const [completedBy, setCompletedBy] = useState();
  const [currentTags, setCurrentTags] = useState([]);
  const [, modalStateDispatch] = useModalState();
  const { data: sopData, isLoading } = useSop();
  const queryClient = useQueryClient();
  const [associationData, setAssociationData] = useState({});

  const isAllDay =
    (isBegOfDay(modalData?.item?.startDate) &&
      isEndOfDay(modalData?.item?.endDate)) ||
    modalData?.item?.allDay;
  /**
   * Format Sop Options for task steps
   */

  useEffect(() => {
    (async () => {
      const associationRef = modalData?.item?.association;

      if (!associationRef) return;

      const resource = associationRef.split("/")[0];

      if (resource === "Asset") {
        try {
          const response = await AssetAPI.getByRef(associationRef);
          setAssociationData(response.data);
        } catch (error) {
          toastError("Failed getting association");
        }
      }

      if (resource === "Project") {
        try {
          const response = await ProjectAPI.getByRef(associationRef);
          setAssociationData(response.data);
        } catch (error) {
          toastError("Failed getting association");
        }
      }

      if (resource === "Property") {
        try {
          const response = await TaskAPI.getByRef(associationRef);
          setAssociationData(response.data);
        } catch (error) {
          toastError("Failed getting association");
        }
      }
    })();
  }, [modalData]);

  const sopOptions = useMemo(() => {
    return !isLoading
      ? sopData?.reduce(
          (list, item) => {
            return {
              options: [
                ...list.options,
                {
                  label: item.name,
                  value: item.reference,
                  version: item?.version,
                },
              ],
              dict: { ...list.dict, [item.reference]: item },
            };
          },
          { dict: {}, options: [] }
        )
      : { dict: {}, options: [] };
  }, [sopData, isLoading]);

  useEffect(() => {
    setCurrentTags(getTagOptions(modalData?.item, tagsData?.tagsDict));
  }, [modalData, tagsData?.tagsDict]);

  useEffect(() => {
    const invitees = modalData?.item?.invitees || [];
    // Map over the invitees (if they exist) and fetch their information from the user dictionary
    const inviteeData = invitees?.reduce((list, invitee) => {
      // Get information for each invitee from the user dictionary
      const inviteeInfo = userDict?.[invitee];
      if (inviteeInfo) list.push(inviteeInfo);
      return list;
    }, []);
    // Set the state of allInviteeInfo to the information of all invitees
    setAllInviteesInfo(inviteeData);
    setCreatedBy(userDict?.[modalData?.item?.metadata?.createdBy]);
    if (modalData?.item?.closing?.closedBy) {
      setCompletedBy(userDict?.[modalData?.item?.closing?.closedBy]);
    }
  }, [userDict, modalData]);

  /**
   *
   * @param {Object} event - event to be removed
   * @param {String} originalKey - appState event key
   * @param {String} ref - resource reference string
   * @summary deletes/removes event from app state
   */
  const removeEvent = (event, ref) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();
    dispatch({
      type: REMOVE_USER_EVENT,
      originalKey: start,
      ref,
    });
  };

  const updateEvent = (event, originalKey, ref) => {
    const start = moment(event?.resource?.startDate).startOf("day").format();

    dispatch({
      type: UPDATE_USER_EVENTS_DICT,
      start,
      event: formatEvents([event]),
      originalKey,
      ref,
    });
  };

  const addEvent = (event) => {
    const start = moment(event?.startDate).startOf("day").format();
    let associated = event?.association;
    if (associated?.includes("Asset")) {
      associated =
        assetsDict?.[associated]?.property || assetsDict?.[associated]?.project;
    }

    dispatch({
      type: PUSH_USER_EVENTS_DICT,
      start,
      event: formatEvents([{ resource: event }]),
      associated,
    });
  };

  /**
   * File Management
   */
  const onAddAttachment = async (photo, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      photo,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };
        const resource = await onAddAttachment(
          original,
          data,
          (loaded, total) => handleProgressCallback(loaded, total, name)
        );

        return { ...data, ...resource };
      })
    );

    return result;
  };

  const handleFilesUploaded = async (form) => {
    const filteredFiles = form?.files.reduce(
      (list, file) => {
        if (!file?.isEditing && file?.type) {
          list.latest.push(file);
        } else {
          list.previous.push(file);
        }
        return list;
      },
      { latest: [], previous: [] }
    );
    const res = await onUpload(filteredFiles.latest, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);
    return [...filteredFiles.previous, ...res];
  };

  /**
   * File Management
   */

  const patchComplete = async (form, comment, toast) => {
    if (form?.recurrence) {
      try {
        const requestPath = `${form.id}/$single?instanceStartDate=${form.instanceStartDate}`;

        // Prepare the new task object with necessary modifications
        const newTask = {
          ...form,
          metadata: { ...form.metadata, lastUpdated: moment().format() }, // Update metadata with current time
          invitees: form.invitees?.map((invitee) => invitee.value ?? invitee), // Extract invitees' values if available, else use the original invitees
          status: "done",
        };

        // Handle file uploads and retrieve references
        const uploads = await handleFilesUploaded(form);

        // Update the task with the new file references
        newTask.files = uploads.map((file) => ({
          ref: file.reference || file.ref,
          category: file.category,
        }));

        // Update the task with the new metadata
        const taskAfterUpdate = await TaskAPI.patch(
          requestPath,
          newTask,
          form.previousTask
        );

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);

        // Update the task in the user events dictionary
        newTask.metadata = taskAfterUpdate?.data?.metadata;
        // Get user's timezone to adjust the date-time
        const userTimeZone = moment.tz.guess();
        // Get the adjusted date-time
        const momentData = moment(form?.startDate).tz(userTimeZone);

        // Reset the adjusted date-time to midnight
        momentData.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        // Format the adjusted date-time for further use
        const formattedDate = momentData.format("YYYY-MM-DDTHH:mm:ssZ");

        // Create a copy of user events for the specific date to modify
        const userEventDayCopy = { ...userEvents[formattedDate] };

        // Modify the allDay and brief events based on the new task data
        userEventDayCopy.allDay = userEventDayCopy.allDay.map((event) => {
          if (event.startDate === form.startDate && event.id === form.id) {
            return newTask;
          }

          // If the event is a recurring event, update the metadata of the event
          if (event.id === form.id) {
            return {
              ...event,
              metadata: newTask.metadata,
            };
          }

          return event;
        });

        // Modify the allDay and brief events based on the new task data
        userEventDayCopy.brief = userEventDayCopy.brief.map((event) => {
          if (event.startDate === form.startDate) {
            return newTask;
          }

          return event;
        });

        // Create a copy of user events to modify
        const userEventsCopy = {};

        // Modify the user events dictionary based on the new task data
        Object.keys(userEvents).forEach((item) => {
          // If the date is the same as the date of the task, skip it
          if (item === formattedDate) {
            return;
          }

          // Create a copy of the user events for the specific date to modify
          const copyToChange = userEvents[item];

          // Modify the allDay and brief events based on the new task data
          copyToChange.allDay = copyToChange.allDay.map((event) => {
            if (event.id === form.id && event.startDate !== form.startDate) {
              return {
                ...event,
                metadata: newTask.metadata,
              };
            }

            return event;
          });

          // Modify the allDay and brief events based on the new task data
          copyToChange.brief = copyToChange.brief.map((event) => {
            if (event.id === form.id && event.startDate !== form.startDate) {
              return {
                ...event,
                metadata: newTask.metadata,
              };
            }

            return event;
          });

          // Update the user events copy with the modified data
          userEventsCopy[item] = copyToChange;
        });

        // Update the user events dictionary with the modified data
        dispatch({
          type: SET_USER_EVENTS_DICT,
          events: {
            ...userEventsCopy,
            [formattedDate]: userEventDayCopy,
          },
        });

        // Notify the user that the task is complete

        toast("Task Complete");
      } catch (error) {
        // Notify the user of an error during the task completion process

        toast("Error Marking Task Complete");
      }

      // Return from the function
      // will not execute the rest of the code
      return;
    }

    const { reference, invitees, originalKey, metadata, ...rest } = form;
    let newTask = {
      reference,
      metadata: { ...metadata, lastUpdated: moment().format() },
      invitees: invitees?.map((invitee) => invitee.value ?? invitee),
      ...rest,
      status: "done",
    };

    newTask = formatTaskObj(newTask);
    handleFilesUploaded(form)
      .then((uploads) =>
        TaskAPI.patch(
          form.id,
          {
            ...newTask,
            files: uploads.map((file) => ({
              ref: file.reference || file.ref,
              category: file.category,
            })),
          },
          form.previousTask
        )
      )
      .then(async (res) => {
        const key = moment(form.startDate).startOf("day").format();
        updateEvent({ resource: res?.data }, key, form.reference);
        if (comment?.content) {
          await CommentAPI.post(comment);
        }

        // Invalidate tasks for tickets.
        // Make sure SR get the latest data update
        queryClient.invalidateQueries(ticketsKeys.tasks());

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);

        toast("Task Complete");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Return the information of all invitees

  let associationUrlPath = "";

  if (associationData?.resource === "Property") {
    associationUrlPath = `/properties/${associationData?.id}`;
  }

  if (associationData?.resource === "Project") {
    associationUrlPath = `/projects/${associationData?.id}`;
  }

  if (associationData?.resource === "Asset") {
    associationUrlPath = `/assets/${associationData?.id}`;
  }

  let recurrenceString = "";

  if (modalData?.item?.recurrence) {
    recurrenceString = describeRruleFromString(modalData?.item?.recurrence);
  }

  return {
    removeEvent,
    addEvent,
    updateEvent,
    patchComplete,
    recurrenceString,
    createdBy,
    completedBy,
    allInviteesInfo,
    modalStateDispatch,
    reload,
    currentTags,
    isAllDay,
    associationData,
    associationUrlPath,
    sopOptions: sopOptions?.options,
    sopLibrary: sopOptions?.dict,
  };
};

// Export the custom hook for use in other parts of the application
export default useEventModalData;
