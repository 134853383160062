import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import {
  GET_ASSET_VIEW_PATH,
  GET_LOCATION_SPACE_ASSET_VIEW_PATH,
  GET_PROPERTY_ASSET_PATH,
} from "../../../constants";
import AssetTable from "../../../stories/Components/AssetTable/AssetTable";
import useAssets from "../../../hooks/useAssets";
import { useAppState } from "../../../state/appState";

const PropertySpaceAssetList = ({
  associatedResource,
  bannerComponent,
  locationId,
  spaceId,
}) => {
  const history = useHistory();
  const [{ managementConfiguration }] = useAppState();
  const [originalAssets, reload] = useAssets({
    propertyRef: associatedResource,
    locationId,
    spaceId,
  });
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptionsMap, setSubCatOptionsMap] = useState({});

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCatOptions(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubCatOptionsMap(subcatMap);
    }
  }, [managementConfiguration]);

  const onAssetRowClick = (id) => {
    const propertyId = associatedResource?.split("/");
    const view = propertyId && propertyId[0];
    const ref = propertyId && propertyId[1];
    if (view && view?.toLowerCase() === "property") {
      if (spaceId) {
        history.replace(
          GET_LOCATION_SPACE_ASSET_VIEW_PATH.property(
            ref,
            locationId,
            spaceId,
            id
          )
        );
      } else {
        history.replace(GET_PROPERTY_ASSET_PATH(ref, id));
      }
    } else {
      // TODO: Fix for projectAsset use case
      history.push(GET_ASSET_VIEW_PATH(id));
    }
  };

  return (
    <AssetTable
      originalAssets={originalAssets}
      reloadAssets={reload}
      categoryOptionsList={catOptions}
      subCatOptionsMap={subCatOptionsMap}
      onRowClick={onAssetRowClick}
      propertyRef={associatedResource}
      bannerComponent={bannerComponent}
      locationId={locationId}
    />
  );
};

PropertySpaceAssetList.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,

  bannerComponent: PropTypes.node,
  spaceId: PropTypes.string,
  locationId: PropTypes.string,
};

PropertySpaceAssetList.defaultProps = {
  associatedResource: undefined,
  bannerComponent: undefined,
  spaceId: undefined,
  locationId: undefined,
};

export default PropertySpaceAssetList;
