import React from "react";
import PropTypes from "prop-types";

/**
 * Component that renders Label - Value (Component) in a row
 */
const GridDetailRow = ({
  labelClassName,
  label,
  readOnlyValue,
  editableComponent,
  className,
  hideBorder = false,
  isEditing = false,
}) => {
  return (
    <div
      className={`flex p-4 w-full ${className || ""} ${
        hideBorder ? "" : "border-b border-gray-450 border-opacity-50"
      }`}
    >
      <span className={`${labelClassName} w-1/2`}>{label}</span>
      {isEditing ? (
        editableComponent
      ) : (
        <span className="text-md w-1/2">{readOnlyValue}</span>
      )}
    </div>
  );
};
GridDetailRow.defaultProps = {
  labelClassName: "font-bold text-gray-450 text-md",
  label: "",
  readOnlyValue: "",
  className: undefined,
  editableComponent: undefined,
  hideBorder: false,
  isEditing: false,
};

GridDetailRow.propTypes = {
  labelClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  readOnlyValue: PropTypes.string,
  className: PropTypes.string,
  editableComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  hideBorder: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default GridDetailRow;
