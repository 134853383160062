import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import * as yup from "yup";
import { NO_ATTRIBUTE_OPTION_MSG } from "../../../constants";
import sortAndGroupByFieldType from "../../../helpers/sortAndGroupByFieldType";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import PureAttributeWidget from "./PureAttributeWidget";
import useAttributes from "../../../hooks/useAttributes";

const labelWidth = {
  minWidth: "12.5rem",
  width: "10.5vw",
  wordBreak: "break-word",
  marginRight: 10,
};

const splitRowCN = cntl`
  flex
  justify-between
  items-center
  mt-1
  ESInputLabel
`;

const AttributeWidget = ({
  title,
  resource,
  dispatch,
  loading,
  defaultAttributes = [],
  disableEditing,
  setNewAttributes,
  newAttributes,
  editing,
}) => {
  const [, reloadAttributes, selectedMeasurements] = useAttributes();

  const filteredItems = useMemo(() => {
    if (selectedMeasurements?.length || newAttributes?.length) {
      const allMeasurements = [...selectedMeasurements];
      const { additionalInfo: info } = resource;
      const temp = allMeasurements?.reduce(
        (list, item) => {
          const found = (info ?? []).find((x) => item.detail === x.detail);
          if (found) {
            const value =
              item?.fieldType === "date-picker"
                ? moment(found.value).format("MM/DD/YYYY")
                : found.value;
            const unit = found.unit && found.unit !== "" ? `${found.unit}` : "";
            list.items.push({
              title: found.detail,
              value: (
                <p
                  className={splitRowCN}
                  title={`${value}${unit && ` ${unit}`}`}
                  aria-label={`${value}${unit && ` ${unit}`}`}
                >{`${value} ${unit}`}</p>
              ),
              field: item?.fieldType,
            });
            list.measurementFields.push(item);
          }
          return list;
        },
        { items: [], measurementFields: [] }
      );
      return temp;
    }

    return { items: [], measurementFields: [] };
  }, [newAttributes, resource, selectedMeasurements]);

  const getMeasurementInput = useCallback(
    (
      detail,
      fieldType,
      fieldValues,
      units,
      defaultValue,
      defaultOnChange,
      defaultTitle
    ) => {
      if (detail === "Types") {
        return null;
      }

      const onChange = (value, unit) => {
        dispatch({
          type: "measurement",
          detail,
          value,
          unit,
        });
      };

      const unitOptions = [];
      if (units?.fieldValues?.length) {
        units.fieldValues.forEach((item) =>
          unitOptions.push({ label: item, value: item })
        );
      }

      const { value = "", unit: valueUnit = "" } =
        resource?.additionalInfo?.find((m) => m.detail === detail) ?? {};

      let returnValue;

      const determineTextFieldNeeded = (input) => {
        if (typeof input !== "string" || input === "") return false;
        if (input.length > 20) return true;

        return false;
      };

      switch (fieldType) {
        case "number-entry":
          returnValue = (
            <div
              key={detail}
              className="flex flex-row w-full items-center gap-2"
            >
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <div className="flex flex-row justify-evenly w-full">
                <Input
                  isTextarea={determineTextFieldNeeded(defaultValue ?? value)}
                  inlineLabel
                  mainWrapperClassName={unitOptions?.length ? "w-44" : "w-full"}
                  placeholder={defaultTitle ?? detail}
                  onChange={(val) =>
                    defaultOnChange
                      ? defaultOnChange(val)
                      : onChange(val, valueUnit)
                  }
                  value={defaultValue ?? value}
                  validation={yup
                    .number()
                    .transform((v, o) => (o === "" ? undefined : v))}
                  disableClear
                />
                {!!unitOptions.length && (
                  <div className="flex flex-row items-center gap-2 w-full pl-2">
                    <p className="ESInputLabel justify-end">Unit</p>
                    <Dropdown
                      noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                      className="w-full text-left"
                      placeholder="Select"
                      options={unitOptions}
                      onChange={({ value: changeValue }) =>
                        onChange(value, changeValue)
                      }
                      value={unitOptions?.find(
                        (opt) => opt.value === valueUnit
                      )}
                      disableClear
                    />
                  </div>
                )}
              </div>
            </div>
          );
          break;
        case "date-picker":
          returnValue = (
            <div
              key={detail}
              className="flex flex-row w-full items-center gap-2"
            >
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <DatePicker
                labelClassName="ESInputLabel text-left"
                className="w-full items-center"
                value={defaultValue ?? value}
                onChange={(val) =>
                  defaultOnChange ? defaultOnChange(val) : onChange(val)
                }
                validation={yup.date()}
              />
            </div>
          );
          break;
        case "ddl":
          {
            const options = fieldValues?.map((fv) => ({
              label: fv,
              value: fv,
            }));

            returnValue = (
              <div className="flex flex-row gap-2 items-center w-full">
                <p className="ESInputLabel text-left" style={labelWidth}>
                  {defaultTitle ?? detail}
                </p>
                <div className="flex w-full">
                  <Dropdown
                    noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                    className="flex-1 text-left"
                    key={detail}
                    placeholder="Select"
                    options={options}
                    onChange={({ value: changeValue }) =>
                      defaultOnChange
                        ? defaultOnChange(changeValue)
                        : onChange(changeValue)
                    }
                    value={options?.find(
                      (opt) => opt.value === defaultValue || opt.value === value
                    )}
                  />
                </div>
              </div>
            );
          }
          break;
        case "check-box":
          returnValue = (
            <div className="flex flex-row">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <Checkbox
                placeholder={detail}
                size={6}
                key={detail}
                className="flex items-center"
                value={defaultValue ?? value}
                checked={value === "Yes"}
                onChange={(isChecked) =>
                  defaultOnChange
                    ? defaultOnChange(isChecked ? "Yes" : "No")
                    : onChange(isChecked ? "Yes" : "No")
                }
              />
            </div>
          );
          break;
        case "text-entry":
        default:
          returnValue = (
            <div key={detail} className="flex flex-row items-center w-full">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <Input
                isTextarea={determineTextFieldNeeded(defaultValue ?? value)}
                mainWrapperClassName="flex w-full"
                placeholder={defaultTitle ?? detail}
                onChange={(val) =>
                  defaultOnChange
                    ? defaultOnChange(val)
                    : onChange(val, valueUnit)
                }
                value={defaultValue ?? value}
                validation={yup.string()}
                disableClear
              />
              {!!unitOptions.length && (
                <Dropdown
                  noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                  className="pl-2 text-left border flex flex-row"
                  label="Unit"
                  placeholder="Select"
                  options={unitOptions}
                  onChange={({ value: changeValue }) =>
                    onChange(value, changeValue)
                  }
                  value={unitOptions?.find((opt) => opt.value === valueUnit)}
                  disableClear
                />
              )}
            </div>
          );
      }

      return (
        <div key={detail} className="flex flex-row w-full text-center">
          <div className="flex flex-row justify-between w-full">
            {returnValue}
          </div>
        </div>
      );
    },
    [dispatch, resource?.additionalInfo]
  );

  const handleAddElements = (elements, attributes) => {
    elements.forEach(({ detail, id, fieldType, data: { value, unit } }) => {
      let finalValue = value;
      if (!finalValue) {
        finalValue = fieldType === "check-box" ? "No" : "";
      }
      const attribute = {
        type: "measurement",
        detail,
        value: finalValue,
        unit,
        id,
      };
      dispatch(attribute);
    });

    setNewAttributes(attributes);
  };

  const handleDeleteElement = (id) => {
    dispatch({
      type: "measurement",
      operation: "remove",
      detail: id,
    });
  };

  const attributes = useMemo(() => {
    const combinedAttributes = [
      ...defaultAttributes,
      ...filteredItems?.measurementFields,
    ];
    const sortedAttributes = sortAndGroupByFieldType(combinedAttributes);

    return sortedAttributes
      ?.map(
        ({
          detail,
          fieldType,
          fieldValues,
          unit: units,
          value: defaultValue,
          onChange: defaultOnChange,
          title: defaultTitle,
          textArea,
        }) => {
          return {
            input: getMeasurementInput(
              detail,
              fieldType,
              fieldValues,
              units,
              defaultValue,
              defaultOnChange,
              defaultTitle,
              textArea
            ),
            display: defaultTitle
              ? { title: defaultTitle, value: defaultValue, field: fieldType }
              : filteredItems?.items?.find((item) => item.title === detail),
            defaultAttribute: defaultTitle,
          };
        }
      )
      .filter((el) => !!el);
  }, [
    defaultAttributes,
    filteredItems?.items,
    filteredItems?.measurementFields,
    getMeasurementInput,
  ]);

  return (
    <PureAttributeWidget
      newAttributes={newAttributes}
      setNewAttributes={setNewAttributes}
      loading={loading}
      resource={resource}
      editing={editing}
      title={title}
      values={attributes}
      onAdd={handleAddElements}
      onDelete={handleDeleteElement}
      showAddButton
      disableEditing={disableEditing}
      reloadAttributes={reloadAttributes}
      measurements={selectedMeasurements}
    />
  );
};

AttributeWidget.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  defaultAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  disableEditing: PropTypes.bool,
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  editing: PropTypes.bool,
};

AttributeWidget.defaultProps = {
  title: undefined,
  resource: undefined,
  dispatch: undefined,
  loading: false,
  defaultAttributes: [],
  disableEditing: false,
  setNewAttributes: undefined,
  newAttributes: [],
  editing: false,
};

export default AttributeWidget;
