import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  useFilters,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import useCurrentUser from "../../hooks/useCurrentUser";
import useProjects from "../../hooks/useProjects";
import { useDocumentSwitchView } from "../../hooks/useSwitchView";

import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import DocumentFolderView from "../../stories/Components/DocumentFolderView/DocumentFolderView";
import DocumentFolderContent from "../../stories/Components/DocumentFolderContent/DocumentFolderContent";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import SiteHeaderSearch from "../../stories/Components/SiteHeader/SiteHeaderSearch";
import SiteHeaderFilter from "../../stories/Components/SiteHeader/SiteHeaderFilter";
import SiteHeaderSwitchView from "../../stories/Components/SiteHeader/SiteHeaderSwitchView";

import folderIconGreen from "../../stories/assets/images/folderIconGreen.svg";
import folderIconWhite from "../../stories/assets/images/folderIconWhite.svg";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import {
  CREATE_DOCUMENTS_PATH,
  GET_PROJECT_CREATE_DOCUMENT_PATH,
  FILTER_TYPES,
  TABLE_FILTER,
} from "../../constants";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
} from "../../stories/Components/Table/tableFilters";

const DocumentFileFolders = ({
  contents,
  favorites,
  path,
  selectedDocuments,
  defaultType,
  docTypeOptionsMap,
  addedFiles,
  onUpdateDocuments,
  onFileSelect,
  onFileClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileAdd,
  onFileDelete,
  onFolderClick,
  onFavoriteClick,
  onBackClick,
  showHeader,
  showFolder,
  hideButtons,
}) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const { projectId } = useParams();
  const [isShowingTable, setIsShowingTable] = useDocumentSwitchView(false);
  const { currentProject } = useProjects(projectId);
  const [columns, setColumns] = useState([]);

  const documentColumns = useMemo(
    () => [
      {
        id: "favorited",
        Header: "",
        accessor: "isFavorited",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
      },
      {
        Header: "Name",
        accessor: "resource",
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "docType",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: (row) => moment(row.modifiedDate).format("MMM DD, YYYY"),
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const col = documentColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [documentColumns]);

  const {
    setAllFilters,
    setGlobalFilter,
    rows,
    allColumns,
    state: { filters, globalFilter },
  } = useTable(
    {
      columns,
      data: contents,
      autoResetFilters: false,
      autoResetGroupBy: false,
    },
    useFilters,
    useGlobalFilter
  );

  const [value, setValue] = useState(globalFilter);
  const onSearchChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const handleCreateDocumentClick = () => {
    if (projectId) {
      history.push(GET_PROJECT_CREATE_DOCUMENT_PATH(projectId));
    } else {
      history.push(CREATE_DOCUMENTS_PATH);
    }
  };

  const handleUploadClick = () => {
    document.getElementsByClassName("document_upload_form")[0]?.click();
  };

  const buttonActions = [
    { title: "Add Document", onClick: handleUploadClick },
    { title: "Create Financial Document", onClick: handleCreateDocumentClick },
  ];

  return (
    <>
      {showHeader && (
        <SiteHeader
          title={currentProject?.name || "Documents"}
          dropdownRoutes={
            projectId && getProjectOptions(projectId, currentUser)
          }
          buttons={
            currentUser?.hasPermission?.("document", "can_write") && (
              <PrimaryButton
                title="Actions"
                dropdownItems={buttonActions}
                onClick={handleCreateDocumentClick}
                large
              />
            )
          }
          search={
            <SiteHeaderSearch
              handleSearch={(val) => {
                onSearchChange(val);
                setValue(val);
              }}
              globalFilter={value}
            />
          }
          filter={
            <SiteHeaderFilter
              allColumns={allColumns}
              tableData={contents}
              filters={filters}
              setAllFilters={setAllFilters}
            />
          }
          switchView={
            <SiteHeaderSwitchView
              isShowingTable={isShowingTable}
              setIsShowingTable={setIsShowingTable}
              firstIcon={folderIconGreen}
              firstSelectedIcon={folderIconWhite}
            />
          }
        />
      )}
      <div className="flex flex-col">
        {!showFolder && (
          <DocumentFolderView
            documentData={rows}
            favorites={favorites}
            selectedDocuments={selectedDocuments}
            docTypeOptionsMap={docTypeOptionsMap}
            addedFiles={addedFiles}
            onUpdateDocuments={onUpdateDocuments}
            onFileSelect={onFileSelect}
            onFileClick={onFileClick}
            onFileEdit={onFileEdit}
            onShowHistory={onShowHistory}
            onSendToWorkflow={onSendToWorkflow}
            onUploadVersion={onUploadVersion}
            onFileAdd={onFileAdd}
            onFileDelete={onFileDelete}
            onFolderClick={onFolderClick}
            onFavoriteClick={onFavoriteClick}
            hideButtons={hideButtons}
          />
        )}
        {showFolder && (
          <DocumentFolderContent
            path={path}
            contents={rows}
            favorites={favorites}
            selectedDocuments={selectedDocuments}
            docTypeOptionsMap={docTypeOptionsMap}
            defaultType={defaultType}
            addedFiles={addedFiles}
            onUpdateDocuments={onUpdateDocuments}
            onFileSelect={onFileSelect}
            onFileClick={onFileClick}
            onFileEdit={onFileEdit}
            onShowHistory={onShowHistory}
            onSendToWorkflow={onSendToWorkflow}
            onUploadVersion={onUploadVersion}
            onFileAdd={onFileAdd}
            onFileDelete={onFileDelete}
            onFavoriteClick={onFavoriteClick}
            onBackClick={onBackClick}
            hideButtons={hideButtons}
          />
        )}
      </div>
    </>
  );
};

DocumentFileFolders.propTypes = {
  /**
   * array of document attachments
   */
  // eslint-disable-next-line react/forbid-prop-types
  contents: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of favortied documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * folder path to display
   */
  path: PropTypes.string,
  /**
   * selected documents
   */
  // eslint-disable-next-line react/forbid-prop-types
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  /**
   * default document type
   */
  defaultType: PropTypes.string,
  /**
   * map of all document type options
   */
  // eslint-disable-next-line react/forbid-prop-types
  docTypeOptionsMap: PropTypes.object,
  /**
   * array of added files
   */
  // eslint-disable-next-line react/forbid-prop-types
  addedFiles: PropTypes.array,
  /**
   * function called after new document is uploaded
   */
  onUpdateDocuments: PropTypes.func,
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * function for handling folder click
   */
  onFolderClick: PropTypes.func,
  /**
   * function for handling file click
   */
  onFileClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * function for file upload
   */
  onFileAdd: PropTypes.func,
  /**
   * function for handling file delete
   */
  onFileDelete: PropTypes.func,
  /**
   * function for handling favoriting of a document
   */
  onFavoriteClick: PropTypes.func,
  /**
   * function for handling back click
   */
  onBackClick: PropTypes.func,
  /**
   * boolean for displaying site header
   */
  showHeader: PropTypes.bool,
  /**
   * boolean for displaying folder
   */
  showFolder: PropTypes.bool,
  /**
   * boolean for hiding document buttons
   */
  hideButtons: PropTypes.bool,
};

DocumentFileFolders.defaultProps = {
  contents: [],
  favorites: [],
  path: undefined,
  selectedDocuments: undefined,
  defaultType: undefined,
  docTypeOptionsMap: {},
  addedFiles: [],
  onUpdateDocuments: undefined,
  onFileSelect: undefined,
  onFolderClick: undefined,
  onFileClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileAdd: () => {},
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  onBackClick: undefined,
  showHeader: true,
  showFolder: false,
  hideButtons: false,
};

export default DocumentFileFolders;
