import React from "react";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import Modal from "../../stories/Components/Modal/Modal";
import PrimaryHeaderItems from "../../stories/Components/Primary/PrimaryHeaderItems";
import WidgetEditorView from "../../stories/Components/WidgetEditorView";
import Attachments from "./Attachments";
import Description from "./Description";
import Details from "./Details";
import Media from "./Media";
import Tasks from "./Tasks";
import useServiceRequestViewData from "./useServiceRequestViewData";

const DIVIDER_CLASSNAME = "my-8 border-b border-gray-450 border-opacity-50";
const detailsLabelCN = "text-es-normal font-semibold text-es-medium-grey";
const detailsRowCN = "py-4 pr-4 pl-0";
const otherLabelCN = "font-es-semibold text-es-normal text-gray-650";

const ServiceRequest = ({
  requestId,
  association,
  hideHeader,
  setButtonActions,
  onBackButtonPressed,
}) => {
  const {
    serviceRequest,
    requestForm,
    isEditing,
    isLoading,
    canUpdate,
    canDelete,
    canComplete,
    canAddTasks,
    showDeleteDialog,
    filesState,
    serviceRequestTasks,
    markCompleteDialog,
    ticketTypeOptions,
    setShowDeleteDialog,
    handleNavigateBack,
    handleChangeForm,
    handleDeleteTicket,
    handleBeginEditing,
    handleCancelEditing,
    handleFinishEditing,
    handleTags,
    handleFilesToAdd,
    handleFilesToRemove,
    handleAddTasks,
    handleRemoveTasks,
    handleDissmissCompleteDialog,
    handleMarkAsComplete,
    handleShowCompleteDialog,
  } = useServiceRequestViewData({
    requestId,
    association,
    setButtonActions,
    onBackButtonPressed,
  });

  return (
    <>
      <PrimaryHeaderItems
        isEditing={isEditing}
        headerTitle="Service Request"
        headerTitleCN="font-bold uppercase"
        buttonTitle="Service Request"
        className={hideHeader ? "hidden" : ""}
        customButton={
          canComplete &&
          !isEditing && (
            <PrimaryButton
              buttonTitle="Mark as Complete"
              addButton
              onClick={handleShowCompleteDialog}
            />
          )
        }
      />

      <WidgetEditorView
        showBackButton
        hideFooter
        isLoading={isLoading}
        onFinishEditing={handleFinishEditing}
        onBackPressed={handleNavigateBack}
      >
        <Description
          descriptionCN="align-left font-es-semibold text-es-xl tracking-es-xwide"
          serviceRequest={serviceRequest}
          editableRequestForm={requestForm}
          isEditing={isEditing}
          handleChangeForm={handleChangeForm}
        />

        <Details
          detailsRowCN={detailsRowCN}
          labelClassName={detailsLabelCN}
          canEdit={canUpdate}
          canDelete={canDelete}
          serviceRequest={serviceRequest}
          editableRequestForm={requestForm}
          isEditing={isEditing}
          ticketTypeOptions={ticketTypeOptions}
          handleChangeForm={handleChangeForm}
          handleTags={handleTags}
          onBeginEditing={handleBeginEditing}
          onCancelEditing={handleCancelEditing}
          onFinishEditing={handleFinishEditing}
          onDeletePressed={() => setShowDeleteDialog(true)}
        >
          <div className={DIVIDER_CLASSNAME} />

          <Tasks
            labelClassName={otherLabelCN}
            canAddTasks={canAddTasks}
            serviceRequestTasks={serviceRequestTasks}
            isEditing={isEditing}
            handleAddTasks={handleAddTasks}
            handleRemoveTasks={handleRemoveTasks}
            serviceRequestAssociation={serviceRequest?.original?.association}
          />

          <div className={isEditing ? DIVIDER_CLASSNAME : "my-4"} />

          <Media
            labelClassName={otherLabelCN}
            requestForm={requestForm}
            isEditing={isEditing}
            filesState={filesState}
            handleFilesToAdd={handleFilesToAdd}
            handleFilesToRemove={handleFilesToRemove}
          />

          <div className={isEditing ? DIVIDER_CLASSNAME : "my-4"} />

          <Attachments
            labelClassName={otherLabelCN}
            requestForm={requestForm}
            isEditing={isEditing}
            filesState={filesState}
            handleFilesToAdd={handleFilesToAdd}
            handleFilesToRemove={handleFilesToRemove}
            documentTypeFilter="Documents"
          />
        </Details>
      </WidgetEditorView>

      {showDeleteDialog && (
        <Modal
          alert
          title="Delete Request"
          isOpen={showDeleteDialog}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={handleDeleteTicket}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteDialog(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <p className="text-base mb-2">
            {serviceRequestTasks?.length
              ? "By deleting this Service Request, you're also deleting all of the related tasks, are you sure? This action cannot be undone."
              : "Are you sure you want to delete this request? Once deleted, it cannot be recovered."}
          </p>
        </Modal>
      )}

      {markCompleteDialog.open && (
        <Modal
          alert
          title={markCompleteDialog.title}
          isOpen={markCompleteDialog.open}
          primaryButtonTitle={markCompleteDialog.primaryButton}
          primaryButtonOnClick={handleMarkAsComplete}
          tertiaryButtonTitle={markCompleteDialog.tertiaryButtonTitle}
          onRequestModalClose={handleDissmissCompleteDialog}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <p className="text-base mb-2">{markCompleteDialog.message}</p>
        </Modal>
      )}
    </>
  );
};

export default ServiceRequest;
