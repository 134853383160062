/* eslint-disable no-nested-ternary */
import moment from "moment-timezone";
import React from "react";
import cntl from "cntl";
import { getFullName } from "../../../../../helpers/Formatters";
import RoleMembersAvatarList from "../../../../../Pages/Admin/RoleMembersAvatarList";
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../../Checkbox/Checkbox";
import DatePicker from "../../../DatePicker/DatePicker";
import TimePicker from "../../../DatePicker/TimePicker";
import DistroListSelectMembers from "../../../DistroList/DistroListSelectMembers";
import RequestGridViewDistroListCell from "../../../Requests/RequestGridView/RequestGridViewDistroListCell";
import useTaskGeneralInfoData from "./useTaskGeneralInfoData";
import Input from "../../../Input/Input";
import Dropdown from "../../../Dropdown/Dropdown";
import FormatedPriorityLevel from "../../../DropDownWithPriorityLevel/FormatedPriorityLevel";
import DescriptionField from "../../../DescriptionField";
import convertTZ from "../../../../../helpers/Date/convertTimezone";
import getDateTimeInTimezone from "../../../../../helpers/Calendar/getDateTimeInTimezone";
import { guessTimeZone } from "../../../../../helpers/Time";
import TaskRelatedTo from "../../../RelatedTo/TaskRelatedTo";
import { repetitionOptions } from "../../../../../constants";
import AssociationLink from "../../../AssociationLink";

const rowCN = (className) => cntl`
flex
flex-row
h-fit
items-center
my-4
gap-6
${className}
`;
const leftContainer = "flex flex-row w-1/2 items-center";
const rightContainer = "flex flex-row w-1/2 ml-4 items-center";
const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };
const textCN = "text-gray-450 text-sm";
const labelCN = "text-gray-450 font-semibold text-md";

const Divider = ({ fullLength }) =>
  !fullLength ? (
    <div className="flex flex-row gap-10">
      <div className="w-1/2 border-b my-2" />
      <div className="w-1/2 border-b my-2" />
    </div>
  ) : (
    <div className="flex flex-row w-full border-b py-2" />
  );

const TaskGeneralInfo = ({
  taskForm,
  allDayData,
  currentTask,
  editingMode,
  setFormDate,
  setFormTime,
  setFormEndDate,
  setFormEndTime,
  setFormRecurrence,
  dateForm,
  timeForm,
  endDateForm,
  endTimeForm,
  currentInvitees,
  completedBy,
  createdBy,
  inviteeArray,
  handleAddInvitee,
  handleRemoveInvitee,
  setInviteOpenPopup,
  inviteOpenPopup,
  allInviteesInfo,
  removeAssignee,
  handleDescriptionChange,
  handlePriorityChange,
  isPastTask,
  recurrenceDropDownRef,
  recurrenceForm,
  recurrenceString,
}) => {
  const { association, type, priorityOptions, rsvpData } =
    useTaskGeneralInfoData({
      currentTask,
      timeForm,
    });

  return (
    <>
      {/* Association and Date */}
      <div className={rowCN("mt-8")}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            {type}
          </p>
          <AssociationLink
            associationName={association}
            associationRef={currentTask?.association}
          />
        </div>
        <div className={rightContainer}>
          <p className={labelCN} style={labelWidth}>
            Timezone
          </p>
          <p className={textCN}>
            {currentTask.timezone || guessTimeZone()?.label}
          </p>
        </div>
      </div>

      <Divider />

      {/* Time and Recurrence Row */}
      <div className={rowCN()}>
        {editingMode ? (
          <div className={leftContainer}>
            <div className="flex flex-row w-full items-center justify-around">
              <p style={labelWidth} className={labelCN}>
                Start
              </p>
              <DatePicker
                className="w-full mr-2"
                onChange={setFormDate}
                value={convertTZ(
                  new Date(moment.tz(dateForm, currentTask.timezone).format()),
                  currentTask.timezone
                )}
                disabled={isPastTask}
              />
              {!allDayData.isAllDay && (
                <TimePicker
                  className="w-full flex flex-row mr-2"
                  onChange={setFormTime}
                  value={convertTZ(
                    new Date(
                      moment.tz(timeForm, currentTask.timezone).format()
                    ),
                    currentTask.timezone
                  )}
                  disabled={isPastTask}
                />
              )}
              <Checkbox
                checked={allDayData.isAllDay}
                onChange={allDayData.toggleAllDay}
                inputClassName="calendar-form-tick"
                label="All Day"
                size="6"
                disabled={isPastTask}
              />
            </div>
          </div>
        ) : (
          <div className={leftContainer}>
            <p style={labelWidth} className={labelCN}>
              Start
            </p>
            <p className={textCN}>
              {getDateTimeInTimezone({
                date: currentTask.startDate,
                timezone: currentTask.timezone,
                format: allDayData.isAllDay
                  ? "MMMM DD, YYYY"
                  : "MMMM DD, YYYY hh:mmA",
              })}
            </p>
          </div>
        )}

        {editingMode ? (
          <div className={rightContainer}>
            <div className="flex flex-row w-full items-center justify-around">
              <p style={labelWidth} className={labelCN}>
                End
              </p>
              <DatePicker
                className="w-full mr-2"
                onChange={setFormEndDate}
                value={convertTZ(
                  new Date(
                    moment.tz(endDateForm, currentTask.timezone).format()
                  ),
                  currentTask.timezone
                )}
                disabled={isPastTask}
              />
              {!allDayData.isAllDay && (
                <TimePicker
                  className="w-full flex flex-row mr-2"
                  onChange={setFormEndTime}
                  value={convertTZ(
                    new Date(
                      moment.tz(endTimeForm, currentTask.timezone).format()
                    ),
                    currentTask.timezone
                  )}
                  disabled={isPastTask}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={rightContainer}>
            <p style={labelWidth} className={labelCN}>
              End
            </p>
            <p className={textCN}>
              {getDateTimeInTimezone({
                date: currentTask.endDate,
                timezone: currentTask.timezone,
                format: allDayData.isAllDay
                  ? "MMMM DD, YYYY"
                  : "MMMM DD, YYYY hh:mmA",
              })}
            </p>
          </div>
        )}
      </div>

      <Divider />

      {/* Assignees and Priority Row */}
      <div className={rowCN()}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Repeat
          </p>
          {editingMode ? (
            <div className="flex-col w-full">
              <Dropdown
                tabIndex={0}
                containerRef={recurrenceDropDownRef}
                name="recurrence"
                placeholder="Select"
                className="w-full"
                options={repetitionOptions}
                value={recurrenceForm}
                onChange={setFormRecurrence}
                isDisabled={isPastTask}
                disableSort
              />
              {!!recurrenceString && (
                <p className={`${textCN} mt-2`}>{recurrenceString}</p>
              )}
            </div>
          ) : (
            <p className={textCN}>{recurrenceString}</p>
          )}
        </div>

        <div className={rightContainer}>
          <p style={labelWidth} className={labelCN}>
            Priority
          </p>
          {editingMode ? (
            <Dropdown
              disableClear
              placeholder="None"
              disableSort
              disableSearch
              options={priorityOptions}
              value={{
                label: FormatedPriorityLevel(taskForm?.priority),
                value: taskForm.priority,
              }}
              onChange={({ value }) => handlePriorityChange(value)}
            />
          ) : (
            FormatedPriorityLevel(currentTask?.priority)
          )}
        </div>
      </div>

      <Divider />

      {/* CreatedBy and Completed By Row */}
      <div className={rowCN("mb-6")}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Description
          </p>
          {editingMode ? (
            <Input
              placeholder="Description"
              className="bg-backgroundGreen rounded text-gray-450"
              inputClassName="bg-backgroundGreen p-2"
              mainWrapperClassName="w-full"
              value={taskForm.description}
              onChange={(value) => handleDescriptionChange(value)}
              isTextarea={taskForm?.description?.trim()?.length > 65}
            />
          ) : (
            <DescriptionField
              className={textCN}
              text={currentTask?.description}
            />
          )}
        </div>

        <div className={rightContainer}>
          <p style={labelWidth} className={labelCN}>
            Created By
          </p>
          <RoleMembersAvatarList members={[createdBy]} className="mr-2" />
          <p className={textCN}>{getFullName(createdBy?.name)}</p>
        </div>
      </div>

      <Divider />

      {/* Description Row */}
      <div className={rowCN()}>
        <div className={leftContainer}>
          {!editingMode ? (
            <p style={labelWidth} className={labelCN}>
              Assigned To
            </p>
          ) : (
            <div style={labelWidth} className="flex items-center">
              <p className={labelCN}>Assign To</p>
              <PlusCircleButton
                className={labelCN}
                onClick={() => {
                  setInviteOpenPopup(!inviteOpenPopup);
                }}
              />
            </div>
          )}

          {allInviteesInfo.length === 0 && !editingMode && (
            <p className={textCN}>This task is not assigned to anyone.</p>
          )}

          {editingMode ? (
            <div>
              <RequestGridViewDistroListCell
                distroList={currentInvitees}
                hideTile
                handleDeleteFromDistro={removeAssignee}
                avatarClassName="w-8 h-8"
              />

              {inviteOpenPopup && (
                <DistroListSelectMembers
                  setDistroSelectOpen={setInviteOpenPopup}
                  selectedMembers={currentInvitees}
                  members={inviteeArray}
                  onAdd={handleAddInvitee}
                  onRemove={handleRemoveInvitee}
                  title="Assignees"
                  showMemberPill
                  placeHolder="Search Assignees"
                />
              )}
            </div>
          ) : (
            <RoleMembersAvatarList
              viewAllButton={
                <div className="flex justify-center items-center bg-backgroundGrey rounded-3xl w-8 h-8">
                  <p className="flex justify-center text-primaryGreen text-xs font-normal w-5">
                    {`+${allInviteesInfo?.length - 5}`}
                  </p>
                </div>
              }
              membersToShowCount={5}
              members={allInviteesInfo}
              showRsvpStatus
              rsvpData={rsvpData}
              viewAllClassName="pl-0"
            />
          )}
        </div>

        <div className={rightContainer}>
          <p style={labelWidth} className={labelCN}>
            Related To
          </p>
          <TaskRelatedTo taskRef={currentTask?.reference} />
        </div>
      </div>
      <Divider />
      <div className={rowCN()}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Completed By
          </p>
          {completedBy && (
            <>
              <RoleMembersAvatarList members={[completedBy]} className="mr-2" />
              <p className={textCN}>{getFullName(completedBy?.name)}</p>
            </>
          )}
        </div>
      </div>
      <Divider fullLength />
    </>
  );
};

export default TaskGeneralInfo;
