import React, { useState, useCallback, useEffect } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { BudgetAPI, ProjectAPI } from "@griffingroupglobal/eslib-api";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import Dropdown from "../Dropdown/Dropdown";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import { projectBudgetInfoSchema } from "../../../helpers/FormValidations";
import { useAppState } from "../../../state/appState";
import { budgetTypesOptions, SET_PROJECTS } from "../../../constants";
import useProjectBudgetFormReducer from "../../../hooks/useProjectBudgetFormReducer";
import BudgetRateSheet from "./BudgetRateSheet";
import useProjectFormReducer from "../../../hooks/useProjectFormReducer";
import BudgetMembers from "./BudgetMembers";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useProjectById from "../../../hooks/useProjectById";
import BudgetLocations from "./BudgetLocations";
import Spinner from "../Spinner/Spinner";
import { toastError } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const BudgetForm = ({ setIsCreatingBudget }) => {
  const [{ projects }, dispatchAppState] = useAppState();
  const { projectId } = useParams();
  const { project: projectData, isLoading } = useProjectById(projectId);
  const [projectBudget, dispatchProjectBudget] = useProjectBudgetFormReducer();
  const { data: managementConfiguration } = useManagementConfiguration();
  const [project, dispatchProject] = useProjectFormReducer();
  const [isInputValid, setIsInputValid] = useState(false);
  const [oldProject, setOldProject] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    setShowLoadingIndicator(
      isSaving ||
        isLoading ||
        !budgetTypesOptions.length ||
        !managementConfiguration
    );
  }, [isLoading, isSaving, managementConfiguration]);

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    if (projectData) {
      dispatchProject({
        type: "reset",
        project: projectData,
      });
      setOldProject(projectData);
      dispatchProjectBudget({
        type: "reset",
        budget: {
          project: projectData?.reference,
          active: true,
        },
      });
    }
  }, [dispatchProject, dispatchProjectBudget, projectData]);

  useEffect(() => {
    checkValidation(projectBudget, projectBudgetInfoSchema);
  }, [projectBudget, checkValidation]);

  const onCancel = useCallback(() => {
    setIsCreatingBudget(false);
  }, [setIsCreatingBudget]);

  const onChangeBudgetType = (val) => {
    dispatchProjectBudget({
      type: "budgetType",
      budgetType: val.value,
    });
  };

  const onCreateBudget = useCallback(async () => {
    try {
      setIsSaving(true);
      const { data } = await BudgetAPI.post(projectBudget);

      const updatedProject = { ...project };
      updatedProject.budget = data.reference;
      // filter empty data
      updatedProject.rateSheet.rates =
        updatedProject?.rateSheet?.rates?.filter((rs) => rs.category !== "") ||
        [];

      await ProjectAPI.patch(project.id, updatedProject, oldProject);

      dispatchAppState({
        type: SET_PROJECTS,
        projects: projects.map((p) => {
          if (p.id === project.id) {
            return updatedProject;
          }
          return p;
        }),
      });

      setIsSaving(false);

      setIsCreatingBudget(false);
    } catch (err) {
      setIsSaving(false);
      toastError(
        `Error Creating Budget. ${err.message || ""}`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  }, [
    dispatchAppState,
    setIsCreatingBudget,
    oldProject,
    project,
    projectBudget,
    projects,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col flex-1 mt-4">
          <PrimaryHeader className="pt-6">Create Budget</PrimaryHeader>
        </div>
        <div className="flex flex-1 justify-end pr-14">
          <ProgressTracker steps={["Details", "Create"]} selectedIndex={0} />
        </div>
      </div>
      <hr />
      <p className="text-gray-300 text-md font-semibold mt-8">Budget details</p>
      <div className="grid grid-cols-12 gap-4">
        <Dropdown
          className="col-span-3 my-5"
          label="Budget Type"
          options={budgetTypesOptions}
          value={budgetTypesOptions?.find(
            (type) => type.value === projectBudget?.budgetType
          )}
          onChange={onChangeBudgetType}
          validation={yup.mixed().required()}
        />
      </div>
      <div>
        <p className="my-4 text-gray-300 text-md font-semibold">
          Budget prerequisites
        </p>
      </div>
      <BudgetRateSheet project={project} dispatch={dispatchProject} />
      <BudgetMembers
        project={project}
        dispatch={dispatchProject}
        managementConfiguration={managementConfiguration}
      />
      <BudgetLocations
        project={project}
        dispatch={dispatchProject}
        managementConfiguration={managementConfiguration}
      />
      <div className="flex flex-row mb-10">
        <div className="flex justify-between min-w-full max-w-full">
          <div />
          <div className="flex border-2 border-gray-100 p-2">
            <TertiaryButton
              title="Cancel"
              onClick={onCancel}
              className="mr-2"
            />
            <PrimaryButton
              title="Create"
              onClick={onCreateBudget}
              disabled={!isInputValid || isSaving}
            />
          </div>
        </div>
      </div>
      {showLoadingIndicator && <Spinner />}
    </>
  );
};

BudgetForm.propTypes = {
  setIsCreatingBudget: PropTypes.func,
};

BudgetForm.defaultProps = {
  setIsCreatingBudget: undefined,
};

export default BudgetForm;
