/**
 * Get the sorting category of a given string based on the first character
 * ES Rule
 * - 0: A-Z
 * - 1: #s
 * - 2: Special characters
 *
 * @param {string} value - The string whose first character is used to determine the sorting category.
 * @returns {number} A numeric value representing the sorting category.
 */
const getSortCategory = (value) => {
  const firstChar = value.charAt(0).toLowerCase();

  // A-Z
  if (firstChar >= "a" && firstChar <= "z") {
    return 0;
  }

  // #s
  if (firstChar >= "0" && firstChar <= "9") {
    return 1;
  }

  // Special characters
  return 2;
};

/**
 * Sort an array of objects based on a specified property.
 * @param {Object[]} arr - The array of objects to be sorted.
 * @param {string} property - The given property of the objects used for sorting.
 * @returns {Object[]} The sorted array of objects.
 */
const sortByProperty = (arr = [], property = "") => {
  return arr.sort((a, b) => {
    const valueA = a[property] || "";
    const valueB = b[property] || "";

    const categoryA = getSortCategory(valueA);
    const categoryB = getSortCategory(valueB);

    if (categoryA === categoryB) {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    }

    return categoryA - categoryB;
  });
};

export default sortByProperty;
